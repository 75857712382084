import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Select from 'components/UI/Select/Select';
import { LANG_LIST } from 'constants/all';
import { setReceiptLang } from 'actions/check';

const LangList = () => {
  const dispatch = useDispatch();
  const [defaultLang, setDefaultLang] = useState({});

  const selectLang = (langCode) => {
    dispatch(setReceiptLang(langCode));
  };

  useEffect(() => {
    const langFirst = LANG_LIST[0];

    selectLang(langFirst.key);
    setDefaultLang({ value: langFirst.key, label: langFirst.value });
  }, []);

  return (
    <>
      <span>Выберите язык для печати чека</span>
      <Select
        title="Выберите язык для печати чека"
        options={LANG_LIST}
        onchange={(lang) => selectLang(lang)}
        selectedValue={defaultLang}
      />
    </>
  );
};

export default LangList;
