import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-maskedinput';

const MypostDeliveryFormData = ({
  account,
  smsCode,
  setAccount,
  setSmsCode,
  isPhoneValid,
  isSmsCodeValid
}) => (
  <>
    <div className={!isPhoneValid ? 'input-error-block' : ''}>
      <label htmlFor="account">Номер телефона</label>
      <br />
      <MaskedInput
        mask="+7 (111) 111-11-11"
        type="tel"
        id="account"
        autoComplete="off"
        maxLength={20}
        placeholder="+7 (777) 123 45 67"
        placeholderChar=" "
        className={!isPhoneValid ? 'input-warning' : ''}
        value={account.toString()}
        onChange={(e) => setAccount(e.target.value.trim())}
        formatCharacters={{
          W: {
            validate(char) { return /[\wа-яА-Я-]/.test(char); },
          }
        }}
      />
      <span className="inputError">
        {(!isPhoneValid) && 'Номер телефона должен состоять из 10 символов'}
      </span>
    </div>
    <div className={!isSmsCodeValid ? 'input-error-block' : ''}>
      <label htmlFor="smsCode">Код из смс</label>
      <br />
      <MaskedInput
        mask={'1'.repeat(10)}
        type="tel"
        id="smsCode"
        autoComplete="off"
        maxLength={20}
        placeholder="Введите код из смс"
        placeholderChar=" "
        className={!isSmsCodeValid ? 'input-warning' : ''}
        value={smsCode.toString()}
        onChange={(e) => setSmsCode(e.target.value.trim())}
      />
      <span className="inputError">
        {!isSmsCodeValid && 'Поле обязательно для заполнения'}
      </span>
    </div>
  </>
);

MypostDeliveryFormData.propTypes = {
  smsCode: PropTypes.string.isRequired,
  account: PropTypes.string.isRequired,
  setAccount: PropTypes.func.isRequired,
  setSmsCode: PropTypes.func.isRequired,
  isPhoneValid: PropTypes.bool.isRequired,
  isSmsCodeValid: PropTypes.bool.isRequired,
};

export default MypostDeliveryFormData;

