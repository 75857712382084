import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/UI/Icon/index';
import CustomButton from 'components/UI/Button/button';
import './modal.scss';

const Modal = ({
  isOpen,
  children,
  onClose,
  title,
  onOk,
  onBack,
  okText,
  cancelText,
  size,
  okButtonIsDisabled,
  isFooterHidden,
  confirm,
  type
}) => {
  const [confirmed, setConfirmed] = useState(false);

  const confirmWindow = () => (
    <div className="modal-confirm">
      <span className="modal-header-text">Все данные будут потеряны</span>
      <span className="modal-header-text">Вы уверены, что хотите закрыть окно?</span>
      <div className="confirm-button-group">
        <CustomButton className="modal-cancel" onClick={() => setConfirmed(false)}>Отмена</CustomButton>
        <CustomButton disabled={okButtonIsDisabled} yellow className="modal-ok" onClick={() => { onClose(); setConfirmed(false); }}>
          Закрыть
        </CustomButton>
      </div>
    </div>
  );

  const onBackCallbak = typeof onBack === 'function' ? () => onBack() : () => onClose();

  if (isOpen) {
    return (
      <div className="modal-wrapper" >
        <div className={`modal-block modal-${size}-size ${type}-modal`} >
          <div className="modal-header">
            <span className="modal-header-text">{title}</span>
            {confirmed && confirmWindow()}
            <button className="modal-close" onClick={confirm ? () => setConfirmed(true) : () => onClose()}>
              <Icon className="modal-close-svg" type="close" />
            </button>
          </div>
          <div className="modal-body">{children}</div>
          {!isFooterHidden && (
            <div className="modal-footer">
              <CustomButton
                className="modal-cancel"
                onClick={confirm ? () => setConfirmed(true) : onBackCallbak}
              >
                {cancelText}
              </CustomButton>
              <CustomButton disabled={okButtonIsDisabled} yellow className="modal-ok" onClick={() => onOk()}>
                {okText}
              </CustomButton>
            </div>
          )}
        </div>
      </div>
    );
  }

  return null;
};

Modal.defaultProps = {
  size: '',
  okButtonIsDisabled: false,
  cancelText: 'Отмена',
  okText: 'Ок',
  isFooterHidden: false,
  confirm: false,
  type: 'standard',
  onBack: '',
  onOk: () => { /* This is intentional */ },
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  okButtonIsDisabled: PropTypes.bool,
  isFooterHidden: PropTypes.bool,
  confirm: PropTypes.bool,
  onBack: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]),
  onOk: PropTypes.func,
  cancelText: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  okText: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  size: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
};

export default Modal;
