import CustomButton from 'components/UI/Button/button'; import React from 'react';
import PropTypes from 'prop-types';

const ExportExcelButton = ({ onClick }) => (
  <CustomButton onClick={onClick}>
    Экспорт в excel
  </CustomButton>
);

ExportExcelButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ExportExcelButton;
