import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Loader from 'components/UI/Loader/Loader';
import Alert from 'components/UI/AlertModal/alertmodal';
import ErrorBoundary from 'components/ErrorBoundary';
import FooterBlock from 'layout/main/FooterBlock';
import { goToLoginPage, goToMainPage } from 'actions/navigate';
import { getAuthData } from 'model/UserDb';

import AuthHeaderBlock from './AuthHeaderBlock';
import './layout.css';

class AuthLayoyt extends Component {
  componentDidMount() {
    const { guid } = getAuthData();
    const path = window.location.pathname;

    if (!guid && path === '/') {
      this.props.goToLoginPage();
    } else if (guid) {
      this.props.goToMainPage();
    }
  }

  render() {
    const path = this.props.children.props?.match?.path;

    return (
      <div className="auth-container">
        <ErrorBoundary>
          <Loader />
          <Alert />
          <AuthHeaderBlock />
          <div className={path && path.includes('session-ended') ? 'session-ended-content' : 'auth-content'} >
            {this.props.children}
          </div>
          <FooterBlock />
        </ErrorBoundary>
      </div>
    );
  }
}

AuthLayoyt.propTypes = {
  children: PropTypes.object.isRequired,
  goToLoginPage: PropTypes.func.isRequired,
  goToMainPage: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      goToLoginPage,
      goToMainPage
    },
    dispatch);
}

export default connect(null, mapDispatchToProps)(AuthLayoyt);
