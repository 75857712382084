import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';

import { goToLoginPage } from 'actions/navigate';
import CustomButton from 'components/UI/Button/button';

class SessionEnded extends Component {
  render() {
    return (
      <div style={{ padding: 60, backgroundColor: 'white', textAlign: 'center' }}>
        <h2>В целях безопасности сеанс работы завершен</h2>
        <p>Для продолжения работы Вам необходимо авторизоваться</p>
        <CustomButton onClick={this.props.goToLoginPage}>Войти</CustomButton>
      </div>
    );
  }
}

SessionEnded.propTypes = {
  goToLoginPage: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ goToLoginPage }, dispatch);
}

export default connect(null, mapDispatchToProps)(SessionEnded);
