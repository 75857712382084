import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { VERSION_PO } from 'constants/all';
import 'moment/locale/ru';
import { getAuthData } from 'model/UserDb';
import { getServicesList } from 'actions/services';

const FooterBlock = (props) => {
  const { idTerminal, guid } = getAuthData();

  return (
    <footer className="footer-wrapper">
      <span>{guid && `Терминал: ${idTerminal}.`}
        <button type="button" tabIndex={0} onClick={() => props.getServicesList(true)} className="buttonWithoutStyles version">Версия: {VERSION_PO}</button>
      </span>
      {`© ТОО «Личная касса». 2005 - ${moment().format('YYYY')}`}
    </footer>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    getServicesList
  },
  dispatch);

FooterBlock.propTypes = {
  getServicesList: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(FooterBlock);
