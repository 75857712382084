/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { sha256 } from 'js-sha256';
import { VERSION_PO } from 'constants/all';
import { WEBSOCKET_URL } from 'constants/api';
import { getAuthData } from 'model/UserDb';
import { setWebsocketCommand, getWebsocketCommand } from 'model/Websocket';
import { b64DecodeUnicode, logInfoToSentry, showNotification } from 'helpers/main';
import { useLocation } from 'hooks';
import { getNotifications } from 'actions/notification';

const useWebSocket = (
  sendInfoLogToGateway,
  getServicesList,
  getCommissionProfiles,
  showCashOutInfo
) => {
  const { latitude, longitude } = useLocation();
  const { idTerminal } = getAuthData();
  const [ws, setWs] = useState({});
  const [isClosedWsByServer, setisClosedWsByServer] = useState(false);

  // В силу того что сокет бывает не стабилен добавим такое
  const [isWsUnstable, setWsIsUnstable] = useState(false);

  useEffect(() => {
    if (latitude && longitude && typeof ws.readyState !== 'undefined' && ws.readyState === 1) {
      // отправка локации
      ws.send(JSON.stringify({
        type: 0,
        latitude,
        longitude
      }));
    }
  }, [latitude, longitude, ws.readyState]);

  useEffect(() => {
    if (typeof ws.readyState === 'undefined' && !isWsUnstable) {
      setWsConnect();
    }

    return () => {
      if (typeof ws.close !== 'undefined') {
        ws.close();
      }
    };
  }, [ws, isWsUnstable]);

  useEffect(() => {
    let interval = () => {
      // This is intentional
    };

    if (isWsUnstable) {
      interval = setInterval(() => {
        setWsConnect();
      }, 900000); // 15 минут
    }

    return () => clearInterval(interval);
  }, [isWsUnstable]);

  ws.onopen = () => {
    // отправка версии ПО
    ws.send(JSON.stringify({
      type: 0,
      vpo: VERSION_PO
    }));

    // проверяем выполнена ли команда обновления
    const command = getWebsocketCommand();

    if (command === 'rebootApp') {
      sendInfoLogToGateway({ message: 'Команда на перезагрузку страницы выполнена', reqType: 'socketCommand' });

      // шлём событие о том что выполнили команду
      ws.send(JSON.stringify({
        type: 0,
        dateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        command: `${command} - success`
      }));

      // Чистим команду сокета в локал сторадж
      setWebsocketCommand('');
    }
  };

  ws.onmessage = ({ data }) => {
    const { command, status, message } = JSON.parse(data);

    if (typeof command !== 'undefined') {
      const commandName = b64DecodeUnicode(command);

      logInfoToSentry(`Пришла команда от сокета ${commandName}`);
      sendInfoLogToGateway({ message: `Пришла команда от сокета ${commandName}`, reqType: 'socketCommand' });

      if (commandName === 'rebootApp') {
        setWebsocketCommand(commandName);
        showNotification();
      }

      if (commandName === 'updateConf') {
        getServicesList(true);
        getCommissionProfiles(true);
        setWebsocketCommand('');
      }

      console.log('commandName', commandName);

      if (commandName === 'cashOutNotify') {
        getNotifications();
        showCashOutInfo(message);
      }
    }

    // Закрылся коннект, потому что создался новый
    if (typeof status !== 'undefined' && status === 'off') {
      setisClosedWsByServer(true);
      ws.close();
    }
  };

  ws.onerror = (error) => {
    if (error.type === 'error') {
      setWsIsUnstable(true);
    }
  };

  ws.onclose = () => {
    if (!isClosedWsByServer && !isWsUnstable) {
      setWsConnect();
    }
  };

  const setWsConnect = useCallback(() => {
    if (typeof idTerminal !== 'undefined' && idTerminal) {
      const wsConnect = new WebSocket(`${WEBSOCKET_URL}?guid=${sha256(idTerminal)}&idTerminal=${idTerminal}`);

      setWs(wsConnect);
    }
  }, [idTerminal]);

  return { isClosedWsByServer };
};

export default useWebSocket;
