/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'components/UI/Select/Select';
import { selectFiscalSectionForPayment } from 'actions/fiscal';
import { getFiscalSectionsForCom, getSectionKeyOnId, getFiscalData } from 'helpers/fiscal';

const SelectFiscalSection = (props) => {
  const [selectedSection, setSelectedSection] = useState(null);
  const { sectionIdForCommission } = getFiscalData();
  const sections = getFiscalSectionsForCom();

  useEffect(() => {
    const defaultValue = sections.find((item) => item.key === sectionIdForCommission);

    if (defaultValue) {
      setSelectedSection({ value: defaultValue.key, label: defaultValue.value });
      props.selectFiscalSectionForPayment(Number(sectionIdForCommission));
    }
  }, []);

  const setSectionForCommission = value => {
    if (value) {
      const selValue = sections.filter((item) => item.key === Number(value))[0];

      setSelectedSection({ value: selValue.key, label: selValue.value });
    } else setSelectedSection(null);
    props.selectFiscalSectionForPayment(Number(value));
  };

  return (
    <div className="payment-method">
      <div>
        <label htmlFor="section">Выберите секцию для учета комиссии:</label>
        <Select
          id="section"
          title="Секция"
          options={sections}
          selectedValue={selectedSection}
          selectedKey={getSectionKeyOnId(selectedSection)}
          onchange={(sectionId) => setSectionForCommission(sectionId)}
        />
      </div>
    </div>
  );
};

SelectFiscalSection.propTypes = {
  selectFiscalSectionForPayment: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ selectFiscalSectionForPayment }, dispatch);
}

export default connect(null, mapDispatchToProps)(SelectFiscalSection);
