import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import generateCheck from '@mvsolovyev/check-file-generation';
import Alert from 'components/UI/Alert/alert';
import Modal from 'components/UI/Modal/modal';
import Input from 'components/UI/Input/input';
import CustomButton from 'components/UI/Button/button';
import { getCheckOnSn, saveCheckSettings } from 'actions/check';
import { printCheckFromB64 } from 'helpers/check';
import './style.scss';
import { getFromLocalStorage } from 'helpers/main';

const PrintCheckSettings = (props) => {
  const dispatch = useDispatch();
  const paymentsHistory = useSelector((state) => state.payment.paymentsHistory);
  const [lineHeight, setLineHeight] = useState(30);
  const [checkWidth, setCheckWidth] = useState(300);
  const [sn, setSn] = useState('');
  const lastPaymentSN = useMemo(() => paymentsHistory.length ? paymentsHistory[0].sn : '', [paymentsHistory]);
  const [receiptData, setReceiptData] = useState({});
  const [receiptB64, setReceiptB64] = useState('');
  const [disabledPrint, setDisabledPrint] = useState(true);
  const [errors, setErrors] = useState({
    lineHeight: false,
    checkWidth: false,
    sn: false,
  });

  const saveReceiptSettings = () => {
    if (!lineHeight) return setErrors({ ...errors, lineHeight: true });
    if (!checkWidth) return setErrors({ ...errors, checkWidth: true });

    props.saveCheckSettings({ lineHeight, checkWidth });
  };

  const getReceipt = useCallback((transNum = '') => {
    if (transNum || sn) {
      setErrors({ ...errors, sn: false });

      dispatch(getCheckOnSn({ sn: transNum || sn, printAfterGetting: false }))
        .then(response => {
          if (typeof response !== 'undefined') {
            setReceiptData(response);
            setDisabledPrint(false);
          }
        });
    } else {
      setErrors({ ...errors, sn: true });
      setDisabledPrint(true);
    }
  }, [dispatch, errors, sn]);

  useEffect(() => {
    if (lastPaymentSN) {
      setSn(lastPaymentSN);
      getReceipt(lastPaymentSN);
    }
    // eslint-disable-next-line
  }, [lastPaymentSN]);

  useEffect(() => {
    if (Object.keys(receiptData).length) {
      generateReceipt();
    }
    // eslint-disable-next-line
  }, [receiptData]);

  useEffect(() => {
    if (
      sn &&
      lineHeight && lineHeight.toString().length >= 2 &&
      checkWidth && checkWidth.toString().length >= 2
    ) {
      generateReceipt();
    }
    // eslint-disable-next-line
  }, [checkWidth, lineHeight, sn]);

  useEffect(() => {
    const settings = getFromLocalStorage('checkSettings');

    if (typeof settings !== 'undefined') {
      setLineHeight(settings.lineHeight);
      setCheckWidth(settings.checkWidth);
    }
  }, []);

  const generateReceipt = () => {
    if (Object.keys(receiptData).length) {
      const checkTextWithFormat = {
        ...receiptData,
        format: {
          padding: 5,
          markdown: {
            normal: {
              fontWeight: 'normal',
              fontSize: 25,
              lineHeight: Number(lineHeight)
            },
          }
        }
      };

      generateCheck('data:image/png', checkTextWithFormat, (err, dataUrl) => {
        if (!err) {
          setReceiptB64(dataUrl);
        } else {
          console.log('err', err);
        }
      });
    } else {
      console.log('receiptData 2', receiptData);
    }
  };

  const print = useCallback(() => {
    if (Object.keys(receiptB64).length) {
      printCheckFromB64({ check: receiptB64, checkWidth });
    }
  }, [receiptB64, checkWidth]);

  const setSnHandler = (value) => {
    setSn(value);
    if (value.length === 14) getReceipt(value);
  };

  return (
    <Modal
      isOpen
      size="full-height"
      okText="Сохранить настройки чека"
      title="Настроить печать чека"
      onClose={() => props.setShowReceiptSettings(false)}
      onOk={() => saveReceiptSettings()}
    >
      <div className="modal-scroll-body">
        <div className="receipt-settings">
          <div>
            <div className="fiscalItem">
              <Input
                placeholder="Введите значение"
                type="number"
                onchange={setCheckWidth}
                value={checkWidth.toString()}
                label="Ширина чека"
                errorMessage="Поле обязательно для заполнения"
                isError={errors.checkWidth}
              />
            </div>
            <div className="fiscalItem">
              <Input
                placeholder="Введите значение"
                type="number"
                onchange={setLineHeight}
                value={lineHeight.toString()}
                label="Отступ между строк"
                errorMessage="Поле обязательно для заполнения"
                isError={errors.lineHeight}
              />
            </div>
            <div className="fiscalItem">
              <Alert message="Заполните номер транзакции чтобы увидеть как применятся настройки для чека" banner />
              <Input
                placeholder=""
                type="text"
                onchange={setSnHandler}
                value={sn.toString()}
                label="Номер транзакции"
                errorMessage="Поле обязательно для заполнения"
                isError={errors.sn}
              />
            </div>
            <div className="fiscalItem">
              <CustomButton yellow className="modal-ok" onClick={() => print()} disabled={disabledPrint} style={{ opacity: disabledPrint ? 0.6 : 1 }}>
                Распечатать чек
              </CustomButton>
            </div>
          </div>
          <div className="check-preview" style={{ width: `${checkWidth}px` }}>
            <div className="receipt-preview-block">
              {checkWidth && receiptB64.length ? (
                <img src={receiptB64} width={checkWidth} alt="receipt-preview" className="receipt-preview" />
              ) : (
                <div className="receipt-preview">Заполните номер транзакции чтобы здесь появился чек</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    saveCheckSettings
  },
  dispatch);

PrintCheckSettings.propTypes = {
  setShowReceiptSettings: PropTypes.func.isRequired,
  saveCheckSettings: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintCheckSettings);

