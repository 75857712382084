import { getAuthData } from 'model/UserDb';
import { getTerminalInfo } from 'model/Terminal';
import {
  checkUnicodeSymbols,
  getDateInMls,
  getAccountOnMask,
  imageEncode,
  getFromLocalStorage
} from 'helpers/main';
import update from 'react-addons-update'; // ES6
import { KTJ } from 'constants/payTypes';
import { CARD_PAYMENT_METHOD } from 'constants/all';
import { ENERGOPOTOK_SERVICE_ID, SERVICES_WITH_CONTRACT, PENALTIES_AND_TAXIES_SCHEME } from 'constants/services';
import { isFiscalInAp } from 'helpers/fiscal';

// Генерим информацию для Инкассационного чека
export const generateCheckDataForEncashment = (SN, encashmentAmount, DateDocument) => {
  const terminalInfo = getTerminalInfo();
  const {
    addressPoint,
    nameDiler,
    dataNDS,
    rnnfil,
    rnm,
  } = terminalInfo;

  const { idTerminal } = getAuthData();

  return {
    Document: {
      DateDocument,
      NumberDoc: SN,
      Value: encashmentAmount,
      Cash: encashmentAmount,
      NonCash: 0,
      Change: 0,
      AutonomousNumber: 0,
      TypeDocument: {
        Id: 3,
        Name: 'Расход / Инкассация'
      },
      KKM: {
        Id: idTerminal,
        Rnm: rnm.toString(),
        Company: {
          Bin: rnnfil.toString(),
          Fullname: nameDiler.toString(),
          Nds: (dataNDS || '').toString(),
          Address: {
            Street: addressPoint.toString(),
            House: '',
            Flat: '',
            Town: {
              Name: ''
            }
          }
        },
        Ofd: {
          Name: 'АО "Казахтелеком"'
        }
      },
      User: {
        Name: 'Вебтерминал'
      }
    }
  };
};

// Генерим информацию для Платежного чека
export const generateCheckDataForAP = ({
  SN,
  idService,
  typeInterface,
  name,
  maskEdit,
  isEMoney,
  willBeCreditedAmount,
  commissionSum,
  checkInfo,
  paymentDate,
  paymentMethod,
  isMyPostPayment,
  lang
}) => {
  const {
    addressPoint,
    telSupport,
    nameDiler,
    iin,
    dataNDS,
    rnnfil,
    kfu,
    rnm,
  } = getTerminalInfo();

  const { numUser, idTerminal } = getAuthData();
  const fiscalTerminal = idService === ENERGOPOTOK_SERVICE_ID ? true : isFiscalInAp();

  return checkInfo.map(check => {
    const checkData = {
      print: {
        language: lang
      },
      agent: {
        address: '',
        name: nameDiler.toString(),
        vat: {
          cert: (dataNDS || '').toString(),
          percent: '',
        },
        iin: iin.toString(),
        bin: rnnfil.toString()
      },
      terminal: {
        id: Number(idTerminal),
        address: addressPoint.toString(),
        phones: telSupport.toString(),
        csm: kfu ? kfu.toString() : '',
        crrn: rnm.toString(),
        isFiscal: fiscalTerminal,
        user: Number(numUser)
      },
      service: {
        id: Number(idService),
        scheme: Number(typeInterface),
        name: checkUnicodeSymbols(name),
        isEMoney,
        mask: (typeof maskEdit !== 'undefined' && !isMyPostPayment) ? maskEdit.toString() : '',
        fillWithStatic: (typeof maskEdit !== 'undefined' && maskEdit[0] === '$')
      },
      payment: {
        amount: getAmount(willBeCreditedAmount, typeInterface, check.online), // к зачислению
        commission: getCommissionSum(commissionSum, typeInterface, check.online),
        balance: 0, // если внесено больше нужной суммы, остаток падает на баланс
        transaction: SN,
        time: getDateInMls(paymentDate),
        type: paymentMethod === CARD_PAYMENT_METHOD ? 'non-cash' : 'cash', // cash, non-cash,

      }
    };

    // используем update чтобы не было ссылок на вложенный объекты внутри check
    const updatedCheckData = update(checkData, { $merge: check });

    if (typeInterface === PENALTIES_AND_TAXIES_SCHEME) {
      updatedCheckData.payment = Object.assign(updatedCheckData.payment, check.online);
    }
    if (typeInterface !== KTJ && typeof updatedCheckData.input.account !== 'undefined') {
      return reactAddonsUpdate(updatedCheckData, isMyPostPayment, typeInterface, maskEdit);
    }

    return updatedCheckData;
  });
};

// Генерим информацию для Фискального чека
export const generateCheckDataForFiscal = ({
  SN,
  idService,
  typeInterface,
  name,
  maskEdit,
  isEMoney,
  willBeCreditedAmount,
  commissionSum,
  checkInfo,
  paymentDate,
  paymentMethod,
  kkmInfo: {
    Address: { Street, House, Flat, Town: { Name: TownName } },
    Ofd: { Id: idOfdOperator },
    Company: { FullName, Bin },
    Rnm,
    Znm
  },
  sectionsForCheck,
  isMyPostPayment, lang
}) => {
  const { telSupport, iin, dataNDS } = getTerminalInfo();
  const { numUser, idTerminal } = getAuthData();
  const idOfd = getIdOfd(idOfdOperator);
  const houseAndFlat = getHouseAndFlat(House, Flat);

  return checkInfo.map(check => {
    const isItKtjTicket = typeof check.input.passengers !== 'undefined';
    const checkData = {
      print: {
        language: lang
      },
      agent: {
        address: '',
        name: FullName,
        bin: Bin,
        vat: {
          cert: (dataNDS || '').toString(),
          percent: '',
        },
        iin: iin.toString(),
      },
      terminal: {
        id: Number(idTerminal),
        idOfdOperator: idOfd,
        csm: 182,
        znm: Znm,
        sections: sectionsForCheck,
        crrn: Rnm,
        address: `г. ${TownName}, ул/мкр. ${Street} ${houseAndFlat}`,
        isFiscal: isFiscalInAp(),
        phones: telSupport.toString(),
        user: Number(numUser),
      },
      service: {
        id: Number(idService),
        scheme: Number(typeInterface),
        name: checkUnicodeSymbols(name),
        isEMoney,
        mask: (typeof maskEdit !== 'undefined' && !isMyPostPayment) ? maskEdit.toString() : '',
        fillWithStatic: (typeof maskEdit !== 'undefined' && maskEdit[0] === '$')
      },
      payment: {
        amount: getAmount(willBeCreditedAmount, typeInterface, check.online), // к зачислению
        balance: 0,
        commission: getCommissionSum(commissionSum, typeInterface, check.online),
        transaction: SN,
        time: getDateInMls(paymentDate),
        type: paymentMethod === CARD_PAYMENT_METHOD ? 'non-cash' : 'cash', // cash, non-cash
      },
      codes: []
    };

    if (!isItKtjTicket) {
      checkData.codes.push({
        align: true,
        type: 'qrcode',
        value: 'https://consumer.oofd.kz'
      });
    }

    // используем update чтобы не было ссылок на вложенный объекты внутри check
    const updatedCheckData = update(checkData, { $merge: check });

    if (typeInterface === PENALTIES_AND_TAXIES_SCHEME) {
      updatedCheckData.payment = Object.assign(updatedCheckData.payment, check.online);
    }

    if (typeInterface !== KTJ && typeof updatedCheckData.input.account !== 'undefined') {
      return reactAddonsUpdate(updatedCheckData, isMyPostPayment, typeInterface, maskEdit);
    }

    return updatedCheckData;
  });
};

const getCommissionSum = (commissionSum, typeInterface, online) => {
  // костыль для штрафов и налогов
  // (для них надо комиссии слать в payment, во всех остальных случаях payment статичный)
  let commission = Number(commissionSum);

  if (typeInterface === 73) { // штрафы и налоги
    const { supplierCommission, operatorCommission } = online;

    commission = Number(commissionSum) + supplierCommission + operatorCommission;
  }

  return commission;
};

const getAmount = (willBeCreditedAmount, typeInterface, online) => {
  // костыль для штрафов и налогов
  // (для них надо комиссии слать в payment, во всех остальных случаях payment статичный)
  let amount = willBeCreditedAmount;

  if (typeInterface === 73) { // штрафы и налоги
    const { supplierCommission, operatorCommission } = online;

    amount = willBeCreditedAmount - supplierCommission - operatorCommission;
  }

  return amount;
};

/**
 * @param {array | object} check
 * @param {number} key
 */
export const printCheckFromImg = (check, key = 0, isFiscal = false) => {
  const mywindow = window.open('', `PRINT${key}`, 'height=700,width=1300');
  const checkWidth = '300px';

  if (mywindow !== null) {
    mywindow.document.write('<html><head>');
    mywindow.document.write('</head><body >');
    [].concat(check).forEach(item => {
      if (isFiscal) {
        const dataImage = `data:image/png;base64, ${item}`;

        return mywindow.document.write(`<img src="${dataImage}" width="${checkWidth}" />`);
      }

      return mywindow.document.write(`<img src="${imageEncode(item)}" width="${checkWidth}" />`);
    }
    );
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    setTimeout(() => {
      mywindow.print();
      mywindow.close();
    }, 1000);
  }
};

/**
 * @param {string} check
 * @param {number} key
 */
export const printCheckFromText = (check, key = 0) => {
  const mywindow = window.open('', `PRINT${key}`, 'height=700,width=1300');

  if (mywindow !== null) {
    mywindow.document.write('<html><head>');
    mywindow.document.write('</head><body >');
    mywindow.document.write(`<pre style="line-height: 20px; font-size: 9px; font-family: 'Roboto Mono', monospace;">${check}</pre>`);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();
  }
};

/**
 * @param {array | object} check
 * @param {number} key
 */
export const printCheckFromB64 = ({ check, key = 0, checkWidth = 300 }) => {
  const checkSettings = getFromLocalStorage('checkSettings');
  let width = 300;

  if (checkWidth && Number(checkWidth) > width) {
    width = checkWidth;
  } else if (typeof checkSettings !== 'undefined' && Number(checkSettings.checkWidth) > width) {
    width = Number(checkSettings.checkWidth);
  }

  width = `${width}px`;

  const mywindow = window.open('', `PRINT${key}`, 'height=700,width=1300');

  if (mywindow !== null) {
    mywindow.document.write('<html><head>');
    mywindow.document.write('</head><body >');
    [].concat(check).forEach(item => mywindow.document.write(`<img src="${item}" width="${width}" />`));
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    setTimeout(() => {
      mywindow.print();
      mywindow.close();
    }, 1000);
  }
};

// Дописываем код полученный из онлайн проверки в объект с чеком
export const addCodeForCheck = (checks, code) => {
  if (checks.length) {
    return checks.map(check => {
      if (typeof check.online !== 'undefined') {
        const updatedCheckData = { ...check };

        updatedCheckData.online.code = code;

        return updatedCheckData;
      }

      return undefined;
    });
  }
};

export const getSettingsForCheck = (checkText) => {
  let checkTextWithFormat = checkText;
  const checkSettings = getFromLocalStorage('checkSettings');

  if (typeof checkSettings !== 'undefined') {
    const { lineHeight, fontWeight } = checkSettings;

    checkTextWithFormat = {
      ...checkText,
      format: {
        // width: Number(checkWidth),
        padding: 5,
        markdown: {
          normal: {
            fontWeight,
            fontSize: 25,
            lineHeight: Number(lineHeight),
          },
        }
      }
    };
  }

  return checkTextWithFormat;
};

const getIdOfd = (idOfdOperator) => {
  if (Number(idOfdOperator) === 3) return 1;

  return 2;
};

const getHouseAndFlat = (House, Flat) => {
  let houseAndFlat = `${House}, ${Flat}`;

  if (House && !Flat) {
    houseAndFlat = House;
  }

  if (!House && !Flat) {
    houseAndFlat = '';
  }

  return houseAndFlat;
};

const reactAddonsUpdate = (updatedCheckData, isMyPostPayment, typeInterface, maskEdit) =>
  update(updatedCheckData, {
    input: {
      account: {
        $apply: (account) => !isMyPostPayment ?
          getAccountOnMask({
            payType: typeInterface,
            maskEdit,
            account
          }) :
          account
      }
    }
  });

export const addOptionalAdditionalDataForReceipt = (idService, clientIin, checkData) => {
  let resData = checkData;

  resData = addClientIinIfExist(clientIin, resData);
  resData = addContractIfEXist(idService, resData);

  return resData;
};

const addClientIinIfExist = (clientIin, checkData) => {
  const resData = checkData;

  if (clientIin && resData) {
    if (!resData[0].input.other) resData[0].input.other = {};
    resData[0].input.other['IIN/BIN'] = clientIin;
  }

  return resData;
};

const addContractIfEXist = (idService, checkData) => {
  const resData = checkData;

  if (SERVICES_WITH_CONTRACT.includes(idService)) {
    const contractId = checkData[0].input.option.value;

    if (contractId) {
      if (!resData[0].input.other) resData[0].input.other = {};
      resData[0].input.other['Договор'] = contractId;
    }
  }

  return resData;
};
