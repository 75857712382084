import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateCommissionData } from 'actions/commission';
import { getCommission } from 'helpers/pay';

class Commission extends Component {
  componentDidMount() {
    const {
      currentComProfile,
      depositedAmount,
      commissionSum,
      commissionValue,
      currentService: { rounding, minPr, maxPr, min, max, isCommissionDenied }
    } = this.props;

    if (!commissionSum && !commissionValue && !isCommissionDenied) {
      const comData = getCommission({
        commissionProfile: currentComProfile,
        depositedAmount,
        rounding,
        minPr,
        maxPr,
        min,
        max,
      });

      this.props.updateCommissionData(comData);
    }
  }

  render() {
    const {
      type,
      commissionValue,
      commissionSum,
      currentComProfile,
      depositedAmount,
      currentService: { min, max, minPr, maxPr },
    } = this.props;
    const com = (commissionValue === '') ? '0 тг' : commissionValue;

    const comInfo = [];
    let renderedCommissionValue = 'Нет';
    let renderedCommissionSum = 0;

    if (typeof currentComProfile.positions !== 'undefined') {
      const { pos } = currentComProfile.positions;

      renderedCommissionValue = [].concat(pos).length > 1 && !Number(depositedAmount) ?
        'Рассчитывается от суммы' : com;

      renderedCommissionSum = [].concat(pos).length > 1 && !Number(depositedAmount) ?
        0 : commissionSum;
    } else if (Number(commissionSum)) {
      // случай, когда профиля комисии нет. но остаток от внесено падает в комиссию из за фикс суммы
      renderedCommissionSum = commissionSum;
      renderedCommissionValue = commissionSum;
    }

    if (min) {
      comInfo.push({ title: 'Минимальная сумма комиссии', value: `${min} тг` });
    }

    if (max) {
      comInfo.push({ title: 'Максимальная сумма комиссии', value: `${max} тг` });
    }

    if (minPr) {
      comInfo.push({ title: 'Минимальный процент комиссии', value: `${minPr} %` });
    }

    if (maxPr) {
      comInfo.push({ title: 'Максимальный процент  комиссии', value: `${maxPr} %` });
    }

    if (type === 'value') {
      return (
        <>
          <p key="commission">
            <b>Комиссия: </b>{renderedCommissionValue}
          </p>
          {comInfo.map(({ title, value }, key) => (
            <p key={key}>
              <b>{title}: </b>{value}
            </p>
          ))}
        </>
      );
    }

    return (
      <p key="commission">
        <b>Сумма комиссии: </b>{renderedCommissionSum} тг
      </p>
    );
  }
}

Commission.propTypes = {
  currentComProfile: PropTypes.object.isRequired,
  currentService: PropTypes.object.isRequired,
  updateCommissionData: PropTypes.func.isRequired,
  commissionSum: PropTypes.number.isRequired,
  depositedAmount: PropTypes.number.isRequired,
  commissionValue: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    commissionSum: Number(state.commission.commissionSum),
    currentComProfile: state.commission.currentComProfile,
    commissionValue: state.commission.commissionValue,
    depositedAmount: Number(state.pay.depositedAmount),
    willBeCreditedAmount: Number(state.pay.willBeCreditedAmount),
    currentService: state.kassa.currentService,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateCommissionData }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Commission);
