import { useState, useEffect } from 'react';

const useLocation = () => {
  const [position, setPosition] = useState({ latitude: '', longitude: '' });
  const [getLocationError, setError] = useState(null);

  useEffect(() => {
    const geo = navigator.geolocation;

    if (!geo) {
      setError('Геолокация не поддерживается браузером');

      return;
    }

    // Подписываемся на изменение геопозиции браузера.
    const watcher = geo.watchPosition(onChange, onError);

    // если геопозицию получили, отписываемся от слежки за локацией
    if (position.latitude && position.longitude) {
      geo.clearWatch(watcher);
    }

    // В случае, если компонент будет удаляться с экрана
    // производим отписку от слежки, чтобы не засорять память.
    return () => geo.clearWatch(watcher);
  }, [position.latitude, position.longitude]);

  const onChange = ({ coords }) => {
    const { latitude, longitude } = coords;

    setPosition({ latitude, longitude });
  };

  const onError = (err) => {
    setError(err.message);
  };

  return { ...position, getLocationError };
};

export default useLocation;
