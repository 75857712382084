import InitDb from './InitDb';

const defaultState = {
  sn: 0,
  checkData: [],
  clientIin: '',
  idService: 0,
  payType: 0
};

export const saveFiscalReceiptInIDB = async (data) => {
  if (data.sn) {
    const db = await InitDb();
    const tx = db.transaction('fiscalReceipts', 'readwrite');
    const store = tx.objectStore('fiscalReceipts');

    return store.put({ ...defaultState, ...data });
  }

  return null;
};

export const removeFiscalReceiptFromIDB = async (sn) => {
  if (sn) {
    const db = await InitDb();
    const tx = db.transaction('fiscalReceipts', 'readwrite');
    const store = tx.objectStore('fiscalReceipts');

    store.delete(sn);
  }
};

export const getFiscalReceiptOnSnFromIDB = async (sn) => {
  if (sn) {
    const db = await InitDb();
    const tx = db.transaction('fiscalReceipts', 'readwrite');
    const store = tx.objectStore('fiscalReceipts');

    const receipt = await store.get(sn);

    return receipt || null;
  }
};
