import { openDB } from 'idb';
import { allServicesStruct, commissionProfilesStruct, receiptWithCodeStruct, fiscalReceiptsStruct } from './tablesStruct';

export default async function initDB() {
  return openDB('Payments-app', 9, {
    upgrade(db, oldVersion, newVersion) {
      const stores = Object.values(db.objectStoreNames);

      // Таблица со всеми сервисами
      if (!stores.includes('allServices')) {
        const allServicesStore = db.createObjectStore(
          'allServices',
          { keyPath: 'idService', autoIncrement: false }
        );

        allServicesStruct.forEach(row =>
          allServicesStore.createIndex(row.title, row.title, { unique: row.unique })
        );
      }

      // Таблица с профилями комиссий
      if (!stores.includes('commissionProfiles')) {
        const commissionProfilesStore = db.createObjectStore(
          'commissionProfiles',
          { keyPath: 'id', autoIncrement: false }
        );

        commissionProfilesStruct.forEach(row =>
          commissionProfilesStore.createIndex(row.title, row.title, { unique: row.unique })
        );
      }

      // Таблица с профилями комиссий
      if (!stores.includes('receipts')) {
        const receiptsStore = db.createObjectStore(
          'receipts',
          { keyPath: 'sn', autoIncrement: false }
        );

        receiptWithCodeStruct.forEach(row =>
          receiptsStore.createIndex(row.title, row.title, { unique: row.unique })
        );
      }

      // Таблица с неотправленными фиск. чеками
      if (!stores.includes('fiscalReceipts')) {
        const fiscalReceiptsStore = db.createObjectStore(
          'fiscalReceipts',
          { keyPath: 'sn', autoIncrement: false }
        );

        fiscalReceiptsStruct.forEach(row =>
          fiscalReceiptsStore.createIndex(row.title, row.title, { unique: row.unique })
        );
      }

      if (oldVersion < 8 && newVersion === 8) {
        // удаляем таблицу с логами
        if (stores.includes('logs')) db.deleteObjectStore('logs');
      }
    }
  });
}
