/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/UI/Modal/modal';
import Icon from 'components/UI/Icon/index';
import { getFormatedJsDateTime } from 'helpers/main';
import './style.scss';

const DublicatePaymentWarning = ({ paymentInfo, confirmDublicate, cancelDublicatePayment }) => {
  let status = 'В обработке';
  let date = getFormatedJsDateTime(paymentInfo.dateout);

  // если с ошибкой
  if (typeof paymentInfo.statusNote !== 'undefined') {
    if (paymentInfo.statusNote) {
      status = paymentInfo.statusNote;
      date = '-';
    }
  }

  // если в обработке
  if (paymentInfo.status === 919) {
    status = 'В обработке';
  }

  // если успешный
  if (paymentInfo.status === 0) {
    status = 'Проведен успешно';
  }

  return (
    <Modal
      isOpen
      title="Транзакция с такими данными уже создана"
      onOk={confirmDublicate}
      onClose={cancelDublicatePayment}
      okText="Провести новый платеж"
      size="small-and-high"
    >
      <div className="dublicate-warning">
        <div className="dublicate-warning-header">
          <Icon type="warning" className="alert-error" />
        </div>
        <div className="dublicate-warning-body" >
          <b>Номер транзакции:</b> {paymentInfo.sn} <br />
          <b>Лицевой счет:</b> {paymentInfo.account} <br />
          <b>Сервис:</b> {paymentInfo.nameService} <br />
          <b>Статус:</b> {status} <br />
          <b>Дата:</b> {date} <br />
        </div>
        <div className="dublicate-warning-body-title">
          <b>Вы уверены что хотите провести платеж снова?</b>
        </div>
      </div>
    </Modal>
  );
};

DublicatePaymentWarning.propTypes = {
  paymentInfo: PropTypes.object.isRequired,
  confirmDublicate: PropTypes.func.isRequired,
  cancelDublicatePayment: PropTypes.func.isRequired,
};

export default DublicatePaymentWarning;

