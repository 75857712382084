/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Icon from 'components/UI/Icon';
import Button from 'components/UI/Button/button';
import { getCounterAmountFromLS, resetCounterValueInLS } from 'helpers/pay';
import { setCounterValueInStore } from 'actions/pay';
import './paymentcounter.scss';

const PaymentCounter = (props) => {
  const { paymentsCounter } = props;
  const [isCounterBlockOpen, setIsCounterBlockOpen] = useState(false);

  useEffect(() => {
    const { cash, card } = getCounterAmountFromLS();

    if (cash) props.setCounterValueInStore('cash', cash);
    if (card) props.setCounterValueInStore('card', card);
  }, []);

  const resetPaymentCounter = () => {
    resetCounterValueInLS();
    props.setCounterValueInStore('cash', 0);
    props.setCounterValueInStore('card', 0);
  };

  return (
    <div className="pay-counter-wrapper">
      <button
        className="pay-counter-title"
        type="button"
        tabIndex={0}
        onClick={() => setIsCounterBlockOpen(!isCounterBlockOpen)}
      >
        Накопленная сумма платежей
        <Icon type="chevron" className={`pay-counter-icon ${isCounterBlockOpen && 'pay-counter-rotate'}`} />
      </button>

      <div className={`pay-counter-block ${isCounterBlockOpen && 'pay-counter-block-display'}`} >
        <span>Наличные {paymentsCounter.cash} тг</span>
        <span>Безналичные {paymentsCounter.card} тг</span>
        <span>Всего {paymentsCounter.cash + paymentsCounter.card} тг</span>
        <Button
          yellow
          className="pay-counter-button"
          onClick={() => resetPaymentCounter()}
        >
          Сбросить счетчик
        </Button>
      </div>
    </div>
  );
};

PaymentCounter.propTypes = {
  paymentsCounter: PropTypes.object.isRequired,
  setCounterValueInStore: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    paymentsCounter: state.kassa.paymentsCounter,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setCounterValueInStore,
  },
  dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCounter);

