import React from 'react';
import PayTypes from 'paytypes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MaskedInput from 'react-maskedinput';

import Icon from 'components/UI/Icon';
import { setClientIIN } from 'actions/pay';
import Steps from 'components/UI/Steps/steps';
import Select from 'components/UI/Select/Select';
import CustomButton from 'components/UI/Button/button';
import { TARIFF_KTJ, DOC_TYPES_KTJ, GENDER_KTJ, CITIZENSHIP_KTJ, STEPS } from 'constants/ktj';

const { KtjEnterPassengerTemplate } = PayTypes.templates;

class EnterPassenger extends KtjEnterPassengerTemplate {
  componentDidMount() {
    this.props.setOnOkCallback(() => this.validateAndContinue());
  }

  renderComponent() {
    const {
      cityFromName,
      cityToName,
      date,
      selectedTrain,
      departureTime,
      arrivalTime,
      timeInWay,
      selectedWagon,
      passengers,
      clearWagon,
      updatePassengerInfo,
      deletePassenger,
      setClientIIN: setClientIINKTJ
    } = this.props;
    const IsInterstate = this.isItInterstate();
    const IsObligativeElReg = this.isItObligativeElReg();

    return (
      <div className="pay-form">
        <Steps current={3} steps={STEPS} />
        <div className="selected-train-info">
          <p><b>{cityFromName} - {cityToName} ({date})</b></p>
          <p><b>Номер поезда:</b> {selectedTrain}</p>
          <p><b>Вагон:</b> № {selectedWagon}</p>
          <p><b>Отправление:</b> {departureTime}</p>
          <p><b>Прибытие:</b> {arrivalTime}</p>
          <p><b>В пути:</b> {timeInWay}</p>
          <CustomButton onClick={() => clearWagon()} >
            Выбрать другой вагон
          </CustomButton>
        </div>
        <div>
          <div className="enter-passenger-header">
            <h3>
              <Icon type="addUser" /> Пожалуйста, введите данные по всем пассажирам (кол-во: {passengers.length})
            </h3>
          </div>
          <hr />
          {passengers.map((
            {
              id,
              lastName,
              firstName,
              patronymic,
              docNumber,
              tariff,
              dateBirth,
              gender,
              citizenship,
              iin
            },
            idx
          ) => (
            <div className="passenger-form" key={id}>
              <div className="passenger-form-col">
                <h4>Введите информацию по {idx + 1} пассажиру:</h4>
                <div className="passenger-form-row">
                  <input
                    placeholder="Фамилия"
                    onChange={(e) => updatePassengerInfo(idx, 'lastName', e.target.value.trim())}
                    value={lastName}
                  />
                  <input
                    placeholder="Имя"
                    onChange={(e) => updatePassengerInfo(idx, 'firstName', e.target.value.trim())}
                    value={firstName}
                  />
                  <input
                    placeholder="Отчество"
                    onChange={(e) => updatePassengerInfo(idx, 'patronymic', e.target.value.trim())}
                    value={patronymic}
                  />
                  <input
                    placeholder="ИИН"
                    onChange={(e) => {
                      setClientIINKTJ(e.target.value.trim());
                      updatePassengerInfo(idx, 'iin', e.target.value.trim());
                    }}
                    value={iin}
                  />
                  <input
                    placeholder="Номер документа"
                    onChange={(e) => updatePassengerInfo(idx, 'docNumber', e.target.value.trim())}
                    value={docNumber}
                  />
                  {((tariff === '2' || tariff === '3') || (IsInterstate || IsObligativeElReg)) && (
                    <MaskedInput
                      mask="11.11.1111"
                      type="tel"
                      id="dateOfBirth"
                      autoComplete="off"
                      maxLength={40}
                      placeholder="Дата рождения"
                      placeholderChar=" "
                      value={dateBirth}
                      onChange={(e) => updatePassengerInfo(idx, 'dateBirth', e.target.value)}
                      formatCharacters={{
                        W: {
                          validate(char) { return /[\wа-яА-Я-]/.test(char); },
                        }
                      }}
                    />
                  )}

                  {!idx ? (
                    <CustomButton yellow onClick={() => this.addPassenger()}>
                      Добавить пассажира
                    </CustomButton>
                  ) : (
                    <CustomButton className="ktj-delete-btn" onClick={() => deletePassenger(idx)}>
                      Удалить пассажира
                    </CustomButton>
                  )}

                  {(IsInterstate || IsObligativeElReg) && (
                    <>
                      <div className="form-group">
                        <Select
                          title="Выберите пол"
                          options={GENDER_KTJ}
                          onchange={(value) => updatePassengerInfo(idx, 'gender', value)}
                          defaultValue={gender}
                        />
                      </div>
                      <div className="form-group">
                        <Select
                          title="Выберите гражданство"
                          options={CITIZENSHIP_KTJ}
                          onchange={(value) => updatePassengerInfo(idx, 'citizenship', value)}
                          defaultValue={citizenship}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="passenger-form-row select-document">
                  <span className="modal-header-text title-checkboxes">Выберите тариф</span>
                  <div className="checkbox-button-group  round toggle">
                    {
                      TARIFF_KTJ.map(({ key, value }) => (
                        <span key={key} >
                          <input type="radio" id={key + idx} name={`tariff${idx}`} onChange={() => updatePassengerInfo(idx, 'tariff', key)} />
                          <label className="checkbox-button" htmlFor={key + idx}>{value}</label>
                        </span>
                      ))
                    }
                  </div>
                  <span className="modal-header-text title-checkboxes">Вид документа</span>
                  <div className="checkbox-button-group round toggle">
                    {
                      DOC_TYPES_KTJ.map(({ key, value }) => (
                        <span key={key} >
                          <input type="radio" id={key + idx} name={`docType${idx}`} onChange={() => updatePassengerInfo(idx, 'docType', key)} />
                          <label className="checkbox-button" htmlFor={key + idx}>{value}</label>
                        </span>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

EnterPassenger.propTypes = {
  setClientIIN: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const {
    date,
    passengers,
    cityToName,
    cityFromName,
    selectedTrain,
    selectedWagon,
    selectedTrainData: {
      Departure: { '@attributes': { Time: departureTime } },
      Arrival: { '@attributes': { Time: arrivalTime } },
      TimeInWay: timeInWay,
    },
  } = state.payTypeKTJ;

  return ({
    departureTime,
    arrivalTime,
    timeInWay,
    selectedTrain,
    date,
    cityFromName,
    selectedWagon,
    cityToName,
    passengers
  });
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setClientIIN
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EnterPassenger);
