import PayTypes from 'paytypes';

import { showError } from 'actions/alert';
import { addCodeForCheck } from 'helpers/check';
import { sendInfoLogToGateway } from 'actions/api/logs';
import { jysanOnlineCheck } from 'actions/payTypes/jysan';
import { cashOutOnlineCheck } from 'actions/payTypes/cashout';
import { wooppayOnlineCheck } from 'actions/payTypes/wooppay';
import { removeCheckWithCode, saveCheck, } from 'actions/check';
import { t, getErrText, getAccountOnMask, setSentryBreadсrumbs } from 'helpers/main';
import { checkError, checkMsg, getOnlineCheckData } from 'helpers/onlineCheck';
import { myPostPaymentOnlineCheck, myPostDeliveryOnlineCheck } from 'actions/payTypes/mypost';
import { CASH_OUT_PAY_TYPE, MYPOST_PAY_TYPE_PAYMENT, MYPOST_PAY_TYPE_DELIVERY } from 'constants/payTypes';

import { SERVICES_STATUSES_WITH_ONLINE_CHECK } from 'constants/services';
import * as types from './types';

const {
  fixed: { fixedOnlineCheck },
  complex: { complexOnlineCheck },
  currency: { currencyOnlineCheck },
  kaspibank: { kaspiBankOnlineCheck },
  entercounter: { enterCounterOperatorsOnlineCheck },
  kazaktelecom: { kazaktelecomOnlineCheck },
  simpleandsubmit: { simpleAdnSubmitOnlineCheck },
  foreignoperators: { foreignOperatorsOnlineCheck },
  choiceofcontract: { choiceContractOnlineCheck },
  choiceoftheperson: { choiceOfThePersonOnlineCheck },
  penaltiesandtaxes: { penaltiesOnlineCheck },
  kostanaySu: { kostanaySuOnlineCheck },
  kmf: { KMFOnlineCheck },
  dynamic: { dynamicOnlineCheck, dynamic2OnlineCheck, dynamic3OnlineCheck },
  ktj: { KTJOnlineCheck },
} = PayTypes.actions;

// Онлайн-проверка
export function onlineCheck(service, account) {
  return async (dispatch) => {
    const { maskEdit, typeInterface, checkOnline, idService } = service;

    if (SERVICES_STATUSES_WITH_ONLINE_CHECK.includes(checkOnline)) {
      setSentryBreadсrumbs('check', 'send onlineCheck request');
      const accountOnMask = getAccountOnMask({ maskEdit, payType: typeInterface, account });
      const xml =
        `<checkAccount>
          <sum>0</sum>
          <idService>${idService}</idService>
          <account>${accountOnMask.trim()}</account>
        </checkAccount>`;

      const successCallback = (res) => {
        if (typeof res.destroy === 'undefined') {
          const data = getOnlineCheckData(res, typeInterface);

          if (data !== null) {
            dispatch(onlineCheckSuccessCallback({
              typeInterface,
              data,
              account,
              accountOnMask,
              res
            }));
          } else {
            dispatch(showError(t('serviceIsNotAvailable')));
          }
        }
      };

      dispatch({
        type: `${types.ONLINE_CHECK}_XML_REQUEST`,
        payload: { reqType: 14, xml, successCallback }
      });
    }
  };
}

export function onlineCheckSuccessCallback({ typeInterface, data, account, accountOnMask, res }) {
  return async (dispatch) => {
    switch (typeInterface) {
      case 0: dispatch(simpleAdnSubmitOnlineCheck(data)); break;
      case 1: dispatch(complexOnlineCheck(data)); break;
      case 2: dispatch(currencyOnlineCheck(data)); break;
      case 4: dispatch(choiceContractOnlineCheck(data)); break;
      case 5: dispatch(simpleAdnSubmitOnlineCheck(data)); break;
      case 11: dispatch(simpleAdnSubmitOnlineCheck(data)); break;
      case 12: dispatch(foreignOperatorsOnlineCheck(data)); break;
      case 14: dispatch(enterCounterOperatorsOnlineCheck(data)); break;
      case 15: dispatch(KTJOnlineCheck(res, parseInt(account[0], 10))); break;
      case 16: dispatch(kazaktelecomOnlineCheck(data)); break;
      case 17: dispatch(fixedOnlineCheck(data)); break;
      case 21: dispatch(choiceOfThePersonOnlineCheck(data)); break;
      case 24: dispatch(simpleAdnSubmitOnlineCheck(data)); break;
      case 25: dispatch(kostanaySuOnlineCheck(data)); break;
      case 32: dispatch(kaspiBankOnlineCheck(data)); break;
      case 55: dispatch(KMFOnlineCheck(data)); break;
      case 58: dispatch(jysanOnlineCheck(data)); break;
      case 61: dispatch(fixedOnlineCheck(data)); break;
      case 67: dispatch(wooppayOnlineCheck(data)); break;
      case 73: dispatch(penaltiesOnlineCheck(data, accountOnMask)); break;
      case 75: dispatch(dynamicOnlineCheck(data)); break;
      case 79: dispatch(dynamic2OnlineCheck(data)); break;
      case 86: dispatch(dynamic3OnlineCheck(data)); break;
      case CASH_OUT_PAY_TYPE:
        dispatch(cashOutOnlineCheck(data, Number(account.slice(0, 1)), account)); break;
      case MYPOST_PAY_TYPE_PAYMENT: dispatch(myPostPaymentOnlineCheck(data)); break;
      case MYPOST_PAY_TYPE_DELIVERY: dispatch(myPostDeliveryOnlineCheck(data, account.split(';')[0])); break;
      default: dispatch(simpleAdnSubmitOnlineCheck(data)); break;
    }
  };
}

// Онлайн-проверка (получение кода для смс)
export function getCode(account, idService) {
  return (dispatch, getState) => {
    if (account && idService) {
      const { checksWithCode } = getState().checksWithCode;

      const xml =
        `<checkAccount>
          <sum>0</sum>
          <idService>${idService}</idService>
          <account>${account}</account>
        </checkAccount>`;

      const successCallback = (res) => {
        if (typeof res.destroy === 'undefined') {
          const data = { data: res };
          const errMsg = checkMsg(data);
          const errCode = checkError(data);

          if (errMsg) {
            dispatch(showError(errMsg));
          }

          if (!errCode) {
            successCallbackGetCode(data, account, checksWithCode, res, dispatch);
          } else {
            dispatch(showError(getErrText(errCode)));
          }
        }
      };

      const errorCallback = (message) => {
        errorCallbackGetCode(message, account, checksWithCode, dispatch);
      };

      dispatch({
        type: `${types.ONLINE_CHECK}_GET_CODE_XML_REQUEST`,
        payload: { reqType: 14, xml, successCallback, errorCallback }
      });
    } else {
      console.log('Не удалось запросить код для чека', account, idService);
    }
  };
}

const successCallbackGetCode = (data, account, checksWithCode, res, dispatch) => {
  if (data !== null) {
    if (typeof res.account.code !== 'undefined') {
      const sn = account.split('|')[1];

      if (typeof checksWithCode[sn.toString()] !== 'undefined') {
        const { checks } = checksWithCode[sn.toString()];
        const code = res.account.code;
        const checkWithCode = addCodeForCheck(checks, code);

        if (checkWithCode) {
          dispatch(saveCheck({
            checkData: checkWithCode,
            showPrintWindowAfterSave: true,
            sn,
            successCallback: () => dispatch(successCallbackGetCodeForSaveCheck(sn, checkWithCode))
          }));
        }
      }
    }
  } else {
    dispatch(showError(t('serviceIsNotAvailable')));
  }
};

const successCallbackGetCodeForSaveCheck = (sn, checkWithCode) => (dispatch) => {
  dispatch(sendInfoLogToGateway({
    message: `sn: ${sn}, checkWithCode: ${checkWithCode}`,
    reqType: 'SaveCheckCallbackFromGetCode'
  }));
  dispatch(removeCheckWithCode(sn));
};

const errorCallbackGetCode = (message, account, checksWithCode, dispatch) => {
  if (message.trim() === 'Платеж обработан с ошибкой') {
    console.log('errorCallback', account.split('|')[1], message);
    const sn = account.split('|')[1];

    dispatch(saveCheck({
      checkData: checksWithCode[sn.toString()].checks,
      showPrintWindowAfterSave: true,
      sn,
      successCallback: () => dispatch(removeCheckWithCode(sn))
    }));
  }
};
