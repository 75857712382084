/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Icon from 'components/UI/Icon/index';
import CustomButton from 'components/UI/Button/button';
import { getTerminalInfo } from 'model/Terminal';
import { getBalance } from 'actions/api/getBalance';
import { fetchTerminalInfo } from 'actions/api/terminal';
import { getServicesList } from 'actions/services';
import { getCommissionProfiles } from 'actions/commission';
import { searchPayments } from 'actions/api/paymentHistory';
import { getTheStartDateOfTheCurrentDay, getTheEndOfTheCurrentDay } from 'helpers/main';
import { checkFiscalAuthCommon } from 'actions/fiscal';
import { MAIN_PAGE_URL } from 'constants/all';

const StatusBlock = (props) => {
  const {
    isServiceListRequested,
    isBalanceRequested,
    isCommissionProfilesRequested,
    isPaymentHistoryRequested,
    isServiceListRequestedWithError,
    isBalancesRequestedWithError,
    isCommissionProfilesRequestedWithError,
    paymentHistoryRequstedWithError,
    commissionProfiles,
    startDate,
    endDate,
    serviceList,
    balances: { mayPay },
    isAuthInFiscal
  } = props;

  const [oneMinutePassed, setOneMinutePassed] = useState(false);

  useEffect(
    () => {
      setTimeout(() => {
        setOneMinutePassed(true);
      }, 60000);
    },
    []
  );

  const haveFinishedRequests = useCallback(
    () => {
      if (window.location.href.includes(MAIN_PAGE_URL)) {
        return isServiceListRequested &&
          isBalanceRequested &&
          isCommissionProfilesRequested &&
          isPaymentHistoryRequested;
      }

      return isBalanceRequested;
    },
    [
      isServiceListRequested,
      isBalanceRequested,
      isCommissionProfilesRequested,
      isPaymentHistoryRequested
    ],
  );

  const haveSuccessfullRequests = useCallback(
    () => !isServiceListRequestedWithError &&
      !isBalancesRequestedWithError &&
      !isCommissionProfilesRequestedWithError &&
      !paymentHistoryRequstedWithError,
    [
      isServiceListRequestedWithError,
      isBalancesRequestedWithError,
      isCommissionProfilesRequestedWithError,
      paymentHistoryRequstedWithError
    ]
  );

  const hasRequestError = useCallback(
    () => isServiceListRequestedWithError ||
      isBalancesRequestedWithError ||
      isCommissionProfilesRequestedWithError ||
      paymentHistoryRequstedWithError ||
      getTerminalInfo() === null,
    [
      isServiceListRequestedWithError,
      isBalancesRequestedWithError,
      isCommissionProfilesRequestedWithError,
      paymentHistoryRequstedWithError
    ]
  );

  const getStatus = () => {
    if (haveFinishedRequests()) {
      if (haveSuccessfullRequests()) {
        return 'status-success';
      }

      return 'status-failed';
    }

    return 'status-in-progress';
  };

  const getAllNeededData = useCallback(
    () => {
      checkServiceList();
      checkNumberMayPay();
      checkComissionProfiles();
      checkPaymentHistoryRequstedWithError();
      checkTerminalInfoOnExist();
      props.checkFiscalAuthCommon();
    },
    [
      startDate,
      endDate,
      serviceList,
      mayPay,
      commissionProfiles,
      paymentHistoryRequstedWithError,
      isAuthInFiscal
    ]
  );

  const checkServiceList = useCallback(() => {
    if (!serviceList.length) {
      props.getServicesList();
    }
  }, [serviceList]);

  const checkNumberMayPay = useCallback(() => {
    if (!Number(mayPay)) {
      props.getBalance();
    }
  }, [mayPay]);

  const checkComissionProfiles = useCallback(() => {
    if (!commissionProfiles.length) {
      props.getCommissionProfiles();
    }
  }, [commissionProfiles]);

  const checkPaymentHistoryRequstedWithError = useCallback(() => {
    if (paymentHistoryRequstedWithError) {
      const dateFromStr = startDate || getTheStartDateOfTheCurrentDay();
      const dateToStr = endDate || getTheEndOfTheCurrentDay();

      props.searchPayments(dateFromStr, dateToStr);
    }
  }, [paymentHistoryRequstedWithError, startDate, endDate]);

  const checkTerminalInfoOnExist = useCallback(() => {
    if (getTerminalInfo() === null) {
      props.fetchTerminalInfo();
    }
  }, []);

  const allRequestsCompleted = haveFinishedRequests();
  const showRedButton = hasRequestError() || (!allRequestsCompleted && oneMinutePassed);

  return (
    <>
      {showRedButton ? (
        <CustomButton
          className="button-refresh button-display-warning"
          onClick={() => getAllNeededData()}
        >
          Запросить данные
          <Icon type="refresh" />
        </CustomButton>
      ) : (
        <div className="status-wrapper">
          Статус: <div className={`circle ${getStatus()}`} />
        </div>
      )}
      {(!allRequestsCompleted && !showRedButton) ? <span>Загружаем данные</span> : ''}
    </>
  );
};

StatusBlock.propTypes = {
  getServicesList: PropTypes.func.isRequired,
  getBalance: PropTypes.func.isRequired,
  fetchTerminalInfo: PropTypes.func.isRequired,
  getCommissionProfiles: PropTypes.func.isRequired,
  searchPayments: PropTypes.func.isRequired,
  commissionProfiles: PropTypes.array.isRequired,
  serviceList: PropTypes.array.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  isBalanceRequested: PropTypes.bool.isRequired,
  isBalancesRequestedWithError: PropTypes.bool.isRequired,
  isCommissionProfilesRequested: PropTypes.bool.isRequired,
  isCommissionProfilesRequestedWithError: PropTypes.bool.isRequired,
  paymentHistoryRequstedWithError: PropTypes.bool.isRequired,
  isPaymentHistoryRequested: PropTypes.bool.isRequired,
  isServiceListRequested: PropTypes.bool.isRequired,
  isServiceListRequestedWithError: PropTypes.bool.isRequired,
  balances: PropTypes.object.isRequired,
  isAuthInFiscal: PropTypes.bool.isRequired,
  checkFiscalAuthCommon: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isBalancesRequestedWithError: state.kassa.isBalancesRequestedWithError,
  isBalanceRequested: state.initialDownload.isBalanceRequested,
  balances: state.kassa.balances,
  commissionProfiles: state.commission.commissionProfiles,
  isCommissionProfilesRequested: state.commission.isCommissionProfilesRequested,
  isCommissionProfilesRequestedWithError: state.commission.isCommissionProfilesRequestedWithError,
  paymentHistoryRequstedWithError: state.payment.paymentHistoryRequstedWithError,
  isPaymentHistoryRequested: state.initialDownload.isPaymentHistoryRequested,
  isServiceListRequested: state.services.isServiceListRequested,
  isServiceListRequestedWithError: state.services.isServiceListRequestedWithError,
  startDate: state.payment.startDate,
  endDate: state.payment.endDate,
  serviceList: state.services.serviceList,
  isAuthInFiscal: state.fiscal.isAuthInFiscal
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getBalance,
  searchPayments,
  getServicesList,
  fetchTerminalInfo,
  getCommissionProfiles,
  checkFiscalAuthCommon
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StatusBlock);
