import * as types from 'actions/payTypes/mypost/types';

const defaultState = {
  parcelList: [], // используется в оплате за посылки
  parcelInfo: { // используется в выдаче посылок
    parcelData: [],
    idInvoice: 0,
    idPackage: 0,
    parcelAmount: 0,
    parcelPaidAmount: 0,
  },
  phoneForPayment: '', // заполняется, когда хотят выдать неоплаченную посылку и переходят к оплате
  showMyPostPaymentModal: false,
  showMyPostDeliveryParcelModal: false,
  isMyPostPayment: false,
};

const mypost = (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_SHOW_MYPOST_PAYMENT_MODAL:
      return {
        ...state,
        showMyPostPaymentModal: action.showMyPostPaymentModal
      };
    case types.SET_SHOW_MYPOST_DELIVERY_PARCEL_MODAL:
      return {
        ...state,
        showMyPostDeliveryParcelModal: action.showMyPostDeliveryParcelModal
      };
    case types.SET_PARCEL_LIST:
      return {
        ...state,
        parcelList: action.parcelList,
        isMyPostPayment: true,
      };
    case types.SET_DELIVERY_PARCEL_INFO:
      return {
        ...state,
        parcelInfo: {
          parcelData: action.parcelData,
          idInvoice: action.idInvoice,
          idPackage: action.idPackage,
          parcelAmount: action.parcelPaidAmount,
          parcelPaidAmount: action.parcelPaidAmount
        },
      };
    case types.CLEAR_MYPOST_PARCEL_INFO:
      return {
        ...state,
        parcelInfo: { // используется в выдаче посылок
          parcelData: [],
          idInvoice: 0,
          idPackage: 0,
          parcelAmount: 0,
          parcelPaidAmount: 0,
        },
      };
    case types.SET_IS_MYPOST_PAYMENT:
      return { ...state, isMyPostPayment: action.isMyPostPayment };
    case types.SET_PHONE_FOR_PAYMENT_MYPOST:
      return { ...state, phoneForPayment: action.phoneForPayment };
    case types.CLEAR_MYPOST_PARCEL_LIST:
      return { ...state, parcelList: [] };
    case types.MYPOST_INIT:
      return {
        ...state,
        parcelList: [], // используется в оплате за посылки
        parcelInfo: { // используется в выдаче посылок
          parcelData: [],
          idInvoice: 0,
          idPackage: 0,
          parcelAmount: 0,
          parcelPaidAmount: 0,
        },
        phoneForPayment: '', // заполняется, когда хотят выдать неоплаченную посылку и переходят к оплате
        isMyPostPayment: false,
      };
    default:
      return state;
  }
};

export default mypost;
