import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import IinField from 'components/IinField';
import { convertUnicode, setFocusFormElement } from 'helpers/main';
import MinAndMaxAmount from 'components/MinAndMaxAmount';
import Modal from 'components/UI/Modal/modal';
import { getUgdSelectOptions } from 'helpers/dynamic';
import DefaultField from './Fields/DefaultField';
import Field from './Fields/Field';
import SelectField from './Fields/SelectField';

const FirstDynamicScreen = (props) => {
  const {
    fields,
    idService,
    validateFormAndOnlineCheck,
    setWasInfoAgreed,
    showInfoModal,
    infoMessage,
    handleOnChange,
    formData,
    isBckService,
    isLoading
  } = props;

  useEffect(() => {
    setFocusFormElement('firstScreenForm');
  }, [idService, fields.length]);

  const getField = ({ name: fieldName, options, mask, hidden, capRU, id }) => {
    const name = (Number(id) === 1) ? 'account' : fieldName;

    if (fieldName !== 'label' && !hidden) {
      const data = {
        placeholder: (capRU !== null) ? convertUnicode(capRU) : '',
        name,
        options,
        mask,
        handleOnChange,
        value: formData[name.toString()] || '',
      };

      if (typeof options !== 'undefined' && options !== null) {
        return <SelectField {...data} key={name} />;
      }

      if (mask !== '') {
        return <Field {...data} key={name} disabled={isLoading}/>;
      }

      return <DefaultField {...data} key={name} disabled={isLoading}/>;
    }

    if (fieldName === 'label') {
      return <div className="form-group" key={capRU}>{capRU}</div>;
    }
  };

  if (typeof fields !== 'undefined' && fields.length > 0) {
    return (
      <div className="pay-form">
        <Modal
          title="Информация"
          isOpen={showInfoModal}
          okText="Продолжить оплату"
          onOk={() => setWasInfoAgreed(true)}
          onClose={() => setWasInfoAgreed(false)}
        >
          {infoMessage}
        </Modal>
        <IinField/>
        <form id="firstScreenForm" onSubmit={validateFormAndOnlineCheck}>
          {fields.map((field) => {
            const fieldList = [];

            fieldList.push(getField(field));

            if (isBckService() && field.name === 'tax' && Number(formData.tax) > 0) {
              fieldList.push(getField({
                capRU: 'Управление государственных доходов',
                name: 'ugd',
                options: getUgdSelectOptions(formData.tax)
              }));
            }

            return fieldList;
          })}
          <MinAndMaxAmount idService={idService} isDynamic />
          <button
            className="btn btn-primary"
            type="submit"
          >
            продолжить
          </button>
        </form>
      </div>
    );
  }

  return null;
};

FirstDynamicScreen.propTypes = {
  fields: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  setWasInfoAgreed: PropTypes.func.isRequired,
  validateFormAndOnlineCheck: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  idService: PropTypes.number.isRequired,
  showInfoModal: PropTypes.bool.isRequired,
  infoMessage: PropTypes.string.isRequired,
  isBckService: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default FirstDynamicScreen;
