import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/UI/Icon/index';
import './steps.scss';

const Step = ({ title, number, current }) => {
  let classForStep = current === number ? 'step-active' : '';

  if (current > number) {
    classForStep = 'completed-step';
  }

  return (
    <div className={`step ${classForStep}`}>
      {(current > number) && (
        <Icon type="successYellow" style={{ fontSize: '25px' }} className="success-step-icon" />
      )}
      {(current === number) && (
        <div className="step-in-progress">
          {number}
        </div>
      )}
      {(current < number) && (
        <div className="waiting-step">
          {number}
        </div>
      )}
      <div className="step-content">
        {title}
      </div>
    </div>
  );
};

const Steps = ({ current, steps }) => (
  <div className="steps">
    {steps.map((step, idx) => <Step key={step} title={step} number={idx + 1} current={current} />)}
  </div>
);

Step.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
};

Steps.propTypes = {
  steps: PropTypes.array.isRequired,
  current: PropTypes.number.isRequired // отсчёт текущего шага начинается с 1
};

export default Steps;
