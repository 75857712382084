import { showError, showSuccess } from 'actions/alert';
import { logErrorToSentry } from 'helpers/main';
import { GATEWAY_URL } from 'constants/api';

import * as types from './types';

export const setShowIdentificationModal =
  (showIdentificationModal) => (dispatch) =>
    dispatch({ type: types.SET_SHOW_IDENTIFICATION_MODAL, showIdentificationModal });

export const setShowIdentificationModalFromCashout =
  (data) => (dispatch) => {
    dispatch({ type: types.SET_SHOW_IDENTIFICATION_MODAL_FROM_CASHOUT, data });
  };

export const sendIdentificationReq =
  ({ cellPhone, iin, base, name, surname, patronymic }) =>
    (dispatch, getState) => {
      if (cellPhone && iin && base && name && surname) {
        const REQ_TYPE = 60;
        const json = {
          cellPhone,
          iin,
          base,
          name,
          surname,
          patronymic,
          system: 'webterm',
        };

        const successCallback = (data) => {
          const result = data;

          if (typeof result.identified !== 'undefined' && result.identified) {
            successIdentification(getState().kassa.showIdentModalFromCashout, dispatch);
          } else {
            errorIndentification(result, json, dispatch);
          }
        };

        dispatch({
          type: `${types.SEND_IDENTIFICATION}_JSON_REQUEST`,
          payload: { reqType: REQ_TYPE, json, successCallback }
        });
      } else {
        dispatch(
          showError(
            'Заполните все необходимые данные для идентификации',
            'Не все поля заполнены'
          )
        );
      }

      return {};
    };

const successIdentification = (stateShowModal, dispatch) => {
  dispatch(showSuccess('Клиент успешно идентифицирован'));
  dispatch(setShowIdentificationModal(false));

  const showIdentModalFromCashout = stateShowModal;

  if (showIdentModalFromCashout) {
    dispatch(setShowIdentificationModalFromCashout({
      showIdentModalFromCashout: false,
      showIdentModalFromCashoutAccount: ''
    }));
  }
};

const errorIndentification = (result, json, dispatch) => {
  const url = GATEWAY_URL;
  let errMsg = '';

  if (!result.identified) {
    errMsg = typeof result.message === 'object' ? JSON.stringify(result.message) : result.message;

    logErrorToSentry(
      errMsg,
      url,
      result.status,
      'IDENTIFICATION',
      JSON.stringify(json)
    );
  } else if (result.status === 715) {
    errMsg = 'Ошибка валидации данных';
  }

  dispatch(showError(errMsg));
};
