import { EXCLUDED_ACTIONS_FOR_LOGGING } from 'constants/logs';

import {
  getLogFromLocalStorage,
  updateLogInLocalStorage,
  resetLogInLocalStorage
} from 'model/Logs';
import { getAuthData } from 'model/UserDb';

import { sendLog } from 'actions/api/logs';

export default (store) => (next) => async (action) => {
  const { dispatch, getState } = store;

  if (!action) return next(action);

  const { type } = action;

  if (
    typeof type !== 'undefined' &&
    !EXCLUDED_ACTIONS_FOR_LOGGING.includes(type) &&
    !type.includes('@@router')
  ) {
    const logInProccess = isLogInProccess();
    const { idService, idTerminal, account, wasSelectService } = getLogFromLocalStorage();
    const sn = getState().payment.SN;
    const params = {
      type,
      action,
      sn,
      logInProccess,
      idService,
      idTerminal,
      account
    };

    if (type === 'SET_CURRENT_SERVICE' && logInProccess) {
      // если не закончили оплату за ранее выбранный сервис, удаляем его из локал стораджа
      resetLogInLocalStorage();
    }

    // Все остальные экшны попадают сюда. Дописываем лог
    writeLog(params, wasSelectService, dispatch);
  }

  await next(action);
};

const getLogInfo = ({ type, service, field, value }, sn) => {
  const log = {};

  if (sn && !getLogFromLocalStorage().sn) {
    log.sn = sn;
  }

  if (type === 'MAKE_PAY_XML_REQUEST') {
    log.wasMakePayActionAlready = true;
  }

  if (type === 'PAYMENT_INIT') {
    log.wasPaymentCancelled = true;
  }

  if (type === 'SET_CURRENT_SERVICE') {
    const { idTerminal } = getAuthData();

    log.idTerminal = idTerminal;
    log.idService = service.idService;
    log.wasSelectService = true;
  }

  if (type === 'UPDATE_FIELD_VALUE' && field === 'account') {
    log.account = value;
  }

  if (type === 'SET_SENDED_TO_FISCAL') {
    log.isSendedToFiscal = value;
  }

  return log;
};

const isLastStep = (type) => type === 'SET_BALANCES_XML_REQUEST' && getLogFromLocalStorage().wasPaymentCancelled;

const isLogInProccess = () => getLogFromLocalStorage().inProccess;

const writeLog = async (params, wasSelectService, dispatch) => {
  const {
    type,
    action,
    sn,
    logInProccess,
    idService,
    idTerminal,
    account
  } = params;

  updateLogInLocalStorage(action, getLogInfo(action, sn));

  if (wasSelectService) {
    // Если это последний шаг, то отсылаем лог и удаляем из локал стораджа
    if (logInProccess && isLastStep(type)) {
      const { sn: snFromLS, logActions } = getLogFromLocalStorage();

      dispatch(sendLog(idService, idTerminal, snFromLS || sn, logActions, account));
    }
  }
};
