import { SET_KTJ_CHECK_WITH_CODE, REMOVE_KTJ_CHECK_WITH_CODE } from 'actions/check/types';
import { SET_KTJ_PAYMENT_SUCCESSFUL } from 'actions/api/types';

/* STRUCT:
 {
  "ktjReceipt": {
    "sn": {
      "checks": [],
      "isPaymentSuccess": false,
    }
  }
} */

const defaultState = {
  ktjReceipt: {},
};

const ktjReceipts = (state = defaultState, action = {}) => {
  switch (action.type) {
    case SET_KTJ_CHECK_WITH_CODE: {
      const receipts = { ...state.ktjReceipt };

      receipts[action.sn] = {};
      receipts[action.sn].checks = action.checkData;
      receipts[action.sn].isPaymentSuccess = false;

      return { ...state, ktjReceipt: receipts };
    }
    case REMOVE_KTJ_CHECK_WITH_CODE: {
      const ktjReceipt = { ...state.ktjReceipt };

      if (typeof ktjReceipt[action.sn] !== 'undefined') {
        delete ktjReceipt[action.sn];
      }

      return { ...state, ktjReceipt };
    }
    case SET_KTJ_PAYMENT_SUCCESSFUL: {
      const receiptData = state.ktjReceipt[action.sn];

      if (typeof receiptData !== 'undefined') {
        receiptData.isPaymentSuccess = true;

        return {
          ...state,
          ktjReceipt: {
            ...state.ktjReceipt,
            [action.sn]: receiptData
          }
        };
      }

      return { ...state };
    }
    default:
      return state;
  }
};

export default ktjReceipts;
