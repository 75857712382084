
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/UI/Modal/modal';
import Icon from 'components/UI/Icon/index';
import { OLIMPBET_SERVICES } from 'constants/services';

const OlimpbetModal = ({ idService }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (OLIMPBET_SERVICES.ID.includes(idService)) {
      setShowModal(true);
    }
  }, [idService]);

  return (
    <Modal
      isOpen={showModal}
      title="Внимание!"
      onClose={() => setShowModal(false)}
      size="small-and-high"
      isFooterHidden
    >
      <div className="dublicate-warning">
        <div className="dublicate-warning-header">
          <Icon type="warning" className="alert-error" />
        </div>
        <div className="dublicate-warning-body-title">
          <b>
            {OLIMPBET_SERVICES.ALERT_INFO}
          </b>
        </div>
      </div>
    </Modal>
  );
};

OlimpbetModal.propTypes = {
  idService: PropTypes.number.isRequired,
};

export default OlimpbetModal;
