import React from 'react';
import PayTypes from 'paytypes';
import MaskedInput from 'react-maskedinput';

import IinField from 'components/IinField';
import Modal from 'components/UI/Modal/modal';
import Checkbox from 'components/UI/Checkbox/checkbox';
import { t, getMaskForInput, checkAccountOnReg } from 'helpers/main';
import MinAndMaxAmount from 'components/MinAndMaxAmount';

const {
  containers: { KmfContainer },
  templates: { KmfTemplate }
} = PayTypes;

class Kmf extends KmfTemplate {
  state = {
    showModal: false,
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDownFowWindow);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDownFowWindow);
  }

  componentDidUpdate(prevProps) {
    const {
      pay: { amount },
      commission: { currentComProfile },
      payTypeKmf: { selectedClients, clients },
    } = this.props;

    if (prevProps.payTypeKmf.selectedClients.length !== selectedClients.length) {
      this.updateItogAmount();
    }

    if (clients.length !== prevProps.payTypeKmf.clients.length && clients.length) {
      this.showModal();
    }

    if (amount !== prevProps.pay.amount && amount) {
      this.props.updateWillBeCreditedAmount(amount, currentComProfile, true);
    }
  }

  onKeyDownFowWindow = (e) => {
    if (e.key === 'Enter') {
      if (this.state.showModal) {
        this.validateAndConfirm();
      } else {
        this.validateAndCheck();
      }
    }
  };

  showModal = () => this.setState({ showModal: true });

  hideModal = () => this.setState({ showModal: false });

  handleOnChange(e) {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  confirm() {
    const {
      setConfirmInfo,
      showConfirmScreen,
      pay: { account },
      payTypeKmf: { editableClients, selectedClients },
    } = this.props;

    const confirmField = [
      { name: 'account', value: account },
    ];

    const checkInfo = {
      input: {
        account,
        users: []
      }
    };

    selectedClients.map(selectedeKey => {
      const { IIN, amount1: clientAmount } = editableClients[selectedeKey.toString()];

      checkInfo.input.users.push({
        iin: IIN.toString(),
        amount: Number(clientAmount)
      });

      return checkInfo;
    });

    setConfirmInfo(confirmField, this.getAddings(), checkInfo);
    showConfirmScreen();
  }

  validateAndConfirm() {
    const { showError } = this.props;
    const checkedClients = this.getCheckedClients();

    if (checkedClients.filter(value => (Number(value.amount1) < 1)).length) {
      return showError('Одна из введенных сумм недопустима');
    }

    return this.confirm();
  }

  validateAndCheck() {
    const { account } = this.state;
    const {
      showError,
      onlineCheck,
      updateFieldValue,
      payTypeKmf: { id },
      kassa: { currentService },
      iinIsValidOrEmpty
    } = this.props;

    this.setState({ isLoading: false });
    if (!iinIsValidOrEmpty()) return;
    if (account) {
      const { regExp, maskEdit } = currentService;

      if (checkAccountOnReg(account, regExp, maskEdit)) {
        updateFieldValue('account', account.trim());

        if (id && id.toString() === account.toString()) {
          return this.showModal();
        }
        this.setState({ isLoading: true });

        return onlineCheck(currentService, account)
          .then(() => { this.setState({ isLoading: false }); });
      }

      return showError(
        'Проверьте, пожалуйста, и повторите попытку',
        'Лицевой счет некорректен'
      );
    }

    return showError(
      'Проверьте, пожалуйста, и повторите попытку',
      'Не заполнен лицевой счет'
    );
  }

  /* Сервис для теста: МКО КМФ. ЛС: 920719350245; 440501450255 */
  render() {
    const { kassa, payTypeKmf } = this.props;
    const { clients, selectedClients, editableClients } = payTypeKmf;
    const { maskEdit, idService } = kassa.currentService;
    const { showModal } = this.state;
    const mask = getMaskForInput(maskEdit);

    return (
      <>
        <Modal
          title={t('chooseWhoYouWillPayFor')}
          isOpen={showModal}
          onOk={() => this.validateAndConfirm()}
          onClose={this.hideModal}
          okText={t('pay')}
        >
          <div className="confirm-info">
            <table className="all-width text-center">
              <thead>
                <tr>
                  <th />
                  <th>{t('client')}</th>
                  <th>{t('forPayment')}</th>
                  <th>{t('upcomingPayment')}</th>
                  <th>{t('earlyPayment')}</th>
                </tr>
              </thead>
              <tbody>
                {clients.map(({ name, amount2, amount3 }, key) => (
                  <tr key={name}>
                    <td span={8} colSpan={2} className="text-left">
                      <Checkbox
                        checked={selectedClients.includes(key)}
                        onchange={() => this.checkClient(key)}
                        label={name}
                        id={key}
                      />
                    </td>
                    <td>
                      <MaskedInput
                        className="ant-input"
                        mask="11111111111111111111"
                        type="tel"
                        autoComplete="off"
                        maxLength={10}
                        placeholder="Введите сумму"
                        placeholderChar=" "
                        onChange={(e) => this.changeAmount(key, e)}
                        value={Math.round(editableClients[key.toString()].amount1).toString()}
                      />
                    </td>
                    <td>{Number(amount2)} тг</td>
                    <td>{Number(amount3)} тг</td>
                    <td />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>

        <div className="pay-form">
          <IinField/>
          <div className="form-group">
            <MaskedInput
              mask={mask}
              id="account"
              type="tel"
              autoComplete="off"
              maxLength={40}
              placeholder="№ лицевого счета"
              placeholderChar=" "
              onChange={(e) => this.handleOnChange(e)}
              value={this.state.account}
              formatCharacters={{
                W: {
                  validate(char) { return /[\wа-яА-Я-]/.test(char); },
                }
              }}
              disabled={this.state.isLoading}
            />
          </div>
          <MinAndMaxAmount idService={idService} />
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => this.validateAndCheck()}
          >
            {t('getInfo')}
          </button>
        </div>
      </>
    );
  }
}

export default KmfContainer(Kmf);
