import React from 'react';
import PayTypes from 'paytypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MaskedInput from 'react-maskedinput';

import IinField from 'components/IinField';
import { showError } from 'actions/alert';
import MinAndMaxAmount from 'components/MinAndMaxAmount';
import { getAdditionalDetails } from 'actions/services';
import { t, getCurrentDate, checkUnicodeSymbols } from 'helpers/main';
import { updateSelectedFieldValue, updateWillBeCreditedAmount } from 'actions/pay';

const { CharityTemplate } = PayTypes.templates;

class Charity extends CharityTemplate {
  state = {
    amount: ''
  };

  componentDidMount() {
    const { additionalDetails, currentService: { idService } } = this.props;

    if (typeof additionalDetails[Number(idService)] === 'undefined') {
      this.props.getAdditionalDetails(idService);
    }

    this.props.updateSelectedFieldValue('account', getCurrentDate());
    document.addEventListener('keydown', this.onKeyDownFowWindow);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDownFowWindow);
  }

  onKeyDownFowWindow = (e) => {
    if (e.key === 'Enter') {
      this.validateAndPay();
    }
  };

  setAmount = (amount) => this.setState({ amount });

  confirm() {
    const { setConfirmInfo, showConfirmScreen } = this.props;
    const confirmField = [];
    const checkInfo = {
      input: {}
    };

    setConfirmInfo(confirmField, '', checkInfo);
    showConfirmScreen();
  }

  validateAndPay() {
    const { iinIsValidOrEmpty } = this.props;
    const { amount } = this.state;

    if (!iinIsValidOrEmpty()) return;

    if (amount) {
      this.props.updateWillBeCreditedAmount(amount, this.props.currentComProfile);
      this.confirm();
    } else {
      return this.props.showError(
        'Пожалуйста, введите сумму оплаты и повторите попытку',
        'Не заполнена сумма оплаты'
      );
    }
  }

  /*  ЧБФ Аяла */
  renderComponent() {
    const {
      additionalDetails,
      currentService: { idService },
      amount,
    } = this.props;

    return (
      <div className="pay-form">
        <div className="form-group text-center">
          {(typeof additionalDetails[Number(idService)] !== 'undefined') &&
            checkUnicodeSymbols(additionalDetails[Number(idService)])
          }
        </div>
        <IinField />
        <div className="form-group text-center">
          <MaskedInput
            mask="111111111"
            type="tel"
            id="amount"
            autoComplete="off"
            maxLength={9}
            placeholder="Сумма оплаты"
            placeholderChar=" "
            onChange={(e) => this.setAmount(e.target.value)}
            value={amount}
          />
        </div>
        <MinAndMaxAmount idService={idService} />
        <div className="form-group">
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.validateAndPay.bind(this)}
          >
            {t('next')}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  additionalDetails: state.services.additionalDetails,
  currentService: state.kassa.currentService,
  currentComProfile: state.commission.currentComProfile,
  amount: state.pay.amount,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    showError,
    getAdditionalDetails,
    updateSelectedFieldValue,
    updateWillBeCreditedAmount
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Charity);
