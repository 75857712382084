import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/UI/Modal/modal';

const FiscalNotSendedEncashment = ({ onClose, }) => (
  <Modal
    isOpen
    size="small"
    title="Внимание! Непроведенная инкассация в фискал!"
    onOk={onClose}
    onClose={onClose}
    okText="Ок"
    cancelText="Закрыть"
    onBack={onClose}
  >
    <div>
      <div>Перед закрытием смены убедитесь, что все инкассации отправлены в фискал.</div>
      <div>
        Для этого перейдите на страницу <b>Журнал инкассации</b>,
        и в столбце <b>Фискал</b> должна быть пометка <b>Отправлено в фискал</b>,
        иначе допроведите инкассацию,
        нажав на кнопку <b>Отправить инкассацию в фискал</b>.
      </div>
    </div>
  </Modal>
);

FiscalNotSendedEncashment.propTypes = {
  onClose: PropTypes.array.isRequired,
};

export default (FiscalNotSendedEncashment);

