import moment from 'moment';

import { LOGO_URL } from 'constants/api';
import { KASPI_CREDIT, KASPI_GOLD } from 'constants/services';
import { CARD_PAYMENT_METHOD } from 'constants/pay';
import { checkUnicodeSymbols } from 'helpers/main';

import { getTerminalInfo } from 'model/Terminal';
import { isInt } from './main';

export const getServiceLogo = (logo) => `${LOGO_URL}${logo}`;

export const getReqBodyForComplexScheme = (complexInfo) => {
  const { params, invoices, address, id } = complexInfo;

  return {
    rs: {
      account: {
        id,
        st: 0,
        address,
        invoices: {
          invoice: invoices,
        },
        params: {
          param: params
        }
      }
    }
  };
};

export const getCommission = (settings) => {
  const { commissionProfile } = settings;

  let comData = { commissionSum: 0, commissionValue: '' };

  if (typeof commissionProfile !== 'undefined' && commissionProfile !== null) {
    const { positions } = commissionProfile;

    if (positions) {
      comData = getComDataOnProfilePositions({ positions, ...settings });
    }
  }

  return comData;
};

const getComDataOnProfilePositions = ({
  positions,
  depositedAmount = 0,
  willBeCreditedAmount = 0,
  isFix = false,
  rounding = '',
  minPr = '',
  maxPr = '',
  min = '',
  max = '',
}) => {
  // Если несколько позиций для профиля, выбираем подходящий по дате
  let comData = { commissionSum: 0, commissionValue: '' };
  const { pos } = positions;

  if (Array.isArray(pos)) {
    const currentActivePos = pos.filter(item => checkProfileDate(item));

    if (currentActivePos.length) {
      // Если изменяется поле "К зачислению", рассчитываем исходя из него, иначе из "Внесено"
      const currentEditedValue = depositedAmount || willBeCreditedAmount;
      const currentPos = getComissionPosition(currentActivePos, currentEditedValue);

      if (typeof currentPos.ae !== 'undefined') {
        comData = calculateCommission({
          position: currentPos,
          depositedAmount,
          willBeCreditedAmount,
          isFix,
          rounding,
          minPr,
          maxPr,
          min,
          max
        });
      }
    }
  } else if (typeof pos !== 'undefined' && typeof pos.ae !== 'undefined') {
    const isPosActive = checkProfileDate(pos);

    if (isPosActive) {
      comData = calculateCommission({
        position: pos,
        depositedAmount,
        willBeCreditedAmount,
        isFix,
        rounding,
        minPr,
        maxPr,
        min,
        max
      });
    }
  }

  return comData;
};

export const getCounterAmountFromLS = () => {
  const counterDataStr = window.localStorage.getItem('counterAmount');

  if (counterDataStr !== null) {
    return JSON.parse(counterDataStr);
  }

  return {
    card: 0,
    cash: 0
  };
};

export const incrementCounterValueInLS = (field, amount) => {
  const savedAmount = getCounterAmountFromLS()[field.toString()];
  const result = Number(savedAmount) + Number(amount);

  setCounterValue(field, result);

  return result;
};

export const resetCounterValueInLS = () => {
  setCounterValue('cash', 0);
  setCounterValue('card', 0);
};

const setCounterValue = (field, value) => {
  const counterData = getCounterAmountFromLS();
  const data = {
    ...counterData,
    [field]: value
  };

  window.localStorage.setItem('counterAmount', JSON.stringify(data));
};

const getComissionPosition = (currentActivePositions, value) => {
  const currentPosition = currentActivePositions.filter(({ ab, ae }) =>
    (value >= Number(ab) && ae > 0 && value <= Number(ae)) ||
    (ab === 0 && ae === 0) ||
    (ae === 0 && value >= ab)
  );

  if (currentPosition.length) {
    return currentPosition[0];
  }

  return {};
};

const checkProfileDate = (pos) => {
  const { db, de } = pos;
  const timeFrom = Number(db.toString().split('19000101')[1]);
  const timeTo = Number(de.toString().split('19000101')[1]);
  const currentTime = Number(moment().format('hhmmss'));

  if ((!timeFrom && !timeTo) ||
    (timeFrom > 0 && !timeTo && currentTime >= timeFrom) ||
    (!timeFrom && timeTo > 0 && currentTime <= timeTo) ||
    (timeFrom > 0 && timeTo > 0 && currentTime <= timeTo && currentTime >= timeFrom)) {
    return true;
  }

  return false;
};

// ab - сумма для начала расчета
// ae - для конца расчета
// am - фиксированная сумма комиссии
// pr - процент
// rounding - округление 'floor', 'round', 'ceil'. Если round, то смотрим на minPr и maxPr
const calculateCommission = ({
  position,
  depositedAmount,
  willBeCreditedAmount,
  isFix,
  rounding,
  minPr,
  maxPr,
  min, // мин сумма комиссии
  max, // макс сумма комиссии
}) => {
  const { am, pr, minA, maxA } = position;
  const commissionValue = (!Number(am) && Number(pr)) ? `${pr} %` : `${am} тг`;

  let deposited = depositedAmount;
  let commissionSum = am; // по дефолту

  if (willBeCreditedAmount) {
    deposited = Number(willBeCreditedAmount) + Number(commissionSum);
  }

  if (!Number(am) && Number(pr)) {
    commissionSum = getCommissionSum({
      willBeCreditedAmount,
      depositedAmount,
      pr,
      rounding,
      minPr,
      maxPr
    });
  }

  // Округление вниз при условии что "Внесено" дробное
  commissionSum = isInt(deposited) ? commissionSum : getRoundingForNumber(commissionSum, rounding);

  // Если "К зачислению" целое, а комиссия дробная, то округляем комиссию  вниз,
  const totalComSum = roundTotalComSum({ isFix, commissionSum, rounding });

  // проверяем на мин и макс суммы из настроек сервиса
  if (min && totalComSum < min) {
    return { commissionSum: min, commissionValue };
  }

  if (max && totalComSum > max) {
    return { commissionSum: max, commissionValue };
  }

  // проверяем на мин и макс суммы из профиля
  if (minA && totalComSum < minA) {
    return { commissionSum: minA, commissionValue };
  }

  if (maxA && totalComSum > maxA) {
    return { commissionSum: maxA, commissionValue };
  }

  return { commissionSum: totalComSum, commissionValue };
};

const roundTotalComSum = ({ isFix, commissionSum, rounding }) =>
  (isFix && !isInt(commissionSum)) ?
    parseFloat(commissionSum).toFixed(2) :
    getRoundingForNumber(commissionSum, rounding);

const getCommissionSum = ({
  willBeCreditedAmount,
  depositedAmount,
  pr,
  rounding,
  minPr,
  maxPr
}) => {
  if (willBeCreditedAmount) {
    return getTheInversePercentageOfANumber(
      willBeCreditedAmount,
      pr,
      rounding,
      minPr,
      maxPr
    );
  }

  return getThePercentageOfANumber(depositedAmount, pr);
};

const getRoundingForNumber = (val, rounding) => {
  if (rounding === 'ceil') {
    return Math.ceil(val);
  }

  return Math.floor(val); // так было изначально, все округлялось вниз
};

// считаем обратный процент
const getTheInversePercentageOfANumber = (value, pr, rounding, minPr, maxPr) => {
  const deposited = Number(value) / (1 - (Number(pr) / 100));

  if (rounding === 'floor') {
    return Math.floor(deposited - value);
  }

  if (rounding === 'ceil') {
    return Math.ceil(deposited - value);
  }

  if (rounding === 'round' && minPr && maxPr) {
    const com = deposited - value;
    const depositedForMinPr = Number(value) / (1 - (Number(minPr) / 100));
    const depositedForMaxPr = Number(value) / (1 - (Number(maxPr) / 100));
    const minCom = depositedForMinPr - value;
    const maxCom = depositedForMaxPr - value;

    if (minPr !== maxPr) {
      if (com < minCom) {
        return Math.ceil(depositedForMinPr - value);
      }

      if (com > maxCom) {
        return Math.floor(depositedForMaxPr - value);
      }
    }
  }

  return Math.round(deposited - value);
};

//  процент числа
const getThePercentageOfANumber = (value, pr) => (Number(value) * Number(pr)) / 100;

export const adjustTheAmount = (value) => {
  // допускаем только одну точку в сумме
  const val = value.toString().replace(/\s/g, '');
  const splitted = val.split('.');
  let result = '';

  if (splitted.length > 2) {
    result = Number(val.replace(/^(([^.]*?\.[^.]*?)\.|([^.]*?))\./g, '$1$2'));
  } else if (!Number(val)) {
    result = 0;
  } else if (isInt(val)) {
    result = Number(val);
  } else {
    result = parseFloat(val).toFixed(2);
  }

  return result;
};

export const checkAmountOnNumbersAndDot = (value) =>
  isFloatNumberWithDot(value) ? value : value.slice(0, -1);

export const isFloatNumberWithDot = (value) => {
  // eslint-disable-next-line
  const regex = new RegExp(/^\d{0,10}(\.\d{0,2})?$/);

  return regex.test(value);
};

export const isFloatAmount = value => value && value % 1 !== 0;

export const getDepositedAmount = (willBeCreditedAmount, commissionSum) => {
  const depositedAmount = Number(willBeCreditedAmount) + Number(commissionSum);

  return (depositedAmount > 0) ? depositedAmount : 0;
};

export const getWillBeCreditedAmount = (depositedAmount, commissionSum) => {
  const value = Number(depositedAmount) - Number(commissionSum);

  return (value > 0) ? value : 0;
};

const isAmountGreaterThanZero = (depositedAmount, willBeCreditedAmount) => {
  if (Number(depositedAmount) && Number(willBeCreditedAmount)) {
    return '';
  }

  return 'Проверьте сумму оплаты, она не может быть равна нулю';
};

const isAmountGreaterThanCommission = (depositedAmount, commissionSum) => {
  if (commissionSum < Number(depositedAmount)) {
    return '';
  }

  return 'Сумма к зачислению должна быть больше суммы комисиии';
};

const isSumBetweenMinAndMax = (depositedAmount, minAmount, maxAmount) => {
  const hasMinAmount = minAmount > 0;
  const hasMaxAmount = maxAmount > 0;

  if (!hasMaxAmount && !hasMinAmount) {
    return '';
  }

  if (hasMaxAmount && hasMinAmount) {
    if (Number(depositedAmount) >= minAmount && Number(depositedAmount) <= maxAmount) {
      return '';
    }
  }

  if (hasMinAmount && !hasMaxAmount && Number(depositedAmount) >= minAmount) {
    return '';
  }

  if (!hasMinAmount && hasMaxAmount && Number(depositedAmount) <= maxAmount) {
    return '';
  }

  return 'Сумма к оплате не входит в диапазон минимальной и максимальной суммы';
};

export const paymentDataIsValid = ({
  depositedAmount,
  willBeCreditedAmount,
  commissionSum,
  minAmount,
  maxAmount
}) => {
  const amountError = isAmountGreaterThanZero(depositedAmount, willBeCreditedAmount);
  const commissionError = isAmountGreaterThanCommission(depositedAmount, commissionSum);
  const boundaryError = isSumBetweenMinAndMax(depositedAmount, minAmount, maxAmount);

  if (amountError) return amountError;
  if (commissionError) return commissionError;
  if (boundaryError) return boundaryError;

  return '';
};

export const checkCertInfo = () => {
  let idTerminal = '0';
  let hasCashout = '0';
  let expiredCertDate = '';

  if (typeof window.idTerminal !== 'undefined' && window.idTerminal !== '{{idTerminal}}') {
    idTerminal = window.idTerminal;
  }

  if (typeof window.hasCashout !== 'undefined' && window.hasCashout !== '{{hasCashout}}') {
    hasCashout = window.hasCashout;
  }

  if (typeof window.expiredCertDate !== 'undefined' && window.expiredCertDate !== '{{expiredCertDate}}') {
    expiredCertDate = window.expiredCertDate;
  }

  window.localStorage.setItem('hasCashout', hasCashout);
  window.localStorage.setItem('expiredCertDate', expiredCertDate);

  return { idTerminal, hasCashout, expiredCertDate };
};

export const getTotalPaymentAmount = (amount) => {
  if (Number.isInteger(amount)) {
    return amount;
  }

  return parseFloat(amount).toFixed(2);
};

export const getServiceName = (nameService, otherIdServiceForMakePay) => {
  let name = nameService;

  if (otherIdServiceForMakePay === KASPI_CREDIT.SERVICE_ID) {
    name = KASPI_CREDIT.SERVICE_INFO.name;
  }

  if (otherIdServiceForMakePay === KASPI_GOLD.SERVICE_ID) {
    name = KASPI_GOLD.SERVICE_INFO.name;
  }

  return checkUnicodeSymbols(name);
};

export const iinIsValidOrEmptyFunc = (clientIin, showError) => {
  if (!clientIin || clientIin.length === 12) {
    return true;
  }

  showError(
    'Проверьте введенный ИИН и повторите попытку',
    'ИИН заполнен неверно'
  );

  return false;
};

export const checkMinAndMaxParamsForDynamic1 = ({
  minAmount,
  maxAmount,
  isDynamic1Scheme,
  min,
  max
}) => {
  let totalMin = minAmount;
  let totalMax = maxAmount;

  if (isDynamic1Scheme) {
    if (min > 0) {
      totalMin = (minAmount < min) ? minAmount : min;
    }
    if (max > 0) {
      totalMax = (maxAmount < max) ? maxAmount : max;
    }
  }

  return { totalMin, totalMax };
};

export const isPayByCardEnabled = () => {
  const { cashlessDisabled } = getTerminalInfo();

  if (cashlessDisabled === CARD_PAYMENT_METHOD.ENABLED) return true;
  if (cashlessDisabled === CARD_PAYMENT_METHOD.DISABLED) return false;
};
