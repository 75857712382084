import PayTypes from 'paytypes';
import { replace } from 'react-router-redux';
import md5 from 'js-md5';

import { sendXmlReq } from 'helpers/api';
import { checkJwtToken } from 'helpers/auth';
import { showError } from 'actions/alert';
import { setAuthData } from 'model/UserDb';
import { getCheckSettings, } from 'actions/check';
import { isPresent, logErrorToSentry } from 'helpers/main';
import { getNotifications } from 'actions/notification';
import { getFiscalAuthDataForCashierAuth } from 'actions/fiscal';
import { MAIN_PAGE_URL } from 'constants/all';
import { sendInfoLogToGateway } from './logs';

const { startLoader, stopLoader } = PayTypes.actions.handlers;

// Авторизация
export const auth = ({ idTerminal, numUser, password, phone, authCashier }) => (dispatch) => {
  dispatch(startLoader());

  const body =
    `<idt>
      <idTerminal>${idTerminal}</idTerminal>
      <passmd5>${md5(password)}</passmd5>
      <numUser>${numUser}</numUser>
    </idt>`;

  sendXmlReq(1, body)
    .then(({ body: response }) => {
      dispatch(stopLoader());

      if (isPresent(response, 'idt')) {
        const { guid, refreshToken } = response.idt;

        try {
          // Проверяем jwt на валидность
          const { isTokenValid, err } = checkJwtToken(guid);

          if (isTokenValid) {
            // auth in fiscal if auth from cashier
            const authCashierProps = {
              authCashier,
              idTerminal,
              guid,
              numUser,
              refreshToken,
              phone,
              password
            };

            dispatch(checkAuthCashier(authCashierProps));
          } else {
            dispatch(showAuthError(err));
          }
        } catch (e) {
          sendXmlRequestAuthCatch(e);
        }

        return guid;
      }

      throw new Error('Сервер временно недоступен. Повторите попытку позже.');
    })
    .catch(err => {
      dispatch(stopLoader());
      dispatch(showError(err.message));
    });
};

const checkAuthCashier = (props) => (dispatch) => {
  const {
    idTerminal,
    guid,
    numUser,
    refreshToken,
    authCashier,
    phone,
    password
  } = props;

  if (authCashier) {
    setAuthData({
      idTerminal: idTerminal.toString(),
      guid,
      numUser,
      refreshToken,
      authCashier
    });

    dispatch(getFiscalAuthDataForCashierAuth({ login: phone, pass: password }))
      .then((resFiscalAuth) => {
        if (resFiscalAuth?.data?.Status === 200) {
          dispatch(authenticate({
            idTerminal: idTerminal.toString(),
            guid,
            numUser,
            refreshToken,
            authCashier
          }));
        }
      });
  } else {
    dispatch(authenticate({
      idTerminal: idTerminal.toString(),
      guid,
      numUser,
      refreshToken,
    }));
  }
};

const sendXmlRequestAuthCatch = (e) => {
  if (typeof e === 'object') {
    console.log('e', e);
    logErrorToSentry(e.message, 'TOKEN_VALIDATE', 1, 'TOKEN_VALIDATE_2');
  } else if (typeof e === 'string') {
    logErrorToSentry(e, 'TOKEN_VALIDATE', 1, 'TOKEN_VALIDATE_3');
  }
};

export const authenticate = ({
  idTerminal,
  numUser,
  guid,
  refreshToken,
  authCashier,
}) => (dispatch) => {
  setAuthData({
    idTerminal: idTerminal.toString(),
    guid,
    numUser,
    refreshToken,
    authCashier
  });

  dispatch(sendInfoLogToGateway({
    message: `idTerminal: ${idTerminal}, numUser: ${numUser}, authCashier: ${authCashier}, userAgent: ${navigator.userAgent}`,
    reqType: 'authRequest'
  }));

  // get check settings
  dispatch(getCheckSettings());

  // get cashier notifications
  dispatch(getNotifications());

  dispatch(replace(MAIN_PAGE_URL));
};

export const showAuthError = (err) => (dispatch) => {
  if (err.name === 'TokenExpiredError') {
    dispatch(showError('Проверьте часовой пояс, дату и время на своем ПК, они должны быть актуальны', 'Ошибка авторизации'));
  } else {
    dispatch(showError('Обратитесь в ТП', 'Ошибка авторизации'));
  }

  logErrorToSentry(err.message, 'TOKEN_VALIDATE', 1, 'TOKEN_VALIDATE_1');
};

