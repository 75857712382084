import { getAuthData } from 'model/UserDb';
import { doFiscalOperation } from 'actions/fiscal';
import { saveCheck } from 'actions/check';

import { getFromLocalStorage, setToLocalStorage } from 'helpers/main';
import { showError } from 'actions/alert';
import moment from 'moment';
import { ENCASHMENT_OPERATION_BLOCK_TIME } from 'constants/all';
import * as types from './types';

export const setEncashmentSendedToAp = () => (dispatch) => {
  dispatch({ type: types.SET_ENCASHMENT_SENDED_TO_AP });
};

export const setEncashmentSendedToFiscal = () => (dispatch) => {
  dispatch({ type: types.SET_ENCASHMENT_SENDED_TO_FISCAL });
};

export const initEncashmentData = () => (dispatch) => {
  dispatch({ type: types.INIT_ENCASHMENT_DATA });
};

export const initEncashmentStatuses = () => (dispatch) => {
  dispatch({ type: types.INIT_ENCASHMENT_STATUSES });
};

// Сделать инкассацию
export const doEncashment = ({
  SN,
  totalAmount,
  currentDate,
  checkData,
  isFiscal
}) => (dispatch) => {
  const { numUser } = getAuthData();
  const lastEncashmentTime = getFromLocalStorage('lastEncashmentTime');
  const lastEncashmentAmount = getFromLocalStorage('lastEncashmentAmount');

  const dateDiff = moment().diff(lastEncashmentTime);
  const diffDuration = moment.duration(dateDiff);

  if (
    diffDuration.minutes() <= ENCASHMENT_OPERATION_BLOCK_TIME &&
    lastEncashmentTime &&
    lastEncashmentAmount === String(totalAmount)
  ) {
    dispatch(showError('Инкассация находится в обработке, попробуйте выполнить запрос позднее', ''));
  } else {
    setToLocalStorage('lastEncashmentTime', moment());
    setToLocalStorage('lastEncashmentAmount', String(totalAmount));

    const xml = `<cash>
        <cashOut>
          <banknot>
            <nominal>1</nominal>
            <amount>${totalAmount}</amount>
          </banknot>
          <numTrans>${SN}</numTrans>
          <numUser>${numUser}</numUser>
          <dateCollection>${currentDate}</dateCollection>
          <currency>KZT</currency>
        </cashOut>
      </cash>`;

    const successCallback = (data) => {
      const { cash } = data;

      if (typeof cash !== 'undefined' && typeof cash.cashOut !== 'undefined') {
        const { status } = cash.cashOut;

        if (status === 0) {
        // Проинкассировалось!
          if (isFiscal) {
            dispatch(doEncashmentFiscal({ SN, totalAmount }));
          }
          dispatch(saveCheck({
            checkData,
            sn: SN,
            showPrintWindowAfterSave: false,
          }));
          dispatch(setEncashmentSendedToAp());
          dispatch(initEncashmentData());
        }
      }
    };

    dispatch({
      type: `${types.DO_ENCASHMENT}_XML_REQUEST`,
      payload: { reqType: 7, xml, successCallback }
    });
  }
};

export const doEncashmentFiscal = ({ SN, totalAmount }) => (dispatch) => {
  const reqData = {
    Amount: Number(totalAmount)
  };

  dispatch(doFiscalOperation({
    reqData,
    typeOperation: 'expenses',
    showCheckAfter: false,
    successCallback: successCallbackForEncashmentFiscal,
    SN,
  }));
};

const successCallbackForEncashmentFiscal = () => (dispatch) => {
  dispatch(setEncashmentSendedToFiscal());
  setToLocalStorage('encashmentIsLoading', false);
};

export const setEncashmentData = (amount) => (dispatch) => {
  dispatch({ type: types.SET_ENCASHMENT_AMOUNT, amount });
};

export const setEncashmentList = (encashmentList) => (dispatch) => {
  dispatch({ type: types.SET_ENCASHMENT_LIST, encashmentList });
};

// Список инкассаций
export const getEncashmentList = (dateB, dateE) => (dispatch) => {
  const json = {
    dateB,
    dateE
  };

  const successCallback = (data) => {
    const { collections } = data;

    if (typeof collections !== 'undefined') {
      dispatch(setEncashmentList(collections.reverse()));
    }
  };

  dispatch({
    type: `${types.GET_ENCASHMENT_LIST}_JSON_REQUEST`,
    payload: { reqType: 16, json, successCallback }
  });
};

// Ping терминала, в ответе получим сумму для инкассации покупюрно
export const pingTerminal = (latitude, longitude) => (dispatch) => {
  const ping = `0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;${latitude};${longitude};0;0;0`;
  const xml =
    `<ping>${ping}</ping>`;

  const successCallback = (data) => {
    const { nmnls } = data;

    if (typeof nmnls !== 'undefined') {
      const banknots = (nmnls === '') ? [] : [].concat(nmnls.nmnl);

      let amount = 0;

      if (banknots.length) {
        banknots.forEach(item => {
          amount += item['@attributes'].val * item.content;
        });
      }

      dispatch({ type: types.SET_ENCASHMENT_AMOUNT, amount });
    }
  };

  dispatch({
    type: `${types.SET_ENCASHMENT_AMOUNT}_XML_REQUEST`,
    payload: { reqType: 22, xml, successCallback }
  });
};
