const initData = {
  command: '',
};

export const setWebsocketCommand = (command) => {
  window.localStorage.setItem('ws', JSON.stringify({ command }));
};

export const getWebsocketCommand = () => {
  if (window.localStorage.getItem('ws') !== null) {
    return JSON.parse(window.localStorage.getItem('ws')).command;
  }

  return initData.command;
};
