import jwt from 'jsonwebtoken';

import PUBLIC_JWT_KEY from 'constants/key';

export const checkJwtToken = (token) =>
  jwt.verify(token, PUBLIC_JWT_KEY, (err, decoded) => {
    if (err === null && typeof decoded !== 'undefined') {
      return { isTokenValid: true };
    }

    return { isTokenValid: false, err };
  });

export default checkJwtToken;
