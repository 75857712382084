/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { getKtjSn } from 'components/PayTypes/KTJ/helper';
import { useLoader } from 'hooks';

/* отправляем КТЖ чеки в АПИ, когда платеж успешно обработан */

const useKtjCheck = (receipts, saveCheck,) => {
  const { runTheLoader, stopTheLoader } = useLoader();

  useEffect(
    () => {
      const ktjSn = getKtjSn();

      if (ktjSn) {
        if (typeof receipts[ktjSn.toString()] !== 'undefined') {
          runTheLoader();
          runTheLoader();

          const isPaymentSuccess = receipts[ktjSn.toString()].isPaymentSuccess;

          if (isPaymentSuccess) {
            // отсылаем чеки в АПИ с последующей печатью
            saveCheck({
              checkData: receipts[ktjSn.toString()].checks,
              showPrintWindowAfterSave: true,
              sn: ktjSn,
              isKtj: true
            });
          }
        }
      } else {
        stopTheLoader();
        stopTheLoader();
      }
    },
    [receipts]
  );
};

export default useKtjCheck;
