import React from 'react';
import { connect } from 'react-redux';
import PayTypes from 'paytypes';
import Steps from 'components/UI/Steps/steps';
import { STEPS } from 'constants/ktj';
import { showError } from 'actions/alert';
import { bindActionCreators } from 'redux';

const { KtjTrainsTemplate } = PayTypes.templates;

/* Пример: 3@@2708000@2700000@17.02.2018@10:15@MDQz0KY= */

class Trains extends KtjTrainsTemplate {
  state = {
    selectedTrain: '',
    departureTime: ''
  };

  componentDidMount() {
    this.props.setOnOkCallback(() => this.validateAndSelectTrain());
  }

  validateAndSelectTrain = () => {
    const { departureTime, selectedTrain } = this.state;

    if (departureTime && selectedTrain) {
      this.selectTrain(departureTime, selectedTrain);
    } else {
      this.props.showError(
        'Выберите поезд и повторите попытку',
        'Поезд не выбран'
      );
    }
  };

  setSelectedTrain = (departureTime, selectedTrain) => {
    this.setState({ departureTime, selectedTrain });
    this.props.setOnOkCallback(() => this.validateAndSelectTrain());
  }

  renderComponent() {
    const { TrainsList } = this.props;

    return (
      <>
        <Steps current={1} steps={STEPS} />
        {TrainsList.map(({
          '@attributes': { Number: TrainNumber },
          TimeInWay,
          Route: { Station },
          Places: { Cars },
          Departure: { '@attributes': { Time: DepartureTime, Date: DepartureDate } },
          Arrival: { '@attributes': { Time: ArrivalTime, Date: ArrivalDate } },
        }) => (
          <button
            key={TrainNumber}
            className={`buttonWithoutStyles ktj-selectable-block ${TrainNumber === this.state.selectedTrain ? 'ktj-selected-block' : ''}`}
            onClick={() => this.setSelectedTrain(DepartureTime, TrainNumber)}
            type="button"
            tabIndex={0}
          >
            <div className="ktj-train-header">
              <div>
                {TrainNumber}
              </div>
              <div>
                {Station[0]} - {Station[1]}
              </div>
              <div>
                <b>Отправление</b><br />
                {DepartureTime} <br />
                {DepartureDate}
              </div>
              <div>
                в пути {TimeInWay}<br />
              </div>
              <div>
                <b>Прибытие</b><br />
                {ArrivalTime} <br />
                {ArrivalDate}
              </div>
            </div>
            <div className="text-center ktj-wagons">
              {[].concat(Cars).map(({ '@attributes': { Type, FreeSeats, }, Tariffs: { Tariff } }) => {
                const tariff = [].concat(Tariff)[0].Tariff;

                return (
                  <div key={Type} className="ktj-place-info">
                    <div>{Type}</div>
                    <div>{FreeSeats}</div>
                    <div>от {tariff} тг</div>
                    <div>Цена на 1 билет с учётом комиссии</div>
                  </div>
                );
              })}
            </div>
          </button>
        )
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  TrainsList: state.payTypeKTJ.Trains,
  selectedTrain: state.payTypeKTJ.selectedTrain,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ showError }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Trains);
