import * as types from './types';

export const runTheLoader = () => (dispatch) => {
  dispatch({ type: types.LOAD_START });
};

export const stopTheLoader = (force) => (dispatch) => {
  dispatch(({ type: types.LOAD_STOP, force }));
};

export const startTheLoaderPayment = () => (dispatch) => {
  dispatch({ type: types.LOAD_PAYMENT_START });
};

export const stopTheLoaderPayment = () => (dispatch) => {
  dispatch({ type: types.LOAD_PAYMENT_STOP });
};
