import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { SENTRY_DSN, VERSION_PO } from 'constants/all';
import { SENTRY_IGNORE_ERRORS } from 'constants/sentry';
import { getFiscalData } from 'helpers/fiscal';

import Root from './root';
import './app.global.css';
import { getAuthData } from './model/UserDb';
import Store, { history } from './store';

const store = Store.configureStore();
const SENTRY_CATEGORY = ['console', 'xhr'];

if (!window.location.href.includes('localhost') && VERSION_PO) {
  Sentry.init({
    normalizeDepth: 10,
    tracesSampleRate: 1.0,
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    })],
    ignoreErrors: SENTRY_IGNORE_ERRORS,
    release: VERSION_PO,
    beforeBreadcrumb: (event, hint) => {
      const breadcrumb = event;

      if (SENTRY_CATEGORY.includes(breadcrumb.category)) return null;

      switch (breadcrumb.category) {
        case 'ui.click':
          if (hint.event.target.innerText) {
            breadcrumb.message = hint.event.target.innerText;
          }
          break;
        case 'ui.input':
          breadcrumb.message += hint.event.target.value;
          break;
        default:
          break;
      }

      return breadcrumb;
    },

  });

  Sentry.configureScope((scope) => {
    const { idTerminal } = getAuthData();
    const { idKkm, uid, date: authDate } = getFiscalData();
    let isFiscal = false;

    if (idKkm) {
      isFiscal = true;
    }

    scope.setUser({ id: idTerminal, idKkm, uid, authDate, isFiscal });
  });
}

ReactDOM.render(
  <Root store={store} history={history} />,
  document.getElementById('root')
);
