import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showError } from 'actions/alert';
import { hideFiscalModal, doFiscalOperation } from 'actions/fiscal';
import PropTypes from 'prop-types';
import Modal from 'components/UI/Modal/modal';
import {
  getFiscalSectionsForOperations,
  getFiscalSectionsWithHidden,
  getFiscalUnitsForOperations
} from 'helpers/fiscal';
import { TERMINALS_WITH_HIDDEN_SECTIONS } from 'constants/fiscal';
import { getAuthData } from 'model/UserDb';
import FiscalOperationReceipt from 'components/Fiscal/FiscalOperationReceipt';

const BuyAndRefund = (props) => {
  const { showModal, modalTitle, operation, cashFunds, articles } = props;
  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);
  const [count, setCount] = useState(1);
  const [section, setSection] = useState(0);
  const [unit, setUnit] = useState(0);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isPriceValid, setIsPriceValid] = useState(true);
  const [isCountValid, setIsCountValid] = useState(true);
  const [isSectionValid, setIsSectionValid] = useState(true);
  const [isUnitValid, setIsUnitValid] = useState(true);
  const sections = getFiscalSectionsForOperations();
  const sectionsWithHidden = getFiscalSectionsWithHidden();
  const { idTerminal } = getAuthData();
  const totalSectionList = TERMINALS_WITH_HIDDEN_SECTIONS.includes(Number(idTerminal))
    ? sectionsWithHidden
    : sections;
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const units = getFiscalUnitsForOperations();

  useEffect(() => {
    if (name) setIsNameValid(true);
    if (price) setIsPriceValid(true);
    if (count) setIsCountValid(true);
    if (section) setIsSectionValid(true);
    if (unit) setIsUnitValid(true);
  }, [name, price, count, section, unit]);

  const clearFormData = () => {
    setName('');
    setPrice(0);
    setCount(1);
    setSection(0);
    setSelectedSection(null);
    setSelectedUnit(null);
  };

  const validateAndBuy = () => {
    if (!name) return setIsNameValid(false);
    if (!price) return setIsPriceValid(false);
    if (!count) return setIsCountValid(false);
    if (!section) return setIsSectionValid(false);
    if (!unit) return setIsUnitValid(false);

    // Для покупки и возврата продажи проверяем хватает ли баланса
    if (cashFunds >= Number(price * count)) {
      const reqData = {
        GenerateCheck: true,
        IdDomain: 1, // Вид деятельности (справочник)
        Cash: price * count, // int Сумма покупки наличными
        NonCash: 0, // int Сумма оплаты безналом
        Positions: [ // Список покупаемых товаров или услуг
          {
            Name: name, // string Наименование позиции
            IdSection: section, // int Идентификатор отдела (секции)
            Price: price, // int Цена позиции
            Qty: count, // int Количество в целых единицах
            Storno: false, // bool позиция была сторнирована (отменена)
            idUnit: unit // int ед.измерения
          }
        ],
        Total: price * count // int Итоговая сумма
      };

      props.doFiscalOperation({ reqData, typeOperation: operation, showCheckAfter: true });
      props.hideFiscalModal();
      clearFormData();
    } else {
      props.showError(
        'Обнаружена нехватка средств в кассе для совершения данной операции. Пожалуйста попробуйте немного позднее',
        'В кассе недостаточно средств'
      );
    }
  };

  const selectArticle = (article) => {
    const { Name, Price, IdSection } = article;

    setName(Name);

    if (Price) {
      setPrice(Price);
    }

    if (IdSection) {
      const selValue = totalSectionList.filter((item) => item.key === Number(IdSection))[0];

      setSection(IdSection);
      if (selValue) {
        setSelectedSection({ value: selValue.key, label: selValue.value });
      }
    }
  };

  const handlerSetSection = (value) => {
    setSection(value);
    if (value) {
      const selValue = totalSectionList.filter((item) => item.key === Number(value))[0];

      setSelectedSection({ value: selValue.key, label: selValue.value });
    } else setSelectedSection(null);
  };

  const handlerSetUnit = (value) => {
    setUnit(value);
    if (value) {
      const selValue = units.filter((item) => item.key === Number(value))[0];

      setSelectedUnit({ value: selValue.key, label: selValue.value });
    } else setSelectedUnit(null);
  };

  return (
    <Modal
      title={modalTitle}
      isOpen={showModal}
      onOk={() => validateAndBuy()}
      onClose={() => props.hideFiscalModal()}
      okText="Оформить чек"
    >
      <FiscalOperationReceipt
        item={{
          value: name,
          onChange: setName,
          isValid: isNameValid,
          dropdownList: articles,
          dropdownCallback: selectArticle }}
        price={{ value: price, isValid: isPriceValid, onChange: setPrice }}
        count={{ value: count, onChange: setCount, isValid: isCountValid }}
        section={{
          options: totalSectionList,
          value: selectedSection,
          onChange: handlerSetSection,
          isValid: isSectionValid
        }}
        units={{
          options: units,
          value: selectedUnit,
          onChange: handlerSetUnit,
          isValid: isUnitValid
        }}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  showModal: state.fiscal.modalData.showModal,
  cashFunds: state.fiscal.balances.cash,
  articles: state.fiscal.articles,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    showError,
    hideFiscalModal,
    doFiscalOperation
  },
  dispatch);

BuyAndRefund.propTypes = {
  showModal: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string.isRequired,
  operation: PropTypes.string.isRequired,
  showError: PropTypes.func.isRequired,
  hideFiscalModal: PropTypes.func.isRequired,
  doFiscalOperation: PropTypes.func.isRequired,
  cashFunds: PropTypes.number.isRequired,
  articles: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyAndRefund);
