import SimpleAndSubmit from 'components/PayTypes/SimpleAndSubmit/Component';
import SimpleAndSubmitWithoutMask from 'components/PayTypes/SimpleAndSubmitWithoutMask/Component';
import Fixed from 'components/PayTypes/Fixed';
import Complex from 'components/PayTypes/Complex';
import ChoiceOfContract from 'components/PayTypes/ChoiceOfContract';
import EnterAccountAndFio from 'components/PayTypes/EnterAccountAndFio';
import ForeignOperators from 'components/PayTypes/ForeignOperators';
import PenaltiesAndTaxes from 'components/PayTypes/PenaltiesAndTaxes';
import KaspiBank from 'components/PayTypes/KaspiBank';
import Currency from 'components/PayTypes/Currency';
import Charity from 'components/PayTypes/Charity';
import Kazaktelecom from 'components/PayTypes/Kazaktelecom';
import EnterCounter from 'components/PayTypes/EnterCounter';
import ChoiceOfThePerson from 'components/PayTypes/ChoiceOfThePerson';
import KostanaySu from 'components/PayTypes/KostanaySu';
import KMF from 'components/PayTypes/KMF';
import Dynamic from 'components/PayTypes/Dynamic';
import Wooppay from 'components/PayTypes/Wooppay';
import Ktj from 'components/PayTypes/KTJ';
import Jysan from 'components/PayTypes/Jysan';

export const PAY_TYPES = {
  0: SimpleAndSubmit,
  1: Complex,
  2: Currency,
  4: ChoiceOfContract,
  5: SimpleAndSubmit,
  9: Charity,
  11: SimpleAndSubmitWithoutMask,
  12: ForeignOperators,
  14: EnterCounter,
  15: Ktj,
  16: Kazaktelecom,
  17: Fixed,
  21: ChoiceOfThePerson,
  24: SimpleAndSubmitWithoutMask,
  25: KostanaySu,
  32: KaspiBank,
  60: EnterAccountAndFio,
  61: Fixed,
  55: KMF,
  58: Jysan,
  67: Wooppay,
  73: PenaltiesAndTaxes,
  75: Dynamic,
  79: Dynamic,
  86: Dynamic, // Динамическая схема с фиксированной суммой
  110: Dynamic
};

export const MYPOST_PAY_TYPE_PAYMENT = 107;
export const MYPOST_PAY_TYPE_DELIVERY = 108;
export const CASH_OUT_PAY_TYPE = 102;
export const KTJ = 15;
export const KASPI_BANK = 32;
export const PAY_SCHEMES = {
  KASPI_BANK: 32,
  KTJ: 15,
  CASH_OUT: 102,
  MYPOST_DELIVERY: 108,
  MYPOST_PAYMENT: 107,
  DYNAMIC1: 75,
  DYNAMIC2: 79,
  DYNAMIC3: 86,
};

/* Используется в онлайн-проверке для отправки ЛС без преобразования к маске */
export const PAY_TYPES_WITHOUT_CONVERT_ACCOUNT = [
  15, // КТЖ
  16, // Казактелеком
  21, // С выбором лица
  32, // Kaspi
  58, // Jysan
  75, // Dynamic1
  79, // Dynamic2
  86, // Dynamic3
  9, // Charity
];

export const INIT_PAY_TYPE = {
  0: 'SIMPLE_AND_SUBMIT_INIT',
  1: 'COMPLEX_INIT',
  2: 'CURRENCY_INIT',
  4: 'CHOICE_CONTRACT_INIT',
  5: 'SIMPLE_AND_SUBMIT_INIT',
  12: 'FOREIGN_OPERATORS_INIT',
  14: 'ENTER_COUNTER_INIT',
  15: 'KTJ_INIT',
  16: 'KAZAKTELECOM_INIT',
  17: 'FIXED_INIT',
  21: 'CHOICE_OF_THE_PERSON_INIT',
  25: 'KOSTANAY_SU_INIT',
  32: 'KASPI_INIT',
  55: 'KMF_INIT',
  58: 'JYSAN_INIT',
  61: 'FIXED_INIT',
  67: 'WOOPPAY_INIT',
  73: 'PENALTIES_AND_TAXES_INIT',
  75: 'DYNAMIC_INIT',
  79: 'DYNAMIC_INIT',
  86: 'DYNAMIC_INIT',
  107: 'MYPOST_INIT',
  108: 'MYPOST_INIT',
  110: 'DYNAMIC_INIT'
};

export const PAY_TYPES_WITHOUT_ENTER_AMOUNT_SCREEN = [
  1, // complex
  2, // Currency
  4, // ChoiceOfContract
  9, // Charity
  14, // enter counter
  16, // kazaktelecom
  17, // fixed
  25, // KostanaySu
  55, // KMF
  61, // fixed
  75, // dynamic 1
  73, // PenaltiesAndTaxes
  79, // dynamic 2
  7963, // АлматыЭнергоСбыт физ лица
];

export const CASHOUT_SCREENS = {
  ENTER_DATA_STEP: 1,
  CHECK_DATA_STEP: 2,
  CASHOUT_CONFIRMED: 3,
  REQUEST_NOT_FOUND_STEP: 4,
};
