import { useState, useEffect } from 'react';
import { checkCertInfo } from 'helpers/pay';
import { useInterval } from 'hooks';

const MAX_RETRY_COUNT = 10;

const useWindow = () => {
  const [hasCertData, setHasCertData] = useState(false);
  const [certData, setCertData] = useState({ idTerminal: 0, hasCashout: 0 });
  const [counter, setCounter] = useState(0);

  const stopInterval = useInterval(() => {
    const { idTerminal, hasCashout, expiredCertDate } = checkCertInfo();

    if (Number(idTerminal)) {
      setHasCertData(true);
    }

    setCertData({ idTerminal, hasCashout, expiredCertDate });
    setCounter((count) => count + 1);
  }, 1000);

  useEffect(
    () => {
      if (counter >= MAX_RETRY_COUNT || hasCertData) {
        stopInterval();
      }
    },
    // eslint-disable-next-line
    [counter, hasCertData]
  );

  return {
    idTerminal: Number(certData.idTerminal),
    hasCashout: Number(certData.hasCashout),
    expiredCertDate: certData.expiredCertDate,
  };
};

export default useWindow;
