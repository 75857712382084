export const SET_KKM_INFO = 'SET_KKM_INFO';
export const SET_ARTICLES = 'SET_ARTICLES';
export const SET_IS_FISCAL = 'SET_IS_FISCAL';
export const SET_KKM_STATUS = 'SET_KKM_STATUS';
export const UPDATE_KKM_INFO = 'UPDATE_KKM_INFO';
export const CLEAR_EPORT_DATA = 'CLEAR_EPORT_DATA';
export const HIDE_FISCAL_MODAL = 'HIDE_FISCAL_MODAL';
export const SET_X_REPORT_DATA = 'SET_X_REPORT_DATA';
export const SET_Z_REPORT_DATA = 'SET_Z_REPORT_DATA';
export const SET_TO_OPEN_SHIFT = 'SET_TO_OPEN_SHIFT';
export const SET_TYPE_OPERATION = 'SET_TYPE_OPERATION';
export const SET_FISCAL_BALANCES = 'SET_FISCAL_BALANCES';
export const GET_FISCAL_AUTH_DATA = 'GET_FISCAL_AUTH_DATA';
export const SET_IS_AUTH_IN_FISCAL = 'SET_IS_AUTH_IN_FISCAL';
export const SET_AUTH_FISCAL_ERROR = 'SET_AUTH_FISCAL_ERROR';
export const SET_FISCAL_SCREEN_TYPE = 'SET_FISCAL_SCREEN_TYPE';
export const SET_SHOW_LICENSE_MODAL = 'SET_SHOW_LICENSE_MODAL';
export const SET_RECEIPT_FOR_REFUND = 'SET_RECEIPT_FOR_REFUND';
export const SET_SHOW_PIN_CODE_MODAL = 'SET_SHOW_PIN_CODE_MODAL';
export const SET_FISCAL_PAYMENT_LIST = 'SET_FISCAL_PAYMENT_LIST';
export const MAKE_FISCAL_PAY_RESPONSE = 'MAKE_FISCAL_PAY_RESPONSE';
export const SET_Z_REPORT_HISTORY_DATA = 'SET_Z_REPORT_HISTORY_DATA';
export const SET_OPENING_SHIFT_CALLBACK = 'SET_OPENING_SHIFT_CALLBACK';
export const SET_AUTH_DATA_IS_REQUESTED = 'SET_AUTH_DATA_IS_REQUESTED';
export const MAKE_FISCAL_PAY_EMPTY_REQUEST = 'MAKE_FISCAL_PAY_EMPTY_REQUEST';
export const MAKE_FISCAL_PAY_TOKEN_EXIST = 'MAKE_FISCAL_PAY_TOKEN_EXIST';
export const SET_FISCAL_X_REPORT_SN_LIST = 'SET_FISCAL_X_REPORT_SN_LIST';
export const SET_SHOW_CONFIRM_CLOSE_SHIFT = 'SET_SHOW_CONFIRM_CLOSE_SHIFT';
export const SET_SHOW_LICENSE_EXPIRED_MODAL = 'SET_SHOW_LICENSE_EXPIRED_MODAL';
export const MAKE_FISCAL_PAY_TOKEN_NOTEXIST = 'MAKE_FISCAL_PAY_TOKEN_NOTEXIST';
export const MAKE_FISCAL_PAY_ERROR_RESPONSE = 'MAKE_FISCAL_PAY_ERROR_RESPONSE';
export const SELECT_FISCAL_SECTION_FOR_PAYMENT = 'SELECT_FISCAL_SECTION_FOR_PAYMENT';
export const SET_WS_CONNECTION_CLOSED_BY_SERVER = 'SET_WS_CONNECTION_CLOSED_BY_SERVER';

