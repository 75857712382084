import React from 'react';
import PayTypes from 'paytypes';
import MaskedInput from 'react-maskedinput';

import { t, checkAccountOnReg } from 'helpers/main';
import IinField from 'components/IinField';
import { SERVICES_STATUSES_WITH_ONLINE_CHECK } from 'constants/services';

const {
  containers: { SimpleAndSubmitContainer },
  templates: { SimpleAndSubmitTemplate }
} = PayTypes;

class SimpleAndSubmit extends SimpleAndSubmitTemplate {
  state = {
    account: '',
    isLoading: false
  };

  componentDidMount() {
    const { account } = this.props.pay;

    if (account) this.handleOnAccountChange(account.toString());

    document.addEventListener('keydown', this.onKeyDownFowWindow);
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props.payTypeSimpleAndSubmit;

    if (prevProps.payTypeSimpleAndSubmit.id !== id && id) {
      this.nextScreen();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDownFowWindow);
  }

  onKeyDownFowWindow = (e) => {
    if (e.key === 'Enter') {
      this.validateAndCheck();
    }
  };

  nextScreen() {
    const { account } = this.state;
    const {
      payTypeSimpleAndSubmit: { fio, debt, address, caption },
      showEnterAmountScreen,
      setConfirmInfo,
    } = this.props;

    const confirmFields = [
      { name: 'account', value: account },
    ];

    if (fio) {
      confirmFields.push({ name: 'fio', value: fio });
    }

    if (debt) {
      confirmFields.push({ name: 'debt', value: debt });
    }

    if (address) {
      const name = caption ? 'address' : address.split(':')[0];
      const value = caption ? address : address.split(':')[1];

      confirmFields.push({ name, value });
    }

    const checkInfo = {
      input: {
        account,
      },
      online: {}
    };

    if (fio) {
      checkInfo.online.fullName = fio;
    }

    setConfirmInfo(confirmFields, '', checkInfo);
    showEnterAmountScreen();
  }

  handleOnAccountChange = (account) => this.setState({ account });

  validateAndCheck() {
    const {
      onlineCheck,
      showError,
      iinIsValidOrEmpty,
      updateFieldValue,
      kassa: { currentService },
      payTypeSimpleAndSubmit: { id },
    } = this.props;

    const { account } = this.state;

    this.setState({ isLoading: false });

    if (!iinIsValidOrEmpty()) return;

    if (account) {
      const { regExp, checkOnline, maskEdit } = currentService;

      if (checkAccountOnReg(account, regExp, maskEdit)) {
        updateFieldValue('account', account);

        if (id && id.toString() === account.toString()) {
          return this.nextScreen();
        }

        if (SERVICES_STATUSES_WITH_ONLINE_CHECK.includes(checkOnline)) {
          this.setState({ isLoading: true });

          return onlineCheck(currentService, account)
            .then(() => { this.setState({ isLoading: false }); });
        }

        return this.nextScreen();
      }

      return showError(
        'Проверьте, пожалуйста, и повторите попытку',
        'Лицевой счет некорректен'
      );
    }

    return showError(
      'Проверьте, пожалуйста, и повторите попытку',
      'Все поля обязательны для заполнения'
    );
  }

  // Транстелеком (2430009607)
  // Горгаз сервис ТОО (2296007, 2031028, 2101019)
  renderComponent = () => (
    <div className="pay-form">
      <IinField />
      <label htmlFor="account">№ лицевого счета</label>
      <MaskedInput
        mask={'W'.repeat(40)}
        type="tel"
        id="account"
        autoComplete="off"
        maxLength={40}
        placeholder="№ лицевого счета"
        placeholderChar=" "
        onChange={(e) => this.handleOnAccountChange(e.target.value.trim())}
        value={this.state.account}
        formatCharacters={{
          W: {
            validate(char) { return /[\wа-яА-Я \-.@/]/.test(char); },
          },
          w: {
            validate(char) { return /[\wа-яА-Я \-.@/]/.test(char); },
          }
        }}
        disabled={this.state.isLoading}
      />
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => this.validateAndCheck()}
      >
        {t('next')}
      </button>
    </div >
  )
}

export default SimpleAndSubmitContainer(SimpleAndSubmit);
