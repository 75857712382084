/* eslint-disable array-callback-return */
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'components/UI/Select/Select';

const SelectField = ({ placeholder, name, options, handleOnChange }) => {
  const selectOptions = () => {
    const arrayOfOptions = [];

    options.map((item, idx) => {
      const objectKey = Object.keys(item)[0];

      arrayOfOptions.push({ key: objectKey, value: options[Number(idx)][objectKey.toString()] });
    });

    return arrayOfOptions;
  };

  const preHandleChange = (key) => {
    let value = '';

    if (key) value = key;
    handleOnChange({ id: name, value });
  };

  return (
    <div className="form-group" key={name}>
      <Select
        title={placeholder}
        options={selectOptions()}
        onchange={(key) => preHandleChange(key)}
      />
    </div>
  );
};

SelectField.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default SelectField;
