/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { getFormatedJsDate } from 'helpers/main';
import Alert from 'components/UI/Alert/alert';

const MypostParcelInfo = ({ parcelData, parcelAmount, parcelPaidAmount }) => {
  console.log('parcelData', parcelData);

  return (
    <div className="pay-form">
      <h4>Информация о посылке:</h4>
      {(parcelAmount && parcelAmount - parcelPaidAmount) ? (
        <Alert message="Посылка не оплачена, чтобы выдать посылку нужно ее оплатить" banner />
      ) :
        <div />
      }
      {parcelData.map(({ name: fieldName, value }) => {
        const fieldValue = fieldName === 'Дата доставки' ? getFormatedJsDate(value) : value;

        return (
          <span key={fieldName}>
            <b>{fieldName}: </b>
            {(fieldName === 'Стоимость заказа' || fieldName === 'Оплаченная сумма') ? `${fieldValue} тг` : fieldValue}
          </span>
        );
      })}
    </div>
  );
};

MypostParcelInfo.propTypes = {
  parcelAmount: PropTypes.number.isRequired,
  parcelPaidAmount: PropTypes.number.isRequired,
  parcelData: PropTypes.array.isRequired,
};

export default MypostParcelInfo;

