import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getKtjSn } from 'components/PayTypes/KTJ/helper';
import './loader.scss';

class Loader extends Component {
  render() {
    const { loaderCounter, checksWithCode } = this.props;
    const isItCheckWithCode = Object.keys(checksWithCode).length;

    if (loaderCounter) {
      return (
        <div className="loader-wrapper" >
          <div className="loader-component" />
          <h3>Пожалуйста, подождите</h3>
          <span>
            {isItCheckWithCode || getKtjSn() ?
              'Идет генерация чеков, не обновляйте и не закрывайте страницу' :
              'Ваш запрос обрабатывается'
            }
          </span>
        </div >
      );
    }

    return null;
  }
}

Loader.propTypes = {
  loaderCounter: PropTypes.number.isRequired,
  checksWithCode: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    loaderCounter: state.loader.loaderCounter,
    checksWithCode: state.checksWithCode.checksWithCode,
  };
}

export default connect(mapStateToProps)(Loader);
