import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import IinField from 'components/IinField';
import { showError } from 'actions/alert';
import { onlineCheck } from 'actions/api/onlineCheck';
import { setCurrentService } from 'actions/services';
import { initCurrentComProfile } from 'actions/commission';
import { getClearPhone } from 'helpers/main';
import PropTypes from 'prop-types';
import Modal from 'components/UI/Modal/modal';
import MyPostIcon from 'public/images/mypost.svg';
import {
  setShowMyPostDeliveryModal,
  setShowMyPostPaymentModal,
  clearParcelInfo,
  setPhoneForPayment,
} from 'actions/payTypes/mypost';
import { iinIsValidOrEmptyFunc } from 'helpers/pay';
import { MYPOST_PAYMENT_SERVICE_ID } from 'constants/services';

import DeliveryFormData from './MypostDeliveryFormData';
import ParcelInfo from './MypostParcelInfo';

const ENTER_DATA_STEP = 1;
const SHOW_PARCEL_INFO_STEP = 2;

const MyPostDeliveryParcel = (props) => {
  const [step, setStep] = useState(ENTER_DATA_STEP);
  const [account, setAccount] = useState('');
  const [smsCode, setSmsCode] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isSmsCodeValid, setIsSmsCodeValid] = useState(true);

  useEffect(() => {
    if (account) setIsPhoneValid(true);
    if (smsCode) setIsSmsCodeValid(true);
  }, [account, smsCode]);

  useEffect(() => {
    if (props.parcelData.length) {
      setStep(SHOW_PARCEL_INFO_STEP);
    }
  }, [props.parcelData]);

  const init = () => {
    setStep(ENTER_DATA_STEP);
    setAccount('');
    setSmsCode('');
    props.clearParcelInfo();
    props.setShowMyPostDeliveryModal(false);
  };

  const validateAndGetParcelList = () => {
    const {
      clientIin,
      showError: showErrorFunc
    }
    = props;

    if (!iinIsValidOrEmptyFunc(clientIin, showErrorFunc)) return;
    if (!account) return setIsPhoneValid(false);
    if (!smsCode) return setIsSmsCodeValid(false);

    const checkedPhoneNum = getClearPhone(account);

    if (checkedPhoneNum.length !== 10) {
      return setIsPhoneValid(false);
    }

    // This is intentional: pvz.findPackageDelivery;7055656560;1234
    const accountForOnlineCheck = `pvz.findPackageDelivery;${checkedPhoneNum};${smsCode}`;

    props.onlineCheck(props.currentService, accountForOnlineCheck);
  };

  const getOnBackCallback = () => {
    if (step === ENTER_DATA_STEP) {
      return () => {
        init();
      };
    }

    return () => {
      setStep(ENTER_DATA_STEP);
    };
  };

  const getOnOkCallback = () => {
    switch (step) {
      case ENTER_DATA_STEP: return () => validateAndGetParcelList();
      case SHOW_PARCEL_INFO_STEP: return () => {
        // если посылка не оплачена
        if (props.parcelAmount - props.parcelPaidAmount) {
          return goToPayForParcel();
        }

        return deliveryParcel();
      };
      default: return () => {
        // This is intentional
      };
    }
  };

  const getOkText = () => {
    if (step === SHOW_PARCEL_INFO_STEP) {
      // если посылка не оплачена
      if (props.parcelAmount - props.parcelPaidAmount) {
        return 'Перейти к оплате посылки';
      }

      return 'Выдать посылку';
    }

    return 'Найти посылки';
  };

  const getCancelText = () => {
    if (step === SHOW_PARCEL_INFO_STEP) {
      return 'Назад';
    }

    return 'Закрыть';
  };

  const deliveryParcel = () => {
    if (props.idInvoice && props.idPackage) {
      // pvz.receivePackage4Customer;604217;631207
      // Идентификатор заказа(idInvoice) и Идентификатор посылки(idPackage)
      const accountForOnlineCheck = `pvz.receivePackage4Customer;${props.idInvoice};${props.idPackage}`;

      props.onlineCheck(props.currentService, accountForOnlineCheck);

      init();
    } else {
      props.showError(
        'У данной посылки не указан номер лицевого счета',
        'Невозможно произвести выдачу'
      );
    }
  };

  const goToPayForParcel = () => {
    const service = getServiceOnId(MYPOST_PAYMENT_SERVICE_ID);

    if (typeof service.idService !== 'undefined') {
      props.setCurrentService(service);
      props.setPhoneForPayment(`+7${getClearPhone(account)}`);
      props.setShowMyPostPaymentModal(true);
      init();
    } else {
      props.showError(
        'Подайте сервис на Ваш терминал в кабинете',
        'Не удалось найти сервис "Оплата посылки Mypost"'
      );
    }
  };

  const getServiceOnId = (idService) => {
    const service = props.serviceList.filter(item => (item.idService === idService));

    if (service.length) return service[0];

    return {};
  };

  return (
    <Modal
      size="small-and-high"
      title="Выдача посылки"
      isOpen
      onBack={getOnBackCallback()}
      onOk={getOnOkCallback()} // отобразится только у 2 и 3 экрана
      onClose={init}
      cancelText={getCancelText()}
      okText={getOkText()}
    >
      <div className="cashout-modal-head">
        <div>
          <img src={MyPostIcon} alt="Оплата за посылку" className="cashout-modal-icon"/>
        </div>
        <div>Выдача посылки</div>
      </div>
      <div className="fiscal-modal-section mypost-modal-body">
        {step === ENTER_DATA_STEP && (
          <>
            <IinField/>
            <DeliveryFormData
              account={account}
              smsCode={smsCode}
              setAccount={setAccount}
              setSmsCode={setSmsCode}
              isPhoneValid={isPhoneValid}
              isSmsCodeValid={isSmsCodeValid}
            />
          </>
        )}

        {step === SHOW_PARCEL_INFO_STEP && (
          <ParcelInfo
            parcelData={props.parcelData}
            parcelAmount={props.parcelAmount}
            parcelPaidAmount={props.parcelPaidAmount}
          />
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  id: state.mypost.id,
  serviceList: state.services.serviceList,
  currentService: state.kassa.currentService,
  idInvoice: state.mypost.parcelInfo.idInvoice,
  idPackage: state.mypost.parcelInfo.idPackage,
  parcelData: state.mypost.parcelInfo.parcelData,
  parcelAmount: state.mypost.parcelInfo.parcelAmount,
  parcelPaidAmount: state.mypost.parcelInfo.parcelPaidAmount,
  currentComProfile: state.commission.currentComProfile,
  clientIin: state.payment.clientIin
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    showError,
    onlineCheck,
    clearParcelInfo,
    setCurrentService,
    setPhoneForPayment,
    setShowMyPostPaymentModal,
    setShowMyPostDeliveryModal,
    initCurrentComProfile,
  },
  dispatch);

MyPostDeliveryParcel.propTypes = {
  idInvoice: PropTypes.number.isRequired,
  idPackage: PropTypes.number.isRequired,
  parcelData: PropTypes.array.isRequired,
  serviceList: PropTypes.array.isRequired,
  parcelAmount: PropTypes.number.isRequired,
  parcelPaidAmount: PropTypes.number.isRequired,
  currentService: PropTypes.object.isRequired,
  showError: PropTypes.func.isRequired,
  onlineCheck: PropTypes.func.isRequired,
  clearParcelInfo: PropTypes.func.isRequired,
  setCurrentService: PropTypes.func.isRequired,
  setPhoneForPayment: PropTypes.func.isRequired,
  setShowMyPostPaymentModal: PropTypes.func.isRequired,
  setShowMyPostDeliveryModal: PropTypes.func.isRequired,
  clientIin: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPostDeliveryParcel);
