import { setHistoryParams } from 'model/Params';
import moment from 'moment';
import * as types from './types';

export const saveSearchingDates = (dateFrom, dateTo) => (dispatch) => {
  dispatch({
    type: types.PAYMENT_HISTORY_SEARCH_DATES,
    startDate: dateFrom,
    endDate: dateTo
  });
};

// Поиск платежей
export const searchPayments = (dateFrom, dateTo) => (dispatch) => {
  const xml =
    `<findPay>
      <dateB>${dateFrom}</dateB>
      <dateE>${dateTo}</dateE>
    </findPay>`;

  const successCallback = (data) => {
    // Записываем параметры поиска в локал сторадж
    // Используется для перезапрашивания истории при изменении статуса платежа
    setHistoryParams({ dateB: dateFrom, dateE: dateTo });

    const { findPay } = data;
    const paymentsHistory =
      typeof findPay === 'undefined' || typeof findPay.pay === 'undefined' ?
        [] :
        [].concat(findPay.pay);
    const snListForTodayPayments = [];
    const today = moment().format('YYYYMMDD');

    paymentsHistory.forEach((pay) => {
      if (String(pay.date).includes(today)) {
        snListForTodayPayments.push(pay);
      }
    });

    dispatch({ type: types.PAYMENT_HISTORY_WITH_ERROR, status: false });
    dispatch({ type: types.SET_PAYMENTS_HISTORY, paymentsHistory });
    dispatch({ type: types.SET_PAYMENT_HISTORY_SN_LIST, snListForTodayPayments });
  };

  dispatch({
    type: `${types.SET_PAYMENTS_HISTORY}_XML_REQUEST`,
    payload: { reqType: 32, xml, successCallback }
  });
};
