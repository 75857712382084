import React from 'react';
import MaskedInput from 'react-maskedinput';
import PayTypes from 'paytypes';

import IinField from 'components/IinField';
import { t, getMaskForInput, checkAccountOnReg } from 'helpers/main';

const {
  containers: { ChoiceOfThePersonContainer },
  templates: { ChoiceOfThePersonTemplate }
} = PayTypes;

class ChoiceOfThePerson extends ChoiceOfThePersonTemplate {
  state = {
    account: '',
    isLoading: false
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDownFowWindow);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDownFowWindow);
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props.payTypeChoiceOfThePerson;

    if (prevProps.payTypeChoiceOfThePerson.id !== id && id !== '') {
      this.confirm();
    }
  }

  onKeyDownFowWindow = (e) => {
    if (e.key === 'Enter') {
      if (this.props.payTypeChoiceOfThePerson.typePerson !== 2) {
        this.validateAndCheck();
      }
    }
  };

  confirm() {
    const {
      payTypeChoiceOfThePerson: { name, address },
      pay: { account },
      setConfirmInfo,
      showEnterAmountScreen
    } = this.props;

    const confirmFields = [
      { name: 'account', value: account },
      { name: 'address', value: address },
      { name: 'name', value: name },
    ];

    const checkInfo = {
      input: {
        account,
      }
    };

    setConfirmInfo(confirmFields, this.getAddings(), checkInfo);
    showEnterAmountScreen();
  }

  handleOnChange(e) {
    this.setState({
      [e.target.id]: e.target.value.trim()
    });
  }

  validateAndCheck() {
    const {
      kassa: { currentService },
      payTypeChoiceOfThePerson: { typePerson, id },
      onlineCheck,
      showError,
      iinIsValidOrEmpty,
      updateFieldValue
    } = this.props;

    this.setState({ isLoading: false });
    const { account } = this.state;

    if (!iinIsValidOrEmpty()) return;
    if (account) {
      const { regExp, maskEdit } = currentService;

      if (checkAccountOnReg(account, regExp, maskEdit)) {
        updateFieldValue('account', account);

        const lastAccount = id.toString().split('@')[0];

        if (lastAccount !== '' && lastAccount === account.toString()) {
          return this.confirm();
        }
        this.setState({ isLoading: true });

        return onlineCheck(currentService, `${account}@${typePerson}`)
          .then(() => {
            this.setState({ isLoading: false });
          });
      }

      return showError(
        'Проверьте, пожалуйста, и повторите попытку',
        'Лицевой счет некорректен'
      );
    }

    return showError(
      'Лицевой счет обязателен для заполнения. Заполните, пожалуйста, и повторите попытку.',
      'Все поля обязательны для заполнения'
    );
  }

  /* РСЦ Абай. ЛС: 510081 */
  renderComponent = () => {
    const {
      kassa: { currentService: { maskEdit, typeInterface } },
      setScreenType,
      payTypeChoiceOfThePerson,
      initPayType,
    } = this.props;

    if (payTypeChoiceOfThePerson.typePerson === 2) {
      return (
        <div className="pay-form">
          <div className="form-group">
            <button
              type="button"
              className="btn btn-primary large"
              onClick={() => setScreenType(0)}
            >
              {t('individualPerson')}
            </button>
          </div>
          <div className="form-group">
            <button
              type="button"
              className="btn btn-primary large"
              onClick={() => setScreenType(1)}
            >
              {t('legalPerson')}
            </button>
          </div>
        </div>
      );
    }

    const mask = getMaskForInput(maskEdit);

    return (
      <div className="pay-form">
        <button
          onClick={() => initPayType(typeInterface)}
          style={{ color: '#108ee9', cursor: 'pointer' }}
          tabIndex="0"
          type="button"
          className="pay-form-edit buttonWithoutStyles"
        >
          {t('editData')}
        </button>
        <IinField/>
        <label htmlFor="№ лицевого счета">№ лицевого счета</label>
        <MaskedInput
          mask={mask}
          type="tel"
          id="account"
          autoComplete="off"
          maxLength={40}
          placeholder="№ лицевого счета"
          placeholderChar=" "
          onChange={(e) => this.handleOnChange(e)}
          value={this.state.account}
          formatCharacters={{
            W: {
              validate(char) { return /[\wа-яА-Я]/.test(char); },
            }
          }}
          disabled={this.state.isLoading}
        />

        <div className="form-group">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => this.validateAndCheck()}
          >
            {t('next')}
          </button>
        </div>
      </div>
    );
  }
}

export default ChoiceOfThePersonContainer(ChoiceOfThePerson);
