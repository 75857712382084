/* eslint-disable import/extensions */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Input from 'components/UI/Input/input';
import CustomButton from 'components/UI/Button/button';
import Flatpickr from 'react-flatpickr';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getObjectElement, getPayFormattedDate, getTheEndOfTheCurrentDay, getTheStartDateOfTheCurrentMonth, setObjectElement } from 'helpers/main';
import { getCheckOnSn as getCheckOnSnCurrent } from 'actions/check';
import 'flatpickr/dist/flatpickr.css';
import { Russian } from 'flatpickr/dist/l10n/ru';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import ReactTooltip from 'react-tooltip';
import Icon from 'components/UI/Icon/index';
import { getXReportData as getXReportDataCurrent } from 'actions/fiscal';
import { FISCAL_SCREENS } from 'constants/fiscal';
import Modal from 'components/UI/Modal/modal';
import { getFiscalData } from 'helpers/fiscal';
import * as encashmentActions from 'actions/encashment';

const columns = [
  {
    title: '№',
    dataIndex: 'key',
  },
  {
    title: 'Дата и время',
    dataIndex: 'date',
  },
  {
    title: 'Транзакция',
    dataIndex: 'transaction',
  },
  {
    title: 'Сумма',
    dataIndex: 'amount',
  },
  {
    title: 'Дубликат чека',
    dataIndex: 'receipt',
    align: 'right'
  },
];

const EncashmentList = ({
  isFiscal,
  xReport,
  encashmentList,
  isEncashmentSendedToFiscal,
  getEncashmentList,
  getCheckOnSn,
  getXReportData,
  doEncashmentFiscal,
  initEncashmentStatuses
}) => {
  const [dateFrom, setDateFrom] = useState(getTheStartDateOfTheCurrentMonth());
  const [dateTo, setDateTo] = useState(getTheEndOfTheCurrentDay());
  const [encashmentListArray, setEncashmentListArray] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fiscalEnashmentList, setFiscalEnashmentList] = useState([]);
  const [showFiscalModal, setShowFiscalModal] = useState(false);
  const [transactionNumber, setTransactionNumber] = useState(0);
  const [transactionSumm, setTransactionSumm] = useState(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const dublicatePaysList = [];

  useEffect(() => {
    getEncashmentList(dateFrom, dateTo);
    if (isFiscal) {
      getXReportData({ showLoader: false });
    }
  }, [dateFrom, dateTo, isFiscal, isEncashmentSendedToFiscal]);

  useEffect(() => {
    setEncashmentListArray(encashmentList);
  }, [encashmentList]);

  useEffect(() => {
    setFiscalEncashmentData();
  }, [xReport]);

  useEffect(() => {
    filterEncashmentList();
  }, [searchValue]);

  useEffect(() => {
    if (isEncashmentSendedToFiscal) {
      setShowSuccessModal(true);
    }
  }, [isEncashmentSendedToFiscal]);

  useMemo(() => {
    if (isFiscal && !columns[5]) {
      columns.push({
        title: 'Фискал',
        dataIndex: 'fiscal',
        align: 'right'
      },);
    }
  }, [isFiscal]);

  const hideSuccessModal = useCallback(() => {
    setShowSuccessModal(false);
    setTransactionNumber(0);
    setTransactionSumm(0);
    initEncashmentStatuses();
  }, []);

  const setFiscalEncashmentData = useCallback(() => {
    setFiscalEnashmentList(xReport?.Operations?.filter(
      (ops) => ops.TypeDocument.Id === FISCAL_SCREENS.OUTCOME));
  }, [xReport]);

  const changeDateRange = useCallback((dates) => {
    if (dates.length === 2) {
      const dateFromFormatted = moment(dates[0]).format('YYYY-MM-DD 00:00:01');
      const dateToFormatted = moment(dates[1]).format('YYYY-MM-DD 23:59:59');

      const dateFromStr = moment(dateFromFormatted).format('YYYYMMDDHHmmss');
      const dateToStr = moment(dateToFormatted).format('YYYYMMDDHHmmss');

      setDateFrom(dateFromStr);
      setDateTo(dateToStr);
    }
  }, []);

  const getFiscalRowContent = useCallback((summ, transNum, dateIn, previewTrans) => {
    const { userData: { DateShiftOpen } } = getFiscalData();
    const fiscalEnashmentSendedTrans = [];
    const fiscalEnashmentSendedDates = [];
    const formattedDateIn = (dateIn).slice(0, 12);

    // находим инкассацию с АП в фискале по дате
    fiscalEnashmentList?.forEach((ops) => {
      const formattedDateDocument = moment(ops?.DateDocument).format('YYYYMMDDHHmm');

      if (formattedDateDocument === formattedDateIn
        && !fiscalEnashmentSendedDates.includes(formattedDateIn)) {
        fiscalEnashmentSendedDates.push(formattedDateIn);
        setObjectElement(fiscalEnashmentSendedTrans, transNum, summ);
      }
    });

    const dateInFormatted = getPayFormattedDate(dateIn);

    if (dateInFormatted.isSameOrAfter(DateShiftOpen)) {
      const previewTransactionObject = getObjectElement(dublicatePaysList, previewTrans);
      let isDublicatePay = false;

      if (previewTransactionObject) {
        const datePreviewTrans = getPayFormattedDate(previewTransactionObject.time);
        const dateNowTrans = getPayFormattedDate(dateIn);
        const dateDiff = moment.duration(dateNowTrans.diff(datePreviewTrans)).minutes();

        isDublicatePay = previewTransactionObject.summ === summ && dateDiff <= 1;
      }

      if (getObjectElement(fiscalEnashmentSendedTrans, transNum)) {
        setObjectElement(dublicatePaysList, transNum, { time: dateIn, summ });
        if (isDublicatePay) {
          return <span className="fiscalEncashWarning">Дубль. Обратитесь в ТП</span>;
        }

        return <span className="fiscalEncashSuccess">Отправлено в фискал</span>;
      }

      return (
        <><ReactTooltip id="tooltip-mail-ref" backgroundColor="#232329">Отправить инкассацию в фискал</ReactTooltip>
          <CustomButton
            data-tip
            data-for="tooltip-mail-ref"
            onClick={toggleFiscalEnashmentModal(transNum, summ)}
          >
            <Icon type="mail" />
          </CustomButton>
        </>
      );
    }

    return <div>Информация отображается <br/> только за текущую смену</div>;
  }, [fiscalEnashmentList, encashmentListArray]);

  const createRowData = useCallback(() => {
    const data = [];

    if (encashmentListArray.length) {
      for (let i = 0; i < encashmentListArray.length; i++) {
        const { dateIn, transNum, bills: { b1 } } = encashmentListArray[i.toString()];
        const encashmentRow = {
          key: i + 1,
          date: moment(dateIn, 'YYYYMMDDHHmmss').format('DD-MM-YYYY HH:mm:ss'),
          transaction: transNum,
          amount: `${b1} тг`,
          receipt: (
            <CustomButton
              className="encashment-button"
              key="submit"
              type="primary"
              onClick={() => getCheckOnSn({ sn: transNum, amount: b1, dateIn })}
            >
              Печать
            </CustomButton>
          )
        };

        if (isFiscal) {
          encashmentRow.fiscal = getFiscalRowContent(
            b1, transNum, dateIn, encashmentListArray[(i - 1).toString()]?.transNum
          );
        }

        data.push(encashmentRow);
      }
    }

    return data;
  }, [encashmentListArray, fiscalEnashmentList]);

  const inputHandleChange = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);

  const filterEncashmentList = useCallback(() => {
    let tempEncashment = [...encashmentList];

    tempEncashment = tempEncashment.filter(filter =>
      filter.bills.b1.toString().includes(searchValue));

    setEncashmentListArray(tempEncashment);
  }, []);

  const handleChangePage = useCallback((event, pageNumber) => {
    setPage(pageNumber);
  }, []);

  const handleChangeRowsPerPage = useCallback(event => {
    setRowsPerPage(event.target.value);
  }, []);

  const sendFiscalEncashment = useCallback(() => {
    doEncashmentFiscal({
      SN: transactionNumber,
      totalAmount: transactionSumm });
    setShowFiscalModal(false);
  }, [transactionNumber, transactionSumm]);

  const toggleFiscalEnashmentModal = useCallback((transNum, transSumm) => () => {
    setTransactionNumber(transNum);
    setTransactionSumm(transSumm);
    setShowFiscalModal(!showFiscalModal);
  }, [showFiscalModal]);

  return (
    <>
      <Modal
        title="Инкассация в фискал"
        isOpen={showSuccessModal && transactionSumm > 0}
        onOk={hideSuccessModal}
        okText="Ok"
        onClose={hideSuccessModal}
        size="small"
        cancelText="Закрыть"
      >
        <div className="encashment-success" >
          <Icon type="success" />
          Инкассация в фискал на сумму {transactionSumm} прошла успешно
        </div>
      </Modal>

      <Modal
        title="Инкассация в фискал"
        isOpen={showFiscalModal}
        onOk={sendFiscalEncashment}
        okText="Да"
        onClose={toggleFiscalEnashmentModal(0, 0)}
        size="small"
      >
        <div className="encashment-modal">
          Вы действительно хотите отправить инкассацию на сумму {transactionSumm} тг в фискал?
        </div>
      </Modal>

      <div className="payment-history-head">
        <h2>Журнал инкассаций</h2>
        <div className="encashment-search">
          <Input
            className="encashment-input"
            search
            placeholder="Поиск по сумме"
            onchange={inputHandleChange}
          />
          <Flatpickr
            options={{
              dateFormat: 'd-m-Y',
              mode: 'range',
              locale: Russian,
              defaultDate: [
                moment(dateFrom, 'YYYYMMDDHHmmss').format('DD-MM-YYYY'),
                moment(dateTo, 'YYYYMMDDHHmmss').format('DD-MM-YYYY')
              ]
            }}
            className="datepicker-wrapper"
            onChange={(dates) => changeDateRange(dates)}
          />
        </div>
      </div>

      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="simple table">
          {!createRowData().length && <caption className="table-caption">Отсутствуют инкассации за выбранный период</caption>}
          <TableHead>
            <TableRow>
              {columns.map((item,) => (
                <TableCell align={item.align} key={item.transaction}>{item.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {createRowData()
              .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
              .map((rows,) => (
                <TableRow key={`${rows.transaction}_row`}>
                  <TableCell component="th" scope="row">
                    {rows.key}
                  </TableCell>
                  <TableCell >
                    {rows.date}
                  </TableCell>
                  <TableCell >
                    {rows.transaction}
                  </TableCell>
                  <TableCell >
                    {rows.amount}
                  </TableCell>
                  <TableCell align="right">
                    {rows.receipt}
                  </TableCell>
                  {isFiscal && (
                    <TableCell align="right">
                      {rows.fiscal}
                    </TableCell>
                  )}
                </TableRow >
              ))}
          </TableBody>
        </Table>

        <TablePagination
          labelRowsPerPage="Количество инкассаций на странице"
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={createRowData().length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
        />
      </TableContainer>
    </>
  );
};

EncashmentList.propTypes = {
  encashmentList: PropTypes.array.isRequired,
  getEncashmentList: PropTypes.func.isRequired,
  getCheckOnSn: PropTypes.func.isRequired,
  isFiscal: PropTypes.bool.isRequired,
  getXReportData: PropTypes.func.isRequired,
  xReport: PropTypes.object.isRequired,
  doEncashmentFiscal: PropTypes.func.isRequired,
  isEncashmentSendedToFiscal: PropTypes.bool.isRequired,
  initEncashmentStatuses: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return ({
    encashmentList: state.encashment.encashmentList,
    isFiscal: state.fiscal.isFiscal,
    xReport: state.fiscal.xReport,
    isEncashmentSendedToFiscal: state.encashment.isEncashmentSendedToFiscal,
  });
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...encashmentActions,
    getCheckOnSn: getCheckOnSnCurrent,
    getXReportData: getXReportDataCurrent,

  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EncashmentList);
