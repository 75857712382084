export const AUTH = 'AUTH';
export const MAKE_PAY = 'MAKE_PAY';
export const SEND_LOG = 'SEND_LOG';
export const SET_BALANCES = 'SET_BALANCES';
export const ONLINE_CHECK = 'ONLINE_CHECK';
export const CHECK_PAYMENT = 'CHECK_PAYMENT';
export const GET_TERMINAL_INFO = 'GET_TERMINAL_INFO';
export const SET_PAYMENTS_HISTORY = 'SET_PAYMENTS_HISTORY';
export const SET_ONLINE_CHECK_CODE = 'SET_ONLINE_CHECK_CODE';
export const SET_PAYMENT_COMPLETED = 'SET_PAYMENT_COMPLETED';
export const SET_KTJ_PAYMENT_FAILED = 'SET_KTJ_PAYMENT_FAILED';
export const TOGGLE_CHECKS_WITH_CODE = 'TOGGLE_CHECKS_WITH_CODE';
export const SET_CASHOUT_REPORT_DATA = 'SET_CASHOUT_REPORT_DATA';
export const SET_PAYMENT_IN_PROCCESS = 'SET_PAYMENT_IN_PROCCESS';
export const SET_SUMMARY_REPORT_DATA = 'SET_SUMMARY_REPORT_DATA';
export const SET_BALANCES_XML_REQUEST = 'SET_BALANCES_XML_REQUEST';
export const CHANGE_CHECK_ONLINE_VALUE = 'CHANGE_CHECK_ONLINE_VALUE';
export const SET_KTJ_PAYMENT_SUCCESSFUL = 'SET_KTJ_PAYMENT_SUCCESSFUL';
export const PAYMENT_HISTORY_WITH_ERROR = 'PAYMENT_HISTORY_WITH_ERROR';
export const BALANCE_REQUEST_WITH_ERROR = 'BALANCE_REQUEST_WITH_ERROR';
export const SET_PAYMENT_HISTORY_SN_LIST = 'SET_PAYMENT_HISTORY_SN_LIST';
export const PAYMENT_HISTORY_SEARCH_DATES = 'PAYMENT_HISTORY_SEARCH_DATES';
export const SET_SHOW_IDENTIFICATION_MODAL = 'SET_SHOW_IDENTIFICATION_MODAL';
export const SET_PAYMENTS_HISTORY_XML_REQUEST = 'SET_PAYMENTS_HISTORY_XML_REQUEST';

