import React, { Component, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-maskedinput';
import { t } from 'helpers/main';
import MinAndMaxAmount from 'components/MinAndMaxAmount';
import Input from 'components/UI/Input/input';

const Kbk = lazy(() => import('./kbkBlock'));
const Knp = lazy(() => import('./knpBlock'));
const Kno = lazy(() => import('./knoBlock'));
const Ugd = lazy(() => import('./ugdBlock'));

const TAXES_SERVICE_ID = 2414;
const PENALTY_SERVICE_ID = 2415;

export default class PenaltiesNotFound extends Component {
  updateAmount = (value) => {
    const {
      OperCom,
      BankCom,
      currentComProfile,
      setSumIsValid,
      updateWillBeCreditedAmount,
    } = this.props;

    const amount = value ? Number(value) + Number(OperCom) + Number(BankCom) : 0;

    setSumIsValid(!!value);
    updateWillBeCreditedAmount(amount, currentComProfile, true);
  };

  updateProtocolNum = (value) => {
    const { setProtocolIsValid, updateFieldValue } = this.props;

    setProtocolIsValid(true);
    updateFieldValue('numProtocol', value);
  };

  updateVinCode = (value) => {
    const { setIsVinCodeValid, updateFieldValue } = this.props;

    setIsVinCodeValid(true);
    updateFieldValue('vinCode', value);
  };

  render() {
    const {
      idService,
      numProtocol,
      ndSelected,
      selectKbk,
      selectKnp,
      selectUgd,
      ugd,
      isProtocolValid,
      isSumValid,
      setUgd,
      vinCode,
      isVinCodeValid,
      isItPayTaxForCar,
      knpError,
      knoError,
      kbkError,
      ugdError
    } = this.props;

    return (
      <div className="pay-form">
        {(idService === PENALTY_SERVICE_ID) && (
          <div className="form-group">
            <Input
              placeholder="Номер протокола"
              id="numProtocol"
              onchange={this.updateProtocolNum}
              value={numProtocol}
              label={t('numProtocol')}
              errorMessage="Заполните номер протокола"
              isError={!isProtocolValid}
            />
          </div>
        )}

        <div className="form-group">
          <span className="pay-form-title">{t('amount')}</span>
          <MaskedInput
            mask="111111111"
            type="text"
            id="amount"
            autoComplete="off"
            maxLength={9}
            placeholder="Сумма оплаты"
            placeholderChar=" "
            onChange={(e) => this.updateAmount(e.target.value)}
            className={!isSumValid ? 'input-warning' : ''}
          />
          {!isSumValid && <span className="inputError">Заполните сумму оплаты</span>}
        </div>
        <MinAndMaxAmount idService={idService} />
        {(idService === TAXES_SERVICE_ID) && (
          <div>
            <Suspense fallback={<div>Загружается список КБК...</div>}>
              <Kbk selectKbk={selectKbk} error={kbkError}/>
              {kbkError && <span className="inputError">Выберите значение из списка</span>}
            </Suspense>
            <Suspense fallback={<div>Загружается список КНП...</div>}>
              <Knp selectKnp={selectKnp} error={knpError}/>
              {knpError && <span className="inputError">Выберите значение из списка</span>}
            </Suspense>
          </div>
        )}
        <Suspense fallback={<div>Загружается список НД...</div>}>
          <Kno setUgd={setUgd} error={knoError}/>
          {knoError && <span className="inputError">Выберите значение из списка</span>}
        </Suspense>
        {(ndSelected) && (
          <Suspense fallback={<div>Загружается список УГД...</div>}>
            <Ugd ugd={ugd} selectUgd={selectUgd} error={ugdError}/>
            {ugdError && <span className="inputError">Выберите значение из списка</span>}
          </Suspense>
        )}
        {isItPayTaxForCar() && (
          <div className="form-group">
            <Input
              placeholder="VIN-код"
              id="vin-code"
              onchange={this.updateVinCode}
              value={vinCode}
              label="VIN-код"
              errorMessage="Заполните VIN-код"
              isError={!isVinCodeValid}
            />
          </div>
        )}
      </div>
    );
  }
}

PenaltiesNotFound.propTypes = {
  idService: PropTypes.number.isRequired,
  currentComProfile: PropTypes.object.isRequired,
  numProtocol: PropTypes.string,
  vinCode: PropTypes.string,
  OperCom: PropTypes.string.isRequired,
  BankCom: PropTypes.string.isRequired,
  selectKbk: PropTypes.func.isRequired,
  selectKnp: PropTypes.func.isRequired,
  selectUgd: PropTypes.func.isRequired,
  isItPayTaxForCar: PropTypes.func.isRequired,
  updateWillBeCreditedAmount: PropTypes.func.isRequired,
  ugd: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  ndSelected: PropTypes.bool.isRequired,
  isProtocolValid: PropTypes.bool.isRequired,
  isSumValid: PropTypes.bool.isRequired,
  isVinCodeValid: PropTypes.bool.isRequired,
  updateFieldValue: PropTypes.func.isRequired,
  setUgd: PropTypes.func.isRequired,
  setSumIsValid: PropTypes.func.isRequired,
  setProtocolIsValid: PropTypes.func.isRequired,
  setIsVinCodeValid: PropTypes.func.isRequired,
  kbkError: PropTypes.bool.isRequired,
  knpError: PropTypes.bool.isRequired,
  ugdError: PropTypes.bool.isRequired,
  knoError: PropTypes.bool.isRequired,
};

PenaltiesNotFound.defaultProps = {
  numProtocol: '',
  vinCode: ''
};
