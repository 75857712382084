// Типы экшнов, которые будем игнорировать при логировании
// eslint-disable-next-line
export const EXCLUDED_ACTIONS_FOR_LOGGING = [
  'LOAD_START',
  'LOAD_STOP',
  'SET_SERVICE_LIST',
  'SET_PAYMENTS_HISTORY',
  'SET_FAVOURITE_SERVICES',
  'SET_BALANCES',
  'SET_PAYMENT_COMPLETED',
  'SET_COMMISSION_PROFILES',
  'SET_GROUPED_SERVICES',
  'SET_SERVICE_LIST',
  'PAYMENT_HISTORY_WITH_ERROR',
  'SEND_LOG_JSON_REQUEST',
  'SET_WS_CONNECTION_CLOSED_BY_SERVER',
  'BALANCE_REQUEST_WITH_ERROR',
  'GET_FISCAL_AUTH_DATA_JSON_REQUEST',
  'SET_FISCAL_BALANCES',
  'SET_ARTICLES',
  'SET_KKM_INFO',
  'SET_FISCAL_TARIFF',
  'SET_FISCAL_LICENSE_LIST',
];
