import axios from 'axios';
import PayTypes from 'paytypes';

import { showError } from 'actions/alert';
import { PULT_API_URL } from 'constants/api';
import { updateFieldValue } from 'actions/pay';
import { getFiscalData } from 'helpers/fiscal';
import { getTerminalInfo } from 'model/Terminal';
import { logAndShowError } from 'actions/fiscal';
import { setCurrentService } from 'actions/services';
import { PAY_FOR_FISCAL_SERVICE_ID } from 'constants/services';
import { doesTheDateEndInCountDays, getCountDaysForDateExpired, getFormatedJsDate } from 'helpers/main';

import { FISCAL_TARIFFS } from 'constants/fiscal';
import * as types from './types';

const { startLoader, stopLoader } = PayTypes.actions.handlers;

// если до конца лицензии осталось 3 дня или меньше, уведомляем
const COUNT_DAYS_FOR_EXPIRED_LICENSE_ALERT = 3;

export const getFiscalTariff = () => (dispatch) => {
  const termInfo = getTerminalInfo();

  if (termInfo) {
    const { isSystem } = termInfo;

    if (isSystem) {
    // если терминал системный
      dispatch({
        type: types.SET_FISCAL_TARIFF,
        tariff: 0,
        licenseExpiredDate: '01.04.2100',
        showLicenseExpiredModal: false
      });
    } else {
    // если дилерский
      const { idKkm, pultHeader } = getFiscalData();
      const url = `${PULT_API_URL}/fiscal/terminal/kkm/${idKkm}`;

      dispatch(startLoader());

      return axios.get(
        url,
        { headers: { Authorization: `Basic ${pultHeader}` } }
      )
        .then(({ status, data }) => {
          if (status === 200) {
            // смотрим на expired_at, здесь лежит дата окончания лицензии
            const { expired_at: expiredDate } = data;
            const test = ''; // 2020-05-25T12:11:59.000000Z
            const licenseExpiredDate = getFormatedJsDate(new Date(test || expiredDate));
            const leftLicenseDays = getCountDaysForDateExpired(licenseExpiredDate);
            const showLicenseExpiredModal = doesTheDateEndInCountDays(
              test || expiredDate,
              COUNT_DAYS_FOR_EXPIRED_LICENSE_ALERT
            );

            dispatch({
              type: types.SET_FISCAL_TARIFF,
              tariff: leftLicenseDays ? FISCAL_TARIFFS.PAYING : FISCAL_TARIFFS.FREE,
              licenseExpiredDate,
              showLicenseExpiredModal
            });
          }

          dispatch(stopLoader());
        })
        .catch((err) => {
          getFiscalTariffError(err, url, dispatch);

          dispatch(stopLoader());
        });
    }
  }
};

export const getFiscalLicenseList = () => (dispatch) => {
  const { idKkm, pultHeader } = getFiscalData();
  const url = `${PULT_API_URL}/fiscal/terminal/kkm/${idKkm}/licenses`;

  dispatch(startLoader());

  return axios.get(
    url,
    { headers: { Authorization: `Basic ${pultHeader}` } }
  )
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({ type: types.SET_FISCAL_LICENSE_LIST, licenseList: data });
      }

      dispatch(stopLoader());
    })
    .catch((err) => {
      if (typeof err.message === 'string') {
        dispatch(logAndShowError(err.message, url, 0, 'PULT_GET_LICENSE_LIST'));
      }

      if (err.message.includes('Request failed with status code 404')) {
        dispatch(logAndShowError('Не удалось получить список лицензий. Касса не найдена. Обратитесь в ТП', url, 0, 'PULT_GET_LICENSE_LIST'));
      }

      dispatch(stopLoader());
    });
};

export const createInvoiceForKkm = (licenseId) => (dispatch) => {
  const { idKkm, pultHeader } = getFiscalData();
  const url = `${PULT_API_URL}/fiscal/terminal/kkm/${idKkm}/order`;

  dispatch(startLoader());

  return axios.post(
    url,
    { license_id: licenseId },
    { headers: { Authorization: `Basic ${pultHeader}` } }
  )
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: types.SET_INVOICE_DATA,
          invoiceData: {
            id: data.data.id.toString(),
            amount: data.data.amount / 100
          }
        });
      }

      dispatch(stopLoader());
    })
    .catch((err) => {
      if (typeof err.message === 'string') {
        dispatch(logAndShowError(err.message, url, 0, 'PULT_CREATE_INVOICE'));
      }

      if (err.message.includes('Request failed with status code 404')) {
        dispatch(logAndShowError('Не удалось создать счет на оплату тарифа. Касса не найдена. Обратитесь в ТП', url, 0, 'PULT_CREATE_INVOICE'));
      }

      dispatch(stopLoader());
    });
};

export const preparePayForFiscalService = (id) => (dispatch, getState) => {
  const { serviceList } = getState().services;
  const service = serviceList.filter(item => item.idService === PAY_FOR_FISCAL_SERVICE_ID);

  if (service.length) {
    dispatch(setCurrentService(service[0]));
    dispatch(updateFieldValue('account', id));
  } else {
    dispatch(showError('Сервис "Fiscal24 - оплата по счету" не найден, обратитесь в ТП'));
  }
};

const getFiscalTariffError = (err, url, dispatch) => {
  if (typeof err.message === 'string') {
    dispatch(logAndShowError(err.message, url, 0, 'PULT_GET_TARIFF'));
  }

  if (err.message.includes('Request failed with status code 404')) {
    dispatch(logAndShowError('Не удалось получить текущий тариф. Касса не найдена. Обратитесь в ТП', url, 0, 'PULT_GET_TARIFF'));
  }
};
