/* eslint-disable no-unused-vars */
import axios from 'axios';
import { XMLParser, XMLValidator } from 'fast-xml-parser';

import { isNull } from 'helpers/main';
import { refreshJwtToken } from 'helpers/api';
import { FISCAL_API_URL } from 'constants/api';
import { XML_TO_JSON_OPTIONS } from 'constants/all';
import { refreshFiscalToken, setFiscalData } from 'helpers/fiscal';
import { goToEndSessionPage } from 'actions/navigate';
import { getAuthData, setAuthData } from 'model/UserDb';

const retry = 3;
const retryDelay = 1000;
const parser = new XMLParser(XML_TO_JSON_OPTIONS);

const isFiscalUnavailable = (newConfig, err) =>
  newConfig.url.includes(FISCAL_API_URL) && err.message.includes('Request failed with status code');

export default {
  setupInterceptors: (store) => {
    // выполняется после каждого запроса
    axios.interceptors.response.use(
      (response) => {
        const { data, config } = response;
        const originalRequest = config;

        // токен истёк
        if (isTokenExpired(data)) {
          const jsonObj = parser.parse(data);
          const { request } = jsonObj;

          if (typeof request !== 'undefined') {
            const { type } = request;

            if (isRefreshRequest(type, config)) {
              // если истекли оба токена, кидаем на экран окончания сессии

              onExpireSessionHandler(store);

              return Promise.resolve(response);
            }

            if (getAuthData().refreshToken) {
              // рефрешим токен, заменяем в запросе и кидаем запрос повторно
              return getRefreshToken(store, originalRequest, config, response);
            }

            onExpireSessionHandler(store);
          }
        }

        return Promise.resolve(response);
      },
      async (err) => {
        console.log('ошибка в  перехватчике', err, err.response);
        const newConfig = err.config;
        const status = getErrStatus(err);

        if (isFiscalUnavailable(newConfig, err) && status >= 500) {
          // eslint-disable-next-line no-param-reassign
          err.response.data = {};
          // eslint-disable-next-line no-param-reassign
          err.response.data.Message = 'Сервер фискала недоступен. Обратитесь в тех. поддержку';
        }

        const { canRetry, isFiscalTokenExpired } = getCanRetry(newConfig, err);

        if (canRetry) {
          // If newConfig does not exist or the retry option is not set, reject
          if (!newConfig) return Promise.reject(err); /* NOSONAR */

          // Set the variable for keeping track of the retry count
          newConfig.__retryCount = newConfig.__retryCount || 0;

          // Check if we've maxed out the total number of retries
          if (newConfig.__retryCount >= retry) {
            // Reject with the error
            return Promise.reject(err); /* NOSONAR */
          }

          // Increase the retry count
          newConfig.__retryCount += 1;

          // Create new promise to handle exponential backoff
          const backoff = new Promise(((resolve) => {
            setTimeout(() => {
              resolve();
            }, retryDelay || 1);
          }));

          // Return the promise in which recalls axios to retry the request
          if (isFiscalTokenExpired) {
            const updatedFiscalToken = await refreshFiscalToken();

            if (updatedFiscalToken) {
              newConfig.headers.Authorization = `Bearer ${updatedFiscalToken}`;

              return backoff.then(() => axios(newConfig));
            }
            // for auth from cashier

            checkIsAuthCashier();

            onExpireSessionHandler(store);

            return Promise.reject(err); /* NOSONAR */
          }

          return backoff.then(() => axios(newConfig));
        }

        return Promise.reject(err); /* NOSONAR */
      });
  }
};

const getErrStatus = (err) => {
  if (err.response) return err.response.status;

  return 0;
};

const isRefreshRequest = (type, config) => {
  if (type === 1) return config.data.includes('refreshToken');

  return false;
};

const isTokenExpired = (data) =>
  typeof data === 'string' &&
  data.includes('<status>607</status>') &&
  XMLValidator.validate(data) === true;

const replaceJwtInReqData = (dataStr, newToken) => {
  const splitted = dataStr.split('<guid>');
  const startOfRequest = splitted[0];
  const endOfRequest = splitted[1].split('</guid>')[1];

  return `${startOfRequest}<guid>${newToken}</guid>${endOfRequest}`;
};

const getCanRetry = (newConfig, err) => {
  let canRetry = !newConfig.url.includes(FISCAL_API_URL) || err.message === 'Network Error';
  let isFiscalTokenExpired = false;

  if (err.message && err.message.includes('Request failed with status code')) {
    const statusCode = Number(err.message.split('Request failed with status code ')[1]);

    if (statusCode >= 500 || statusCode === 404) {
      canRetry = true;
    }

    if (statusCode === 401 && err.response && err.response.data.Message.includes('token is expired by')) {
      isFiscalTokenExpired = true;
      canRetry = true;
    }
  }

  return { canRetry, isFiscalTokenExpired };
};

const getRefreshToken = (store, origReq, config, response) => {
  const originalRequest = origReq;

  // рефрешим токен, заменяем в запросе и кидаем запрос повторно
  return refreshJwtToken()
    .then(token => {
      if (isNull(token)) {
        onExpireSessionHandler(store);
      }

      if (token) {
        originalRequest.data = replaceJwtInReqData(config.data, token);

        return axios(originalRequest);
      }

      return Promise.resolve(response);
    });
};

const onExpireSessionHandler = (store) => {
  setAuthData({ guid: '' });
  store.dispatch(goToEndSessionPage());
};

const checkIsAuthCashier = () => {
  const { authCashier } = getAuthData();

  if (authCashier) {
    setFiscalData({ token: '' });
    setAuthData({ guid: '' });
  }
};
