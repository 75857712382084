import * as apiTypes from 'actions/api/types';
import * as payTypes from 'actions/pay/types';
import { SET_CURRENT_SERVICE } from 'actions/services/types';

const defaultState = {
  SN: '', // номер транзакции последнего платежа
  inProccess: [], // платежи в проведении, перезапрашиваем их статус, находясь в истории
  paymentsHistory: [],
  paymentMethod: null, // 0 - наличные, 1 - безналичные
  paymentHistoryRequstedWithError: false,
  paymentsHistoryIsRequested: false,
  minAmount: 0,
  maxAmount: 0,
  startDate: '',
  endDate: '',
  isSendedToFiscal: false,
  serviceWasSelected: false,
  paymentWasCancelled: false,
  clientIin: null, // optional
  lastSuccessPaymentData: {
    SN: '',
    FiscalNumber: '',
  },
  snListForTodayPayments: []
};

const payment = (state = defaultState, action = {}) => {
  switch (action.type) {
    case payTypes.SET_TRANSACTION_NUMBER:
      return { ...state, SN: action.SN };
    case apiTypes.SET_PAYMENT_COMPLETED: {
      const oldOnProccess = [].concat(state.inProccess);
      const newInProccess = oldOnProccess.filter(item => (item.sn !== action.sn));

      return { ...state, inProccess: newInProccess };
    }
    case apiTypes.SET_PAYMENT_IN_PROCCESS: {
      const inProccess = [].concat(state.inProccess);

      inProccess.push(action.payment);

      return { ...state, inProccess };
    }
    case apiTypes.SET_PAYMENTS_HISTORY_XML_REQUEST: {
      return { ...state, paymentsHistoryIsRequested: true };
    }
    case apiTypes.SET_PAYMENTS_HISTORY:
      return { ...state, paymentsHistory: action.paymentsHistory };
    case payTypes.SET_PAYMENT_METHOD:
      return { ...state, paymentMethod: action.paymentMethod };
    case payTypes.UPDATE_MIN_SERVICE_AMOUNT:
      return { ...state, minAmount: Number(action.minAmount) };
    case payTypes.UPDATE_MAX_SERVICE_AMOUNT:
      return { ...state, maxAmount: Number(action.maxAmount) };
    case SET_CURRENT_SERVICE: {
      const { minAmount, maxAmount } = action.service;

      return {
        ...state,
        minAmount: (typeof minAmount !== 'undefined' ? Number(minAmount) : 0),
        maxAmount: (typeof maxAmount !== 'undefined' ? Number(maxAmount) : 0),
      };
    }
    case payTypes.PAYMENT_INIT:
      return { ...state, paymentMethod: null };
    case apiTypes.PAYMENT_HISTORY_WITH_ERROR:
      return { ...state, paymentHistoryRequstedWithError: action.status };

    case apiTypes.PAYMENT_HISTORY_SEARCH_DATES:
      return { ...state, startDate: action.startDate, endDate: action.endDate };
    case payTypes.SET_SENDED_TO_FISCAL:
      return { ...state, isSendedToFiscal: action.value };
    case payTypes.SET_CLIENT_IIN:
      return { ...state, clientIin: action.clientIin };
    case apiTypes.SET_PAYMENT_HISTORY_SN_LIST:
      return { ...state, snListForTodayPayments: action.snListForTodayPayments };
    case payTypes.SET_LAST_PAYMENT_DATA:
      return {
        ...state,
        lastSuccessPaymentData: {
          ...state.lastSuccessPaymentData,
          ...action.lastSuccessPaymentData
        }
      };
    default:
      return state;
  }
};

export default payment;
