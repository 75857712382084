/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Alert from 'components/UI/Alert/alert';
import MinAndMaxAmount from 'components/MinAndMaxAmount';
import { showError } from 'actions/alert';
import { t, setSentryBreadсrumbs, setFocusFormElement, isDynamicScheme } from 'helpers/main';
import { paymentDataIsValid, checkMinAndMaxParamsForDynamic1, isFloatAmount } from 'helpers/pay';
import Commission from 'containers/Commission';
import PaymentInfo from 'containers/PaymentInfo';
import {
  setAdding,
  showConfirmScreen,
  showPayTypeScreen,
  updateSelectedFieldValue,
  updateDepositedAmount,
  updateWillBeCreditedAmount,
} from 'actions/pay';
import { PAY_SCHEMES } from 'constants/payTypes';
import { OLIMPBET_SERVICES } from 'constants/services';
import OlimpbetModal from 'components/OlimpbetModal';

class EnterAmount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalMin: 0,
      totalMax: 0,
    };
  }

  componentDidMount() {
    const {
      currentService,
      min,
      max,
      minAmount,
      maxAmount
    } = this.props;

    if (currentService.typeInterface === PAY_SCHEMES.DYNAMIC1) {
      const { totalMin, totalMax } = checkMinAndMaxParamsForDynamic1({
        minAmount,
        maxAmount,
        isDynamic1Scheme: true,
        min,
        max
      });

      this.setTotalMinAndMax({ totalMin, totalMax });
    }
    // search input for focus
    setFocusFormElement('enterAmountForm');
  }

  setTotalMinAndMax = ({ totalMin, totalMax }) => {
    this.setState({ totalMin, totalMax });
  };

  validateAndCheck = (e) => {
    e.preventDefault();
    const { totalMin, totalMax } = this.state;
    const {
      depositedAmount,
      willBeCreditedAmount,
      commissionSum,
      getAddings,
      currentService
    } = this.props;

    setSentryBreadсrumbs('pay', 'EnterAmount - validateAndCheck');

    const amountToValidate = isDynamicScheme(currentService.typeInterface) ?
      willBeCreditedAmount :
      depositedAmount;

    const hasPaymentValidateErr = paymentDataIsValid({
      depositedAmount: amountToValidate,
      willBeCreditedAmount,
      commissionSum,
      minAmount: totalMin,
      maxAmount: totalMax
    });

    if (hasPaymentValidateErr) {
      this.props.showError(hasPaymentValidateErr);
    } else {
      // в некоторых схемах аддинги генерятся на основе введенной суммы
      const adding = getAddings(willBeCreditedAmount);

      if (!Array.isArray(adding)) {
        this.props.setAdding(adding);
      }

      if (this.props.callbackFunc) this.props.callbackFunc();
      this.props.showConfirmScreen();
    }
  };

  render() {
    const { totalMin, totalMax } = this.state;
    const {
      depositedAmount,
      willBeCreditedAmount,
      currentComProfile,
      currentService: { idService },
    } = this.props;

    return (
      <>
        <OlimpbetModal idService={idService}/>
        <div className="pay-form">
          {!this.props.isItKaspiSchemeInModal && (
          <button onClick={this.props.showPayTypeScreen} type="button" className="back-text buttonWithoutStyles">
            {t('back')}
          </button>
          )}
          <div>
            {!this.props.isItKaspiSchemeInModal &&
            <h4>Оплата</h4>
          }
            {OLIMPBET_SERVICES.ID.includes(idService) && (
            <Alert
              message={OLIMPBET_SERVICES.ALERT_INFO}
              banner
            />
            )}
            <div className="confirm-info amount">
              <form onSubmit={this.validateAndCheck} id="enterAmountForm">
                <PaymentInfo />
                <hr />
                <div className="form-group">
                  <label htmlFor="Внесено" className="form-label"><b>Внесено</b></label>
                  <span className="pay-form-input">
                    <input
                      type="text"
                      id="depositedAmount"
                      className="pay-form-input"
                      autoComplete="off"
                      maxLength={9}
                      placeholder="Внесено"
                      onChange={(e) =>
                        this.props.updateDepositedAmount(
                          e.target.value,
                          currentComProfile,
                          !isFloatAmount(totalMin) && !isFloatAmount(totalMax)
                        )
                  }
                      value={depositedAmount}
                    />
                  </span>
                </div>
                <div className="form-group">
                  <label htmlFor="К зачислению" className="form-label"><b>К зачислению</b></label>
                  <span className="pay-form-input">
                    <input
                      type="text"
                      id="willBeCreditedAmount"
                      autoComplete="off"
                      maxLength={9}
                      placeholder="К зачислению"
                      onChange={(e) =>
                        this.props.updateWillBeCreditedAmount(
                          e.target.value,
                          currentComProfile,
                          false,
                          !isFloatAmount(totalMin) && !isFloatAmount(totalMax)
                        )
                  }
                      value={willBeCreditedAmount}
                    />
                  </span>
                </div>
                <div className="form-group">
                  <Commission type="sum" />
                </div>
                {!this.props.isItKaspiSchemeInModal &&
                <Alert message={<MinAndMaxAmount idService={idService} />} banner />
            }
                <hr />
                <button
                  type="submit"
                  className="btn btn-primary"
                >
                  проверить и оплатить
                </button>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

EnterAmount.propTypes = {
  currentComProfile: PropTypes.object.isRequired,
  currentService: PropTypes.object.isRequired,
  showConfirmScreen: PropTypes.func.isRequired,
  showPayTypeScreen: PropTypes.func.isRequired,
  updateWillBeCreditedAmount: PropTypes.func.isRequired,
  updateDepositedAmount: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  setAdding: PropTypes.func.isRequired,
  getAddings: PropTypes.func.isRequired,
  depositedAmount: PropTypes.string.isRequired,
  willBeCreditedAmount: PropTypes.string.isRequired,
  commissionSum: PropTypes.number.isRequired,
  minAmount: PropTypes.number.isRequired,
  maxAmount: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  isItKaspiSchemeInModal: PropTypes.bool,
  callbackFunc: PropTypes.func,
};

EnterAmount.defaultProps = {
  isItKaspiSchemeInModal: false,
  callbackFunc: null
};

function mapStateToProps(state) {
  return {
    alert: state.alert,
    depositedAmount: state.pay.depositedAmount.toString(),
    willBeCreditedAmount: state.pay.willBeCreditedAmount.toString(),
    getAddings: state.pay.getAddings,
    minAmount: Number(state.payment.minAmount),
    maxAmount: Number(state.payment.maxAmount),
    currentService: state.kassa.currentService,
    currentComProfile: state.commission.currentComProfile,
    commissionSum: Number(state.commission.commissionSum),
    min: state.pay.min,
    max: state.pay.max,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateSelectedFieldValue,
    showConfirmScreen,
    showPayTypeScreen,
    updateDepositedAmount,
    updateWillBeCreditedAmount,
    setAdding,
    showError
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EnterAmount);
