
import React from 'react';
import PropTypes from 'prop-types';

const ReportResultTable = ({ title, table, setComponentRef }) => (
  <div style={{ display: 'none' }} id="reportTable">
    <div
      ref={(el) => {
        setComponentRef(el);
      }}
      style={{ padding: 20 }}
    >
      {title}
      <div style={{ marginTop: 20 }}>{table}</div>
    </div>
  </div>
);

ReportResultTable.propTypes = {
  setComponentRef: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  table: PropTypes.object.isRequired
};

export default ReportResultTable;
