/* eslint-disable no-unreachable */
/* eslint-disable no-bitwise */
import axios from 'axios';
import PayTypes from 'paytypes';

import { resetAuthData as resetApAuthData, getAuthData } from 'model/UserDb';
import { addOptionalAdditionalDataForReceipt, printCheckFromImg } from 'helpers/check';
import { getBalance } from 'actions/api/getBalance';
import { showError, showSuccess, showWarning } from 'actions/alert';
import { sendInfoLogToGateway } from 'actions/api/logs';
import * as initialDownloadTypes from 'actions/initialDownload/types';
import { setInitialRequestCompleted } from 'actions/initialDownload';
import { saveCheck, setCheckWithCode, setKtjCheckWithCode, setReceiptLang } from 'actions/check';
import {
  initPayType,
  setSendedToFiscal,
  setTransactionNumber,
  initCurrentService,
  setOtherIdServiceForMakePay,
  setClientIIN,
  setLastSuccessPaymentData
} from 'actions/pay';
import {
  setFiscalData,
  clearSections,
  getFiscalData,
  updateFiscalUserData,
  removeSections,
  isFiscalTokenAlive,
  getUidForMakeFiscalPay,
  getIdServiceForMakeFiscalPayStatus,
  getFiscalPayDocumentTemplate
} from 'helpers/fiscal';
import {
  getCurrentDate,
  captureException,
  logErrorToSentry,
  b64EncodeUnicode,
  getArrayElement,
  setArrayElement,
  setSentryBreadсrumbs,
  getFromLocalStorage,
  setToLocalStorage,
  setObjectProperty
} from 'helpers/main';
import { sendJsonReq } from 'helpers/api';

import { FISCAL_API_URL } from 'constants/api';
import { FISCAL_SCREENS, SECTION_TYPES, GET_UID_TRY_COUNT, GET_FISCAL_AUTH_DATA_REQ_NUMBER, FISCAL_KKM_STATUSES, GET_DOCUMENT_BY_SN_TRY_COUNT } from 'constants/fiscal';
import {
  KASPI_CREDIT,
  KASPI_GOLD,
  KTJ_SERVICE_ID,
  SERVICES_WITH_CODE_FOR_CHECK,
} from 'constants/services';

import { goToEndSessionPage } from 'actions/navigate';
import { startTheLoaderPayment, stopTheLoaderPayment } from 'actions/loader';
import moment from 'moment';
import { SET_PAYMENT_HISTORY_SN_LIST } from 'actions/api/types';
import { removeFiscalReceiptFromIDB, saveFiscalReceiptInIDB } from 'model/FiscalReceipt';
import * as types from './types';

const { startLoader, stopLoader } = PayTypes.actions.handlers;

export const setWsServerStatus = (status) =>
  ({ type: types.SET_WS_CONNECTION_CLOSED_BY_SERVER, status });

export const setOpeningShiftCallback = (openingShiftCallback) => (dispatch) => {
  dispatch({ type: types.SET_OPENING_SHIFT_CALLBACK, openingShiftCallback });
};

export const setNeedToOpenShift = (needToOpenShift) => (dispatch) => {
  dispatch({ type: types.SET_TO_OPEN_SHIFT, needToOpenShift });
};

export const setTypeOperation = (typeOperation) => (dispatch) => {
  dispatch({ type: types.SET_TYPE_OPERATION, typeOperation });
};

export const setShowConfirmCloseShift = (showConfirmCloseShift) => (dispatch) => {
  dispatch({ type: types.SET_SHOW_CONFIRM_CLOSE_SHIFT, showConfirmCloseShift });
};

export const setShowPinCodeModal = (showPinCodeModal) => (dispatch) => {
  dispatch({ type: types.SET_SHOW_PIN_CODE_MODAL, showPinCodeModal });
};

export const setShowLicenseExpiredModal = (showLicenseExpiredModal) => (dispatch) => {
  dispatch({ type: types.SET_SHOW_LICENSE_EXPIRED_MODAL, showLicenseExpiredModal });
};

export const setShowLicenseModal = (showLicenseModal) => (dispatch) => {
  dispatch({ type: types.SET_SHOW_LICENSE_MODAL, showLicenseModal });
};

export const setFiscalScreen = (screenType) => (dispatch) => {
  dispatch({ type: types.SET_FISCAL_SCREEN_TYPE, screenType });
};

export const hideFiscalModal = () => (dispatch) => {
  dispatch({ type: types.HIDE_FISCAL_MODAL });
};

export const clearReportData = () => (dispatch) => {
  dispatch({ type: types.CLEAR_EPORT_DATA });
};

export const setFiscalPaymentsList = (paymentsList) => (dispatch) =>
  dispatch({ type: types.SET_FISCAL_PAYMENT_LIST, paymentsList });

export const setFiscalReceipt = (receiptForRefund) => (dispatch) =>
  dispatch({ type: types.SET_RECEIPT_FOR_REFUND, receiptForRefund });

export const selectFiscalSectionForPayment = (sectionForPayment) => (dispatch) => {
  dispatch({ type: types.SELECT_FISCAL_SECTION_FOR_PAYMENT, sectionForPayment });
};

export const setAuthDataIsRequested = (authDataIsRequested) => (dispatch) => {
  dispatch({ type: types.SET_AUTH_DATA_IS_REQUESTED, authDataIsRequested });
};

export const setIsAuthInFiscal = (isAuthInFiscal) => (dispatch) => {
  dispatch({ type: types.SET_IS_AUTH_IN_FISCAL, isAuthInFiscal });
};

export const setAuthInFiscalIsError = (isError) => (dispatch) => {
  dispatch({ type: types.SET_AUTH_FISCAL_ERROR, isError });
};

const getHeaders = (token, uid = '') => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  if (uid) headers.Uid = uid;

  return headers;
};

export const logAndShowError = (Message, Url, Status, action) => (dispatch) => {
  logErrorToSentry(Message, Url, Status, action);
  dispatch(showError(Message));
};

export const setIsFiscal = (isFiscal) => (dispatch) => {
  dispatch({ type: types.SET_IS_FISCAL, isFiscal });
};

export const getFiscalAuthDataForCashierAuth = (fiscalParams = {}) => (dispatch) => {
  dispatch(startLoader());

  return sendJsonReq(GET_FISCAL_AUTH_DATA_REQ_NUMBER, '')
    .then((data) => {
      try {
        const { tFiscalInfo } = data.body;

        if (typeof tFiscalInfo !== 'undefined') {
          dispatch(setAuthDataIsRequested(true));

          // когда фиск данных нет, приходит пустой массив, когда есть - заполненный объект
          if (!Array.isArray(tFiscalInfo)) {
          // фискальный терминал
            const { idKkm } = tFiscalInfo;

            setFiscalData({ idKkm });
            dispatch(setIsFiscal(true));

            // авторизовываемся в фискале
            let fiscalInfo = tFiscalInfo;

            if (fiscalParams.login) {
              fiscalInfo = fiscalParams;
            }

            return dispatch(authInFiscal(fiscalInfo));
          }
        }

        return Promise.resolve({ data: { Status: 0 } });
      } catch (err) {
        console.log('getFiscalAuthDataForCashierAuth err:', err);
      }
    })
    .catch((error) => console.log('getFiscalAuthDataForCashierAuth error:', error));
};

export const getFiscalAuthDataCommonRequest = () => (dispatch) => {
  dispatch(startLoader());

  const successCallback = (data) => {
    const { tFiscalInfo } = data;

    if (typeof tFiscalInfo !== 'undefined') {
      dispatch(setAuthDataIsRequested(true));

      // когда фиск данных нет, приходит пустой массив, когда есть - заполненный объект
      if (!Array.isArray(tFiscalInfo)) {
        // фискальный терминал
        const { idKkm, login } = tFiscalInfo;

        setFiscalData({ idKkm, login });
        dispatch(setIsFiscal(true));

        // авторизовываемся в фискале
        dispatch(authInFiscal(tFiscalInfo));
      }
    }
  };

  dispatch({
    type: `${types.GET_FISCAL_AUTH_DATA}_JSON_REQUEST`,
    payload: { reqType: 15, json: {}, successCallback }
  });
};

export const getZLogOperationsByShift = (shiftId) => (dispatch) => {
  const { token, idKkm } = getFiscalData();

  if (token) {
    const url = `${FISCAL_API_URL}/kkms/${idKkm}/zlog/operations/byshift?idshift=${shiftId}`;

    dispatch(startLoader());

    return axios.get(url, { headers: getHeaders(token) })
      .then((response) => {
        const { Status, Data, Message } = response.data;

        dispatch(stopLoader());
        if (Status === 200) {
          return Data;
        }

        dispatch(showError(Message));
      }).catch(err => {
        if (typeof err.response !== 'undefined') {
          const { Status, Message } = err.response.data;

          dispatch(stopLoader());

          if (Status !== 401) {
            dispatch(showError(Message));
          }
        } else if (typeof err.message !== 'undefined') {
          dispatch(showError('Возникла ошибка. Повторите попытку позднее или обратитесь в ТП'));
        }
      });
  }
  dispatch(showError('Не удалось подключиться к фискалу, обновите страницу'));
};

const authInFiscal = ({ login: Login, pass: Password }) => (dispatch) => {
  const url = `${FISCAL_API_URL}/auth`;

  if (Login && Password) {
    dispatch(startLoader());

    return axios.post(url, { Login, Password })
      .then(response => {
        dispatch(setAuthInFiscalIsError(false));
        const { Status, Data, Message } = response.data;

        dispatch({
          type: initialDownloadTypes.FISCAL_AUTH_EMPTY_REQUEST,
          payload: { reqType: 'auth' }
        });

        if (Status === 200) {
          const { Token, User: { Name, Lock, IdShift } } = Data;

          setFiscalData({
            token: Token,
            date: getCurrentDate(),
            pultHeader: b64EncodeUnicode(`${Login}:${Password}`),
          });

          updateFiscalUserData({ Name, Lock, IdShift });
          dispatch(setIsAuthInFiscal(true));
          dispatch(sendInfoLogToGateway({
            message: `Name: ${Name}, Lock: ${Lock}, IdShift: ${IdShift}, Data: ${JSON.stringify(Data.User)}`,
            reqType: 'authInFiscalUserData'
          }));
        } else {
          dispatch(logAndShowError(`Fiscal24. ${Message}`, url, Status, 'AUTH_IN_FISCAL'));
        }
        dispatch(stopLoader());
        dispatch(stopLoader());

        return response;
      })
      .catch(err => {
        captureException(err, url, 'AUTH_IN_FISCAL', 'FISCAL');
        dispatch(showError(err?.response?.data?.Message || err));
        dispatch(setAuthInFiscalIsError(true));
        dispatch(stopLoader());
        dispatch(stopLoader());
      });
  }

  dispatch(logAndShowError('Фискальный модуль не подключен. Обратитесь в ТП'));
};

export const getUid = (callback) => (dispatch) => {
  const { token, idKkm } = getFiscalData();

  setSentryBreadсrumbs('pay', 'getUid - start');

  if (token) {
    dispatch(startLoader());
    const url = `${FISCAL_API_URL}/uid/${idKkm}`;

    return axios.get(url, { headers: getHeaders(token) })
      .then((response) => {
        const { Status, Data, Message } = response.data;

        setSentryBreadсrumbs('pay', 'getUid - get response');

        if (Status === 200) {
          setToLocalStorage('getUidTryCount', 0);
          setSentryBreadсrumbs('pay', `getUid - status 200 - New UID: ${Data.Uid}`);
          setFiscalData({ uid: Data.Uid });
          dispatch({ type: types.SET_KKM_STATUS, isKkmBlocked: false, blockReason: '' });

          // пока используется в закрытии смены и в запросе на платеж
          if (typeof callback !== 'undefined') {
            setSentryBreadсrumbs('pay', 'getUid - has callback');
            dispatch(callback(Data.Uid));
          }
        }

        dispatch(stopLoader());
        logErrorToSentry(Message, url, Status, 'GET_UID');
      })
      .catch(err => {
        const { Status, Message } = err.response.data;

        setSentryBreadсrumbs('pay', `getUid - has error - status ${Status} - message: ${Message}`);

        if (Status === 429) {
          // касса заблокирована
          dispatch(startLoader());
          dispatch({
            type: types.SET_KKM_STATUS,
            isKkmBlocked: true,
            blockReason: 'Касса заблокирована из-за работы с разных устройств'
          });

          // кидаем повторный запрос на получение uid, через минуту.
          setTimeout(() => {
            dispatch(stopLoader());
            dispatch(stopLoader());
            dispatch(getUid(callback));
          }, 15000);
        } else dispatch(stopLoader());
        logErrorToSentry(Message, url, Status, 'GET_UID');
      });
  }

  dispatch(showError('Не удалось подключиться к фискалу, обновите страницу'));
};

const isNeedToStartLoader = (isPayOperation) => (dispatch) => {
  if (!isPayOperation) dispatch(startLoader());
};

const isNeedToStopLoader = (isPayOperation) => (dispatch) => {
  if (!isPayOperation) dispatch(stopLoader());
};

export const getKkmInfo = (isPayOperation = false) => (dispatch) => {
  const { token, idKkm } = getFiscalData();

  if (token) {
    dispatch(isNeedToStartLoader(isPayOperation));
    const url = `${FISCAL_API_URL}/kkms/${idKkm}`;

    return axios.get(url, { headers: getHeaders(token) })
      .then(response => {
        const { Status, Data, Message } = response.data;

        dispatch({
          type: initialDownloadTypes.FISCAL_GET_KKM_INFO_EMPTY_REQUEST,
          payload: { reqType: 'kkm' }
        });
        dispatch(isNeedToStopLoader(isPayOperation));

        if (Status === 200) {
          const { Kkm } = Data;

          dispatch({ type: types.SET_KKM_INFO, kkmInfo: Kkm });

          dispatch(checkKkmAndSetIsBlocked(Kkm));

          return Kkm;
        }

        logErrorToSentry(Message, url, Status, 'GET_KKM_INFO');
        dispatch(showError(Message));
      })
      .catch(err => {
        if (typeof err.response !== 'undefined') {
          const { Status, Message } = err.response.data;

          logErrorToSentry(Message, url, Status, 'GET_KKM_INFO');
          dispatch(isNeedToStopLoader(isPayOperation));

          if (Status !== 401) {
            dispatch(showError(Message));
          }
        } else if (typeof err.message !== 'undefined') {
          logErrorToSentry(err.message, url, 0, 'GET_KKM_INFO');
          dispatch(showError('Возникла ошибка. Повторите попытку позднее или обратитесь в ТП'));
        }
      });
  }

  dispatch(showError('Не удалось подключиться к фискалу, обновите страницу'));

  return {};
};

const checkKkmAndSetIsBlocked = ({ IdStatusKkm, IsActive }) => (dispatch) => {
  if (typeof IdStatusKkm !== 'undefined' && typeof IsActive !== 'undefined') {
    if (IdStatusKkm === FISCAL_KKM_STATUSES.REGISTRATION) {
      const blockReason = 'Касса в процессе регистрации';

      dispatch({ type: types.SET_KKM_STATUS, isKkmBlocked: true, blockReason });
      dispatch(showError(blockReason));
    } else if (IdStatusKkm === FISCAL_KKM_STATUSES.BLOCKED || !IsActive) {
      const blockReason = 'Касса заблокирована';

      dispatch({ type: types.SET_KKM_STATUS, isKkmBlocked: true, blockReason });
      dispatch(showError(blockReason));
    }
  } else {
    dispatch(showError('Не удалось получить информацию о кассе'));
  }
};

export const getUnits = () => (dispatch) => {
  const { token, idKkm } = getFiscalData();

  if (token) {
    dispatch(startLoader());
    const url = `${FISCAL_API_URL}/kkms/${idKkm}/units`;

    return axios.get(url, { headers: getHeaders(token) })
      .then(response => {
        const { Status, Data, Message } = response.data;

        if (Status === 200) {
          if (Data.length) {
            setFiscalData({
              units: Data,
            });
          } else {
            logErrorToSentry('Не получено ни одной единицы измерения', url, Status, 'GET_UNITS');
          }
        } else {
          logErrorToSentry(Message, url, Status, 'GET_UNITS');
        }

        dispatch(stopLoader());
      })
      .catch(err => {
        sendErrorLogToSentry(err, url, 'GET_UNITS');
        dispatch(stopLoader());
      });
  }

  dispatch(showError('Не удалось подключиться к фискалу, обновите страницу'));
};

const sendErrorLogToSentry = (err, url, actionType) => {
  if (typeof err.response !== 'undefined') {
    const { Status, Message } = err.response.data;

    logErrorToSentry(Message, url, Status, actionType);
  }

  if (typeof err === 'string') {
    logErrorToSentry(err, url, 0, actionType);
  }
};

export const getSections = (forceUpdate = false) => (dispatch) => {
  const { token, idKkm } = getFiscalData();

  if (token) {
    dispatch(startLoader());
    const url = `${FISCAL_API_URL}/kkms/${idKkm}/sections`;

    return axios.get(url, { headers: getHeaders(token) })
      .then(response => {
        const { Status, Data, Message } = response.data;

        if (Status === 200) {
          getSectionsSuccess(Data, url, Status, dispatch);
          if (forceUpdate) {
            dispatch(showSuccess('Секции успешно обновлены'));
          }
        } else {
          logErrorToSentry(Message, url, Status, 'GET_SECTIONS');
        }

        dispatch(stopLoader());
      })
      .catch(err => {
        if (typeof err.response !== 'undefined') {
          const { Status, Message } = err.response.data;

          logErrorToSentry(Message, url, Status, 'GET_SECTIONS');
        }

        if (typeof err === 'string') {
          logErrorToSentry(err, url, 0, 'GET_SECTIONS');
        }

        dispatch(stopLoader());
      });
  }

  dispatch(showError('Не удалось подключиться к фискалу, обновите страницу'));
};

export const openShift = (showSuccessMsg = true) => (dispatch) => {
  const { token, idKkm } = getFiscalData();

  if (token) {
    const url = `${FISCAL_API_URL}/shifts`;

    dispatch(startLoader());

    return axios.post(url, { IdKkm: idKkm }, { headers: getHeaders(token) })
      .then(response => {
        const {
          data: { Status, Message }
        } = response;

        if (Status === 200) {
          openShiftSuccess(response, showSuccessMsg, dispatch);
        } else {
          logErrorToSentry(Message, url, Status, 'OPEN_SHIFT');
          dispatch(showError(Message));
        }

        dispatch(stopLoader());
      })
      .catch(err => {
        openShiftError(err, url, dispatch);

        dispatch(stopLoader());
      });
  }

  dispatch(showError('Не удалось подключиться к фискалу, обновите страницу'));
};

export const closeShift = () => (dispatch) => {
  const { token, uid, userData: { IdShift } } = getFiscalData();

  if (token) {
    const url = `${FISCAL_API_URL}/shifts/${IdShift}/z`;
    const req = (updatedUid) => {
      dispatch(startLoader());

      return axios.post(url, {}, { headers: getHeaders(token, updatedUid || uid) })
        .then(({ data: { Status, Message, Data } }) => {
          if (Status === 200) {
            updateFiscalUserData({ Lock: false });
            dispatch({ type: types.UPDATE_KKM_INFO, data: { Lock: false } });
            dispatch({ type: types.SET_Z_REPORT_DATA, zReport: Data });
            dispatch(setFiscalScreen(FISCAL_SCREENS.CLOSE_SHIFT));
            removeSections();
          } else {
            dispatch(logAndShowError(`Fiscal24. ${Message}`, url, Status, 'CLOSE_SHIFT'));
          }

          dispatch(stopLoader());
        })
        .catch(({ response: { data: { Status, Message } } }) => {
          handleCloseShiftError(Status, Message, req, url, dispatch);

          dispatch(stopLoader());
        });
    };

    return req();
  }

  dispatch(showError('Не удалось подключиться к фискалу, обновите страницу'));
};

export const getShiftInfo = () => (dispatch) => {
  const { token, userData: { IdShift } } = getFiscalData();

  if (token && IdShift) {
    const url = `${FISCAL_API_URL}/shifts/${IdShift}`;

    dispatch(startLoader());

    return axios.get(url, { headers: getHeaders(token) })
      .then(({ data: { Status, Data } }) => {
        if (Status === 200) {
          updateFiscalUserData({ DateShiftOpen: Data.DateOpen });
        }

        dispatch(stopLoader());
      })
      .catch(err => {
        captureException(err, url, 'GET_SHIFT_INFO', 'FISCAL');
        dispatch(stopLoader());
      });
  }

  if (!token) {
    dispatch(showError('Не удалось подключиться к фискалу, обновите страницу'));
  }
};

export const makeFiscalPay = (reqData, payType = 999, checkData = [], updatedServiceId = 0) =>
  (dispatch, getState) => {
    const { token, idKkm, uid } = getFiscalData();

    setSentryBreadсrumbs('pay', 'makeFiscalPay - start');
    dispatch({
      type: types.MAKE_FISCAL_PAY_EMPTY_REQUEST,
      payload: { reqType: 'fiscalPay' }
    });

    if (token) {
      dispatch({
        type: types.MAKE_FISCAL_PAY_TOKEN_EXIST,
        payload: { reqType: 'fiscalPay' }
      });
      setSentryBreadсrumbs('pay', 'makeFiscalPay - token is exist');

      const url = `${FISCAL_API_URL}/kkms/${idKkm}/sales`;
      const req = (updatedUid) => {
        dispatch(startTheLoaderPayment());

        const headers = getHeaders(token, getUidForMakeFiscalPay(updatedUid, uid));

        const init = () => {
          dispatch(initCurrentService());
          dispatch(setTransactionNumber(''));
          dispatch(initPayType(payType));
          dispatch(getFiscalBalances());
          dispatch(getBalance()); // Запрашиваем баланс
          dispatch(setOtherIdServiceForMakePay(0));
          dispatch(setClientIIN(''));
          dispatch(setReceiptLang(''));
        };

        return axios.post(url, { ...reqData }, { headers })
          .then(response => {
            dispatch({
              type: types.MAKE_FISCAL_PAY_RESPONSE,
              payload: { reqType: 'fiscalPay' }
            });

            setSentryBreadсrumbs('pay', 'makeFiscalPay - got response');

            try {
              const {
                headers: { uid: newUid },
                data: { Data: { FiscalNumber, AutonomousNumber, Receipt } }
              } = response;

              setFiscalData({ uid: newUid });

              dispatch(sendInfoLogToGateway({
                message: `sn:${reqData.Sn}, sendingUID: ${getUidForMakeFiscalPay(updatedUid, uid)}, gettingUID: ${newUid}`,
                reqType: 'makeFiscalPayUIDS'
              }));

              const {
                kassa: { currentService: { idService } },
              } = getState();

              const reqParams = {
                payType,
                checkData,
                FiscalNumber,
                AutonomousNumber,
                Receipt,
                idService: getIdServiceForMakeFiscalPayStatus(updatedServiceId, idService)
              };

              dispatch(checkMakeFiscalPayResponseStatus(response, reqParams, url, idKkm));
              init();
              dispatch(stopTheLoaderPayment());
            } catch (err) {
              setSentryBreadсrumbs('pay', 'makeFiscalPay - error in try catch');
              captureException(err, url, 'MAKE_FISCAL_PAY', 'FISCAL');
              dispatch(setTransactionNumber(''));
              dispatch(stopTheLoaderPayment());
            }
          })
          .catch(err => {
            const { response } = err;

            dispatch({
              type: types.MAKE_FISCAL_PAY_ERROR_RESPONSE,
              payload: { reqType: 'fiscalPay' }
            });
            setSentryBreadсrumbs('pay', 'makeFiscalPay - error in catch');
            captureException(response.data, url, 'MAKE_FISCAL_PAY', 'FISCAL');

            dispatch(sendInfoLogToGateway({
              message: `Result: ${JSON.stringify(response)}`,
              reqType: 'makeFiscalPayDataError'
            }));

            dispatch(stopTheLoaderPayment());
            console.log('makeFiscalPay ошибка:', response);
            dispatch(handleMakeFiscalPayError({
              response,
              payType,
              checkData,
              req,
              url,
              init,
              updatedServiceId,
              SN: reqData.Sn
            }));
          });
      };

      return req();
    }
    dispatch({
      type: types.MAKE_FISCAL_PAY_TOKEN_NOTEXIST,
      payload: { reqType: 'fiscalPay' }
    });
    setSentryBreadсrumbs('pay', 'makeFiscalPay - token is NOT exist');

    dispatch(sendInfoLogToGateway({
      message: `reqData: ${JSON.stringify(reqData)}, Result: ${JSON.stringify(getFiscalData())}`,
      reqType: 'makeFiscalPayDataNoToken'
    }));

    dispatch(showError('Не удалось подключиться к фискалу, обновите страницу'));
  };

const checkMakeFiscalPayResponseStatus = (response, reqParams, url, idKkm) =>
  (dispatch, getState) => {
    const {
      headers: { uid: newUid },
      data: { Status, Message }
    } = response;
    const { FiscalNumber, Receipt } = reqParams;
    const {
      payment: { clientIin }
    } = getState();

    if (newUid) {
      setFiscalData({ uid: newUid });
    }

    if (Status === 200) {
      setSentryBreadсrumbs('pay', 'makeFiscalPay - response status 200');
      dispatch(setLastSuccessPaymentData({ FiscalNumber }));
      dispatch(makeFiscalPayGenerateReceipt(reqParams, clientIin));
    } else if (Status === 208) {
      // Уже сообщалось

      setSentryBreadсrumbs('pay', 'makeFiscalPay - response status 208 (pay is consist in fiscal)');
      if (Receipt) {
        printCheckFromImg(Receipt, 0, true);
      }
    } else {
      setSentryBreadсrumbs('pay', `makeFiscalPay - response Status: ${Status} - Message: ${Message}`);
      dispatch(
        logAndShowError(
          `Fiscal24. ${Message}. Uid: ${newUid}. IdKkm: ${idKkm}`,
          url,
          Status,
          'MAKE_FISCAL_PAY'
        )
      );
    }
  };

export const doFiscalCashout = ({ reqData, checkData }) =>
  (dispatch) => {
    const { token, idKkm, uid } = getFiscalData();

    if (token) {
      const url = `${FISCAL_API_URL}/kkms/${idKkm}/purchases`;
      const req = (updatedUid) => {
        dispatch(startLoader());

        return axios.post(url, { ...reqData }, { headers: getHeaders(token, updatedUid || uid) })
          .then(response => {
            const {
              headers: { uid: newUid },
              data: { Status, Data: { FiscalNumber, AutonomousNumber }, Message }
            } = response;

            dispatch(stopLoader());
            setFiscalData({ uid: newUid });

            // возвращает новый uid
            if (Status === 200) {
              const updatedReceipt = addFiscalNumberToReceipt(
                checkData,
                FiscalNumber,
                AutonomousNumber,
                dispatch
              );

              const { updatedCheckData, sn } = updatedReceipt;

              dispatch(saveCheck({
                checkData: updatedCheckData,
                sn,
                showPrintWindowAfterSave: true,
              }));

              dispatch(getFiscalBalances());
            } else {
              dispatch(logAndShowError(`Fiscal24. ${Message}`, url, Status, 'fiscal_purchases'));
            }
          })
          .catch((response) => {
            doFiscalCashoutError({ url, req, dispatch, response });

            dispatch(stopLoader());
          });
      };

      return req();
    }

    dispatch(showError('Не удалось подключиться к фискалу, обновите страницу'));
  };

export const doFiscalOperation = ({
  reqData,
  typeOperation,
  showCheckAfter,
  successCallback,
  SN }) =>
  (dispatch) => {
    const { token, idKkm, uid } = getFiscalData();

    if (token) {
      const url = `${FISCAL_API_URL}/kkms/${idKkm}/${typeOperation}`;

      if (typeOperation === 'expenses') {
        dispatch(sendInfoLogToGateway({
          message: `Транзакция: ${SN}, reqData: ${JSON.stringify(reqData)}`,
          reqType: 'doFiscalExpensesStart'
        }));
      }

      const req = (updatedUid) => {
        dispatch(startLoader());

        if (typeOperation === 'expenses') {
          dispatch(sendInfoLogToGateway({
            message: `Транзакция: ${SN}, reqData: ${JSON.stringify(reqData)}`,
            reqType: 'doFiscalExpensesCallRequest'
          }));
        }

        return axios.post(
          url,
          { ...reqData },
          { headers: getHeaders(token, updatedUid || uid) }
        )
          .then(response => {
            const {
              headers: { uid: newUid },
              data: { Status, Data: { Receipt }, Message }
            } = response;

            dispatch(stopLoader());
            setFiscalData({ uid: newUid });

            dispatch(sendInfoLogToGateway({
              message: `Транзакция: ${SN}, тип операции: ${typeOperation}, Status: ${Status}, Message: ${Message}, has successCallback: ${Boolean(successCallback)}, newUid: ${newUid}, request: ${JSON.stringify(reqData)}`,
              reqType: 'doFiscalOperationSuccess'
            }));

            // возвращает новый uid
            if (Status === 200) {
              doFiscalOperationSuccess(
                typeOperation,
                successCallback,
                showCheckAfter,
                Receipt,
                dispatch,
              );

              dispatch(getFiscalBalances());
            } else if (Status === 208 && typeOperation === 'expenses') {
              //
              dispatch(getUid(req));
            } else {
              dispatch(logAndShowError(`Fiscal24. ${Message}`, url, Status, `fiscal_${typeOperation}`));
            }

            return response;
          })
          .catch(({ response: { data: { Status, Message } } }) => {
            dispatch(sendInfoLogToGateway({
              message: `Транзакция: ${SN}, тип операции: ${typeOperation}, typeof successCallback: ${typeof successCallback}, status: ${Status}, request: ${JSON.stringify(reqData)}`,
              reqType: 'doFiscalOperationError'
            }));

            doFiscalOperationError({ Status,
              Message,
              url,
              typeOperation,
              showCheckAfter,
              successCallback,
              req,
              dispatch });
            dispatch(stopLoader());
          });
      };

      return dispatch(sendInfoLogToGateway({
        message: `Транзакция: ${SN}, тип операции: ${typeOperation}, typeof successCallback: ${typeof successCallback}`,
        reqType: 'doFiscalOperationBeforeRequest',
        functionCallback: req
      }));
    }

    dispatch(showError('Не удалось подключиться к фискалу, обновите страницу'));
  };

export const getXReportData = ({ showLoader = true }) => (dispatch) => {
  const { token, userData: { IdShift } } = getFiscalData();

  if (token) {
    const url = `${FISCAL_API_URL}/shifts/${IdShift}/x`;

    if (showLoader) {
      dispatch(startLoader());
    }

    return axios.get(url, { headers: getHeaders(token) })
      .then(({ data: { Status, Message, Data } }) => {
        if (Status === 200) {
          const fiscalXReportsSnList = [];

          Data.Operations.forEach((operation) => {
            fiscalXReportsSnList.push(operation.SN);
          });

          dispatch({ type: types.SET_X_REPORT_DATA, xReport: Data });
          dispatch({ type: types.SET_FISCAL_X_REPORT_SN_LIST, fiscalXReportsSnList });
        } else {
          dispatch(logAndShowError(`Fiscal24. ${Message}`, url, Status, 'X_REPORT'));
        }

        dispatch(stopLoader());
      })
      .catch(({ response: { data: { Status, Message } } }) => {
        logErrorToSentry(`Fiscal24. ${Message}`, url, Status, 'X_REPORT');

        if (Status !== 401) {
          dispatch(showError(`Fiscal24. ${Message}`, url, Status, 'X_REPORT'));
        }
        dispatch(stopLoader());
      });
  }

  dispatch(showError('Не удалось подключиться к фискалу, обновите страницу'));
};

export const getFiscalBalances = () => (dispatch) => {
  const { token, idKkm } = getFiscalData();

  if (token) {
    const url = `${FISCAL_API_URL}/kkms/${idKkm}/balances`;

    dispatch(startLoader());

    return axios.get(url, { headers: getHeaders(token) })
      .then(({ data: { Status, Message, Data } }) => {
        if (Status === 200) {
          getFiscalBalancesSuccess(Data, dispatch);
        } else {
          dispatch(logAndShowError(`Fiscal24. ${Message}`, url, Status, 'GET_FISCAL_BALANCES'));
        }

        dispatch(stopLoader());
      })
      .catch(({ response: { data: { Status, Message } } }) => {
        logErrorToSentry(`Fiscal24. ${Message}`, url, Status, 'GET_FISCAL_BALANCES');

        if (Status !== 401) {
          dispatch(showError(`Fiscal24. ${Message}`, url, Status, 'GET_FISCAL_BALANCES'));
        }
        dispatch(stopLoader());
      });
  }

  dispatch(showError('Не удалось подключиться к фискалу, обновите страницу'));
};

export const getArticles = () => (dispatch) => {
  const { token, idKkm } = getFiscalData();

  if (token) {
    const url = `${FISCAL_API_URL}/kkms/${idKkm}/articles`;

    dispatch(startLoader());

    return axios.get(url, { headers: getHeaders(token) })
      .then(({ data: { Status, Message, Data } }) => {
        if (Status === 200) {
          dispatch({ type: types.SET_ARTICLES, articles: Data.Articles });
        } else {
          dispatch(logAndShowError(`Fiscal24. ${Message}`, url, Status, 'GET_ARTICLES'));
        }

        dispatch(stopLoader());
      })
      .catch(({ response: { data: { Status, Message } } }) => {
        logErrorToSentry(`Fiscal24. ${Message}`, url, Status, 'GET_ARTICLES');

        if (Status !== 401) {
          dispatch(showError(`Fiscal24. ${Message}`, url, Status, 'GET_ARTICLES'));
        }
        dispatch(stopLoader());
      });
  }

  dispatch(showError('Не удалось подключиться к фискалу, обновите страницу'));
};

export const getOperationDetail = ({ IdDocument, printAfterGetting = true }) => (dispatch) => {
  const { token } = getFiscalData();

  if (token) {
    const url = `${FISCAL_API_URL}/www/document/${IdDocument}`;

    dispatch(startLoader());

    return axios.get(url, { headers: getHeaders(token) })
      .then(({ data: { Status, Message, Data } }) => {
        if (Status === 200) {
          getOperationDetailSuccess(printAfterGetting, Data, dispatch);
        } else {
          dispatch(logAndShowError(`Fiscal24. ${Message}`, url, Status, 'GET_OPERATION_DETAIL'));
        }

        dispatch(stopLoader());
      })
      .catch(({ response: { data: { Status, Message } } }) => {
        logErrorToSentry(`Fiscal24. ${Message}`, url, Status, 'GET_OPERATION_DETAIL');

        if (Status !== 401) {
          dispatch(showError(`Fiscal24. ${Message}`, url, Status, 'GET_OPERATION_DETAIL'));
        }
        dispatch(stopLoader());
      });
  }

  dispatch(showError('Не удалось подключиться к фискалу, обновите страницу'));
};

export const getFiscalPaymentsList = (dateFrom, dateTo) => (dispatch) => {
  const { token, idKkm } = getFiscalData();

  if (token) {
    const url = `${FISCAL_API_URL}/kkms/${idKkm}/documents?datefrom=${dateFrom}&dateto=${dateTo}`;

    dispatch(startLoader());

    return axios.get(url, { headers: getHeaders(token) })
      .then(({ data: { Status, Message, Data } }) => {
        if (Status === 200) {
          dispatch(setFiscalPaymentsList(Data.reverse()));
        } else {
          dispatch(logAndShowError(`Fiscal24. ${Message}`, url, Status, 'GET_FISCAL_PAYMENT_LIST'));
        }

        dispatch(stopLoader());
      })
      .catch(({ response: { data: { Status, Message } } }) => {
        logErrorToSentry(`Fiscal24. ${Message}`, url, Status, 'GET_FISCAL_PAYMENT_LIST');

        if (Status !== 401) {
          dispatch(showError(`Fiscal24. ${Message}`, url, Status, 'GET_FISCAL_PAYMENT_LIST'));
        }
        dispatch(stopLoader());
      });
  }

  dispatch(showError('Не удалось подключиться к фискалу, обновите страницу'));
};

export const doFiscalRefund = (IdDocument) =>
  (dispatch) => {
    const { token, idKkm, uid } = getFiscalData();

    if (token) {
      const url = `${FISCAL_API_URL}/kkms/${idKkm}/refunds`;
      const req = (updatedUid) => {
        dispatch(startLoader());

        return axios.patch(
          url,
          { IdDocument, GenerateCheck: true },
          { headers: getHeaders(token, updatedUid || uid) }
        )
          .then(response => {
            const {
              headers: { uid: newUid },
              data: { Status, Data: { Receipt }, Message }
            } = response;

            dispatch(stopLoader());

            if (newUid) {
              setFiscalData({ uid: newUid });
            }

            // возвращает новый uid
            if (Status === 200) {
              doFiscalRefundSuccess(Receipt, dispatch);

              dispatch(getFiscalBalances());
            } else {
              dispatch(logAndShowError(`Fiscal24. ${Message}`, url, Status, 'FISCAL_REFUND'));
            }
          })
          .catch(({ response: { data: { Status, Message } } }) => {
            doFiscalRefundError(Status, Message, url, req, dispatch);

            dispatch(stopLoader());
          });
      };

      return req();
    }

    dispatch(showError('Не удалось подключиться к фискалу, обновите страницу'));
  };

const setAutonomousCode = (payType, checkRes) => (dispatch, getState) => {
  // Делаем платёж и фискализируем его (как и раньше)
  const {
    kassa: { currentServive: { idService } },
    payment: { clientIin },
    payment: { isSendedToFiscal: { isSended } }
  } = getState();
  const checkData = addOptionalAdditionalDataForReceipt(idService, clientIin, checkRes);

  if (payType < 999 && !isSended) {
    let sn = '';
    let AutonomousNumber = '';

    const updatedCheckData = checkData.map(checkDataItem => {
      const updatedItem = checkDataItem;

      sn = checkDataItem.payment.transaction;
      AutonomousNumber = getAutonomousFiscalCode(sn);

      updatedItem.payment.autonomousFiscalCode = AutonomousNumber;

      return updatedItem;
    });

    // если не нужен код в чеке, то сохраняем и печатаем чек,
    // иначе сохраняем в пропсах checkData

    if (SERVICES_WITH_CODE_FOR_CHECK.includes(idService || 0)) {
      dispatch(setCheckWithCode({ sn, checks: updatedCheckData, idService }));
    } else if (idService === KTJ_SERVICE_ID) {
      dispatch(setKtjCheckWithCode(sn, updatedCheckData));
    } else {
      dispatch(saveCheck({
        checkData: updatedCheckData,
        sn,
        showPrintWindowAfterSave: true,
      }));
    }

    dispatch(initCurrentService());
    dispatch(setTransactionNumber(''));
    dispatch(initPayType(payType));
    dispatch(getFiscalBalances());
    dispatch(getBalance()); // Запрашиваем баланс
  }
};

const getAutonomousFiscalCode = (sn) => {
  let retVal;

  try {
    let k,
      temp;
    let a = getBytes(sn);

    for (k = 0; k < Math.floor(a.length / 2); k++) {
      temp = getArrayElement(a, k);
      setArrayElement(a, k, a[a.length - (1 + k)]);
      a[a.length - (1 + k)] = temp;
    }

    a = a.slice(0, 4);
    for (k = 0; k < Math.floor(a.length / 2); k++) {
      temp = getArrayElement(a, k);
      setArrayElement(a, k, a[a.length - (1 + k)]);
      a[a.length - (1 + k)] = temp;
    }

    const r = ((0xff && a[0]) << 56 || (0xff && a[1]) << 48 || (0xff && a[2]) << 40 /* NOSONAR */
    || (0xff && a[3]) << 32); /* NOSONAR */

    retVal = Math.abs(r).toString();
  } catch (e) {
    retVal = '0';
  }

  return retVal;
};

const getBytes = (sn) => {
  let SN = Number(sn);
  const buffer = [];

  for (let i = 0; i < 8; i++) {
    buffer.push(SN & 0xff);
    SN >>>= 8;
  }

  return buffer.reverse();
};

const getSectionsSuccess = (Data, url, Status, dispatch) => {
  const { Sections } = Data;

  if (Sections.length) {
    // Секций всегда как минимум две
    // приходит SectionType
    // SectionType = 1 => для платежа
    // SectionType = 2 => для комиссии

    const sectionIdForCommission = Sections.find(section =>
      section.SectionType === SECTION_TYPES.FOR_COMMISSION);
    const sectionIdForPayment = Sections.find(section =>
      section.SectionType === SECTION_TYPES.FOR_PAYMENT);

    if (!sectionIdForPayment || !sectionIdForCommission) {
      dispatch(showError('Проблема с секциями. Обратитесь в Службу Поддержки для создания секции'));
      clearSections();
    } else {
      setFiscalData({
        sections: [].concat(Sections),
        sectionIdForCommission: sectionIdForCommission.Id,
        sectionIdForPayment: sectionIdForPayment.Id,
      });
    }
  } else {
    logErrorToSentry('Не получено ни одной секции', url, Status, 'GET_SECTIONS');
  }
};

const openShiftSuccess = (response, showSuccessMsg, dispatch) => {
  const {
    headers: { uid: newUid },
    data: { Data: { Shift: { Id, DateOpen } } }
  } = response;

  updateFiscalUserData({ Lock: true, IdShift: Id, DateShiftOpen: DateOpen });
  setFiscalData({ uid: newUid });
  dispatch({ type: types.UPDATE_KKM_INFO, data: { Lock: true, IdShift: Id } });

  if (showSuccessMsg) {
    dispatch(showSuccess('Смена успешно открыта'));
  }
};

const openShiftError = (err, url, dispatch) => {
  if (typeof err.response === 'object') {
    const { Status, Message } = err.response.data;

    logErrorToSentry(Message, url, Status, 'OPEN_SHIFT');

    if (Message.toLowerCase() === 'касса заблокирована') {
      dispatch({ type: types.SET_KKM_STATUS, isKkmBlocked: true, blockReason: 'Касса заблокирована' });
    }

    if (Status !== 401) {
      dispatch(showError(Message));
    }
  } else if (typeof err.message !== 'undefined') {
    logErrorToSentry(err.message, url, 0, 'OPEN_SHIFT');
    dispatch(showError('Возникла ошибка. Повторите попытку позднее или обратитесь в ТП'));
  }
};

const handleCloseShiftError = (Status, Message, req, url, dispatch) => {
  if (Status === 452) { // неактивный uid
    dispatch(checkGetUidTryCount(req));
  } else {
    if (Status === 403 && Message.toLowerCase() === 'касса заблокирована') {
      dispatch({ type: types.SET_KKM_STATUS, isKkmBlocked: true, blockReason: 'Касса заблокирована' });
    }

    if (Status !== 401) {
      dispatch(logAndShowError(`Fiscal24. ${Message}`, url, Status, 'CLOSE_SHIFT'));
    }
  }
};

const checkGetUidTryCount = (req) => (dispatch) => {
  const tryCount = getFromLocalStorage('getUidTryCount') ?? 0;

  if (tryCount >= GET_UID_TRY_COUNT) {
    dispatch(showError('Фискал недоступен. Обратитесь в ТП.'));
  } else {
    dispatch(getUid(req));
    setToLocalStorage('getUidTryCount', tryCount + 1);
  }
};

const makeFiscalPayGenerateReceipt = (reqParams, clientIin) => (dispatch) => {
  const { payType, FiscalNumber, AutonomousNumber, Receipt, idService } = reqParams;

  const checkData = addOptionalAdditionalDataForReceipt(idService, clientIin, reqParams.checkData);

  // пометка отправки в фискал
  dispatch(setSendedToFiscal(true));

  // Делаем платёж и фискализируем его (как и раньше)
  if (payType < 999) {
    // for kaspi
    if (idService === KASPI_CREDIT.SERVICE_ID) {
      checkData[0].service = KASPI_CREDIT.SERVICE_INFO;
    }

    if (idService === KASPI_GOLD.SERVICE_ID) {
      checkData[0].service = KASPI_GOLD.SERVICE_INFO;
    }

    const result = addFiscalNumberToReceipt(checkData, FiscalNumber, AutonomousNumber, dispatch);
    const { updatedCheckData, sn } = result;

    // если не нужен код в чеке, то сохраняем и печатаем чек,
    // иначе сохраняем в пропсах checkData
    if (SERVICES_WITH_CODE_FOR_CHECK.includes(idService || 0)) {
      dispatch(setCheckWithCode({ sn, checks: updatedCheckData, idService }));
    } else if (idService === KTJ_SERVICE_ID) {
      dispatch(setKtjCheckWithCode(sn, updatedCheckData));
    } else {
      dispatch(saveCheck({
        checkData: updatedCheckData,
        sn,
        showPrintWindowAfterSave: true,
      }));
    }
  } else {
    // Если просто делаем фиск Продажу
    printCheckFromImg(Receipt, 0, true);
  }
};

/**
 * Получение информации о фискальном номере по SN платежа (используется при 449 от фискала)
 * @param params { response, payType, checkData, updatedServiceId, SN, init, clientIin, idService }
 * @param isPaymentHistory - пришел ли запрос из истории платежей
 * @returns FiscalNumber | AutonomousNumber и вызывается метод генерации и отображения чека
 */
export const getFiscalNumberBySnAndShowReceipt = (params, isPaymentHistory = false) =>
  (dispatch) => {
    const { token } = getFiscalData();
    const { payType, checkData, updatedServiceId, init, SN, clientIin, idService } = params;
    const url = `${FISCAL_API_URL}/www/document_by_sn/${SN}`;

    if (!isPaymentHistory) {
      dispatch(startTheLoaderPayment());
    }

    const req = () => setTimeout(() => {
      const requestCount = getFromLocalStorage('getFiscalDocBySnTryCount');

      if (requestCount >= GET_DOCUMENT_BY_SN_TRY_COUNT) {
        dispatch(stopTheLoaderPayment());
        dispatch(showWarning('Это может занять до 10 минут. Распечатайте чек позднее из журнала операций.', 'Платеж добавлен в очередь на обработку'));
        init();

        return;
      }

      setToLocalStorage('getFiscalDocBySnTryCount', requestCount + 1);

      dispatch(getFiscalNumberBySnAndShowReceipt(params, isPaymentHistory));
    }, 10000);

    return axios.get(url, { headers: getHeaders(token) })
      .then(response => {
        const { Data: { FiscalNumber, AutonomousNumber, Receipt } } = response.data;
        const requestCount = getFromLocalStorage('getFiscalDocBySnTryCount');

        dispatch(sendInfoLogToGateway({
          message: `FiscalNumber: ${FiscalNumber}, AutonomousNumber:${AutonomousNumber}, TryCount: ${requestCount}`,
          reqType: 'getFiscalDocumentBySnSuccess'
        }));

        if (FiscalNumber) {
          const reqParams = {
            payType,
            checkData,
            FiscalNumber,
            AutonomousNumber,
            Receipt,
            idService: getIdServiceForMakeFiscalPayStatus(updatedServiceId, idService)
          };

          dispatch(makeFiscalPayGenerateReceipt(reqParams, clientIin));

          if (!isPaymentHistory) {
            init();
            dispatch(stopTheLoaderPayment());
          }

          removeFiscalReceiptFromIDB(String(SN));
        } else if (!isPaymentHistory) {
          // если ответ пришел пустой и запрос не из истории платежей, то делаем запрос еще раз
          req();
        }

        return FiscalNumber || AutonomousNumber;
      }).catch((response) => {
        dispatch(sendInfoLogToGateway({
          message: `Response: ${JSON.stringify(response)}`,
          reqType: 'getFiscalDocumentBySnError'
        }));

        if (!isPaymentHistory) {
          req();
        }

        return null;
      });
  };

const handleMakeFiscalPayError = ({
  response,
  payType,
  checkData,
  req,
  url,
  init,
  updatedServiceId,
  SN
}) => (dispatch, getState) => {
  if (typeof response !== 'undefined') {
    const { data: { Status, Message } } = response;

    setSentryBreadсrumbs('pay', `makeFiscalPay - error in catch - Status: ${Status} - Message: ${Message}`);

    if (Status === 423) { // смена просрочена
      dispatch(showError('Смена просрочена. Закройте текущую смену и откройте новую'));
      init();
    } else if (Status === 452) { // некорректный uid
      dispatch(checkGetUidTryCount(req));
    } else if (Status === 449) {
      setToLocalStorage('getFiscalDocBySnTryCount', 0);

      dispatch(checkUidInHeadersAndSaveToLS(response));

      const {
        payment: { clientIin },
        kassa: { currentService: { idService } },
      } = getState();

      saveFiscalReceiptInIDB({ sn: SN, checkData, payType, clientIin, idService });
      dispatch(getFiscalNumberBySnAndShowReceipt({
        response,
        payType,
        checkData,
        updatedServiceId,
        SN,
        init,
        clientIin,
        idService
      }));
    } else {
      logErrorToSentry(`Fiscal24. ${Message}`, url, Status, 'MAKE_FISCAL_PAY');
      dispatch(setTransactionNumber(''));
      if (Status !== 401) {
        dispatch(showError(`Fiscal24. ${Message}`));
      }
    }
    // если не отправлен чек-формируем автономный код
    if (Status !== 452 && Status !== 449) {
      dispatch(setAutonomousCode(payType, checkData));
    }
  } else {
    if (typeof response === 'string') {
      setSentryBreadсrumbs('pay', `makeFiscalPay - error in catch - response: ${response}`);
    }
    dispatch(showError(`Не удалось отправить платеж в фискал: ${response}`));
    dispatch(setTransactionNumber(''));
    dispatch(setAutonomousCode(payType, checkData));
  }
};

/**
 * Проверяет наличие uid в заголовках ответа и сохраняет его в localStorage
 * @param {*} response - ответ от сервера
 */
const checkUidInHeadersAndSaveToLS = (response) => (dispatch) => {
  if (response.headers.uid) {
    setFiscalData({ uid: response.headers.uid });
  } else {
    dispatch(getUid());
  }
};

const addFiscalNumberToReceipt = (checkData, FiscalNumber, AutonomousNumber, dispatch) => {
  // Печатаем чек
  let sn = '';
  const resultObj = { updatedCheckData: {}, sn };

  checkData.forEach(checkDataItem => {
    const updatedItem = checkDataItem;

    sn = checkDataItem.payment.transaction;

    if (FiscalNumber) {
      updatedItem.payment.fiscalCode = FiscalNumber;
    } else if (AutonomousNumber) {
      updatedItem.payment.autonomousFiscalCode = AutonomousNumber;
    } else {
      return dispatch(showError('Ошибка. Нет связи с фискальным сервером'));
    }
    resultObj.updatedCheckData = updatedItem;
    resultObj.sn = sn;
  });

  return resultObj;
};

const doFiscalCashoutError = ({ url, req, dispatch, response }) => {
  const { Status, Message } = response;

  if (Status === 452) { // неактивный uid
    dispatch(checkGetUidTryCount(req));
  } else if (Status === 423) { // Касса заблокирована
    dispatch(showError('Смена просрочена. Закройте текущую смену и откройте новую'));
  } else if (Status === 449) {
    dispatch(checkUidInHeadersAndSaveToLS(response));
    dispatch(showWarning('Это может занять до 10 минут.', 'Операция добавлена в очередь на обработку'));
  } else {
    logErrorToSentry(`Fiscal24. ${Message}`, url, Status, 'fiscal_purchases');

    if (Status !== 401) {
      dispatch(showError(`Fiscal24. ${Message}`, url, Status, 'fiscal_purchases'));
    }
  }
};

const doFiscalOperationError = ({
  Status,
  Message,
  url,
  typeOperation,
  showCheckAfter,
  successCallback,
  req,
  dispatch
}) => {
  if (Status === 452) { // неактивный uid
    dispatch(checkGetUidTryCount(req));
  } else if (Status === 423) { // Касса заблокирована
    dispatch(showError('Смена просрочена. Закройте текущую смену и откройте новую'));
  } else if (Status === 449) {
    setToLocalStorage('encashmentBlockTime', moment());
    dispatch(showWarning('Это может занять до 10 минут. На это время инкассация в терминале будет не доступна.', 'Инкассация в фискал добавлена в очередь на обработку'));
    doFiscalOperationSuccess(
      typeOperation,
      successCallback,
      showCheckAfter,
      '',
      dispatch,
    );

    dispatch(getFiscalBalances());
  } else {
    logErrorToSentry(`Fiscal24. ${Message}`, url, Status, `fiscal_${typeOperation}`);

    if (Status !== 401) {
      dispatch(showError(`Fiscal24. ${Message}`, url, Status, `fiscal_${typeOperation}`));
    }
  }
};

const doFiscalOperationSuccess = (
  typeOperation,
  successCallback,
  showCheckAfter,
  Receipt,
  dispatch,
) => {
  // служебный расход

  if (typeOperation === 'expenses' && typeof successCallback === 'function') {
    dispatch(successCallback());
  }

  // Печатаем чек
  if (showCheckAfter) {
    printCheckFromImg(Receipt, 0, true);
  }
};

const getFiscalBalancesSuccess = (Data, dispatch) => {
  const balances = {
    wasRequested: true,
    cash: '',
    card: ''
  };

  Data.Balances.map(({ TypeBalance, Amount }) => {
    if (TypeBalance.Id === 1) { // наличные
      balances.cash = Number(Amount);
    }
    if (TypeBalance.Id === 2) { // безнал
      balances.card = Number(Amount);
    }

    return balances;
  });

  dispatch({ type: types.SET_FISCAL_BALANCES, balances });
};

const getOperationDetailSuccess = (printAfterGetting, Data, dispatch) => {
  if (printAfterGetting) {
    if (!Data.Reciept.includes('Check API Error')) {
      printCheckFromImg(Data.Reciept, 0, true);
    } else {
      dispatch(showError('Чек для данной транзакции не найден'));
    }
  } else {
    dispatch(setFiscalReceipt(Data.Reciept));
  }
};

const doFiscalRefundSuccess = (Receipt, dispatch) => {
  // Печатаем чек
  if (Receipt && !Receipt.includes('Check API Error')) {
    printCheckFromImg(Receipt, 0, true);
  } else {
    dispatch(showError('Повторите попытку позднее', 'Не удалось распечатать чек'));
  }
};

const doFiscalRefundError = (Status, Message, url, req, dispatch) => {
  if (Status === 452) { // неактивный uid
    dispatch(checkGetUidTryCount(req));
  } else if (Status === 423) { // Касса заблокирована
    dispatch(showError('Закройте текущую смену и откройте новую', 'Смена просрочена'));
  } else {
    logErrorToSentry(`Fiscal24. ${Message}`, url, Status, 'FISCAL_REFUND');

    if (Status !== 401) {
      dispatch(showError(`Fiscal24. ${Message}`, url, Status, 'FISCAL_REFUND'));
    }
  }
};

export const checkFiscalToken = () => (dispatch) => {
  if (isFiscalTokenAlive()) {
    return true;
  }

  setFiscalData({ token: '' });
  resetApAuthData();

  dispatch(setIsAuthInFiscal(false));
  dispatch(setAuthDataIsRequested(false));
  dispatch(goToEndSessionPage());

  return false;
};

export const checkFiscalAuthCommon = () => (dispatch, getState) => {
  const { fiscal: { isAuthInFiscal, authDataIsRequested } } = getState();
  const { authCashier } = getAuthData();

  if (!authDataIsRequested && !authCashier) {
    dispatch(getFiscalAuthDataCommonRequest());
  }

  if (authCashier && !isAuthInFiscal) {
    if (dispatch(checkFiscalToken())) {
      dispatch(setIsFiscal(true));
      dispatch(setIsAuthInFiscal(true));
      dispatch(setInitialRequestCompleted('auth'));
    }
  }
};

export const getZReportList = (dateFrom, dateTo) => (dispatch) => {
  const { token, idKkm, } = getFiscalData();

  if (token) {
    const url = `${FISCAL_API_URL}/kkms/${idKkm}/zlog?datefrom=${dateFrom}&dateto=${dateTo}`;

    dispatch(startLoader());

    return axios.get(url, { headers: getHeaders(token) })
      .then(({ data: { Status, Message, Data } }) => {
        if (Status === 200) {
          dispatch({ type: types.SET_Z_REPORT_HISTORY_DATA, zReportHistory: Data });
        } else {
          dispatch(logAndShowError(`Fiscal24. ${Message}`, url, Status, 'Z_REPORT'));
        }

        dispatch(stopLoader());
      })
      .catch(({ response: { data: { Status, Message } } }) => {
        logErrorToSentry(`Fiscal24. ${Message}`, url, Status, 'Z_REPORT');

        if (Status !== 401) {
          dispatch(showError(`Fiscal24. ${Message}`, url, Status, 'Z_REPORT'));
        }
        dispatch(stopLoader());
      });
  }

  dispatch(showError('Не удалось подключиться к фискалу, обновите страницу'));
};

export const sendPaysToFiscal = (snList) => (dispatch, getState) => {
  const { token, idKkm } = getFiscalData();

  if (token) {
    const requestDataDocuments = [];
    const { paymentsHistory } = getState().payment;
    const url = `${FISCAL_API_URL}/kkms/${idKkm}/load_offline_docs`;

    paymentsHistory.forEach((pay) => {
      const payData = String(pay.date);
      const date = `${payData.slice(0, 8)} ${payData.slice(-6)}`;

      setObjectProperty(pay, 'formattedDate', `${moment(date).subtract(12, 'hours').format('YYYY-MM-DDTHH:mm:ss')}.000000001+06:00`);

      if (snList.includes(pay.sn)) {
        requestDataDocuments.push(getFiscalPayDocumentTemplate({ pay, idKkm }));
      }
    });

    const requestData = { Documents: requestDataDocuments };

    if (requestData.Documents.length > 0) {
      dispatch(startLoader());

      return axios.post(url, { ...requestData }, { headers: getHeaders(token) })
        .then(({ data: { Status } }) => {
          if (Status === 200) {
            dispatch({ type: SET_PAYMENT_HISTORY_SN_LIST, snListForTodayPayments: [] });
            dispatch({ type: types.SET_FISCAL_X_REPORT_SN_LIST, fiscalXReportsSnList: [] });
            dispatch(getFiscalBalances());
            dispatch(showSuccess('Платежи успешно отправлены в фискал'));
          }

          dispatch(stopLoader());
        })
        .catch(({ response: { data: { Status, Message } } }) => {
          logErrorToSentry(`Fiscal24. ${Message}`, url, Status, 'SN_SEND_TO_FISCAL');

          dispatch(stopLoader());
        });
    }
    dispatch(showError('Нет платежей для отправки в фискал'));

    return;
  }
  dispatch(showError('Не удалось подключиться к фискалу, обновите страницу'));
};

