/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { b64EncodeUnicode, t } from 'helpers/main';
import IinField from 'components/IinField';

/* АО Нурбанк. Данные для теста (ИИН: 910502301156;  iban: KZ9384901KZ002249403) */
const Wooppay = ({
  onlineCheck,
  showError,
  iinIsValidOrEmpty,
  updateFieldsValue,
  showEnterAmountScreen,
  setConfirmInfo,
  initPayScheme,
  pay: { account },
  kassa: { currentService },
  wooppay: { BIK, FIO, IBAN, IIN },
}) => {
  const [userAccount, setUserAccount] = useState();
  const [userIban, setUserIban] = useState();

  useEffect(() => {
    const onKeyDownFowWindow = (e) => {
      if (e.key === 'Enter') {
        validateAndCheck();
      }
    };

    window.addEventListener('keydown', onKeyDownFowWindow);

    return () => {
      window.removeEventListener('keydown', onKeyDownFowWindow);
    };
  }, [userAccount, userIban]);

  useEffect(() => {
    const getAddings = () => ({
      constraint: IBAN,
      memo: b64EncodeUnicode(FIO),
    });

    const confirm = () => {
      const confirmFields = [
        { name: 'account', value: account },
        { name: 'FIO', value: FIO },
        { name: 'IIN', value: IIN },
        { name: 'BIK', value: BIK },
        { name: 'IBAN', value: IBAN },
      ];

      const checkInfo = {
        input: {
          iin: account,
        }
      };

      initPayScheme();
      setConfirmInfo(confirmFields, getAddings(), checkInfo);
      showEnterAmountScreen();
    };

    if (FIO) confirm();
  }, [account, FIO, IIN, BIK, IBAN, setConfirmInfo, showEnterAmountScreen]);

  const validateAndCheck = useCallback(() => {
    if (!iinIsValidOrEmpty()) return;

    if (userAccount) {
      const userData = {
        account: userAccount,
        iban: userIban
      };

      updateFieldsValue(userData);
      onlineCheck(currentService, `${userAccount};${userIban}`);
    } else {
      return showError(
        'Проверьте, пожалуйста, и повторите попытку',
        'Все поля обязательны для заполнения'
      );
    }
  }, [userAccount, currentService, userIban, onlineCheck, showError]);

  return (
    <div className="pay-form">
      <IinField />
      <input
        id="account"
        placeholder="№ лицевого счета"
        onChange={(e) => setUserAccount(e.target.value)}
        maxLength={12}
      />
      <input
        id="iban"
        placeholder="IBAN"
        onChange={(e) => setUserIban(e.target.value)}
        className="pay-input"
        maxLength={20}
      />
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => validateAndCheck()}
      >
        {t('next')}
      </button>
    </div>
  );
};

Wooppay.propTypes = {
  pay: PropTypes.object.isRequired,
  kassa: PropTypes.object.isRequired,
  wooppay: PropTypes.object.isRequired,
  onlineCheck: PropTypes.func.isRequired,
  updateFieldsValue: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  setConfirmInfo: PropTypes.func.isRequired,
  showEnterAmountScreen: PropTypes.func.isRequired,
  initPayScheme: PropTypes.func.isRequired,
  iinIsValidOrEmpty: PropTypes.func.isRequired,
};

export default Wooppay;
