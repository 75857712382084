import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactToPrint from 'react-to-print';
import Modal from 'components/UI/Modal/modal';
import { formatMoney } from 'helpers/main';
import CustomButton from 'components/UI/Button/button';
import { getZLogOperationsByShift, getOperationDetail } from 'actions/fiscal';
import ReportReceiptContent from 'components/Reports/ReportReceiptContent';
import ReportResultTable from 'components/Reports/ReportResultTable';
import ReportOperations from 'components/Reports/ReportOperations';

const ZReportDetail = (props) => {
  const { report, onClose } = props;
  const [componentRef, setComponentRef] = useState({});
  const [operations, setOperations] = useState([]);
  const titlePrint = 'Дубликат Z-отчётов';

  useEffect(() => {
    props.getZLogOperationsByShift(report.IdShift).then((result) => {
      if (result?.Operations) setOperations(result.Operations);
    });
    // eslint-disable-next-line
  }, [report]);

  const handleReportRowClick = useCallback((operationId) => {
    props.getOperationDetail({ IdDocument: operationId });
    // eslint-disable-next-line
  }, []);

  const table = (
    <div className="fiscal-report">
      <div className="fiscal-report-info">
        <div className="card">
          <div className="header-block">
            Основная информация
          </div>
          <div>
            <table>
              <tbody>
                <tr>
                  <td>Наименование организации</td>
                  <td>{report.Kkm.Company.FullName} </td>
                </tr>
                <tr>
                  <td>ИИН/БИН</td>
                  <td>{report.Kkm.Company.Bin}</td>
                </tr>
                <tr>
                  <td>ККМ</td>
                  <td>{report.IdKkm}</td>
                </tr>
                <tr>
                  <td>Регистрационный номер в КГД</td>
                  <td>{report.Rnm}</td>
                </tr>
                <tr>
                  <td>Название кассы</td>
                  <td>{report.Kkm.Name}</td>
                </tr>
                <tr>
                  <td>Адрес торговой точки</td>
                  <td>{report.Kkm.Company.Address.Town.Name},
                    {report.Kkm.Company.Address.Street}
                    {report.Kkm.Company.Address.House},
                    {report.Kkm.Company.Address.Flat}
                  </td>
                </tr>
                <tr>
                  <td>Кассир</td>
                  <td>{report.User.Name}</td>
                </tr>
                <tr>
                  <td><hr /></td>
                  <td><hr /></td>
                </tr>
                <tr>
                  <td>Баланс на момент открытия смены</td>
                  <td>{formatMoney(report.BalanceOpen)} тг</td>
                </tr>
                <tr>
                  <td>Оформлено чеков</td>
                  <td>
                    <span>{report.Count}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="issues-receipt">
          <div className="header-block">
            Оформленные чеки
          </div>
          <div>
            <ReportReceiptContent title="Служебный приход" count={report.IncomesQty} sum={report.IncomesAmount} />
            <ReportReceiptContent title="Служебный расход" count={report.ExpensesQty} sum={report.ExpensesAmount} />
            <ReportReceiptContent title="Продажи" count={report.SalesQty} sum={report.SalesAmount} />
            <ReportReceiptContent title="Возвраты продаж" count={report.RefundsQty} sum={report.RefundsAmount} />
            <ReportReceiptContent title="Покупки" count={report.PurchasesQty} sum={report.PurchasesAmount} />
            <ReportReceiptContent title="Возвраты покупок" count={report.PurchaseRefundsQty} sum={report.PurchaseRefundsAmount} />
          </div>
        </div>

      </div>
      <ReportOperations operations={operations} onClickRow={handleReportRowClick}/>
    </div>
  );

  const getPrintButton = useCallback(() => (
    <CustomButton >
      Распечатать
    </CustomButton>
  ), []);

  return (
    <Modal
      title="Дубликат Z-отчётов"
      isOpen
      onOk={null}
      onClose={onClose}
      okText=""
      size="high"
      isFooterHidden
    >{report && (
      <>
        <ReactToPrint
          trigger={getPrintButton}
          content={() => componentRef}
        />
        <ReportResultTable
          setComponentRef={setComponentRef}
          title={titlePrint}
          table={table}
        />
      </>
    )}
      {table}
    </Modal>
  );
};

ZReportDetail.propTypes = {
  report: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  getOperationDetail: PropTypes.func.isRequired,
  getZLogOperationsByShift: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    getOperationDetail,
    getZLogOperationsByShift
  },
  dispatch);

export default connect(null, mapDispatchToProps)(ZReportDetail);
