import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/UI/Icon/index';
import { formatMoney, getHumanDateTime } from 'helpers/main';
import CustomButton from 'components/UI/Button/button';
import ReactTooltip from 'react-tooltip';

const ReportOperations = ({ operations, onClickRow }) => (
  <div className="fiscal-operations">
    <div className="header-block">
      Cписок операций
    </div>
    <div>
      {operations.length ? (
        <table>
          <thead>
            <tr>
              <td>Наименование</td>
              <td>Дата транзакции</td>
              <td>Номер транзакции</td>
              <td>Сумма</td>
              <td className="hideForPrint">Дубликат чека</td>
            </tr>
          </thead>
          <tbody>
            {operations.map(({
              TypeDocument: { Name },
              DateDocument,
              NumberDoc,
              Value,
              Id
            }) => (
              <tr key={NumberDoc}>
                <td>{Name}</td>
                <td>{getHumanDateTime(DateDocument)}</td>
                <td>{NumberDoc}</td>
                <td>{formatMoney(Value)} тг</td>
                <td className="hideForPrint">
                  <ReactTooltip id="tooltip-print-ref" backgroundColor="#232329">Печатать</ReactTooltip>
                  <CustomButton
                    data-tip
                    data-for="tooltip-print-ref"
                    onClick={() => onClickRow(Id)}
                  >
                    <Icon type="print" />
                  </CustomButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )
        :
        'Операций не производилось'}
    </div>
  </div>
);

ReportOperations.propTypes = {
  operations: PropTypes.array.isRequired,
  onClickRow: PropTypes.func.isRequired
};

export default ReportOperations;
