import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MaskedInput from 'react-maskedinput';
import ReactTooltip from 'react-tooltip';

import Icon from 'components/UI/Icon/index';
import { setClientIIN } from 'actions/pay';

const IinField = ({ clientIin, setClientIIN: setClientIINInStore }) => {
  const [showInput, setShowInput] = useState(false);

  const handleChangeIIN = useCallback((e) => {
    setClientIINInStore(e.target.value.trim());
  }, [setClientIINInStore]);

  const toggleShowInput = useCallback(() => {
    setShowInput(!showInput);
  }, [showInput]);

  return (
    <div style={{ padding: '10px 0' }}>
      <ReactTooltip id="tooltip-ref" backgroundColor="#232329">
        <div style={{ width: 300 }}>
          В соответствии с Законом РК от 24 июня 2021 года № 53-VII с 01.01.2022 г.
          пункт 7 статьи 166 Налогового кодекса дополняется частью второй и будет изложен
          в следующей редакции: по требованию покупателя (клиента), получателя товаров,
          работ, услуг чек ккм должен содержать идентификационный номер покупателя (клиента),
          получателя.
        </div>
      </ReactTooltip>
      <button type="button" onClick={toggleShowInput} className="buttonWithoutStyles">
        <Icon
          type="question"
          className="pay-form-icon"
          data-tip
          data-for="tooltip-ref"
        />
        <span style={{ verticalAlign: 'top', cursor: 'pointer' }}>ИИН клиента</span>
      </button>
      <br />
      {showInput && (
        <MaskedInput
          mask="111111111111"
          type="tel"
          id="iin"
          autoComplete="off"
          maxLength={12}
          placeholder="Введите ИИН"
          placeholderChar=" "
          onChange={handleChangeIIN}
          value={clientIin}
        />
      )}
      <hr />
    </div>
  );
};

IinField.propTypes = {
  clientIin: PropTypes.string,
  setClientIIN: PropTypes.func.isRequired,
};

IinField.defaultProps = {
  clientIin: null,
};

const mapStateToProps = (state) => ({
  clientIin: state.payment.clientIin,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setClientIIN }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(IinField);
