import * as types from 'actions/pay/types';

const defaultState = {
  comment: '',
  account: '',
  amount: '',
  adding: [],
  checkInfo: [],
  dateOfBirth: '',
  confirmFields: [],
  depositedAmount: '', // внесенная сумма
  willBeCreditedAmount: '', // будет зачислено
  screenType: 0, // 0 - экраны схемы оплаты;  1 - экран ввода суммы;  2 - экран подтверждения
  getAddings: () => [], // передается, если надо вызвать этот метод на экране ввода сумм
  min: 0, // минимальная сумма платежа из онлайн-проверки (Dynamic 75)
  max: 0, // максимальная сумма платежа из онлайн-проверки (Dynamic 75)
};

const pay = (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_ADDING:
      return { ...state, adding: action.adding };
    case types.SET_CONFIRM_INFO:
      return {
        ...state,
        confirmFields: action.confirmFields,
        adding: (action.adding !== '') ? action.adding : [],
        checkInfo: [].concat(action.checkInfo),
        getAddings: action.getAddings,
        min: action.min ?? 0,
        max: action.max ?? 0
      };
    case types.SHOW_PAY_TYPE_SCREEN:
      return { ...state, screenType: 0 };
    case types.SHOW_ENTER_AMOUNT_SCREEN:
      return { ...state, screenType: 1 };
    case types.SHOW_CONFIRM_SCREEN:
      return { ...state, screenType: 2 };
    case types.UPDATE_FIELD_VALUE:
      return { ...state, [action.field]: action.value };
    case types.UPDATE_FIELDS_VALUE:
      return { ...state, ...action.fields };
    case types.CLEAR_ACCOUNT_AND_AMOUNT:
      return { ...state, account: '', amount: '' };
    case types.SELECTED_KASPI_IN_MODAL:
      return { ...state, value: action.value };
    case types.PAYMENT_INIT:
      return defaultState;
    default:
      return state;
  }
};

export default pay;
