/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import XLSX from 'xlsx';
import CustomButton from 'components/UI/Button/button';
import moment from 'moment';
import 'moment/locale/ru';
import { getTheStartDateOfTheCurrentDay, getTheEndOfTheCurrentDay, isInt, getFormatedJsDate } from 'helpers/main';
import { getSummaryReport } from 'actions/api/reports';
import { getServicesList } from 'actions/services';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import { Russian } from 'flatpickr/dist/l10n/ru';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReportSaveInstruction from 'public/files/report-save-instruction.pdf';

const SummaryReport = (props) => {
  const [dateFrom, setDateFrom] = useState(getTheStartDateOfTheCurrentDay());
  const [dateTo, setDateTo] = useState(getTheEndOfTheCurrentDay());
  const [summaryReportState, setSummaryReportState] = useState([]);
  const [componentRef, setComponentRef] = useState({});

  useEffect(() => {
    props.getSummaryReport(dateFrom, dateTo);

    if (!props.serviceList.length) {
      props.getServicesList();
    }
  }, []);

  useEffect(() => {
    setSummaryReport();
  }, [props.summaryReport]);

  const setSummaryReport = () => {
    const { summaryReport } = props;
    const tableData = [];

    if ([].concat(summaryReport.row).length) {
      let entryItogSum = 0;
      let entryItogCom = 0;
      let entryItogCnt = 0;
      let proccessItogSum = 0;
      let proccessItogCom = 0;
      let proccessItogCnt = 0;
      let noentryItogSum = 0;
      let noentryItogCom = 0;
      let noentryItogCnt = 0;
      let totalItogSum = 0;
      let totalItogCom = 0;
      let totalItogCnt = 0;
      let entryItogSumAndCom = 0;
      let proccessItogSumAndCom = 0;
      let noentryItogSumAndCom = 0;
      let totalItogSumAndCom = 0;

      [].concat(summaryReport.row).forEach((row, i) => {
        if (typeof row !== 'undefined') {
          const {
            idService,
            entry: { cnt: eCnt, amount: eAmount, comission: eCom },
            process: { cnt: pCnt, amount: pAmount, comission: pCom },
            noentry: { cnt: nCnt, amount: nAmount, comission: nCom }
          } = row;

          const totalSum = Number(nAmount) + Number(eAmount) + Number(pAmount);
          const totalCom = Number(nCom) + Number(eCom) + Number(pCom);
          const totalCount = Number(nCnt) + Number(eCnt) + Number(pCnt);

          entryItogSumAndCom += eAmount;
          entryItogSum += eAmount - eCom;
          entryItogCom += eCom;
          entryItogCnt += eCnt;
          proccessItogSumAndCom += pAmount;
          proccessItogSum += pAmount - pCom;
          proccessItogCom += pCom;
          proccessItogCnt += pCnt;
          noentryItogSumAndCom += nAmount;
          noentryItogSum += nAmount - nCom;
          noentryItogCom += nCom;
          noentryItogCnt += nCnt;
          totalItogSumAndCom += totalSum;
          totalItogSum += totalSum - totalCom;
          totalItogCom += totalCom;
          totalItogCnt += totalCount;

          tableData.push({
            key: i,
            number: i + 1,
            provider: getServiceNameOnId(idService),
            entrySumAndCom: getFormattedSum(eAmount),
            entrySum: getFormattedSum(eAmount - eCom),
            entryCom: eCom,
            entryCount: eCnt,
            proccessSumAndCom: getFormattedSum(pAmount),
            proccessSum: getFormattedSum(pAmount - pCom),
            proccessCom: pCom,
            proccessCount: pCnt,
            noentrySumAndCom: getFormattedSum(nAmount),
            noentrySum: getFormattedSum(nAmount - nCom),
            noentryCom: nCom,
            noentryCount: nCnt,
            totalSumAndCom: getFormattedSum(totalItogSumAndCom),
            totalSum: getFormattedSum(totalItogSum),
            totalCom: getFormattedSum(totalItogCom),
            totalCount: getFormattedSum(totalItogCnt),
          });
        }
      });

      tableData.push(getZeroFields(tableData.length + 1, tableData.length + 1, 'Служебный приход'));
      tableData.push(getZeroFields(tableData.length + 1, tableData.length + 1, 'Служебный расход'));

      tableData.push({
        key: '',
        provider: 'Итого',
        entrySumAndCom: getFormattedSum(entryItogSumAndCom),
        entrySum: getFormattedSum(entryItogSum),
        entryCom: getFormattedSum(entryItogCom),
        entryCount: getFormattedSum(entryItogCnt),
        proccessSumSumAndCom: getFormattedSum(proccessItogSumAndCom),
        proccessSum: getFormattedSum(proccessItogSum),
        proccessCom: getFormattedSum(proccessItogCom),
        proccessCount: getFormattedSum(proccessItogCnt),
        noentrySumAndCom: getFormattedSum(noentryItogSumAndCom),
        noentrySum: getFormattedSum(noentryItogSum),
        noentryCom: getFormattedSum(noentryItogCom),
        noentryCount: getFormattedSum(noentryItogCnt),
        totalSumAndCom: getFormattedSum(totalItogSumAndCom),
        totalSum: getFormattedSum(totalItogSum),
        totalCom: getFormattedSum(totalItogCom),
        totalCount: getFormattedSum(totalItogCnt),
      });

      tableData.push(getZeroFields(tableData.length + 1, '', 'Итого по служебному приходу'));
      tableData.push(getZeroFields(tableData.length + 1, '', 'Итого по служебному расходу'));
      tableData.push(getZeroFields(tableData.length + 1, '', 'Фискализация'));
      tableData.push(getZeroFields(tableData.length + 1, '', 'Покупка'));
    }

    setSummaryReportState(tableData);
  };

  const getFormattedSum = (sum) => isInt(sum) ? sum : parseFloat(sum).toFixed(2);

  const getZeroFields = (key, number, title) => ({
    key,
    number,
    provider: title,
    totalSum: 0,
    totalCom: 0,
    totalCount: 0,
  });

  const getServiceNameOnId = (id) => {
    const service = props.serviceList.filter(item => (item.idService === id));

    if (service.length) {
      return service[0].name;
    }

    return '';
  };

  const changeDateRange = (dates) => {
    if (dates.length === 2) {
      const dateFromFormat = moment(dates[0]).format('YYYY-MM-DD 00:00:01');
      const dateToFormat = moment(dates[1]).format('YYYY-MM-DD 23:59:59');
      const dateFromStr = moment(dateFromFormat).format('YYYYMMDDHHmmss');
      const dateToStr = moment(dateToFormat).format('YYYYMMDDHHmmss');

      setDateFrom(dateFromStr);
      setDateTo(dateToStr);
      props.getSummaryReport(dateFromStr, dateToStr);
    }
  };

  const exportToXlsx = () => {
    const workbook = XLSX.utils.table_to_book(document.getElementById('reportTable'));

    workbook.Sheets.Sheet1['!cols'] = [
      { width: 3 },
      { width: 40 },
      { width: 10 }, { width: 10 }, { width: 10 }, { width: 10 },
      { width: 10 }, { width: 10 }, { width: 10 }, { width: 10 },
      { width: 10 }, { width: 10 }, { width: 10 }, { width: 10 },
      { width: 10 }, { width: 10 }, { width: 10 }, { width: 10 },
    ];

    // eslint-disable-next-line
    XLSX.writeFile(workbook, `Отчет_за_период_${getFormatedJsDate()}.xls`, { bookType: 'xlsx', type: 'buffer' });
  };

  const table = (
    <TableContainer component={Paper} elevation={0}>
      <Table aria-label="spanning table" classes={{ root: 'cellBorder' }}>
        <TableHead classes={{ root: 'cellBorder' }}>
          <TableRow classes={{ root: 'cellBorder' }}>
            <TableCell classes={{ root: 'cellBorder' }} rowSpan={2} align="center" >
              №
            </TableCell>
            <TableCell classes={{ root: 'cellBorder' }} rowSpan={2} align="center" >
              Провайдер
            </TableCell>
            <TableCell classes={{ root: 'cellBorder' }} colSpan={4} align="center" >
              Проведенные
            </TableCell>
            <TableCell classes={{ root: 'cellBorder' }} colSpan={4} align="center">Обрабатываются</TableCell>
            <TableCell classes={{ root: 'cellBorder' }} colSpan={4} align="center">Не проведенные</TableCell>
            <TableCell classes={{ root: 'cellBorder' }} colSpan={4} align="center">Итого</TableCell>
          </TableRow>
          <TableRow classes={{ root: 'cellBorder' }}>
            <TableCell classes={{ root: 'cellBorder' }} align="center" >Принято</TableCell>
            <TableCell classes={{ root: 'cellBorder' }} align="center" >Зачислено</TableCell>
            <TableCell classes={{ root: 'cellBorder' }} align="center" >Комиссия</TableCell>
            <TableCell classes={{ root: 'cellBorder' }} align="center" >Кол-во</TableCell>

            <TableCell classes={{ root: 'cellBorder' }} align="center" >Принято</TableCell>
            <TableCell classes={{ root: 'cellBorder' }} align="center" >Зачислено</TableCell>
            <TableCell classes={{ root: 'cellBorder' }} align="center" >Комиссия</TableCell>
            <TableCell classes={{ root: 'cellBorder' }} align="center" >Кол-во</TableCell>

            <TableCell classes={{ root: 'cellBorder' }} align="center" >Принято</TableCell>
            <TableCell classes={{ root: 'cellBorder' }} align="center" >Зачислено</TableCell>
            <TableCell classes={{ root: 'cellBorder' }} align="center" >Комиссия</TableCell>
            <TableCell classes={{ root: 'cellBorder' }} align="center" >Кол-во</TableCell>

            <TableCell classes={{ root: 'cellBorder' }} align="center" >Принято</TableCell>
            <TableCell classes={{ root: 'cellBorder' }} align="center" >Зачислено</TableCell>
            <TableCell classes={{ root: 'cellBorder' }} align="center" >Комиссия</TableCell>
            <TableCell classes={{ root: 'cellBorder' }} align="center" >Кол-во</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {summaryReportState.map((item) => (
            <TableRow key={item.number}>
              <TableCell classes={{ root: 'cellBorder' }}>{item.number}</TableCell>
              <TableCell classes={{ root: 'cellBorder' }}>{item.provider}</TableCell>
              <TableCell classes={{ root: 'cellBorder' }}>{item.entrySumAndCom}</TableCell>
              <TableCell classes={{ root: 'cellBorder' }}>{item.entrySum}</TableCell>
              <TableCell classes={{ root: 'cellBorder' }}>{item.entryCom}</TableCell>
              <TableCell classes={{ root: 'cellBorder' }}>{item.entryCount}</TableCell>
              <TableCell classes={{ root: 'cellBorder' }}>{item.proccessSumAndCom}</TableCell>
              <TableCell classes={{ root: 'cellBorder' }}>{item.proccessSum}</TableCell>
              <TableCell classes={{ root: 'cellBorder' }}>{item.proccessCom}</TableCell>
              <TableCell classes={{ root: 'cellBorder' }}>{item.proccessCount}</TableCell>
              <TableCell classes={{ root: 'cellBorder' }}>{item.noentrySumAndCom}</TableCell>
              <TableCell classes={{ root: 'cellBorder' }}>{item.noentrySum}</TableCell>
              <TableCell classes={{ root: 'cellBorder' }}>{item.noentryCom}</TableCell>
              <TableCell classes={{ root: 'cellBorder' }}>{item.noentryCount}</TableCell>
              <TableCell classes={{ root: 'cellBorder' }}>{item.totalSumAndCom}</TableCell>
              <TableCell classes={{ root: 'cellBorder' }}>{item.totalSum}</TableCell>
              <TableCell classes={{ root: 'cellBorder' }}>{item.totalCom}</TableCell>
              <TableCell classes={{ root: 'cellBorder' }}>{item.totalCount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const dateFromStr = moment(dateFrom, 'YYYYMMDDHHmmss').format('DD.MM.YYYY');
  const dateToStr = moment(dateTo, 'YYYYMMDDHHmmss').format('DD.MM.YYYY');
  const titlePrint = `Итоговый отчет за период: ${dateFromStr} - ${dateToStr}`;

  const reactPrintBtn = () => <CustomButton >Распечатать</CustomButton>;

  return (
    <>
      <div className="payment-history-head">
        <Flatpickr
          options={{
            dateFormat: 'd-m-Y',
            mode: 'range',
            locale: Russian,
            defaultDate: [
              moment(dateFrom, 'YYYYMMDDHHmmss').format('DD-MM-YYYY'),
              moment(dateTo, 'YYYYMMDDHHmmss').format('DD-MM-YYYY')
            ]
          }}
          className="datepicker-wrapper"
          onChange={(dates) => changeDateRange(dates)}
        />
        <div>
          <a
            target="_blank"
            href={ReportSaveInstruction}
            rel="noopener noreferrer"
            className="link-block"
          >
            Как сохранить отчет в pdf-файл?
          </a>
          <CustomButton onClick={exportToXlsx}>Экспорт в excel</CustomButton>
          <ReactToPrint
            trigger={reactPrintBtn}
            content={() => componentRef}
          />
        </div>
        <div style={{ display: 'none' }} id="reportTable">
          <div
            ref={el => {
              setComponentRef(el);
            }}
            style={{ padding: 20 }}
          >
            {titlePrint}
            <div style={{ marginTop: 20 }}>
              {table}
            </div>
          </div>
        </div>
      </div>
      {table}
    </>
  );
};

SummaryReport.propTypes = {
  summaryReport: PropTypes.object.isRequired,
  getSummaryReport: PropTypes.func.isRequired,
  getServicesList: PropTypes.func.isRequired,
  serviceList: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    summaryReport: state.kassa.summaryReport,
    serviceList: state.services.serviceList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getSummaryReport, getServicesList }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryReport);
