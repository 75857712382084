import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showError } from 'actions/alert';
import { hideFiscalModal, doFiscalOperation } from 'actions/fiscal';
import PropTypes from 'prop-types';
import Modal from 'components/UI/Modal/modal';
import Input from 'components/UI/Input/input';
import { FISCAL_SCREENS } from 'constants/fiscal';

const IncomeAndOutcome = (props) => {
  const { showModal, modalTitle, operation, cashFunds, screenType } = props;
  const [amount, setAmount] = useState('');
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (amount) return setIsValid(true);
  }, [amount]);

  const validateAndSend = () => {
    if (!amount) return setIsValid(false);

    // Если операция = расход, то проверяем хватает ли баланса
    if (
      (screenType === FISCAL_SCREENS.INCOME) ||
      (screenType === FISCAL_SCREENS.OUTCOME && cashFunds >= Number(amount))
    ) {
      const reqData = {
        Amount: Number(amount)
      };

      props.doFiscalOperation({ reqData, typeOperation: operation, showCheckAfter: true });
      props.hideFiscalModal();
      setAmount(0);
    } else {
      props.showError(
        'Обнаружена нехватка средств в кассе для совершения данной операции. Пожалуйста попробуйте немного позднее',
        'В кассе недостаточно средств'
      );
    }
  };

  return (
    <Modal
      title={modalTitle}
      isOpen={showModal}
      onOk={() => validateAndSend()}
      onClose={() => props.hideFiscalModal()}
      okText="Оформить чек"
      size="small"
    >
      <div className="fiscal-income">
        <Input
          type="number"
          placeholder="0"
          id="productName"
          onchange={(value) => setAmount(value.trim())}
          value={String(amount)}
          label="Сумма"
          errorMessage="Поле обязательно для заполнения"
          isError={!isValid}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  showModal: state.fiscal.modalData.showModal,
  screenType: state.fiscal.modalData.screenType,
  cashFunds: state.fiscal.balances.cash,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    showError,
    hideFiscalModal,
    doFiscalOperation
  },
  dispatch);

IncomeAndOutcome.propTypes = {
  showModal: PropTypes.bool.isRequired,
  showError: PropTypes.func.isRequired,
  hideFiscalModal: PropTypes.func.isRequired,
  doFiscalOperation: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  operation: PropTypes.string.isRequired,
  cashFunds: PropTypes.number.isRequired,
  screenType: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncomeAndOutcome);
