import * as types from 'actions/payTypes/wooppay/types';

const defaultState = {
  IIN: '',
  IBAN: '',
  FIO: '',
  BIK: '',
};

const wooppay = (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_WOOPPAY_DATA:
      return { ...state,
        IIN: action.IIN,
        IBAN: action.IBAN,
        FIO: action.FIO,
        BIK: action.BIK
      };
    case 'WOOPPAY_INIT':
      return defaultState;
    default:
      return state;
  }
};

export default wooppay;
