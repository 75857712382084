import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/UI/Modal/modal';
import Input from 'components/UI/Input/input';
import { validateEmail } from 'helpers/main';

const SendCheckToMail = (props) => {
  const { sn, setShowSendCheckToMailModal } = props;
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isEmailCorrect, setIsEmailCorrect] = useState(true);

  const validateAndSend = () => {
    if (!email) {
      setIsEmailValid(false);
    }

    if (email) {
      if (validateEmail(email)) {
        props.sendCheckToEmail(sn, email);
        setShowSendCheckToMailModal(false);
      } else {
        setIsEmailCorrect(false);
        setIsEmailValid(true);
      }
    }
  };

  const getErrMsg = useCallback(
    () => {
      if (!isEmailValid) {
        return 'Поле обязательно для заполнения';
      }

      if (!isEmailCorrect) {
        return 'Email некорректен';
      }
    },
    [isEmailValid, isEmailCorrect],
  );

  return (
    <Modal
      isOpen
      title="Отправка чека на email"
      onOk={() => validateAndSend()}
      onClose={() => setShowSendCheckToMailModal(false)}
      okText="Отправить"
      size="small"
    >
      <Input
        placeholder="Введите email"
        onchange={(value) => setEmail(value.trim())}
        value={email}
        errorMessage={getErrMsg()}
        isError={!isEmailValid || !isEmailCorrect}
      />
    </Modal>
  );
};

SendCheckToMail.propTypes = {
  sn: PropTypes.string.isRequired,
  sendCheckToEmail: PropTypes.func.isRequired,
  setShowSendCheckToMailModal: PropTypes.func.isRequired,
};

export default SendCheckToMail;

