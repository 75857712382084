import { CASHOUT_SCREENS } from 'constants/payTypes';
import * as types from 'actions/payTypes/cashout/types';

const defaultState = {
  showCashOutModal: false,
  step: CASHOUT_SCREENS.ENTER_DATA_STEP,
  CashToTerminalAmount: 0,
  CashToTerminalId: 0,
  AgentName: '',
  confirmed: false,
  id: '',
};

const cashout = (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_SHOW_CASHOUT_MODAL:
      return {
        ...state,
        showCashOutModal: action.showCashOutModal
      };
    case types.SET_CASHOUT_DATA:
      return {
        ...state,
        step: CASHOUT_SCREENS.CHECK_DATA_STEP,
        CashToTerminalAmount: action.CashToTerminalAmount,
        CashToTerminalId: action.CashToTerminalId,
        AgentName: action.AgentName,
        id: action.id
      };
    case types.SET_CASHOUT_CONFIRMED:
      return {
        ...state,
        step: CASHOUT_SCREENS.CASHOUT_CONFIRMED,
      };
    case types.SET_CASHOUT_ACCOUNT_ID:
      return {
        ...state,
        step: CASHOUT_SCREENS.REQUEST_NOT_FOUND_STEP,
        id: action.id
      };
    case types.SET_STEP:
      return {
        ...state,
        step: action.step,
      };
    case types.SET_CONFIRMED:
      return {
        ...state,
        confirmed: action.confirmed,
      };
    default:
      return state;
  }
};

export default cashout;
