import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/UI/Modal/modal';

const FiscalNotSendedPays = ({ sn, onClose, onOk, onBack }) => (
  <Modal
    isOpen
    size="small"
    title="Внимание! Непроведенные платежи в фискал!"
    onOk={onOk}
    onClose={onClose}
    okText="Провести"
    cancelText="Продолжить без проведения"
    onBack={onBack}
  >
    <div>
      <div>У вас есть платежи, непроведенные в фискал. Номера транзакций: </div>
      <div style={{ whiteSpace: 'pre' }}>{sn.join('\r\n')}</div>
    </div>
  </Modal>
);

FiscalNotSendedPays.propTypes = {
  sn: PropTypes.array.isRequired,
  onClose: PropTypes.array.isRequired,
  onBack: PropTypes.array.isRequired,
  onOk: PropTypes.func.isRequired
};

export default (FiscalNotSendedPays);

