/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useEffect, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getCommissionProfileOnServiceID } from 'model/CommissionProfiles';
import { setCurrentComProfile } from 'actions/commission';
import { logErrorToSentry } from 'helpers/main';

const CommissionProfile = (props) => {
  const { commissionProfiles, currentComProfile, currentService: { idService } } = props;

  useEffect(() => {
    if (typeof currentComProfile.positions === 'undefined') {
      findAndSetComProfile();
    }
  }, [currentComProfile.positions]);

  useEffect(() => {
    findAndSetComProfile();
  }, [commissionProfiles.length, idService]);

  const findAndSetComProfile = useCallback(
    () => {
      if (commissionProfiles.length) {
        const profileFromProps = getProfileFromProps();

        console.log('profileFromProps', profileFromProps);
        props.setCurrentComProfile(profileFromProps);
      } else {
        getProfileFromIndexedDB()
          .then(profileFromIDB => {
            console.log('getProfileFromIndexedDB', profileFromIDB);
            props.setCurrentComProfile(profileFromIDB);
          })
          .catch((err) => logErrorToSentry(`Ошибка получения профиля из IDB: ${err.message}`, '', 0, 'GET_PROFILE_FRON_IDB'));
      }
    },
    [commissionProfiles.length, idService]);

  // Ищем профиль в пропсах
  const getProfileFromProps = useCallback(() => {
    const currentProfile = commissionProfiles.filter((profile) => {
      try {
        const { services } = profile;

        if (typeof services !== 'undefined') {
          if (Array.isArray(services.idService)) {
            return services.idService.includes(idService);
          }

          return services.idService.toString() === idService.toString();
        }

        return false;
      } catch (err) {
        logErrorToSentry(
          `Не смог вытащить профиль из пропсов ${err.message}`,
          10,
          0,
          'GET_PROFILE_FROM_PROPS',
        );
      }

      return false;
    });

    if (Array.isArray(currentProfile)) {
      if (currentProfile.length) {
        return currentProfile[0];
      }

      return {};
    }

    if (typeof currentProfile !== 'undefined') {
      return currentProfile;
    }

    return {};
  },
  [commissionProfiles, idService]);

  // Ищем профиль в indexedDB и сохраняем его в пропсы
  const getProfileFromIndexedDB = useCallback(() =>
    getCommissionProfileOnServiceID(idService)
      .then(findedComProfile => {
        if (typeof findedComProfile !== 'undefined' && findedComProfile !== null) {
          return findedComProfile;
        }
      }),
  [idService]);

  return null;
};

CommissionProfile.propTypes = {
  commissionProfiles: PropTypes.object.isRequired,
  currentComProfile: PropTypes.object.isRequired,
  currentService: PropTypes.object.isRequired,
  setCurrentComProfile: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  currentService: state.kassa.currentService,
  commissionProfiles: state.commission.commissionProfiles,
  currentComProfile: state.commission.currentComProfile,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ setCurrentComProfile }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CommissionProfile);
