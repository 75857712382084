import React from 'react';
import PropTypes from 'prop-types';

const DefaultField = ({ handleOnChange, name, placeholder, value, disabled }) => (
  <div className="form-group" key={name}>
    <input
      className="form-control"
      autoComplete="off"
      name={name}
      id={name}
      maxLength="40"
      onChange={(e) => handleOnChange({ id: e.target.id, value: e.target.value })}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
    />
  </div>
);

DefaultField.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default DefaultField;
