export const FISCAL_API_URL = 'https://api.fiscalv2.test.tech24.kz';
export const PULT_API_URL = 'https://pult-test.kassa24pay.kz';
export const GATEWAY_URL = 'https://gateway.kassa24.kz/';
export const CHECK_API_URL = 'https://checkapi.kassa24.kz/api/v1.0.0';
export const LOGO_URL = 'https://pics.kassa24.kz/ap/prov_content/RU/';
export const WEBSOCKET_URL = 'wss://gateway.termsys.tech24.kz/gateway';
export const API_KASSA24_URL = 'https://plus24api.test.tech24.kz';

export default {
  FISCAL_API_URL,
  PULT_API_URL,
  GATEWAY_URL,
  CHECK_API_URL,
  API_KASSA24_URL,
  LOGO_URL,
};
