import React from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from 'helpers/main';

const ReportReceiptContent = ({ title, count, sum }) => (
  <>
    <h5>{title}</h5>
    {count ? (
      <table >
        <tbody>
          <tr>
            <td>Кол-во чеков</td>
            <td>
              <span>{count.toString().replace(/[^+\d]/g, '')}</span>
            </td>
          </tr>
          <tr>
            <td>Общая сумма</td>
            <td>{formatMoney(sum)} тг</td>
          </tr>
        </tbody>
      </table>
    ) :
      <span>Чеков нет</span>
        }
  </>
);

ReportReceiptContent.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  sum: PropTypes.number.isRequired,
};

export default ReportReceiptContent;
