import React from 'react';

const JysanOferta = () => (
  <div>
    <p><b>ОФЕРТА(предложение заключить договор)</b></p>
    <p>АО «Jysan bank» (далее – Банк) предлагает Вам заключить Договор о предоставлении
      электронных банковских услуг, который позволит Вам пользоваться электронными банковскими
      услугами, предоставляемыми Банком. До заключения указанного договора, просим Вас внимательно
      ознакомиться с его условиями.
    </p>

    <p><b>ДОГОВОР</b></p>
    <p><b>о предоставлении электронных банковских услуг</b></p>
    <p>
      <b>АО «Jysan bank»</b> именуемое в дальнейшем <b>«Банк»</b> с одной стороны,
      и <b>«Клиент»</b>, с другой стороны, далее вместе именуемые <b>«Стороны»</b>,
    </p>
    <p>
      принимая во внимание, что между Банком и Клиентом заключен Договор текущего
      банковского счета физического лица / Договор срочного банковского вклада(далее –
      Договор банковского счета), согласно которому Банк открыл клиенту текущий /
      сберегательный банковский счет(далее – Счет), заключили настоящий Договор о
      предоставлении электронных банковских услуг(далее - Договор) о нижеследующем:
    </p>

    <p><b>1. Предмет Договора</b></p>
    <p>Банком предоставляются электронные банковские услуги, связанные с переводом денег,
      внесенных Клиентом с использованием систем удаленного доступа, включая электронные
      терминалы, на его Счет. Порядок и отдельные условия предоставления электронных
      банковских услуг, в том числе порядок и сроки предоставления Банком подтверждения
      об отправке и(или) получении электронных документов, на основании которых Клиенту
      предоставлены электронные банковские услуги, процедуры безопасности, порядок аутентификации
      и подтверждения прав клиента на получение электронных банковских услуг,
      изложены в Правилах предоставления электронных банковских услуг для физических лиц в
      АО «Jysan bank», размещенных на Интернет – ресурсе Банка по
      адресу: <a href="www.tsb.kz" target="blank">www.tsb.kz</a>{' '}
      (далее – Интернет – ресурс Банка), а также в доступных для Клиента помещениях Банка
      (операционных подразделениях), и заключением настоящего Договора Клиент подтверждает
      свое ознакомление и согласие с ними.
    </p>
    <p>
      Электронные банковские услуги предоставляются на платной основе, согласно тарифам
      Банка(далее - Тарифы), действующим на день совершения операции.
    </p>
    <p>
      Предоставление / получение
      электронных банковских услуг осуществляется Банком и Клиентом в соответствии с
      законодательством Республики Казахстан, внутренними документами Банка и настоящим Договором.
    </p>
    <p>
      Предоставление Банком
      электронных банковских услуг осуществляется в соответствии с процедурами безопасности,
      установленными Правилами предоставления электронных банковских услуг для физических
      лиц в АО «Jysan bank».
    </p>
    <p>
      При осуществлении операций по Счету в иностранной валюте, операции
      осуществляются в соответствии с обменным курсом, установленным Банком на момент совершения
      операций.
    </p>
    <p>
      Информация об электронных банковских услугах, оказываемых по настоящему Договору,
      в том числе о Тарифах за оказание электронных банковских услуг, взымаемых в рамках
      настоящего Договора, размещена на Интернет – ресурсе Банка, а также в операционных залах
      филиалов Банка и их дополнительных помещениях в доступном для обозрения месте.
    </p>

    <p><b>2. Права и обязанности Клиента</b></p>
    <p>
      <b>Клиент имеет право: </b>
      <br />
      Получать электронные банковские услуги в порядке и на условиях, установленных
      законодательством Республики Казахстан, внутренними документами Банка и настоящим Договором.
      <br />
      Знакомиться с Тарифами Банка.
      <br />
      Знакомиться с Правилами предоставления электронных банковских услуг для физических
      лиц в АО «Jysan bank».
      <br />
      Расторгнуть в любое время Договор, предварительно уведомив Банк об этом не позднее
      5(пять) рабочих дней, до планируемой даты расторжения Договора.
      <br />
      <br />
      <b>Клиент обязан: </b>
      <br />
      При необходимости, для предоставления электронных банковских услуг, предоставлять
      надлежащим образом оформленные документы, истребуемые Банком в соответствии с
      законодательством Республики Казахстан, внутренними документами Банка и настоящим Договором.
      <br />
      Выполнять процедуры безопасности, указанные в Правилах предоставления электронных
      банковских услуг для физических лиц в АО «Jysan bank», для получения электронных
      банковских услуг и установления их подлинности в соответствии с условиями настоящего
      Договора, в том числе проходить процедуру динамической идентификации Клиента при
      совершении каждой операции.
    </p>

    <p><b>3. Права и обязанности Банка </b></p>
    <p>
      <b>Банк имеет право: </b>
      <br />
      Истребовать любые документы, предусмотренные законодательством Республики Казахстан и
      внутренними документами Банка для предоставления электронных банковских услуг, а также
      необходимые дополнительные документы при проведении операций посредством электронных
      банковских услуг.Изменять действующие Тарифы на оказание услуг, в т.ч.устанавливать
      дополнительные и(или) отменять действующие Тарифы на оказание услуг, о чем Клиент
      информируется не позднее 5(пять) рабочих дней путем размещения объявлений в
      доступных для Клиента помещениях Банка(операционных подразделениях), а также
      на Интернет – ресурсе Банка.Изменять перечень, порядок и условия предоставления
      электронных банковских услуг, в т.ч.включать дополнительные и(или) исключать
      действующие виды предоставляемых электронных банковских услуг, о чем Клиент
      информируется не позднее 5(пять) рабочих дней путем размещения объявлений в доступных
      для обозрения Клиента местах в помещениях Банка(операционных подразделениях), а
      также на Интернет – ресурсе Банка.Без предварительного уведомления Клиента на
      основании настоящего Договора производить изъятие(списание) денег со Счета Клиента
      без его согласия в случае удержания суммы комиссионного вознаграждения за
      предоставленные электронные банковские услуги согласно действующим Тарифам.
      <br />
      <b>
        Приостанавливать или прекращать предоставление Клиенту электронных банковских услуг
        в случаях:
      </b>
      <br />
      1) нарушения Клиентом порядка и условий получения электронных банковских услуг,
      предусмотренных Договором и Правилами предоставления электронных банковских услуг для
      физических лиц в АО «Jysan bank»;
      <br />
      2) неисправности технических средств, обеспечивающих оказание электронных банковских услуг;
      <br />
      3) поступления в Банк решения и(или) распоряжения уполномоченного государственного
      органа и(или) должностного лица о приостановлении расходных операций по Счету;
      <br />
      4) по иным основаниям, предусмотренным Гражданским кодексом
      Республики Казахстан, Законом Республики Казахстан «О банках и банковской деятельности в
      Республике Казахстан», Законом Республики Казахстан «О платежах и платежных системах»,
      Законом Республики Казахстан «О противодействии легализации(отмыванию) доходов, полученных
      преступным путем, и финансированию терроризма».О приостановлении или прекращении
      предоставления электронных банковских услуг, в случаях предусмотренных настоящим пунктом
      Договора, за исключением случаев приостановления или прекращения предоставления электронных
      банковских услуг, предусмотренных подпунктами 3) и 4) настоящего пункта Договора,
      Банк уведомляет Клиента непосредственно при обращении Клиента за получением электронных
      банковских услуг.Отказаться от исполнения Договора по основаниям и в порядке, предусмотренным
      Законом «О платежах и платежных системах» и Законом Республики Казахстан «О противодействии
      легализации(отмыванию) доходов, полученных преступным путем, и финансированию терроризма».
      <br />
      Банк направляет по адресу Клиента, указанному в реквизитах настоящего Договора, уведомление
      об отказе от исполнения настоящего Договора(полностью) в электронной форме или по почте
      (по усмотрению Банка) в течение 3(три) рабочих дней со дня принятия решения.
      <br />
      Договор считается расторгнутым с даты, указанной в уведомлении, при этом не требуется
      заключение между Сторонами каких - либо соглашений.Односторонний отказ от исполнения
      настоящего Договора не допускается в случаях, установленных Законом «О платежах и
      платежных системах».Банк обязан: Информировать Клиента о действующих Тарифах.
      <br />
      Предоставлять электронные банковские услуги в соответствии с законодательством
      Республики Казахстан и настоящим Договором.Возобновить предоставление Клиенту
      электронных банковских услуг, при устранении причин, указанных в п.3.5.Договора,
      повлекших приостановление права Клиента на получение банковских электронных услуг,
      с последующим его уведомлением письменно либо в электронной форме(по усмотрению
      Банка), за исключением случаев приостановления или прекращения предоставления
      электронных банковских услуг, предусмотренных подпунктами 3) и 4) пункта 3.5.
      настоящего Договора.
    </p>

    <p><b>4. Ответственность Сторон </b></p>
    <p>
      <b>Ответственность Банка: </b>
      <br />
      За совершение нарушений, связанных с банковским обслуживанием Клиента, Банк несет
      ответственность в пределах, установленных законодательством Республики Казахстан.
      <br />За неисполнение указаний Клиента по оказанию электронной банковской услуги, Банк по
      письменному требованию Клиента оплачивает ему неустойку в размере 0, 01(ноль целых одна сотая)
      процента от суммы операции за каждый день просрочки путем зачисления суммы неустойки по
      реквизитам, указанным в письменном требовании Клиента, в течение 10(десять) рабочих дней
      со дня получения письменного требования Клиента.В любых случаях ответственность Банка
      при нарушении условий настоящего Договора ограничивается размером реального ущерба,
      причиненного Клиенту неправомерными действиями / бездействием, размер которого должен
      быть подтвержден соответствующими документами.
      <br />
      <br />
      <b>Ответственность Клиента: </b>
      Клиент несет ответственность за законность совершаемых им операций посредством получения
      электронных банковских услуг в соответствии с законодательством Республики Казахстан,
      за достоверность представляемых в Банк документов.Исключение ответственности: Стороны
      не несут ответственности за случаи неисполнения и / или ненадлежащего исполнения
      обязательств по настоящему Договору, если такие случаи явились следствием наступления
      обстоятельств непреодолимой силы, включая, но не ограничиваясь: принятия уполномоченными
      государственными органами, Национальным Банком Республики Казахстан актов \мер
      ограничительно - запретительного характера, сбоев программного обеспечения, отключения
      электроэнергии, повреждения линии связи и других обстоятельств, не зависящих от воли
      Сторон и имеющих непосредственное отношение к Предмету настоящего Договора.
      <br />
      Банк не несет ответственности за ущерб, причиненный Клиенту, возникший в результате
      неисполнения и / или ненадлежащего исполнения Клиентом обязанностей, предусмотренных
      настоящим Договором.Банк не несет ответственности за убытки, причиненные Клиенту в результате
      приостановления операций по Счету и\или наложения ареста на деньги, находящиеся на Счете,
      осуществленных в соответствии с законодательством Республики Казахстан; а также за убытки,
      причиненные Клиенту в результате изъятия(списания) денег со Счета Клиента без его согласия
      третьими лицами.
      <br />
      Банк не несет ответственности перед Клиентом за неисполнение или ненадлежащее исполнение
      обязательств по настоящему Договору, возникшее в результате неясных, неполных или неточных
      инструкций Клиента, и по другим причинам, не зависящим от Банка.
      <br />
    </p>

    <p><b>5. Условия действия Договора </b></p>
    <p>
      Настоящий Договор заключается на срок предоставления Клиенту электронной банковской услуги
      (разовая операция) в порядке, предусмотренном п.7.2.настоящего Договора, и вступает в силу
      в дату его заключения.
    </p>

    <p><b>6. Порядок разрешения споров</b></p>
    <p>
      В части вопросов, не урегулированных настоящим Договором, Стороны руководствуются
      законодательством Республики Казахстан.Споры, связанные с настоящим Договором
      (в том числе, по несанкционированным операциям), не урегулированные путем переговоров,
      подлежат рассмотрению в Постоянно действующем Арбитраже «Большое жюри Казахстана»,
      одним или несколькими арбитрами, в соответствии с его регламентом, являющимся неотъемлемой
      частью настоящего арбитражного соглашения, в редакции, действующей на дату подачи
      заявления о рассмотрении спора, за исключением споров, не подведомственных арбитражам
      в соответствии с законодательством Республики Казахстан и подлежащих, в связи с этим,
      рассмотрению в компетентном суде.Решение Арбитража «Большое жюри Казахстана» является
      окончательным.
      <br />
      Регламент Постоянно действующего Арбитража «Большое жюри Казахстана» размещен на
      сайте <a href="www.kazcourt.kz" target="blank">www.kazcourt.kz.</a>» {' '}
    </p>

    <p><b>7. Заключительные положения</b></p>
    <p>
      Настоящий Договор составлен на государственном и русском языках.В случае разночтения
      текста Договора на государственном и русском языках Стороны руководствуются текстом
      Договора на русском языке.
      <br />
      Настоящий Договор заключается путем последовательного осуществления Клиентом следующих
      действий:
      <br />
      - нажатие кнопки «ДА» на экране электронного терминала после всплытия окна
      «С условиями вышеуказанного Договора о предоставлении электронных банковских услуг ознакомлен.
      Данные условия мне понятны и я принимаю их полностью и безоговорочно.»;
      <br />
      - нажатие кнопки «ДА» на экране электронного терминала после всплытия окна «Заключить
      Договор о предоставлении электронных банковских услуг ?».7.3.Датой заключения настоящего
      Договора является дата, когда Клиентом осуществлены действия, предусмотренные пунктом
      7.2 настоящего Договора. 7.4.Клиент подтверждает, что подписанием настоящего Договора,
      предоставляет письменное согласие и уполномочивает Банк на предоставление, на основании
      настоящего Договора сведений и информации о Клиенте и условиях настоящего Договора, а
      также иной информации, имеющей отношение к Клиенту и / или Договору, в том числе
      составляющую банковскую тайну, коммерческую или иную охраняемую законом тайну,
      без какого - либо дополнительного согласия Клиента на конфиденциальной основе в
      порядке, предусмотренном законодательством Республики Казахстан, третьим лицам,
      с которыми Банком заключены договоры / соглашения или которые наняты или будут
      наняты Банком, акционерами Банка и / или аффилиированными лицами Банка для оказания
      третьими лицами услуг(консультативных, юридических, аудиторских и иных услуг).
      При этом данная информация и сведения предоставляются указанным третьим лицам в том объеме,
      который необходим и достаточен для надлежащего оказания услуг Банку, акционерам Банка и /
      или аффилиированным лицам Банка, а равно как для осуществления Банком своей деятельности
      в рамках законодательства Республики Казахстан(предоставление отчетов / отчетности /
      информации Национальному Банку Республики Казахстан, органам государственных доходов,
      а также в иных случаях, при которых возникает / имеется необходимость / обязанность
      предоставления такой информации / сведений).
      <br />
      В остальных случаях Банк гарантирует конфиденциальность полученной от Клиента информации.
      <br />
      Настоящий Договор считается незаключенным, в случае отказа Клиента от получения
      электронной банковской услуги(отказа в совершении операции).
    </p>

    <br />
    <p>
      <b>ЮРИДИЧЕСКИЙ АДРЕС И РЕКВИЗИТЫ БАНКА: </b>
      <br />
      <b>БАНК</b>
      <br />
      БАНК АО «Jysan bank», 010000, г.Астана, район Есиль, ул.Сығанақ, д. 24,
      <br />
      БИН 920140000084, ИИК KZ48125KZT1001300336 в НБ РК, БИК TSESKZKA, КБЕ 14,
      КОД ОКПО 199247930000,
      <br />
      <b>Справка о государственной перерегистрации юридического лица от 26.12.2003 г.
        (внесены изменения и дополнения 19 мая 2014 года).
      </b>
      <br />
      <b>С условиями вышеуказанного Договора о предоставлении электронных банковских услуг
        ознакомлен.Данные условия мне понятны и я принимаю их полностью и безоговорочно.
      </b>
    </p>
  </div>
);

export default JysanOferta;
