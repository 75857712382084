/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MaskedInput from 'react-maskedinput';

import Icon from 'components/UI/Icon/index';
import Input from 'components/UI/Input/input';
import CustomButton from 'components/UI/Button/button';
import * as AuthActions from 'actions/api/auth';
import { showError } from 'actions/alert';
import { goToMainPage } from 'actions/navigate';
import { getAuthData } from 'model/UserDb';
import { useWindow } from 'hooks';
import { getClearPhone } from 'helpers/main';
import { getFiscalData } from 'helpers/fiscal';
import { TERMINALS_WITH_CERT, TERMINALS_WITH_CERT_DATE_EXPIRE } from 'constants/terminals';
import moment from 'moment';
import Alert from 'components/UI/Alert/alert';

const CERT_ERROR_MESSAGE = 'Для авторизации в вебтерминале необходим сертификат.';

const Auth = (props) => {
  const [idTerminalError, setIdTerminalError] = useState('');
  const [numUserError, setNumUserError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [idTerminal, setIdTerminal] = useState('');
  const [numUser, setNumUser] = useState('');
  const [password, setPassword] = useState('');
  const [authCashier, setAuthCashier] = useState(false);
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [authBtnDisabled, setAuthBtnDisabled] = useState(false);
  const { idTerminal: idTerminalFromCert } = useWindow();

  useEffect(() => {
    if (idTerminalFromCert && !idTerminal) {
      setIdTerminal(idTerminalFromCert.toString());
    }
  }, [idTerminalFromCert]);

  useEffect(() => {
    const { guid, refreshToken, authCashier: authCashierInLocalStorage } = getAuthData();
    const { token } = getFiscalData();

    if ((guid !== '' && refreshToken !== '' && !authCashierInLocalStorage) || (guid !== '' && refreshToken !== '' && authCashierInLocalStorage && token !== '')) {
      props.goToMainPage();
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const phoneValueLength = getClearPhone(phone).length;

    if (!idTerminal.length) {
      setIdTerminalError(true);
    } else {
      setIdTerminalError(false);
    }

    if (!numUser.length) {
      setNumUserError(true);
    } else {
      setNumUserError(false);
    }

    if (!password.length) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }

    if (authCashier && phoneValueLength < 10) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }

    if ((idTerminal && numUser && password) &&
    ((phoneValueLength >= 10 && authCashier) || !authCashier)) {
      props.auth({
        idTerminal: idTerminal.toString(),
        numUser,
        password,
        phone: `7${getClearPhone(phone)}`,
        authCashier
      });
    }
  };

  const setIdTerminalHandler = (value) => {
    const terminalID = value.trim();

    if (terminalID !== '') setIdTerminalError(false);
    else setIdTerminalError(true);
    setIdTerminal(terminalID);
    checkCertificateByTerminalId(terminalID);
  };

  const checkCertificateByTerminalId = (terminalID) => {
    const terminalIDFormatted = Number(terminalID);

    if ((String(terminalID).length > 5 &&
    TERMINALS_WITH_CERT.includes(terminalIDFormatted)) ||
    moment() > moment(TERMINALS_WITH_CERT_DATE_EXPIRE)
    ) {
      setAuthBtnDisabled(true);
    } else {
      setAuthBtnDisabled(false);
    }
  };

  const setNumUserHandler = (value) => {
    if (value !== '') setNumUserError(false);
    else setNumUserError(true);
    setNumUser(value.trim());
  };

  const setPasswordHandler = (value) => {
    if (value !== '') setPasswordError(false);
    else setPasswordError(true);
    setPassword(value.trim());
  };

  const setPhoneHandler = (value) => {
    if (value !== '') setPhoneError(false);
    setPhone(value);
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)} className="auth-wrapper ">
      <h2 className="title authTitle">Авторизация</h2>
      <span >
        <Input
          name="idTerminal"
          placeholder="Номер терминала"
          onchange={(value) => setIdTerminalHandler(value.toString())}
          prefix="auth"
          errorMessage="Номер терминала обязателен к заполнению"
          isError={!!idTerminalError}
          defaultValue={idTerminal}
          disabled={!!idTerminalFromCert}
        />
      </span>

      <span className="pay-input">
        <Input
          name="numUser"
          placeholder="Номер пользователя"
          onchange={(value) => setNumUserHandler(value.toString())}
          prefix="auth"
          errorMessage="Номер пользователя обязателен к заполнению"
          isError={!!numUserError}
        />
      </span>

      <span className="pay-input">
        <Input
          name="password"
          type="password"
          placeholder="Пароль"
          prefix="lock"
          autoComplete="off"
          onchange={(value) => setPasswordHandler(value.toString())}
          errorMessage="Пароль обязателен к заполнению"
          isError={!!passwordError}
        />
      </span>
      {authCashier && (
        <span className="pay-input">
          <div className="inputWrapper">
            <Icon type="phone" style={{ zIndex: 9 }}/>
            <MaskedInput
              mask="8(111)111-11-11"
              name="phone"
              type="tel"
              placeholder="Номер телефона"
              autoComplete="off"
              maxLength={11}
              onChange={(e) => setPhoneHandler(e.target.value.toString())}
              formatCharacters={{
                W: {
                  validate(char) { return /[\wа-яА-Я-]/.test(char); },
                }
              }}
              className={phoneError ? 'auth-input input-warning' : 'auth-input'}
            />
            <span className="inputError">{phoneError && 'Номер телефона обязателен к заполнению'}</span>
          </div>
        </span>
      )}
      <span className="pay-input radio">

        <label htmlFor="authCashier" className="radio-label">
          <input
            type="checkbox"
            className="radio-label-input"
            id="authCashier"
            name="authCashier"
            onChange={() => setAuthCashier(!authCashier)}

          />{' '}
          Хочу авторизоваться как кассир
        </label>
      </span>
      {authBtnDisabled && <div className="authAlert"><Alert message={CERT_ERROR_MESSAGE}/></div>}
      <CustomButton yellow className="login-form-button pay-input" disabled={authBtnDisabled}>
        Авторизоваться
      </CustomButton>
    </form>
  );
};

Auth.propTypes = {
  auth: PropTypes.func.isRequired,
  goToMainPage: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AuthActions, goToMainPage, showError }, dispatch);
}

export default connect(null, mapDispatchToProps)(Auth);

