import * as types from 'actions/services/types';

const defaultState = {
  serviceList: [],
  isServiceListRequested: false,
  isServiceListRequestedWithError: false,
  servicesByTypes: [], // Сгруппированные сервисы
  additionalDetails: {},
  multilingualServiceName: [],
  favourites: [],
  hasCashOut: false,
  hasMyPost: false,
};

const services = (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_SERVICE_LIST:
      return { ...state, serviceList: action.services };
    case types.GET_SERVICE_LIST_XML_REQUEST:
      return { ...state, isServiceListRequested: true };
    case types.SERVICE_LIST_REQUEST_WITH_ERROR:
      return { ...state, isServiceListRequestedWithError: action.status };
    case types.SET_GROUPED_SERVICES:
      return {
        ...state,
        servicesByTypes: action.services,
        hasCashOut: action.hasCashOut,
        hasMyPost: action.hasMyPost
      };
    case types.ADD_ADDITIONAL_DETAILS:
      return {
        ...state,
        additionalDetails: {
          ...state.additionalDetails,
          [action.id]: action.addInfo
        }
      };
    case types.ADD_MULTILINGUAL_SERVICE_NAME:
      return { ...state, multilingualServiceName: action.value };
    case types.SET_FAVOURITE_SERVICES:
      return { ...state, favourites: action.favourites };
    default:
      return state;
  }
};

export default services;
