import { getCurrentDate } from '../helpers/main';

const defaultParams = {
  lastServiceUpdate: null,
  lastProfilesUpdate: null,
  lastTerminalUpdate: null,
  historyParams: {
    dateB: '',
    dateE: ''
  }
};

/* =============================================================================  */
/* Последнее обновление сервисов и профилей */
export const setLastServiceUpdate = () => setParam('lastServiceUpdate');

export const setLastProfilesUpdate = () => setParam('lastProfilesUpdate');

export const getLastServiceUpdate = () => getParam('lastServiceUpdate');

export const getLastProfilesUpdate = () => getParam('lastProfilesUpdate');

const setParam = (paramName) => {
  let params = defaultParams;

  if (window.localStorage.getItem('params') !== null) {
    params = JSON.parse(window.localStorage.getItem('params'));
  }

  window.localStorage.setItem(
    'params',
    JSON.stringify({ ...params, [paramName]: getCurrentDate() })
  );
};

const getParam = (paramName) => {
  if (window.localStorage.getItem('params') !== null) {
    return JSON.parse(window.localStorage.getItem('params'))[paramName.toString()];
  }

  return null;
};

/* =============================================================================  */
/* Период запрошенной истории платежей */
export const getHistoryParams = () => {
  if (window.localStorage.getItem('params') !== null) {
    return JSON.parse(window.localStorage.getItem('params')).historyParams;
  }

  return defaultParams.historyParams;
};

export const setHistoryParams = (data = defaultParams.historyParams) => {
  let params = defaultParams;

  if (window.localStorage.getItem('params') !== null) {
    params = JSON.parse(window.localStorage.getItem('params'));
  }

  window.localStorage.setItem(
    'params',
    JSON.stringify({ ...params, historyParams: data })
  );
};
