import * as fiscalTypes from 'actions/fiscal/types';
import * as pultTypes from 'actions/pult/types';

// Это все касается исключительно Fiscal 2.0
const defaultState = {
  authDataIsRequested: false,
  isFiscal: false,
  isAuthInFiscal: false,
  isClosedWsByServer: false,
  showPinCodeModal: false,
  showConfirmCloseShift: false,
  needToOpenShift: false,
  openingShiftCallback: null,
  typeOperation: 0,
  isKkmBlocked: false,
  blockReason: '',
  kkmInfo: {},
  modalData: {
    showModal: false,
    screenType: 0,
  },
  xReport: {},
  zReport: {},
  zReportHistory: [],
  tariff: null,
  articles: [],
  wasArticlesRequested: false,
  licenseList: [],
  paymentsList: [],
  licenseExpiredDate: '', // DD.MM.YYYY
  showLicenseModal: false,
  showLicenseExpiredModal: false,
  balances: {
    wasRequested: false,
    cash: 0,
    card: 0, // пока не используется
  },
  invoiceData: {
    amount: 0,
    id: 0
  },
  sectionForPayment: 0,
  receiptForRefund: '',
  fiscalXReportsSnList: [],
  authInFiscalIsError: false
};

const fiscal = (state = defaultState, action = {}) => {
  switch (action.type) {
    case fiscalTypes.SET_IS_FISCAL:
      return { ...state, isFiscal: action.isFiscal };
    case fiscalTypes.SET_IS_AUTH_IN_FISCAL:
      return { ...state, isAuthInFiscal: action.isAuthInFiscal };
    case fiscalTypes.SET_AUTH_DATA_IS_REQUESTED:
      return { ...state, authDataIsRequested: action.authDataIsRequested };
    case fiscalTypes.SET_KKM_STATUS:
      return { ...state, isKkmBlocked: action.isKkmBlocked, blockReason: action.blockReason };
    case fiscalTypes.SET_KKM_INFO:
      return { ...state, kkmInfo: action.kkmInfo };
    case fiscalTypes.UPDATE_KKM_INFO:
      return {
        ...state,
        kkmInfo: {
          ...state.kkmInfo,
          ...action.data
        }
      };
    case fiscalTypes.HIDE_FISCAL_MODAL:
      return { ...state, modalData: { ...state.modalData, showModal: false, screenType: 0 } };
    case fiscalTypes.SET_FISCAL_SCREEN_TYPE:
      return {
        ...state,
        modalData: {
          ...state.modalData,
          screenType: action.screenType,
          showModal: true
        }
      };
    case fiscalTypes.SET_X_REPORT_DATA:
      return { ...state, xReport: action.xReport };
    case fiscalTypes.SET_Z_REPORT_DATA:
      return { ...state, zReport: action.zReport };
    case fiscalTypes.SET_Z_REPORT_HISTORY_DATA:
      return { ...state, zReportHistory: action.zReportHistory };
    case fiscalTypes.CLEAR_EPORT_DATA:
      return { ...state, zReport: {}, xReport: {} };
    case pultTypes.SET_FISCAL_TARIFF:
      return {
        ...state,
        tariff: action.tariff,
        licenseExpiredDate: action.licenseExpiredDate,
        showLicenseExpiredModal: action.showLicenseExpiredModal
      };
    case pultTypes.SET_FISCAL_LICENSE_LIST:
      return { ...state, licenseList: action.licenseList };
    case pultTypes.SET_INVOICE_DATA:
      return { ...state, invoiceData: action.invoiceData };
    case fiscalTypes.SET_SHOW_LICENSE_MODAL:
      return { ...state, showLicenseModal: action.showLicenseModal };
    case fiscalTypes.SET_SHOW_LICENSE_EXPIRED_MODAL:
      return { ...state, showLicenseExpiredModal: action.showLicenseExpiredModal };
    case fiscalTypes.SET_FISCAL_BALANCES:
      return { ...state, balances: action.balances };
    case fiscalTypes.SET_SHOW_PIN_CODE_MODAL:
      return { ...state, showPinCodeModal: action.showPinCodeModal };
    case fiscalTypes.SET_SHOW_CONFIRM_CLOSE_SHIFT:
      return { ...state, showConfirmCloseShift: action.showConfirmCloseShift };
    case fiscalTypes.SET_OPENING_SHIFT_CALLBACK:
      return { ...state, openingShiftCallback: action.openingShiftCallback };
    case fiscalTypes.SET_TO_OPEN_SHIFT:
      return { ...state, needToOpenShift: action.needToOpenShift };
    case fiscalTypes.SET_TYPE_OPERATION:
      return { ...state, typeOperation: action.typeOperation };
    case fiscalTypes.SET_WS_CONNECTION_CLOSED_BY_SERVER:
      return { ...state, isClosedWsByServer: action.status };
    case fiscalTypes.SET_ARTICLES:
      return { ...state, articles: action.articles, wasArticlesRequested: true };
    case fiscalTypes.SELECT_FISCAL_SECTION_FOR_PAYMENT:
      return { ...state, sectionForPayment: action.sectionForPayment };
    case fiscalTypes.SET_FISCAL_PAYMENT_LIST:
      return { ...state, paymentsList: action.paymentsList };
    case fiscalTypes.SET_RECEIPT_FOR_REFUND:
      return { ...state, receiptForRefund: action.receiptForRefund };
    case fiscalTypes.SET_FISCAL_X_REPORT_SN_LIST:
      return { ...state, fiscalXReportsSnList: action.fiscalXReportsSnList };
    case fiscalTypes.SET_AUTH_FISCAL_ERROR:
      return { ...state, authInFiscalIsError: action.isError };
    default:
      return state;
  }
};

export default fiscal;
