/* eslint-disable import/extensions */
/* eslint-disable array-callback-return */
import React from 'react';
import PayTypes from 'paytypes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import MaskedInput from 'react-maskedinput';

import { setClientIIN } from 'actions/pay';
import Modal from 'components/UI/Modal/modal';
import { t, checkAccountOnReg, getClearPhone } from 'helpers/main';
import knoWithoutTaxpayers from 'public/json/finesAndTaxes/knoWithoutTaxpayers.json';
import restrictedKBKAndKNO from 'public/json/finesAndTaxes/restrictedKBKAndKNO.json';

import PenaltiesNotFound from './PenaltiesNotFound';
import PenaltiesFound from './PenaltiesFound';

const TAXES_SERVICE_ID = 2414;
const PENALTY_SERVICE_ID = 2415;
const TAX_ON_THE_CAR = 104402; // кбк налог на транспорт физ лица
const {
  containers: { PenaltiesAndTaxesContainer },
  templates: { PenaltiesAndTaxesTemplate }
} = PayTypes;

/* 931008450335  860307351061 */
class PenaltiesAndTaxes extends PenaltiesAndTaxesTemplate {
  state = {
    showModal: false,
    account: '',
    phone: '',
    isProtocolValid: true,
    isVinCodeValid: true,
    isSumValid: true,
    knpError: false,
    knoError: false,
    kbkError: false,
    ugdError: false
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDownFowWindow);
  }

  componentDidUpdate(prevProps) {
    const {
      payTypePenalties: { FullName, selectedPenaltyKey },
      commission: { currentComProfile }
    } = this.props;

    const {
      FullName: oldFullName,
      selectedPenaltyKey: oldSelectedPenaltyKey
    } = prevProps.payTypePenalties;

    if (oldFullName !== FullName && FullName) {
      this.showModal();
    }

    if (oldSelectedPenaltyKey !== selectedPenaltyKey) {
      this.props.updateWillBeCreditedAmount(this.getPenaltyAmount(), currentComProfile, true);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDownFowWindow);
    this.handleOnClose();
  }

  onKeyDownFowWindow = (e) => {
    if (e.key === 'Enter') {
      if (this.state.showModal) {
        this.validateAndPay(); // из модалки
      } else {
        this.validate(); // первый экран
      }
    }
  };

  getPenaltyAmount = () => {
    const { penalties, selectedPenaltyKey, BankCom, OperCom } = this.props.payTypePenalties;

    if (penalties.length) {
      const { penaltyAmount } = penalties[selectedPenaltyKey.toString()]['@attributes'];

      return Number(penaltyAmount) + Number(BankCom) + Number(OperCom);
    }

    return 0;
  };

  confirm() {
    const {
      setConfirmInfo,
      showConfirmScreen,
      pay: { account, willBeCreditedAmount, numProtocol, vinCode },
      kassa: { currentService: { idService } },
      payTypePenalties: {
        FullName,
        selectedKbk,
        selectedKnp,
        selectedUgd,
        selectedPenaltyKey,
        penalties,
        hasPenalties,
        BankCom,
        OperCom
      },
    } = this.props;

    const confirmFields = [
      { name: 'iin', value: account },
      { name: 'fio', value: FullName },
    ];

    const checkInfo = {
      input: {
        iin: account.toString(),
        kbk: '',
        kno: '',
        knp: ''
      },
      online: {
        supplierCommission: Number(BankCom),
        operatorCommission: Number(OperCom)
      },
    };

    if (idService === TAXES_SERVICE_ID) { // налоги
      if (this.isItPayTaxForCar()) {
        checkInfo.input.vin = vinCode;
      }
      checkInfo.input.kbk = selectedKbk;
      checkInfo.input.knp = selectedKnp;
      checkInfo.input.kno = selectedUgd;

      checkInfo.online.fullName = FullName;
    }

    if (idService === PENALTY_SERVICE_ID) { // штрафы
      checkInfo.input.kbk = '204106';
      checkInfo.input.knp = '913';

      if (hasPenalties) {
        const {
          penaltyId,
          blankNumber,
          violationDate,
          penaltyAmount,
          violationPlace,
          KNO
        } = penalties[selectedPenaltyKey.toString()]['@attributes'];

        checkInfo.input.kno = KNO.toString();
        checkInfo.online.penaltyId = penaltyId;
        checkInfo.online.blankNumber = blankNumber;
        checkInfo.online.violationDate = violationDate;
        checkInfo.online.penaltyAmount = penaltyAmount;
        checkInfo.online.violationPlace = violationPlace;
      } else {
        // оплата по номеру протокола
        checkInfo.input.kno = selectedUgd;
        checkInfo.input.reportNumber = numProtocol;
        checkInfo.online.penaltyAmount = willBeCreditedAmount;
      }
    }

    setConfirmInfo(confirmFields, this.getAddings(), checkInfo);
    showConfirmScreen();
  }

  handleOnChange(e) {
    this.setState({
      [e.target.id]: e.target.value.trim()
    });
  }

  showModal = () => this.setState({ showModal: true });

  hideModal = () => this.setState({ showModal: false });

  validate = () => {
    const { account, phone } = this.state;
    const {
      showError,
      onlineCheck,
      iinIsValidOrEmpty,
      updateFieldValue,
      kassa: { currentService },
      payTypePenalties: { lastAccount },
      setClientIIN: setClientIINFunc
    } = this.props;

    if (!iinIsValidOrEmpty()) return;
    if (account) {
      setClientIINFunc(account);
    }

    if (account) {
      const { regExp, maskEdit } = currentService;

      if (checkAccountOnReg(account, regExp, maskEdit)) {
        updateFieldValue('account', account);
        updateFieldValue('comment', getClearPhone(phone));

        if (lastAccount && lastAccount.toString() === account.toString()) {
          return this.showModal();
        }

        return onlineCheck(currentService, account);
      }

      return showError(
        'Проверьте, пожалуйста, и повторите попытку',
        'Лицевой счет некорректен'
      );
    }

    return showError(
      'Проверьте, пожалуйста, и повторите попытку',
      'Все поля обязательны для заполнения'
    );
  }

  handleOnClose = () => {
    this.props.initPayScheme();
    this.hideModal();
  };

  setSumIsValid = (isSumValid) => this.setState({ isSumValid });

  setProtocolIsValid = (isProtocolValid) => this.setState({ isProtocolValid });

  setIsVinCodeValid = (isVinCodeValid) => this.setState({ isVinCodeValid });

  checkKnoAndKbk() {
    const { selectedUgd, selectedKbk } = this.props.payTypePenalties;
    const value = `${selectedUgd}-${selectedKbk}`;
    const result = {
      st: true,
      msg: ''
    };

    if (typeof restrictedKBKAndKNO[value.toString()] !== 'undefined') {
      result.st = false;
      result.msg = 'Данное сочетание КБК и УГД недоступно для оплаты!';
    }

    if (typeof knoWithoutTaxpayers[selectedUgd.toString()] !== 'undefined') {
      result.st = false;
      result.msg = 'Убедитесь, пожалуйста, в правильности выбора налогового органа. Данное Управление/Департамент не имеют на рег.учете НП';
    }

    return result;
  }

  isItPayTaxForCar = () => {
    const {
      kassa: { currentService: { idService } },
      payTypePenalties: { selectedKbk },
    } = this.props;

    return idService === TAXES_SERVICE_ID && selectedKbk && TAX_ON_THE_CAR === Number(selectedKbk);
  };

  validateAndPay() {
    const {
      pay: { willBeCreditedAmount, numProtocol, vinCode },
      payTypePenalties: { hasPenalties, selectedPenaltyKey, selectedKbk, selectedUgd, selectedKnp },
      showError,
      updateFieldValue
    } = this.props;

    updateFieldValue('account', this.state.account);

    this.validateCheckPenalties(hasPenalties, selectedPenaltyKey, showError);

    if (!hasPenalties) {
      const checkData = this.checkKnoAndKbk();

      if (checkData.st) {
        const isItPayTaxForCar = this.isItPayTaxForCar();
        const isProtocolValid = this.isProtocolValid(numProtocol);
        const isSumValid = !!willBeCreditedAmount;

        const isVinCodeValid = this.validateAndPayCheckTaxCar(isItPayTaxForCar, vinCode);

        this.setProtocolIsValid(isProtocolValid);
        this.setSumIsValid(isSumValid);

        const taxErr = this.getTaxesErrors();

        if (taxErr) return;

        // Оплата штрафа по номеру протокола
        const resProtNum = this.validateAndPayProtNumber(
          numProtocol,
          willBeCreditedAmount,
          selectedUgd,
          isProtocolValid,
          isSumValid);

        if (resProtNum) { return this.confirm(); }

        // Оплата налога
        const paramsTaxReq = { numProtocol, selectedKbk, selectedUgd, selectedKnp, isSumValid };
        const payTax = this.validateAndPayTaxPay(paramsTaxReq, isItPayTaxForCar, isVinCodeValid);

        if (payTax === '1') return;
        if (payTax === '2') return this.confirm();
      }
      this.props.showError(checkData.msg);
    }
  }

  validateCheckPenalties=(hasPenalties, selectedPenaltyKey, showError) => {
    if (hasPenalties) {
      if (selectedPenaltyKey !== '') {
        return this.confirm();
      }

      return showError('Необходимо выбрать штраф для оплаты');
    }
  }

  validateAndPayCheckTaxCar(isItPayTaxForCar, vinCode) {
    let isVinCodeValid = true;

    // налог на транспорт
    if (isItPayTaxForCar) {
      if (typeof vinCode !== 'undefined' && vinCode.length) { isVinCodeValid = isItPayTaxForCar; }

      this.setIsVinCodeValid(isVinCodeValid);
    }

    return isVinCodeValid;
  }

  validateAndPayTaxPay=(params, isItPayTaxForCar, isVinCodeValid) => {
    const { numProtocol, selectedKbk, selectedUgd, selectedKnp, isSumValid } = params;
    let result = '';

    // Оплата налога
    if (!numProtocol && selectedKbk && selectedUgd && selectedKnp && isSumValid) {
      if (isItPayTaxForCar && !isVinCodeValid) {
        result = '1';
      } else result = '2';
    }

    return result;
  }

  isProtocolValid=(numProtocol) => (numProtocol === undefined || numProtocol === '')

  validateAndPayProtNumber=(
    numProtocol,
    willBeCreditedAmount,
    selectedUgd,
    isProtocolValid,
    isSumValid
  ) => (numProtocol && willBeCreditedAmount && selectedUgd && isProtocolValid && isSumValid)

  handlerSelectKbk=(value) => {
    this.setState({ kbkError: !value });
    this.props.selectKbk(value);
  }

  handlerSelectKnp=(value) => {
    this.setState({ knpError: !value });
    this.props.selectKnp(value);
  }

  handlerSelectUgd=(value) => {
    this.setState({ ugdError: !value });
    this.props.selectUgd(value);
  }

  getTaxesErrors=() => {
    const {
      payTypePenalties: {
        selectedKbk,
        selectedKnp,
        selectedUgd, ugd
      },
    } = this.props;

    if (!selectedKbk) this.setState({ kbkError: true });
    if (!selectedKnp) this.setState({ knpError: true });
    if (!ugd.length) this.setState({ knoError: true });
    if (!selectedUgd) this.setState({ ugdError: true });
  }

  renderComponent() {
    const {
      pay: { numProtocol, vinCode },
      kassa: { currentService: { name, idService, maskEdit } },
      payTypePenalties: {
        penalties,
        FullName,
        selectedPenaltyKey,
        ndSelected,
        ugd,
        BankCom,
        OperCom
      },
      pay,
      commission: { currentComProfile },
      selectPenalty,
      updateWillBeCreditedAmount
    } = this.props;

    const {
      showModal,
      isProtocolValid,
      isSumValid,
      isVinCodeValid,
      account,
      knoError,
      knpError,
      ugdError,
      kbkError,
      phone
    } = this.state;

    return (
      <>
        <Modal
          title={name}
          okText={t('next')}
          isOpen={showModal}
          onOk={() => this.validateAndPay()}
          onClose={() => this.handleOnClose()}
        >
          {(FullName && penalties.length) ? (
            <PenaltiesFound
              dataSource={this.getPenaltiesData()}
              selectedPenaltyKey={selectedPenaltyKey}
              selectPenalty={selectPenalty}
            />
          ) : null}

          {(FullName && !penalties.length) ? (
            <PenaltiesNotFound
              idService={idService}
              numProtocol={numProtocol}
              pay={pay}
              ndSelected={ndSelected}
              selectKbk={this.handlerSelectKbk}
              selectKnp={this.handlerSelectKnp}
              selectUgd={this.handlerSelectUgd}
              currentComProfile={currentComProfile}
              updateWillBeCreditedAmount={updateWillBeCreditedAmount}
              BankCom={BankCom}
              OperCom={OperCom}
              ugd={ugd}
              isProtocolValid={isProtocolValid}
              isSumValid={isSumValid}
              updateFieldValue={this.props.updateFieldValue}
              setUgd={this.props.setUgd}
              setSumIsValid={this.setSumIsValid}
              setProtocolIsValid={this.setProtocolIsValid}
              vinCode={vinCode}
              isVinCodeValid={isVinCodeValid}
              setIsVinCodeValid={this.setIsVinCodeValid}
              isItPayTaxForCar={this.isItPayTaxForCar}
              knoError={knoError}
              kbkError={kbkError}
              knpError={knpError}
              ugdError={ugdError}
            />
          ) : null}
        </Modal>

        <div className="pay-form">
          <MaskedInput
            mask={maskEdit.toString().replace(/9/g, '1')}
            type="tel"
            id="account"
            autoComplete="off"
            maxLength={40}
            placeholder="ИИН"
            placeholderChar=" "
            onChange={(e) => this.handleOnChange(e)}
            value={account}
            formatCharacters={{
              W: {
                validate(char) { return /[\wа-яА-Я-]/.test(char); },
              }
            }}
          />
          <div className="form-group">
            <MaskedInput
              mask="8(111)111-11-11"
              type="tel"
              id="phone"
              autoComplete="off"
              maxLength={15}
              placeholder="Номер телефона"
              placeholderChar=" "
              onChange={(e) => this.handleOnChange(e)}
              value={phone}
              formatCharacters={{
                W: {
                  validate(char) { return /[\wа-яА-Я \-.@]/.test(char); },
                },
                w: {
                  validate(char) { return /[\wа-яА-Я \-.@]/.test(char); },
                }
              }}
            />
          </div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.validate}
          >
            {t('next')}
          </button>
        </div>
      </>
    );
  }
}

PenaltiesAndTaxes.propTypes = {
  setClientIIN: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setClientIIN
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(PenaltiesAndTaxesContainer(PenaltiesAndTaxes));
