import React from 'react';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import * as Sentry from '@sentry/react';

import Auth from 'containers/Auth';
import Main from 'containers/Main';
import NotFound from 'components/NotFound';
import SummaryReport from 'containers/SummaryReport';
import CashoutReport from 'containers/CashoutReport';
import EncashmentList from 'containers/EncashmentList';

import Group from 'components/GroupServices';
import SessionEnded from 'components/SessionEnded';
import MainLayout from 'layout/main';
import AuthLayout from 'layout/auth';

import { history } from './store';

const withAuthLayout = (Component) => <AuthLayout><Component /></AuthLayout>;
const withMainLayout = (Component) => <MainLayout><Component /></MainLayout>;

export default () => (
  <Sentry.ErrorBoundary fallback={NotFound}>
    <ConnectedRouter history={history}>

      <Switch>
        <Route path="/login" render={() => withAuthLayout(Auth)}/>
        <Route path="/encashment-list" render={() => withMainLayout(EncashmentList)}/>
        <Route path="/summary-report" render={() => withMainLayout(SummaryReport)}/>
        <Route path="/cashout-report" render={() => withMainLayout(CashoutReport)}/>
        <Route path="/group" render={() => withMainLayout(Group)}/>
        <Route path="/session-ended" render={() => withAuthLayout(SessionEnded)}/>
        <Route path="/main" index render={() => withMainLayout(Main)}/>
        <Route path="*" render={() => withAuthLayout(NotFound)}/>
      </Switch>

    </ConnectedRouter>
  </Sentry.ErrorBoundary>
);

