import { useCallback, useState, useEffect, React } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getXReportData, sendPaysToFiscal } from 'actions/fiscal';
import Modal from 'components/UI/Modal/modal';
import { checkFiscalAndApSendedPays, getFiscalData, getShiftStatus } from 'helpers/fiscal';

import { sendInfoLogToGateway } from 'actions/api/logs';
import { TIME_FOR_CHECK_PAYS_IN_FISCAL, TIME_FOR_CALL_FISCAL_REQUEST } from 'constants/fiscal';

const FiscalPaymentsStatus = () => {
  const [warningSnList, setWarningSnList] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const {
    payment: { snListForTodayPayments },
    fiscal: { fiscalXReportsSnList, xReport }
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { idKkm } = getFiscalData();
  const { kkmInfo: { Lock, IdShift } } = useSelector((state) => state.fiscal);
  const shiftStatus = getShiftStatus(Lock, IdShift);

  useEffect(() => {
    if (shiftStatus === 'Открыта') {
      dispatch(getXReportData({ showLoader: false }));
      checkFiscalNotSendedPays();
    }

    setTimeout(() => {
      if (shiftStatus === 'Открыта') {
        dispatch(getXReportData({ showLoader: false }));
      }
    }, TIME_FOR_CALL_FISCAL_REQUEST);

    setTimeout(() => {
      checkFiscalNotSendedPays();
    },
    TIME_FOR_CHECK_PAYS_IN_FISCAL);
  },
  [shiftStatus, snListForTodayPayments]);

  const checkFiscalNotSendedPays = useCallback(() => {
    const notInFiscalSnList = checkFiscalAndApSendedPays({
      snListForTodayPayments,
      xReport,
      shiftStatus,
      fiscalXReportsSnList });

    setWarningSnList(notInFiscalSnList);
    sendLog();
  }, []);

  const sendLog = useCallback(() => {
    if (warningSnList.length > 0) {
      dispatch(sendInfoLogToGateway({
        message: `paysSnList: ${warningSnList.join(', ')}, kkm: ${idKkm}`,
        reqType: 'notSendedPaysToFiscal'
      }));
    }
  }, []);

  const openConfirmModal = useCallback(() => {
    setShowConfirmModal(true);
  }, []);

  const sendPaysToFiscalHundler = useCallback(() => {
    dispatch(sendPaysToFiscal(warningSnList));
    setShowConfirmModal(false);
  }, [warningSnList]);

  const hideConfirmModal = useCallback(() => {
    setShowConfirmModal(false);
  }, []);

  return (
    <div>
      {showConfirmModal && (
        <Modal
          title="Непроведенные платежи в фискал"
          isOpen={showConfirmModal}
          onClose={hideConfirmModal}
          onOk={sendPaysToFiscalHundler}
          size="small"
          okText="Провести"
        >
          Вы действительно хотите отправить платежи в фискал?
          <div style={{ whiteSpace: 'pre' }}>{warningSnList.join('\r\n')}</div>
        </Modal>
      )}
      {warningSnList.length > 0 && (
        <div className="fiscalWarning ">
          <div>
            Внимание! За текущую смену имеются непроведенные платежи в фискал.
          </div>
          <div>
            <button className="custom-button fiscal-block-button" onClick={openConfirmModal}>Показать</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FiscalPaymentsStatus;
