import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import IinField from 'components/IinField';
import { onlineCheck } from 'actions/api/onlineCheck';
import {
  updateFieldValue,
  updateWillBeCreditedAmount,
  showEnterAmountScreen,
  setConfirmInfo
} from 'actions/pay';
import { showError } from 'actions/alert';
import { setCurrentService } from 'actions/services';
import { initCurrentComProfile } from 'actions/commission';
import { setShowMyPostPaymentModal, clearParcelList } from 'actions/payTypes/mypost';
import { getClearPhone } from 'helpers/main';
import PropTypes from 'prop-types';
import Modal from 'components/UI/Modal/modal';
import MyPostIcon from 'public/images/mypost.svg';
import { iinIsValidOrEmptyFunc } from 'helpers/pay';
import MypostSearchType from './MypostSearchType';
import MypostFormData from './MypostFormData';
import MypostSelectParcel from './MypostSelectParcel';

const SEARCH_BY_PHONE = 2;

const SELECT_SEARCH_TYPE_STEP = 1;
const ENTER_DATA_STEP = 2;
const SELECT_PARCEL_STEP = 3;

const MyPostPaymentForParcel = (props) => {
  const [searchType, setSearchType] = useState(0);
  const [selectedParcelID, setSelectedParcelID] = useState('');
  const [step, setStep] = useState(SELECT_SEARCH_TYPE_STEP);
  const [account, setAccount] = useState('');
  const [isAccountValid, setIsAccountValid] = useState(true);

  useEffect(() => {
    if (account) {
      setIsAccountValid(true);
    }
  }, [account]);

  useEffect(() => {
    // если из сервиса выдача посылок перешли в оплату за посылку - подставляем номер телефона

    if (props.phoneForPayment && !account) {
      setSearchType(SEARCH_BY_PHONE);
      setStep(ENTER_DATA_STEP);
      setAccount(props.phoneForPayment.toString());
    }
  }, [props.phoneForPayment, account]);

  useEffect(() => {
    if (props.parcelList.length) {
      setStep(SELECT_PARCEL_STEP);
    }
  }, [props.parcelList]);

  const init = () => {
    setSearchType(0);
    setSelectedParcelID('');
    setStep(SELECT_SEARCH_TYPE_STEP);
    setAccount('');
    props.clearParcelList();
    props.setShowMyPostPaymentModal(false);
  };

  const validateAndGetParcelList = () => {
    const {
      clientIin,
      showError: showErrorFunc
    }
    = props;

    if (!iinIsValidOrEmptyFunc(clientIin, showErrorFunc)) return;
    if (!account) return setIsAccountValid(false);

    if (searchType === SEARCH_BY_PHONE && getClearPhone(account).length !== 10) {
      return setIsAccountValid(false);
    }

    const accountForOnlineCheck = searchType === SEARCH_BY_PHONE ? getClearPhone(account) : account;

    // ti1
    props.onlineCheck(props.currentService, accountForOnlineCheck);
  };

  const getOnBackCallback = () => {
    if (step === ENTER_DATA_STEP) {
      return () => {
        setStep(SELECT_SEARCH_TYPE_STEP);
        setAccount('');
      };
    }

    return () => {
      setStep(ENTER_DATA_STEP);
    };
  };

  const getOnOkCallback = () => {
    if (step === ENTER_DATA_STEP) {
      return () => validateAndGetParcelList();
    }

    return () => selectParcel();
  };

  const getOkText = () => {
    switch (step) {
      case SELECT_PARCEL_STEP: return 'Перейти к оплате';
      case ENTER_DATA_STEP: return 'Найти посылки';
      default: return 'Далее';
    }
  };

  const selectParcel = () => {
    if (selectedParcelID) {
      const parselData = props.parcelList.filter(parcel =>
        parcel.InvoiceTracking === selectedParcelID)[0];

      const serviceData = getServiceOnId(parselData.IDService);

      if (typeof serviceData.idService !== 'undefined') {
        if (parselData.AccountPayment !== 'null' && parselData.AccountPayment) {
          const getAddings = () => ({
            constraint: parselData.InvoiceTracking,
            memo: 'InvoiceTracking',
          });
          const checkInfo = {
            input: {
              account: parselData.AccountPayment,
            }
          };
          const confirmFields = [
            { name: 'account', value: parselData.AccountPayment },
            { name: 'amount', value: parselData.InvoiceAmount },
          ];

          init();
          props.initCurrentComProfile();
          props.setCurrentService(serviceData);
          props.updateFieldValue('account', parselData.AccountPayment);
          props.updateWillBeCreditedAmount(parselData.InvoiceAmount, props.currentComProfile);
          props.setConfirmInfo(confirmFields, getAddings(), checkInfo);
          props.showEnterAmountScreen();
        } else {
          props.showError(
            'У данной посылки не указан номер лицевого счета',
            'Невозможно произвести оплату'
          );
        }
      } else {
        props.showError(
          `Id сервиса: ${parselData.IDService}. Необходимо подать даннный сервис на терминал в кабинете`,
          'Не удалось найти сервис для оплаты');
      }
    } else {
      props.showError(
        'Выберите посылку и повторите попытку',
        'Посылка не выбрана'
      );
    }
  };

  const getServiceOnId = (idService) => {
    const service = props.serviceList.filter(item => (item.idService === idService));

    if (service.length) return service[0];

    return {};
  };

  return (
    <Modal
      size="small-and-high"
      title="Оплата за посылку"
      isOpen
      onBack={getOnBackCallback()}
      onOk={getOnOkCallback()} // отобразится только у 2 и 3 экрана
      onClose={init}
      cancelText="Назад"
      okText={getOkText()}
      isFooterHidden={step === SELECT_SEARCH_TYPE_STEP}
    >
      <div className="cashout-modal-head">
        <div>
          <img src={MyPostIcon} alt="Оплата за посылку" className="cashout-modal-icon"/>
        </div>
        <div>Оплата за посылку</div>
      </div>
      <div className="fiscal-modal-section cashout-modal-body">
        {step === SELECT_SEARCH_TYPE_STEP && (
          <MypostSearchType
            setSearchType={(type) => {
              setSearchType(type);
              setStep(ENTER_DATA_STEP);
            }}
          />
        )}

        {step === ENTER_DATA_STEP && (
          <>
            <IinField/>
            <MypostFormData
              account={account}
              setAccount={setAccount}
              isAccountValid={isAccountValid}
              searchType={searchType}
            />
          </>
        )}

        {step === SELECT_PARCEL_STEP && (
          <MypostSelectParcel
            parcelList={props.parcelList}
            selectedParcelID={selectedParcelID}
            selectParcel={(id) => setSelectedParcelID(id)}
          />
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  serviceList: state.services.serviceList,
  currentService: state.kassa.currentService,
  id: state.mypost.id,
  parcelList: state.mypost.parcelList,
  phoneForPayment: state.mypost.phoneForPayment,
  currentComProfile: state.commission.currentComProfile,
  clientIin: state.payment.clientIin
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    showError,
    onlineCheck,
    setConfirmInfo,
    clearParcelList,
    updateFieldValue,
    setCurrentService,
    setShowMyPostPaymentModal,
    initCurrentComProfile,
    showEnterAmountScreen,
    updateWillBeCreditedAmount,
  },
  dispatch);

MyPostPaymentForParcel.propTypes = {
  phoneForPayment: PropTypes.string.isRequired,
  parcelList: PropTypes.array.isRequired,
  serviceList: PropTypes.array.isRequired,
  currentService: PropTypes.object.isRequired,
  currentComProfile: PropTypes.object.isRequired,
  showError: PropTypes.func.isRequired,
  clearParcelList: PropTypes.func.isRequired,
  onlineCheck: PropTypes.func.isRequired,
  setConfirmInfo: PropTypes.func.isRequired,
  updateFieldValue: PropTypes.func.isRequired,
  setCurrentService: PropTypes.func.isRequired,
  setShowMyPostPaymentModal: PropTypes.func.isRequired,
  initCurrentComProfile: PropTypes.func.isRequired,
  showEnterAmountScreen: PropTypes.func.isRequired,
  updateWillBeCreditedAmount: PropTypes.func.isRequired,
  clientIin: PropTypes.string.isRequired

};

export default connect(mapStateToProps, mapDispatchToProps)(MyPostPaymentForParcel);
