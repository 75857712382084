import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from '../helpers/main';
import Commission from './Commission';

class PaymentInfo extends Component {
  render() {
    const { confirmFields, willBeCreditedAmount } = this.props;

    return (
      <div>
        {confirmFields.map(({ name, value, callback }) => {
          if (value) {
            return (
              <p key={name}><b>{t(name)}: </b>{value}</p>
            );
          }

          if (!value && typeof callback !== 'undefined') {
            return (
              <p key={name}><b>{t(name)}: </b>{callback(willBeCreditedAmount)}</p>
            );
          }

          return null;
        })}
        {}
        <Commission type="value" />
      </div>
    );
  }
}

PaymentInfo.propTypes = {
  confirmFields: PropTypes.array.isRequired,
  willBeCreditedAmount: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    confirmFields: state.pay.confirmFields,
    willBeCreditedAmount: state.pay.willBeCreditedAmount.toString(),
  };
}

export default connect(mapStateToProps, null)(PaymentInfo);
