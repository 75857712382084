import React from 'react';
import PayTypes from 'paytypes';
import MaskedInput from 'react-maskedinput';

import IinField from 'components/IinField';
import { getMaskForInput, t, checkAccountOnReg } from 'helpers/main';

const {
  containers: { EnterAccountAndFioContainer },
  templates: { EnterAccountAndFioTemplate }
} = PayTypes;

class EnterAccountAndFio extends EnterAccountAndFioTemplate {
  state = {
    account: ''
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDownFowWindow);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDownFowWindow);
  }

  onKeyDownFowWindow = (e) => {
    if (e.key === 'Enter') {
      this.validateAndCheck();
    }
  };

  confirm() {
    const { setConfirmInfo, showEnterAmountScreen } = this.props;
    const { account, fio } = this.state;

    const confirmField = [
      { name: 'account', value: account },
      { name: 'fio', value: fio },
    ];

    const checkInfo = {
      input: {
        account,
        fullName: fio
      }
    };

    setConfirmInfo(confirmField, this.getAddings(), checkInfo);
    showEnterAmountScreen();
  }

  getAddings() {
    return {
      constraint: this.state.fio
    };
  }

  handleOnChange(e) {
    this.setState({
      [e.target.id]: e.target.value.trim()
    });
  }

  validateAndCheck() {
    const { account, fio } = this.state;
    const {
      showError,
      iinIsValidOrEmpty,
      updateFieldsValue,
      kassa: { currentService: { regExp, maskEdit } },
    } = this.props;

    if (!iinIsValidOrEmpty()) return;
    if (account && fio) {
      if (checkAccountOnReg(account, regExp, maskEdit)) {
        updateFieldsValue({ account, fio });

        return this.confirm();
      }

      return showError(
        'Проверьте, пожалуйста, и повторите попытку',
        'Лицевой счет некорректен'
      );
    }

    return showError(
      'Проверьте, пожалуйста, и повторите попытку',
      'Все поля обязательны для заполнения'
    );
  }

  /*
   Ювис ТВ г. Караганда 5629, 14390, 26559
   Журнал Строй Гид 664343211666
  */
  renderComponent() {
    const { kassa: { currentService: { maskEdit } } } = this.props;
    const mask = getMaskForInput(maskEdit);

    return (
      <div className="pay-form">
        <IinField/>
        <div className="form-group">
          <MaskedInput
            mask={mask}
            type="text"
            id="account"
            autoComplete="off"
            maxLength={40}
            placeholder="№ лицевого счета"
            placeholderChar=" "
            onChange={(e) => this.handleOnChange(e)}
            value={this.state.account}
            formatCharacters={{
              W: {
                validate(char) { return /[\wа-яА-Я\-№]/.test(char); },
              }
            }}
          />
        </div>
        <div className="form-group">
          <input placeholder="ФИО" id="fio" onChange={(e) => this.handleOnChange(e)} />
        </div>
        <div className="form-group">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => this.validateAndCheck()}
          >
            {t('next')}
          </button>
        </div>
      </div>
    );
  }
}

export default EnterAccountAndFioContainer(EnterAccountAndFio);
