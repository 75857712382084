import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/UI/Icon/index';
import './input.scss';

const Input = (props) => {
  const {
    id,
    type,
    className,
    isError,
    placeholder,
    onchange,
    label,
    errorMessage,
    search,
    prefix,
    ...attrs
  } = props;

  const onChangeHandler = (e) => {
    let value = e.target.value;

    // запрещаем все кроме точки и цифр
    if (type === 'number') {
      value = getNumberInputValue(value);
    }

    onchange(value);
  };

  const getNumberInputValue = (v) => {
    let value = v.replace(/[^\d.]/g, '');

    // удаляем 0 в начале строки если он есть
    if (value.substr(0, 1) === '0') {
      value = value.replace('0', '');
    }

    // не даем ввести вторую точку
    if ((value.match(/\./g) || []).length > 1 && value.slice(-1) === '.') {
      value = value.slice(0, value.lastIndexOf('.'));
    }

    // проверяем на кол-во знаков после запятой, разрешаем не больше 2х
    if ((value.match(/\./g) || []).length === 1 && value.split('.')[1].length > 2) {
      value = value.slice(0, value.length - 1);
    }

    return value;
  };

  return (
    <div className="inputWrapper" >
      <div className="labelsWrapper">
        {label && <label className="inputLabel" htmlFor={id}>{label}</label>}
      </div>
      <input
        type={type === 'number' ? 'text' : type}
        placeholder={placeholder}
        id={id}
        onChange={onChangeHandler}
        className={`input-field  ${isError && 'input-warning'} ${prefix && 'auth-input'} ${search && 'input-search'}`}
        {...attrs}
      />
      {prefix && <Icon type={prefix} />}
      <span className="inputError">{isError ? errorMessage : ''}</span>
    </div >
  );
};

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  prefix: PropTypes.string,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  onchange: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  search: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
  prefix: '',
  className: '',
  label: '',
  errorMessage: '',
  placeholder: '',
  isError: false,
  id: '',
  search: false
};

export default Input;
