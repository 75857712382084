import * as types from 'actions/payTypes/jysan/types';

const defaultState = {
  id: '',
  FIO: '',
  MaxAmount: '',
  showTimeMsg: false,
};

const jysan = (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_JYSAN_CHECKTIME:
      return {
        ...state,
        showTimeMsg: action.showTimeMsg
      };
    case types.SET_JYSAN_DATA:
      return {
        ...state,
        FIO: action.FIO,
        MaxAmount: action.MaxAmount,
        id: action.id,
      };
    case types.JYSAN_INIT:
      return defaultState;
    default:
      return state;
  }
};

export default jysan;
