import React, { Component } from 'react';

import { getHumanDateTime } from 'helpers/main';
import FooterBlock from 'layout/main/FooterBlock';
import AuthHeaderBlock from 'layout/auth/AuthHeaderBlock';

class Error extends Component {
  render() {
    return (
      <div className="error-wrapper">
        <AuthHeaderBlock />
        <div className="auth-content">
          <div style={{ padding: 40, backgroundColor: 'white' }}>
            <h1>Ошибка</h1>
            <p>Возникла непредвиденная ошибка</p>
            <p>{getHumanDateTime()}</p>
          </div>
          <button className="btn btn-primary" onClick={() => window.location.reload()}>
            Вернуться на главную
          </button>
        </div>
        <FooterBlock />
      </div>
    );
  }
}

export default Error;
