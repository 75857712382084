const defaultParams = {
  idTerminal: '',
  idService: '',
  account: '',
  sn: '',
  data: '',
  inProccess: false,
  logActions: [],
  wasSelectService: false,
  wasMakePayActionAlready: false,
  isSendedToFiscal: false,
  wasPaymentCancelled: false,
};

// Работа с localStorage
export const updateLogInLocalStorage = (action, logInfo) => {
  const log = getLogFromLocalStorage();
  const logActions = log.logActions.concat([action]);

  window.localStorage.setItem(
    'log',
    JSON.stringify({ ...log, ...logInfo, inProccess: true, logActions })
  );
};

export const resetLogInLocalStorage = () => {
  window.localStorage.setItem(
    'log',
    JSON.stringify(defaultParams)
  );
};

export const getLogFromLocalStorage = () => {
  if (window.localStorage.getItem('log') !== null) {
    return JSON.parse(window.localStorage.getItem('log'));
  }

  return defaultParams;
};
