import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import PayTypes from 'paytypes';
import CustomButton from 'components/UI/Button/button';
import { showSuccess } from 'actions/alert';
import { setCheckWithCode } from 'actions/check';
import Steps from 'components/UI/Steps/steps';
import { STEPS } from 'constants/ktj';
import { getDateInMls } from 'helpers/main';

const { KtjBookingTemplate } = PayTypes.templates;

/* 7@@{orderId} - Отмена брони */

class Booking extends KtjBookingTemplate {
  state = {
    totalAmount: 0
  };

  componentDidMount() {
    this.props.setOnOkCallback(() => this.confirm());
    this.props.setOnCloseCallback(() => this.handleCancelBooking());
    this.props.updateFieldValue('account', this.props.orderId.toString()); // записываем ЛС для оплаты
    this.setTotalAmount();
  }

  handleCancelBooking = () => {
    this.cancelBooking();
    this.props.showSuccess(`Заказ ${this.props.orderId} успешно отменен`);
  };

  setTotalAmount = () => {
    const totalAmount = this.getTotalAmount();

    this.setState({ totalAmount });
    // записываем сумму для оплаты
    this.props.updateWillBeCreditedAmount(totalAmount, this.props.currentComProfile);
  };

  getTotalAmount() {
    return []
      .concat(this.props.payTypeKTJ.orderInfo.Tickets.Ticket)
      .reduce((a, ticket) => a + ticket.TariffTotal, 0);
  }

  confirm = () => {
    const {
      setConfirmInfo,
      showConfirmScreen,
      orderId,
      tickets,
      carOwnerType,
      departureAndArrivalTimeInfo,
      departureDate,
      departureTime,
      departureStation,
      arrivalDate,
      arrivalTime,
      arrivalStation,
      seats,
      train,
      wagonNumber,
      wagonType,
      cityFromSc,
      cityToSc,
      tariff
    } = this.props;

    const confirmFields = [
      { name: 'Номер заказа', value: orderId },
      { name: 'Стоимость заказа', value: `${this.state.totalAmount} тг` },
    ];

    const convertTimeForCheck = (dateStr, timeStr) => {
      const date = dateStr.split('.');
      const time = timeStr.split(':');

      return getDateInMls(`${date[2]}${date[1]}${date[0]}${time[0]}${time[1]}00`);
    };

    // вроде как на каждый билет создается отдельный чек и в цикле отсылается в АПИ
    // как их потом ве разом отобразить на печать?
    const ticketsInfoForCheck = tickets.map(({
      '@attributes': { ExpressID, ID: ticketId },
      Tariff,
      TariffNDS,
      TariffB,
      TariffP,
      TariffService,
      TariffServiceNDS,
      TariffKtj,
      TariffDealer,
      TariffTotal,
      NoBedding,
      TariffType,
      Passenger,
    }) => ({
      isSpecial: true,
      input: {
        passengers: [].concat(Passenger).map(({ '@attributes': { Doc, Name } }) => {
          const fio = Name.split('=');
          const docType = Doc.slice(0, 2);
          const docNumber = Doc.slice(2);

          return {
            family: fio[0],
            name: fio[1],
            patronymic: fio[2] || '',
            document: {
              type: docType,
              number: docNumber
            }
          };
        })
      },
      online: {
        order: {
          ticketId: ticketId.toString(),
          departureAndArrivalTimeInfo,
          route: `${carOwnerType}27`,
          date: new Date().getTime(),
          tariffB: parseFloat(TariffB),
          tariffNDS: TariffNDS,
          tariffType: TariffType,
          tariffTotal: parseFloat(TariffTotal),
          tariffDealer: parseFloat(TariffDealer),
          tariffService: parseFloat(TariffService),
          tariffServiceNds: parseFloat(TariffServiceNDS),
          tariffP: parseFloat(TariffP),
          tariff: parseFloat(Tariff),
          tariffKtj: parseFloat(TariffKtj),
          expressId: ExpressID.toString(),
          amount: parseFloat(TariffTotal),
          train: {
            number: train,
            carriage: {
              number: wagonNumber,
              type: wagonType
            }
          },
          places: seats.map(({ Seat }) => Seat).join(','),
          bedding: !NoBedding ? 'Бар' : 'Жоқ',
          departure: {
            date: convertTimeForCheck(departureDate, departureTime),
            timeZone: '',
            name: departureStation,
            id: cityFromSc
          },
          arrive: {
            date: convertTimeForCheck(arrivalDate, arrivalTime),
            timeZone: '',
            name: arrivalStation,
            id: cityToSc
          }
        }
      }
    }));

    const paymentCheckInfo = {
      online: {
        order: {
          amount: tariff.toString(),
          number: orderId.toString()
        }
      },
      input: {}
    };

    console.log('KTJ ticketsInfoForCheck', ticketsInfoForCheck);
    console.log('KTJ paymentCheckInfo', paymentCheckInfo);

    const ktjChecks = [paymentCheckInfo].concat(ticketsInfoForCheck);

    setConfirmInfo(confirmFields, this.getAddings(), ktjChecks);
    showConfirmScreen();
  };

  getAddings = () =>
    this.props.tickets.map(({
      '@attributes': { ID, ExpressID },
      TariffTotal
    },
    i) => ({
      constraint: `${i};${ID};${ExpressID}`,
      amount0: TariffTotal
    }));

  renderComponent() {
    const {
      date,
      orderId,
      train,
      departureStation,
      departureTime,
      arrivalStation,
      arrivalTime,
      wagonNumber,
      tariff,
      tickets,
      seats,
      timeInWay,
      wagonType,
    } = this.props;

    return (
      <div className="ktj-booking-info">
        <Steps current={4} steps={STEPS} />
        <h2>Заказ № {orderId}. Проверьте ваши билеты</h2>
        <div className="selected-train-info">
          <p><b>{departureStation} - {arrivalStation} <br />{date}</b></p>
          <p><b>Номер поезда:</b> <br />{train}</p>
          <p><b>Вагон:</b> <br />№ {wagonNumber}</p>
          <p><b>Отправление:</b> <br />{departureTime}</p>
          <p><b>Прибытие:</b> <br />{arrivalTime}</p>
          <p><b>В пути:</b> <br />{timeInWay}</p>
          <p><b>Сумма:</b> <br />{tariff} тг</p>
          <CustomButton onClick={() => this.handleCancelBooking()} >
            Выбрать другие места
          </CustomButton>
        </div>
        <div>
          {tickets.map(({ '@attributes': { ID }, TariffType, TariffTotal, Passenger }) =>
            [].concat(Passenger)
              .map(({ '@attributes': { Doc, Name } }) => {
                const fio = Name.split('=');
                const initials = `${fio[1].slice(0, 1).toUpperCase()}.${fio[2].slice(0, 1).toUpperCase()}.`;

                return (
                  <div className="passenger-confirm-form" key={ID}>
                    <div className="passengers-confirm-form-header">
                      <div>
                        <span>Пассажир</span>
                        <div>{fio[0]} {initials}</div>
                      </div>
                      <div>
                        <span>Номер документа</span>
                        <div>{Doc}</div>
                      </div>
                      <div>
                        <span>Вид билета</span>
                        <div>{TariffType}</div>
                      </div>
                      <div>
                        <span>Тип вагона</span>
                        <div>{wagonType}</div>
                      </div>
                      <div>
                        <span>Номер места</span>
                        <div>
                          {seats.map(({ Seat }, i) =>
                            <span key={Seat}>{Seat} {i + 1 === seats.length ? '' : ','}</span>
                          )}
                        </div>
                      </div>
                      <div>
                        <span>Стоимость</span>
                        <div>{TariffTotal} тг</div>
                      </div>
                    </div>
                  </div>
                );
              }))}
        </div>
        <h3>Итоговая сумма: {this.state.totalAmount} тг</h3>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    Seats,
    Tariff,
    Tickets,
    DepartureAndArrivalTimeInfo,
    Order: { '@attributes': { ID: orderId } },
    Departure: { '@attributes': { Train, Station: departureStation, Date: departureDate, Time: departureTime } },
    Arrival: { '@attributes': { Station: arrivalStation, Date: arrivalDate, Time: arrivalTime } },
    Car: { '@attributes': { Number: wagonNumber }, Owner: { '@attributes': { Type: carOwnerType } } },
  } = state.payTypeKTJ.orderInfo;

  return ({
    orderId,
    train: Train,
    departureStation,
    departureDate,
    departureTime,
    arrivalStation,
    arrivalTime,
    arrivalDate,
    wagonNumber,
    tariff: Tariff,
    tickets: [].concat(Tickets.Ticket),
    seats: [].concat(Seats),
    carOwnerType,
    departureAndArrivalTimeInfo: DepartureAndArrivalTimeInfo,
    payTypeKTJ: state.payTypeKTJ,
    date: state.payTypeKTJ.date,
    cityToSc: state.payTypeKTJ.cityToSc,
    cityFromSc: state.payTypeKTJ.cityFromSc,
    timeInWay: state.payTypeKTJ.selectedTrainData.TimeInWay,
    wagonType: state.payTypeKTJ.selectedWagonData.wagonType,
    currentComProfile: state.commission.currentComProfile,
  });
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    showSuccess,
    setCheckWithCode
  },
  dispatch
  );

Booking.propTypes = {
  orderId: PropTypes.number.isRequired,
  tariff: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  timeInWay: PropTypes.string.isRequired,
  wagonType: PropTypes.string.isRequired,
  train: PropTypes.string.isRequired,
  departureStation: PropTypes.string.isRequired,
  wagonNumber: PropTypes.string.isRequired,
  departureTime: PropTypes.string.isRequired,
  arrivalStation: PropTypes.string.isRequired,
  arrivalTime: PropTypes.string.isRequired,
  carOwnerType: PropTypes.string.isRequired,
  departureAndArrivalTimeInfo: PropTypes.string.isRequired,
  setOnOkCallback: PropTypes.func.isRequired,
  setOnCloseCallback: PropTypes.func.isRequired,
  setConfirmInfo: PropTypes.func.isRequired,
  showConfirmScreen: PropTypes.func.isRequired,
  updateWillBeCreditedAmount: PropTypes.func.isRequired,
  updateFieldValue: PropTypes.func.isRequired,
  showSuccess: PropTypes.func.isRequired,
  setCheckWithCode: PropTypes.func.isRequired,
  tickets: PropTypes.array.isRequired,
  seats: PropTypes.array.isRequired,
  currentComProfile: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
