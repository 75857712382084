import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { formatMoney } from 'helpers/main';
import CustomButton from '../Button/button';
import Icon from '../Icon';
import './alertmodal.scss';

const AlertModalTemplate = ({ classNames, body, closeAlert }) => (
  <div className="alert-modal-wrapper">
    <div className={`alert-modal-block ${classNames}`}>
      {body}
      <div className="alert-modal-footer">
        <CustomButton className="alert-button" onClick={closeAlert}>Закрыть</CustomButton>
      </div>
    </div>
  </div>
);

const AlertModal = ({
  successText,
  warningText,
  errorText,
  cashOutInfoText,
  title,
  showAlertModal,
  closeAlert
}) => {
  if (typeof errorText === 'string' && typeof successText === 'string') {
    if (showAlertModal && errorText) {
      return (
        <AlertModalTemplate
          closeAlert={closeAlert}
          body={(
            <>
              <div className="alert-header">
                <Icon type="error" className="alert-error" />
              </div>
              <div className="alert-header">
                <div>{title || 'Упс, что-то пошло не так...'}</div>
              </div>
              <div className="alert-body" >{errorText}</div>
            </>
          )}
        />
      );
    }

    if (showAlertModal && successText) {
      return (
        <AlertModalTemplate
          closeAlert={closeAlert}
          body={(
            <>
              <div className="alert-header">
                <Icon type="success" className="alert-error" />
              </div>
              <div className="alert-header">
                Отлично!
              </div>
              <div className="alert-body" >{successText}</div>
            </>
          )}
        />
      );
    }

    if (showAlertModal && cashOutInfoText !== '') {
      return (
        <AlertModalTemplate
          closeAlert={closeAlert}
          classNames="cashout-alert-modal-block"
          body={(
            <>
              <div className="alert-header">
                <Icon type="getMoney" className="alert-error" />
              </div>
              <div className="alert-header">
                <div>Была подана заявка на выдачу денежных средств через Ваш терминал</div>
              </div>
              <div className="alert-body">
                <b>Сумма: {formatMoney(cashOutInfoText)} тг</b>
                <br />
                <br />
                Подготовьте данную сумму и ожидайте клиента
              </div>
            </>
          )}
        />
      );
    }

    if (showAlertModal && warningText !== '') {
      return (
        <AlertModalTemplate
          closeAlert={closeAlert}
          body={(
            <>
              <div className="alert-header">
                <Icon type="warning" className="alert-error" />
              </div>
              {title && (
                <div className="alert-header">
                  <div>{title}</div>
                </div>
              )}
              <div className="alert-body" >{warningText}</div>
            </>
          )}
        />
      );
    }
  }

  return <div />;
};

AlertModal.propTypes = {
  showAlertModal: PropTypes.bool.isRequired,
  successText: PropTypes.string.isRequired,
  errorText: PropTypes.string.isRequired,
  warningText: PropTypes.string.isRequired,
  cashOutInfoText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  closeAlert: PropTypes.func.isRequired,
};

AlertModalTemplate.propTypes = {
  classNames: PropTypes.string,
  body: PropTypes.object.isRequired,
  closeAlert: PropTypes.func.isRequired
};

AlertModalTemplate.defaultProps = {
  classNames: '',
};

const mapDispatchToProps = dispatch => ({
  closeAlert: () => dispatch({ type: 'ALERT_INIT' }),
});

const mapStateToProps = state => ({
  showAlertModal: state.alert.showAlertModal,
  errorText: state.alert.errorText,
  successText: state.alert.successText,
  warningText: state.alert.warningText,
  cashOutInfoText: state.alert.cashOutInfoText,
  title: state.alert.title,
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertModal);
