import axios from 'axios';
import moment from 'moment';
import PayTypes from 'paytypes';
import generateCheck from '@mvsolovyev/check-file-generation';

import { getAuthData } from 'model/UserDb';
import { CHECK_API_URL } from 'constants/api';
import { showError, showSuccess } from 'actions/alert';
import { sendInfoLogToGateway } from 'actions/api/logs';
import { setKtjSn } from 'components/PayTypes/KTJ/helper';
import { saveReceiptInIDB, removeReceiptFromIDB, updateReceiptInIDB } from 'model/RecepitWithCode';
import { generateCheckDataForEncashment, printCheckFromB64, getSettingsForCheck } from 'helpers/check';
import {
  getCurrentTimesTamp,
  logErrorToSentry,
  setSentryBreadсrumbs,
  setToLocalStorage,
  captureException
} from 'helpers/main';

import { getFiscalNumberBySnAndShowReceipt } from 'actions/fiscal';
import { getFiscalReceiptOnSnFromIDB } from 'model/FiscalReceipt';
import * as types from './types';

const { startLoader, stopLoader } = PayTypes.actions.handlers;

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: 'Basic d2ViLXRlcm1pbmFsOl9aVTsqZWgyS3UuLktWM3N+L0h3'
};

export const setKtjCheckWithCode = (sn, checkData) => (dispatch) => {
  dispatch({ type: types.SET_KTJ_CHECK_WITH_CODE, checkData, sn });
};

export const removeKtjCheckWithCode = (sn) => (dispatch) => {
  dispatch({ type: types.REMOVE_KTJ_CHECK_WITH_CODE, sn });
};

export const setChecksWithCodeInProps = (data) => (dispatch) => {
  dispatch({ type: types.SET_CHECK_WITH_CODE, data });
};

// сохраняем в пропсах чек, для отображения которого понадобится код
export const setCheckWithCode = (data) =>
  (dispatch) => {
    saveReceiptInIDB(data);
    dispatch(setChecksWithCodeInProps(data));
  };

export const updateCheckWithCode = (sn, updatedData) =>
  (dispatch) => {
    updateReceiptInIDB(sn, updatedData);
    dispatch({ type: types.UPDATE_CHECK_WITH_CODE_DATA, sn, updatedData });
  };

export const removeCheckWithCode = (sn) =>
  (dispatch) => {
    removeReceiptFromIDB(sn);
    dispatch({ type: types.REMOVE_CHECK_WITH_CODE, sn });
  };

export const setReceiptLang = (lang) => (dispatch) => {
  dispatch({ type: types.SET_RECEIPT_LANG, lang });
};

// Сохранение чека в АПИ
export const saveCheck = ({
  checkData,
  sn,
  showPrintWindowAfterSave,
  successCallback = () => { /* This is intentional */ },
  isKtj = false,
  isPayOperation = false
}) =>
  (dispatch) => {
    setSentryBreadсrumbs('saveCheck', 'saveCheck - start');

    const { idTerminal } = getAuthData();

    if (!isPayOperation) dispatch(startLoader());

    const reqData = {
      paid: true,
      point: Number(idTerminal),
      pointTime: getCurrentTimesTamp(),
      project: 2,
      data: Array.isArray(checkData) ? checkData : [checkData]
    };

    const saveCheckErrorCallback = (err) => {
      setSentryBreadсrumbs('saveCheck', 'saveCheck - error callback');
      captureException(err, `${CHECK_API_URL}/check`, 'SAVE_CHECK', 'CHECK_API');
      if (!isPayOperation) dispatch(stopLoader());
      dispatch(sendCheckToGateway(reqData, sn.toString()));

      if (typeof err.message !== 'undefined' && err.message === 'Network error') {
        dispatch(showError('Нет связи, проверьте соединение с интернетом', 'Отсутствует интернет'));
      } else {
        dispatch(showError('Распечатайте дубликат позднее или обратитесь в ТП', 'Не удалось сохранить чек'));
      }

      return '';
    };

    return axios({
      url: `${CHECK_API_URL}/check`,
      method: 'post',
      data: reqData,
      headers,
    })
      .then(({ status, data }) => {
        if (!isPayOperation) dispatch(stopLoader());
        setSentryBreadсrumbs('saveCheck', 'saveCheck - got response');

        if (status === 201) {
          setSentryBreadсrumbs('saveCheck', 'saveCheck - got response with 201 status');

          successCallback();
          dispatch(sendInfoLogToGateway({
            reqType: 'saveCheck',
            message: `Чек успешно сохранен. Ссылка на АПИ: ${CHECK_API_URL}. Чек: ${JSON.stringify(checkData)}`
          }));

          if (showPrintWindowAfterSave) {
            setSentryBreadсrumbs('saveCheck', 'saveCheck - generateReceiptAndPrint - end');
            generateReceiptAndPrint(isKtj, sn, data, dispatch);
          }

          return data.data[0];
        }

        return data;
      })
      .catch((err) => saveCheckErrorCallback(err));
  };

// Получение сохраненного чека по sn и idTerminal
// amount и dateIn приходит только от инкассационного чека,
// чтобы можно было сгенерить и сохранить, если его нет в АПИ
// getInJson приходит для КТЖ, для них запрашиваем чеки в json-формате
export const getCheckOnSn = ({
  sn,
  amount = 0,
  dateIn = '',
  isKtj = false,
  printAfterGetting = true,
  email = '',
}) =>
  (dispatch) => {
    dispatch(startLoader());

    return axios({
      url: `${CHECK_API_URL}/check?point=${getAuthData().idTerminal}&transaction=${sn}`,
      method: 'get',
      headers,
    })
      .then(({ data }) => {
        if (typeof data !== 'undefined' && typeof data.data !== 'undefined') {
          dispatch(sendInfoLogToGateway({
            message: `sn:${sn}, data: ${JSON.stringify(data.data[0])}`,
            reqType: 'getCheckOnSnSuccess'
          }));
          printCheckForKtjService(data, isKtj, sn, dispatch);

          const returnRes = getCheckOnSnCheckEmail(email, data, printAfterGetting, dispatch);

          if (returnRes) return data.data[0];
        }
      })
      .catch(async (err) => {
        const { message } = err;

        dispatch(sendInfoLogToGateway({
          message: `sn:${sn}, data: ${JSON.stringify(err)}`,
          reqType: 'getCheckOnSnError'
        }));

        console.log('err', err);

        dispatch(stopLoader());

        if (typeof message !== 'undefined' && message === 'Request failed with status code 404') {
          dispatch(generateReceiptOrShow404Message(amount, sn, dateIn));
        } else {
          captureException(err, `${CHECK_API_URL}/check?point=${getAuthData().idTerminal}&transaction=${sn}`, 'GET_CHECK', 'CHECK_API');
          dispatch(showError('Невозможно отобразить чек для данного платежа'));
        }
      });
  };

/**
 * Если терминал фискальный, ищем чек из IDB по sn и запрашиваем для него фискальный номер
 * Если есть amount, то генерим и сохраняем инкассационный чек
 * Иначе отображаем ошибку что чек не найден
 * @param {*} amount - сумма инкассационного чека
 * @param {*} sn - номер транзакции
 * @param {*} dateIn - дата транзакции
 * @returns
 */
const generateReceiptOrShow404Message = (amount, sn, dateIn) => (dispatch, getState) => {
  const { isFiscal } = getState().fiscal;
  const notFoundErrorText = 'Чек для данной транзакции не найден';

  if (amount) {
    // инкассационный
    dispatch(generateEncashmentCheckAndSave(sn, amount, dateIn));
  } else if (isFiscal) {
    dispatch(getFiscalReceiptsFromIdbOnSnAndFetchFiscalNumber(sn));
  } else {
    // обычный
    dispatch(showError(notFoundErrorText));
  }
};

/**
 * Получаем сохраненные фискальные чеки из IDB (при 449 статусе с фискала)
 * и запрашиваем для них фиск. номер
 * @param sn - номер транзакции
 * @returns
 */
const getFiscalReceiptsFromIdbOnSnAndFetchFiscalNumber = (sn) => async (dispatch) => {
  const notFoundErrorText = 'Чек для данной транзакции не найден';
  const fiscalReceiptData = await getFiscalReceiptOnSnFromIDB(String(sn));

  if (fiscalReceiptData) {
    dispatch(getFiscalNumberBySnAndShowReceipt({ ...fiscalReceiptData, SN: sn }, true))
      .then((res) => {
        if (!res) {
          dispatch(showError(notFoundErrorText));
        }
      });
  } else {
    dispatch(showError(notFoundErrorText));
  }
};

/** Запрашиваем чеки по айди и печатаем их
 * @param {array} checks
 */
export const getAndPrintChecksOnId = (checks) => (dispatch) => {
  dispatch(startLoader());

  checks.map(({ _id }, key) =>
    axios({
      url: `${CHECK_API_URL}/check/${_id}`,
      method: 'get',
      headers,
    })
      .then(({ data }) => {
        dispatch(stopLoader());

        if (typeof data !== 'undefined' && typeof data.data !== 'undefined') {
          getAndPrintChecksOnIdGenerateCheck(data, key, dispatch);
        }
      })
      .catch((err) => {
        const { message } = err;

        console.log('err', err);
        logErrorToSentry(`CHECK API error: ${message}`, 'GET_CHECK_ON_ID', 0, 'GET_CHECK_ON_ID');
        dispatch(stopLoader());
        if (typeof message !== 'undefined' && message === 'Request failed with status code 404') {
        // обычный
          dispatch(showError('Чек для данной транзакции не найден'));
        } else {
          dispatch(showError('Невозможно отобразить чек для данного платежа'));
        }
      }));
};

export const generateEncashmentCheckAndSave = (sn, amount, dateIn) => (dispatch) => {
  // генерим инкассационный чек
  const checkData = generateCheckDataForEncashment(
    sn,
    amount,
    moment(dateIn, 'YYYYMMDDHHmmss').format('YYYY-MM-DDTHH:MM:SS.SSSSSSSZ')
  );

  // сохраняем чек в АПИ
  dispatch(saveCheck({ checkData, sn, showPrintWindowAfterSave: true }));
};

export const sendCheckToGateway = (data, sn) => (dispatch) => {
  const { idTerminal } = getAuthData();

  // исключаем тестовые
  if (idTerminal !== 111784 && idTerminal !== 127935) {
    const json = {
      action: 'requestCheck',
      check: {
        email: '',
        sn,
        data
      }
    };

    dispatch({
      type: `${types.SEND_CHECK_TO_GATEWAY}_JSON_REQUEST`,
      payload: { reqType: 51, json, successCallback: () => { /* This is intentional */ } }
    });
  }
};

export const sendCheckToEmail = (sn, email) => (dispatch) =>
  dispatch(getCheckOnSn({ sn, email }));

/** Отправляем чек на email
* @param {string} checkId
* @param {string} email
*/
const sendCheckToMail = (checkId, email) => (dispatch) => {
  dispatch(startLoader());

  return axios.post(`${CHECK_API_URL}/mail/${checkId}/${email}`, {}, { headers })
    .then(({ status }) => {
      dispatch(stopLoader());

      if (status === 200) {
        dispatch(showSuccess('Заявка принята. Чек отправится на почту в течении 15 минут'));
      }
    })
    .catch(({ message }) => {
      dispatch(stopLoader());
      if (typeof message !== 'undefined' && message === 'Request failed with status code 404') {
        dispatch(showError('Чек для данной транзакции не найден'));
      } else {
        dispatch(showError('Возникла ошибка при отправке чека. Повторите попытку позднее'));
      }
    });
};

export const saveCheckSettings = ({ lineHeight, checkWidth }) => (dispatch) => {
  const REQ_TYPE = 151;

  const json = {
    format: {
      fontWeight: 'normal',
      fontSize: 25,
      lineHeight,
      checkWidth
    }
  };

  const successCallback = (data) => {
    if (data.status !== 0) {
      dispatch(showError(`Ошибка выполнения запроса. Статус: ${data.status}`));

      return false;
    }
    dispatch(showSuccess('Настройки успешно сохранены'));
    setToLocalStorage('checkSettings', json.format);
  };

  dispatch({
    type: `${types.SAVE_CHECK_SETTINGS}_JSON_REQUEST`,
    payload: { reqType: REQ_TYPE, json, successCallback }
  });
};

export const getCheckSettings = () => (dispatch) => {
  const REQ_TYPE = 152;
  const json = {};

  const successCallback = (data) => {
    if (data.status === 0) {
      setToLocalStorage('checkSettings', data.format);
    }
  };

  dispatch({
    type: `${types.GET_CHECK_SETTINGS}_JSON_REQUEST`,
    payload: { reqType: REQ_TYPE, json, successCallback }
  });
};

const generateReceiptAndPrint = (isKtj, sn, data, dispatch) => {
  if (isKtj) {
    setKtjSn('');
    dispatch(removeKtjCheckWithCode(sn));
    dispatch(getAndPrintChecksOnId(data.data));
  } else {
    try {
      // получаем настройки чека
      const checkTextWithFormat = getSettingsForCheck(data.data[0]);

      generateCheck('data:image/png', checkTextWithFormat, (err, dataUrl) => {
        if (!err) {
          printCheckFromB64({ check: dataUrl });
        } else {
          console.log('generateCheck err:', err);

          dispatch(showError('Возникла ошибка при отображении чека'));
        }
      });
    } catch (e) {
      dispatch(showError('Произошла ошибка при отображении чека'));
    }
  }
};

const printCheckForKtjService = (data, isKtj, sn, dispatch) => {
  if (isKtj) {
    setKtjSn('');
    dispatch(removeKtjCheckWithCode(sn));
    dispatch(getAndPrintChecksOnId(data.data));
    dispatch(stopLoader());
  }
};

const getCheckOnSnCheckEmail = (email, data, printAfterGetting, dispatch) => {
  if (!email) {
    try {
      // получаем настройки чека
      const checkTextWithFormat = getSettingsForCheck(data.data[0]);

      generateCheck('data:image/png', checkTextWithFormat, (err, dataUrl) => {
        dispatch(stopLoader());
        if (!err) {
          if (printAfterGetting) {
            printCheckFromB64({ check: dataUrl });
          }
        } else {
          dispatch(showError('Возникла ошибка при отображении чека'));
        }
      });

      return true;
    } catch (e) {
      dispatch(showError('Произошла ошибка при отображении чека'));

      return false;
    }
  } else {
    dispatch(stopLoader());
    dispatch(sendCheckToMail(data.data[0]._id, email));
  }

  return false;
};

const getAndPrintChecksOnIdGenerateCheck = (data, key, dispatch) => {
  try {
    // получаем настройки чека
    const checkTextWithFormat = getSettingsForCheck(data.data[0]);

    generateCheck('data:image/png', checkTextWithFormat, (err, dataUrl) => {
      if (!err) {
        printCheckFromB64({ check: dataUrl, key });
      } else {
        dispatch(showError('Возникла ошибка при отображении чека'));
      }
    });
  } catch (e) {
    dispatch(showError('Произошла ошибка при отображении чека'));
  }
};

