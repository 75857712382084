import Select from 'components/UI/Select/Select';
import Input from 'components/UI/Input/input';
import DropdownInput from 'components/UI/DropdownInput/dropdownInput';
import React from 'react';
import PropTypes from 'prop-types';

const FiscalOperationReceipt = ({ item, price, section, units, count }) => (
  <>
    <h2>Позиция</h2>
    <div className="fiscal-modal-section">
      <div className="firstItem fiscalItem">
        <DropdownInput
          placeholder="Название товара или услуги"
          id="productName"
          label="Наименование"
          callback={(value) => item.onChange(value)}
          value={item.value}
          errorMessage="Поле обязательно для заполнения"
          isError={!item.isValid}
          dropdownList={item.dropdownList}
          dropdownCallback={(article) => item.dropdownCallback(article)}
        />
      </div>
      <div className="fiscalItem">
        <Input
          placeholder="0"
          id="price"
          type="number"
          onchange={price.onChange}
          value={price.value.toString()}
          label="Цена"
          errorMessage="Поле обязательно для заполнения"
          isError={!price.isValid}
        />
      </div>
      <div className="fiscalItem">
        <Input
          placeholder={count.value.toString()}
          id="count"
          type="number"
          onchange={(value) => count.onChange(Number(value))}
          value={count.value.toString()}
          label="Количество"
          errorMessage="Поле обязательно для заполнения"
          isError={!count.isValid}
        />
      </div>
      <div className="itemSection">
        <label htmlFor="section">Выберите секцию</label>
        <Select
          id="section"
          title="Секция"
          options={section.options}
          selectedValue={section.value}
          onchange={(sectionId) => section.onChange(sectionId)}
        />
        <span className="inputError">{!section.isValid ? 'Поле обязательно для заполнения' : ' '}</span>
      </div>
    </div>
    <div className="fiscal-modal-section">

      <div className="itemSection itemUnits">
        <label htmlFor="section">Единица измерения</label>
        <Select
          id="units"
          title="Единица измерения"
          options={units.options}
          selectedValue={units.value}
          onchange={(unitId) => units.onChange(unitId)}
        />
        <span className="inputError">{!units.isValid ? 'Поле обязательно для заполнения' : ' '}</span>
      </div>
    </div>
  </>
);

FiscalOperationReceipt.propTypes = {
  item: PropTypes.object.isRequired,
  count: PropTypes.object.isRequired,
  price: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  units: PropTypes.object.isRequired,
};

export default FiscalOperationReceipt;
