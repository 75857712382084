/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as payAction from 'actions/pay';
import { makePay } from 'actions/api/makePay';
import { getKkmInfo, getSections, checkFiscalToken } from 'actions/fiscal';
import * as checkActions from 'actions/check';
import Alert from 'components/UI/Alert/alert';
import { showAccordion } from 'actions/services';
import MinAndMaxAmount from 'components/MinAndMaxAmount';
import { showError as showErrorAlert } from 'actions/alert';

import { PAY_TYPES_WITHOUT_ENTER_AMOUNT_SCREEN, KTJ } from 'constants/payTypes';
import {
  KASPI_CREDIT,
  KASPI_GOLD,
  IGNORED_SERVICES_FOR_FISCAL,
  SERVICES_WITH_CODE_FOR_CHECK,
} from 'constants/services';

import Commission from 'containers/Commission';
import PaymentInfo from 'containers/PaymentInfo';
import PaymentMethod from 'containers/PaymentMethod';
import SelectForFiscalSections from 'containers/SelectFiscalSection';
import {
  isFiscalTokenAlive,
  getReqDataFoFiscal,
  isTheShiftActive,
  getFiscalSectionsOnPos,
  checkSectionsOnValid,
  getFiscalData,
} from 'helpers/fiscal';
import { paymentDataIsValid } from 'helpers/pay';
import { setKtjSn } from 'components/PayTypes/KTJ/helper';
import DublicatePaymentWarning from 'components/DublicatePaymentWarning/index';
import {
  t,
  getCurrentDate,
  checkUnicodeSymbols,
  formatMoney,
  parseAccount,
  setSentryBreadсrumbs,
  logErrorToSentry,
  setFocusFormElement,
  getCurrentDateWithTimezone
} from 'helpers/main';
import {
  generateCheckDataForAP,
  generateCheckDataForFiscal,
  addOptionalAdditionalDataForReceipt
} from 'helpers/check';
import { isTheSamePaymentExist } from 'helpers/confirm';
import { sendInfoLogToGateway } from 'actions/api/logs';
import { FISCAL_KKM_STATUSES } from 'constants/fiscal';
import { getAuthData } from 'model/UserDb';
import LangList from './LangList';

const SERVICES_WITHOUT_COMISSION = [9498, 9565];

const Confirm = (props) => {
  const {
    depositedAmount,
    willBeCreditedAmount,
    currService,
    paymentMethod,
    commissionSum,
    adding,
    account,
    SN,
    mayPay,
    checkInfo,
    saveCheck,
    showEnterAmountScreen,
    showPayTypeScreen,
    showError,
    toggleAccordion,
    isFiscal,
    isAuthInFiscal,
    requestKkmInfo,
    kkmInfo,
    sectionForPayment,
    isMyPostPayment,
    otherIdServiceForMakePay,
    minAmount,
    maxAmount,
    comment,
    clientIin,
    clickEnterConfirmScreen,
    lang
  } = props;
  const [sectionsWasRequested, setSectionsWasRequested] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [dublicatePaymentInfo, setDublicatePaymentInfo] = useState({});
  const [showDublicateWarning, setShowDublicateWarning] = useState(false);
  const [dublicateConfirmed, setDublicateConfirmed] = useState(false);
  const paymentDate = getCurrentDate();
  // Это временно, потом удалим receiptDate, останется только paymentDate
  const receiptDate = getCurrentDateWithTimezone();
  const ref = useRef();
  const currentService = props.currentServiceKaspi || currService;

  useEffect(() => {
    if (dublicateConfirmed) {
      validateAndPay();
    }
  }, [dublicateConfirmed]);

  useEffect(() => {
    setFocusFormElement('confirmScreenForm');
  }, []);

  useEffect(() => {
    if (sectionsWasRequested) {
      ref.current.click();
    }
  }, [sectionsWasRequested]);

  const onKeyDownFowWindow = useCallback(
    (e) => {
      if (e.key === 'Enter' && !isDisabled) {
        clickEnterConfirmScreen();
        validateAndPay();
        document.removeEventListener('keydown', onKeyDownFowWindow);
      }
    },
    [paymentMethod, isDisabled],
  );

  const validateAndPay = (e = null) => {
    e?.preventDefault();

    setSentryBreadсrumbs('pay', 'Confirm - validateAndPay');
    const paymentValidateErr = paymentDataIsValid({
      depositedAmount,
      willBeCreditedAmount,
      commissionSum,
      minAmount,
      maxAmount
    });

    const hasDublicate = checkPaymentOnDublicate();

    if (paymentValidateErr) {
      showError(paymentValidateErr);
    } else if (!hasDublicate || (hasDublicate && dublicateConfirmed)) {
      handleMakePay();
    }
  };

  const checkPaymentOnDublicate = () => {
    // ищем такой же платеж по лс, айди сервиса и сумме
    const theSamePayment = []
      .concat(props.paymentsHistory)
      .concat(props.paymentInProccess)
      .filter((payment) =>
        [parseAccount(currentService.maskEdit, account), account.toString()]
          .includes(payment.account.toString()) &&
        depositedAmount === payment.amount &&
        currentService.idService === payment.idService
      );

    if (theSamePayment.length) {
      setDublicatePaymentInfo(theSamePayment[0]);
      setShowDublicateWarning(true);

      return true;
    }

    return false;
  };

  // платеж для нефискального терминала
  const handleAPMakePay = () => {
    const reqData = getReqDataForAP();
    const generatedCheckData = generateCheckDataForAP({
      SN,
      ...currentService,
      willBeCreditedAmount: Number(reqData.amount) - Number(reqData.commission),
      commissionSum,
      checkInfo,
      paymentDate: receiptDate,
      paymentMethod,
      isMyPostPayment,
      lang
    });

    // ктж
    if (currentService.typeInterface === KTJ) {
      setKtjSn(SN);
      props.setKtjCheckWithCode(SN, generatedCheckData);
      props.makePay(reqData, paymentMethod);
    }

    // другие чеки, для которых нужен код
    if (SERVICES_WITH_CODE_FOR_CHECK.includes(currentService.idService)) {
      props.setCheckWithCode({
        sn: SN,
        checks: generatedCheckData,
        idService: currentService.idService
      });
      props.makePay(reqData, paymentMethod);
    }

    // обычные чеки
    if (
      currentService.typeInterface !== KTJ &&
      !SERVICES_WITH_CODE_FOR_CHECK.includes(currentService.idService)
    ) {
      const checkData = addOptionalAdditionalDataForReceipt(
        currentService.idService,
        clientIin,
        generatedCheckData
      );

      if (otherIdServiceForMakePay === KASPI_CREDIT.SERVICE_ID) {
        checkData[0].service = KASPI_CREDIT.SERVICE_INFO;
      }

      if (otherIdServiceForMakePay === KASPI_GOLD.SERVICE_ID) {
        checkData[0].service = KASPI_GOLD.SERVICE_INFO;
      }

      saveCheck({ checkData, sn: SN, showPrintWindowAfterSave: false, isPayOperation: true })
        .then((checkText) => props.makePay(reqData, paymentMethod, {}, checkText));
    }
  };

  const getReqDataForAP = () => {
    const { idService, maskEdit, typeInterface, isEMoney, name: nameService } = currentService;

    const data = {
      idService,
      maskEdit,
      account,
      amount: depositedAmount,
      payType: typeInterface,
      commission: commissionSum,
      SN,
      isEMoney,
      currentDate: paymentDate,
      nameService: checkUnicodeSymbols(nameService),
      comment
    };

    if (adding !== null) {
      data.addings = adding;
    }

    return data;
  };

  const handleFiscalMakePay = () => {
    if (checkShiftStatus() && checkSectionsStatus()) {
      requestKkmInfo(true)
        .then((response) => {
          if (typeof response !== 'undefined') {
            const { IdStatusKkm, IsActive } = response;

            if (IdStatusKkm === FISCAL_KKM_STATUSES.ACTIVE && IsActive) {
              // кидаем фискальный платёж
              sendFiscalPaymentRequest();
            }
          } else {
            logErrorToSentry('Не удалось получить информацию о кассе', 'requestKkmInfo', 0, 'Fiscal_GetKkmInfo_Error');
          }
        })
        .catch((err) => {
          console.log('err', err);
          logErrorToSentry(err, 'requestKkmInfo', 0, 'Fiscal_GetKkmInfo_Error');
        });

      kaspiSchemeInModalCallback();
    }
  };

  const checkShiftStatus = () => {
    if (isTheShiftActive()) {
      return true;
    }

    showError('Закройте текущую смену и откройте новую', 'Смена просрочена');

    return false;
  };

  const checkSectionsStatus = () => {
    if (checkSectionsOnValid(isFiscal)) {
      return true;
    }

    if (!sectionsWasRequested) {
      props.getSections()
        .then(() => setSectionsWasRequested(true));
    } else {
      showError('Для работы с платежами необходимо как минимум две фискальные секции. Проверьте в фискальном кабинете секции и обновите страницу');
    }

    return false;
  };

  // платеж для фискального терминала
  const sendFiscalPaymentRequest = () => {
    const fiscalPayData = getReqDataFoFiscal({
      currentService,
      depositedAmount,
      commissionSum,
      sectionForPayment,
      paymentMethod,
      otherIdServiceForMakePay
    });

    const sectionsForCheck = getFiscalSectionsOnPos(fiscalPayData.Positions);
    const checkData = generateCheckDataForFiscal({
      SN,
      ...currentService,
      willBeCreditedAmount: depositedAmount - commissionSum,
      commissionSum,
      checkInfo,
      paymentDate: receiptDate,
      kkmInfo,
      sectionsForCheck,
      paymentMethod,
      isMyPostPayment,
      lang
    });

    // КТЖ Чеки
    if (currentService.typeInterface === KTJ) {
      setKtjSn(SN);
    }

    // Здесь не сохраняем чек, потому что с фискала надо получить фискальный признак
    // добавить его в checkData и только потом сохранять.
    // Из  makePay передадим checkData в makeFiscalPay
    props.makePay(
      getReqDataForAP(),
      paymentMethod,
      checkData,
      '',
      fiscalPayData
    );
  };

  const handleMakePay = () => {
    const isTerminalFiscal = isFiscal && isAuthInFiscal;

    setShowDublicateWarning(false);

    if (isDataForRequestValid()) {
      const reqData = getReqDataForAP();

      if (!isTheSamePaymentExist(reqData, showError)) return;

      setIsDisabled(true);

      // Если терминал фискальный и сервис не входит в список исключения и фискальный токен жив
      if (
        isTerminalFiscal
         && props.checkFiscalToken()
         && !IGNORED_SERVICES_FOR_FISCAL.includes(currentService.idService)
      ) {
        handleFiscalMakePay();
        sendConfirmScreenInfoLog('handleMakePayFiscalSuccess');
      } else {
        handleAPMakePay();
        kaspiSchemeInModalCallback();
        sendConfirmScreenInfoLog('handleMakePayNoFiscalSuccess');
      }

      toggleAccordion(true);
    }
  };

  const sendConfirmScreenInfoLog = (reqType) => {
    props.sendInfoLogToGateway({
      message: `isFiscal: ${isFiscal}, isAuthInFiscal: ${isAuthInFiscal}, isFiscalTokenAlive: ${isFiscalTokenAlive()}, authCashier: ${getAuthData()?.authCashier}, fiscalTokenDate: ${getFiscalData()?.date}`,
      reqType,
    });
  };

  const isDataForRequestValid = () => checkAmount() && checkBalance() && checkPaymentMethod();

  const kaspiSchemeInModalCallback = () =>
    props.initKaspiSchemeInModal && props.initKaspiSchemeInModal();

  const checkAmount = useCallback(() => {
    if (Number(depositedAmount)) {
      return true;
    }

    showError('Сумма оплаты не может быть равной нулю');

    return false;
  }, [depositedAmount]);

  const checkBalance = useCallback(() => {
    if (Number(mayPay) >= Number(depositedAmount)) {
      return true;
    }

    showError('Суммы на балансе недостаточно для оплаты');

    return false;
  }, [mayPay, depositedAmount]);

  const checkPaymentMethod = useCallback(() => {
    if (typeof paymentMethod === 'number') {
      return true;
    }

    showError('Выберите способ оплаты');

    return false;
  }, [paymentMethod]);

  const goBack = useCallback(() => {
    const { typeInterface } = currentService;

    if (!PAY_TYPES_WITHOUT_ENTER_AMOUNT_SCREEN.includes(typeInterface)) {
      return showEnterAmountScreen();
    }

    return showPayTypeScreen();
  }, []);

  const willBeCredited = depositedAmount - commissionSum;

  return (
    <div className="pay-form">
      {showDublicateWarning && (
      <DublicatePaymentWarning
        paymentInfo={dublicatePaymentInfo}
        confirmDublicate={() => setDublicateConfirmed(true)}
        cancelDublicatePayment={() => setShowDublicateWarning(false)}
      />
      )}
      {!props.isItKaspiSchemeInModal && (
      <>
        <button type="button" onClick={goBack} className="buttonWithoutStyles back-text">
          {t('back')}
        </button>

        <h4>{t('confirmationOfInfo')}</h4>
      </>
      )
      }
      <div className="confirm-info">
        <form onSubmit={validateAndPay} id="confirmScreenForm">
          <PaymentInfo />
          <div>
            <hr />
            <p key="depositedAmount">
              <b>Внесено: </b>
              {Number.isInteger(depositedAmount) ?
                formatMoney(depositedAmount) :
                parseFloat(depositedAmount).toFixed(2)}  тг
            </p>
            <p key="willBeCreditedAmount">
              <b>К зачислению: </b>
              {Number.isInteger(willBeCredited) ?
                formatMoney(willBeCredited) :
                parseFloat(willBeCredited).toFixed(2)}  тг
            </p>
            <Commission type="sum" />
            <hr />
          </div>
          {(
            isFiscal &&
          !currentService.isEMoney &&
          !SERVICES_WITHOUT_COMISSION.includes(currentService.idService)
          ) &&
          <SelectForFiscalSections />
        }
          <Alert message={<MinAndMaxAmount idService={currentService.idService} />} banner />
          <PaymentMethod />
          <hr /><LangList/>
          <button
            disabled={isDisabled && !sectionsWasRequested}
            className="btn btn-primary"
            type="submit"
            ref={ref}
          >
            оплатить {formatMoney(Math.ceil(depositedAmount))} тг
          </button>
        </form>
      </div>
    </div>
  );
};

Confirm.propTypes = {
  adding: PropTypes.array.isRequired,
  checkInfo: PropTypes.array.isRequired,
  paymentsHistory: PropTypes.array.isRequired,
  paymentInProccess: PropTypes.array.isRequired,
  mayPay: PropTypes.number.isRequired,
  commissionSum: PropTypes.number.isRequired,
  paymentMethod: PropTypes.number,
  minAmount: PropTypes.number.isRequired,
  maxAmount: PropTypes.number.isRequired,
  otherIdServiceForMakePay: PropTypes.number.isRequired,
  depositedAmount: PropTypes.number.isRequired,
  sectionForPayment: PropTypes.number.isRequired,
  willBeCreditedAmount: PropTypes.number.isRequired,
  currService: PropTypes.object.isRequired,
  kkmInfo: PropTypes.object.isRequired,
  account: PropTypes.string.isRequired,
  SN: PropTypes.string.isRequired,
  makePay: PropTypes.func.isRequired,
  saveCheck: PropTypes.func.isRequired,
  showEnterAmountScreen: PropTypes.func.isRequired,
  showPayTypeScreen: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  toggleAccordion: PropTypes.func.isRequired,
  requestKkmInfo: PropTypes.func.isRequired,
  setCheckWithCode: PropTypes.func.isRequired,
  setKtjCheckWithCode: PropTypes.func.isRequired,
  isFiscal: PropTypes.bool.isRequired,
  isAuthInFiscal: PropTypes.bool.isRequired,
  isMyPostPayment: PropTypes.bool.isRequired,
  comment: PropTypes.string,
  getSections: PropTypes.func.isRequired,
  checkFiscalToken: PropTypes.func.isRequired,
  isItKaspiSchemeInModal: PropTypes.bool,
  currentServiceKaspi: PropTypes.object,
  initKaspiSchemeInModal: PropTypes.func,
  clientIin: PropTypes.string.isRequired,
  clickEnterConfirmScreen: PropTypes.func.isRequired,
  sendInfoLogToGateway: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired
};

Confirm.defaultProps = {
  paymentMethod: null,
  comment: '',
  isItKaspiSchemeInModal: false,
  currentServiceKaspi: null,
  initKaspiSchemeInModal: null
};

function mapStateToProps(state) {
  return {
    commissionSum: Number(state.commission.commissionSum),
    currentComProfile: state.commission.currentComProfile,
    commissionProfiles: state.commission.commissionProfiles,
    currService: state.kassa.currentService,
    otherIdServiceForMakePay: state.kassa.otherIdServiceForMakePay,
    mayPay: state.kassa.balances.mayPay,
    adding: [].concat(state.pay.adding),
    account: state.pay.account,
    depositedAmount: Number(state.pay.depositedAmount),
    willBeCreditedAmount: Number(state.pay.willBeCreditedAmount),
    checkInfo: state.pay.checkInfo,
    SN: state.payment.SN,
    maxAmount: Number(state.payment.maxAmount),
    minAmount: Number(state.payment.minAmount),
    paymentMethod: state.payment.paymentMethod,
    paymentsHistory: state.payment.paymentsHistory,
    paymentInProccess: state.payment.inProccess,
    isFiscal: state.fiscal.isFiscal,
    isAuthInFiscal: state.fiscal.isAuthInFiscal,
    kkmInfo: state.fiscal.kkmInfo,
    sectionForPayment: state.fiscal.sectionForPayment,
    isMyPostPayment: state.mypost.isMyPostPayment,
    comment: state.pay.comment,
    clientIin: state.payment.clientIin,
    lang: state.checksWithCode.lang
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...payAction,
    ...checkActions,
    showError: showErrorAlert,
    toggleAccordion: showAccordion,
    requestKkmInfo: getKkmInfo,
    getSections,
    checkFiscalToken,
    makePay,
    sendInfoLogToGateway
  },
  dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
