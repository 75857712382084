import { combineReducers } from 'redux';
import PayTypes from 'paytypes';
import { connectRouter } from 'connected-react-router';

import loader from './loader';
import alert from './alert';
import pay from './pay';
import payment from './payment';
import services from './services';
import kassa from './home';
import encashment from './encashment';
import commission from './commission';
import checksWithCode from './check';
import ktjReceipt from './ktjReceipts';
import fiscal from './fiscal';
import wooppay from './payTypes/wooppay';
import jysan from './payTypes/jysan';
import cashout from './payTypes/cashout';
import mypost from './payTypes/mypost';
import notification from './notification';
import initialDownload from './initialDownload';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  loader,
  alert,
  kassa,
  pay,
  checksWithCode,
  ktjReceipt,
  payment,
  services,
  encashment,
  commission,
  fiscal,
  wooppay,
  jysan,
  cashout,
  mypost,
  notification,
  initialDownload,
  ...PayTypes.reducers,
});

export default createRootReducer;
