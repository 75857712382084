/* eslint-disable import/extensions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import XLSX from 'xlsx';
import CustomButton from 'components/UI/Button/button';
import moment from 'moment';
import 'moment/locale/ru';
import {
  getTheStartDateOfTheCurrentDay,
  getTheEndOfTheCurrentDay,
  getFormatedJsDate,
} from 'helpers/main';
import { getCashoutReport } from 'actions/api/reports';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import { Russian } from 'flatpickr/dist/l10n/ru';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Grid } from '@material-ui/core';
import ReportSaveInstruction from 'public/files/report-save-instruction.pdf';

const columns = [
  {
    title: 'Лицевой счет',
    key: 'account',
    dataIndex: 'account',
  },
  {
    title: 'Агент [id]',
    key: 'agentName',
    dataIndex: 'agentName',
  },
  {
    title: 'Дата запроса',
    key: 'dateIn',
    dataIndex: 'dateIn',
  },
  {
    title: 'Дата вывода',
    key: 'dateOut',
    dataIndex: 'dateOut',
  },
  {
    title: 'Запрошенная сумма, тг',
    key: 'amountRequest',
    dataIndex: 'amountRequest',
  },
  {
    title: 'Выведенная сумма, тг',
    key: 'amountOut',
    dataIndex: 'amountOut',
  },

  {
    title: 'Вознаграждение',
    key: 'remuneration',
    dataIndex: 'remuneration',
  },
];

const CashoutReport = (props) => {
  const [dateFrom, setDateFrom] = useState(getTheStartDateOfTheCurrentDay());
  const [dateTo, setDateTo] = useState(getTheEndOfTheCurrentDay());
  const [account, setAccount] = useState('');
  const [accountError, setAccountError] = useState('');
  const [localOutputReport, setLocalOutputReport] = useState([]);
  const [componentRef, setComponentRef] = useState({});
  const { cashoutReport } = props;
  let amountReqSumm = 0;
  let amountOutSum = 0;

  useEffect(() => {
    const dateFromStrUe = getReqFormatDate(dateFrom);
    const dateToStrUe = getReqFormatDate(dateTo);

    props.getCashoutReport(dateFromStrUe, dateToStrUe, '');
  }, []);

  useEffect(() => {
    setLocalOutputReport(cashoutReport);
  }, [cashoutReport]);

  useEffect(() => {
    setAccountError('');
  }, [account]);

  const getFormattedSum = (sum) => `${sum.toLocaleString()} тг`;

  const changeDateRange = (dates) => {
    if (dates.length === 2) {
      const dateFromVal = moment(dates[0]).format('YYYY-MM-DD 00:00:01');
      const dateToVal = moment(dates[1]).format('YYYY-MM-DD 23:59:59');
      const dateFromStrCd = moment(dateFromVal).format('YYYYMMDDHHmmss');
      const dateToStrCd = moment(dateToVal).format('YYYYMMDDHHmmss');

      setDateFrom(dateFromStrCd);
      setDateTo(dateToStrCd);
    }
  };

  const searchOutputReport = () => {
    const dateFromStrSo = getReqFormatDate(dateFrom);
    const dateToStrSo = getReqFormatDate(dateTo);

    if (account && account.length !== 10) {
      setAccountError('Лицевой счет должен быть в формате 7771112233');
    } else {
      props.getCashoutReport(dateFromStrSo, dateToStrSo, account);
    }
  };

  const getUserData = (date) =>
    moment(date.toString(), 'YYYYMMDDHHmmss').format('DD.MM.YYYY HH:mm:ss');

  const getReqFormatDate = (date) =>
    moment(date, 'YYYYMMDDHHmmss').format('YYYY-MM-DD');

  const exportToXlsx = () => {
    const workbook = XLSX.utils.table_to_book(
      document.getElementById('reportTable')
    );

    workbook.Sheets.Sheet1['!cols'] = [
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];

    // eslint-disable-next-line
    XLSX.writeFile(workbook, `Отчет_Выдача_наличных_за_период_${getFormatedJsDate()}.xls`, {
      bookType: 'xlsx',
      type: 'buffer',
    });
  };

  const table =
    localOutputReport.length > 0 ? (
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="spanning table" classes={{ root: 'cellBorder' }}>
          <TableHead classes={{ root: 'cellBorder' }}>
            <TableRow>
              {columns.map((item, idx) => (
                <TableCell key={idx} sortDirection="asc">
                  {item.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {localOutputReport.map((item, idx) => {
              amountReqSumm += item.amountRequest;
              amountOutSum += item.amountOut;

              return (
                <TableRow key={idx}>
                  <TableCell>{item.account}</TableCell>
                  <TableCell>
                    {item.agentName} [{item.idAgent}]
                  </TableCell>
                  <TableCell>{getUserData(item.dateIn)}</TableCell>
                  <TableCell>{getUserData(item.dateOut)}</TableCell>
                  <TableCell>{getFormattedSum(item.amountRequest)}</TableCell>
                  <TableCell>{getFormattedSum(item.amountOut)}</TableCell>

                  <TableCell>{item.remuneration}</TableCell>
                </TableRow>
              );
            })}
            <TableRow style={{ background: '#d5d5d5' }}>
              <TableCell>Итого</TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell>{getFormattedSum(amountReqSumm)}</TableCell>
              <TableCell>{getFormattedSum(amountOutSum)}</TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      'Не найдено записей за выбранный период'
    );

  const dateFromStr = moment(dateFrom, 'YYYYMMDDHHmmss').format('DD.MM.YYYY');
  const dateToStr = moment(dateTo, 'YYYYMMDDHHmmss').format('DD.MM.YYYY');
  const titlePrint = `Отчет по сервису "Выдача наличных" за период: ${dateFromStr} - ${dateToStr}`;

  return (
    <>
      <Typography variant="button">
        Отчет по сервису «Выдача наличных»
      </Typography>
      <div className="payment-history-head">
        <Grid container>
          <Grid item sm={6} xs={12}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <input
                  placeholder="Лицевой счет"
                  className="search"
                  onChange={(e) => setAccount(e.target.value)}
                  style={{ background: '#fff', border: accountError ? '1px solid red' : '1px solid transparent' }}
                />
                <span style={{ color: accountError ? 'red' : 'transparent' }}>{accountError}</span>
              </Grid>
              <Grid item sm={5} xs={12}>
                <Flatpickr
                  options={{
                    dateFormat: 'd-m-Y',
                    mode: 'range',
                    locale: Russian,
                    defaultDate: [
                      moment(dateFrom, 'YYYYMMDDHHmmss').format('DD-MM-YYYY'),
                      moment(dateTo, 'YYYYMMDDHHmmss').format('DD-MM-YYYY'),
                    ],
                  }}
                  className="datepicker-wrapper"
                  onChange={(dates) => changeDateRange(dates)}
                  style={{ margin: '0', width: '100%' }}
                />
              </Grid>
              <Grid item sm={1} xs={12}>
                <CustomButton
                  onClick={searchOutputReport}
                  className="custom-button-background"
                >
                  Поиск
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} xs={12} align="right">
            {localOutputReport.length > 0 && (
              <>
                <div>
                  <a
                    target="_blank"
                    href={ReportSaveInstruction}
                    rel="noopener noreferrer"
                    className="link-block"
                  >
                    Как сохранить отчет в pdf-файл?
                  </a>
                  <CustomButton onClick={exportToXlsx}>
                    Экспорт в excel
                  </CustomButton>
                  <ReactToPrint
                    trigger={() => (
                      <CustomButton >
                        Распечатать
                      </CustomButton>
                    )}
                    content={() => componentRef}
                  />
                </div>
                <div style={{ display: 'none' }} id="reportTable">
                  <div
                    ref={(el) => {
                      setComponentRef(el);
                    }}
                    style={{ padding: 20 }}
                  >
                    {titlePrint}
                    <div style={{ marginTop: 20 }}>{table}</div>
                  </div>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </div>
      {table}
    </>
  );
};

CashoutReport.propTypes = {
  cashoutReport: PropTypes.array.isRequired,
  getCashoutReport: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    cashoutReport: state.kassa.cashoutReport,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getCashoutReport }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CashoutReport);
