import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorComponent from './Error';
import { logWarningToSentry } from '../helpers/main';

class ErrorBoundary extends Component {
  state = { hasError: false };

  componentDidCatch(error, errorInfo) {
    logWarningToSentry(`${error.message}. \n ${errorInfo.componentStack}`);
    this.setState({ hasError: true });
  }

  render() {
    if (!this.state.hasError) {
      return <div className="errorBoundaryContent">{this.props.children}</div>;
    }

    return <ErrorComponent />;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
};

export default ErrorBoundary;
