import React from 'react';
import PayTypes from 'paytypes';
import MaskedInput from 'react-maskedinput';

import IinField from 'components/IinField';
import Modal from 'components/UI/Modal/modal';
import { t, getMaskForInput, checkAccountOnReg } from 'helpers/main';
import MinAndMaxAmount from 'components/MinAndMaxAmount';

const {
  containers: { EnterCounterContainer },
  templates: { EnterCounterTemplate }
} = PayTypes;

class EnterCounter extends EnterCounterTemplate {
  state = {
    showModal: false,
    account: '',
    isLoading: false
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDownFowWindow);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDownFowWindow);
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props.payTypeEnterCounter;
    const { id: oldId } = prevProps.payTypeEnterCounter;

    if (id !== oldId && id) {
      this.showModal();
    }
  }

  onKeyDownFowWindow = (e) => {
    if (e.key === 'Enter') {
      if (this.state.showModal) {
        this.confirm();
      } else {
        this.validateAndCheck();
      }
    }
  };

  getMeters() {
    const { counters } = this.props.payTypeEnterCounter;

    return [].concat(counters).map(({ name, code, value }, key) => (
      <tr key={code}>
        <td>{name}</td>
        <td><input defaultValue={Number(value || 0)} disabled /></td>
        <td>
          <input
            defaultValue={value || 0}
            onChange={(e) => this.props.updateMeterVal(e.target.value, key)}
          />
        </td>
      </tr>
    ));
  }

  confirm = () => {
    const {
      setConfirmInfo,
      showEnterAmountScreen,
      updateWillBeCreditedAmount,
      pay: { account },
      payTypeEnterCounter: { editedCounters, name },
      commission: { currentComProfile }
    } = this.props;

    const itogAmount = editedCounters.reduce((sum, item) => sum + Math.round(item.value), 0);

    updateWillBeCreditedAmount(itogAmount, currentComProfile);

    const confirmField = [
      { name: 'account', value: account },
      { name: 'fio', value: name },
    ];

    const checkInfo = {
      input: {
        account,
      }
    };

    setConfirmInfo(confirmField, this.getAddings(), checkInfo);
    showEnterAmountScreen();
  }

  showModal = () => this.setState({ showModal: true });

  hideModal = () => this.setState({ showModal: false });

  handleOnChange(e) {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  validateAndCheck() {
    const { account } = this.state;
    const {
      onlineCheck,
      showError,
      iinIsValidOrEmpty,
      kassa: { currentService },
      payTypeEnterCounter: { id },
      updateFieldValue
    } = this.props;

    this.setState({ isLoading: false });
    if (!iinIsValidOrEmpty()) return;
    if (account) {
      const { regExp, maskEdit } = currentService;

      if (checkAccountOnReg(account, regExp, maskEdit)) {
        updateFieldValue('account', account.trim());

        if (id && id.toString().trim() === account.toString().trim()) {
          return this.showModal();
        }
        this.setState({ isLoading: true });

        return onlineCheck(currentService, account)
          .then(() => { this.setState({ isLoading: false }); });
      }

      return showError(
        'Проверьте, пожалуйста, и повторите попытку',
        'Лицевой счет некорректен'
      );
    }

    return showError(
      'Проверьте, пожалуйста, и повторите попытку',
      'Лицевой счет обязателен для заполнения'
    );
  }

  // Рудненская ЭнергоКомпания - Эл.энергия - физ.лица 1240180004 , 51180022
  renderComponent() {
    const {
      pay,
      payTypeEnterCounter: { name, address, info },
      kassa: { currentService: { maskEdit, name: serviceName, idService } },
    } = this.props;
    const { showModal } = this.state;
    const mask = getMaskForInput(maskEdit);

    return (
      <div >
        <Modal
          title={serviceName}
          isOpen={showModal}
          onOk={this.confirm}
          okText={t('pay')}
          onClose={this.hideModal}
        >
          <div>
            <h1>{serviceName}</h1>
            <p><b>{t('account')}:</b> {pay.account}</p>
            <p><b>{t('fio')}:</b> {name}</p>
            <p><b>{t('address')}:</b> {address}</p>
            {(typeof info !== 'undefined' && info) && (
              <p><b>Доп. информация:</b> {info}</p>
            )}
            <br />
            <h2>{t('enterMeterReadings')}</h2><br />
            <table className="enter-counter-table">
              <thead>
                <tr>
                  <th> {t('counter')} </th>
                  <th> {t('lastMeter')} </th>
                  <th> {t('currentMeter')} </th>
                </tr>
              </thead>
              <tbody>
                {this.getMeters()}
              </tbody>
            </table>
          </div>
        </Modal>

        <div className="pay-form">
          <IinField/>
          <label htmlFor="account">№ счета</label>
          <MaskedInput
            mask={mask}
            type="tel"
            autoComplete="off"
            name="account"
            id="account"
            maxLength={40}
            placeholder="№ счета"
            placeholderChar=" "
            onChange={(e) => this.handleOnChange(e)}
            value={this.state.account}
            formatCharacters={{
              W: {
                validate(char) { return /[\wа-яА-Я-]/.test(char); },
              }
            }}
            disabled={this.state.isLoading}
          />
        </div>
        <MinAndMaxAmount idService={idService} />
        <button
          type="button"
          className="btn-primary"
          onClick={() => this.validateAndCheck()}
        >
          {t('next')}
        </button>
      </div>
    );
  }
}

export default EnterCounterContainer(EnterCounter);
