import InitDb from './InitDb';
import { logWarningToSentry } from '../helpers/main';

// Заполнение/Добавление записей в таблицу с сервисами
export function saveCommissionProfilesInIDB(profiles) {
  return getDbObject()
    .then((db) => {
      clearProfilesTable(db)
        .then(() => {
          const tx = db.transaction('commissionProfiles', 'readwrite');
          const store = tx.objectStore('commissionProfiles');

          profiles.map(async profile => {
            const obj = {
              am: '',
              id: 0,
              name: '',
              positions: {},
              pr: '',
              tKz: '',
              tRU: '',
              ...profile
            };

            store.put(obj);
            await tx.done;
          });
        });
    });
}

const getDbObject = async () => InitDb();

const clearProfilesTable = async (db) => {
  const tx = db.transaction('commissionProfiles', 'readwrite');
  const store = tx.objectStore('commissionProfiles');
  const clearRes = store.clear();

  await tx.done;

  return clearRes;
};

// Получение списка сервисов
export async function getCommissionProfilesFromIDB() {
  try {
    const db = await InitDb();

    return db.getAll('commissionProfiles');
  } catch (e) {
    return [];
  }
}

// Получение профиля комиссий по id Сервиса
export async function getCommissionProfileOnServiceID(id) {
  try {
    const db = await InitDb();

    const value =
      await db.getAll('commissionProfiles')
        .then((profiles) => {
          const currentProfile = profiles.filter(profile => {
            if (typeof profile.services !== 'undefined') {
              if (Array.isArray(profile.services.idService)) {
                return profile.services.idService.includes(id);
              }

              return profile.services.idService.toString() === id.toString();
            }

            return false;
          });

          if (Array.isArray(currentProfile)) return currentProfile[0];

          return currentProfile;
        });

    if (typeof value === 'undefined') return null;

    return value;
  } catch (e) {
    logWarningToSentry(`Ошибка получения профиля комиссии: ${e}`);

    return null;
  }
}
