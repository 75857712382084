import React, { useState, useRef } from 'react';
import Icon from 'components/UI/Icon/index';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showAccordion } from 'actions/services';
import PropTypes from 'prop-types';
import './accordion.scss';

const Accordion = (props) => {
  const [activeClass, setActiveClass] = useState('');
  const [rotateIcon, setRotateIcon] = useState('accordion__icon');

  const content = useRef(null);

  const toggleAccordion = () => {
    props.showAccordion(!props.isAccordionOpen);
    setActiveClass(activeClass === '' ? 'active' : '');
    setRotateIcon(
      activeClass === 'active' ? 'accordion__icon' : 'accordion__icon rotate'
    );
  };

  return (
    <div className="accordion__section">
      <button className={`accordion ${props.isAccordionOpen ? 'accordion_active' : ''}`} onClick={toggleAccordion}>
        <span className="accordion__title">{`${props.isAccordionOpen ? 'Свернуть список сервисов' : 'Развернуть список сервисов'}`}</span>
        <Icon type="chevron" className={`${rotateIcon}`} />
      </button>
      <div
        ref={content}
        className={`accordion__content ${props.isAccordionOpen && 'accordion_display'}`}
      >
        <div className="accordion__text">
          {props.children}
        </div>
      </div>
    </div >
  );
};

Accordion.propTypes = {
  isAccordionOpen: PropTypes.bool.isRequired,
  showAccordion: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  isAccordionOpen: state.kassa.isAccordionOpen,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    showAccordion,

  },
  dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Accordion);
