import { push } from 'react-router-redux';

export function goToMainPage() {
  return (dispatch) => {
    dispatch(push('/main'));
  };
}

export function goToLoginPage() {
  // eslint-disable-next-line no-unused-vars
  return (dispatch) => {
    window.location = '/login';
  };
}

export function goToReport() {
  return (dispatch) => {
    dispatch(push('/summary-report'));
  };
}

export function goToCashoutReport() {
  return (dispatch) => {
    dispatch(push('/cashout-report'));
  };
}

export function goToEncashmentListPage() {
  return (dispatch) => {
    dispatch(push('/encashment-list'));
  };
}

export function goToEndSessionPage() {
  // eslint-disable-next-line no-unused-vars
  return (dispatch) => {
    window.location = '/session-ended';
  };
}
