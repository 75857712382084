export const SET_CHECK_WITH_CODE = 'SET_CHECK_WITH_CODE';
export const SEND_CHECK_TO_GATEWAY = 'SEND_CHECK_TO_GATEWAY';
export const SET_KTJ_CHECK_WITH_CODE = 'SET_CHECK_WITH_CODE';
export const REMOVE_CHECK_WITH_CODE = 'REMOVE_CHECK_WITH_CODE';
export const REMOVE_KTJ_CHECK_WITH_CODE = 'REMOVE_CHECK_WITH_CODE';
export const UPDATE_CHECK_WITH_CODE_DATA = 'UPDATE_CHECK_WITH_CODE_DATA';
export const SET_CHECK_WITH_CODE_IS_SAVED = 'SET_CHECK_WITH_CODE_IS_SAVED';
export const SAVE_CHECK_SETTINGS = 'SAVE_CHECK_SETTINGS';
export const GET_CHECK_SETTINGS = 'GET_CHECK_SETTINGS';
export const SET_RECEIPT_LANG = 'SET_RECEIPT_LANG';
