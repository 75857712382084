/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PayTypes from 'paytypes';

import Alert from 'components/UI/Alert/alert';
import MinAndMaxAmount from 'components/MinAndMaxAmount';
import {
  t,
  isInt,
  parseAccount,
  formatMoney,
  b64DecodeUnicode,
  b64EncodeUnicode,
  setFocusFormElement,
  setObjectProperty
} from 'helpers/main';
import { adjustTheAmount } from 'helpers/pay';
import {
  KASPI_CREDIT,
  KASPI_GOLD,
  CASH2CARD_KASSA24,
  ALMATYENERGOSBYT_OBLAST,
  JYSAN_CARD_REPLENISHMENT,
  ALMATYENERGOSBYT_UR_LITCA,
  SERVICES_STATUSES_WITH_ONLINE_CHECK,
  BCK_SERVICES_WITH_KBK_KNO_SELECT,
  SERVICES_WITH_KBK_KNO_IN_RECEIPT
} from 'constants/services';
import { getAdditionalDetails } from 'actions/services';
import { setOtherIdServiceForMakePay } from 'actions/pay';
import RadioGroup from 'components/UI/RadioGroup/radioGroup';
import {
  getAddingsAmount0,
  getAddingsAmount1,
  getAddingsAmount2,
  getAddingsCheckTypeInterfaceGetAmount0,
  getAddingsCheckTypeInterfaceGetAmount1,
  getAddingsCheckTypeInterfaceGetAmount2,
  calculateConstraint,
  getAccountHandler,
  confirmFeldsCheckInfo,
  confirmCheckInfoGetValue,
  confirmConfirmFieldsGetValue,
  confirmCaseTypeInterface79,
  getDynamicFieldsItem,
  getIsCountInputDisabled,
  getDefaultValueInRender,
  getServiceNonExistError,
  getKbkSelectOptions,
  getKnoSelectOptions,
  getKnpSelectOptions
} from 'helpers/dynamic';
import { PAY_SCHEMES } from 'constants/payTypes';

import restrictedKBKAndKNO from 'public/json/finesAndTaxes/restrictedKBKAndKNO';

import FirstDynamicScreen from './FirstDynamicScreen';

/*
  Dynamic1:

  3556 OLX.kz. Пополнить счет (ЛС 3556)
  6181 Жамбыл жарык сауда Карточные счетчики 37201263664
  2613 Thuraya - Пополение по номеру дозвона на 4000 тг (Fix Sum)
    Account: 8821671153161; Amount: 5500;
    Constraint: 7776441244|; Amount0: 5500; Amount1: 5500; Amount2: 5500

  PlayStation.Store 500 - пополнение бумажника v.tochshevikov@gmail.com
  Керемет KIDS - Подписка на журнал (есть комиссия) 6425687123 74

  Thuraya - Пополение по номеру сим-карты на 5500 тг (id: 2607, payType: 75, profile: 16997)
  Номер сим карты: 89882052013051748171
  Номер мобильного телефона: 8(821)671-15-80

  АлматыЭнергоСбыт юр. лица область (980540000793, 30920)

  Dynamic 2:
    3240 Plagiat.pl - Проверка на плагиат  (checkOnline = 2)
      Account aikena_kz@mail.ru;
      Amount 16800
      Constraint: aikena_kz@mail.ru;
      Amout0: 16800,
      commission:16800
      memo:"KASSA24_TOKENS_20"

    3558 Альфа-Банк - Погашение Кредита
      Account 600320303614
      Amount 17260,54

    Dynamic 3:
      Парковочное пространство Астаны - Оплата паркинга
      ЛС: 1016;471MPA01;60;7014567716

      Казахстанская Ипотечная Компания
      860522450960 01.09.2016
      900704400577 28.08.2018
*/
const ALL_DISPLAY_INFO_FIELDS = ['Сумма задолженности'];
const SERVICES_WITHOUT_ENTER_SUM = [
  ALMATYENERGOSBYT_UR_LITCA,
  ALMATYENERGOSBYT_OBLAST,
  CASH2CARD_KASSA24,
  JYSAN_CARD_REPLENISHMENT
];

const {
  templates: { Dynamic3Template },
  actions: { dynamic }
} = PayTypes;

class Dynamic extends Dynamic3Template {
  state = {
    internalNumberOfTheScreen: 1,
    formData: {},
    showInfoModal: false,
    wasInfoAnswered: false,
    wasInfoAgreed: false,
    wasSetHelpService: false,
    kaspiSelectedOption: false,
    isKaspi: false,
    isLoading: false
  };

  componentDidMount() {
    const { additionalDetails, currentService: { idService }, pay: { account } } = this.props;

    if (typeof additionalDetails[Number(idService)] === 'undefined') {
      this.props.getAdditionalDetails(idService);
    } else {
      const addInfo = additionalDetails[Number(idService)];

      this.getFields(addInfo);
    }

    if (account && !this.state.formData.account) {
      this.handleOnChange({ id: 'account', value: account.toString() });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      wasInfoAgreed,
      wasInfoAnswered,
      internalNumberOfTheScreen,
      formData,
      showInfoModal,
      wasSetHelpService
    } = this.state;

    const {
      additionalDetails,
      currentService: { typeInterface, idService },
      fields,
      pay: { account },
      infoMessage,
      dynamic2: { selectedOption, options }
    } = this.props;

    // Если выбран сервис, для которого НЕ запрошены additionalDetails - запрашиваем
    this.getAdditionalDetailsHandler(typeInterface, idService, additionalDetails, prevProps);
    // Когда получили additionalDetails, запрашиваем поля для формы
    this.getFormFieldAfterGetAdditionals(additionalDetails, prevProps, idService, fields);

    if (fields.length) {
      switch (typeInterface) {
        case 75: this.checkMaxMinFix(prevProps); break;
        case 79: this.checkOptions(prevProps); break;
        case 86: this.checkInfoAndFix(prevProps); break; // info и fix стали необязательны
        default: break;
      }

      this.checkWasOnlineCheck(
        internalNumberOfTheScreen,
        infoMessage,
        wasInfoAnswered,
        showInfoModal,
        wasInfoAgreed
      );
    }

    if (account && !formData.account) {
      this.handleOnChange({ id: 'account', value: account });
    }

    if (
      (idService === KASPI_GOLD.SERVICE_ID || idService === KASPI_CREDIT.SERVICE_ID) &&
      (selectedOption !== '' &&
      options.length > 0 &&
      !wasSetHelpService && internalNumberOfTheScreen === 2)
    ) {
      this.getLastOptionCharHandler(selectedOption);
    }

    if (prevState.internalNumberOfTheScreen !== 2 && internalNumberOfTheScreen === 2) {
      setFocusFormElement('secondScreenForm');
    }
  }

  getAdditionalDetailsHandler(typeInterface, idService, additionalDetails, prevProps) {
    if (typeof idService !== 'undefined' && prevProps.kassa.currentService.idService !== idService) {
      // если перевыбран сервис находясь при этом уже в динамо схеме то обнуляем всё
      this.initState();
      this.props.initPayType(typeInterface);

      if (typeof additionalDetails[Number(idService)] === 'undefined') {
        this.props.getAdditionalDetails(idService);
      } else {
        const addInfo = additionalDetails[Number(idService)];

        this.getFields(addInfo);
      }
    }
  }

  getFormFieldAfterGetAdditionals(additionalDetails, prevProps, idService, fields) {
    if ((typeof additionalDetails[Number(idService)] !== 'undefined' &&
      typeof prevProps.additionalDetails[Number(idService)] === 'undefined') ||
      (!fields.length && typeof additionalDetails[Number(idService)] !== 'undefined')) {
      const addInfo = additionalDetails[Number(idService)];

      this.getFields(addInfo);
    }
  }

  checkWasOnlineCheck(
    internalNumberOfTheScreen,
    infoMessage,
    wasInfoAnswered,
    showInfoModal,
    wasInfoAgreed
  ) {
    if (this.wasOnlineCheck()) {
      if (internalNumberOfTheScreen !== 2) {
        if (infoMessage && !wasInfoAnswered && !showInfoModal) {
          this.setShowInfoModal(true);
        }

        if (infoMessage && wasInfoAgreed) {
          this.showSecondDynamicScreen();
        }
      }
    }
  }

  onKeyDownFowWindow = (e) => {
    if (e.key === 'Enter') {
      if (this.state.internalNumberOfTheScreen === 2) {
        this.checkMinMaxFixAndConfirm();
      } else this.validateFormAndOnlineCheck();
    }
  };

  /* Делим поля по типам: скрытые, фиксированные, обычные, и пишем в пропсы */
  getFields(addInfo) {
    const {
      updateFixedFields,
      updateFields,
      updateOptions,
      commission: { currentComProfile },
    } = this.props;

    const fields = JSON.parse(addInfo);

    if (Array.isArray(fields)) {
      updateFields(fields);
      const hiddenAndFix = fields.filter(item => (item.hidden && item.name === 'fix'));

      if (hiddenAndFix.length) {
        updateFixedFields(hiddenAndFix[0]);
        this.props.updateWillBeCreditedAmount(hiddenAndFix[0].value, currentComProfile, true);
      }
      // fix for bck
      if (this.isBckService()) {
        fields.forEach((field) => {
          switch (field.name) {
            case 'kbk':
              setObjectProperty(field, 'options', getKbkSelectOptions());
              break;
            case 'tax':
              setObjectProperty(field, 'options', getKnoSelectOptions());
              break;
            case 'knp':
              setObjectProperty(field, 'options', getKnpSelectOptions());
              break;
            default:
              break;
          }
        });
      }

      const fieldsWithOptions = fields.filter(item => (item.options !== null));

      if (fieldsWithOptions.length) {
        updateOptions(fieldsWithOptions);
      }
    }
  }

  checkMaxMinFix(prevProps) {
    const { min, max, fix } = prevProps.dynamic1;
    const { wasInfoAgreed } = this.state;
    const {
      dynamic1: { min: newMin, max: newMax, fix: newFix },
      commission: { currentComProfile },
      infoMessage
    } = this.props;

    // Они могут быть не равны только после онлайн-проверки, используем их в подтверждении
    if (min !== newMin && max !== newMax && fix !== newFix) {
      if (Number(newFix)) {
        this.props.updateWillBeCreditedAmount(newFix, currentComProfile, true);
      }

      // если было отображено инф сообщение и с ним согласились
      if (infoMessage && wasInfoAgreed) {
        this.submit(0);
      }

      // если есть инф сообщение, но его еще не отобразили
      if (infoMessage && !wasInfoAgreed) {
        this.setShowInfoModal(true);
      }

      // если нет инф сообщения
      if (!infoMessage) {
        this.submit(0);
      }
    }
  }

  checkOptions(prevProps) {
    const { selectedOption } = prevProps.dynamic2;
    const {
      commission: { currentComProfile },
      dynamic2: { selectedOption: newSelectedOption, options },
      pay: { willBeCreditedAmount }
    } = this.props;

    this.checkMaxMinFix(prevProps);

    // Устанавливаем сумму из первого опшна или
    // проверяем был ли выбран какой-нибудь option из списка
    if ((selectedOption !== newSelectedOption && newSelectedOption !== '') ||
      (!willBeCreditedAmount && options.length > 0 && options[0]['@attributes'].amount)) {
      const amount = this.getAmountFromSelectedOption(newSelectedOption);

      if (amount) {
        if (!this.isKaspiGoldService()) {
          this.props.updateWillBeCreditedAmount(amount, currentComProfile, true);
        }
      }
    }
  }

  isKaspiGoldService = () => this.props.currentService.idService === KASPI_GOLD.SERVICE_ID

  getAmountFromSelectedOption(key, newCount = '') {
    const { editedCounts, options } = this.props.dynamic2;
    const sum = options[key.toString()]['@attributes'].amount;

    let count = 1;

    if (newCount === '') {
      if (typeof editedCounts[key.toString()] === 'number') {
        count = editedCounts[key.toString()];
      }
    } else {
      count = newCount;
    }

    return Number(sum) * ((typeof count === 'boolean') ? 1 : Number(count));
  }

  changeCountAndUpdateAmount(key, e) {
    const { changeOptionCount } = this.props;
    const newCount = e.target.value;

    changeOptionCount(key, newCount);

    const amount = this.getAmountFromSelectedOption(key, newCount);

    this.props.updateAmountValue(Number(amount));
  }

  wasOnlineCheck = () => this.props.id.toString() !== '';

  wasOnlineCheckOnTheSameAccount = () => {
    const { id, pay: { account } } = this.props;
    const concatenatedAccount = this.getAccount();

    return ((account.toString() === id.toString() && id.toString() !== '') ||
      (concatenatedAccount === id.toString() && id.toString() !== ''));
  };

  checkInfoMessageAndAgreed=() => {
    const { wasInfoAnswered, wasInfoAgreed } = this.state;
    const {
      infoMessage,
    } = this.props;

    return infoMessage && (!wasInfoAnswered || (wasInfoAnswered && !wasInfoAgreed));
  }

  isBckService=() => {
    const {
      currentService: { idService },
    } = this.props;

    return BCK_SERVICES_WITH_KBK_KNO_SELECT.includes(idService);
  }

  isReturnValidateFormAndCheck=() => {
    const {
      iinIsValidOrEmpty
    } = this.props;
    let isReturn = false;

    if (!iinIsValidOrEmpty()) {
      isReturn = true;
    } else if (this.wasOnlineCheck()) {
      if (this.checkInfoMessageAndAgreed()) {
        this.setShowInfoModal(true);
        isReturn = true;
      }
    }

    return isReturn;
  }

  validateFormAndOnlineCheck = (e) => {
    e.preventDefault();
    const {
      showError,
      dynamic3,
      currentService: { typeInterface, idService },
    } = this.props;

    if (this.isReturnValidateFormAndCheck()) return;

    if (this.isBckService()) {
      const { formData: { kbk, ugd } } = this.state;
      const value = `${ugd}-${kbk}`;

      if (typeof restrictedKBKAndKNO[value.toString()] !== 'undefined' || !ugd) {
        showError('Данное сочетание КБК и КНО недоступно для оплаты!');

        return;
      }
    }

    // онлайн-проверка по текущему ЛС уже была
    if (this.wasOnlineCheckOnTheSameAccount()) {
      if (typeInterface === PAY_SCHEMES.DYNAMIC3) {
        const { info, fix } = dynamic3;

        if ((Number(fix) && info !== '' && fix !== '') || Number(idService) === JYSAN_CARD_REPLENISHMENT) {
          return this.submitWindow();
        }

        showError(
          'Данный лицевой счет не имеет задолженности по текущему сервису',
          'У Вас нет задолженности'
        );

        return this.init();
      }
      if (this.isHaveMaxSummTypeInterface75()) {
        return this.submitWindow();
      }

      return this.submit(0);
    }

    this.validateForm();
  }

  validateForm = () => {
    const { showError, fields, onlineCheck, currentService, updateFieldsValue } = this.props;
    const { formData } = this.state;
    const formDataWithMask = {};

    this.setState({ isLoading: false });
    // подгоняем заполненные значения под маску и пишем в редьюсер pay
    fields.map(({ id, mask, name }) => {
      if (Number(id) === 1 && typeof formData.account !== 'undefined') { // account
        formDataWithMask.account = mask ? parseAccount(mask, formData.account) : formData.account;

        return formDataWithMask;
      }

      formDataWithMask[name.toString()] = mask ?
        parseAccount(mask, formData[name.toString()]) :
        formData[name.toString()];
    });

    updateFieldsValue(formDataWithMask);

    /* Если item.optional = 1, значит поле необязательно для заполнения, не проверяем их */
    const notOptional = fields.filter(item =>
      (!item.optional && item.name !== 'label' && item.name !== 'fix' && (Number(item.id) !== 1)));

    const fieldsValues = notOptional.filter(item => (typeof formData[item.name] !== 'undefined' && formData[item.name] !== ''));

    if (typeof formData.account !== 'undefined' && notOptional.length === fieldsValues.length) {
      if (SERVICES_STATUSES_WITH_ONLINE_CHECK.includes(currentService.checkOnline)) {
        const account = this.getAccount();

        this.setState({ isLoading: true });

        return onlineCheck(currentService, account)
          .then(() => {
            this.setState({ isLoading: false });
          });
      }

      return this.showSecondDynamicScreen();
    }

    return showError(
      'Проверьте, пожалуйста, и повторите попытку',
      'Все поля обязательны для заполнения'
    );
  }

  /* Формирование Лицевого счета для онлайн проверки */
  getAccount() {
    // Проверяем значение параметра check, если = true, то включаем в онлайн-проверку, иначе нет
    const { formData } = this.state;
    const { payTypeDynamic: { fields } } = this.props;
    const maskForAccount = fields.filter(({ id }) => Number(id) === 1)[0].mask;
    const checked = fields.filter(item => (item.check));
    const account = checked.map(item => {
      if (item.name !== 'label') {
        // Выбираем все скрытые поля, кроме фикс, которые нужны в онлайн-проверке, если такие есть
        const hiddenAndNotFix = item.hidden && item.name !== 'fix' && item.check;

        if (hiddenAndNotFix) {
          return item.value;
        }
        if (typeof formData[item.name] !== 'undefined') {
          if (item.options === null) {
            return parseAccount(item.mask, formData[item.name]);
          }

          return formData[item.name];
        }
      }

      return null;
    }).join(';');

    return getAccountHandler(formData, account, maskForAccount);
  }

  // Когда получили в онлайн-проверке info и fix, обновляем сумму оплаты (Dynamic 3)
  // Когда изменилась сумма, отправляем на экран подтверждения
  checkInfoAndFix(prevProps) {
    const { dynamic3: { info: oldInfo, fix: oldFix }, showError, id: oldId } = prevProps;
    const {
      id,
      dynamic3: { info, fix },
      commission: { currentComProfile },
      currentService: { idService }
    } = this.props;

    if ((info !== oldInfo && fix !== oldFix) ||
        (Number(idService) === JYSAN_CARD_REPLENISHMENT && oldId !== id && id)) {
      if (Number(idService) !== JYSAN_CARD_REPLENISHMENT) {
        if (!Number(fix)) {
          showError(
            'Данный лицевой счет не имеет задолженности по текущему сервису',
            'У Вас нет задолженности'
          );

          return this.init();
        }
      }

      this.props.updateAmountValue(fix || 0);
      this.props.updateWillBeCreditedAmount(fix, currentComProfile, true);
      this.submit(fix);
    }
  }

  initState = () => this.setState({
    internalNumberOfTheScreen: 1,
    formData: {},
    showInfoModal: false,
    wasInfoAgreed: false,
    wasInfoAnswered: false,
    wasSetHelpService: false
  });

  init() {
    const { currentService: { typeInterface }, initPayType } = this.props;

    this.setState({ wasSetHelpService: false });
    initPayType(typeInterface);
  }

  getItogAmount() {
    const { pay: { willBeCreditedAmount }, fixAmountField } = this.props;
    let res = 0;

    if (!Number(willBeCreditedAmount)) {
      // Проверяем есть ли поле с фиксированной суммой
      if (typeof fixAmountField.id !== 'undefined') {
        res = fixAmountField.value;
      }
    } else {
      res = willBeCreditedAmount;
    }

    return res;
  }

  submitWindow = () => {
    const { idService } = this.props.currentService;
    const { currentService: { typeInterface } } = this.props;

    // Костыль для АлматыЭнергоСбыт юр.лица область, город и др.
    if (SERVICES_WITHOUT_ENTER_SUM.includes(idService) ||
    (Number(typeInterface) === PAY_SCHEMES.DYNAMIC1 && (this.isHasFixAmount()
    || this.isHaveMaxSummTypeInterface75()))) {
      this.confirm();
    } else {
      this.setNumberOfDynamicScreen(2);
    }
  }

  showFirstDynamicScreen = () => {
    this.init();
    this.setWasInfoAgreed(false);
    this.setNumberOfDynamicScreen(1);
    this.setState({ kaspiSelectedOption: false });
  };

  setNumberOfDynamicScreen = (screen) =>
    this.setState({ internalNumberOfTheScreen: screen });

  showSecondDynamicScreen = () => this.submitWindow();

  getFieldsForDynamicScheme = (params) => {
    const { checkInfo, selectedOptionData } = params;
    const {
      fields,
      currentService: { idService },
    } = this.props;
    let result = fields;

    if (SERVICES_WITH_KBK_KNO_IN_RECEIPT.includes(idService)
    ) {
      if (selectedOptionData) {
        const [,,,,, kno, kbk, knp] = `${checkInfo.input.option.value}`.split(';');
        const penaltiesFields = [
          { capRU: 'КБК', value: kbk },
          { capRU: 'КНО', value: kno },
          { capRU: 'КНП', value: knp }
        ];

        result = [...penaltiesFields, ...fields];
      }
    }

    return result;
  }

  confirm = () => {
    const {
      infoFields,
      currentService: { typeInterface, idService },
      dynamic2: { options },
      dynamic1: { min, max }
    } = this.props;
    let payTypeFields = [];

    const { formData } = this.state;
    const confirmFields = [];
    const checkInfo = {
      input: {
        fields: [],
      }
    };
    let selectedOptionData = '';

    switch (typeInterface) {
      case 79: {
        if (options.length) {
          selectedOptionData = this.getSelectedOptionData();
          checkInfo.input.option = confirmCaseTypeInterface79(selectedOptionData);
        }
        break;
      }
      case 86: {
        checkInfo.input.option = {
          name: ''
        };
        break;
      }
      default: break;
    }

    if (this.isBckService()) {
      formData.tax = formData.ugd;
    }

    payTypeFields = this.getFieldsForDynamicScheme({ checkInfo, selectedOptionData });

    payTypeFields.forEach(({ id, name: fieldName, capRU, value: fieldValue }) => {
      const value = fieldValue || confirmFeldsCheckInfo(formData, id, fieldName);

      if (value) {
        confirmFields.push({ name: capRU, value });
        checkInfo.input.fields.push({ name: capRU, value });
      }
    });

    if (infoFields.length) {
      checkInfo.online = {
        fields: []
      };

      infoFields.map(field => {
        const { '@attributes': { name = '' }, content: value = '' } = field;

        // АлматыЭнергоСбыт юр лица
        if (idService === 8034 && name === 'Номер договора') return;

        checkInfo.online.fields.push({
          name,
          value: confirmCheckInfoGetValue(name, value)
        });

        confirmFields.push({
          name,
          value: confirmConfirmFieldsGetValue(name, value)
        });
      });
    }

    if (min > 0) {
      confirmFields.push({
        name: 'Минимальная внесенная сумма',
        value: min
      });
    }

    if (max > 0) {
      confirmFields.push({
        name: 'Максимальная внесенная сумма',
        value: max
      });
    }

    this.props.setConfirmInfo(confirmFields, this.getAddings(), checkInfo, undefined, min, max);
    this.confirmCheckTypeInterfaceforInit(typeInterface);

    // Костыль для АлматыЭнергоСбыт юр.лица область, город и др.
    this.confirmCheckServiceId(idService);

    this.initState();
  }

  confirmCheckServiceId(idService) {
    if ((SERVICES_WITHOUT_ENTER_SUM.includes(idService) && !this.isHasFixAmount())
    || this.isHaveMaxSummTypeInterface75()) {
      this.props.showEnterAmountScreen();
    } else {
      this.props.showConfirmScreen();
    }
  }

  confirmCheckTypeInterfaceforInit(typeInterface) {
    if (typeInterface === PAY_SCHEMES.DYNAMIC1 || typeInterface === PAY_SCHEMES.DYNAMIC2) {
      this.props.initPayScheme();
    }
  }

  getAddings() {
    const { formData } = this.state;
    const {
      payTypeDynamic: {
        fields: payTypeFields,
        fixAmountField,
        min,
        max,
        minServiceValue,
        maxServiceValue,
        fix,
        dynamic2: { options, selectedOption: selectedOptionFromProps },
        dynamic3
      },
      kassa: { currentService: { typeInterface } }
    } = this.props;

    let constraint = '';
    let memo = '';
    let commission = 0.00;
    let amount0 = 0.00;
    let amount1 = 0.00;
    let amount2 = 0.00;

    payTypeFields.map(({ name, id, mask, options: fieldOptions }) => {
      const constraintRes = calculateConstraint(
        name,
        id,
        mask,
        formData,
        fieldOptions,
        fixAmountField
      );

      if (constraintRes) constraint += constraintRes;
    });

    constraint = constraint.substring(0, constraint.length - 1);

    /*  В динамической № 86 приходит параметр info. Его и дописываем */
    switch (typeInterface) {
      case 79: { // Динамические поля 2
        const { id, amount, type } = options[Number(selectedOptionFromProps)]['@attributes'];

        memo = b64EncodeUnicode(id);
        commission = (typeof amount !== 'undefined') ? Number(amount) : 0;
        amount0 = getAddingsCheckTypeInterfaceGetAmount0(type, amount);
        amount1 = getAddingsCheckTypeInterfaceGetAmount1(type, amount);
        amount2 = getAddingsCheckTypeInterfaceGetAmount2(type, amount);

        break;
      }
      case 86: { // Динамические поля 3
        const { info } = dynamic3;

        constraint += b64DecodeUnicode(info);
        break;
      }
      default: break;
    }

    const addings = {
      constraint: (!constraint.length) ? null : constraint,
      commission: 0.00,
      amount0: 0.00,
      amount1: 0.00,
      amount2: 0.00,
      memo: ''
    };

    // Эти два параметра сейчас добавляются только в 79 схеме
    if (memo !== '') {
      addings.memo = memo;
    }

    if (commission && !Number.isNaN(commission)) {
      addings.commission = commission;
    }

    // Если есть поле с фиксированное суммой к оплате, берем max и min оттуда

    if (typeof fixAmountField.id !== 'undefined') {
      const { id, sum } = fixAmountField;
      const defaultData = { value: formData.amount, sum };
      const data = (typeof id !== 'undefined') ? fixAmountField : defaultData;

      amount0 = parseFloat(data.value).toFixed(2); // fix_amount
    }
    const amount0Res = getAddingsAmount0(amount0, fix);
    const amount1Res = getAddingsAmount1(
      min,
      amount1,
      minServiceValue,
      fixAmountField,
      formData
    );
    const amount2Res = getAddingsAmount2(
      max,
      amount2,
      maxServiceValue,
      fixAmountField,
      formData);

    if (amount0Res) addings.amount0 = amount0Res;
    if (amount1Res) addings.amount1 = amount1Res;
    if (amount2Res) addings.amount2 = amount2Res;

    return addings;
  }

  paySummOnChangeHandler(e) {
    const {
      commission: { currentComProfile },
    } = this.props;

    this.props.updateWillBeCreditedAmount(
      e.target.value.replace(/[^+.\d]/g, ''),
      currentComProfile,
      !isInt(e.target.value.replace(/[^+.\d]/g, '')),
      false
    );
  }

  isHaveMaxSummTypeInterface75() {
    const {
      dynamic1: { max },
      currentService: { typeInterface }
    } = this.props;

    return (typeInterface === PAY_SCHEMES.DYNAMIC1 && Number(max) > 0);
  }

  isHaveMinSummTypeInterface75() {
    const {
      dynamic1: { min },
      currentService: { typeInterface }
    } = this.props;

    return (typeInterface === PAY_SCHEMES.DYNAMIC1 && Number(min) > 0);
  }

  getAmountField() {
    const {
      fixAmountField,
      fields,
      pay: { willBeCreditedAmount },
      dynamic1: { fix },
      dynamic3: { fix: dynamic3Fix },
      currentService: { typeInterface, idService }
    } = this.props;
    const itogAmount = this.getItogAmount();
    const itogValue = !itogAmount ? '' : itogAmount;
    const inputOptions = {
      type: 'text',
      id: 'amount',
      name: 'amount',
      value: itogValue,
      autoComplete: 'off',
      maxLength: '10',
      disabled: false
    };

    switch (typeInterface) {
      case 79: {
        inputOptions.value = willBeCreditedAmount;
        break;
      }
      case 86: {
        inputOptions.disabled = itogAmount && dynamic3Fix;
        break;
      }
      default: {
        inputOptions.disabled = !!Number(fix);
        break;
      }
    }

    if (typeof fixAmountField.value !== 'undefined') {
      inputOptions.id = 'fix';
      inputOptions.name = 'fix';
      inputOptions.defaultValue = adjustTheAmount(fixAmountField.value);
      inputOptions.className = 'form-control';
      inputOptions.disabled = true;

      return <input {...inputOptions} />;
    }

    const hasAmoutFieldAlready = fields.filter(item => (item.name === 'amount'));

    if (!hasAmoutFieldAlready.length) {
      return (
        <>
          <div className="form-group" key="paySumm">
            <input
              {...inputOptions}
              placeholder={this.isHaveMaxSummTypeInterface75() ? 'Внесенная сумма' : 'Сумма оплаты'}
              onChange={(e) =>
                this.paySummOnChangeHandler(e)
            }
            />
          </div>
          <div className="form-group" key="amount">
            <Alert message={<MinAndMaxAmount idService={idService} />} banner />
          </div>
        </>
      );
    }

    return <div />;
  }

  isHasFixAmount = () => {
    const { fixAmountField, dynamic1: { fix } } = this.props;

    if (typeof fixAmountField.value !== 'undefined') {
      return true;
    }

    return (!!Number(fix));
  }

  getInfoFieldValue = (name) => {
    const { formData } = this.state;

    return this.isBckService() && name === 'tax' ? formData.ugd : formData[name.toString()];
  }

  getInfoField = () => {
    const { fields, infoFields, pay } = this.props;
    const { formData } = this.state;
    const hasAmoutFieldAlready = fields.filter(item => (item.name === 'amount'));
    const confirmFields = [];

    fields.map(({ id, name: fieldName, capRU }) => {
      const name = (id === 0) ? 'account' : fieldName;

      if (typeof formData[name.toString()] !== 'undefined') {
        const fieldValue = this.getInfoFieldValue(name);

        confirmFields.push({ name: capRU, value: fieldValue });
      }

      return null;
    });

    if (hasAmoutFieldAlready.length) {
      confirmFields.push({ name: 'amount', value: `${pay.willBeCreditedAmount} тг` });
    }

    if (infoFields.length) {
      infoFields.map(field => {
        if (field) {
          const { '@attributes': { name = '' }, content: value = '' } = field;

          confirmFields.push({
            name,
            value: name === 'Сумма к оплате' ? parseFloat(value).toFixed(2) : value
          });

          return confirmFields;
        }
      });
    }

    return confirmFields;
  }

  handleOnChange = ({ id, value }) =>
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [id]: value.trim()
      }
    }));

  getDataForRadioGroup = () => {
    const data = [];

    this.props.dynamic2.options.map(({ '@attributes': attr }, i) => {
      data.push({ key: i, value: i, label: attr.name, id: attr.id });

      return data;
    });

    return data.length > 0 ? data : [];
  };

  setShowInfoModal = (showInfoModal) => this.setState({ showInfoModal });

  setWasInfoAgreed = (wasInfoAgreed) => {
    this.setState({ wasInfoAgreed, wasInfoAnswered: true });
    this.setShowInfoModal(false);
  }

  checkMinMaxFixAndConfirm = (e) => {
    /* (по убыванию приоритета, также и с min)
      1. optionMax
      2. accountMax (min, max, fix сохранены в корне payTypeDynamic))
      3. serviceMax (пишу на экране ввода сумм)
    */
    e.preventDefault();
    const {
      pay: { willBeCreditedAmount },
      currentService: { typeInterface },
      payTypeDynamic: { min, max },
    } = this.props;

    if (this.state.isKaspi && !this.state.kaspiSelectedOption) {
      return this.props.showError(
        'Выберите договор для оплаты',
        ''
      );
    }

    if (typeInterface === PAY_SCHEMES.DYNAMIC2) {
      if (!willBeCreditedAmount) {
        return this.props.showError(
          'Проверьте сумму и повторите попытку',
          'Сумма оплаты должна быть больше нуля'
        );
      }

      const kaspiNonExistError = getServiceNonExistError(this.props);

      if (kaspiNonExistError) {
        return this.props.showError(
          kaspiNonExistError.errMessage,
          kaspiNonExistError.errTitle
        );
      }

      const selectedOptionData = this.getSelectedOptionData();

      if (selectedOptionData.length) {
        const { type, amount } = selectedOptionData[0]['@attributes'];

        const minMaxRes = this.checkMinMaxFixAndConfirm79(type, amount, max, willBeCreditedAmount);

        if (minMaxRes && minMaxRes.err) {
          return this.props.showError(
            minMaxRes.errMess,
            minMaxRes.errMessTitle
          );
        }
      }
    }

    const amountError = this.checkAmountOnMinAndMax({ min, max, willBeCreditedAmount });

    if (amountError) {
      return this.props.showError(amountError.message, amountError.title);
    }

    this.confirm();
  };

  checkAmountOnMinAndMax = ({ min, max, willBeCreditedAmount }) => {
    if (Number(min) && Number(willBeCreditedAmount) < Number(min)) {
      return {
        message: `Введенная сумма меньше минимальной суммы по данному сервису.
         Минимальная сумма составляет ${formatMoney(min, 2)} тг`,
        title: 'Сумма оплаты меньше минимальной'
      };
    }

    if (Number(max) && Number(willBeCreditedAmount) > Number(max)) {
      return {
        message: `Введенная сумма превышает максимальную сумму по данному сервису.
         Максимальная сумма составляет ${formatMoney(max, 2)} тг`,
        title: 'Сумма оплаты больше максимальной'
      };
    }

    return null;
  };

  checkMinMaxFixAndConfirm79(type, amount, max, willBeCreditedAmount) {
    const result = { err: false, errMess: '', errMessTitle: '' };

    if (type === 'max') {
      const maxOptionAmount = Number(parseFloat(amount).toFixed(2));

      this.props.updateMaxAmountInOption(max);

      if (maxOptionAmount && willBeCreditedAmount > maxOptionAmount) {
        result.err = true;
        result.errMess = `Введенная сумма превышает максимальную сумму оплаты по договору ${formatMoney(maxOptionAmount, 2)} тг`;
        result.errMessTitle = 'Сумма оплаты больше максимальной';

        return result;
      }
    }

    if (type === 'min') {
      const minOptionAmount = Number(parseFloat(amount).toFixed(2));

      this.props.updateMinAmountInOption(minOptionAmount);

      if (minOptionAmount && willBeCreditedAmount < minOptionAmount) {
        result.err = true;
        result.errMess = `Введенная сумма меньше минимальной суммы оплаты по договору ${formatMoney(minOptionAmount, 2)} тг`;
        result.errMessTitle = 'Сумма оплаты меньше минимальной';

        return result;
      }
    }

    return result;
  }

  getSelectedOptionData = () => {
    const { options, selectedOption } = this.props.dynamic2;
    const selected = Number(selectedOption);

    return options.filter((_, key) => (key === selected));
  }

  getLastOptionCharHandler = (val) => {
    const {
      currentService: { idService },
      dynamic2: { options }
    } = this.props;

    let kaspiSelected = false;

    if (idService === KASPI_GOLD.SERVICE_ID || idService === KASPI_CREDIT.SERVICE_ID) {
      this.setState({ isKaspi: true });

      if (options.length === 2) {
        this.setState({ kaspiSelectedOption: true });
        kaspiSelected = true;
      } else if (options.length === 1) {
        const lastChar = this.getKaspiOptionLastChar(options);

        if ((lastChar === 'R' && idService === KASPI_CREDIT.SERVICE_ID)
      || (lastChar === 'W' && idService === KASPI_GOLD.SERVICE_ID)) {
          this.setState({ kaspiSelectedOption: true });
          kaspiSelected = true;
        } else if (this.state.isKaspi) { this.props.selectOption(''); }
      }
    }
    this.setState({ wasSetHelpService: true });
    this.getLastOptionChar(val, kaspiSelected);
  }

  getKaspiOptionLastChar=(options) => {
    const optionId = options ? options[0]['@attributes'].id : '';

    if (optionId) { return optionId.substring(optionId.length - 1); }

    return '';
  }

  selectOptionValue = (val) => {
    const { idService } = this.props.currentService;

    if (idService === KASPI_GOLD.SERVICE_ID || idService === KASPI_CREDIT.SERVICE_ID) {
      this.setState({ kaspiSelectedOption: true });
      this.getLastOptionChar(val, true);
    }

    this.props.selectOption(val);
  }

  getLastOptionChar = (value, kaspiSelected = false) => {
    const { currentService: { idService } } = this.props;

    if (idService === KASPI_GOLD.SERVICE_ID || idService === KASPI_CREDIT.SERVICE_ID) {
      if (this.props.dynamic2.options && kaspiSelected) {
        const findedItem = this.props.dynamic2.options.find((_, i) => i === value);

        const optionId = findedItem ? findedItem['@attributes'].id : '';
        const lastChar = optionId.substring(optionId.length - 1);

        if (lastChar === 'R') {
          this.props.setOtherIdServiceForMakePay(KASPI_CREDIT.SERVICE_ID);
        } else if (lastChar === 'W') {
          this.props.setOtherIdServiceForMakePay(KASPI_GOLD.SERVICE_ID);
        }
      }
    }
  }

  kaspiCreditWasSelected=() => {
    const {
      dynamic2: { options },
      currentService: { idService },
    } = this.props;
    const lastChar = this.getKaspiOptionLastChar(options);

    return lastChar === 'R' && idService === KASPI_GOLD.SERVICE_ID;
  }

  kaspiGoldWasSelected = () => {
    const {
      dynamic2: { options },
      currentService: { idService },
    } = this.props;
    const lastChar = this.getKaspiOptionLastChar(options);

    return lastChar === 'W' && idService === KASPI_CREDIT.SERVICE_ID;
  }

  getKaspiOptionsAlert = () => {
    const {
      dynamic2: { options },
      currentService: { idService },
    } = this.props;

    if (options.length === 1 && this.state.isKaspi &&
      (this.kaspiCreditWasSelected() || this.kaspiGoldWasSelected())
    ) {
      if (idService === KASPI_GOLD.SERVICE_ID) {
        return <><Alert message={KASPI_GOLD.ALERT_INFO} banner /><br/></>;
      }
      if (idService === KASPI_CREDIT.SERVICE_ID) {
        return <><Alert message={KASPI_CREDIT.ALERT_INFO} banner /><br/></>;
      }
    }

    return null;
  }

  getPayButtonText = () => {
    const {
      dynamic2: { options },

    } = this.props;

    if (options.length === 1 && this.state.isKaspi) {
      if (this.kaspiGoldWasSelected()) return t('pay.kaspiGold');
      if (this.kaspiCreditWasSelected()) return t('pay.kaspiCredit');

      return t('pay');
    }

    return t('pay');
  }

 getTitleForSelectAnItemFromTheList=() => {
   const { dynamic2: { options } } = this.props;

   if (options.length > 1) {
     return <h4>{t('selectAnItemFromTheList')}</h4>;
   }

   // eslint-disable-next-line react/jsx-no-useless-fragment
   return <></>;
 }

 checkShowingMinSummContent = (min) => {
   if (this.isHaveMinSummTypeInterface75()) {
     return (
       <p key="maxSumm">
         <b>Минимальная сумма: </b>{min}
       </p>
     );
   }

   // eslint-disable-next-line react/jsx-no-useless-fragment
   return <></>;
 }

checkShowingMaxSummContent = (max) => {
  if (this.isHaveMaxSummTypeInterface75()) {
    return (
      <p key="maxSumm">
        <b>Максимальная сумма: </b>{max}
      </p>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}

render() {
  const { internalNumberOfTheScreen, showInfoModal } = this.state;
  const {
    fields,
    lang,
    dynamic1: { min, max },
    dynamic2: { selectedOption, editedCounts, fields: dynamic2Fields },
    currentService: { typeInterface, idService },
    infoMessage
  } = this.props;

  if (internalNumberOfTheScreen === 2) {
    if (typeInterface === PAY_SCHEMES.DYNAMIC2) {
      // Если в динамической 79 пришли options, рендерим их
      const selected = this.state.isKaspi && !this.state.kaspiSelectedOption ? '' : Number(selectedOption);
      const selectedOptionData = this.getSelectedOptionData();
      const isCountInputDisabled = getIsCountInputDisabled(selectedOptionData);
      const defaultValue = getDefaultValueInRender(editedCounts, selected);

      return (
        <div className="pay-form">
          <button type="button" onClick={() => this.showFirstDynamicScreen()} className="back-text buttonWithoutStyles">
            {t('editData')}
          </button>
          <div>
            <form onSubmit={this.checkMinMaxFixAndConfirm} id="secondScreenForm">
              <div>
                {dynamic2Fields.map((item) => getDynamicFieldsItem(item))}
              </div>
              {this.getTitleForSelectAnItemFromTheList()}

              <div className="list">
                {this.getKaspiOptionsAlert()}
                <div style={{ marginTop: '10px' }}>
                  <RadioGroup
                    onChange={(val) => this.selectOptionValue(val)}
                    selected={selected}
                    data={this.getDataForRadioGroup()}
                    selectedIsKey
                  />
                </div>
                <div style={{ paddingTop: '10px' }}>
                  <span>Количество: </span>
                  <input
                    disabled={isCountInputDisabled}
                    defaultValue={Number(defaultValue)}
                    onChange={(e) => this.changeCountAndUpdateAmount(selected, e)}
                  />
                </div>
                {this.getAmountField()}
                <button
                  type="submit"
                  className="btn btn-primary"
                >
                  {this.getPayButtonText()}
                </button>
              </div>
            </form>
          </div>
        </div>
      );
    }

    const infoFields = this.getInfoField();

    // этот код сработает только в 86 схеме, в 75 я кидаю на confirm сразу из submitWindow
    return (
      <div className="pay-form">
        <button type="button" onClick={() => this.showFirstDynamicScreen()} className="back-text buttonWithoutStyles">
          {t('editData')}
        </button>
        <div>
          <form onSubmit={this.checkMinMaxFixAndConfirm} id="secondScreenForm">
            {this.checkShowingMinSummContent(min)}
            {this.checkShowingMaxSummContent(max)}

            {infoFields.map(({ name = '', value = '' }) => {
              if (value || ALL_DISPLAY_INFO_FIELDS.includes(name)) {
                return (
                  <p key={name}>
                    <b>{name}: </b>{name === 'Сумма к оплате' ? parseFloat(value).toFixed(2) : value}
                  </p>
                );
              }

              return null;
            })}
            {this.getAmountField()}
            <button className="btn btn-primary" type="submit">
              {t('pay')}
            </button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <FirstDynamicScreen
      lang={lang}
      fields={fields}
      handleOnChange={this.handleOnChange}
      validateFormAndOnlineCheck={this.validateFormAndOnlineCheck}
      formData={this.state.formData}
      idService={idService}
      setWasInfoAgreed={this.setWasInfoAgreed}
      showInfoModal={showInfoModal}
      infoMessage={infoMessage}
      isBckService={this.isBckService}
      isLoading={this.state.isLoading}
    />
  );
}
}

Dynamic.propTypes = {
  lang: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  infoMessage: PropTypes.string.isRequired,
  additionalDetails: PropTypes.object.isRequired,
  currentService: PropTypes.object.isRequired,
  pay: PropTypes.object.isRequired,
  services: PropTypes.object.isRequired,
  kassa: PropTypes.object.isRequired,
  setConfirmInfo: PropTypes.func.isRequired,
  getAdditionalDetails: PropTypes.func.isRequired,
  selectOption: PropTypes.func.isRequired,
  initPayType: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  updateAmountValue: PropTypes.func.isRequired,
  changeOptionCount: PropTypes.func.isRequired,
  showEnterAmountScreen: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  infoFields: PropTypes.array.isRequired,
  serviceList: PropTypes.array.isRequired,
  dynamic1: PropTypes.object.isRequired,
  dynamic2: PropTypes.object.isRequired,
  dynamic3: PropTypes.object.isRequired,
  fixAmountField: PropTypes.object.isRequired,
  setOtherIdServiceForMakePay: PropTypes.func.isRequired,
  otherIdServiceForMakePay: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return ({
    lang: state.kassa.lang,
    serviceList: state.services.serviceList,
    currentService: state.kassa.currentService,
    otherIdServiceForMakePay: state.kassa.otherIdServiceForMakePay,
    additionalDetails: state.services.additionalDetails,
    pay: state.pay,
    fields: state.payTypeDynamic.fields,
    infoFields: state.payTypeDynamic.infoFields,
    dynamic1: {
      min: state.payTypeDynamic.min,
      max: state.payTypeDynamic.max,
      fix: state.payTypeDynamic.fix,
    },
    dynamic2: state.payTypeDynamic.dynamic2,
    dynamic3: state.payTypeDynamic.dynamic3,
    fixAmountField: state.payTypeDynamic.fixAmountField,
    id: state.payTypeDynamic.id.toString(),
    infoMessage: state.payTypeDynamic.infoMessage,
  });
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...dynamic,
    getAdditionalDetails,
    setOtherIdServiceForMakePay,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Dynamic);
