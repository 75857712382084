import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-maskedinput';

const SEARCH_BY_ORDER_NUM = 1;
const SEARCH_BY_PHONE = 2;

const MypostFormData = ({ account, setAccount, isAccountValid, searchType }) => (
  <div className={!isAccountValid ? 'input-error-block' : ''}>
    <label htmlFor="account">{searchType === SEARCH_BY_ORDER_NUM ? 'Номер заказа' : 'Номер телефона' }</label>
    <br />
    <MaskedInput
      mask={searchType === SEARCH_BY_ORDER_NUM ? 'W'.repeat(20) : '+7 (111) 111-11-11'}
      type="tel"
      id="account"
      autoComplete="off"
      maxLength={20}
      placeholder={searchType === SEARCH_BY_ORDER_NUM ? 'Введите номер заказа' : '+7 (777) 123 45 67'}
      placeholderChar=" "
      className={!isAccountValid ? 'input-warning' : ''}
      value={account.toString()}
      onChange={(e) => setAccount(e.target.value.trim())}
      formatCharacters={{
        W: {
          validate(char) { return /[\wа-яА-Я-]/.test(char); },
        }
      }}
    />
    <span className="inputError">
      {(!isAccountValid && searchType === SEARCH_BY_ORDER_NUM) && 'Поле обязательно для заполнения'}
      {(!isAccountValid && searchType === SEARCH_BY_PHONE) && 'Номер телефона должен состоять из 10 символов'}
    </span>
  </div>
);

MypostFormData.propTypes = {
  account: PropTypes.string.isRequired,
  setAccount: PropTypes.func.isRequired,
  isAccountValid: PropTypes.bool.isRequired,
  searchType: PropTypes.number.isRequired,
};

export default MypostFormData;

