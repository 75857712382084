/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import MaskedInput from 'react-maskedinput';

import Confirm from 'containers/Confirm';
import { getServiceLogo } from 'helpers/pay';
import Modal from 'components/UI/Modal/modal';
import EnterAmount from 'containers/EnterAmount';
import ServiceBlock from 'components/ServiceBlock';
import { onlineCheck } from 'actions/api/onlineCheck';
import { KASPI_GOLD, KASPI_CREDIT, KASPI_LOGO, } from 'constants/services';
import { setObjectProperty, t, generateTransactionNumber, b64EncodeUnicode, checkUnicodeSymbols } from 'helpers/main';
import {
  confirmCaseTypeInterface79,
  calculateConstraint,
  getAddingsCheckTypeInterfaceGetAmount0,
  getAddingsCheckTypeInterfaceGetAmount1,
  getAddingsCheckTypeInterfaceGetAmount2,
  getAddingsAmount0,
  getAddingsAmount1,
  getAddingsAmount2
} from 'helpers/dynamic';
import {
  setOtherIdServiceForMakePay,
  initPayType,
  updateFieldValue,
  setTransactionNumber,
  setConfirmInfo,
  setSelectedKaspiInModal,
  setClientIIN
} from 'actions/pay';
import logoKassa from 'public/images/kassa24/kassa24.svg';

import './style.scss';

const FIELDS = [
  {
    name: 'iin',
    label: 'Введите ИИН',
    type: 'text',
    required: true,
  },
  {
    name: 'repeatIin',
    label: 'Повторите ИИН',
    type: 'text',
    required: true,
  },
];
const title = 'Kaspi Bank:';
const MODAL_TITLE = { 1: 'Введите ИИН', 2: 'Выберите сервис', 3: 'Оплата', 4: 'Подтверждение оплаты' };
const KASPI_TYPE_INTERFACE = 79;

const Kaspi = (props) => {
  const [fieldsError, setFieldsError] = useState({});
  const [fieldsValue, setFieldsValue] = useState({});
  const [screen, setScreen] = useState(1);
  const [services, setServices] = useState([]);
  const [wasRequested, setWasRequested] = useState(false);
  const [showSecondScreen, setShowSecondScreen] = useState(false);
  const [idService, setIdService] = useState(0);
  const [modalTitle, setModalTitle] = useState('');
  const [optionSelected, setOptionSelected] = useState(0);
  const [currentService, setCurrentService] = useState({});
  const [confirmFields, setConfirmFields] = useState([]);

  useEffect(() => {
    setIdService(props.activeServiceId);
    const sn = generateTransactionNumber();

    props.setConfirmInfo([], '', {});
    props.setSelectedKaspiInModal(true);
    props.setTransactionNumber(sn);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (showSecondScreen && props.dynamic2.options.length > 0 && !wasRequested) {
      getDataForRadioGroup();
      setWasRequested(true);
      setScreen(2);
    }
    // eslint-disable-next-line
  }, [showSecondScreen, props.dynamic2.options]);

  useEffect(() => {
    if (screen === 1) {
      setFieldsValue({});
    }
    setModalTitle(`${title} ${MODAL_TITLE[Number(screen)]}`);
  }, [screen]);

  const handleChange = (name, value) => {
    const errors = {
      ...fieldsError,
      [name]: ''
    };

    setFieldsError(errors);
    setFieldsValue({
      ...fieldsValue,
      [name]: value
    });
    props.setClientIIN(value);
    props.updateFieldValue('account', value);
  };

  const getFieldErrText = (value) => {
    if (!value) {
      return 'Поле обязательно для заполнения';
    }

    if (value.length !== 12) {
      return 'ИИН должен состоять из 12 цифр';
    }

    return '';
  };

  const isFieldValid = (name, required, value) => {
    let isValid = true;
    const errors = { ...fieldsError };

    if (required) {
      const errText = getFieldErrText(value);

      if (errText) {
        isValid = false;
        setObjectProperty(errors, name, errText);
      }

      if (fieldsValue.iin !== fieldsValue.repeatIin) {
        isValid = false;
        setObjectProperty(errors, 'iin', 'ИИН не совпадают');
      }
    }

    setFieldsError(errors);

    return isValid;
  };

  const isFormValid = () => {
    let isValid = true;

    FIELDS.forEach(({ name, required }) => {
      if (!isFieldValid(name, required, fieldsValue[name.toString()])) {
        isValid = false;
      }
    });

    return isValid;
  };

  const validateAndOnlineCheck = () => {
    if (isFormValid()) {
      const serviseSetting = {
        maskEdit: '',
        typeInterface: KASPI_GOLD.SERVICE_INFO.scheme,
        checkOnline: 2,
        idService: KASPI_GOLD.SERVICE_ID
      };

      props.onlineCheck(serviseSetting, fieldsValue.iin);
      setShowSecondScreen(true);
      setWasRequested(false);
    }
  };

  const getDataForRadioGroup = () => {
    const data = [];

    props.dynamic2.options.forEach(({ '@attributes': attr }, i) => {
      const serviceExist = getServiceExist(attr.id);
      const kaspiService = getServiceFromLastOptionChar(attr.id);

      if (serviceExist) {
        const logo = KASPI_LOGO[String(kaspiService.SERVICE_ID)];

        data.push({ key: i, value: i, name: attr.name, id: kaspiService.SERVICE_ID, logo });
      }
    });

    setServices(data);
    getOptionService(0);
  };

  const getServiceExist = (serviceId) => {
    const kaspiService = getServiceFromLastOptionChar(serviceId);

    const isKaspiCreditExistOnTerminal = getServiceOnId(kaspiService.SERVICE_ID);

    return !!isKaspiCreditExistOnTerminal;
  };

  const selectService = (service) => {
    getOptionService(service.key);
    setOptionSelected(service.key);
  };

  const getServiceOnId = (serviceId) =>
    props.serviceList.find(item => (item.idService === serviceId));

  const getOptionService = (value) => {
    if (idService === KASPI_GOLD.SERVICE_ID || idService === KASPI_CREDIT.SERVICE_ID) {
      if (props.dynamic2.options) {
        const findedItem = props.dynamic2.options.find((_, i) => i === value);

        const optionId = findedItem ? findedItem['@attributes'].id : '';
        const serviceKaspi = getServiceFromLastOptionChar(optionId);

        props.setOtherIdServiceForMakePay(serviceKaspi.SERVICE_ID);
        setCurrentService({
          idService: serviceKaspi.SERVICE_ID,
          name: serviceKaspi.SERVICE_INFO.name,
          maskEdit: serviceKaspi.SERVICE_INFO.mask,
          typeInterface: serviceKaspi.SERVICE_INFO.scheme,
          isEMoney: serviceKaspi.SERVICE_INFO.isEMoney,
          logo: serviceKaspi.SERVICE_LOGO
        });
      }
    }
  };

  const getServiceFromLastOptionChar = (optionId) => {
    const lastChar = optionId.substring(optionId.length - 1);

    if (lastChar === 'R') {
      return KASPI_CREDIT;
    }
    if (lastChar === 'W') {
      return KASPI_GOLD;
    }
  };

  const getScreenNextAction = () => {
    setShowSecondScreen(false);
    if (screen === 1) return validateAndOnlineCheck();
    if (screen === 2) {
      setScreen(screen + 1);

      const fields = [];

      fields.push({ name: 'ФИО', value: props.dynamic2.fields[0].content });
      setConfirmFields(fields);
      props.setConfirmInfo(fields, getAddings(), {});
    }
  };

  const getScreenPrevAction = () => {
    const screenNumber = screen === 1 ? screen : screen - 1;

    setShowSecondScreen(false);
    if (screen === 1) {
      return closeKaspiPopup();
    }
    setScreen(screenNumber);
  };

  const getSelectedOptionData = () => {
    const { options, selectedOption } = props.dynamic2;
    const selected = Number(selectedOption);

    return options.filter((_, key) => (key === selected));
  };

  const getAddings = () => {
    const formData = fieldsValue;
    const {
      payTypeDynamic: {
        fields: payTypeFields,
        fixAmountField,
        min,
        max,
        minServiceValue,
        maxServiceValue,
        fix,
        dynamic2: { options },

      },

    } = props;

    let constraint = '';
    let memo = '';
    let commission = 0.00;
    let amount0 = 0.00;
    let amount1 = 0.00;
    let amount2 = 0.00;

    payTypeFields.forEach((item) => {
      const constraintRes = calculateConstraint(
        item.name,
        item.id,
        item.mask,
        formData,
        item.options,
        fixAmountField
      );

      if (constraintRes) constraint += constraintRes;
    });

    constraint = constraint.substring(0, constraint.length - 1);
    const { id, amount, type } = options[Number(optionSelected)]['@attributes'];

    memo = b64EncodeUnicode(id);
    commission = (typeof amount !== 'undefined') ? Number(amount) : 0;
    amount0 = getAddingsCheckTypeInterfaceGetAmount0(type, amount);
    amount1 = getAddingsCheckTypeInterfaceGetAmount1(type, amount);
    amount2 = getAddingsCheckTypeInterfaceGetAmount2(type, amount);

    const addings = {
      constraint: (!constraint.length) ? null : constraint,
      commission: 0.00,
      amount0: 0.00,
      amount1: 0.00,
      amount2: 0.00,
      memo: ''
    };

    // Эти два параметра сейчас добавляются только в 79 схеме
    if (memo !== '') {
      addings.memo = memo;
    }

    if (commission && !Number.isNaN(commission)) {
      addings.commission = commission;
    }

    // Если есть поле с фиксированное суммой к оплате, берем max и min оттуда
    if (typeof fixAmountField.id !== 'undefined') {
      const defaultData = { value: formData.amount, sum: fixAmountField.sum };
      const data = (typeof fixAmountField.id !== 'undefined') ? fixAmountField : defaultData;

      amount0 = parseFloat(data.value).toFixed(2); // fix_amount
    }
    const amount0Res = getAddingsAmount0(amount0, fix);
    const amount1Res = getAddingsAmount1(
      min,
      amount1,
      minServiceValue,
      fixAmountField,
      formData
    );
    const amount2Res = getAddingsAmount2(
      max,
      amount2,
      maxServiceValue,
      fixAmountField,
      formData);

    if (amount0Res) addings.amount0 = amount0Res;
    if (amount1Res) addings.amount1 = amount1Res;
    if (amount2Res) addings.amount2 = amount2Res;

    return addings;
  };

  const enterAmountFunc = () => {
    setScreen(4);
    const { dynamic2: { options } } = props;
    const formData = fieldsValue;
    const fields = confirmFields;
    const checkInfo = {
      input: {
        fields: [],
      }
    };

    if (options.length) {
      const selectedOptionData = getSelectedOptionData();

      checkInfo.input.option = confirmCaseTypeInterface79(selectedOptionData);
    }

    fields.push({ name: 'ИИН', value: formData.iin });
    checkInfo.input.fields.push({ name: 'ИИН', value: formData.iin });

    props.setConfirmInfo(fields, getAddings(), checkInfo);
  };

  const closeKaspiPopup = () => {
    props.closeKaspiModal();
    props.initPayType(KASPI_TYPE_INTERFACE);
    props.updateFieldValue('account', '');
    props.setSelectedKaspiInModal(false);
  };

  const getDefaultImage = (e) => {
    e.target.src = logoKassa;
  };

  return (
    <Modal
      size="payment"
      title={modalTitle}
      isOpen
      isFooterHidden
      className="groups-modal"
      onClose={closeKaspiPopup}
    >
      {screen > 1 && (
        <button type="button" onClick={() => getScreenPrevAction()} className="back-text buttonWithoutStyles">
          {t('back')}
        </button>
      )}
      <div className="form-data">
        {screen > 2 && (
          <div className="pay-title">
            <img
              src={getServiceLogo(currentService.logo) || getDefaultImage}
              alt={currentService.name}
              className="pay-logo"
            />
            <h3 className="pay-service-name">{checkUnicodeSymbols(currentService.name)}</h3>
          </div>
        )}

        {screen === 1 && (
          FIELDS.map(({ name, label, type }) => (
            <div className="inputWrapper" key={name}>
              <label>{label}</label>
              <MaskedInput
                mask="111111111111"
                type={type}
                id={name}
                autoComplete="off"
                maxLength={12}
                placeholder="Введите ИИН"
                placeholderChar=" "
                className={fieldsError[name.toString()] ? 'pay-form-input input-warning' : 'pay-form-input'}
                value={fieldsValue[name.toString()]}
                onChange={(e) => handleChange(name, type === 'text' ? e.target.value.trim() : e.target.value)}
              />

            </div>
          ))
        )}

        {screen === 2 && services.length > 0 && (
          <>
            {services.map((service) => (
              <ServiceBlock
                name={service.name}
                logo={getServiceLogo(service.logo)}
                key={service.id}
                selectService={() => selectService(service)}
                active={props.otherIdServiceForMakePay === service.id}
              />
            ))}
          </>
        )}

        {screen === 3 && <EnterAmount isItKaspiSchemeInModal callbackFunc={enterAmountFunc}/>}

        {screen === 4 && (
          <Confirm
            isItKaspiSchemeInModal
            currentServiceKaspi={currentService}
            initKaspiSchemeInModal={closeKaspiPopup}
          />
        )}

        {(screen === 1 || screen === 2) && (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => getScreenNextAction()}
          >
            Далее
          </button>
        )}
      </div>
    </Modal>
  );
};

Kaspi.propTypes = {
  onlineCheck: PropTypes.func.isRequired,
  closeKaspiModal: PropTypes.func.isRequired,
  dynamic2: PropTypes.object.isRequired,
  setOtherIdServiceForMakePay: PropTypes.func.isRequired,
  serviceList: PropTypes.array.isRequired,
  otherIdServiceForMakePay: PropTypes.number.isRequired,
  activeServiceId: PropTypes.number.isRequired,
  initPayType: PropTypes.func.isRequired,
  updateFieldValue: PropTypes.func.isRequired,
  setTransactionNumber: PropTypes.func.isRequired,
  payTypeDynamic: PropTypes.object.isRequired,
  setConfirmInfo: PropTypes.func.isRequired,
  setSelectedKaspiInModal: PropTypes.func.isRequired,
  setClientIIN: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  otherIdServiceForMakePay: state.kassa.otherIdServiceForMakePay,
  dynamic2: state.payTypeDynamic.dynamic2,
  serviceList: state.services.serviceList,
  payTypeDynamic: state.payTypeDynamic
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onlineCheck,
      setOtherIdServiceForMakePay,
      initPayType,
      updateFieldValue,
      generateTransactionNumber,
      setTransactionNumber,
      setConfirmInfo,
      setSelectedKaspiInModal,
      setClientIIN
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Kaspi);
