import * as types from 'actions/initialDownload/types';

export const INITIAL_LOADED_TYPES = {
  32: types.PAYMENT_HISTORY_LOADED,
  8: types.BALANCE_LOADED,
  auth: types.FISCAL_AUTH_DATA_LOADED,
  kkm: types.FISCAL_KKM_INFO_LOADED
};

export const REQUEST_API_TYPES = [
  'SET_PAYMENTS_HISTORY_XML_REQUEST',
  'SET_BALANCES_XML_REQUEST',
  'FISCAL_AUTH_EMPTY_REQUEST',
  'FISCAL_GET_KKM_INFO_EMPTY_REQUEST'
];
