import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import chunk from 'lodash/chunk';

import Modal from 'components/UI/Modal/modal';
import { setCurrentService, setFavourites, showAccordion, checkAllNeededData } from 'actions/services';
import { setShowCashOutModal } from 'actions/payTypes/cashout';
import { setShowMyPostPaymentModal, setShowMyPostDeliveryModal } from 'actions/payTypes/mypost';
import { initCurrentComProfile } from 'actions/commission';
import { initPayType, updateSelectedFieldValue, setClientIIN } from 'actions/pay';
import { showError } from 'actions/alert';
import { setIsAuthInFiscal, setAuthDataIsRequested } from 'actions/fiscal';
import { goToEndSessionPage } from 'actions/navigate';
import ServiceBlock from 'components/ServiceBlock';
import GroupBlock from 'components/GroupBlock';
import GroupServices from 'components/GroupServices';
import {
  SERVICE_TYPES,
  KASPI_GROUP_ID,
  CASHOUT_SERVICES_TAB_ID,
  MYPOST_SERVICES_TAB_ID,
} from 'constants/services';
import { getFavourites } from 'model/Favourites';
import Accordion from 'components/UI/Accordion/accordion';
import { Tabs, Tab } from '@material-ui/core';
import Pagination from 'rc-pagination';
import { getServiceLogo } from 'helpers/pay';
import { canDoCashOut } from 'helpers/cert';
import KaspiModal from 'containers/Kaspi';
import 'rc-pagination/assets/index.css';
import { CASH_OUT_PAY_TYPE, MYPOST_PAY_TYPE_PAYMENT, MYPOST_PAY_TYPE_DELIVERY } from 'constants/payTypes';

const MAX_SERVICES_ON_PAGE = 12;
const FAVOURITE_SERVICES_TAB_ID = 1000;

class ServiceListBlock extends Component {
  state = {
    currentPage: 1,
    currentTypeService: FAVOURITE_SERVICES_TAB_ID,
    activeServiceId: 0,
    activeGroupId: 0,
    visibleServiceGroupModal: false,
    servicesForTypes: {},
    countPagesForTypes: {},
    favouriteServices: [],
    typeId: 1,
    modalType: null,
  };

  componentDidMount() {
    const favourites = getFavourites();

    this.props.setFavourites(favourites);
    this.setFavoritesWithInfoAboutTheService();
    this.setServices();
  }

  componentDidUpdate(prevProps) {
    const { servicesByTypes, favourites, serviceList, currentService } = this.props;

    if (prevProps.servicesByTypes.length !== servicesByTypes.length && servicesByTypes.length) {
      this.setServices();
    }

    if (prevProps.serviceList.length !== serviceList.length && favourites.length) {
      this.setFavoritesWithInfoAboutTheService();
    }

    if (prevProps.favourites.length !== favourites.length) {
      this.setFavoritesWithInfoAboutTheService();
    }

    if (
      prevProps.currentService.idService !== currentService.idService &&
      !currentService.idService
    ) {
      this.setActiveServiceId(0);
    }
  }

  setActiveServiceId = (activeServiceId) => this.setState({ activeServiceId });

  setServices = () => {
    const { servicesByTypes } = this.props;
    const servicesForTypes = {};
    const countPagesForTypes = {};

    if (servicesByTypes.length) {
      SERVICE_TYPES.map(({ id: typeId }) => {
        const groupsForType = this.getGroupsForType(typeId);
        const servicesForType = this.getServicesForType(typeId);

        if (typeof servicesForTypes[Number(typeId)] === 'undefined') {
          servicesForTypes[Number(typeId)] = [];
        }

        const totalListService = servicesForTypes[Number(typeId)];

        servicesForTypes[Number(typeId)] = totalListService
          .concat(groupsForType)
          .concat(servicesForType);

        servicesForTypes[Number(typeId)] = chunk(
          servicesForTypes[Number(typeId)],
          MAX_SERVICES_ON_PAGE
        );

        if (typeof countPagesForTypes[Number(typeId)] === 'undefined') {
          countPagesForTypes[Number(typeId)] = servicesForTypes[Number(typeId)].length;
        }

        return servicesForTypes;
      });

      const cashOutServices = this.getServicesForType(CASHOUT_SERVICES_TAB_ID);
      const myPostServices = this.getServicesForType(MYPOST_SERVICES_TAB_ID);

      servicesForTypes[Number(CASHOUT_SERVICES_TAB_ID)] = [];
      servicesForTypes[Number(CASHOUT_SERVICES_TAB_ID)][0] = cashOutServices;

      servicesForTypes[Number(MYPOST_SERVICES_TAB_ID)] = [];
      servicesForTypes[Number(MYPOST_SERVICES_TAB_ID)][0] = myPostServices;

      this.setState({ countPagesForTypes, servicesForTypes });
    }
  };

  setFavoritesWithInfoAboutTheService = () => {
    const { favourites } = this.props;
    const favouriteServices = [];

    if (favourites.length) {
      favourites.map(favouriteId => {
        const service = this.getServiceOnId(favouriteId);

        if (typeof service.idService !== 'undefined') {
          favouriteServices.push(service);
        }

        return service;
      });

      this.setState({ favouriteServices });
    } else {
      this.setState({ favouriteServices: [] });
    }
  };

  getServiceOnId = (idService) => {
    const service = this.props.serviceList.filter(item => (item.idService === idService));

    if (service.length) return service[0];

    return {};
  };

  selectService = (service) => {
    const hasAllData = this.props.checkAllNeededData();

    if (hasAllData) {
      // если выбирают кэшаут, проверяем разрешено ли его выбрать (есть ли кука из серта)
      if (service.typeInterface === CASH_OUT_PAY_TYPE && canDoCashOut()) {
        this.props.setShowCashOutModal(true);
      }

      if (service.typeInterface === CASH_OUT_PAY_TYPE && !canDoCashOut()) {
        this.props.showError('У вас нет прав для осуществления выдачи средств. Обратитесь к курирующему менеджеру для подключения данной услуги');
      }

      if (service.typeInterface === MYPOST_PAY_TYPE_PAYMENT) {
        this.props.setShowMyPostPaymentModal(true);
      }

      if (service.typeInterface === MYPOST_PAY_TYPE_DELIVERY) {
        this.props.setShowMyPostDeliveryModal(true);
      }

      this.setState({ visibleServiceGroupModal: false });

      this.props.initPayType(service.typeInterface);
      this.props.initCurrentComProfile();
      this.props.setClientIIN('');
      this.props.setCurrentService(service);
      this.setActiveServiceId(service.idService);
    }
  };

  selectGroup = (groupId, service = null) => {
    if (groupId === KASPI_GROUP_ID) {
      this.props.setCurrentService({});
      this.props.showAccordion(true);
      this.setActiveServiceId(service[0].idService);
      this.props.updateSelectedFieldValue('depositedAmount', '');
      this.props.updateSelectedFieldValue('willBeCreditedAmount', '');
      this.props.initCurrentComProfile();
      this.props.setClientIIN('');
      const hasAllData = this.props.checkAllNeededData();

      if (!hasAllData) return;
    }

    this.setState({
      activeGroupId: groupId,
      modalType: groupId === KASPI_GROUP_ID ? 'kaspi' : null
    });

    this.showServiceGroupModal();
  };

  showServiceGroupModal = () => {
    this.setState({ visibleServiceGroupModal: true });
  }

  closeServiceGroupModal = () => {
    this.setState({ visibleServiceGroupModal: false, modalType: null, activeServiceId: 0 });
  }

  getGroupsForType(typeService) {
    const { activeServiceId } = this.state;
    const { servicesByTypes } = this.props;

    if (typeof servicesByTypes[Number(typeService)] !== 'undefined') {
      const groupsByType = servicesByTypes[Number(typeService)].groups;
      const groups = [];
      let key = 1;

      if (typeof groupsByType !== 'undefined' && groupsByType.length) {
        groupsByType.map((service, groupId) => {
          groups[Number(key)] = (
            <span className="service-block-wrapper" key={`${service[0].idService}-${groupId}`}>
              <GroupBlock
                grbut={service[0].grbut}
                grname={service[0].grname}
                key={`${service[0].idService}-${groupId}`}
                selectGroup={() => this.selectGroup(groupId, service)}
                active={activeServiceId === service.idService}
                {...this.props}
              />
            </span>
          );

          key++;

          return groups;
        });
      }

      return groups.filter(item => (typeof item !== 'undefined'));
    }

    return [];
  }

  getServicesForType(typeService) {
    const { servicesByTypes } = this.props;
    const { activeServiceId } = this.state;

    // если тип сервиса есть, но сервисов для этого типа нет
    if (typeof servicesByTypes[Number(typeService)] !== 'undefined') {
      const servicesByType = servicesByTypes[Number(typeService)].services;
      const services = [];
      let key = 1;

      if (typeof servicesByType !== 'undefined' && servicesByType.length) {
        const sortedByRank = [].concat(servicesByType).sort((a, b) => a.rank - b.rank);

        sortedByRank.forEach(service => {
          services[Number(key)] = (
            <span className="service-block-wrapper" key={service.idService}>

              <ServiceBlock
                key={service.idService}
                name={String(service.name)}
                logo={getServiceLogo(service.logo)}
                selectService={() => this.selectService(service)}
                active={activeServiceId === service.idService}
                typeInterface={service.typeInterface}
              />
            </span>
          );

          key++;
        });
      }

      return services.filter(item => (typeof item !== 'undefined'));
    }

    return [];
  }

  changePage = (currentPage) => {
    this.setState({ currentPage });
  };

  changeTypeService = (_, newVal) => {
    this.setState({ currentTypeService: newVal, currentPage: 1 });
  };

  getServicesContent=(favouriteServices, activeServiceId) => {
    if (favouriteServices.length) {
      return favouriteServices.map((service) => (
        <span key={service.idService} className="service-block-wrapper">
          <ServiceBlock
            key={service.idService}
            name={service.name}
            logo={getServiceLogo(service.logo)}
            selectService={() => this.selectService(service)}
            active={activeServiceId === service.idService}
            typeInterface={service.typeInterface}
          />
        </span>
      ));
    }

    return (
      <p className="favourites-info-text">
        На данный момент список Избранных сервисов пуст
      </p>
    );
  }

  render() {
    const { servicesByTypes } = this.props;
    const {
      modalType,
      currentPage,
      currentTypeService,
      activeGroupId,
      visibleServiceGroupModal,
      activeServiceId,
      countPagesForTypes,
      servicesForTypes,
      favouriteServices,
      typeId
    } = this.state;

    const pagesCount = countPagesForTypes[Number(typeId)];

    return (
      <Accordion>
        <div className="service-list-block">
          {visibleServiceGroupModal && modalType === null && (
            <Modal
              title={servicesByTypes[Number(currentTypeService)]
                .groups[Number(activeGroupId)][0]
                .grname
              }
              isOpen={visibleServiceGroupModal}
              onOk={this.closeServiceGroupModal}
              onClose={this.closeServiceGroupModal}
              className="groups-modal"
              isFooterHidden
            >
              <GroupServices
                serviceByGroups={
                  servicesByTypes[Number(currentTypeService)].groups[Number(activeGroupId)]
                }
                selectService={(service) => this.selectService(service)}
                activeServiceId={activeServiceId}
              />
            </Modal>
          )}
          {visibleServiceGroupModal && modalType === 'kaspi' && (
            <KaspiModal
              closeKaspiModal={this.closeServiceGroupModal}
              activeServiceId={activeServiceId}
            />
          )}
          <Tabs
            value={this.state.currentTypeService}
            onChange={(val, newVal) => this.changeTypeService(val, newVal)}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Избранное" value={FAVOURITE_SERVICES_TAB_ID} />
            {(this.props.hasCashOut && canDoCashOut()) && (
              <Tab
                value={CASHOUT_SERVICES_TAB_ID}
                onClick={() => this.setState({ typeId: CASHOUT_SERVICES_TAB_ID })}
                label={(
                  <div className="cashout-tab">
                    <div>Выдача наличных</div>
                    <div className="new">Новое!</div>
                  </div>
                )}
              />
            )}

            {(this.props.hasMyPost) && (
              <Tab
                value={MYPOST_SERVICES_TAB_ID}
                onClick={() => this.setState({ typeId: MYPOST_SERVICES_TAB_ID })}
                label={(
                  <div className="cashout-tab">
                    <div>MyPost</div>
                    <div className="new">Новое!</div>
                  </div>
                )}
              />
            )}

            {SERVICE_TYPES.map(({ id: type, title }) => {
              const services = servicesForTypes[Number(type)];

              if (typeof services !== 'undefined' && services.length) {
                return (
                  <Tab
                    value={type}
                    label={title}
                    key={type}
                    onClick={() => this.setState({ typeId: type })}
                  />
                );
              }

              return null;
            })}
          </Tabs>

          {currentTypeService === typeId && (
            <div className="service-list-items">
              {this.props.hasCashOut && CASHOUT_SERVICES_TAB_ID === typeId ? (
                <div className="cashout-tab-content">
                  {servicesForTypes[this.state.typeId][currentPage - 1]}
                </div>
              ) : servicesForTypes[this.state.typeId][currentPage - 1]}
            </div>
          )}

          {currentTypeService === FAVOURITE_SERVICES_TAB_ID ? (
            <div className="service-list-items">
              {this.getServicesContent(favouriteServices, activeServiceId)}
            </div>
          ) : ''}

          {/* не избранные и не кэшаут и не майпост */}
          {(currentTypeService !== FAVOURITE_SERVICES_TAB_ID &&
            currentTypeService !== CASHOUT_SERVICES_TAB_ID &&
            currentTypeService !== MYPOST_SERVICES_TAB_ID) && (
            <Pagination
              current={currentPage}
              defaultPageSize={10}
              pageSize={1}
              total={pagesCount}
              onChange={this.changePage}
              showTotal={total => `Всего страниц:  ${total}`}
            />
          )}
        </div>
      </Accordion >
    );
  }
}

ServiceListBlock.propTypes = {
  servicesByTypes: PropTypes.array.isRequired,
  commissionProfiles: PropTypes.array.isRequired,
  favourites: PropTypes.array.isRequired,
  serviceList: PropTypes.array.isRequired,
  setCurrentService: PropTypes.func.isRequired,
  initPayType: PropTypes.func.isRequired,
  setIsAuthInFiscal: PropTypes.func.isRequired,
  setAuthDataIsRequested: PropTypes.func.isRequired,
  goToEndSessionPage: PropTypes.func.isRequired,
  initCurrentComProfile: PropTypes.func.isRequired,
  setFavourites: PropTypes.func.isRequired,
  setShowCashOutModal: PropTypes.func.isRequired,
  setShowMyPostPaymentModal: PropTypes.func.isRequired,
  setShowMyPostDeliveryModal: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  mayPay: PropTypes.number.isRequired,
  hasCashOut: PropTypes.bool.isRequired,
  hasMyPost: PropTypes.bool.isRequired,
  isFiscal: PropTypes.bool.isRequired,
  isKkmBlocked: PropTypes.bool.isRequired,
  isAuthInFiscal: PropTypes.bool.isRequired,
  blockReason: PropTypes.string.isRequired,
  kkmInfo: PropTypes.object.isRequired,
  currentService: PropTypes.object.isRequired,
  showAccordion: PropTypes.func.isRequired,
  updateSelectedFieldValue: PropTypes.func.isRequired,
  setClientIIN: PropTypes.func.isRequired,
  checkAllNeededData: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    mayPay: Number(state.kassa.balances.mayPay),
    hasCashOut: state.services.hasCashOut,
    hasMyPost: state.services.hasMyPost,
    favourites: state.services.favourites,
    serviceList: state.services.serviceList,
    servicesByTypes: state.services.servicesByTypes,
    commissionProfiles: state.commission.commissionProfiles,
    kkmInfo: state.fiscal.kkmInfo,
    isFiscal: state.fiscal.isFiscal,
    blockReason: state.fiscal.blockReason,
    isKkmBlocked: state.fiscal.isKkmBlocked,
    isAuthInFiscal: state.fiscal.isAuthInFiscal,
    currentService: state.kassa.currentService
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setCurrentService,
    initPayType,
    setFavourites,
    showError,
    setShowMyPostPaymentModal,
    setShowMyPostDeliveryModal,
    setShowCashOutModal,
    initCurrentComProfile,
    setIsAuthInFiscal,
    goToEndSessionPage,
    setAuthDataIsRequested,
    showAccordion,
    updateSelectedFieldValue,
    setClientIIN,
    checkAllNeededData
  },
  dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceListBlock);
