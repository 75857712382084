import * as payTypes from 'actions/pay/types';
import * as apiTypes from 'actions/api/types';
import * as coidTypes from 'actions/coid/types';
import * as servicesTypes from 'actions/services/types';

const defaultState = {
  lang: 'ru',
  summaryReport: {},
  cashoutReport: [],
  currentService: {},
  paymentsCounter: {
    card: 0,
    cash: 0,
  },
  balances: {
    limit: 0,
    balance: 0,
    remuneration: 0,
    mayPay: 0,
  },
  isAccordionOpen: true,
  isBalancesRequested: false,
  showIdentificationModal: false,
  showIdentModalFromCashout: false,
  isBalancesRequestedWithError: false,
  showIdentModalFromCashoutAccount: '',
  otherIdServiceForMakePay: 0,
};

const home = (state = defaultState, action = {}) => {
  switch (action.type) {
    case servicesTypes.SET_CURRENT_SERVICE:
      return {
        ...state,
        currentService: action.service,
        isAccordionOpen: false,
      };
    case apiTypes.SET_BALANCES: {
      if (action.balances) {
        const { balance, limit, remuneration, mayPay } = action.balances;

        return {
          ...state,
          balances: {
            limit: Number(limit),
            balance: Number(balance),
            remuneration: Number(remuneration),
            mayPay: Number(mayPay),
          },
        };
      }

      return { ...state };
    }
    case apiTypes.SET_BALANCES_XML_REQUEST:
      return { ...state, isBalancesRequested: true };
    case payTypes.SET_OTHER_ID_SERVICE_FOR_MAKE_PAY:
      return { ...state, otherIdServiceForMakePay: action.value };
    case apiTypes.BALANCE_REQUEST_WITH_ERROR:
      return { ...state, isBalancesRequestedWithError: action.status };
    case apiTypes.SET_SUMMARY_REPORT_DATA:
      return { ...state, summaryReport: action.summaryReport };
    case apiTypes.SET_CASHOUT_REPORT_DATA:
      return { ...state, cashoutReport: action.cashoutReport };
    case servicesTypes.SET_ACCORDION_OPENED:
      return { ...state, isAccordionOpen: action.isAccordionOpen };
    case coidTypes.SET_SHOW_IDENTIFICATION_MODAL:
      return {
        ...state,
        showIdentificationModal: action.showIdentificationModal,
      };
    case coidTypes.SET_SHOW_IDENTIFICATION_MODAL_FROM_CASHOUT:
      return {
        ...state,
        showIdentModalFromCashout: action.data.showIdentModalFromCashout,
        showIdentModalFromCashoutAccount: action.data.showIdentModalFromCashoutAccount
      };
    case payTypes.SET_PAYMENT_COUNTER_VALUE: {
      return {
        ...state,
        paymentsCounter: {
          ...state.paymentsCounter,
          [action.field]: action.value,
        },
      };
    }
    case servicesTypes.INIT_CURRENT_SERVICE: {
      return { ...state, currentService: {} };
    }
    case payTypes.PAYMENT_INIT: {
      return { ...state, otherIdServiceForMakePay: 0 };
    }
    default:
      return state;
  }
};

export default home;
