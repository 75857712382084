/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactToPrint from 'react-to-print';
import { hideFiscalModal, getXReportData, clearReportData, getOperationDetail } from 'actions/fiscal';
import { getHumanDateTime, formatMoney } from 'helpers/main';
import { getFiscalData } from 'helpers/fiscal';
import PropTypes from 'prop-types';
import Modal from 'components/UI/Modal/modal';
import CustomButton from 'components/UI/Button/button';
import { X_REPORT_TYPE, Z_REPORT_TYPE } from 'constants/fiscal';
import ReportSaveInstruction from 'public/files/report-save-instruction.pdf';
import ReportReceiptContent from 'components/Reports/ReportReceiptContent';
import ReportOperations from 'components/Reports/ReportOperations';

const MODAL_TITLE = {
  0: 'X-отчёт',
  1: 'Z-отчёт'
};

const Report = (props) => {
  const { showModal, xReportData, zReportData, type } = props;
  const [isReportDataLoaded, setIsReportDataLoaded] = useState(false);
  const [reportData, setReportData] = useState({});
  const [componentRef, setComponentRef] = useState({});

  // Если тип отчета x-отчет, запрашиваем информацию для отчета
  useEffect(() => {
    if (type === X_REPORT_TYPE) {
      props.getXReportData({ showLoader: true });
    }
  }, []);

  useEffect(() => {
    if (typeof xReportData.Operations !== 'undefined') {
      setIsReportDataLoaded(true);
      setReportData(xReportData);
    }
  }, [xReportData]);

  useEffect(() => {
    if (typeof zReportData.Operations !== 'undefined') {
      setIsReportDataLoaded(true);
      setReportData(zReportData);
    }
  }, [zReportData]);

  const handleCloseModal = () => {
    props.hideFiscalModal();
    props.clearReportData();
  };

  const handleReportRowClick = useCallback((operationId) => {
    props.getOperationDetail({ IdDocument: operationId });
  }, []);

  const reactPrintTrigger = useCallback(() => <CustomButton >Распечатать</CustomButton>, []);

  if (showModal && isReportDataLoaded) {
    const {
      TotalAmount,
      BalanceOpen,
      BalanceClose,
      BalanceCloseNonCash,
      Operations,
      Sections: SectionsReport,
      Total: { Expenses, Incomes, Refunds, Purchases, Sales },
      Kkm: {
        Rnm,
        Id: idKkm,
        Name: kkmName,
        Company: { FullName, Bin },
        Address: { Street, House, Flat, Town: { Name: TownName } },
      }
    } = reportData;
    const { Name: cashierName } = getFiscalData().userData;
    const balanceCloseNonCash = BalanceCloseNonCash || 0;

    const sectionsData = Object.values(SectionsReport);
    const sectionsId = Object.keys(SectionsReport);
    const reportRender = (
      <>
        <div className="fiscal-report-info">
          <div className="card">
            <div className="header-block">
              Основная информация
            </div>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>Наименование организации</td>
                    <td>{FullName} </td>
                  </tr>
                  <tr>
                    <td>ИИН/БИН</td>
                    <td>{Bin}</td>
                  </tr>
                  <tr>
                    <td>ККМ</td>
                    <td>{idKkm}</td>
                  </tr>
                  <tr>
                    <td>Регистрационный номер в КГД</td>
                    <td>{Rnm}</td>
                  </tr>
                  <tr>
                    <td>Название кассы</td>
                    <td>{kkmName}</td>
                  </tr>
                  <tr>
                    <td>Адрес торговой точки</td>
                    <td>{TownName}, {Street} {House}, {Flat}</td>
                  </tr>
                  <tr>
                    <td>Кассир</td>
                    <td>{cashierName}</td>
                  </tr>
                  <tr>
                    <td><hr /></td>
                    <td><hr /></td>
                  </tr>
                  <tr>
                    <td>Баланс на момент открытия смены</td>
                    <td>{formatMoney(BalanceOpen)} тг</td>
                  </tr>
                  {type === Z_REPORT_TYPE && (
                    <>
                      <tr>
                        <td>Баланс на момент закрытия смены</td>
                        <td>{formatMoney(BalanceClose)} тг</td>
                      </tr>
                      <tr>
                        <td>Наличных в кассе на момент закрытия смены</td>
                        <td>{formatMoney(Number(TotalAmount) - Number(balanceCloseNonCash))} тг</td>
                      </tr>
                      <tr>
                        <td>Безналичных в кассе на момент закрытия смены</td>
                        <td>{formatMoney(balanceCloseNonCash)} тг</td>
                      </tr>
                    </>
                  )}
                  {type === X_REPORT_TYPE && (
                    <>
                      <tr>
                        <td>Наличных в кассе</td>
                        <td>{formatMoney(Number(TotalAmount) - Number(balanceCloseNonCash))} тг</td>
                      </tr>
                      <tr>
                        <td>Безналичных в кассе</td>
                        <td>{formatMoney(balanceCloseNonCash)} тг</td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <td>Оформлено чеков</td>
                    <td>
                      <span>{Operations.length.toString().replace(/[^+\d]/g, '')}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="issues-receipt">
            <div className="header-block">
              Оформленные чеки
            </div>
            <div>
              <ReportReceiptContent title="Служебный приход" count={Incomes.Qty} sum={Incomes.Amount} />
              <ReportReceiptContent title="Служебный расход" count={Expenses.Qty} sum={Expenses.Amount} />
              <ReportReceiptContent title="Продажи" count={Sales.Qty} sum={Sales.Amount} />
              <ReportReceiptContent title="Возвраты" count={Refunds.Qty} sum={Refunds.Amount} />
              <ReportReceiptContent title="Покупки" count={Purchases.Qty} sum={Purchases.Amount} />
            </div>
          </div>
        </div>
        <ReportOperations operations={Operations} onClickRow={handleReportRowClick}/>
        <div className="fiscal-operations">
          <div className="header-block">
            Отчет по секциям
          </div>
          <div>
            {typeof SectionsReport !== 'undefined' ? (
              <table>
                <thead>
                  <tr>
                    <td>Секция</td>
                    <td>Сумма продаж</td>
                    <td>Сумма покупок</td>
                    <td>Сумма возвратов продаж</td>
                    <td>Сумма возвратов покупок</td>
                  </tr>
                </thead>
                <tbody>
                  {sectionsId.map((sectionId, key) => (
                    <tr key={sectionId}>
                      <td>{sectionId} {sectionsData[Number(key)].Name ? `(${sectionsData[Number(key)].Name})` : ''}</td>
                      <td>{formatMoney(sectionsData[Number(key)].Sales || 0)} тг</td>
                      <td>{formatMoney(sectionsData[Number(key)].Purchases || 0)} тг</td>
                      <td>{formatMoney(sectionsData[Number(key)].Refunds || 0)} тг</td>
                      <td>{formatMoney(sectionsData[Number(key)].PurchaseRefunds || 0)} тг</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )
              :
              'Операций не производилось'}
          </div>
        </div>
      </>
    );

    const getModalOpen = () => (showModal === true && isReportDataLoaded === true);

    return (
      <Modal
        title={`${MODAL_TITLE[Number(type)]} ${type === Z_REPORT_TYPE ? 'Смена закрыта' : ''} (${getHumanDateTime()})`}
        isOpen={getModalOpen()}
        onOk={null}
        onClose={() => handleCloseModal()}
        okText="Оформить чек"
        size="high"
        isFooterHidden
      >
        <div className="fiscal-report">
          <div style={{ display: 'none' }}>
            <div ref={el => setComponentRef(el)} style={{ padding: 20 }} className="fiscal-report">
              {MODAL_TITLE[Number(type)]}
              <br />
              {type === Z_REPORT_TYPE ? 'Смена закрыта' : ''}
              <br />
              {getHumanDateTime()}
              <div style={{ marginTop: 20 }}>
                {reportRender}
              </div>
            </div>
          </div>
          <div className="head">
            <ReactToPrint
              trigger={reactPrintTrigger}
              content={() => componentRef}
            />
            <a
              target="_blank"
              href={ReportSaveInstruction}
              rel="noopener noreferrer"
              className="link-block"
            >
              Как сохранить отчет в файл?
            </a>
          </div>
          {reportRender}
        </div>
      </Modal>
    );
  }

  return <div />;
};

const mapStateToProps = (state) => ({
  showModal: state.fiscal.modalData.showModal,
  xReportData: state.fiscal.xReport,
  zReportData: state.fiscal.zReport,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    hideFiscalModal,
    clearReportData,
    getOperationDetail,
    getXReportData
  },
  dispatch);

Report.propTypes = {
  showModal: PropTypes.bool.isRequired,
  getXReportData: PropTypes.func.isRequired,
  hideFiscalModal: PropTypes.func.isRequired,
  clearReportData: PropTypes.func.isRequired,
  getOperationDetail: PropTypes.func.isRequired,
  xReportData: PropTypes.object.isRequired,
  zReportData: PropTypes.object.isRequired,
  type: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);
