import PayTypes from 'paytypes';
import React from 'react';
import MaskedInput from 'react-maskedinput';

import IinField from 'components/IinField';
import { t, getMaskForInput, checkAccount } from 'helpers/main';
import MinAndMaxAmount from 'components/MinAndMaxAmount';

const {
  containers: { CurrencyContainer },
  templates: { CurrencyTemplate }
} = PayTypes;

class Currency extends CurrencyTemplate {
  state = {
    account: '',
    isLoading: false
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDownFowWindow);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDownFowWindow);
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props.payTypeCurrency;

    if (prevProps.payTypeCurrency.id !== id && id) {
      this.confirm();
    }
  }

  onKeyDownFowWindow = (e) => {
    if (e.key === 'Enter') {
      this.validateAndCheck();
    }
  };

  confirm() {
    const {
      setConfirmInfo,
      showConfirmScreen,
      pay: { account, willBeCreditedAmount },
      payTypeCurrency: { tariff, measure, commission }
    } = this.props;

    const toPay = parseFloat((Number(willBeCreditedAmount) - commission) / tariff).toFixed(2);

    const confirmFields = [
      { name: 'account', value: account },
      { name: 'exchangeRate', value: `${tariff} ${measure}` },
      { name: 'toEnrollment', value: `${toPay} ${measure}` },
    ];

    const checkInfo = {
      input: {
        account,
      },
      online: {
        currency: {
          name: measure,
          tariff,
          commission
        }
      }
    };

    setConfirmInfo(confirmFields, this.getAddings(), checkInfo);
    showConfirmScreen();
  }

  getAddings() {
    const {
      payTypeCurrency: { tariff, measure, commission },
      pay: { willBeCreditedAmount }
    } = this.props;

    return {
      constraint: `${tariff} тг/${measure}`,
      amount0: parseFloat((Number(willBeCreditedAmount) - Number(commission)) / tariff).toFixed(2),
    };
  }

  handleOnChange(e) {
    this.setState({
      [e.target.id]: e.target.value.trim()
    });
  }

  validateAndCheck() {
    const {
      onlineCheck,
      kassa: { currentService },
      pay: { willBeCreditedAmount },
      payTypeCurrency: { id },
      showError,
      updateFieldValue,
      iinIsValidOrEmpty
    } = this.props;

    this.setState({ isLoading: false });
    const { regExp, minAmount, maxAmount } = currentService;
    const { account } = this.state;

    if (!iinIsValidOrEmpty()) return;
    if (account && willBeCreditedAmount) {
      updateFieldValue('account', account);

      const result = checkAccount(
        account,
        willBeCreditedAmount,
        regExp,
        minAmount,
        maxAmount
      );

      if (result.message === '') {
        if (id !== '' && id.toString() === account.toString()) {
          return this.confirm();
        }
        this.setState({ isLoading: true });

        return onlineCheck(currentService, account)
          .then(() => { this.setState({ isLoading: false }); });
      }

      return showError(result.message);
    }

    return showError(
      'Проверьте, пожалуйста, и повторите попытку',
      'Все поля обязательны для заполнения'
    );
  }

  /*
    Яндекс Деньги 410014760572489 410012046643795 410015458884703
    WMZ Z087622683964
    WMK K247107773364
    edit: вбить Яндекс Деньги в поиск и выбрать перый. счет 410015458884703
  */
  renderComponent() {
    const {
      pay: { amount },
      commission: { currentComProfile },
      kassa: { currentService: { maskEdit, idService } },
    } = this.props;

    const mask = getMaskForInput(maskEdit);

    return (
      <div className="pay-form">
        <IinField/>
        <div className="form-group">
          <MaskedInput
            mask={mask}
            type="tel"
            id="account"
            autoComplete="off"
            maxLength={40}
            placeholder="№ лицевого счета"
            placeholderChar=" "
            onChange={(e) => this.handleOnChange(e)}
            value={this.state.account}
            formatCharacters={{
              W: {
                validate(char) { return /[\wа-яА-Я]/.test(char); },
              }
            }}
            disabled={this.state.isLoading}
          />
        </div>

        <div className="form-group">
          <MaskedInput
            mask="111111111"
            type="tel"
            id="amount"
            autoComplete="off"
            maxLength={9}
            placeholder="Сумма оплаты"
            placeholderChar=" "
            onChange={(e) =>
              this.props.updateWillBeCreditedAmount(e.target.value, currentComProfile, true)
            }
            value={amount}
            disabled={this.state.isLoading}
          />
        </div>
        <MinAndMaxAmount idService={idService} />
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => this.validateAndCheck()}
        >
          {t('next')}
        </button>
      </div>
    );
  }
}

export default CurrencyContainer(Currency);
