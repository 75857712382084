import React from 'react';
import PayTypes from 'paytypes';
import MaskedInput from 'react-maskedinput';

import IinField from 'components/IinField';
import MinAndMaxAmount from 'components/MinAndMaxAmount';
import RadioGroup from 'components/UI/RadioGroup/radioGroup';
import { t, getMaskForInput, checkAccountOnReg } from 'helpers/main';

const {
  containers: { ChoiceOfContractContainer },
  templates: { ChoiceOfContractTemplate }
} = PayTypes;

/* Жедел Кузет 870924350595 */
/* Koke.kz 620826400788, 910113300341, 860521300685 */
/* SmartSchool.kz 130211600181, 040609650965 */

class ChoiceOfContractComponent extends ChoiceOfContractTemplate {
  state = {
    account: '',
    isLoading: false
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDownFowWindow);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDownFowWindow);
  }

  onKeyDownFowWindow = (e) => {
    if (e.key === 'Enter') {
      if (this.props.payTypeChoiceOfContract.invoice.length === 0) {
        this.validateAndCheck();
      } else {
        this.validateAndPay();
      }
    }
  };

  confirm() {
    const {
      setConfirmInfo,
      showConfirmScreen,
      pay: { account },
      payTypeChoiceOfContract: { selectedContract, invoice }
    } = this.props;

    const confirmFields = [
      { name: 'account', value: account },
    ];

    const { ID, num } = [].concat(invoice).filter(item => (item.ID === selectedContract))[0];

    const checkInfo = {
      input: {
        account,
        contract: {
          id: ID || '0',
          name: num
        }
      },
    };

    setConfirmInfo(confirmFields, this.getAddings(), checkInfo);
    showConfirmScreen();
  }

  setAccountValue = (account) => this.setState({ account });

  validateAndCheck() {
    const {
      onlineCheck,
      kassa: { currentService },
      showError,
      updateFieldValue,
      iinIsValidOrEmpty
    } = this.props;

    this.setState({ isLoading: false });
    if (!iinIsValidOrEmpty()) return;
    const { account } = this.state;

    if (account) {
      const { regExp, maskEdit } = currentService;

      if (checkAccountOnReg(account, regExp, maskEdit)) {
        updateFieldValue('account', account);
        this.setState({ isLoading: true });

        return onlineCheck(currentService, account)
          .then(() => {
            this.setState({ isLoading: false });
          });
      }

      return showError(
        'Проверьте, пожалуйста, и повторите попытку',
        'Лицевой счет некорректен'
      );
    }

    return showError(
      'Заполните лицевой счет и повторите попытку',
      'Лицевой счет обязателен для заполнения'
    );
  }

  validateAndPay() {
    const {
      showError,
      payTypeChoiceOfContract: { selectedContract },
      pay: { willBeCreditedAmount }
    } = this.props;

    if (selectedContract === '') return showError('Необходимо выбрать договор для оплаты', 'Договор не выбран');

    if (!willBeCreditedAmount) return showError('Введите сумму оплаты', 'Не заполнена сумма оплаты');

    this.confirm();
  }

  getInvoicesForRadio = () => {
    const { payTypeChoiceOfContract: { invoice, selectedContract } } = this.props;
    const invoices = [].concat(invoice);
    const invoicesBlock = [];

    if (invoices.length === 1 && selectedContract === '') {
      this.setAmountAndSelectContract(invoices[0].ID);
    }

    if (invoices.length) {
      invoices.forEach(({ sum, num, ID }) => {
        const number = (typeof num !== 'object' && typeof num !== 'undefined') ? num : '(Не указано)';
        let amount = 0;

        if (typeof sum === 'object' && typeof sum.content !== 'undefined') {
          amount = sum.content;
        } else if (typeof sum === 'string') {
          amount = sum;
        }

        const amountLabel = amount ? `(${Number(amount)} тг)` : '';

        invoicesBlock.push({
          key: ID,
          value: ID,
          label: `Договор ${number} ${amountLabel}`
        });
      });
    }

    return invoicesBlock;
  }

  setAmountAndSelectContract = (value) => {
    const {
      payTypeChoiceOfContract,
      selectContract,
      updateWillBeCreditedAmount,
      commission: { currentComProfile }
    } = this.props;

    const contract = [].concat(payTypeChoiceOfContract.invoice).filter(item => (item.ID === value));
    const { sum } = contract[0];
    let amount = 0;

    if (typeof sum === 'object' && typeof sum.content !== 'undefined') {
      amount = Number(sum.content).toString();
    } else if (typeof sum === 'string' || typeof sum === 'number') {
      amount = sum;
    }

    updateWillBeCreditedAmount(Math.ceil(amount).toString(), currentComProfile);
    selectContract(value, amount);
  }

  getAddings() {
    const {
      pay: { willBeCreditedAmount },
      payTypeChoiceOfContract: { selectedContract }
    } = this.props;

    return {
      constraint: selectedContract,
      amount0: Number(willBeCreditedAmount),
    };
  }

  renderComponent = () => {
    const {
      initPayType,
      updateWillBeCreditedAmount,
      pay: { account, willBeCreditedAmount },
      payTypeChoiceOfContract: { invoice, fio, showAmount, selectedContract },
      kassa: { currentService: { maskEdit, typeInterface, idService } },
      commission: { currentComProfile }
    } = this.props;

    if (invoice.length === 0) {
      return (
        <div className="pay-form">
          <IinField/>
          <div className="form-group">
            <MaskedInput
              mask={getMaskForInput(maskEdit)}
              id="account"
              type="tel"
              autoComplete="off"
              maxLength={40}
              placeholder="№ лицевого счета"
              placeholderChar=" "
              onChange={(e) => this.setAccountValue(e.target.value)}
              value={this.state.account}
              formatCharacters={{
                W: {
                  validate(char) { return /[\wа-яА-Я]/.test(char); },
                }
              }}
              disabled={this.state.isLoading}
            />
          </div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => this.validateAndCheck()}
          >
            {t('checkDebt')}
          </button>
        </div>
      );
    }

    return (
      <div className="pay-form">
        <button
          onClick={() => initPayType(typeInterface)}
          className="back-text buttonWithoutStyles"
          tabIndex="0"
          type="button"
        >
          {t('editData')}
        </button>
        <h1>{t('selectTheContract')}</h1>
        <div className="confirm-info">
          <p><b>{t('account')}: </b> {account} </p>
          {(fio !== '') && (<p><b>{t('fio')}: </b> {fio} </p>)}
          <RadioGroup
            onChange={(val) => this.setAmountAndSelectContract(val)}
            selected={selectedContract}
            data={this.getInvoicesForRadio()}
          />
          {(showAmount) && (
            <div className="form-group text-center">
              <MaskedInput
                mask="111111111"
                type="tel"
                id="amount"
                autoComplete="off"
                maxLength={9}
                placeholder="Сумма оплаты"
                placeholderChar=" "
                onChange={(e) => updateWillBeCreditedAmount(e.target.value, currentComProfile)}
                value={willBeCreditedAmount.toString()}
              />
            </div>
          )}
        </div>
        <MinAndMaxAmount idService={idService} />
        <div>
          <button
            className="btn btn-primary"
            onClick={() => this.validateAndPay()}
          >
            {t('pay')}
          </button>
        </div>
      </div>
    );
  }
}

export default ChoiceOfContractContainer(ChoiceOfContractComponent);
