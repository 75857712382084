import axios from 'axios';
import { sha256 } from 'js-sha256';
import md5 from 'js-md5';
import { XMLValidator, XMLParser } from 'fast-xml-parser';
import jwt from 'jsonwebtoken';
import get from 'lodash/get';

import { setAuthData, getAuthData, resetAuthData } from 'model/UserDb';
import { XML_TO_JSON_OPTIONS } from 'constants/all';
import { GATEWAY_URL } from 'constants/api';
import PUBLIC_JWT_KEY from 'constants/key';
import { getErrText } from 'helpers/main';

const headers = {
  jwtauth: sha256(md5('myPaymentsAppIsAwesome').toString())
};

const parser = new XMLParser(XML_TO_JSON_OPTIONS);

// содержимое jwtauth неважно, важно наличие, сам jwt-токен шлем в guid в каждом запросе
export const sendXmlReq = async (type, body) => {
  const isAuthRequest = type === 1 && !body.includes('refreshToken');
  const guid = getGuid(isAuthRequest);

  const xml = `
    <request>
      <type>${type}</type>
      <guid>${guid}</guid>
      <body>${body}</body>
      <sign></sign>
    </request>`;

  return axios.post(GATEWAY_URL, xml, { headers })
    .then(({ data, status }) => {
      const result = { body: {}, status };

      if (XMLValidator.validate(data) === true) {
        const jsonObj = parser.parse(data);

        const { request, rs } = jsonObj;

        if (typeof request !== 'undefined') {
          // исключение статуса для фискала
          if (request.body.status !== 927) {
            const error = checkErrors(request, type);

            if (error) throw new Error(error);
          }
          // Возврат результата
          if (typeof request.body !== 'undefined') {
            result.body = request.body;
          }

          return result;
        }

        // Возврат результата
        if (typeof rs !== 'undefined') {
          result.body = rs;

          return result;
        }
      }

      return null;
    });
};

const getGuid = (isAuthRequest) => {
  if (isAuthRequest) return '';

  return getAuthData().guid;
};

export const sendJsonReq = async (type, body) => {
  const { guid } = getAuthData();

  const json = {
    request: {}
  };

  if (type === 'logException') {
    json.request.action = type;
    json.request.message = body;
  } else {
    json.request.type = type;
    json.request.body = body;
    json.request.sign = '';
    json.request.guid = guid;
  }

  return axios.post(GATEWAY_URL, json, { headers })
    .then(({ data, status }) => {
      const result = { body: {}, status };
      const { request, rs, tFiscalInfo } = data;

      if (typeof request !== 'undefined') {
        const error = checkErrors(request, type);

        if (error) {
          throw new Error(error);
        }

        // Возврат результата
        if (typeof request.body !== 'undefined') {
          result.body = request.body;
        }

        return result;
      }

      // Возврат результата
      if (typeof rs !== 'undefined') {
        result.body = rs;

        return result;
      }

      if (typeof tFiscalInfo !== 'undefined') {
        result.body = data;

        return result;
      }
    });
};

// Проверка на наличие ошибок
export const checkErrors = (response, type = '') => {
  const { body } = response;

  if (typeof body !== 'undefined' && typeof body.message !== 'undefined' && body.message) {
    return body.message;
  }

  const status1 = get(response, 'status', null);
  const status2 = get(response, 'body.status', null);
  const status3 = get(response, 'body.errCode', null);
  const status4 = get(response, 'body.request.status', null);
  const statusCode = status1 || status2 || status3 || status4;

  if (statusCode) return getErrText(statusCode, type);

  return '';
};

export const isTokenAlive = () => {
  const { guid } = getAuthData();

  if (guid) {
    const expTimeStamp = jwt.decode(guid).exp;
    const dateToExpire = new Date(expTimeStamp * 1000);
    const dateNow = new Date(Date.now());
    const timeToExpire = (dateToExpire.getTime() - dateNow.getTime()) / 1000;

    if (timeToExpire > 120) {
      return true;
    }

    return false;
  }

  return false;
};

export const refreshJwtToken = () => {
  const { idTerminal, numUser, refreshToken } = getAuthData();

  if (refreshToken !== '') {
    const xml = `<refreshToken>${refreshToken}</refreshToken>`;

    return sendXmlReq(1, xml)
      .then(({ body }) => {
        // Проверяем jwt на валидность
        const { guid, refreshToken: newRefreshToken } = body.idt;

        return jwt.verify(body.idt.guid, PUBLIC_JWT_KEY, (err, decoded) => {
          if (err === null && typeof decoded !== 'undefined') {
            setAuthData({ idTerminal, guid, numUser, refreshToken: newRefreshToken });

            return body.idt.guid;
          }

          return '';
        });
      })
      .catch(({ message }) => {
        console.log('message', message);
        if (['Вы не авторизованы', 'Время вышло'].includes(message)) {
          resetAuthData();

          return null;
        }

        return '';
      });
  }

  return null;
};
