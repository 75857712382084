import * as types from 'actions/check/types';

/* STRUCT:
 {
  "checksWithCode": {
    "sn": {
      "idService": 0,
      "checks": [],
      "code": '',
      isPaymentSuccess: false,
    }
  }
} */

const defaultState = {
  checksWithCode: {},
  lang: ''
};

const check = (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_CHECK_WITH_CODE: {
      const receipts = { ...state.checksWithCode };

      receipts[action.data.sn] = {};
      receipts[action.data.sn].code = '';
      receipts[action.data.sn].idService = action.data.idService;
      receipts[action.data.sn].isPaymentSuccess = action.data.isPaymentSuccess || false;
      receipts[action.data.sn].checks = action.data.checks;

      return { ...state, checksWithCode: receipts };
    }
    case types.SET_CHECK_WITH_CODE_IS_SAVED: {
      const receipts = { ...state.checksWithCode };

      receipts[action.sn].code = action.code;

      return { ...state, checksWithCode: receipts };
    }
    case types.REMOVE_CHECK_WITH_CODE: {
      const checksWithCode = { ...state.checksWithCode };

      if (typeof checksWithCode[action.sn] !== 'undefined') {
        delete checksWithCode[action.sn];
      }

      return { ...state, checksWithCode };
    }

    case types.UPDATE_CHECK_WITH_CODE_DATA: {
      const receiptData = state.checksWithCode[action.sn];

      if (typeof receiptData !== 'undefined') {
        return {
          ...state,
          checksWithCode: {
            ...state.checksWithCode,
            [action.sn]: {
              ...receiptData,
              ...action.updatedData
            }
          }
        };
      }

      return { ...state };
    }
    case types.SET_RECEIPT_LANG: {
      return { ...state, lang: action.lang };
    }
    default:
      return state;
  }
};

export default check;
