import { setTerminalInfo } from 'model/Terminal';

import * as types from './types';

// Информация о терминале
// eslint-disable-next-line import/prefer-default-export
export const fetchTerminalInfo = () => (dispatch) => {
  const REQ_TYPE = 121;

  const successCallback = (data) => {
    setTerminalInfo({
      isSystem: data.isSystem,
      nameDiler: data.nameDiler || '',
      fisCode: data.fisCode || '',
      telSupport: data.telSupport || '',
      iin: data.iin || '',
      rnm: data.rnm || '',
      dataNDS: data.dataNDS || '',
      addressPoint: data.addressPoint || '',
      addressCompany: data.addressCompany || '',
      kfu: data.kfu || '',
      nds: data.nds || '',
      region: data.region || '',
      rnnfil: data.rnnfil || '',
      cashlessDisabled: data.cashlessDisabled || 'off',
    });
  };

  dispatch({
    type: `${types.GET_TERMINAL_INFO}_JSON_REQUEST`,
    payload: { reqType: REQ_TYPE, json: '', successCallback }
  });
};
