
import { getFromLocalStorage } from 'helpers/main';

// eslint-disable-next-line import/prefer-default-export
export const isTheSamePaymentExist = (reqData, showError) => {
  if (reqData) {
    const lastTransaction = getFromLocalStorage('lastTransaction');

    if (lastTransaction === Number(reqData.SN)) {
      showError('Платеж уже был отправлен');

      return false;
    }

    return true;
  }

  return true;
};
