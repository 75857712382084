import React from 'react';
import PayTypes from 'paytypes';
import MaskedInput from 'react-maskedinput';

import IinField from 'components/IinField';
import Modal from 'components/UI/Modal/modal';
import { t, getMaskForInput, checkAccountOnReg } from 'helpers/main';
import { paramsKostanaySu, COUNTERS_WITHOUT_SEVERAGE } from 'constants/all';
import MinAndMaxAmount from 'components/MinAndMaxAmount';

import KostanaySuCouters from './Counters';

const {
  containers: { KostanaySuContainer },
  templates: { KostanaySuTemplate }
} = PayTypes;

class KostanaySu extends KostanaySuTemplate {
  state = {
    showModal: false,
    isLoading: false
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDownFowWindow);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDownFowWindow);
  }

  componentDidUpdate(prevProps) {
    const { step } = this.props.payTypeKostanaySu;

    if (step !== prevProps.payTypeKostanaySu.step && step) {
      this.showModal();
      this.updateItogAmount();
    }
  }

  onKeyDownFowWindow = (e) => {
    if (e.key === 'Enter') {
      if (this.state.showModal) {
        this.checkAndConfirm();
      } else {
        this.validateAndCheck();
      }
    }
  };

  confirm() {
    const {
      setConfirmInfo,
      showConfirmScreen,
      pay: { account },
      payTypeKostanaySu: { peni, saldo }
    } = this.props;

    const confirmField = [
      { name: 'account', value: account },
    ];

    let checkInfo = {
      input: {
        account,
        counters: {}
      },
      online: {
        arrears: Number(saldo),
        counters: [],
        penalty: Number(peni),
      }
    };

    checkInfo = this.getCheckInfoFromNotEmptyCount(checkInfo);

    setConfirmInfo(confirmField, this.getAddings(), checkInfo);
    showConfirmScreen();
  }

  getCheckInfoFromNotEmptyCount = (checkInfoProp) => {
    const checkInfo = checkInfoProp;
    const {
      payTypeKostanaySu: { notEmptyCounts, changedCounts, tarifs, sewerage, }
    } = this.props;

    notEmptyCounts.forEach(({ key, value }) => {
      if (changedCounts[key.toString()] !== value) {
        checkInfo.input.counters[key.toString()] = Number(changedCounts[key.toString()]);
      }

      checkInfo.online.counters.push({
        name: key,
        sewerage: !COUNTERS_WITHOUT_SEVERAGE.includes(key) ? Number(sewerage) : 0,
        tariff: Number(tarifs[paramsKostanaySu[key.toString()].tariff]),
        value: Number(value)
      });
    });

    return checkInfo;
  }

  enterAmount() {
    const {
      setConfirmInfo,
      showEnterAmountScreen,
      pay: { account },
      payTypeKostanaySu: { peni, saldo }
    } = this.props;

    const confirmField = [
      { name: 'account', value: account },
    ];

    let checkInfo = {
      input: {
        account,
        counters: {}
      },
      online: {
        arrears: Number(saldo),
        counters: [],
        penalty: Number(peni),
      }
    };

    checkInfo = this.getCheckInfoFromNotEmptyCount(checkInfo);

    setConfirmInfo(confirmField, this.getAddings(), checkInfo);
    showEnterAmountScreen();
  }

  showModal = () => this.setState({ showModal: true });

  hideModal = () => this.setState({ showModal: false });

  checkAndConfirm = () => {
    const {
      showError,
      payTypeKostanaySu: {
        changedCounts,
        notEmptyCounts,
        saldo,
        peni
      }
    } = this.props;

    if (notEmptyCounts.filter((item) =>
      (Number(item.value) > Number(changedCounts[item.key]))).length) {
      return showError('В одной из позиций текущие показания меньше последних!');
    }

    if (notEmptyCounts.length === 0 && Number(saldo) === 0 && Number(peni) === 0) {
      return this.enterAmount();
    }

    this.confirm();
  }

  handleOnChange(e) {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  validateAndCheck = () => {
    const { account } = this.state;
    const {
      kassa: { currentService },
      onlineCheck,
      showError,
      updateFieldValue,
      payTypeKostanaySu: { id },
      iinIsValidOrEmpty
    } = this.props;

    this.setState({ isLoading: false });
    if (!iinIsValidOrEmpty()) return;

    if (account) {
      const { regExp, maskEdit } = currentService;

      if (checkAccountOnReg(account, regExp, maskEdit)) {
        updateFieldValue('account', account.trim());

        if (id && Number(id) === Number(account)) {
          return this.showModal();
        }
        this.setState({ isLoading: true });

        return onlineCheck(currentService, account)
          .then(() => { this.setState({ isLoading: false }); });
      }

      return showError(
        'Проверьте, пожалуйста, и повторите попытку',
        'Лицевой счет некорректен'
      );
    }

    return showError(
      'Проверьте, пожалуйста, и повторите попытку',
      'Лицевой счет обязателен для заполнения'
    );
  }

  updateItogAmount() {
    const { commission: { currentComProfile }, updateWillBeCreditedAmount } = this.props;
    const sum = this.getItogAmount();
    const itogSum = (sum > 0) ? sum.toString() : '0';

    updateWillBeCreditedAmount(parseFloat(itogSum).toFixed(2), currentComProfile, true);
  }

  //  проверить эту схему
  getItogAmountOnCounters() {
    const { notEmptyCounts, changedCounts, tarifs } = this.props.payTypeKostanaySu;

    return notEmptyCounts.reduce((amount, item) => {
      const { value, key } = item;
      const diff = Number(changedCounts[key.toString()]) - Number(value);
      const tarriff = Number(tarifs[paramsKostanaySu[key.toString()].tariff]);

      return amount + (diff * tarriff);
    }, 0);
  }

  changeCurrentValueAndItog(key, e) {
    const { changeCurrentValue } = this.props;

    changeCurrentValue(key, e.target.value);
    this.updateItogAmount();
  }

  /*  Костанай Су  Лицевые счета: 59781, 63128, 10266, 78732 */
  renderComponent() {
    const { kassa, payTypeKostanaySu, pay } = this.props;
    const { maskEdit, idService } = kassa.currentService;
    const mask = getMaskForInput(maskEdit);
    const { showModal } = this.state;

    return (
      <>
        <Modal
          title={t('enterMeterReadings')}
          isOpen={showModal}
          okText={t('next')}
          onOk={this.checkAndConfirm}
          onClose={this.hideModal}
          size="high"
        >
          <KostanaySuCouters
            payTypeKostanaySu={payTypeKostanaySu}
            account={pay.account}
            itogAmountOnCounters={this.getItogAmountOnCounters()}
            changeCurrentValueAndItog={(key, e) => this.changeCurrentValueAndItog(key, e)}
          />
        </Modal>

        <div className="pay-form">
          <IinField/>
          <div className="form-group">
            <MaskedInput
              mask={mask}
              id="account"
              type="tel"
              autoComplete="off"
              maxLength={40}
              placeholder="№ лицевого счета"
              placeholderChar=" "
              onChange={(e) => this.handleOnChange(e)}
              formatCharacters={{
                W: {
                  validate(char) { return /[\wа-яА-Я-]/.test(char); },
                }
              }}
              disabled={this.state.isLoading}
            />
          </div>
          <MinAndMaxAmount idService={idService} />
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => this.validateAndCheck()}
          >
            {t('next')}
          </button>
        </div>
      </>
    );
  }
}

export default KostanaySuContainer(KostanaySu);
