export const SET_SERVICE_LIST = 'SET_SERVICE_LIST';
export const SET_CURRENT_SERVICE = 'SET_CURRENT_SERVICE';
export const INIT_CURRENT_SERVICE = 'INIT_CURRENT_SERVICE';
export const SET_ACCORDION_OPENED = 'SET_ACCORDION_OPENED';
export const SET_GROUPED_SERVICES = 'SET_GROUPED_SERVICES';
export const SET_FAVOURITE_SERVICES = 'SET_FAVOURITE_SERVICES';
export const ADD_ADDITIONAL_DETAILS = 'ADD_ADDITIONAL_DETAILS';
export const GET_SERVICE_LIST_XML_REQUEST = 'GET_SERVICE_LIST_XML_REQUEST';
export const ADD_MULTILINGUAL_SERVICE_NAME = 'ADD_MULTILINGUAL_SERVICE_NAME';
export const SERVICE_LIST_REQUEST_WITH_ERROR = 'SERVICE_LIST_REQUEST_WITH_ERROR';
