import { setLastServiceUpdate, getLastServiceUpdate } from 'model/Params';
import { addAllServices, getAllServices } from 'model/Services';
import { getTerminalInfo } from 'model/Terminal';
import { showError, showSuccess } from 'actions/alert';
import { hasItBeenTwoHours } from 'helpers/main';
import { CASH_OUT_PAY_TYPE, MYPOST_PAY_TYPE_PAYMENT, MYPOST_PAY_TYPE_DELIVERY } from 'constants/payTypes';
import { CASHOUT_SERVICES_TAB_ID, MYPOST_SERVICES_TAB_ID } from 'constants/services';
import { checkFiscalToken } from 'actions/fiscal';
import { getShiftStatus, isTheShiftActive, checkSectionsOnValid } from 'helpers/fiscal';

import * as types from './types';

export function setCurrentService(service) {
  return (dispatch) => {
    dispatch({ type: types.SET_CURRENT_SERVICE, service });
  };
}

export function showAccordion(toggle) {
  return (dispatch) => {
    dispatch({ type: types.SET_ACCORDION_OPENED, isAccordionOpen: toggle });
  };
}

export function setServiceList(services) {
  return (dispatch) => {
    dispatch({ type: types.SET_SERVICE_LIST, services });
  };
}

export function groupedService(services) {
  return (dispatch) => {
    // группируем сервисы
    const groupedServices = [];

    services.forEach((item) => {
      const isItCashOutService = item.typeInterface === CASH_OUT_PAY_TYPE;
      const isItMyPostService = item.typeInterface === MYPOST_PAY_TYPE_PAYMENT ||
        item.typeInterface === MYPOST_PAY_TYPE_DELIVERY;

      // создаем группы сервисов
      const createdServiceGroups = createGroupService(
        groupedServices,
        item,
        isItCashOutService,
        isItMyPostService
      );

      return groupedServices.concat(createdServiceGroups);
    });

    dispatch({
      type: types.SET_GROUPED_SERVICES,
      services: groupedServices,
      hasCashOut: groupedServices[Number(CASHOUT_SERVICES_TAB_ID)].services.length > 0,
      hasMyPost: groupedServices[Number(MYPOST_SERVICES_TAB_ID)].services.length > 0
    });
  };
}

export function setExistingService() {
  return (dispatch) => {
    getAllServices()
      .then(services => {
        dispatch(groupedService(services));
        dispatch(setServiceList(services));

        return services;
      })
      .catch((err) => dispatch(showError(err)));
  };
}

export function setFavourites(favourites) {
  return (dispatch) => {
    dispatch({ type: types.SET_FAVOURITE_SERVICES, favourites });
  };
}

// Получение списка сервисов и группировка
export const getServicesList = (forceUpdate = false) => async (dispatch) => {
  const lastServiceUpdate = getLastServiceUpdate();
  const savedServicesList = await getAllServices();
  // lastUpdate шлется когда надо получить теги, и ни на что в АПИ не влияет
  const xml =
    `<rounding></rounding>
    <isCommissionDenied></isCommissionDenied>
    <lastUpdate>19000101000000</lastUpdate>
    <srvFullInfo>idService,name,description,minAmount,maxAmount,minLength,maxLength,type,grname,grbut,checkOnline,typeInterface,maskEdit,regExp,rank,logo,idsg,tag,tagUpdate,isEMoney,rounding,minPr,maxPr,min,max,isCommissionDenied</srvFullInfo>
  `;

  const successCallback = (data) => {
    const services = [].concat(data.srvFullInfo.serviceInfo);

    // Записываем в БД все сервисы
    addAllServices(services);

    // Записываем дату последнего обновления
    setLastServiceUpdate();

    if (data.srvFullInfo.serviceInfo && forceUpdate) {
      dispatch(showSuccess('Список сервисов успешно обновлен'));
    }

    // группируем сервисы
    dispatch(groupedService(services));
    dispatch(setServiceList(services));

    dispatch({ type: types.SERVICE_LIST_REQUEST_WITH_ERROR, status: false });
  };

  const dispatchData = {
    type: types.GET_SERVICE_LIST_XML_REQUEST,
    payload: { reqType: 9, xml, successCallback }
  };

  // Диспачим чтобы сделать флаг isServiceListRequested = true
  dispatch({ type: types.GET_SERVICE_LIST_XML_REQUEST });

  if (savedServicesList.length) {
    const canRequested = hasItBeenTwoHours(lastServiceUpdate);

    if (canRequested || forceUpdate) {
      dispatch(dispatchData);
    } else {
      dispatch(setExistingService());
    }
  } else {
    dispatch(dispatchData);
  }
};

// Доп информация по сервису
export const getAdditionalDetails = (idService) => (dispatch) => {
  const xml =
    `<srvAddInfo>
      <idService>${idService}</idService>
    </srvAddInfo>`;

  const successCallback = (data) => {
    const { srvInfo } = data.srvAddInfo;

    if (typeof srvInfo !== 'undefined') {
      let additionalDynamicInfo = '';
      let additionalCommonInfo = '';

      [].concat(srvInfo).forEach(({ addInfo, sName, lang }) => {
        const addsDynInfoRes = getAdditionalDetailsDynamicInfo(sName, addInfo);
        const addComInfoRes = getAdditionalDetailsCommonInfo(sName, addInfo, lang);

        if (addsDynInfoRes) additionalDynamicInfo = addsDynInfoRes;
        if (addComInfoRes) additionalCommonInfo = addComInfoRes;
      });

      if (additionalDynamicInfo || additionalCommonInfo) {
        dispatch({
          type: types.ADD_ADDITIONAL_DETAILS,
          id: idService,
          addInfo: additionalDynamicInfo || additionalCommonInfo
        });

        const srvInfoCopy = [].concat(srvInfo);

        srvInfoCopy.shift();

        dispatch({
          type: types.ADD_MULTILINGUAL_SERVICE_NAME,
          id: idService,
          value: [].concat(srvInfoCopy)
        });
      }
    }
  };

  dispatch({
    type: `${types.ADD_ADDITIONAL_DETAILS}_XML_REQUEST`,
    payload: { reqType: 21, xml, successCallback }
  });
};

const createGroupService = (services, item, isItCashOutService, isItMyPostService) => {
  const groupedServices = services;

  if (typeof groupedServices[item.type] === 'undefined') {
    groupedServices[item.type] = {
      services: [],
      groups: []
    };
  }

  if (item.idsg > 0) {
    if (typeof groupedServices[item.type].groups[item.idsg] === 'undefined') {
      groupedServices[item.type].groups[item.idsg] = [];
    }

    groupedServices[item.type].groups[item.idsg].push(item);
  }

  if (typeof item.idsg === 'undefined' || item.idsg === 0) {
    groupedServices[item.type].services.push(item);
  }

  //  группируем сервис кэшаута
  if (typeof groupedServices[Number(CASHOUT_SERVICES_TAB_ID)] === 'undefined') {
    groupedServices[Number(CASHOUT_SERVICES_TAB_ID)] = {
      services: [],
      groups: []
    };
  }

  if (isItCashOutService) {
    groupedServices[Number(CASHOUT_SERVICES_TAB_ID)].services.push(item);
  }

  //  группируем сервисы майпоста
  if (typeof groupedServices[Number(MYPOST_SERVICES_TAB_ID)] === 'undefined') {
    groupedServices[Number(MYPOST_SERVICES_TAB_ID)] = {
      services: [],
      groups: []
    };
  }

  if (isItMyPostService) {
    groupedServices[Number(MYPOST_SERVICES_TAB_ID)].services.push(item);
  }

  return groupedServices;
};

const getAdditionalDetailsDynamicInfo = (sName, addInfo) => {
  let additionalDynamicInfo = '';

  if (sName === 'service_fields' && addInfo.includes('[{')) {
    additionalDynamicInfo = addInfo.replace(/\\/g, '\\\\').replace(/\\\\"/g, '');
  }

  return additionalDynamicInfo;
};

const getAdditionalDetailsCommonInfo = (sName, addInfo, lang) => {
  let additionalCommonInfo = '';

  if (sName === 'long_memo' && lang === 'RU') {
    additionalCommonInfo = addInfo;
  }

  return additionalCommonInfo;
};

//
export const checkAllNeededData = () => (dispatch, getState) => {
  const props = getState();
  const {
    fiscal: { isFiscal },
    kassa: { balances: { mayPay } },
    commission: { commissionProfiles },
  } = props;

  const balanceIsLoaded = dispatch(checkBalanceIsLoaded(mayPay));
  const terminalInfoIsLoaded = dispatch(checkTerminalInfoIsLoaded());
  const commissionProfilesIsLoaded = dispatch(checkCommissionProfilesIsLoaded(commissionProfiles));
  const isFiscalDataValid = isFiscal ? dispatch(checkFiscalData(props.fiscal)) : true;

  return balanceIsLoaded && terminalInfoIsLoaded && commissionProfilesIsLoaded && isFiscalDataValid;
};

export const checkBalanceIsLoaded = (mayPay) => (dispatch) => {
  if (Number(mayPay) <= 0) {
    dispatch(showError('Суммы на балансе недостаточно для проведения оплат, попробуйте обновить страницу'));

    return false;
  }

  return true;
};

export const checkTerminalInfoIsLoaded = () => (dispatch) => {
  const terminalInfo = getTerminalInfo();

  if (terminalInfo === null) {
    dispatch(showError('Не удалось загрузить информацию о терминале, попробуйте обновить страницу'));

    return false;
  }

  return true;
};

export const checkCommissionProfilesIsLoaded = (commissionProfiles) => (dispatch) => {
  if (commissionProfiles.length) {
    return true;
  }
  dispatch(showError('Пожалуйста подождите, профили комиссий еще не загрузились'));

  return false;
};

const checkFiscalData = (props) => (dispatch) => dispatch(checkKkmStatus(props))
  && dispatch(checkShiftStatus(props))
  && dispatch(checkSections(props))
  && dispatch(checkFiscalToken());

const checkKkmStatus = (props) => (dispatch) => {
  const { isFiscal, isKkmBlocked, blockReason } = props;

  if (!isFiscal) return true;

  if (isFiscal && !isKkmBlocked) return true;

  dispatch(showError(blockReason));

  return false;
};

const checkShiftStatus = (props) => (dispatch) => {
  const { isFiscal, isAuthInFiscal, kkmInfo: { Lock, IdShift } } = props;
  const shiftStatus = getShiftStatus(Lock, IdShift);

  if (!isFiscal) return true;

  if (!isAuthInFiscal) {
    dispatch(showError('Обратитесь в ТП', 'Не удалось авторизоваться в фискале'));

    return false;
  }

  if (!shiftStatus) {
    dispatch(showError('Информация о смене загружается', 'Пожалуйста подождите...'));

    return false;
  }

  if (shiftStatus === 'Закрыта') {
    dispatch(showError('Необходимо открыть смену, чтобы продолжить работу с платежами', 'Смена закрыта'));

    return false;
  }

  if (shiftStatus === 'Смена открыта другим кассиром') {
    dispatch(showError(
      'Нельзя продолжить работу с кассой, пока за ней работает другой кассир',
      'Смена открыта другим кассиром'
    ));

    return false;
  }

  if (Lock) {
    if (isTheShiftActive()) return true;

    dispatch(showError('Закройте текущую смену и откройте новую', 'Смена просрочена'));

    return false;
  }

  return true;
};

const checkSections = (props) => (dispatch) => {
  const { isFiscal } = props;
  const isValid = checkSectionsOnValid(isFiscal);

  if (isValid) return true;

  dispatch(showError('Для работы с платежами необходимо как минимум две фискальные секции'));

  return false;
};
