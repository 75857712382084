import * as types from './types';

export const showError = (message, title = '') => (dispatch) => {
  dispatch({ type: types.ALERT_ERROR, message, title });
};

export const showSuccess = (message) => (dispatch) => {
  dispatch({ type: types.ALERT_SUCCESS, message });
};

export const showCashOutInfo = (message = '') => (dispatch) => {
  dispatch({ type: types.ALERT_CASHOUT_INFO, message });
};

export const showWarning = (message, title = '') => (dispatch) => {
  dispatch({ type: types.ALERT_WARNING, message, title });
};
