import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getServiceLogo } from 'helpers/pay';

import ServiceBlock from './ServiceBlock';

export default class GroupServices extends Component {
  getServices() {
    const { serviceByGroups, activeServiceId, selectService } = this.props;

    if (typeof serviceByGroups !== 'undefined') {
      return serviceByGroups.map((service) => (
        <span className="service-block-wrapper" key={service.idService}>
          <ServiceBlock
            name={service.name}
            logo={getServiceLogo(service.logo)}
            selectService={() => selectService(service)}
            active={activeServiceId === service.idService}
          />
        </span>
      ));
    }
  }

  render() {
    return (
      <span className="group-block-wrapper">
        {this.getServices()}
      </span>
    );
  }
}

GroupServices.propTypes = {
  serviceByGroups: PropTypes.array,
  selectService: PropTypes.func,
  activeServiceId: PropTypes.number,
};

GroupServices.defaultProps = {
  serviceByGroups: [],
  selectService: null,
  activeServiceId: null

};
