
export const allServicesStruct = [
  { title: 'idService', unique: true },
  { title: 'type', unique: false },
  { title: 'name', unique: false },
  { title: 'rank', unique: false },
  { title: 'minLength', unique: false },
  { title: 'maxLength', unique: false },
  { title: 'logo', unique: false },
  { title: 'checkOnline', unique: false },
  { title: 'typeInterface', unique: false },
  { title: 'regExp', unique: false },
  { title: 'maskEdit', unique: false },
  { title: 'grbut', unique: false },
  { title: 'grname', unique: false },
  { title: 'idsg', unique: false },
  { title: 'description', unique: false },
  { title: 'tag', unique: false },
  { title: 'isEMoney', unique: false },
  { title: 'rounding', unique: false },
];

export const commissionProfilesStruct = [
  { title: 'am', unique: false },
  { title: 'id', unique: true },
  { title: 'name', unique: false },
  { title: 'positions', unique: false },
  { title: 'pr', unique: false },
  { title: 'tKz', unique: false },
  { title: 'tRU', unique: false },
];

export const receiptWithCodeStruct = [
  { title: 'sn', unique: true },
  { title: 'checks', unique: false },
  { title: 'code', unique: false },
  { title: 'idService', unique: false },
  { title: 'isPaymentSuccess', unique: false },
];

export const fiscalReceiptsStruct = [
  { title: 'sn', unique: true },
  { title: 'checkData', unique: false },
  { title: 'clientIin', unique: false },
  { title: 'idService', unique: false },
  { title: 'payType', unique: false },
];
