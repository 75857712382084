import React from 'react';
import PropTypes from 'prop-types';

const MypostSearchType = ({ setSearchType }) => (
  <div className="pay-form">
    <div className="form-group">
      <button
        type="button"
        className="btn btn-primary large mypost-btn"
        onClick={() => setSearchType(1)}
      >
        По номеру заказа
      </button>
    </div>
    <div className="form-group">
      <button
        type="button"
        className="btn btn-primary large mypost-btn"
        onClick={() => setSearchType(2)}
      >
        По номеру сотового телефона
      </button>
    </div>
  </div>
);

MypostSearchType.propTypes = {
  setSearchType: PropTypes.func.isRequired
};

export default MypostSearchType;

