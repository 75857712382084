import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import createRootReducer from 'reducers';
import logs from 'middlewares/logs';
import AxiosInterceptors from 'helpers/axios';
import gatewayRequest from 'middlewares/gatewayRequest';
import initialDataLoader from 'middlewares/initialDataLoader';

export const history = createBrowserHistory();

const isDev = process.env.NODE_ENV === 'development';
const router = routerMiddleware(history);
const enhancers = applyMiddleware(thunk, router, gatewayRequest, initialDataLoader, logs);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
  : compose;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const configureStore = (initialState) => createStore(
  createRootReducer(history),
  initialState,
  isDev ? composeEnhancers(...[enhancers]) : compose(enhancers, sentryReduxEnhancer)
);

AxiosInterceptors.setupInterceptors(configureStore());

export default { configureStore, history };
