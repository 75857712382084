import * as types from 'actions/alert/types';

const defaultState = {
  successText: '',
  errorText: '',
  warningText: '',
  cashOutInfoText: '',
  title: '',
  showAlertModal: false
};

const alert = (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.ALERT_SUCCESS:
      return {
        ...state,
        showAlertModal: true,
        successText: action.message || 'Операция выполнена успешно',
      };
    case types.ALERT_ERROR:
      return { ...state, errorText: action.message, title: action.title, showAlertModal: true };
    case types.ALERT_WARNING:
      return { ...state, warningText: action.message, title: action.title, showAlertModal: true };
    case types.ALERT_CASHOUT_INFO:
      return {
        ...state,
        cashOutInfoText: action.message,
        showAlertModal: true
      };
    case types.ALERT_INIT:
      return defaultState;
    default:
      return state;
  }
};

export default alert;
