/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Modal from 'components/UI/Modal/modal';
import ReactCodeInput from 'react-code-input';
import { getAuthData } from 'model/UserDb';
import { FISCAL_SCREENS } from 'constants/fiscal';
import { openShift } from 'actions/fiscal';
import { getFiscalData } from 'helpers/fiscal';
import moment from 'moment';
import { getObjectElement, getTheEndOfTheCurrentDay } from 'helpers/main';
import { getEncashmentList as getEncashmentListCurrent } from 'actions/encashment';
import FiscalNotSendedEncashment from 'containers/Fiscal/FiscalNotSendedEncashment';

const PinCodeModal = (props) => {
  const codeRef = useRef(undefined);
  const [forPinCodeRerender, setForPinCodeRerender] = useState(true);
  const [pinCode, setPinCode] = useState('');
  const [isPinCodeValid, setIsPinCodeValid] = useState(true);
  const [isEncashmentNotSendedToFiscal, setIsEncashmentNotSendedToFiscal] = useState(false);
  const { idTerminal } = getAuthData();
  const {
    isFiscal,
    typeOperation,
    encashmentList,
    xReport,
    showPinCodeModal,
    setShowPinCodeModal,
    setShowConfirmCloseShift,
    showOpenShiftSuccesMsg,
    resetTypeOperation,
    getEncashmentList
  } = props;

  // check fiscal encashment list
  useEffect(() => {
    if (typeOperation === FISCAL_SCREENS.CLOSE_SHIFT && isFiscal) {
      const { userData: { DateShiftOpen } } = getFiscalData();

      getEncashmentList(moment(DateShiftOpen).format('YYYYMMDDHHmmss'), getTheEndOfTheCurrentDay());
    }
  }, [typeOperation]);

  // check AP encashment list
  useEffect(() => {
    if (typeOperation === FISCAL_SCREENS.CLOSE_SHIFT) {
      let isConsistDublicateSumms = false;
      const fiscalEnashmentList = xReport?.Operations?.filter(
        (ops) => ops.TypeDocument.Id === FISCAL_SCREENS.OUTCOME);

      for (let i = 0; i < encashmentList.length; i++) {
        if (i > 0 &&
          encashmentList[i - 1].bills.b1 === getObjectElement(encashmentList, i).bills.b1) {
          isConsistDublicateSumms = true;
        }
      }

      if (fiscalEnashmentList && encashmentList &&
       fiscalEnashmentList.length < encashmentList.length && !isConsistDublicateSumms) {
        setIsEncashmentNotSendedToFiscal(true);
      } else {
        setIsEncashmentNotSendedToFiscal(false);
      }
    }
  }, [xReport, encashmentList, showPinCodeModal, isFiscal]);

  useEffect(() => {
    const clearPinCodeInputs = () => {
      if (typeof codeRef.current !== 'undefined' && codeRef.current && !pinCode) {
        if (codeRef.current.textInput[0]) codeRef.current.textInput[0].focus();
        codeRef.current.state.input[0] = '';
        codeRef.current.state.input[1] = '';
        codeRef.current.state.input[2] = '';
        codeRef.current.state.input[3] = '';
        codeRef.current.state.input[4] = '';
        codeRef.current.state.input[5] = '';

        setForPinCodeRerender(v => !v); // This will force you component to re-render
      }
    };

    clearPinCodeInputs();
  }, [pinCode]);

  const validatePinCode = useCallback(
    () => {
      const isValid = pinCode === idTerminal;

      setIsPinCodeValid(isValid);
      if (isValid) {
        if (typeOperation !== FISCAL_SCREENS.CLOSE_SHIFT) {
          props.openShift(showOpenShiftSuccesMsg);
        } else {
          setShowConfirmCloseShift(true);
        }

        resetTypeOperation();
        setShowPinCodeModal(false);
        setPinCode('');
      } else {
        setPinCode('');
      }
    },
    [typeOperation, pinCode]
  );

  const closeFiscalEncashmentWarningModal = useCallback(() => {
    setShowPinCodeModal(false);
    setIsEncashmentNotSendedToFiscal(false);
  }, []);

  if (isFiscal && showPinCodeModal && typeOperation === FISCAL_SCREENS.CLOSE_SHIFT
    && isEncashmentNotSendedToFiscal) {
    return <FiscalNotSendedEncashment onClose={closeFiscalEncashmentWarningModal}/>;
  }

  return (
    <Modal
      title="Введите пароль"
      isOpen={showPinCodeModal}
      onOk={validatePinCode}
      onClose={() => {
        setShowPinCodeModal(false);
        setPinCode('');
      }}
      okText="Далее"
      size="small"
      type="pin-code"
    >
      <div className="pin-code-modal-body">
        <input type="password" style={{ display: 'none' }} />
        <ReactCodeInput
          forPinCodeRerender={forPinCodeRerender}
          ref={codeRef}
          id="pinCode"
          type="text"
          isValid={isPinCodeValid}
          fields={idTerminal.length}
          onChange={setPinCode}
          value={pinCode}
        />
        <br />
        <label htmlFor="pinCode" style={{ color: '#ca4040' }}>
          {isPinCodeValid ? '' : 'Неверный пароль'}
        </label>
      </div>
    </Modal>
  );
};

PinCodeModal.propTypes = {
  typeOperation: PropTypes.number.isRequired,
  showPinCodeModal: PropTypes.bool.isRequired,
  setShowPinCodeModal: PropTypes.func.isRequired,
  openShift: PropTypes.func.isRequired,
  setShowConfirmCloseShift: PropTypes.func.isRequired,
  resetTypeOperation: PropTypes.func.isRequired,
  showOpenShiftSuccesMsg: PropTypes.bool,
  getEncashmentList: PropTypes.func.isRequired,
  encashmentList: PropTypes.array.isRequired,
  xReport: PropTypes.object.isRequired,
  isFiscal: PropTypes.bool.isRequired,
};

PinCodeModal.defaultProps = {
  showOpenShiftSuccesMsg: true
};

function mapStateToProps(state) {
  return {
    xReport: state.fiscal.xReport,
    encashmentList: state.encashment.encashmentList,
    isFiscal: state.fiscal.isFiscal,
  };
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  openShift,
  getEncashmentList: getEncashmentListCurrent
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PinCodeModal);
