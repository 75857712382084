import PayTypes from 'paytypes';

import { showError } from 'actions/alert';
import { resetAuthData } from 'model/UserDb';
import { goToEndSessionPage, goToLoginPage } from 'actions/navigate';
import { SERVICE_LIST_REQUEST_WITH_ERROR } from 'actions/services/types';
import { COMMISION_PROFILES_REQUESTED_WITH_ERROR } from 'actions/commission/types';
import { refreshJwtToken, sendXmlReq, sendJsonReq, isTokenAlive } from 'helpers/api';
import { PAYMENT_HISTORY_WITH_ERROR, BALANCE_REQUEST_WITH_ERROR } from 'actions/api/types';
import { logErrorToSentry, logWarningToSentry, isNull, setSentryBreadсrumbs } from 'helpers/main';
import { setInitialRequestCompleted } from 'actions/initialDownload';
import { stopTheLoaderPayment, startTheLoaderPayment } from 'actions/loader';

const { startLoader, stopLoader } = PayTypes.actions.handlers;

const ACTIONS_WITHOUT_LOADER = [
  'SET_ONLINE_CHECK_CODE_JSON_REQUEST',
  'CHECK_PAYMENT_XML_REQUEST',
  'SEND_LOG_JSON_REQUEST',
  'SEND_CHECK_TO_GATEWAY_JSON_REQUEST',
  'MAKE_PAY_XML_REQUEST'
];
const ACTION_WITH_PAYMENT_LOADER = ['MAKE_PAY_XML_REQUEST'];

// Запросы для которых не отображаем окно с ошибкой
const REQUESTS_WITHOUT_ERROR_MSG = ['logException'];

export const XML_REQUEST = 'XML_REQUEST';
export const JSON_REQUEST = 'JSON_REQUEST';

const ERROR_DISPATCH = {
  8: BALANCE_REQUEST_WITH_ERROR,
  9: SERVICE_LIST_REQUEST_WITH_ERROR,
  10: COMMISION_PROFILES_REQUESTED_WITH_ERROR,
  32: PAYMENT_HISTORY_WITH_ERROR,
};

export default (store) => (next) => async (action) => {
  const { dispatch } = store;

  if (!action) return next(action);

  const { type, payload } = action;

  if (typeof type !== 'undefined') {
    if (!isItGatewayApiRequest(type, payload)) {
      return next(action);
    }

    const { reqType, xml, json, successCallback, errorCallback } = payload;

    checkActionTypeAndStartLoader(action, dispatch, store);

    let reqStatus = 0;
    let loaderWasStoped = false;

    try {
      const { result, loaderWasStopped } = await sendRequest({
        type,
        reqType,
        xml,
        json,
        action,
        loaderWasStoped,
        dispatch,
        store
      });

      reqStatus = checkReqStatusAndRunCallback(result, successCallback);
      loaderWasStoped = checkToLoadStop(loaderWasStopped, action, dispatch);
    } catch (error) {
      dispatchErrorMessage(reqType, action, loaderWasStoped, dispatch, store);

      const { message } = error;

      switch (message) {
        case 'Время вышло': break;
        case 'Вы не авторизованы': {
          logWarningToSentry('Вы не авторизованы (middleware');
          resetAuthData();
          dispatch(goToLoginPage());
          break;
        }
        default: {
          logErrorToSentry(message, reqType, reqStatus, type);
          storeCatchSwitchDefault(reqType, message, errorCallback, dispatch);
        }
      }
    }

    await next(action);
  }
};

const getErrorMsg = ({ isPaymentRequest, message }) => {
  if (isPaymentRequest && message.includes('Request failed with status code 502')) {
    return 'Платёжная система не отвечает. Нажмите кнопку "Оплатить" ещё раз. Если ошибка повторится, то обратитесь в техническую поддержку';
  }

  if (isPaymentRequest && message.includes('Request failed with status code 504')) {
    return 'Платёжная система временно не работает. Попробуйте провести платёж позднее или обратитесь в техническую поддержку.';
  }

  if (message.includes('Request failed with status code')) {
    return 'Ошибка запроса, повторите попытку позже';
  }

  if (message === 'Network Error') {
    return 'Нет связи, проверьте соединение с интернетом';
  }

  return message;
};

const isItGatewayApiRequest = (type, payload) => {
  if (!type.includes(XML_REQUEST) && !type.includes(JSON_REQUEST)) return false;

  return !!payload;
};

const checkActionTypeAndStartLoader = (action, dispatch, store) => {
  const { getState } = store;
  const {
    loader: { showLoaderPayment }
  } = getState();

  if (!ACTIONS_WITHOUT_LOADER.includes(action.type) && !showLoaderPayment) {
    dispatch(startLoader());
  } else if (ACTION_WITH_PAYMENT_LOADER.includes(action.type)) {
    dispatch(startTheLoaderPayment());
  }
};

const checkToLoadStop = (loaderWasStoped, action, dispatch) => {
  let loadStop = loaderWasStoped;

  if (!ACTIONS_WITHOUT_LOADER.includes(action.type) && !loaderWasStoped) {
    loadStop = true;
    dispatch(stopLoader());
  }

  return loadStop;
};

const checkReqStatusAndRunCallback = (data, successCallback) => {
  let reqStatus = 0;

  if (typeof data !== 'undefined') {
    const { body, status } = data;

    if (typeof status !== 'undefined') {
      reqStatus = status;
    }

    if (typeof body !== 'undefined' && body !== null) {
      if (successCallback) successCallback(body);
    }
  }

  return reqStatus;
};

const sendRequest = async ({
  type,
  reqType,
  xml,
  json,
  action,
  loaderWasStoped,
  dispatch,
  store
}) => {
  const { getState } = store;
  const {
    loader: { showLoaderPayment }
  } = getState();
  let result = {};
  let loaderWasStopped = loaderWasStoped;

  if (isTokenAlive()) {
    await sendGatewayRequest(type, reqType, xml, json)
      .then((res) => {
        result = res;
        setInitialRequestCompleted(reqType, dispatch);
      });
  } else {
    const token = await refreshJwtToken();

    if (token) {
      await sendGatewayRequest(type, reqType, xml, json)
        .then((res) => {
          result = res;
          setInitialRequestCompleted(reqType, dispatch);
        });
    } else if (isNull(token)) {
      if (!ACTIONS_WITHOUT_LOADER.includes(action.type)) {
        loaderWasStopped = true;
        dispatch(stopLoader());
      } else if (showLoaderPayment) {
        dispatch(stopTheLoaderPayment());
      }

      dispatch(goToEndSessionPage());
    }
  }

  return { loaderWasStopped, result };
};

const sendGatewayRequest = async (type, reqType, xml, json) => {
  let data = {};

  setSentryBreadсrumbs('sendGatewayRequest', `sendGatewayRequest - type: ${type}. reqType: ${reqType}`);

  if (type.includes(XML_REQUEST)) {
    data = await sendXmlReq(reqType, xml);
  } else if (type.includes(JSON_REQUEST)) {
    data = await sendJsonReq(reqType, json);
  }

  return data;
};

const dispatchErrorMessage = (reqType, action, loaderWasStoped, dispatch, store) => {
  const { getState } = store;
  const {
    loader: { showLoaderPayment }
  } = getState();

  if (reqType in ERROR_DISPATCH) {
    dispatch({ type: ERROR_DISPATCH[reqType.toString()], status: true });
  }

  if (!ACTIONS_WITHOUT_LOADER.includes(action.type) && !loaderWasStoped) {
    dispatch(stopLoader());
  } else if (showLoaderPayment) {
    dispatch(stopTheLoaderPayment());
  }
};

const storeCatchSwitchDefault = (reqType, message, errorCallback, dispatch) => {
  if (!REQUESTS_WITHOUT_ERROR_MSG.includes(reqType)) {
    const errMsg = getErrorMsg({ isPaymentRequest: reqType === 3, message });

    if (typeof errorCallback !== 'undefined') {
      errorCallback(errMsg);
    }
    dispatch(showError(errMsg));
  }
};
