import * as types from 'actions/encashment/types';

const defaultState = {
  amount: 0,
  isRequested: false,
  status: false,
  encashmentList: [],
  isEncashmentSendedToAP: false,
  isEncashmentSendedToFiscal: false,
};

const encashment = (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_ENCASHMENT_AMOUNT:
      return { ...state, amount: action.amount, isRequested: true };
    case types.SET_ENCASHMENT_LIST:
      return { ...state, encashmentList: action.encashmentList };
    case types.SET_ENCASHMENT_SENDED_TO_AP:
      return { ...state, isEncashmentSendedToAP: true };
    case types.SET_ENCASHMENT_SENDED_TO_FISCAL:
      return { ...state, isEncashmentSendedToFiscal: true };
    case types.INIT_ENCASHMENT_DATA:
      return {
        ...state,
        amount: 0,
        isRequested: false,
        status: false,
        encashmentList: [],
      };
    case types.INIT_ENCASHMENT_STATUSES:
      return { ...state, isEncashmentSendedToAP: false, isEncashmentSendedToFiscal: false };
    default:
      return state;
  }
};

export default encashment;
