import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-maskedinput';
import { getMaskForInput } from 'helpers/main';

const Field = ({ placeholder, name, mask, handleOnChange, value, disabled }) => {
  const maskNew = getMaskForInput(mask);

  return (
    <div className="form-group" key={name}>
      <MaskedInput
        mask={maskNew}
        className="form-control"
        autoComplete="off"
        name={name}
        id={name}
        maxLength="40"
        onChange={(e) => handleOnChange({ id: e.target.id, value: e.target.value })}
        type={(mask && mask.includes('9')) ? 'tel' : 'text'}
        placeholder={placeholder}
        placeholderChar=" "
        value={value}
        formatCharacters={{
          W: {
            validate(char) { return /[\wа-яА-Я\-@.]/.test(char); },
          }
        }}
        disabled={disabled}
      />
    </div>
  );
};

Field.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  mask: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Field;
