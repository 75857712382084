/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/button';
import Icon from '../Icon';
import './dropdown.scss';

const NewBlock = () => <div className="dropdown-new">NEW</div>;

const Dropdown = ({ title, menuItems, clickOnMenuItem, isNew }) => (
  <div className="dropdown-wrapper">
    <Button>
      {title}
      {isNew && <NewBlock />}
      <Icon type="arrowBottom" />
    </Button>
    <div className="dropdown-list">
      {menuItems.map(({ name, key, classname, isItemNew, href }) => {
        let itemBlock = '';

        if (href) {
          itemBlock = (
            <a
              key={key}
              href={href}
              className="dropdown-item"
              target="_blank"
              rel="noopener noreferrer"
            >
              {name} {isItemNew && <NewBlock />}
            </a>
          );
        } else {
          itemBlock = (
            <button
              type="button"
              key={key}
              onClick={() => clickOnMenuItem(key)}
              className={`buttonWithoutStyles dropdown-item ${classname || ''} `}
            >
              {name} {isItemNew && <NewBlock />}
            </button>
          );
        }

        return itemBlock;
      })}
    </div>
  </div >
);

Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired,
  clickOnMenuItem: PropTypes.func.isRequired,
  isNew: PropTypes.bool,
};

Dropdown.defaultProps = {
  isNew: false,
};

export default Dropdown;
