/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getReceiptFromIDB } from 'model/RecepitWithCode';
import { useLoader } from 'hooks';

const MAX_RETRY_COUNT = 10;

/* отправляем чеки в АПИ, когда платеж успешно обработан */
const useCheckWithCode = ({
  saveCheck,
  checksWithCode,
  removeCheckWithCode,
  setChecksWithCodeInProps,
  updateCheckWithCode,
  paymentsList,
  getCode,
}) => {
  const [counter, setCounter] = useState(0);
  const [wasLoaderStarted, setWasLoaderStarted] = useState(false);
  const checkObjLength = useMemo(() => Object.keys(checksWithCode).length, [checksWithCode]);
  const hasSuccessPayment = useMemo(() => // ищем обработанные платежи
    Object.values(checksWithCode).filter((item) => item.isPaymentSuccess).length,
  [checksWithCode]);
  const { runTheLoader, stopTheLoader } = useLoader();

  useEffect(() => {
    if (checkObjLength) {
      setWasLoaderStarted(true);
      runTheLoader();
      runTheLoader();
    }
  }, [checkObjLength]);

  useEffect(() => {
    if (!checkObjLength && wasLoaderStarted) {
      setWasLoaderStarted(false);
      stopTheLoader();
      stopTheLoader();
    }
  }, [checkObjLength, wasLoaderStarted]);

  // пишем в пропсы из idb
  useEffect(() => {
    if (!checkObjLength) {
      getReceiptFromIDB()
        .then((receipts) => {
          if (receipts.length) {
            receipts.map((data) => setChecksWithCodeInProps(data));
          }
        });
    }
  }, [checkObjLength]);

  useEffect(() => {
    if (paymentsList.length && checkObjLength) {
      Object.keys(checksWithCode).forEach(sn => {
        const findPayment = paymentsList.find(payment => payment.sn.toString() === sn.toString());

        if (findPayment && !checksWithCode[sn.toString()].isPaymentSuccess) {
          if (findPayment.status === 0) {
            updateCheckWithCode(sn, { ...checksWithCode[sn.toString()], isPaymentSuccess: true });
          } else if (findPayment.status !== 919) { // в обработке
            saveCheck({
              checkData: checksWithCode[sn.toString()].checks,
              showPrintWindowAfterSave: true,
              sn,
              successCallback: () => removeCheckWithCode(sn)
            });
          }
        }

        return findPayment;
      });
    }
  }, [checksWithCode, checkObjLength, paymentsList]);

  //  запрашиваем код для успешных транзакций
  const getCodeHandler = useCallback(() => {
    if (checkObjLength && counter < MAX_RETRY_COUNT) {
      Object.entries(checksWithCode).forEach(([sn, receipt]) => {
        if (receipt.isPaymentSuccess) {
          getCode(`getCode|${sn}`, receipt.idService);
          setCounter(counter + 1);
        }
      });
    } else if (checkObjLength && counter >= MAX_RETRY_COUNT) {
      Object.keys(checksWithCode).forEach(sn => {
        removeCheckWithCode(sn);
      });
    }
  }, [checksWithCode, checkObjLength, counter]);

  return { hasSuccessPayment, getCodeHandler };
};

useCheckWithCode.propTypes = {
  checksWithCode: PropTypes.object.isRequired,
};

export default useCheckWithCode;
