import { Grid } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import 'moment/locale/ru';

import 'flatpickr/dist/flatpickr.css';
import { Russian } from 'flatpickr/dist/l10n/ru';
import CustomButton from 'components/UI/Button/button';

const SearchContainer = ({ dateFrom, dateTo, changeDateRange, searchZReport }) => (
  <Grid item sm={6} xs={12}>
    <Grid container spacing={2}>
      <Grid item sm={8} xs={12}>
        <Flatpickr
          options={{
            dateFormat: 'd-m-Y',
            mode: 'range',
            locale: Russian,
            defaultDate: [
              moment(dateFrom, 'YYYYMMDDHHmmss').format('DD-MM-YYYY'),
              moment(dateTo, 'YYYYMMDDHHmmss').format('DD-MM-YYYY'),
            ],
          }}
          className="datepicker-wrapper"
          onChange={(dates) => changeDateRange(dates)}
          style={{ margin: '0', width: '100%' }}
        />
      </Grid>
      <Grid item sm={1} xs={12}>
        <CustomButton
          onClick={searchZReport}
          className="custom-button-background"
        >
          Поиск
        </CustomButton>
      </Grid>
    </Grid>
  </Grid>
);

SearchContainer.propTypes = {
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired,
  changeDateRange: PropTypes.func.isRequired,
  searchZReport: PropTypes.func.isRequired
};

export default SearchContainer;
