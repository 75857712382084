/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import useStyles from './styles';

/**
 * options - список опшнов
 * onchange - событие выбора значения в селекте
 * title - текст, отображаемый когда никакой из опшнов не выбран
 * selectedValue - выбранное значение
 * selectedKey - ключ выбранного значения в options
 */

const Select = ({ options, onchange, title, error, selectedValue }) => {
  const classes = useStyles();
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});

  useEffect(() => {
    const newOpts = [];

    if (options) {
      options.map((item) => newOpts.push({ label: item.value, value: item.key }));
      setStateOptions(newOpts);
    }
  }, [options]);

  useEffect(() => {
    if (selectedValue) setSelectedOption(selectedValue);
  }, [selectedValue]);

  const selectItem = (selectedItem) => {
    if (selectedItem && selectedItem.value) {
      setSelectedOption({ value: selectedItem.value, label: selectedItem.label });
      onchange(selectedItem.value);
    } else {
      setSelectedOption({});
      onchange();
    }
  };

  return (
    <div className={classes.select}>
      <ReactSelect
        value={selectedOption?.value ? selectedOption : null}
        isClearable="true"
        isSearchable="true"
        label={title}
        options={stateOptions}
        onChange={selectItem}
        placeholder={title}
        noOptionsMessage={() => 'Ничего не найдено'}
        className={error ? classes.error : ''}
        menuPosition="fixed"
      />
    </div>
  );
};

Select.defaultProps = {
  error: false,
  selectedValue: null
};

Select.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  onchange: PropTypes.func.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func,
  ]).isRequired,
  error: PropTypes.bool,
  selectedValue: PropTypes.object
};

export default Select;
