import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';

export default class PenaltiesFound extends Component {
  state = {
    rowsPerPage: 5,
    page: 0
  }

  handleChangePage = (_, page) => this.setState({ page });

  render() {
    const { dataSource, selectedPenaltyKey, selectPenalty } = this.props;
    const { rowsPerPage, page } = this.state;
    const columns = [{
      title: 'Номер бланка',
      dataIndex: 'blankNumber',
      key: 'blankNumber',
    }, {
      title: 'Место происшествия',
      dataIndex: 'place',
      key: 'place',
    }, {
      title: 'Сумма штрафа',
      dataIndex: 'amount',
      key: 'amount',
    }, {
      title: 'КНО',
      dataIndex: 'kno',
      key: 'kno',
    }];

    return (
      <div className="pay-form">
        <TableContainer component={Paper} elevation={0}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />
                {columns.map((column) => (
                  <TableCell key={column.key}>{column.title}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                dataSource
                  .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                  .map((data) => (
                    <TableRow key={dataSource.key}>
                      <TableCell >
                        <input
                          type="radio"
                          value={data.key}
                          onChange={(e) => selectPenalty(e.target.value)}
                          className="panalties-radioBtn"
                          checked={selectedPenaltyKey !== '' ? +selectedPenaltyKey === data.key : false}
                        />
                      </TableCell>
                      <TableCell >{data.blankNumber}</TableCell>
                      <TableCell >{data.place}</TableCell>
                      <TableCell >{data.amount}</TableCell>
                      <TableCell >{data.kno}</TableCell>
                    </TableRow>
                  ))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <div className="penalties-pagination">
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={dataSource.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={this.handleChangePage}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
            labelRowsPerPage=""
          />
        </div>
      </div>
    );
  }
}

PenaltiesFound.propTypes = {
  dataSource: PropTypes.array.isRequired,
  selectedPenaltyKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  selectPenalty: PropTypes.func.isRequired,
};
