export const SET_ADDING = 'SET_ADDING';
export const PAYMENT_INIT = 'PAYMENT_INIT';
export const SET_CLIENT_IIN = 'SET_CLIENT_IIN';
export const SET_CONFIRM_INFO = 'SET_CONFIRM_INFO';
export const DISPATCH_INFO_LOG = 'DISPATCH_INFO_LOG';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const UPDATE_FIELD_VALUE = 'UPDATE_FIELD_VALUE';
export const UPDATE_FIELDS_VALUE = 'UPDATE_FIELDS_VALUE';
export const SHOW_CONFIRM_SCREEN = 'SHOW_CONFIRM_SCREEN';
export const SET_SENDED_TO_FISCAL = 'SET_SENDED_TO_FISCAL';
export const SET_DEPOSITED_AMOUNT = 'SET_DEPOSITED_AMOUNT';
export const SHOW_PAY_TYPE_SCREEN = 'SHOW_PAY_TYPE_SCREEN';
export const SET_TRANSACTION_NUMBER = 'SET_TRANSACTION_NUMBER';
export const CLEAR_ACCOUNT_AND_AMOUNT = 'CLEAR_ACCOUNT_AND_AMOUNT';
export const SHOW_ENTER_AMOUNT_SCREEN = 'SHOW_ENTER_AMOUNT_SCREEN';
export const UPDATE_MIN_SERVICE_AMOUNT = 'UPDATE_MIN_SERVICE_AMOUNT';
export const UPDATE_MAX_SERVICE_AMOUNT = 'UPDATE_MAX_SERVICE_AMOUNT';
export const SET_PAYMENT_COUNTER_VALUE = 'SET_PAYMENT_COUNTER_VALUE';
export const SET_WILL_BE_CREDITED_AMOUNT = 'SET_WILL_BE_CREDITED_AMOUNT';
export const SET_OTHER_ID_SERVICE_FOR_MAKE_PAY = 'SET_OTHER_ID_SERVICE_FOR_MAKE_PAY';
export const SELECTED_KASPI_IN_MODAL = 'SELECTED_KASPI_IN_MODAL';
export const SET_LAST_PAYMENT_DATA = 'SET_LAST_PAYMENT_DATA';
export const CONFIRM_SCREEN_CLICK_ENTER = 'CONFIRM_SCREEN_CLICK_ENTER';
