import * as types from './types';

// Итоговый отчет
export const getSummaryReport = (dateFrom, dateTo) => (dispatch) => {
  const xml =
    `<report>
      <dateB>${dateFrom}</dateB>
      <dateE>${dateTo}</dateE>
    </report>`;

  const successCallback = (data) => {
    const { report } = data;

    if (typeof report !== 'undefined') {
      dispatch({ type: types.SET_SUMMARY_REPORT_DATA, summaryReport: report });
    }
  };

  dispatch({
    type: `${types.SET_SUMMARY_REPORT_DATA}_XML_REQUEST`,
    payload: { reqType: 28, xml, successCallback }
  });
};

// Итоговый отчет по выводу средств
export const getCashoutReport = (dateFrom, dateTo, account = '') => (dispatch) => {
  const json = { dateFrom, dateTo };

  if (account) json.account = account;

  const successCallback = (data) => {
    const { report } = data;

    if (typeof report !== 'undefined' && report !== '') {
      dispatch({ type: types.SET_CASHOUT_REPORT_DATA, cashoutReport: report });
    }
  };

  dispatch({
    type: `${types.SET_CASHOUT_REPORT_DATA}_JSON_REQUEST`,
    payload: { reqType: 59, json, successCallback },
  });
};

