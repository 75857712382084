import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setPaymentMethod } from 'actions/pay';
import { isPayByCardEnabled } from 'helpers/pay';

const PaymentMethod = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (!isPayByCardEnabled()) setPaymentMethodHandler(0);
  }, []);

  const setPaymentMethodHandler = (value) => {
    setSelectedOption(Number(value));
    props.setPaymentMethod(Number(value));
  };

  return (
    <div className="payment-method">

      <div className="radio">
        <label htmlFor="1" className="radio-label">
          <input
            id="1"
            type="radio"
            value={0}
                // eslint-disable-next-line eqeqeq
            checked={selectedOption == 0}
            onChange={(e) => setPaymentMethodHandler(e.target.value)}
            className="radio-label-input"
          />{'  '}
          Наличная оплата
        </label>
      </div>
      {isPayByCardEnabled() && (
      <div className="radio">
        <label htmlFor="2" className="radio-label">
          <input
            id="2"
            type="radio"
            value={1} // eslint-disable-next-line eqeqeq
            checked={selectedOption == 1}
            onChange={(e) => setPaymentMethodHandler(e.target.value)}
            className="radio-label-input"
          />{'  '}
          Безналичная оплата
        </label>
      </div>
      )}

    </div>
  );
};

PaymentMethod.propTypes = {
  setPaymentMethod: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setPaymentMethod }, dispatch);
}

export default connect(null, mapDispatchToProps)(PaymentMethod);
