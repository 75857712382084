
import * as types from 'actions/notification/types';

const defaultState = {
  cashierNotifications: []
};

const notification = (state = defaultState, action = {}) => {
  if (action.type === types.SET_NOTIFICATIONS) {
    return {
      ...state,
      cashierNotifications: action.notifList,
    };
  }

  return state;
};

export default notification;
