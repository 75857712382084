import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MaskedInput from 'react-maskedinput';
import React, { useState, useEffect, useCallback } from 'react';

import IinField from 'components/IinField';
import { saveCheck } from 'actions/check';
import { showError } from 'actions/alert';
import Modal from 'components/UI/Modal/modal';
import { doFiscalCashout } from 'actions/fiscal';
import { iinIsValidOrEmptyFunc } from 'helpers/pay';
import CashOutIcon from 'public/images/cashout.svg';
import { CASHOUT_SCREENS } from 'constants/payTypes';
import { onlineCheck } from 'actions/api/onlineCheck';
import { formatMoney, getClearPhone, getCurrentDate } from 'helpers/main';
import { generateCheckDataForAP, generateCheckDataForFiscal } from 'helpers/check';
import { setShowCashOutModal, setStep, setConfirmed } from 'actions/payTypes/cashout';
import { getFiscalData, getReqDataFoFiscal, getFiscalSectionsOnPos } from 'helpers/fiscal';

const CashOut = (props) => {
  const {
    cashOnFiscalBalance,
    CashToTerminalAmount,
    CashToTerminalId,
    currentService,
    sectionForPayment,
    AgentName,
    confirmed,
    isFiscal,
    kkmInfo,
    step,
  } = props;
  const [phone, setPhone] = useState('');
  const [smsCode, setSmsCode] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [phoneError, setPhoneError] = useState('');
  const [isSmsCodeValid, setIsSmsCodeValid] = useState(true);
  const { sectionIdForPayment } = getFiscalData();

  useEffect(() => {
    if (phone) {
      setIsPhoneValid(true);
      setPhoneError('');
    }
    if (smsCode) setIsSmsCodeValid(true);
  }, [phone, smsCode]);

  const init = useCallback(
    () => {
      setPhone('');
      setSmsCode('');
      props.setStep(CASHOUT_SCREENS.ENTER_DATA_STEP);
      props.setShowCashOutModal(false);
      props.setConfirmed(false);
    },
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    if (confirmed) {
      const checkInfo = [{
        input: {
          action: 2,
          account: CashToTerminalId.toString(),
          nominal: [{
            nominal: 1,
            cnt: CashToTerminalAmount
          }]
        },
        online: {
          agent: AgentName
        }
      }];

      if (isFiscal && sectionIdForPayment) {
        if (checkFiscalBalance()) {
          // делаем фискальную покупку
          const fiscalReqData = getReqDataFoFiscal({
            currentService,
            depositedAmount: CashToTerminalAmount,
            commissionSum: 0,
            sectionForPayment,
            paymentMethod: 0
          });
          const sectionsForCheck = getFiscalSectionsOnPos(fiscalReqData.Positions);
          const checkData = generateCheckDataForFiscal({
            ...currentService,
            SN: CashToTerminalId.toString(),
            willBeCreditedAmount: CashToTerminalAmount,
            commissionSum: 0,
            paymentDate: getCurrentDate(),
            kkmInfo,
            checkInfo,
            sectionsForCheck,
            paymentMethod: 0
          });

          props.doFiscalCashout({
            reqData: fiscalReqData,
            checkData,
          });
        }
      } else {
        // генерим обычный чек
        const checkData = generateCheckDataForAP({
          SN: CashToTerminalId.toString(),
          ...currentService,
          willBeCreditedAmount: CashToTerminalAmount,
          commissionSum: 0,
          checkInfo,
          paymentDate: getCurrentDate(),
          paymentMethod: 0, // наличные
        });

        // сохраняем в чековой АПИ
        props.saveCheck({
          checkData,
          sn: CashToTerminalId.toString(),
          showPrintWindowAfterSave: true
        });
      }

      init();
    }
    // eslint-disable-next-line
  }, [
    init,
    currentService,
    confirmed,
    isFiscal,
    sectionIdForPayment,
    cashOnFiscalBalance,
    CashToTerminalAmount,
    CashToTerminalId
  ]);

  const validateAndCheck = () => {
    const {
      clientIin,
      showError: showErrorFunc
    }
    = props;

    if (!iinIsValidOrEmptyFunc(clientIin, showErrorFunc)) return;

    if (!phone) {
      setPhoneError('Поле обязательно для заполнения');

      return setIsPhoneValid(false);
    }

    if (getClearPhone(phone).length !== 10) {
      setPhoneError('Номер телефона должен состоять из 10 символов');

      return setIsPhoneValid(false);
    }

    if (!smsCode) return setIsSmsCodeValid(false);

    props.onlineCheck(currentService, `0;${getClearPhone(phone)};${smsCode.replace(/[\D]+/g, '')}`);
  };

  const confirm = () => {
    if (isFiscal && !sectionIdForPayment) {
      return props.showError('Не удалось получить фискальную секцию, обновите страницу');
    }

    if (!isFiscal || checkFiscalBalance()) {
      props.onlineCheck(currentService, `1;${CashToTerminalId};${CashToTerminalAmount};1:${CashToTerminalAmount}`);
    }
  };

  const checkFiscalBalance = useCallback(() => {
    if (isFiscal && cashOnFiscalBalance >= CashToTerminalAmount) {
      return true;
    }

    props.showError(
      'Обнаружена нехватка средств в кассе для совершения данной операции. Пожалуйста попробуйте немного позднее',
      'В кассе недостаточно средств'
    );

    return false;
  // eslint-disable-next-line
  }, [isFiscal, cashOnFiscalBalance, CashToTerminalAmount]);

  const getOkText = () => {
    switch (step) {
      case CASHOUT_SCREENS.CASHOUT_CONFIRMED: return 'Закрыть';
      case CASHOUT_SCREENS.REQUEST_NOT_FOUND_STEP: return 'Закрыть';
      case CASHOUT_SCREENS.CHECK_DATA_STEP: return 'Выдать средства';
      default: return 'Далее';
    }
  };

  const getBackText = () => {
    if (step === CASHOUT_SCREENS.ENTER_DATA_STEP) { return 'Отмена'; }

    return 'Назад';
  };

  const getBackCallback = () => {
    switch (step) {
      case CASHOUT_SCREENS.CHECK_DATA_STEP:
        return () => props.setStep(CASHOUT_SCREENS.ENTER_DATA_STEP);
      case CASHOUT_SCREENS.REQUEST_NOT_FOUND_STEP:
        return () => props.setStep(CASHOUT_SCREENS.CHECK_DATA_STEP);
      case CASHOUT_SCREENS.CASHOUT_CONFIRMED:
        return () => props.setStep(CASHOUT_SCREENS.CHECK_DATA_STEP);
      default: return () => init();
    }
  };

  const getOkCallback = () => {
    switch (step) {
      case CASHOUT_SCREENS.ENTER_DATA_STEP: return () => validateAndCheck();
      case CASHOUT_SCREENS.CHECK_DATA_STEP: return () => confirm();
      default: return () => init();
    }
  };

  return (
    <Modal
      size="small-and-high"
      title="Выдача наличных"
      isOpen
      onBack={getBackCallback()}
      onOk={getOkCallback()}
      onClose={init}
      cancelText={getBackText()}
      okText={getOkText()}
    >
      <div className="cashout-modal-head">
        <div>
          <img src={CashOutIcon} alt="Выдача наличных" className="cashout-modal-icon"/>
        </div>
        <div>Выдача наличных</div>
      </div>
      <div className="fiscal-modal-section cashout-modal-body">
        {step === CASHOUT_SCREENS.ENTER_DATA_STEP && (
          <>
            <IinField/>
            <div className={!isPhoneValid ? 'input-error-block' : ''}>
              <label htmlFor="phone">Номер телефона</label>
              <br />
              <MaskedInput
                mask="+7 (111) 111-11-11"
                type="tel"
                id="phone"
                autoComplete="off"
                maxLength={20}
                placeholder="+7 (777) 123 45 67"
                placeholderChar=" "
                className={!isPhoneValid ? 'input-warning' : ''}
                value={phone.toString()}
                onChange={(e) => setPhone(e.target.value)}
                formatCharacters={{
                  W: {
                    validate(char) { return /[а-яА-Я \-0-9]/.test(char); },
                  }
                }}
              />
              <span className="inputError">{phoneError}</span>
            </div>
            <div>
              <label htmlFor="smsCode">SMS-код</label>
              <br />
              <MaskedInput
                mask="1111 - 1111 - 1111"
                type="tel"
                id="smsCode"
                autoComplete="off"
                maxLength={20}
                placeholder="ХХХХ - ХХХХ - ХХХХ"
                placeholderChar=" "
                className={!isSmsCodeValid ? 'input-warning' : ''}
                value={smsCode.toString()}
                onChange={(e) => setSmsCode(e.target.value.trim())}
                formatCharacters={{
                  W: {
                    validate(char) { return /[а-яА-Я \-0-9]/.test(char); },
                  }
                }}
              />
            </div>
            <div>
              Комиссия 0 тг <br />
              <div>
                <p>
                  Максимальная общая сумма операций,  полностью идентифицированному клиенту,
                  через кассу, за операцию- 2 800 000 тг;
                </p>
                <p>Минимальная сумма операции - 2000 тг; </p>
                <p>Максимальное общее количество операций по выводу наличных - без ограничений.</p>
              </div>
            </div>
          </>
        )}

        {step === CASHOUT_SCREENS.CHECK_DATA_STEP && (
          <div>
            {AgentName && (
              <div className="cashout-confirm">
                <span>Агент</span>
                <div>{AgentName}</div>
              </div>
            )}

            <div className="cashout-confirm">
              <span>Номер заявки, №</span>
              <div>{CashToTerminalId}</div>
            </div>
            <div className="cashout-confirm">
              <span>Сумма</span>
              <div>{formatMoney(CashToTerminalAmount)} тг</div>
            </div>
          </div>
        )}

        {step === CASHOUT_SCREENS.CASHOUT_CONFIRMED && (
          <div className={!isPhoneValid ? 'input-error-block' : ''}>
            <span>Выплата прошла успешно</span>
          </div>
        )}

        {step === CASHOUT_SCREENS.REQUEST_NOT_FOUND_STEP && (
          <div className={!isPhoneValid ? 'input-error-block' : ''}>
            <span>Заяка не найдена</span>
          </div>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  kkmInfo: state.fiscal.kkmInfo,
  isFiscal: state.fiscal.isFiscal,
  sectionForPayment: state.fiscal.sectionForPayment,
  cashOnFiscalBalance: Number(state.fiscal.balances.cash),
  currentService: state.kassa.currentService,
  CashToTerminalAmount: Number(state.cashout.CashToTerminalAmount),
  CashToTerminalId: state.cashout.CashToTerminalId,
  AgentName: state.cashout.AgentName,
  confirmed: state.cashout.confirmed,
  step: state.cashout.step,
  id: state.cashout.id,
  clientIin: state.payment.clientIin
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    setStep,
    saveCheck,
    showError,
    onlineCheck,
    setConfirmed,
    doFiscalCashout,
    setShowCashOutModal
  },
  dispatch);

CashOut.propTypes = {
  kkmInfo: PropTypes.object.isRequired,
  currentService: PropTypes.object.isRequired,
  setStep: PropTypes.func.isRequired,
  saveCheck: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  onlineCheck: PropTypes.func.isRequired,
  setConfirmed: PropTypes.func.isRequired,
  doFiscalCashout: PropTypes.func.isRequired,
  setShowCashOutModal: PropTypes.func.isRequired,
  AgentName: PropTypes.string.isRequired,
  isFiscal: PropTypes.bool.isRequired,
  confirmed: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
  CashToTerminalId: PropTypes.number.isRequired,
  sectionForPayment: PropTypes.number.isRequired,
  cashOnFiscalBalance: PropTypes.number.isRequired,
  CashToTerminalAmount: PropTypes.number.isRequired,
  clientIin: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(CashOut);
