/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RadioGroup from 'components/UI/RadioGroup/radioGroup';

const MypostSelectParcel = ({ parcelList, selectedParcelID, selectParcel }) => {
  const [radioOptions, setRadioOptions] = useState([]);

  useEffect(() => {
    if (parcelList.length && !selectedParcelID) {
      selectParcel(parcelList[0].InvoiceTracking);
    }
  }, [parcelList, selectedParcelID]);

  useEffect(() => {
    if (parcelList.length && !radioOptions.length) {
      const options = parcelList.map((parcel) => ({
        key: parcel.InvoiceTracking,
        value: parcel.InvoiceTracking,
        label: `№ ${parcel.InvoiceTracking}. Сумма: ${parcel.InvoiceAmount} тг, Получатель: ${parcel.FIO}`
      }));

      setRadioOptions(options);
    }
  }, [parcelList]);

  return (
    <div className="pay-form">
      <h5>Выберите посылку:</h5>
      <RadioGroup
        onChange={(parcel) => selectParcel(parcel)}
        selected={selectedParcelID}
        data={radioOptions}
      />
    </div>
  );
};

MypostSelectParcel.propTypes = {
  selectedParcelID: PropTypes.string.isRequired,
  parcelList: PropTypes.array.isRequired,
  selectParcel: PropTypes.func.isRequired
};

export default MypostSelectParcel;

