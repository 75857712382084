import React from 'react';
import PayTypes from 'paytypes';
import MaskedInput from 'react-maskedinput';

import IinField from 'components/IinField';
import { t, getMaskForInput, checkAccountOnReg } from 'helpers/main';

const {
  containers: { FixedContainer },
  templates: { FixedTemplate }
} = PayTypes;

class Fixed extends FixedTemplate {
  state = {
    account: '',
    isLoading: false
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDownFowWindow);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDownFowWindow);
  }

  componentDidUpdate(prevProps) {
    const {
      commission: { currentComProfile },
      payTypeFixed: { id, fixedAmount }
    } = this.props;

    if (prevProps.payTypeFixed.id !== id && id !== '') {
      this.confirm();
    }

    if (prevProps.payTypeFixed.fixedAmount !== fixedAmount) {
      this.props.updateWillBeCreditedAmount(fixedAmount, currentComProfile, true);
    }
  }

  onKeyDownFowWindow = (e) => {
    if (e.key === 'Enter') {
      this.validateAndCheck();
    }
  };

  handleOnChange(e) {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  confirm() {
    const {
      payTypeFixed: { fixedAmount },
      pay: { account },
      setConfirmInfo,
      showConfirmScreen
    } = this.props;

    const confirmFields = [
      { name: 'account', value: account },
      { name: 'amount', value: `${fixedAmount} тг` },
    ];

    const checkInfo = {
      input: {
        account,
      }
    };

    setConfirmInfo(confirmFields, '', checkInfo);
    showConfirmScreen();
  }

  validateAndCheck() {
    const { account } = this.state;
    const {
      onlineCheck,
      showError,
      iinIsValidOrEmpty,
      kassa: { currentService },
      payTypeFixed: { id },
      updateFieldValue,
    } = this.props;

    this.setState({ isLoading: false });
    if (!iinIsValidOrEmpty()) return;
    if (account) {
      const { regExp, maskEdit } = currentService;

      if (checkAccountOnReg(account, regExp, maskEdit)) {
        updateFieldValue('account', account.trim());

        if (id && id.toString() === account.toString()) {
          return this.confirm();
        }
        this.setState({ isLoading: true });

        return onlineCheck(currentService, account).then(() => {
          this.setState({ isLoading: false });
        });
      }

      return showError(
        'Проверьте, пожалуйста, и повторите попытку',
        'Лицевой счет некорректен'
      );
    }

    return showError(
      'Проверьте, пожалуйста, и повторите попытку',
      'Лицевой счет обязателен для заполнения'
    );
  }

  /* FROOT.KZ - подписка на 3 месяцев 8(707)884-34-39 */
  /* perchiknadom.kz 13447 13239 */
  /* Газета Информ-Вест г.Петропавловск 1223599 1223579 1223738 */
  /* Smart Box - Абонентская плата 1 мес KZ0000081 KZ0000059 */
  /* Ccloan - Продление займа 10 дней 800704301736 760902401174 */
  renderComponent() {
    const { maskEdit } = this.props.kassa.currentService;

    return (
      <div className="pay-form">
        <IinField/>
        <label htmlFor="№ лицевого счета">№ лицевого счета</label>
        <MaskedInput
          mask={getMaskForInput(maskEdit)}
          id="account"
          type="tel"
          autoComplete="off"
          maxLength={40}
          placeholder="№ лицевого счета"
          placeholderChar=" "
          onChange={(event) => this.handleOnChange(event)}
          value={this.state.account}
          formatCharacters={{
            W: {
              validate(char) { return /[\wа-яА-Я-]/.test(char); },
            }
          }}
          disabled={this.state.isLoading}
        />
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => this.validateAndCheck()}
        >
          {t('checkDebt')}
        </button>
      </div>
    );
  }
}

export default FixedContainer(Fixed);
