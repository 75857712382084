/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import { LOGO_URL } from 'constants/api';
import { checkUnicodeSymbols, setSentryBreadсrumbs } from 'helpers/main';
import logoKassa from 'public/images/kassa24/kassa24.svg';
import { showError } from 'actions/alert';
import { initPayType } from 'actions/pay';
import { canDoCashOut } from 'helpers/cert';
import { setCurrentService, checkAllNeededData } from 'actions/services';
import { initCurrentComProfile } from 'actions/commission';
import { CASH_OUT_PAY_TYPE } from 'constants/payTypes';

const SearchService = (props) => {
  const { serviceList } = props;
  const [searchedService, setSearchedService] = useState('');
  const [servicesListForSearch, setServicesListForSearch] = useState([]);

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = useCallback(
    ({ value }) => {
      const newServicesList = serviceList
        .filter(({ name, tag, description }) => {
          const serviceName = String(name);

          return (serviceName?.toLowerCase().includes(value.toLowerCase())) ||
          tag?.toLowerCase().includes(value.toLowerCase()) ||
          description?.toString().toLowerCase().includes(value.toLowerCase());
        }
        )
        .slice(0, 10);

      setSearchedService(value);
      setServicesListForSearch(newServicesList);
    },
    [serviceList]
  );

  const getSuggestionValue = useCallback(
    ({ idService, name, typeInterface }) => {
      const findService = serviceList.filter(item => item.idService === idService);
      const currentSerice = (findService.length) ? findService[0] : {};
      const hasAllData = props.checkAllNeededData();

      if (hasAllData) {
      // если выбирают кэшаут, проверяем разрешено ли его выбрать (есть ли кука из серта)
        if (currentSerice.typeInterface !== CASH_OUT_PAY_TYPE || canDoCashOut()) {
          setSentryBreadсrumbs('service', `Select service from search: ${idService}`);
          props.setCurrentService(currentSerice);
          props.initPayType(typeInterface);
          props.initCurrentComProfile();
        }

        if (currentSerice.typeInterface === CASH_OUT_PAY_TYPE && !canDoCashOut()) {
          props.showError('У вас нет прав для осуществления выдачи средств. Обратитесь к курирующему менеджеру для подключения данной услуги');
        }
      }

      return checkUnicodeSymbols(name);
    },
    [serviceList]
  );

  const inputProps = {
    value: searchedService,
    onChange: (_, { newValue }) => setSearchedService(newValue),
    type: 'search',
    placeholder: 'Введите название оператора или услуги',
    className: 'search'
  };

  return (
    <Autosuggest
      inputProps={inputProps}
      suggestions={servicesListForSearch}
      getSuggestionValue={getSuggestionValue}
      onSuggestionsClearRequested={() => setServicesListForSearch([])}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      renderSuggestion={({ logo, name }) => (
        <div className="search-item">
          <div className="search-block-img">
            <img
              src={logo ? `${LOGO_URL}${logo}` : logoKassa}
              alt={checkUnicodeSymbols(name)}
            />
          </div>
          <div className="search-service-name">{checkUnicodeSymbols(name)}</div>
        </div>
      )}
    />
  );
};

SearchService.propTypes = {
  serviceList: PropTypes.array.isRequired,
  showError: PropTypes.func.isRequired,
  initPayType: PropTypes.func.isRequired,
  setCurrentService: PropTypes.func.isRequired,
  initCurrentComProfile: PropTypes.func.isRequired,
  checkAllNeededData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  serviceList: state.services.serviceList,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showError,
  initPayType,
  setCurrentService,
  initCurrentComProfile,
  checkAllNeededData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchService);
