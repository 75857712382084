/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MaskedInput from 'react-maskedinput';

import Modal from 'components/UI/Modal/modal';
import Checkbox from 'components/UI/Checkbox/checkbox';
import { b64EncodeUnicode } from 'helpers/main';
import { setClientIIN } from 'actions/pay';
import Oferta from './JysanOferta';

const JysanPayType = (props) => {
  const [iin, setIin] = useState('');
  const [card, setCard] = useState('');
  const [agreedForAgreement, setAgreedForAgreement] = useState(false);
  const [agreedForOferta, setAgreedForOferta] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [openOferta, setOpenOferta] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    kassa: { currentService },
    jysan: { showTimeMsg, id, MaxAmount, FIO },
    setClientIIN: setClientIINFunc
  } = props;

  useEffect(() => {
    const onKeyDownFowWindow = (e) => {
      if (e.key === 'Enter') {
        if (agreedForAgreement) {
          if (openOferta) {
            setOpenOferta(false);
          } else if (openInfoModal) {
            setOpenInfoModal(false);
          } else {
            validateAndCheck();
          }
        } else {
          setAgreedForAgreement(true);
        }
      }
    };

    window.addEventListener('keydown', onKeyDownFowWindow);

    return () => {
      window.removeEventListener('keydown', onKeyDownFowWindow);
    };
  }, [iin, card, agreedForOferta, openInfoModal, openOferta]);

  useEffect(() => {
    if (id) {
      nextScreen();
    }
  }, [id]);

  useEffect(() => {
    if (showTimeMsg) {
      setOpenInfoModal(true);
    }
  }, [showTimeMsg]);

  useEffect(() => {
    if (agreedForAgreement) {
      // проверяем время (для того чтобы понять стоит ли отображать мадалку)
      props.onlineCheck(currentService, '111');
    }
  }, [agreedForAgreement]);

  const validateAndCheck = useCallback(
    () => {
      setIsLoading(false);
      if (iin && card) {
        if (agreedForOferta) {
          setIsLoading(true);
          props.updateFieldValue('account', iin);

          return props.onlineCheck(currentService, `2;${iin};${card}`).then(() => { setIsLoading(false); });
        }
        props.showError('Для дальнейшего выполнения операции соглашение с офертой обязательно.');
      } else {
        props.showError(
          'Проверьте, пожалуйста, и повторите попытку',
          'Все поля обязательны для заполнения'
        );
      }
    },
    [iin, card, agreedForOferta],
  );

  const nextScreen = () => {
    const confirmFields = [
      { name: 'account', value: iin },
      { name: 'fio', value: FIO },
    ];

    if (MaxAmount) {
      confirmFields.push({ name: 'Максимальная сумма', value: MaxAmount });
    }

    const checkInfo = {
      input: {
        iin,
        iban: card,
      },
      online: {}
    };

    props.initPayScheme();
    props.setConfirmInfo(confirmFields, getAddings(), checkInfo);
    props.showEnterAmountScreen();
  };

  const getAddings = useCallback(
    () => ({
      constraint: iin,
      subservice: 0,
      amount0: 0,
      amount1: 0,
      amount2: 0,
      comission: 0,
      memo: b64EncodeUnicode(card),
    }),
    [iin, card],
  );

  const setIinHandler = (e) => {
    const value = e.target.value.trim();

    setIin(value);
    setClientIINFunc(value);
  };

  if (agreedForAgreement) {
    return (
      <>
        {/* Оферта */}
        <Modal
          size="high"
          isOpen={openOferta}
          isFooterHidden
          title="Публичная оферта"
          onClose={() => setOpenOferta(false)}
          onOk={() => setOpenOferta(false)}
        >
          <div className="jysan-oferta">
            <Oferta />
          </div>
        </Modal>
        {/* Оферта */}

        <Modal
          title="Информация"
          size="small"
          isOpen={openInfoModal}
          onClose={() => setOpenInfoModal(false)}
          onOk={() => setOpenInfoModal(false)}
          isFooterHidden
        >
          <p>
            Платежи отправленные после 22:00 будут обработаны и зачислены на счёт
            на следующий рабочий день!
          </p>
        </Modal>
        <div className="pay-form">
          <div className="form-group">
            <MaskedInput
              mask={'1'.repeat(12)}
              type="tel"
              id="account"
              autoComplete="off"
              maxLength={40}
              placeholder="Введите ИИН"
              placeholderChar=" "
              onChange={(e) => setIinHandler(e)}
              value={iin}
              disabled={isLoading}
            />
          </div>
          <div className="form-group">
            <MaskedInput
              mask={'1'.repeat(6)}
              type="tel"
              id="card"
              autoComplete="off"
              maxLength={40}
              placeholder="Введите последние 6 цифр Вашего счета"
              placeholderChar=" "
              onChange={(e) => setCard(e.target.value.trim())}
              value={card}
              disabled={isLoading}
            />
          </div>
          <div className="form-group">
            <Checkbox
              checked={agreedForOferta}
              onchange={() => setAgreedForOferta(!agreedForOferta)}
              label="Я принимаю условия публичной оферты"
              id={1}
            />
          </div>
          <div className="form-group">
            <button type="button" className="link-text buttonWithoutStyles" onClick={() => setOpenOferta(true)}>Открыть оферту</button>
          </div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => validateAndCheck()}
          >
            Далее
          </button>
        </div>
      </>
    );
  }

  return (
    <div>
      <h4>Соглашение</h4>
      <p>
        Настоящим даю согласие на сбор, обработку, хранение и распространение АО «Jysan bank»
        моих персональных данных в соответствии с требованиями законодательства Республики Казахстан
        и внутренних документов АО «Jysan bank»
      </p>
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => setAgreedForAgreement(true)}
      >
        Далее
      </button>
    </div>
  );
};

JysanPayType.propTypes = {
  showEnterAmountScreen: PropTypes.func.isRequired,
  updateFieldValue: PropTypes.func.isRequired,
  setConfirmInfo: PropTypes.func.isRequired,
  initPayScheme: PropTypes.func.isRequired,
  onlineCheck: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  kassa: PropTypes.object.isRequired,
  jysan: PropTypes.object.isRequired,
  setClientIIN: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setClientIIN
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(JysanPayType);
