import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/UI/Modal/modal';

const Help = ({ setShowHelpWindow }) => (
  <Modal
    isOpen
    isFooterHidden
    title="Помощь"
    onClose={() => setShowHelpWindow(false)}
  >
    <div className="modal-scroll-body">
      <ol>
        <li>
          <h4>Закрытие и открытие смены:</h4>
          <p>
            Выбираем вкладку в операциях  «Закрытие или Открытие смены»,  вводим пароль,
            пароль это номер терминала, смена открыта или закрыта.
          </p>
        </li>
        <li>
          <h4>Меню</h4>
          <p>
            Во вкладке Меню », отражается функционал по «Инкассации», «Журнал инкассаций»,
            «Итоговый отчет за период», «Оферта Fiscal 24».
          </p>
        </li>
        <li>
          <h4>Оплата по сервису:</h4>
          <p>
            Выбираем нужную вкладку сервиса, вводим запрошенные данные, выбираем способ оплаты,
            оплачиваем.
          </p>
        </li>
        <li>
          <h4>Подключение расширенного функционала  Web Terminal:</h4>
          <p>
            Выбираем вкладку  «Продажа New» кликаем, всплывает окно с выбором лицензий,
            выбираем лицензию, нажимаем «Оплатить», формируется счет. После оплаты
            Агенту доступен расширенный функционал.
          </p>
        </li>
        <li>
          <h4>
            Расширенный функционал
          </h4>
          <p>
            При подключении расширенной  версии Web Terminal появляется возможность
            совершать покупку и продажу.
          </p>
        </li>
      </ol>
    </div>
  </Modal>
);

Help.propTypes = {
  setShowHelpWindow: PropTypes.func.isRequired
};

export default Help;

