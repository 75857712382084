import { initPayType } from 'actions/pay';
import { getErrText } from 'helpers/main';
import { setCurrentService } from 'actions/services';
import { showError, showSuccess } from 'actions/alert';
import { initCurrentComProfile } from 'actions/commission';
import { checkError, checkMsg } from 'helpers/onlineCheck';
import { MYPOST_PAYMENT_SERVICE_ID } from 'constants/services';

import * as types from './types';

export const setPhoneForPayment = (phoneForPayment) => (dispatch) => {
  dispatch({ type: types.SET_PHONE_FOR_PAYMENT_MYPOST, phoneForPayment });
};

export const setShowMyPostDeliveryModal = (showMyPostDeliveryParcelModal) => (dispatch) => {
  dispatch({ type: types.SET_SHOW_MYPOST_DELIVERY_PARCEL_MODAL, showMyPostDeliveryParcelModal });
};

export const setShowMyPostPaymentModal = (showMyPostPaymentModal) => (dispatch) => {
  dispatch({ type: types.SET_SHOW_MYPOST_PAYMENT_MODAL, showMyPostPaymentModal });
};

export const setIsMyPostPayment = (isMyPostPayment) => (dispatch) => {
  dispatch({ type: types.SET_IS_MYPOST_PAYMENT, isMyPostPayment });
};

export const clearParcelList = () => (dispatch) => {
  dispatch({ type: types.CLEAR_MYPOST_PARCEL_LIST });
};

export const clearParcelInfo = () => (dispatch) => {
  dispatch({ type: types.CLEAR_MYPOST_PARCEL_INFO });
};

export const myPostPaymentOnlineCheck = (res) => (dispatch) => {
  const errMsg = checkMsg(res);
  const errCode = checkError(res);

  if (errMsg) {
    dispatch(showError(errMsg));
  }

  if (!errCode) {
    const result = res.data.account;
    const { '@attributes': { st }, Options: { Option } } = result;

    if (Number(st) === 0) {
      dispatch({ type: types.SET_PARCEL_LIST, parcelList: [].concat(Option) });
    }
  } else {
    dispatch(showError(getErrText(errCode)));
  }
};

export const myPostDeliveryOnlineCheck = (res, reqType) => (dispatch) => {
  const errMsg = checkMsg(res);
  const errCode = checkError(res);

  if (errMsg) {
    dispatch(showError(errMsg));
  }

  if (!errCode) {
    // первая онлайн-проверка на получение информации о посылке
    if (reqType === 'pvz.findPackageDelivery') {
      const result = res.data.account;
      const { '@attributes': { st }, fields: { field } } = result;

      let idInvoice = '';
      let idPackage = '';
      let parcelAmount = '';
      let parcelPaidAmount = '';

      const parcelData = field.map(item => {
        const fieldName = item['@attributes'].name;

        switch (fieldName) {
          case 'Идентификатор заказа':
            idInvoice = item.content;
            break;
          case 'Идентификатор посылки':
            idPackage = item.content;
            break;
          case 'Стоимость заказа':
            parcelAmount = Number(item.content);
            break;
          case 'Оплаченная сумма':
            parcelPaidAmount = Number(item.content);
            break;
          default: break;
        }

        return ({
          name: fieldName,
          value: item.content
        });
      });
      const reqParams = {
        type: types.SET_DELIVERY_PARCEL_INFO,
        parcelData,
        idInvoice,
        idPackage,
        parcelAmount,
        parcelPaidAmount
      };

      myPostDeliveryOnlineCheckSt(reqParams, st, reqType, dispatch);
    }

    // вторая онлайн-проверка о выдаче посылки
    if (reqType === 'pvz.receivePackage4Customer') {
      const result = res.data.account;
      const { '@attributes': { st } } = result;

      myPostDeliveryOnlineCheckSt('', st, reqType, dispatch);
    }
  } else {
    dispatch(showError(getErrText(errCode)));
  }
};

export const selectMyPostService = () => (dispatch, getState) => {
  dispatch(setShowMyPostPaymentModal(true));

  const myPostService = getState().services.serviceList
    .filter(item => (item.idService === MYPOST_PAYMENT_SERVICE_ID));

  if (myPostService.length) {
    dispatch(setCurrentService(myPostService[0]));
    dispatch(initPayType(myPostService[0].typeInterface));
    dispatch(initCurrentComProfile());
  }
};

const myPostDeliveryOnlineCheckSt = (reqParams, st, reqType, dispatch) => {
  if (reqType === 'pvz.findPackageDelivery') {
    if (Number(st) === 0) {
      dispatch(reqParams);
    }
  } else if (reqType === 'pvz.receivePackage4Customer') {
    if (Number(st) === 0) {
      dispatch(showSuccess('Посылка успешно выдана'));
    }
  }
};
