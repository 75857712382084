import PayTypes from 'paytypes';
import React from 'react';
import CustomButton from 'components/UI/Button/button';

const { KtjWagonSchemeTemplate } = PayTypes.templates;

class WagonScheme extends KtjWagonSchemeTemplate {
  renderComponent() {
    const { car } = this.props;
    const typeOptions = this.getTypeOptions(car);
    const additionalClass = (typeOptions.additionalClass) ? typeOptions.additionalClass : '';
    const classes = `car-scheme ${typeOptions.class} ${additionalClass}`;
    let content = [];

    if (typeOptions) {
      const placesNumber = typeOptions.places;

      for (let i = 0; i < placesNumber; i++) {
        const place = i < 9 ? `0${i + 1}` : (i + 1);
        const isFree = (car.Places.includes(place)) ? 'free' : '';

        content.push(<div data-place={place} key={place} className={isFree}>{Number(place)}</div>);
      }
    } else {
      content = 'Неизвестный тип вагона';
    }

    return (
      <div>
        <CustomButton onClick={() => this.props.setShowWagonScheme(false)}>
          Вернуться к выбору вагона
        </CustomButton>
        {(typeOptions) && (
          <div id="wrapper" className={classes}>
            {content}
          </div>
        )}
        {(!typeOptions) && (
          <div>Неизвестный тип вагона</div>
        )}
      </div>
    );
  }
}

export default WagonScheme;
