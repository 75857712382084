import { PAY_SCHEMES } from 'constants/payTypes';

export function checkError(res) {
  /*
      Варианты, где может лежать код ошибки:

      1. <account st="статус"
      2. <body><errCode> статус </errCode></body>
      3. <body><status> статус </status ></body>
      4. <rs><er> статус </er></rs>

      Функция возвращает код ошибки, или 0 если ошибки нет
  */

  const { body } = res.data;

  if (typeof body !== 'undefined') {
    if (body.message !== 'undefined') {
      return body.message;
    }

    if (typeof body.status !== 'undefined') {
      return Number(body.status);
    }
  }

  const { account } = res.data;

  if (typeof account !== 'undefined') {
    return Number(account['@attributes'].st);
  }

  const { errCode } = res.data;

  if (typeof errCode !== 'undefined') {
    return Number(errCode);
  }

  const { status } = res.data;

  if (typeof status !== 'undefined') {
    return Number(status);
  }

  const { er } = res.data;

  if (typeof er !== 'undefined') {
    return Number(er);
  }

  const { request } = res.data;

  if (typeof request !== 'undefined') {
    if (typeof request.body !== 'undefined') {
      if (typeof request.body.status !== 'undefined') {
        return Number(request.body.status);
      }
    }
  }

  return 0;
}

export function checkMsg(res) {
  const { body } = res.data;

  if (typeof body !== 'undefined') {
    if (typeof body.message !== 'undefined') {
      return body.message;
    }
  }

  return '';
}

export const getOnlineCheckData = (res, typeInterface) => {
  if (typeInterface === PAY_SCHEMES.KASPI_BANK) {
    return {
      data: {
        body: res
      }
    };
  }

  return { data: res };
};
