import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'helpers/main';
import { paramsKostanaySu } from 'constants/all';

const CounterRow = ({ title, value, defaultValue, onChange, total, tarif, noEdit, itog }) => (
  <tr key={`${title}${value}`}>
    <td> {title} </td>
    <td>
      <input value={value} disabled />
    </td>
    <td>
      <input defaultValue={defaultValue} onChange={onChange.bind(this)} disabled={noEdit} />
    </td>
    <td>
      <input value={total} disabled />
    </td>
    <td>
      <input value={tarif} disabled />
    </td>
    <td>
      <input value={parseFloat(itog).toFixed(2)} disabled />
    </td>
  </tr>
);

export default class Counters extends Component {
  render() {
    const {
      account,
      itogAmountOnCounters,
      changeCurrentValueAndItog,
      payTypeKostanaySu: {
        fio,
        address,
        peni,
        saldo,
        changedCounts,
        notEmptyCounts,
        sewerage,
        tarifs
      }
    } = this.props;

    const titleSaldo = Number(saldo) > 0 ? 'Переплата' : 'Долг';
    const titlePeni = Number(peni) > 0 ? 'Переплата по пене' : 'Пеня';
    // Выбираем счетчики, в которых есть оплата за канализацию
    const kanalCounters = notEmptyCounts.filter(item => (item.key !== 'hot' && item.key !== 'plv'));
    // Считаем итоговую сумму по счетчикам с канализацией (end - begin) * tarif
    const kanalItogAmount = kanalCounters.reduce((amount, item) => {
      const { key, value } = item;

      return Number(amount) + ((Number(changedCounts[key.toString()]) - Number(value))
        * Number(tarifs.kanal));
    }, 0);
    const totalKanalItogAmount = (kanalItogAmount > 0) ? kanalItogAmount : 0;
    // Итоговая сумма по счетчикам + за канализацию
    const itogAmount = parseFloat(kanalItogAmount + itogAmountOnCounters).toFixed(2);
    // Не выводим если итоговая сумма меньше 0
    const totalItogAmount = (itogAmount > 0) ? itogAmount.toString() : '0';

    return (
      <div>
        <div className="pay-form">
          <div className="confirm-info">
            <p><b>{t('account')}: </b>{account}</p>
            <p><b>{t('fio')}: </b> {fio} </p>
            <p><b>{t('address')}: </b> {address}</p>
            <p><b>{titleSaldo}: </b> {saldo < 0 ? saldo * (-1) : saldo} тг</p>
            <p><b>{titlePeni}: </b> {peni} тг</p>
            <p className="margin-top">{t('totalMeterForHotAndColdWater')}:</p>
            <div>
              <table>
                <thead>
                  <tr>
                    <th> {t('counter')} </th>
                    <th> {t('lastMeter')} </th>
                    <th> {t('currentMeter')} </th>
                    <th> {t('subtotal')} <br /> ({t('smallPrice')}) </th>
                    <th> {t('tariff')} </th>
                    <th> {t('subtotal')} </th>
                  </tr>
                </thead>
                <tbody>
                  {notEmptyCounts.map(item => {
                    const { key, value } = item;
                    const difference = Number(changedCounts[String(key)]) - Number(value);
                    const totalDiff = difference > 0 ? difference : 0;
                    const keyStrKost = paramsKostanaySu[String(key)];
                    const itogSumOnRow = Number(tarifs[keyStrKost.tariff]) * totalDiff;

                    return (
                      <CounterRow
                        key={key}
                        title={paramsKostanaySu[String(key)].title}
                        value={value.toString()}
                        defaultValue={changedCounts[String(key)]}
                        onChange={(e) => changeCurrentValueAndItog(key, e)}
                        total={totalDiff.toString()}
                        tarif={tarifs[paramsKostanaySu[String(key)].tariff]}
                        noEdit={false}
                        itog={itogSumOnRow}
                      />
                    );
                  })}
                  {(Number(sewerage)) ? (
                    <CounterRow
                      title="Сброс холодной воды"
                      value=""
                      defaultValue=""
                      onChange={() => console.log('Отредактировать нельзя, он disabled')}
                      total=""
                      tarif={tarifs.kanal.toString()}
                      itog={totalKanalItogAmount}
                      noEdit
                    />
                  ) : <tr />}
                </tbody>
              </table>
              <div className="text-right">
                {totalItogAmount} тг
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Counters.propTypes = {
  payTypeKostanaySu: PropTypes.object.isRequired,
  itogAmountOnCounters: PropTypes.number.isRequired,
  account: PropTypes.string.isRequired,
  changeCurrentValueAndItog: PropTypes.func.isRequired,
};

CounterRow.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  itog: PropTypes.number.isRequired,
  total: PropTypes.string.isRequired,
  tarif: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  noEdit: PropTypes.bool.isRequired,
};
