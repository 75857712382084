import { REQUEST_API_TYPES } from 'constants/initialDownload';

import { setInitialRequestCompleted } from 'actions/initialDownload';
import { runTheLoader, stopTheLoader } from 'actions/loader';
import { MAIN_PAGE_URL } from 'constants/all';

const PAGE_KASSA = MAIN_PAGE_URL;
const END_SESSION_PAGE = '/session-ended';
const INITIAL_REQUESTS_ACTIONS = ['auth', 'kkm', 8, 32];
const TYPES = Object.values(REQUEST_API_TYPES);

export default (store) => (next) => async (action) => {
  const { type, payload } = action;

  if (!TYPES.includes(type)) return next(action);

  const { dispatch } = store;

  if (typeof type !== 'undefined') {
    const { reqType } = payload;

    if (INITIAL_REQUESTS_ACTIONS.includes(reqType)) {
      dispatch(setInitialRequestCompleted(payload.reqType));
    }

    checkInitialRequestsAndControlLoader(dispatch, store);
  }

  await next(action);
};

const checkInitialRequestsAndControlLoader = (dispatch, store) => {
  const { getState } = store;
  const {
    initialDownload,
    router: { location },
    fiscal: { isFiscal, authInFiscalIsError }
  } = getState();

  let isFiscalDataLoaded = false;

  if (isFiscal) {
    isFiscalDataLoaded = isFiscalInitialRequestsCompleted({ ...initialDownload, location });
  }

  const isGatewayDataLoaded = isGatewayInitialRequestsCompleted({ ...initialDownload, location });

  if (((isFiscal && isFiscalDataLoaded) ||
  (isFiscal && authInFiscalIsError) || !isFiscal)
  && isGatewayDataLoaded) {
    dispatch(stopTheLoader(true));
  } else {
    dispatch(runTheLoader());
  }
};

const isGatewayInitialRequestsCompleted = ({
  isBalanceRequested,
  isPaymentHistoryRequested,
  location
}) =>
  (isBalanceRequested && isPaymentHistoryRequested && (location?.pathname === PAGE_KASSA))
  || (isBalanceRequested && (location?.pathname !== PAGE_KASSA))
  || (location?.pathname === END_SESSION_PAGE);

const isFiscalInitialRequestsCompleted = ({
  isFiscalKkmInfoRequested: hasKkmInfo,
  isFiscalAuthDataRequested: hasFiscalAuthData,
  location
}) => ((hasFiscalAuthData && hasKkmInfo) || (location?.pathname !== PAGE_KASSA));
