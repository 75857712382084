import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/UI/Icon/index';
import { formatMoney } from 'helpers/main';
import FiscalOferta from 'public/files/pult24-offer.pdf';
import logoKassa from 'public/images/kassa24/kassa24.svg';
import './fiscal-license.scss';

const FiscalLicenses = ({
  licenseList,
  showError,
  setShowLicenseModal,
  createInvoiceForKkm,
}) => {
  const [tariff, setTariff] = useState(1);

  const selectTariff = () => {
    const currentTariff = licenseList.filter(item => item.id === tariff)[0];

    if (tariff) {
      createInvoiceForKkm(currentTariff.id);
      setShowLicenseModal(false);
    }
  };

  const getAMonthWord = (monthCount) => {
    if (monthCount === 1) return 'месяц';
    if (monthCount > 1 && monthCount < 5) return 'месяца';
    if (monthCount > 5) return 'месяцев';
  };

  if (!licenseList.length) {
    showError('В данный момент операция недоступна для выполнения, повторите попытку позже');
    setShowLicenseModal(false);

    return <div />;
  }

  return (
    <div className="fiscal-tariffs">
      <div className="fiscal-tariffs-body">
        <div className="header">
          <img src={logoKassa} alt="kassa24" className="logo-kassa24" />
          <button className="modal-close" onClick={() => setShowLicenseModal(false)}>
            <Icon className="modal-close-svg" type="close" />
          </button>
          <h2>
            С фискальной лицензией от Касса24 вы сможете покупать и продавать прямо в веб терминале
          </h2>
          <div className="gray">Абонентская плата за ОФД включена в стоимость лицензии</div>
        </div>
        <div className="fiscal-tariffs-items">
          {licenseList.map(({ id, name, cost, value }) => (
            <button
              key={id}
              type="button"
              tabIndex={0}
              onClick={() => setTariff(id)}
              className={`tariffs-block ${id === tariff ? 'active-tariff' : ''}`}
            >
              {name}
              <div>
                <div className="price">{formatMoney(cost)} тг</div>
                <div className="description">оплачивается за {value} {getAMonthWord(value)}</div>
                <div className="gray price-per-month">{formatMoney(cost / value)} тг/месяц</div>
              </div>
            </button>
          ))}
        </div>
        <div className="footer-block">
          <a
            target="_blank"
            href={FiscalOferta}
            rel="noopener noreferrer"
          >
            Ознакомиться с офертой
          </a>
          <button onClick={selectTariff}>Оплатить</button>
        </div>
      </div>
    </div>
  );
};

FiscalLicenses.propTypes = {
  setShowLicenseModal: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  createInvoiceForKkm: PropTypes.func.isRequired,
  licenseList: PropTypes.array.isRequired,
};

export default FiscalLicenses;
