import * as servicesTypes from 'actions/services/types';
import { getInitTypeByPayType, isInt } from 'helpers/main';
import { updateCommissionData } from 'actions/commission';
import { setIsMyPostPayment } from 'actions/payTypes/mypost';
import { adjustTheAmount, getDepositedAmount, getWillBeCreditedAmount, getCommission, checkAmountOnNumbersAndDot } from 'helpers/pay';
import * as types from './types';

export function setCounterValueInStore(field, value) {
  return (dispatch) => {
    dispatch({ type: types.SET_PAYMENT_COUNTER_VALUE, field, value });
  };
}

export function initCurrentService() {
  return (dispatch) => {
    dispatch({ type: servicesTypes.INIT_CURRENT_SERVICE });
  };
}

export function initPayType(payType) {
  return (dispatch, getState) => {
    dispatch({ type: getInitTypeByPayType({ payType }) });
    dispatch({ type: types.PAYMENT_INIT });

    if (getState().mypost.isMyPostPayment) {
      setIsMyPostPayment(false);
    }
  };
}

export function initPayScheme(payScheme = 0) {
  return (dispatch, getState) => {
    const payType = payScheme || getState().kassa.currentService.typeInterface;

    dispatch({ type: getInitTypeByPayType({ payType }) });
  };
}

export function updateFieldValue(id, value) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_FIELD_VALUE, field: id, value });
  };
}

export function updateFieldsValue(fields) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_FIELDS_VALUE, fields });
  };
}

export function updateSelectedFieldValue(field, value) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_FIELD_VALUE, field, value });
  };
}

export function updateAmountValue(value) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_FIELD_VALUE, field: 'amount', value: value.toString() });
  };
}

// Обновление суммы к зачислению
export function updateWillBeCreditedAmount(
  value,
  currentComProfile,
  isFix = false,
  doAdjust = true
) {
  return (dispatch, getState) => {
    const {
      rounding,
      minPr,
      maxPr,
      min,
      max,
      isCommissionDenied,
    } = getState().kassa.currentService;

    const val = doAdjust ? adjustTheAmount(value) : checkAmountOnNumbersAndDot(value);

    dispatch(updateSelectedFieldValue('willBeCreditedAmount', val));

    let depositedAmount = val; // приравниваем к "К зачислению"

    if (!isCommissionDenied) {
      const comData = getCommission({
        commissionProfile: currentComProfile,
        willBeCreditedAmount: Math.ceil(val),
        isFix,
        rounding,
        minPr,
        maxPr,
        min,
        max
      });

      let comSum = comData.commissionSum;

      // Если "К зачислению" целое, а комиссия дробная, то округляем комиссию,
      // Если "К зачислению" дробное, то остаток дроби переносим в комиссию,
      // чтоб Внесено получилось целым

      if (isFix && !isInt(val)) {
        // если сумма фиксированная и не целая, округляем Внесено вверх,
        // и считаем комиссию как внесено - к зачислению
        const deposited = getDepositedAmount(val, comSum);

        depositedAmount = Math.ceil(deposited);
        comSum = parseFloat(depositedAmount - val).toFixed(2);
        // если сумма комиссии превышает максимальную,
        // округляем внесено вниз и отнимаем разницу из комиссии
        if (max && comSum > max) {
          depositedAmount = Math.floor(deposited);
          comSum = depositedAmount - val;
        }
      } else {
        depositedAmount = getDepositedAmount(val, comSum);
      }

      comData.commissionSum = comSum;
      dispatch(updateCommissionData(comData));
    }

    dispatch(updateSelectedFieldValue('depositedAmount', depositedAmount));
  };
}

// Обновление внесенной суммы
export function updateDepositedAmount(value, currentComProfile, doAdjust) {
  return (dispatch, getState) => {
    const {
      rounding,
      minPr,
      maxPr,
      min,
      max,
      isCommissionDenied
    } = getState().kassa.currentService;

    const val = doAdjust ? adjustTheAmount(value) : checkAmountOnNumbersAndDot(value);

    dispatch(updateSelectedFieldValue('depositedAmount', val));

    let willBeCreditedAmount = val; //  приравниваю сумму к зачислению к внесено

    if (!isCommissionDenied) { // если у сервиса комиссия не запрещена, считаем
      const comData = getCommission({
        commissionProfile: currentComProfile,
        depositedAmount: Math.ceil(val),
        rounding,
        minPr,
        maxPr,
        min,
        max,
      });

      dispatch(updateCommissionData(comData));
      willBeCreditedAmount = getWillBeCreditedAmount(val, Math.floor(comData.commissionSum));
    }

    dispatch(updateSelectedFieldValue('willBeCreditedAmount', willBeCreditedAmount));
  };
}

export function setConfirmInfo(
  confirmFields,
  adding = '',
  checkInfo = [],
  getAddings = () => [],
  min = 0,
  max = 0,
) {
  return (dispatch) => {
    dispatch({
      type: types.SET_CONFIRM_INFO,
      confirmFields,
      adding,
      checkInfo,
      getAddings,
      min,
      max,
    });
  };
}

export function setAdding(adding) {
  return (dispatch) => {
    dispatch({ type: types.SET_ADDING, adding });
  };
}

export function setTransactionNumber(SN) {
  return (dispatch) => {
    dispatch({ type: types.SET_TRANSACTION_NUMBER, SN });
  };
}

export function setPaymentMethod(paymentMethod) {
  return (dispatch) => {
    dispatch({ type: types.SET_PAYMENT_METHOD, paymentMethod });
  };
}

export function changeDepositedAmount(depositedAmount) {
  return (dispatch) => {
    dispatch({ type: types.SET_DEPOSITED_AMOUNT, depositedAmount });
  };
}

export function changeWillBeCreditedAmount(willBeCreditedAmount) {
  return (dispatch) => {
    dispatch({ type: types.SET_WILL_BE_CREDITED_AMOUNT, willBeCreditedAmount });
  };
}

export function showPayTypeScreen() {
  return (dispatch) => {
    dispatch({ type: types.SHOW_PAY_TYPE_SCREEN });
  };
}

export function showEnterAmountScreen() {
  return (dispatch) => {
    dispatch({ type: types.SHOW_ENTER_AMOUNT_SCREEN });
  };
}

export function showConfirmScreen() {
  return (dispatch) => {
    dispatch({ type: types.SHOW_CONFIRM_SCREEN });
  };
}

export function updateMinServiceAmount(minAmount) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_MIN_SERVICE_AMOUNT, minAmount });
  };
}

export function updateMaxServiceAmount(maxAmount) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_MAX_SERVICE_AMOUNT, maxAmount });
  };
}

// используется просто чтобы отобразиться в диспачах, ни на что не влияет
export function dispatchInfoLog(info) {
  return ({ type: types.DISPATCH_INFO_LOG, info });
}

export function setSendedToFiscal(value) {
  return (dispatch) => {
    dispatch({ type: types.SET_SENDED_TO_FISCAL, value });
  };
}

export function setOtherIdServiceForMakePay(value) {
  return (dispatch) => {
    dispatch({ type: types.SET_OTHER_ID_SERVICE_FOR_MAKE_PAY, value });
  };
}

export function setSelectedKaspiInModal(value) {
  return (dispatch) => {
    dispatch({ type: types.SELECTED_KASPI_IN_MODAL, value });
  };
}

export function setClientIIN(clientIin) {
  return (dispatch) => {
    dispatch({ type: types.SET_CLIENT_IIN, clientIin });
  };
}

export function setLastSuccessPaymentData(lastSuccessPaymentData) {
  return (dispatch) => {
    dispatch({ type: types.SET_LAST_PAYMENT_DATA, lastSuccessPaymentData });
  };
}

export function clickEnterConfirmScreen() {
  return (dispatch) => {
    dispatch({ type: types.CONFIRM_SCREEN_CLICK_ENTER });
  };
}

