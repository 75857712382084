import { resetLogInLocalStorage } from 'model/Logs';
import { getAuthData } from 'model/UserDb';
import { pakoStrEncode, getCurrentDate, b64EncodeUnicode, logErrorToSentry, setSentryBreadсrumbs } from 'helpers/main';

import * as types from './types';

export const sendLog = (idService = '', idTerminal = '', sn = '', logActions = '', account = '') =>
  (dispatch) => {
    if (!window.location.href.includes('localhost')) {
      const payload = b64EncodeUnicode(
        pakoStrEncode(JSON.stringify(logActions).replace(/\\/g, '\\\\').replace(/"/g, '\\"'))
      );

      try {
        const json = {
          content: `{"payload": "${payload}"}`,
          account,
          date: getCurrentDate(),
          idService,
          idTerminal: Number(idTerminal),
          sn: Number(sn),
          reqType: 3,
        };

        const successCallback = ({ status }) => {
          if (typeof status !== 'undefined' && !status) {
            resetLogInLocalStorage();
          }
        };

        setSentryBreadсrumbs('sendLog', 'reqType: 3. Info about payment');

        dispatch({
          type: `${types.SEND_LOG}_JSON_REQUEST`,
          payload: { reqType: 'logException', json, successCallback }
        });
      } catch (err) {
        logErrorToSentry(
          'Не смог отправить лог по платежу',
          'SEND_LOG_ERROR',
          1,
          'SEND_LOG_ERROR',
          JSON.stringify(logActions),
          err.message
        );
      }
    }
  };

// eslint-disable-next-line no-unused-vars
export const sendInfoLogToGateway = ({ message, reqType, functionCallback }) => (dispatch) => {
  const { idTerminal } = getAuthData();
  const json = {
    content: message,
    date: getCurrentDate(),
    idTerminal: Number(idTerminal),
    reqType
  };

  const successCallback = () => {
    if (typeof functionCallback !== 'undefined') {
      functionCallback();
    }
  };

  setSentryBreadсrumbs('sendInfoLogToGateway', `reqType: ${reqType}`);

  dispatch({
    type: `${types.SEND_LOG}_JSON_REQUEST`,
    payload: { reqType: 'logException', json, successCallback }
  });
};
