export const getAmountForAdmission = (willBeCreditedAmount, tariff, measure) => {
  const amountForAdmission =
    willBeCreditedAmount ?
      parseFloat(willBeCreditedAmount / tariff).toFixed(2) :
      0;

  return `${amountForAdmission} ${measure}`;
};

export const getAddings = (willBeCreditedAmount, tariff, measure) => ({
  constraint: `${tariff} тг/${measure}`,
  amount0: parseFloat(willBeCreditedAmount / tariff).toFixed(2),
});
