
import { showSuccess } from 'actions/alert';

import * as types from './types';

export const setNotifications =
  (notifList) => (dispatch) =>
    dispatch({ type: types.SET_NOTIFICATIONS, notifList });

export const getNotifications = () => (dispatch) => {
  const REQ_TYPE = 61;
  const json = {
    notificationsType: 1
  };

  const successCallback = (data) => {
    if (data.status === 0) {
      dispatch(setNotifications(data.notifications));
    }
  };

  dispatch({
    type: `${types.GET_NOTIFICATION}_JSON_REQUEST`,
    payload: { reqType: REQ_TYPE, json, successCallback }
  });
};

export const setNotifStatus = (id, status, date) => (dispatch) => {
  const REQ_TYPE = 62;
  const json = {
    cashOutCashierNotificationID: id,
    approved: status,
  };

  if (date) json.extraditionDate = date;

  const successCallback = (data) => {
    if (data.status === 0) {
      dispatch(showSuccess(data.message));
      dispatch(getNotifications());
    }
  };

  dispatch({
    type: `${types.SAVE_NOTIFICATION_STATUS}_JSON_REQUEST`,
    payload: { reqType: REQ_TYPE, json, successCallback }
  });
};
