import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Modal from 'components/UI/Modal/modal';
import Icon from 'components/UI/Icon/index';
import PaymentsHistory from 'containers/PaymentsHistory';
import { getFiscalData } from 'helpers/fiscal';
import { makeFiscalPay, hideFiscalModal } from 'actions/fiscal';
import Alert from 'components/UI/Alert/alert';

const ServicePayment = (props) => {
  const [showOperationDetailModal, setShowOperationDetailModal] = useState(false);
  const [operationDetails, setOperationDetails] = useState({});
  const [showServicePayConfirmModal, setShowServicePayConfirmModal] = useState(false);
  const { sectionIdForCommission, sectionIdForPayment } = getFiscalData();

  const servicePayHandler = () => {
    const typeInterface = props.serviceList.filter((service) =>
      service.idService === operationDetails.idService)[0].typeInterface;

    const reqData = {
      GenerateCheck: true,
      IdDomain: 1,
      Cash: operationDetails.totalSum,
      NonCash: 0,
      Positions: [
        {
          Name: operationDetails.provider,
          IdSection: sectionIdForPayment,
          Price: operationDetails.totalSum - operationDetails.commission,
          Qty: 1,
          Storno: false,
        },
        {
          Name: operationDetails.provider,
          IdSection: sectionIdForCommission,
          Price: operationDetails.commission,
          Qty: 1,
          Storno: false,
        }
      ],
      Total: operationDetails.totalSum,
      Sn: String(operationDetails.sn)
    };

    if (typeInterface !== 1) reqData.Positions[0].Unit = { ShortName: 'шт' };
    props.makeFiscalPay(reqData);
    setCloseServiceOperationDetailModal();
    setShowServicePayConfirmModal(false);
  };

  const showOperationDetails = (data) => {
    setShowOperationDetailModal(true);
    setOperationDetails(data);
  };

  const setCloseServiceOperationDetailModal = () => {
    setShowOperationDetailModal(false);
    setOperationDetails({});
  };

  return (
    <>
      <Modal
        title="Оплата за сервис"
        isOpen={props.showModal}
        onOk={null}
        onClose={() => props.hideFiscalModal()}
        size="high"
        isFooterHidden
      >
        <div className="servicePaymentModalContent">
          <PaymentsHistory fromServicePaymentModal showOperationDetails={showOperationDetails}/>
        </div>
      </Modal>

      {showOperationDetailModal && (
      <Modal
        title={`Проведение транзакции №${operationDetails.sn}`}
        isOpen={showOperationDetailModal}
        onOk={() => setShowServicePayConfirmModal(true)}
        okText="Провести"
        onClose={setCloseServiceOperationDetailModal}
        size="small-and-high"
        isFooterHidden={false}
      >
        <Alert message="Вы собираетесь выполнить продажу в фискал" banner />
        <br />
        <div><h3>Информация о платеже:</h3></div>
        <div><b>Продажа по сервису:</b> {operationDetails.provider}</div>
        <div><b>Cумма платежа:</b> {operationDetails.totalSum} тг</div>
        <div><b>Cумма комиссии:</b> {operationDetails?.commission || 0} тг</div>
      </Modal>
      )}

      <Modal
        isOpen={showServicePayConfirmModal}
        title="Внимание!"
        onOk={servicePayHandler}
        onClose={() => setShowServicePayConfirmModal(false)}
        okText="Провести платеж"
        size="small-and-high"
      >
        <div className="dublicate-warning">
          <div className="dublicate-warning-header">
            <Icon type="warning" className="alert-error" />
          </div>
          <div className="dublicate-warning-body-title">
            <b>Вы уверены что хотите провести продажу?</b>
          </div>
        </div>
      </Modal>

    </>
  );
};

ServicePayment.propTypes = {
  showModal: PropTypes.bool.isRequired,
  serviceList: PropTypes.array.isRequired,
  makeFiscalPay: PropTypes.func.isRequired,
  hideFiscalModal: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    serviceList: state.services.serviceList,
    showModal: state.fiscal.modalData.showModal,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    makeFiscalPay,
    hideFiscalModal
  },
  dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicePayment);
