import generateCheck from '@mvsolovyev/check-file-generation';

import { getAuthData } from 'model/UserDb';
import { getHistoryParams } from 'model/Params';
import { makeFiscalPay } from 'actions/fiscal';
import { setReceiptLang, updateCheckWithCode } from 'actions/check';
import { getBalance } from 'actions/api/getBalance';
import { showError, showSuccess } from 'actions/alert';
import { sendInfoLogToGateway } from 'actions/api/logs';
import { searchPayments } from 'actions/api/paymentHistory';
import { PAY_FOR_FISCAL_SERVICE_ID } from 'constants/services';
import { getKtjSn, setKtjSn } from 'components/PayTypes/KTJ/helper';
import { printCheckFromB64, getSettingsForCheck } from 'helpers/check';
import { incrementCounterValueInLS, getServiceName } from 'helpers/pay';
import { getAccountOnMask, checkAddingsAndReturnBack, setSentryBreadсrumbs, setToLocalStorage, setObjectElement, getCurrentTimesTamp } from 'helpers/main';
import {
  initPayType,
  setCounterValueInStore,
  setTransactionNumber,
  initCurrentService,
  setOtherIdServiceForMakePay,
  setClientIIN,
  setLastSuccessPaymentData
} from 'actions/pay';

import { stopTheLoaderPayment } from 'actions/loader';
import * as types from './types';

// у фискального терминала не будут передаваться checkData и checkArr, тк чек фискал нам вернёт
export const makePay = (data, paymentMethod, checkData = {}, checkText = '', reqDataForFiscal = {}) =>
  (dispatch, getState) => {
    const isMyPostPayment = getState().mypost.isMyPostPayment || false;
    const dataForReq = {
      ...data,
      account: !isMyPostPayment ? getAccountOnMask(data) : data.account
    };
    const {
      SN,
      account,
      amount,
      commission,
      isEMoney,
      currentDate,
      nameService,
      payType,
      comment
    } = dataForReq;

    // set SN for fiscal pay
    setObjectElement(reqDataForFiscal, 'Sn', SN);

    const otherIdServiceForMakePay = getState().kassa.otherIdServiceForMakePay || 0;
    const idService = otherIdServiceForMakePay > 0 ?
      otherIdServiceForMakePay : dataForReq.idService;

    const addings = checkAddingsAndReturnBack(data.addings);
    const { idTerminal } = getAuthData();
    let amountCard = 0.00;
    let amountCardTag = '';
    let nmnls = `<nmnls> <nmnl val='1'>${amount}</nmnl> </nmnls>`;

    if (paymentMethod) { // оплата картой
      amountCard = parseFloat(amount).toFixed(2);
      amountCardTag = `<amountCard>${amountCard}</amountCard>`;
      nmnls = '';
    }

    const xml = `
      <payments>
        <payment>
          <numTrans>${SN}</numTrans>
          <idTerminal>${idTerminal}</idTerminal>
          <account>${account}</account>
          <idService>${idService}</idService>
          <amount>${parseFloat(amount).toFixed(2)}</amount>
          ${amountCardTag}
          <comission>${parseFloat(commission).toFixed(2)}</comission>
          <date>${currentDate}</date>
          <comment>${comment}</comment>
          <isEMoney>${isEMoney}</isEMoney>
          ${nmnls}
          ${addings}
        </payment>
      </payments>`;

    setSentryBreadсrumbs('pay', 'makePay - start');

    const successCallback = (response) => {
      setToLocalStorage('lastTransaction', Number(SN));
      const { payments } = response;

      setSentryBreadсrumbs('pay', `makePay - SuccessCallback. Service:${idService}, транзакция: ${SN}`);

      dispatch(sendInfoLogToGateway({
        message: `терминал: ${idTerminal}, транзакция: ${SN}, сервис: ${idService}, reqDataForFiscal: ${JSON.stringify(reqDataForFiscal)},
        callbackResult: ${JSON.stringify(response)}`,
        reqType: 'makePayData'
      }));

      if (typeof payments !== 'undefined') {
        const { finally: finallySt, numTrans: sn } = payments.check;

        if (!finallySt) { // В обработке
          dispatch({
            type: types.SET_PAYMENT_IN_PROCCESS,
            payment: {
              sn,
              dateout: currentDate,
              account,
              amount,
              com: commission,
              nameService: getServiceName(nameService, otherIdServiceForMakePay),
              idService
            }
          });
        }

        const checkTermParams = {
          reqDataForFiscal,
          sn,
          checkData,
          payType,
          checkText,
          paymentMethod,
          amount,
          otherIdServiceForMakePay
        };

        const { id } = getState().fiscal.invoiceData;

        dispatch(setLastSuccessPaymentData({ SN }));
        showPayForFiscalSuccessMsg(idService, account, dispatch, id);
        makePayCheckTerminalType(checkTermParams, dispatch);

        // если терминал фискальный, все это сделаем после отправки платежа в фискал
        if (typeof reqDataForFiscal.Positions === 'undefined') {
          setSentryBreadсrumbs('pay', 'makePay - terminal is not fiscal - call init actions');
          dispatch(initCurrentService());
          dispatch(initPayType(payType));
          dispatch(getBalance()); // Запрашиваем баланс
          dispatch(setTransactionNumber(''));
          dispatch(setOtherIdServiceForMakePay(0));
          dispatch(setClientIIN(''));
          dispatch(setReceiptLang(''));
          dispatch(stopTheLoaderPayment());
        }
      } else {
        dispatch(stopTheLoaderPayment());
        dispatch(setTransactionNumber(''));
      }
    };

    dispatch({
      type: `${types.MAKE_PAY}_XML_REQUEST`,
      payload: { reqType: 3, xml, successCallback }
    });
  };

const showPayForFiscalSuccessMsg = (idService, account, dispatch, invoiceId) => {
  // Если сервис  - оплата по счету, то выводим сообщение
  if (idService === PAY_FOR_FISCAL_SERVICE_ID) {
    if (invoiceId && Number(invoiceId) === Number(account)) {
      dispatch(showSuccess('Оплата принята. Обработка платежа может занять до 24 часов. Как только оплата пройдет, лицензия будет активирована'));
    }
  }
};

const makePayCheckTerminalType = (params, dispatch) => {
  const {
    reqDataForFiscal,
    sn,
    checkData,
    payType,
    checkText,
    paymentMethod,
    amount,
    otherIdServiceForMakePay
  } = params;

  // если терминал фискальный
  if (typeof reqDataForFiscal.Positions !== 'undefined') {
    const { idTerminal } = getAuthData();

    setSentryBreadсrumbs('pay', 'makePay - terminal is fiscal - before makeFiscalPay');
    const receiptLogData = [...checkData];

    const receiptDataWithWrapper = {
      paid: true,
      point: Number(idTerminal),
      pointTime: getCurrentTimesTamp(),
      project: 2,
      data: receiptLogData
    };

    dispatch(sendInfoLogToGateway({
      message: `Сгенерированный json для чека для транзакции ${sn}: ${JSON.stringify(receiptDataWithWrapper)}`,
      reqType: 'checkData'
    }));

    dispatch(makeFiscalPay(reqDataForFiscal, payType, checkData, otherIdServiceForMakePay));
  } else if (checkText) { // не фискальный и не КТЖ
    // получаем настройки чека
    const checkTextWithFormat = getSettingsForCheck(checkText);

    setSentryBreadсrumbs('pay', 'makePay - terminal is not a fiscal - call generateCheck');

    generateCheck('data:image/png', checkTextWithFormat, (err, dataUrl) => {
      if (!err) {
        printCheckFromB64({ check: dataUrl });
      } else {
        dispatch(showError('Возникла ошибка при отображении чека'));
      }
    });
  }

  // увеличиваем суммы в счетчиках
  if (paymentMethod === 0) {
    const result = incrementCounterValueInLS('cash', amount);

    dispatch(setCounterValueInStore('cash', result));
  }

  if (paymentMethod === 1) {
    const result = incrementCounterValueInLS('card', amount);

    dispatch(setCounterValueInStore('card', result));
  }
};

export const checkPaymentStatus = (paymentList) => (dispatch, getState) => {
  const { idTerminal } = getAuthData();

  let xmlPayments = '';

  paymentList.map(payment => {
    xmlPayments += `
      <check>
        <idTerminal>${idTerminal}</idTerminal>
        <numTrans>${payment.sn}</numTrans>
      </check>
    `;

    return xmlPayments;
  });

  const xml = `
    <payments>
      ${xmlPayments}
    </payments>`;

  const successCallback = (response) => {
    const { payments } = response;

    let hasCompletedPayments = false;

    if (typeof payments !== 'undefined' && typeof payments.check !== 'undefined') {
      [].concat(payments.check).forEach(payment => {
        // платеж обработан
        if (payment.finally === 1) {
          checkPaymentStatusSuccessPay(payment, getState().checksWithCode, dispatch);

          hasCompletedPayments = true;
        }
      });

      if (hasCompletedPayments) {
        // Запрашиваем историю платежей за тот же период
        const { dateB, dateE } = getHistoryParams();

        dispatch(searchPayments(dateB, dateE));
      }
    }
  };

  dispatch({
    type: `${types.CHECK_PAYMENT}_XML_REQUEST`,
    payload: { reqType: 3, xml, successCallback }
  });
};

const checkPaymentStatusSuccessPay = (payment, checksWithCodeState, dispatch) => {
  const { numTrans, status } = payment;
  const { checksWithCode } = checksWithCodeState;

  // Если сохранены КТЖ чеки, ожиюдащие своей отправки в АПИ, шлём статус платежа
  if (getKtjSn().length && getKtjSn() === numTrans) {
    if (!status) {
      dispatch({ type: types.SET_KTJ_PAYMENT_SUCCESSFUL, sn: numTrans });
    } else {
      setKtjSn('');
      dispatch({ type: types.SET_KTJ_PAYMENT_FAILED, sn: numTrans });
    }
  }

  // Если есть чек, для которого нужен код
  if (typeof checksWithCode[numTrans.toString()] !== 'undefined') {
    if (!status) {
      dispatch(updateCheckWithCode(
        numTrans.toString(),
        {
          ...checksWithCode[numTrans.toString()],
          isPaymentSuccess: true
        }));
    }
  }

  dispatch({ type: types.SET_PAYMENT_COMPLETED, sn: numTrans });

  return true;
};
