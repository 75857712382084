/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { LOGO_URL } from 'constants/api';
import logoKassa from 'public/images/kassa24/kassa24.svg';

export default class GroupBlock extends Component {
  getDefaultImage = (e) => {
    e.target.src = logoKassa;
  }

  render() {
    const { grbut, grname, active, selectGroup } = this.props;
    const logo = grbut || '';
    const name = grname || '';

    return (
      <button type="button" onClick={selectGroup} className={`service-block ${(active) ? 'service-block-active' : ''}`}>
        <div className="service-block-img" >
          <img src={logo ? `${LOGO_URL}${logo}` : this.getDefaultImage} alt={name} />
        </div>
        <div className="service-name">{name}</div>
      </button>
    );
  }
}

GroupBlock.propTypes = {
  grbut: PropTypes.string.isRequired,
  grname: PropTypes.string.isRequired,
  selectGroup: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired
};
