import { initPayType } from 'actions/pay';
import { showError } from 'actions/alert';
import { getErrText } from 'helpers/main';
import { setCurrentService } from 'actions/services';
import { CASHOUT_SERVICE_ID } from 'constants/services';
import { initCurrentComProfile } from 'actions/commission';
import { checkError, checkMsg } from 'helpers/onlineCheck';
import { setShowIdentificationModal, setShowIdentificationModalFromCashout } from 'actions/coid';

import * as types from './types';

export const setShowCashOutModal = (showCashOutModal) => (dispatch) => {
  dispatch({ type: types.SET_SHOW_CASHOUT_MODAL, showCashOutModal });
};

export const setConfirmed = (confirmed) => (dispatch) => {
  dispatch({ type: types.SET_CONFIRMED, confirmed });
};

export const selectCashOutService = (showCashOutModal) => (dispatch, getState) => {
  dispatch({ type: types.SET_SHOW_CASHOUT_MODAL, showCashOutModal });

  const cashOutService = getState().services.serviceList
    .filter(item => (item.idService === CASHOUT_SERVICE_ID));

  if (cashOutService.length) {
    dispatch(setCurrentService(cashOutService[0]));
    dispatch(initPayType(cashOutService[0].typeInterface));
    dispatch(initCurrentComProfile());
  }
};

export const cashOutOnlineCheck = (res, type, accountStr = '') => (dispatch) => {
  if (res.data.status === 927) {
    const accArr = accountStr.split(';');
    const account = `+7${accArr[1]}`;

    dispatch(setShowIdentificationModal(true));
    dispatch(setShowIdentificationModalFromCashout({ showIdentModalFromCashout: true,
      showIdentModalFromCashoutAccount: account }));
  } else {
    const errMsg = checkMsg(res);
    const errCode = checkError(res);

    if (errMsg) {
      dispatch(showError(errMsg));
    }

    if (!errCode) {
      const result = res.data.account;
      const { '@attributes': { st, id } } = result;

      cashOutOnlineCheckSuccess(type, result, id, st, dispatch);
    } else {
      dispatch(showError(getErrText(errCode)));
    }
  }
};

export const setStep = (step) => (dispatch) => {
  dispatch({ type: types.SET_STEP, step });
};

const cashOutOnlineCheckSuccess = (type, result, id, st, dispatch) => {
  if (type === 0) { // 1ая онлайн-проверка (получение данных)
    const { CashToTerminalAmount, CashToTerminalId, AgentName } = result;

    if (typeof CashToTerminalAmount !== 'undefined' && typeof CashToTerminalId !== 'undefined') {
      dispatch({
        type: types.SET_CASHOUT_DATA,
        CashToTerminalAmount,
        CashToTerminalId,
        AgentName,
        id
      });
    } else {
      dispatch({
        type: types.SET_CASHOUT_ACCOUNT_ID,
        id
      });
    }
  } else if (type === 1) { // 2ая онлайн-проверка (подтверждение данных)
    if (Number(st) === 0) {
      dispatch({ type: types.SET_CASHOUT_CONFIRMED });
      dispatch(setConfirmed(true));
    }
  }
};
