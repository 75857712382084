export function getWagonInfo(Cars, selectedWagon) {
  const wagonType = getWagonTypeInfo(Cars, selectedWagon);

  return [].concat(wagonType.Car).filter(item => (Number(item['@attributes'].Number) === Number(selectedWagon)))[0];
}

// Вытаскиваем тип вагона по номеру вагона (тип вагона на уровень выше)
export function getWagonTypeInfo(Cars, selectedWagon) {
  return [].concat(Cars).filter(wagonTypes =>
    [].concat(wagonTypes).filter((wagon) =>
      [].concat(wagon.Car).some((car) =>
        car['@attributes'].Number === selectedWagon
      )
    ).length
  )[0];
}

export function getWagonSales(Cars, selectedWagon) {
  const wagonType = getWagonTypeInfo(Cars, selectedWagon);

  // Делаем это всё для того чтобы понять пришли ли в типе вагона saleOnTwo и saleOnFour

  return {
    saleOnTwo: (typeof wagonType.saleOnTwo !== 'undefined'),
    saleOnFour: (typeof wagonType.saleOnFour !== 'undefined'),
    addSigns: (typeof wagonType.addSigns !== 'undefined' ? wagonType.addSigns : false),
  };
}

// сохранение SN для чеков КТЖ
export const setKtjSn = (sn) => {
  window.localStorage.setItem(
    'ktjSn',
    sn.toString()
  );
};

// Получение сохранных sn для чеков КТЖ
export const getKtjSn = () => {
  const ktjData = window.localStorage.getItem('ktjSn');

  return ktjData || '';
};
