import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Paper, TablePagination } from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import 'moment/locale/ru';
import 'flatpickr/dist/flatpickr.css';

import {
  getTheStartDateOfTheCurrentMonth,
  getTheEndOfTheCurrentDay,
  formatMoney
} from 'helpers/main';
import Modal from 'components/UI/Modal/modal';
import { getZReportList, hideFiscalModal } from 'actions/fiscal';
import CustomButton from 'components/UI/Button/button';
import { exportToXlsxZreport, getZreportFormattedDateRange } from 'helpers/fiscal';
import ExportExcelButton from 'components/UI/ExportExcelButton';
import ReportResultTable from 'components/Reports/ReportResultTable';
import ZReportsDetail from './ZReportsDetail';
import SearchContainer from './SearchContainer';

const Zreport = (props) => {
  const { zReport, showModal } = props;
  const [dateFrom, setDateFrom] = useState(getTheStartDateOfTheCurrentMonth());
  const [dateTo, setDateTo] = useState(getTheEndOfTheCurrentDay());
  const [zReportCheckedInfo, setZReportCheckedInfo] = useState({});
  const [componentRef, setComponentRef] = useState({});
  const [page, setPage] = useState(0);
  const rowsPerPage = 15;

  const dateFromStr = moment(dateFrom, 'YYYYMMDDHHmmss').format('DD.MM.YYYY');
  const dateToStr = moment(dateTo, 'YYYYMMDDHHmmss').format('DD.MM.YYYY');
  const titlePrint = `Итоговый отчет за период: ${dateFromStr} - ${dateToStr}`;

  useEffect(() => {
    searchZReport();
    // eslint-disable-next-line
  }, []);

  const changeDateRange = (dates) => {
    if (dates.length === 2) {
      const { dateFromStrCdr, dateToStrCdr } = getZreportFormattedDateRange(dates);

      setDateFrom(dateFromStrCdr);
      setDateTo(dateToStrCdr);
    }
  };

  const getReqFormatDate = (date) => moment(date, 'YYYYMMDDHHmmss').format('YYYY-MM-DDTHH:mm:ss');

  const searchZReport = useCallback(() => {
    const dateFromRequest = getReqFormatDate(dateFrom);
    const dateToRequest = getReqFormatDate(dateTo);

    props.getZReportList(dateFromRequest, dateToRequest);
  }, []);

  const zreportDetailModalCloseHandler = () => {
    setZReportCheckedInfo({});
  };

  const table = zReport.length > 0 ? (
    <TableContainer component={Paper} elevation={0} style={{ marginTop: '20px' }}>
      <Table aria-label="spanning table" classes={{ root: 'cellBorder' }}>
        <TableHead>
          <TableRow>
            <TableCell>Номер смены</TableCell>
            <TableCell>Открыта</TableCell>
            <TableCell>Закрыта</TableCell>
            <TableCell>Принято</TableCell>
            <TableCell>Сдано</TableCell>
            <TableCell>Кассир</TableCell>
            <TableCell>Общее кол-во операций</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {zReport
            .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
            .map((item) => (
              <TableRow key={item.ShiftIndex}>
                <TableCell>{item.ShiftIndex}</TableCell>
                <TableCell>{moment(item.DateOpen).format('DD.MM.YYYY HH:mm')}</TableCell>
                <TableCell>{moment(item.DateClose).format('DD.MM.YYYY HH:mm')}</TableCell>
                <TableCell>{formatMoney(item.BalanceOpen)} тг</TableCell>
                <TableCell>{formatMoney(item.BalanceClose)} тг</TableCell>
                <TableCell>{item.User.Name}</TableCell>
                <TableCell>{item.Count}</TableCell>
                <TableCell>
                  <CustomButton onClick={() => setZReportCheckedInfo(item)}>
                    Дубликат
                  </CustomButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : 'Нет z-отчетов за указанный период';

  const getPrintButton = useCallback(() => (
    <CustomButton >
      Распечатать
    </CustomButton>
  ), []);

  return (
    <>
      <Modal
        title="История z-отчетов"
        isOpen={showModal}
        onOk={null}
        onClose={props.hideFiscalModal}
        okText=""
        size="high"
        isFooterHidden
      >
        <Grid container spacing={2}>
          <SearchContainer
            dateFrom={dateFrom}
            dateTo={dateTo}
            searchZReport={searchZReport}
            changeDateRange={changeDateRange}
          />
          <Grid item sm={6} xs={12} align="right">
            {zReport.length > 0 && (
            <>
              <div>
                <ExportExcelButton onClick={exportToXlsxZreport}/>
                <ReactToPrint
                  trigger={getPrintButton}
                  content={() => componentRef}
                />
              </div>
              <ReportResultTable
                setComponentRef={setComponentRef}
                title={titlePrint}
                table={table}
              />

            </>
            )}
          </Grid>
        </Grid>
        {table}
        <TablePagination
          component="div"
          count={zReport.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
          labelRowsPerPage=""
          rowsPerPageOptions={[]}
        />
      </Modal>
      {zReportCheckedInfo.Id > 0
      && (
      <ZReportsDetail
        report={zReportCheckedInfo}
        onClose={zreportDetailModalCloseHandler}
      />
      )}
    </>
  );
};

Zreport.propTypes = {
  getZReportList: PropTypes.func.isRequired,
  zReport: PropTypes.array.isRequired,
  hideFiscalModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  zReport: state.fiscal.zReportHistory,
  showModal: state.fiscal.modalData.showModal,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    getZReportList,
    hideFiscalModal
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Zreport);
