import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-maskedinput';
import PayTypes from 'paytypes';
import ComplexReceipt from 'complex-receipt';

import IinField from 'components/IinField';
import Modal from 'components/UI/Modal/modal';
import { getReqBodyForComplexScheme } from 'helpers/pay';
import MinAndMaxAmount from 'components/MinAndMaxAmount';
import { ENERGOPOTOK_SERVICE_ID } from 'constants/services';
import { t, checkAccountOnReg, getMaskForComplexScheme } from 'helpers/main';

const { ComplexContainer } = PayTypes.containers;

// Алсеко г.Алматы (490139270)
// АлматыЭнергоСбыт физ. лица г.Алматы (6705634)
// ЕРЦКУ Караганды (102907150, 109327132)
class Complex extends Component {
  state = {
    showModal: false,
    currentInvoice: null,
    account: '',
    totalAmount: '',
    isLoading: false
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDownFowWindow);
  }

  componentDidUpdate(prevProps) {
    const { payTypeComplex } = this.props;
    const { services, id } = payTypeComplex;
    const { services: oldServices, id: oldId } = prevProps.payTypeComplex;

    if (!this.state.showModal) {
      if ((typeof services !== 'undefined' && typeof services.servicesList !== 'undefined' &&
        typeof oldServices === 'undefined') || (id !== oldId && id !== '')) {
        this.showModal();
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDownFowWindow);
  }

  onError = ({ message }) => this.props.showError(message);

  onSelectInvoice = (invoice) => this.setState({ currentInvoice: invoice });

  onChangeService = (newService) => {
    const { currentInvoice } = this.state;
    const serviceKey = this.getServiceKeyFromInvoice(currentInvoice.services, newService.id);

    if (serviceKey !== null) {
      const updatedInvoiceData = currentInvoice;

      updatedInvoiceData.services[Number(serviceKey)] = newService;

      this.sendServiceLog(newService);
      this.setState({ currentInvoice: updatedInvoiceData });
    }
  }

  sendServiceLog = ({ name, fixSum, toPay }) => {
    const amountsHaveBeenChanged = Math.ceil(fixSum) !== Math.ceil(toPay);

    // если суммы по счетчикам изменялись, отправляем информацию в лог
    if (amountsHaveBeenChanged) {
      this.props.dispatchInfoLog(
        `Изменение суммы по счетчику ${name}. Было: ${Math.ceil(fixSum)}. Стало: ${toPay}`
      );
    }
  };

  getServiceKeyFromInvoice = (services, id) =>
    Object.entries(services).find(item => {
      const [key, value] = item;

      if (value.id === id) {
        return key;
      }

      return null;
    })[0];

  onChangeTotalAmount = (totalAmount) => this.setState({ totalAmount });

  confirmPage = () => {
    const { address } = this.props.payTypeComplex;
    const { totalAmount, currentInvoice } = this.state;
    const {
      setConfirmInfo,
      showConfirmScreen,
      updateWillBeCreditedAmount,
      pay: { account },
      commission: { currentComProfile },
      kassa: { currentService: { idService } }
    } = this.props;

    updateWillBeCreditedAmount(totalAmount, currentComProfile, true);

    const confirmFields = [
      { name: 'account', value: account },
    ];

    const checkInfo = {
      input: {
        account,
        subServices: []
      },
      online: {
        address
      }
    };

    currentInvoice.services.forEach(item => {
      const newData = {
        name: item.name,
        amount: Math.ceil(item.toPay)
      };

      if (idService === ENERGOPOTOK_SERVICE_ID) {
        if (item.oplInfo && item.oplInfo.EP_FLAG === 1) {
          newData.oplInfo = item.oplInfo;
          newData.invoiceInfo = item.invoiceInfo;
        }
      }

      checkInfo.input.subServices.push(newData);
    });

    setConfirmInfo(confirmFields, this.getAddings(), checkInfo);
    showConfirmScreen();
  }

  getAddings() {
    return this.state.currentInvoice.services.map(item => ({
      subservice: item.id,
      constraint: this.props.payTypeComplex.invoices.invoiceId,
      amount0: Math.ceil(item.toPay),
      amount1: item.X,
      amount2: item.fixCount,
    }));
  }

  showModal = () => {
    this.setState({ showModal: true });
  }

  hideModal = () => this.setState({ showModal: false });

  handleOnChange(e) {
    this.setState({
      [e.target.id]: e.target.value.trim()
    });
  }

  onKeyDownFowWindow = (e) => {
    if (e.key === 'Enter' && this.state.showModal) {
      this.confirmPage();
    }
  };

  handleInputOnKeyUp = (e) => {
    if (e.key === 'Enter') {
      this.validateAndCheck();
    }
  }

  validateAndCheck = () => {
    const { account } = this.state;
    const {
      onlineCheck,
      showError,
      iinIsValidOrEmpty,
      updateFieldValue,
      payTypeComplex: { id },
      kassa: { currentService },
    } = this.props;

    this.setState({ isLoading: false });
    if (!iinIsValidOrEmpty()) return;

    if (account) {
      const { regExp, maskEdit } = currentService;

      if (checkAccountOnReg(account, regExp, maskEdit)) {
        updateFieldValue('account', account);

        if (id !== '' && Number(id.toString()) === Number(account.toString())) {
          return this.showModal();
        }
        this.setState({ isLoading: true });

        return onlineCheck(currentService, account)
          .then(() => {
            this.setState({ isLoading: false });
          });
      }

      return showError(
        'Проверьте, пожалуйста, и повторите попытку',
        'Лицевой счет некорректен'
      );
    }

    return showError(
      'Лицевой счет обязателен для заполнения. Заполните, пожалуйста, и повторите попытку.',
      'Все поля обязательны для заполнения'
    );
  }

  render() {
    const { kassa: { currentService: { maskEdit, idService } }, payTypeComplex } = this.props;
    const { showModal } = this.state;
    const mask = getMaskForComplexScheme(maskEdit);

    return (
      <>
        <Modal
          title={t('confirmOrSupplementTheInfo')}
          isOpen={showModal}
          onOk={this.confirmPage}
          onClose={this.hideModal}
          okText={t('next')}
          size="big"
        >
          <ComplexReceipt
            response={getReqBodyForComplexScheme(payTypeComplex)}
            onError={this.onError}
            onSelectInvoice={this.onSelectInvoice}
            onChangeService={this.onChangeService}
            handleOnKeyUp={this.handleInputOnKeyUp}
            onChangeTotalAmount={this.onChangeTotalAmount}
            serviceId={idService}
          />
        </Modal>

        <div className="pay-form">
          <IinField />
          <MaskedInput
            mask={mask}
            type="tel"
            autoComplete="off"
            name="account"
            id="account"
            maxLength={40}
            placeholder="№ лицевого счета"
            placeholderChar=" "
            onKeyUp={this.handleInputOnKeyUp}
            onChange={(e) => this.handleOnChange(e)}
            value={this.state.account}
            formatCharacters={{
              W: {
                validate(char) { return /[\wа-яА-Я]/.test(char); },
              },
              D: {
                validate(char) { return /^[0-9.]+$/.test(char); },
              },
            }}
            disabled={this.state.isLoading}
          />
          <MinAndMaxAmount idService={idService} />
          <button
            type="button"
            className="btn-primary"
            onClick={this.validateAndCheck}
          >
            {t('next')}
          </button>
        </div>
      </>
    );
  }
}

Complex.propTypes = {
  payTypeComplex: PropTypes.object.isRequired,
  commission: PropTypes.object.isRequired,
  kassa: PropTypes.object.isRequired,
  pay: PropTypes.object.isRequired,
  updateFieldValue: PropTypes.func.isRequired,
  onlineCheck: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  updateWillBeCreditedAmount: PropTypes.func.isRequired,
  setConfirmInfo: PropTypes.func.isRequired,
  showConfirmScreen: PropTypes.func.isRequired,
  dispatchInfoLog: PropTypes.func.isRequired,
  iinIsValidOrEmpty: PropTypes.func.isRequired
};

export default ComplexContainer(Complex);
