/* eslint-disable array-callback-return */
import React from 'react';
import PayTypes from 'paytypes';
import MaskedInput from 'react-maskedinput';

import IinField from 'components/IinField';
import Select from 'components/UI/Select/Select';
import { t, getMaskForInput, parseAccount, checkAccountOnReg } from 'helpers/main';
import { KT_REGIONS } from 'constants/all';

const KAZAKHTELEKOM_BY_PHONE = [3523, 10605];
const KAZAKHTELEKOM_BY_REGION = [7319, 10604];

const {
  containers: { KazaktelecomContainer },
  templates: { KazaktelecomTemplate }
} = PayTypes;

class Kazaktelecom extends KazaktelecomTemplate {
  state = {
    account: '',
    regionId: '',
    payByPhone: true,
    isLoading: false
  };

  componentDidMount() {
    this.setPayTypeOnServiceId();
    document.addEventListener('keydown', this.onKeyDownFowWindow);
  }

  componentDidUpdate(prevProps) {
    const {
      pay: { willBeCreditedAmount },
      payTypeKazaktelecom: { debt, overpayment },
      commission: { currentComProfile },
    } = this.props;

    if (prevProps.payTypeKazaktelecom.debt !== debt && debt) {
      if (!Number(willBeCreditedAmount)) {
        this.props.updateWillBeCreditedAmount(
          Math.ceil(debt),
          currentComProfile
        );
      }

      this.confirm();
    }

    if (prevProps.payTypeKazaktelecom.overpayment !== overpayment && overpayment) {
      this.props.updateWillBeCreditedAmount(
        '',
        currentComProfile
      );
      this.confirm();
    }

    this.setPayTypeOnServiceId();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDownFowWindow);
  }

  onKeyDownFowWindow = (e) => {
    if (e.key === 'Enter') {
      this.validateAndCheck();
    }
  };

  setPayTypeOnServiceId = () => {
    const { idService } = this.props.kassa.currentService;

    if (KAZAKHTELEKOM_BY_REGION.includes(idService) && this.state.payByPhone) {
      this.setPayByAccountNumber(false);
    }

    if (KAZAKHTELEKOM_BY_PHONE.includes(idService) && !this.state.payByPhone) {
      this.setPayByAccountNumber(true);
    }
  };

  setPayByAccountNumber = (payByPhone) => this.setState({ payByPhone });

  confirm() {
    const {
      setConfirmInfo,
      initPayScheme,
      showEnterAmountScreen,
      pay: { account },
      payTypeKazaktelecom: { city, id2, debt, overpayment },
    } = this.props;

    const confirmField = [
      { name: 'account', value: account }
    ];

    if (overpayment) confirmField.push({ name: 'overpayment', value: overpayment });
    else if (debt) confirmField.push({ name: 'debt', value: debt });
    confirmField.push({ name: 'city', value: city });

    const checkInfo = {
      input: {
        account,
      },
    };

    if (!this.state.payByPhone) {
      checkInfo.input.region = this.state.regionId;
    }

    const addings = {
      constraint: id2,
    };

    setConfirmInfo(confirmField, addings, checkInfo);
    showEnterAmountScreen();
    initPayScheme();
  }

  handleOnAccountChange = (value) => this.setState({ account: value.trim() });

  getRegionsOptions = () => {
    const ktRegions = [];

    KT_REGIONS.map(({ description, townId }) => {
      ktRegions.push({ key: townId, value: description });
    });

    return ktRegions;
  };

  isSuccessCheckMaskByPhone = (accountOnMask) => {
    const {
      payTypeKazaktelecom: { id },
    } = this.props;
    const { payByPhone, } = this.state;

    return (payByPhone && accountOnMask.toString() === id.toString());
  }

    isSuccessCheckMaskByAccount = (accountOnMask) => {
      const {
        payTypeKazaktelecom: { id },
      } = this.props;
      const { payByPhone, regionId } = this.state;

      return (!payByPhone && `${regionId}-${accountOnMask}`.toString() === id.toString());
    }

    getAccountByMaskForOnlineCheck=(accountOnMask) => {
      const { regionId, payByPhone } = this.state;

      return payByPhone ? accountOnMask : `${regionId}-${accountOnMask}`;
    }

  validateAndCheck = () => {
    const { account, regionId, payByPhone } = this.state;
    const {
      onlineCheck,
      showError,
      iinIsValidOrEmpty,
      updateFieldValue,
      kassa: { currentService },
    } = this.props;

    this.setState({ isLoading: false });
    if (!iinIsValidOrEmpty()) return;
    if (account) {
      if (!payByPhone && !regionId) {
        return showError(
          'Выберите регион и повторите попытку',
          'Необходимо выбрать регион'
        );
      }

      const { idService, regExp, maskEdit } = currentService;

      if (
        (KAZAKHTELEKOM_BY_PHONE.includes(idService) &&
          checkAccountOnReg(account, regExp, maskEdit)) ||
        KAZAKHTELEKOM_BY_REGION.includes(idService)
      ) {
        const accountOnMask = this.getAccountOnMask(payByPhone, maskEdit, account);

        updateFieldValue('account', accountOnMask);

        if (this.isSuccessCheckMaskByPhone(accountOnMask) ||
        this.isSuccessCheckMaskByAccount(accountOnMask)) {
          return this.confirm();
        }
        this.setState({ isLoading: true });

        return onlineCheck(
          currentService,
          this.getAccountByMaskForOnlineCheck(accountOnMask)
        ).then(() => { this.setState({ isLoading: false }); });
      }

      return showError(
        'Лицевой счет некорректен. Проверьте, пожалуйста, и повторите попытку.'
      );
    }

    return showError(
      'Лицевой счет обязателен для заполнения. Заполните, пожалуйста, и повторите попытку.'
    );
  };

  getAccountOnMask = (payByPhone, maskEdit, account) => payByPhone
    ? parseAccount(maskEdit, account)
    : account;

  selectRegion = (regionId) => this.setState({ regionId });

  renderComponent() {
    const {
      kassa: {
        currentService: { maskEdit },
      },
    } = this.props;
    const { payByPhone } = this.state;

    return (
      <div className="pay-form">
        <IinField/>
        {!payByPhone && (
          <div className="form-group">
            <Select
              title="Выберите регион"
              options={this.getRegionsOptions()}
              onchange={(region) => this.selectRegion(region)}
            />
          </div>
        )}
        <div className="form-group">
          <MaskedInput
            type="tel"
            id="account"
            autoComplete="off"
            maxLength={40}
            mask={
              !payByPhone ? '11111111111111111111' : getMaskForInput(maskEdit)
            }
            placeholder={!payByPhone ? '№ лицевого счета' : 'Номер телефона'}
            placeholderChar=" "
            onChange={(e) => this.handleOnAccountChange(e.target.value)}
            value={this.state.account}
            formatCharacters={{
              W: {
                validate(char) {
                  return /[\wа-яА-Я-]/.test(char);
                },
              },
            }}
            disabled={this.state.isLoading}
          />
        </div>
        <div className="form-group">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => this.validateAndCheck()}
          >
            {t('next')}
          </button>
        </div>
      </div>
    );
  }
}

export default KazaktelecomContainer(Kazaktelecom);
