import React, { useEffect, useState } from 'react';
import { getNotifications, setNotifStatus } from 'actions/notification';
import NotifList from 'components/Notifications/NotifList';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import 'components/Notifications/style.scss';
import Icon from 'components/UI/Icon';
import { IconButton, Menu } from '@material-ui/core';
import EditNotification from 'components/Notifications/EditNotification';

const Notifications = (props) => {
  const { cashierNotifications } = props;
  const [openModal, setOpenModal] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [itemData, setItemData] = useState({ title: '', id: 0 });

  useEffect(() => {
    props.getNotifications();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const handlerUpdateNotif = (id, status, time) => {
    setOpenEdit(false);
    props.setNotifStatus(id, status, time);
  };

  const onCloseEditModal = () => {
    setOpenEdit(false);
  };

  const handlerAfterCheck = (id, title) => {
    setItemData({ id, title });
    setOpenEdit(true);
    onCloseModal();
  };

  return (
    <>

      <IconButton
        id="notifAnchor"
        onMouseEnter={() => cashierNotifications.length > 0 ? setOpenModal(true) : {}}
        className={cashierNotifications.length > 0 ? 'pulse' : 'pulse-disable'}
      >
        <Icon type="notification"/>
      </IconButton>
      <Menu
        open={openModal}
        onClose={onCloseModal}
        anchorEl={document.getElementById('notifAnchor')}
        onMouseLeave={onCloseModal}
        className="MenuList"
        style={{
          top: '0',
          marginTop: '40px' }}
        PaperProps={{
          style: {
            maxHeight: '230px',
            padding: '0'
          },
        }}
      >
        <NotifList list={cashierNotifications} handlerAfterCheck={handlerAfterCheck}/>
      </Menu>

      <EditNotification
        itemData={itemData}
        open={openEdit}
        onSend={handlerUpdateNotif}
        onCloseModal={onCloseEditModal}
      />
    </>
  );
};

Notifications.propTypes = {
  getNotifications: PropTypes.func.isRequired,
  setNotifStatus: PropTypes.func.isRequired,
  cashierNotifications: PropTypes.array.isRequired

};

function mapStateToProps(state) {
  return {
    cashierNotifications: state.notification.cashierNotifications,

  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getNotifications,
      setNotifStatus,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
