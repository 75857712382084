import { useState, useEffect, useCallback } from 'react';

import { resetAuthData } from 'model/UserDb';
import { setFiscalData } from 'helpers/fiscal';

// расскоментировать когда будет готов бекенд
// import { getBalance } from 'actions/api/getBalance';

const LogoutTimer = () => {
  const [isActive, setIsActive] = useState(true);
  const TEN_MINUTES_IN_MILLISECONDS = 10 * 60 * 1000; // 10 минут в миллисекундах

  const expireSessionHandler = useCallback(() => {
    setFiscalData({ token: '' });
    resetAuthData();

    window.location = '/session-ended';

    // props.getBalance(); // расскоментировать когда будет готов бекенд
  }, []);

  useEffect(() => {
    let inactivityTimeout;

    const handleUserActivity = () => {
      clearTimeout(inactivityTimeout);

      inactivityTimeout = setTimeout(() => {
        setIsActive(false);
        expireSessionHandler();
      }, TEN_MINUTES_IN_MILLISECONDS);
    };

    handleUserActivity();

    // Добавляем обработчики событий для отслеживания активности пользователя
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);
    document.addEventListener('scroll', handleUserActivity);

    // Очищаем обработчики событий при размонтировании компонента
    return () => {
      clearTimeout(inactivityTimeout);
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
      document.removeEventListener('scroll', handleUserActivity);
    };
  }, []);

  useEffect(() => {
    if (!isActive) {
      expireSessionHandler();
    }
  }, []);

  return null;
};

/*
расскоментировать когда будет готов бекенд

LogoutTimer.propTypes = {
  getBalance: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getBalance }, dispatch);
}

export default (connect(null, mapDispatchToProps)(LogoutTimer));
*/

export default LogoutTimer;
