import React, { Component } from 'react';
import PropTypes from 'prop-types';
import logoKassa from 'public/images/kassa24/kassa24.svg';
import { CASH_OUT_PAY_TYPE } from 'constants/payTypes';

export default class ServiceBlock extends Component {
  getDefaultImage = (e) => {
    e.target.src = logoKassa;
  }

  render() {
    const { selectService, active, name, logo, typeInterface } = this.props;

    return (
      <button
        className={`service-block ${(active) ? 'service-block-active' : ''}`}
        onClick={() => !active ? selectService() : () => { /* This is intentional */ }}
      >
        <div className="service-block-img">
          <img
            src={logo || this.getDefaultImage}
            alt={name}
            style={typeInterface === CASH_OUT_PAY_TYPE ? { width: 110, height: 80 } : {}}
          />
        </div>
        <div className="service-name">{typeInterface === CASH_OUT_PAY_TYPE ? 'Выдача наличных' : name}</div>
      </button>
    );
  }
}

ServiceBlock.defaultProps = {
  logo: '',
  typeInterface: 0,
};

ServiceBlock.propTypes = {
  logo: PropTypes.string,
  typeInterface: PropTypes.number,
  name: PropTypes.string.isRequired,
  selectService: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};
