import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import PayTypes from 'paytypes';
import { getMinAndMaxAmount } from 'model/Services';
import { updateMinServiceAmount, updateMaxServiceAmount } from 'actions/pay';
import { checkMinAndMaxParamsForDynamic1 } from 'helpers/pay';
import { PAY_SCHEMES } from 'constants/payTypes';

const {
  updateMaxServiceAmount: updateMaxServiceAmountForDynamic, // используется для аддингов
  updateMinServiceAmount: updateMinServiceAmountForDynamic // используется для аддингов
} = PayTypes.actions.dynamic;

const MinAndMaxAmount = (props) => {
  const {
    minAmount,
    maxAmount,
    isDynamic,
    currentService,
    min,
    max,
    idService
  } = props;
  const [minAmountState, setMinAmountState] = useState(0);
  const [maxAmountState, setMaxAmountState] = useState(0);

  useEffect(() => {
    if ((minAmount && maxAmount) || (minAmount && !maxAmount) || (!minAmount && maxAmount)) {
      setMinAndMaxAmountValuesFromProps();
      checkMinAndMaxParamsForDynamic1Handler();
    } else {
      getMinAndMaxAmountValues();
    }
  }, []);

  useEffect(() => {
    if (Number(minAmountState)) {
      props.updateMinServiceAmount(minAmountState);
      if (isDynamic) {
        props.updateMinServiceAmountForDynamic(minAmountState);
      }
    }
  }, [minAmountState]);

  useEffect(() => {
    if (Number(maxAmountState)) {
      props.updateMaxServiceAmount(maxAmountState);
      if (isDynamic) {
        props.updateMaxServiceAmountForDynamic(maxAmountState);
      }
    }
  }, [maxAmountState]);

  const checkMinAndMaxParamsForDynamic1Handler = () => {
    const { typeInterface } = currentService;
    const { totalMin, totalMax } = checkMinAndMaxParamsForDynamic1({
      minAmount,
      maxAmount,
      isDynamic1Scheme: typeInterface === PAY_SCHEMES.DYNAMIC1,
      min,
      max
    });

    if (typeInterface === PAY_SCHEMES.DYNAMIC1) {
      setMinAndMaxValues(totalMin, totalMax);
    }
  };

  const setMinAndMaxValues = (minValue, maxValue) => {
    setMinAmountState(minValue);
    setMaxAmountState(maxValue);
  };

  const setMinAndMaxAmountValuesFromProps = () => {
    setMinAmountState(minAmount);
    setMaxAmountState(maxAmount);
  };

  const getMinAndMaxAmountValues = async () => {
    const { minAmount: minAmountValue, maxAmount: maxAmountValue } =
     await getMinAndMaxAmount(idService);

    setMinAmountState(minAmountValue);
    setMaxAmountState(maxAmountValue);
  };

  const getMinMaxAmountContent = () => {
    if (minAmountState > 0 && maxAmountState > 0) {
      return `Минимум ${minAmountState} тг, максимум ${maxAmountState} тг с учетом комиссии`;
    }

    if (minAmountState > 0 && !maxAmountState) {
      return `Минимум ${minAmountState} тг с учетом комиссии`;
    }

    if (!minAmountState && maxAmountState > 0) {
      return `Максимум ${maxAmountState} тг с учетом комиссии`;
    }

    return 'Минимальная и максимальная сумма оплаты не указаны';
  };

  return getMinMaxAmountContent();
};

MinAndMaxAmount.propTypes = {
  idService: PropTypes.number.isRequired,
  minAmount: PropTypes.number.isRequired,
  maxAmount: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  isDynamic: PropTypes.bool,
  updateMaxServiceAmountForDynamic: PropTypes.func,
  updateMinServiceAmountForDynamic: PropTypes.func,
  updateMinServiceAmount: PropTypes.func,
  updateMaxServiceAmount: PropTypes.func,
  currentService: PropTypes.object.isRequired,

};

MinAndMaxAmount.defaultProps = {
  isDynamic: false,
  updateMinServiceAmount: () => { /* This is intentional */ },
  updateMaxServiceAmount: () => { /* This is intentional */ },
  updateMaxServiceAmountForDynamic: () => { /* This is intentional */ },
  updateMinServiceAmountForDynamic: () => { /* This is intentional */ },
};

function mapStateToProps(state) {
  return ({
    minAmount: state.payment.minAmount,
    maxAmount: state.payment.maxAmount,
    max: state.pay.max,
    min: state.pay.min,
    currentService: state.kassa.currentService,
  });
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMaxServiceAmountForDynamic,
    updateMinServiceAmountForDynamic,
    updateMinServiceAmount,
    updateMaxServiceAmount,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MinAndMaxAmount);
