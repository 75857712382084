import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Divider } from '@material-ui/core';
import 'components/Notifications/style.scss';

const NotifList = ({ list, handlerAfterCheck }) => {
  const setEditNotification = (id, title) => {
    handlerAfterCheck(id, title);
  };

  const getNotificationShort = (title) => {
    const amount = title.replace(/[А-я]/g, '');

    return `Заявка на выдачу: ${Number(amount).toLocaleString()} тг`;
  };

  return (
    <>
      {list.map((item, key) => {
        const title = item.Message;

        return (
          <div key={item.IDCashierCashOutNotifications}>
            {key > 0 && <Divider />}
            <MenuItem
              className="MenuItem"
              onClick={() => setEditNotification(item.IDCashierCashOutNotifications, title)}
            >{getNotificationShort(title)}
            </MenuItem>

          </div>
        );
      }
      )}
    </>
  );
};

NotifList.propTypes = {
  list: PropTypes.array.isRequired,
  handlerAfterCheck: PropTypes.func.isRequired

};

export default NotifList;
