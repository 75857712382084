/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PayTypes from 'paytypes';
import MaskedInput from 'react-maskedinput';
import { v1 as uuidv1 } from 'uuid';

import { getMaskForInput, t, parseAccount, checkAccountOnReg } from 'helpers/main';
import Select from 'components/UI/Select/Select';
import Alert from 'components/UI/Alert/alert';

const {
  containers: { KaspiBankContainer },
  templates: { KaspiBankTemplate }
} = PayTypes;

class KaspiBank extends KaspiBankTemplate {
  state = {
    account: '',
    dateOfBirth: '',
    selectOptions: [],
    cloneAgreements: [],
    isSubmitClicked: false,
    isMultipleContract: false,
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDownFowWindow);
  }

  componentDidUpdate(prevProps) {
    const { lastName, firstName } = this.props.payTypeKaspiBank;

    if ((prevProps.payTypeKaspiBank.lastName !== lastName && lastName) &&
      (prevProps.payTypeKaspiBank.firstName !== firstName && firstName)) {
      this.setAgreements();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDownFowWindow);
  }

  onKeyDownFowWindow = (e) => {
    const { showRechargeTheCardScreen, showRechargeTheWalletScreen } = this.props.payTypeKaspiBank;

    if (e.key === 'Enter') {
      if (showRechargeTheCardScreen || showRechargeTheWalletScreen) {
        this.validateAndCheck();
      }
    }
  };

  setisSubmitClicked(val) {
    this.setState({ isSubmitClicked: val, });
  }

  setAgreements() {
    this.setState(
      { cloneAgreements: this.props.payTypeKaspiBank.agreements },
      () => this.confirm()
    );
  }

  handleSelect(idx) {
    const { agreements } = this.props.payTypeKaspiBank;
    const tempAgreements = agreements.filter((_, index) => index === idx - 1);

    this.props.updateFieldValue('account', tempAgreements[0]['@attributes'].agreementNumber);
    this.setState({ cloneAgreements: tempAgreements });
  }

  confirm() {
    const {
      kassa: { currentService: { maskEdit } },
      pay: { dateOfBirth },
      initPayScheme,
      setConfirmInfo,
      showEnterAmountScreen,
      payTypeKaspiBank: { agreements, lastName, firstName, middleName },
    } = this.props;
    const { cloneAgreements, account } = this.state;
    const fio = `${lastName} ${firstName} ${middleName}`;

    if (cloneAgreements.length === 1) {
      const agreementItem = cloneAgreements[0];
      const {
        agreementType,
        agreementNumber,
        agreementId,
        maxReceiptSum,
        cardNumber
      } = agreementItem['@attributes'];

      const confirmField = [
        { name: 'fio', value: fio },
        { name: 'contract', value: agreementNumber },
      ];

      const checkInfo = {
        input: {
          product: agreementType,
          phone: parseAccount(maskEdit, account),
          birthDate: dateOfBirth.replace(/./g, ''),
        },
        online: {
          agreement: {
            id: agreementId,
            card: cardNumber,
            type: agreementType
          }
        }
      };

      if (agreementType === 'R') {
        checkInfo.input.uuid = `AP${uuidv1()}`;
      }

      if (agreementType === 'W') {
        checkInfo.online.agreement.maxReceiptSum = maxReceiptSum;
      }
      setConfirmInfo(confirmField, this.getAddings(maskEdit), checkInfo);
      showEnterAmountScreen();
      initPayScheme();
    } else {
      const selectOptions = [];

      for (let i = 0; i < agreements.length; i++) {
        const agreementItem = agreements[i.toString()];

        const {
          agreementNumber,
        } = agreementItem['@attributes'];

        selectOptions.push({ key: i + 1, value: agreementNumber });
      }

      this.setState({ selectOptions });
    }
  }

  handleOnChange(e) {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  validateAndCheck() {
    const {
      onlineCheck,
      kassa: { currentService },
      showError,
      payTypeKaspiBank,
      updateFieldsValue,

    } = this.props;
    const { account, dateOfBirth, isSubmitClicked, cloneAgreements } = this.state;

    if (isSubmitClicked && cloneAgreements.length && account) {
      return this.confirm();
    }
    if (account && dateOfBirth) {
      const { regExp, maskEdit } = currentService;

      if (checkAccountOnReg(account, regExp, maskEdit)) {
        updateFieldsValue({ account, dateOfBirth });
        let type = 'W';

        if (payTypeKaspiBank.showRechargeTheCardScreen) {
          type = 'R';
        }

        const date = dateOfBirth.split('.');
        const dateOfBirthTemp = `${date[1]}/${date[0]}/${date[2]}`;
        const accountOnMask = parseAccount(currentService.maskEdit, account);

        this.setisSubmitClicked(true);

        if (payTypeKaspiBank.agreements.length > 1) {
          this.setState({ isMultipleContract: true });
        }

        if (accountOnMask.indexOf('undefined') === -1) {
          return onlineCheck(currentService, `8${accountOnMask}@${dateOfBirthTemp}@${type}`);
        }
      }

      return showError(
        'Проверьте, пожалуйста, и повторите попытку',
        'Лицевой счет некорректен'
      );
    }

    return showError(
      'Проверьте, пожалуйста, и повторите попытку',
      'Все поля обязательны для заполнения'
    );
  }

  renderComponent = () => {
    const {
      goToPayForTheCard,
      goToPayForTheWallet,
      kassa: { currentService: { maskEdit } },
      payTypeKaspiBank: { showRechargeTheCardScreen, showRechargeTheWalletScreen },
    } = this.props;
    const { selectOptions } = this.state;

    if (!showRechargeTheCardScreen && !showRechargeTheWalletScreen) {
      return (
        <div className="pay-form">
          <div className="form-group">
            <button
              type="button"
              className="btn btn-primary large"
              onClick={() => goToPayForTheCard()}
            >
              {t('replenishYourCard')}
            </button>
          </div>
          <div className="form-group">
            <button
              type="button"
              className="btn btn-primary large"
              onClick={() => goToPayForTheWallet()}
            >{t('replenish')} Kaspi {t('wallet')}
            </button>
          </div>
        </div>
      );
    }
    const mask = getMaskForInput(maskEdit);

    return (
      <div className="pay-form">
        <button
          type="button"
          onClick={() => this.props.initPayScheme()}
          className="back-text buttonWithoutStyles"
        >
          {t('back')}
        </button>
        <div className="form-group">
          <MaskedInput
            mask={mask}
            type="tel"
            id="account"
            autoComplete="off"
            maxLength={40}
            placeholder="Номер телефона"
            placeholderChar=" "
            onChange={(e) => this.handleOnChange(e)}
            formatCharacters={{
              W: {
                validate(char) { return /[\wа-яА-Я \-0-9]/.test(char); },
              }
            }}
          />
        </div>
        <div className="form-group">
          <MaskedInput
            mask="11.11.1111"
            type="tel"
            id="dateOfBirth"
            autoComplete="off"
            maxLength={40}
            placeholder="Дата рождения"
            placeholderChar=" "
            onChange={(e) => this.handleOnChange(e)}
            formatCharacters={{
              W: {
                validate(char) { return /[\wа-яА-Я \-0-9]/.test(char); },
              }
            }}
          />
        </div>
        <div className="form-group alert-form-group">
          {selectOptions.length ? (
            <>
              <span className="alert-kaspi"><Alert message="Выберите договор" /></span>
              <Select
                title="Выбрать"
                options={selectOptions}
                onchange={(idx) => this.handleSelect(idx)}
              />
            </>
          )
            : null
          }
        </div>
        <div className="form-group">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => this.validateAndCheck()}
          >
            {t('next')}
          </button>
        </div>
      </div>
    );
  }
}

export default KaspiBankContainer(KaspiBank);
