/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Dropdown from 'components/UI/Dropdown/dropdown';
import { showError } from 'actions/alert';
import { goToEndSessionPage } from 'actions/navigate';
import { setFiscalScreen, setShowLicenseModal, setIsAuthInFiscal, setAuthDataIsRequested, getSections } from 'actions/fiscal';
import { resetAuthData as resetApAuthData } from 'model/UserDb';

import {
  getShiftStatus,
  setFiscalData,
  isTheShiftActive,
  isFiscalTokenAlive,
  isShiftOpenedByTheCashier,
  isShiftOpenedByOtherCashier,
} from 'helpers/fiscal';
import { FISCAL_SCREENS, FISCAL_TARIFFS } from 'constants/fiscal';

const MENU_ITEMS = FISCAL_SCREENS;

const MenuOperations = ({
  setTypeOperation,
  setShowPinCodeModal,
  setNeedToOpenShift,
  setOpeningShiftCallback,
}) => {
  const dispatch = useDispatch();
  const fiscal = useSelector(state => state.fiscal);
  const {
    tariff,
    isFiscal,
    blockReason,
    isKkmBlocked,
    isAuthInFiscal,
    kkmInfo: { Lock, IdShift }
  } = fiscal;

  const [fiscalItems, setFiscalItems] = useState([]);

  useEffect(() => {
    if (isFiscal && isAuthInFiscal) {
      const menuItems = [
        {
          name: 'Покупка',
          key: MENU_ITEMS.BUY,
        },
        {
          name: 'Продажа',
          key: MENU_ITEMS.SELL,
        },
        {
          name: 'Оплата за сервис',
          key: MENU_ITEMS.SERVICE_PAYMENT,
        },
        {
          name: 'Служебный приход',
          key: MENU_ITEMS.INCOME,
        },
        {
          name: 'Служебный расход',
          key: MENU_ITEMS.OUTCOME,
        },
        {
          name: 'Возврат операции',
          key: MENU_ITEMS.REFUND,
        },
        {
          name: 'Обновить секции',
          key: MENU_ITEMS.UPDATE_SECTIONS,
        },
        {
          name: 'X-отчет',
          key: MENU_ITEMS.X_REPORT,
        },
        {
          name: 'История Z-отчетов',
          key: MENU_ITEMS.Z_REPORTS_HISTORY,
        }
      ];

      if (isFiscal && isAuthInFiscal && getShiftStatus(Lock, IdShift) === 'Открыта') {
        menuItems.push({
          name: 'Закрыть смену',
          key: MENU_ITEMS.CLOSE_SHIFT,
          classname: 'dropdown-exit'
        });
      }

      if (isFiscal && isAuthInFiscal && getShiftStatus(Lock, IdShift) === 'Закрыта') {
        menuItems.push({
          name: 'Открыть смену',
          key: MENU_ITEMS.OPEN_SHIFT,
          classname: 'dropdown-exit'
        });
      }

      setFiscalItems(menuItems);
    }
  }, [isFiscal, isAuthInFiscal, Lock, IdShift]);

  const clickOnFiscalMenuItem = useCallback(
    (key) => {
      let callback = () => { /* This is intentional */ };

      switch (key) {
        case MENU_ITEMS.BUY: {
          callback = getMenuLinkForBuy();
          break;
        }
        case MENU_ITEMS.SELL: {
          callback = getMenuLinkForSell();
          break;
        }
        case MENU_ITEMS.INCOME:
          callback = () => dispatch(setFiscalScreen(FISCAL_SCREENS.INCOME));
          break;
        case MENU_ITEMS.OUTCOME:
          callback = () => dispatch(setFiscalScreen(FISCAL_SCREENS.OUTCOME));
          break;
        case MENU_ITEMS.SERVICE_PAYMENT:
          callback = () => dispatch(setFiscalScreen(FISCAL_SCREENS.SERVICE_PAYMENT));
          break;
        case MENU_ITEMS.REFUND:
          callback = () => dispatch(setFiscalScreen(FISCAL_SCREENS.REFUND));
          break;
        case MENU_ITEMS.X_REPORT:
          callback = () => dispatch(setFiscalScreen(FISCAL_SCREENS.X_REPORT));
          break;
        case MENU_ITEMS.Z_REPORTS_HISTORY:
          callback = () => dispatch(setFiscalScreen(FISCAL_SCREENS.Z_REPORTS_HISTORY));
          break;
        case MENU_ITEMS.UPDATE_SECTIONS:
          callback = () => dispatch(getSections(true));
          break;
        case MENU_ITEMS.CLOSE_SHIFT:
          callback = () => openAndCloseShiftHadnler(FISCAL_SCREENS.CLOSE_SHIFT);
          break;
        case MENU_ITEMS.OPEN_SHIFT:
          callback = () => openAndCloseShiftHadnler(FISCAL_SCREENS.OPEN_SHIFT);
          break;
        default: break;
      }

      if (!isFiscalTokenAlive()) {
        setFiscalData({ token: '' });
        resetApAuthData();

        dispatch(setIsAuthInFiscal(false));
        dispatch(setAuthDataIsRequested(false));

        return dispatch(goToEndSessionPage());
      }

      if (checkKkmStatus()) {
        if (isShiftOpenedByTheCashier(Lock, IdShift) || key === MENU_ITEMS.OPEN_SHIFT) {
          if (isTheShiftActive() ||
              key === MENU_ITEMS.CLOSE_SHIFT ||
              key === MENU_ITEMS.OPEN_SHIFT
          ) {
            return callback();
          }

          return dispatch(showError('Смена просрочена. Закройте текущую смену и откройте новую'));
        }

        if (isShiftOpenedByOtherCashier(Lock, IdShift)) {
          dispatch(showError(
            'Нельзя продолжить работу с кассой, пока за ней работает другой кассир',
            'Смена открыта другим кассиром'
          ));

          return;
        }

        setNeedToOpenShift(true);
        setOpeningShiftCallback(callback);
      }
    },
    [tariff, Lock, IdShift]
  );

  const getMenuLinkForSell = () => {
    if (tariff === FISCAL_TARIFFS.PAYING) {
      return () => dispatch(setFiscalScreen(FISCAL_SCREENS.SELL));
    }

    return () => dispatch(setShowLicenseModal(true));
  };

  const getMenuLinkForBuy = () => {
    if (tariff === FISCAL_TARIFFS.PAYING) {
      return () => dispatch(setFiscalScreen(FISCAL_SCREENS.BUY));
    }

    return () => dispatch(setShowLicenseModal(true));
  };

  // надо обновить реакт-редакс и потом это вынести в один хук. повторяется
  const checkKkmStatus = () => {
    if (!isFiscal) return true;

    if (isFiscal && !isKkmBlocked) return true;

    dispatch(showError(blockReason));

    return false;
  };

  const openAndCloseShiftHadnler = (typeOperation) => {
    setShowPinCodeModal(true);
    setTypeOperation(typeOperation);
  };

  return (
    <Dropdown
      title="Операции"
      menuItems={fiscalItems}
      clickOnMenuItem={(key) => clickOnFiscalMenuItem(key)}
    />
  );
};

MenuOperations.propTypes = {
  setTypeOperation: PropTypes.func.isRequired,
  setNeedToOpenShift: PropTypes.func.isRequired,
  setShowPinCodeModal: PropTypes.func.isRequired,
  setOpeningShiftCallback: PropTypes.func.isRequired,
};

export default MenuOperations;

