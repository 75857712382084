import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/index';
import './bookmark.scss';

const Bookmark = ({ onClick, checked }) => (
  <button onClick={() => onClick()} className="bookmark-wrapper ">
    <Icon type={checked ? 'starfilled' : 'star'} className={checked ? 'bookmark-fill' : ''} />
  </button>
);

Bookmark.defaultProps = {
  checked: false
};

Bookmark.propTypes = {
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool
};

export default Bookmark;
