import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PayTypes from 'paytypes';

import Modal from 'components/UI/Modal/modal';

import FirstScreen from './FirstScreen';
import Trains from './Trains';
import Wagons from './Wagons';
import Booking from './Booking';
import EnterPassenger from './EnterPassenger';
import SelectPlaces from './SelectPlaces';
import './ktj.scss';

const { KtjContainer } = PayTypes.containers;

class KTJ extends Component {
  state = {
    showModal: false,
    onOkCallback: () => {
      // This is intentional
    },
    onCloseCallback: () => {
      // This is intentional
    },
    step: 0
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDownFowWindow);
  }

  componentDidUpdate(prevProps) {
    const { step } = this.props.payTypeKTJ;

    if (prevProps.payTypeKTJ.step !== this.props.payTypeKTJ.step) {
      this.setStep(step);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDownFowWindow);
  }

  onKeyDownFowWindow = (e) => {
    if (e.key === 'Enter') {
      if (this.state.showModal) {
        this.state.onOkCallback();
      }
    }
  };

  setStep = (step) => this.setState({ step });

  setShowModal = (showModal) => {
    this.setState({ showModal });
  };

  setOnOkCallback = (onOkCallback) => this.setState({ onOkCallback });

  setOnCloseCallback = (onCloseCallback) => this.setState({ onCloseCallback });

  render() {
    const { step } = this.state;
    const customProps = {
      ...this.props,
      setOnOkCallback: this.setOnOkCallback,
      setOnCloseCallback: this.setOnCloseCallback,
    };

    let currentScreen = <div />;
    let modalTitle = '';
    let okButtonTitle = 'Далее';

    if (step) {
      switch (step) {
        case 2: {
          currentScreen = <Wagons {...customProps} />;
          modalTitle = 'Пожалуйста, выберите вагон';
          break;
        }
        case 3: {
          currentScreen = <EnterPassenger {...customProps} />;
          modalTitle = 'Пожалуйста, введите данные по всем пассажирам';
          break;
        }
        case 4: {
          currentScreen = <SelectPlaces {...customProps} />;
          modalTitle = 'Выберите места';
          okButtonTitle = 'Забронировать';
          break;
        }
        case 5: {
          currentScreen = <Booking {...customProps} />;
          modalTitle = 'Проверьте ваши билеты';
          okButtonTitle = 'Оплатить';
          break;
        }
        default: {
          currentScreen = <Trains {...customProps} />;
          modalTitle = 'Выберите наиболее подходящий для Вас поезд';
          break;
        }
      }
    }

    const { showModal, onOkCallback, onCloseCallback } = this.state;

    return (
      <>
        {step ? (
          <Modal
            title={modalTitle}
            isOpen={showModal}
            onOk={() => onOkCallback()}
            onClose={() => {
              this.setShowModal(false);
              this.props.resetStep();
              onCloseCallback();
            }}
            okText={okButtonTitle}
            size="big"
            confirm
          >
            <div className="ktj-modal-body">
              {currentScreen}
            </div>
          </Modal>
        )
          : null}

        <FirstScreen {...this.props} setShowModal={this.setShowModal} showModal={showModal} />
      </>
    );
  }
}

KTJ.propTypes = {
  payTypeKTJ: PropTypes.object.isRequired,
  resetStep: PropTypes.func.isRequired
};

export default KtjContainer(KTJ);
