import React from 'react';
import PropTypes from 'prop-types';
import './radioGroup.scss';

const RadioGroup = ({ data, onChange, selected, selectedIsKey }) => (
  <div>
    {data.map(({ key, value, label }, i) => (
      <button className="radio-group-wrapper buttonWithoutStyles" key={key} onClick={() => onChange(value)} type="button" tabIndex={-1}>
        <input
          type="radio"
          className="radio-input"
          checked={selectedIsKey ? selected === i : selected === value}
          onChange={() => false}
        />
        <label htmlFor={key}>{label}</label>
      </button>
    ))}
  </div>
);

RadioGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired, // data = { key: (int), value: (string), label: (string) }
  selected: PropTypes.oneOfType([ // либо key, либо value
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  selectedIsKey: PropTypes.bool, // для понимания по какому флагу устанавливаем selected
};

RadioGroup.defaultProps = {
  selectedIsKey: false,
};

export default RadioGroup;
