import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/index';
import './checkbox.scss';

const Checkbox = ({ checked, onchange, label, id }) => (
  <label htmlFor={id} className="checkbox-custom">
    <div className="checkbox-container">
      <input
        className="checkbox-hidden"
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onchange}
      />
      <div className="checkbox-styled">
        <Icon type="checkbox" className="checkbox-icon" style={{ visibility: checked ? 'visible' : 'hidden' }} />
      </div>
    </div>
    <span className="checkbox-label">{label}</span>
  </label>
);

Checkbox.defaultProps = {
  id: null,
  label: null
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onchange: PropTypes.func.isRequired,
  label: PropTypes.string,
  id: PropTypes.number

};

export default Checkbox;
