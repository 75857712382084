import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(() => ({
  error: {
    '& .css-yk16xz-control': {
      borderColor: 'red',
    },

  },
  select: { width: '100%' }
}));

export default styles;
