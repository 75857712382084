import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/index';
import './alert.scss';

const Alert = ({ message }) => (
  <div className="alert-wrapper">
    <Icon type="alertIcon" className="alert-icon" />
    <span className="alert-text">{message}</span>
  </div>
);

Alert.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
};
export default Alert;
