const defaultState = {
  numUser: '',
  idTerminal: '',
  dateCreate: '',
  guid: '',
  refreshToken: '',
  locale: 'ru',
  remember: true,
  authCashier: false
};

export const resetAuthData = () => {
  const user = getAuthData();

  jsonSet({ ...user, guid: '', refreshToken: '', authCashier: false });
};

export const setAuthData = (payload = defaultState) => {
  const user = getAuthData();

  jsonSet({ ...user, ...payload });
};

const jsonSet = (data = defaultState) =>
  window.localStorage.setItem('user', JSON.stringify(data));

export const getAuthData = () => {
  const user = JSON.parse(window.localStorage.getItem('user'));

  if (user !== null) {
    return user;
  }

  return defaultState;
};
