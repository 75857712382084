import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'components/UI/Modal/modal';
import Checkbox from 'components/UI/Checkbox/checkbox';
import { setShowLicenseModal, setShowLicenseExpiredModal } from 'actions/fiscal';
import { getFormatedJsDate, getCountDaysForDateExpired } from 'helpers/main';
import { setFiscalData } from 'helpers/fiscal';

const FiscalLicenseAlert = (props) => {
  const { licenseExpiredDate } = props;
  const leftLicenseDays = getCountDaysForDateExpired(licenseExpiredDate);
  const todayIsLastDay = getFormatedJsDate() === licenseExpiredDate;
  const [checked, setChecked] = useState(true);

  const getDay = () => todayIsLastDay ? ' (сегодня)' : '';

  return (
    <Modal
      isOpen
      size="small"
      title={`Ваша лицензия ${!leftLicenseDays ? 'закончилась' : 'заканчивается'}`}
      onOk={() => {
        props.setShowLicenseExpiredModal(false);
        props.setShowLicenseModal(true);
      }}
      onClose={() => props.setShowLicenseExpiredModal(false)}
      okText="Хочу продлить"
    >
      {!leftLicenseDays ? (
        <div>
          Внимание! Ваша лицензия истекла.
          Вы хотите продлить лицензию?
        </div>
      ) : (
        <div>
          Внимание! Ваша лицензия истекает {licenseExpiredDate} {getDay()}.
          Вы хотите продлить лицензию?
        </div>
      )}
      <Checkbox
        checked={!checked}
        onchange={() => {
          setChecked(!checked);
          setFiscalData({ showAlertIsAgreed: !checked });
        }}
        label="Больше не спрашивать"
        id={1}
      />
    </Modal>
  );
};

FiscalLicenseAlert.propTypes = {
  licenseExpiredDate: PropTypes.string.isRequired,
  setShowLicenseModal: PropTypes.func.isRequired,
  setShowLicenseExpiredModal: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  licenseExpiredDate: state.fiscal.licenseExpiredDate,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    setShowLicenseModal,
    setShowLicenseExpiredModal,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FiscalLicenseAlert);

