import * as types from './types';

// Получение баланса
export const getBalance = () => (dispatch) => {
  const successCallback = (data) => {
    const { balances } = data;

    if (typeof balances !== 'undefined') {
      dispatch({ type: types.SET_BALANCES, balances });
      dispatch({ type: types.BALANCE_REQUEST_WITH_ERROR, status: false });
    }
  };

  dispatch({
    type: `${types.SET_BALANCES}_XML_REQUEST`,
    payload: { reqType: 8, xml: '', successCallback }
  });
};

export default getBalance;
