export const setTerminalInfo = (info) => {
  window.localStorage.setItem('terminalInfo', JSON.stringify(info));
};

export const getTerminalInfo = () => {
  if (window.localStorage.getItem('terminalInfo') !== null) {
    return JSON.parse(window.localStorage.getItem('terminalInfo'));
  }

  return null;
};

const TerminalInfo = {
  setTerminalInfo,
  getTerminalInfo
};

export default TerminalInfo;
