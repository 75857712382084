import axios from 'axios';
import moment from 'moment';

import { FISCAL_API_URL } from 'constants/api';
import { DEFAULT_ID_UNIT_FOR_PAYMENTS, SECTION_TYPES } from 'constants/fiscal';

import { getTerminalInfo } from 'model/Terminal';
import { getTotalPaymentAmount, getServiceName } from 'helpers/pay';
import {
  getCurrentDate,
  getFormatedJsDate,
  b64EncodeUnicode,
  b64DecodeUnicode,
  getShiftFormattedDate,
  getPayFormattedDate,
} from 'helpers/main';
import XLSX from 'xlsx';

const defaultState = {
  token: '',
  date: getCurrentDate(),
  idKkm: 0,
  uid: '', // первый uid получаем после открытия смены
  sections: [],
  units: [],
  sectionIdForPayment: '',
  sectionIdForCommission: '',
  userData: {
    Name: '',
    Lock: false,
    IdShift: 0,
    DateShiftOpen: ''
  },
  pultHeader: '',
  tempUid: '',
  tempIdKkm: 0,
  showAlertIsAgreed: true
};

export const setFiscalData = (info) => {
  const fiscalInfo = getFiscalData();

  delete fiscalInfo.sectionIdWithNds;
  delete fiscalInfo.sectionIdWithoutNds;

  const data = {
    ...fiscalInfo,
    ...info
  };

  window.localStorage.setItem('fiscal', JSON.stringify(data));
};

export const updateFiscalUserData = (updatedUserData) => {
  const fiscalInfo = getFiscalData();
  const data = {
    ...fiscalInfo,
    userData: {
      ...fiscalInfo.userData,
      ...updatedUserData
    }
  };

  window.localStorage.setItem('fiscal', JSON.stringify(data));
};

export const getFiscalData = () => {
  if (window.localStorage.getItem('fiscal') !== null) {
    return JSON.parse(window.localStorage.getItem('fiscal'));
  }

  return defaultState;
};

export const isFiscalTokenAlive = () => {
  const currentDate = getCurrentDate();
  const { token, date } = getFiscalData();

  return !((!token) || (token && currentDate.slice(0, 8) !== date.slice(0, 8)));
};

export const hasUid = () => {
  const { uid } = getFiscalData();

  return (typeof uid !== 'undefined' && uid);
};

export const hasUnits = () => {
  const { units } = getFiscalData();

  return (typeof units !== 'undefined' && units.length);
};

export const hasSectionInfo = () => {
  const { sections } = getFiscalData();

  return (typeof sections !== 'undefined' && sections.length);
};

export const getFiscalSectionsForCom = () => {
  const { sections } = getFiscalData();

  if (sections.length) {
    return getFilteredSections(sections, 'Com');
  }

  return [];
};

export const getFiscalSectionsForOperations = () => {
  const { sections } = getFiscalData();

  if (sections.length) {
    return getFilteredSections(sections, 'Ops');
  }

  return [];
};

export const getFiscalSectionsWithHidden = () => {
  const { sections } = getFiscalData();

  if (sections.length) {
    return sections.map(({ Id, Name, Nds }) => ({
      key: Id,
      value: `${Name} (ID: ${Id})`,
      nds: Nds
    }));
  }

  return [];
};

export const getSectionKeyOnId = (sectionId) => {
  const sections = getFiscalSectionsForOperations();
  let sectionKey = 0;

  if (sections.length) {
    sections.map(({ key }, idx) => {
      if (sectionId === key) {
        sectionKey = idx;
      }

      return sectionKey;
    });
  }

  return sectionKey;
};

export const getFiscalUnitsForOperations = () => {
  const { units } = getFiscalData();

  if (units.length) {
    return units
      .map(({ Id, NameRU }) => ({
        key: Id,
        value: NameRU,

      }));
  }

  return [];
};

export const getSectionDataOnId = (sectionId) => {
  const { sections } = getFiscalData();

  if (sections.length) {
    const sectionData = sections.find(({ Id }) => sectionId === Id);

    return sectionData || {};
  }

  return {};
};

export const getFiscalSectionsOnPos = (positions) =>
  positions.map(({ IdSection }) => {
    const { Id, Nds } = getSectionDataOnId(IdSection);

    return ({
      id: Id,
      nds: Nds
    });
  });

export const isShiftOpenedByTheCashier = (kkmLock, kkmIdShift) => {
  const { Lock, IdShift } = getFiscalData().userData;

  return (kkmLock === Lock && kkmLock && IdShift === kkmIdShift);
};

export const isShiftOpenedByOtherCashier = (kkmLock, kkmIdShift) => {
  const { Lock, IdShift } = getFiscalData().userData;

  return ((kkmLock || Lock) && IdShift !== kkmIdShift);
};

export const getShiftStatus = (Lock, IdShift) => {
  if (typeof Lock !== 'undefined' && typeof IdShift !== 'undefined') {
    if (isShiftOpenedByTheCashier(Lock, IdShift)) {
      return 'Открыта';
    }

    if (isShiftOpenedByOtherCashier(Lock, IdShift)) {
      return 'Смена открыта другим кассиром';
    }

    if (typeof Lock !== 'undefined' && !Lock) {
      return 'Закрыта';
    }
  }

  return '';
};

export const isFiscalInAp = () => {
  if (getTerminalInfo() !== null) {
    const { fisCode } = getTerminalInfo();

    return typeof fisCode !== 'undefined' && Number(fisCode) === 1;
  }

  return false;
};

// говорит активна (не просрочена) ли смена
export const isTheShiftActive = () => {
  const { DateShiftOpen } = getFiscalData().userData;

  if (DateShiftOpen) {
    const today = getFormatedJsDate(); // DD.MM.YYYY
    const formattedShiftOpen = moment(new Date(DateShiftOpen)).format('DD.MM.YYYY');

    return (formattedShiftOpen === today);
  }

  return true;
};

// Удаляем секции после закрытия смены
export const removeSections = () => {
  if (hasSectionInfo()) {
    setFiscalData({
      sections: [],
      sectionIdForPayment: '',
      sectionIdForCommission: '',
    });
  }
};

export const getFiscalLink = () => {
  const { idKkm, uid } = getFiscalData();

  if (idKkm && uid) {
    return `${FISCAL_API_URL}/www/kkms/${idKkm}/sale?uid=${uid}`;
  }

  return FISCAL_API_URL;
};

// проверяем передал ли фискал нам uid (при переходе с фискала в вебтерминал)
export const checkFiscalGetParams = (searchParams) => {
  if (searchParams) {
    const getParams = searchParams.split('?')[1];
    let tempUid = '';
    let tempIdKkm = 0;

    if (getParams) {
      getParams.split('&').map(param => {
        const paramName = param.split('=')[0];
        const paramValue = param.split('=')[1];

        if (paramName === 'uid') tempUid = paramValue;
        if (paramName === 'kkmid') tempIdKkm = paramValue;

        return tempIdKkm;
      });

      setFiscalData({
        tempUid,
        tempIdKkm
      });
    }
  }
};

export const hasFiscalTempUid = () => {
  const { tempUid, tempIdKkm, idKkm } = getFiscalData();

  if (tempIdKkm && tempUid && Number(idKkm) === Number(tempIdKkm)) {
    return true;
  }

  setFiscalData({ tempIdKkm: 0, tempUid: '' });

  return false;
};

export const saveFiscalTempUid = () => {
  const { tempUid, tempIdKkm, idKkm } = getFiscalData();

  if (tempIdKkm && tempUid && Number(idKkm) === Number(tempIdKkm)) {
    setFiscalData({ uid: tempUid, tempIdKkm: 0, tempUid: '' });
  }
};

export const checkSectionsOnValid = (isFiscal) => (!isFiscal || (isFiscal && checkSectionsCount()));

export const checkSectionsCount = () => {
  const { sections } = getFiscalData();

  if (typeof sections !== 'undefined' && sections.length && sections.length >= 2) {
    const sectionIdForCommission = sections.find(section =>
      section.SectionType === SECTION_TYPES.FOR_COMMISSION);
    const sectionIdForPayment = sections.find(section =>
      section.SectionType === SECTION_TYPES.FOR_PAYMENT);

    return (sectionIdForCommission && sectionIdForPayment);
  }

  return false;
};

export const refreshFiscalToken = () => {
  const { pultHeader } = getFiscalData();
  const [Login, Password] = b64DecodeUnicode(pultHeader).split(':');
  const url = `${FISCAL_API_URL}/auth`;

  if (Login && Password) {
    console.log('refreshFiscalToken', Login, Password);

    return axios.post(url, { Login, Password })
      .then(response => {
        const { Status, Data } = response.data;

        if (Status === 200) {
          const { Token, User: { Name, Lock, IdShift } } = Data;

          setFiscalData({
            token: Token,
            date: getCurrentDate(),
            pultHeader: b64EncodeUnicode(`${Login}:${Password}`)
          });
          updateFiscalUserData({ Name, Lock, IdShift });

          return Token;
        }

        return null;
      })
      .catch((err) => {
        console.log('err', err);

        return null;
      });
  }

  return undefined;
};

export const getReqDataFoFiscal = ({
  currentService,
  depositedAmount,
  commissionSum,
  sectionForPayment,
  paymentMethod,
  otherIdServiceForMakePay
}) => {
  const { sectionIdForPayment, sectionIdForCommission } = getFiscalData();
  const { name: nameService, isEMoney, typeInterface } = currentService;

  const defaultPositionData = {
    Markup: 0, // наценка
    Discount: 0, // скидка
    Qty: 1, //  Количество в целых единицах
    Storno: false, // позиция была сторнирована (отменена)
    idUnit: DEFAULT_ID_UNIT_FOR_PAYMENTS, // ед.измерения
  };

  // ед.измерения для всех схем, кроме комплексной
  if (typeInterface !== 1) defaultPositionData.Unit = { ShortName: 'шт' };

  // если СЭД или нет комиссии то одна позиция
  // Price - либо вся сумма оплаты (если СЭД), если не СЭД - сумма к зачислению
  const Positions = [{
    Name: getServiceName(nameService, otherIdServiceForMakePay),
    IdSection: sectionIdForPayment, //  та которая с SectionType = 1
    Price: isEMoney ?
      getTotalPaymentAmount(depositedAmount) :
      getTotalPaymentAmount(depositedAmount - commissionSum),
    ...defaultPositionData
  }];

  // Если сервис не СЭД и есть комиссия, заполняем вторую позицию по комиссии
  // Секция для комиссии выбирается в SelectFiscalSection
  // sectionForPayment - из селекта (на экране подтверждения)
  // sectionIdForCommission - секция с SectionType = 2
  if (!isEMoney && commissionSum && sectionIdForCommission) {
    Positions.push({
      Name: 'Комиссия',
      IdSection: sectionForPayment || sectionIdForCommission,
      Price: getTotalPaymentAmount(commissionSum),
      ...defaultPositionData
    });
  }

  return {
    IdDomain: 1, // (DOMAIN_TRADING) -  Идентификатор вида деятельности
    Cash: paymentMethod ? 0 : depositedAmount, // int Сумма оплаты наличными
    NonCash: !paymentMethod ? 0 : depositedAmount, // int Сумма оплаты безналом
    Positions,
    Total: getTotalPaymentAmount(depositedAmount), // int Итоговая сумма
  };
};

export const clearSections = () => {
  setFiscalData({
    sections: [],
    sectionIdForCommission: '',
    sectionIdForPayment: '',
  });
};

const getFilteredSections = (sections, type) => {
  let sectFiltered = sections
    .filter(({ SectionType }) => !SectionType);

  if (type === 'Com') {
    sectFiltered = sections
      .filter(({ SectionType }) => SectionType !== SECTION_TYPES.FOR_PAYMENT);
  }

  return sectFiltered
    .map(({ Id, Name, Nds }) => ({
      key: Id,
      value: `${Name} (ID: ${Id})`,
      nds: Nds
    }));
};

export const getIdServiceForMakeFiscalPayStatus = (updatedServiceId, idService) =>
  updatedServiceId || idService;

export const getUidForMakeFiscalPay = (updatedUid, uid) =>
  updatedUid || uid;

export const getFiscalPayDocumentTemplate = ({ pay, idKkm }) => {
  const { sectionIdForPayment, sectionIdForCommission } = getFiscalData();
  const { amount, nameService, com, formattedDate, sn } = pay;

  const docTemplate = {
    IdKkm: idKkm,
    IdDomain: 1,
    IdTypeDocument: 1,
    Positions: [
      {
        Name: nameService,
        IdSection: sectionIdForPayment,
        Price: amount - com,
        Markup: 0,
        Discount: 0,
        Qty: 1,
        idUnit: DEFAULT_ID_UNIT_FOR_PAYMENTS,
        Storno: false
      }
    ],
    Value: amount,
    SN: String(sn),
    Cash: amount,
    NonCash: 0,
    Change: 0,
    AutonomousNumber: 0,
    CustomerIIN: '',
    ReceiptDate: formattedDate
  };

  if (com) {
    docTemplate.Positions.push({
      Name: 'Комиссия',
      IdSection: sectionIdForCommission,
      Price: com,
      Markup: 0,
      Discount: 0,
      Qty: 1,
      idUnit: DEFAULT_ID_UNIT_FOR_PAYMENTS,
      Storno: false
    });
  }

  return docTemplate;
};

/**
 * Функция по сравнению платежей отправленных в АП и фискал
 * сравниваются только те платежи, которые были проведены за текущую смену
 * а также были проведены более минуты назад, чтобы синхронизировать с фискальными операциями
* */

export const checkFiscalAndApSendedPays = ({ snListForTodayPayments,
  xReport,
  shiftStatus,
  fiscalXReportsSnList }) => {
  const notInFiscalSnList = [];
  const { userData: { DateShiftOpen }, } = getFiscalData();
  const shiftOpenDate = DateShiftOpen && getShiftFormattedDate(DateShiftOpen);
  const minutesForWait = 1;

  /* Если смена открыта и есть платежи в АП и операции в фискальном отчете */
  if (snListForTodayPayments && DateShiftOpen && snListForTodayPayments.length > 0 && xReport.Operations && shiftStatus === 'Открыта') {
    snListForTodayPayments.forEach(({ sn, dateout }) => {
      const payDate = getPayFormattedDate(dateout);
      const paySendDataDiff = moment().diff(getPayFormattedDate(payDate));
      const paySendDataMins = Math.ceil(paySendDataDiff / (60 * 1000));

      /*
      если дата проведения платежа в АП позже либо равна дате открытия смены и
      номера транзакции нет в фискальных операциях,
      и разница времени между проведением платежа больше минуты
      добавляем в неотправленные платежи
      */

      if (payDate >= shiftOpenDate && !fiscalXReportsSnList.includes(sn.toString())
        && paySendDataMins > minutesForWait) {
        notInFiscalSnList.push(sn);
      }
    });
  }

  return notInFiscalSnList;
};

export const exportToXlsxZreport = () => {
  const workbook = XLSX.utils.table_to_book(
    document.getElementById('reportTable')
  );

  workbook.Sheets.Sheet1['!cols'] = [];
  for (let i = 0; i < 13; i++) {
    workbook.Sheets.Sheet1['!cols'].push({ width: 8 });
  }

  // eslint-disable-next-line
    XLSX.writeFile(workbook, `Отчет_за_период_${getFormatedJsDate()}.xls`, {
    bookType: 'xlsx',
    type: 'buffer',
  });
};

export const getZreportFormattedDateRange = (dates) => {
  const dateFromVal = moment(dates[0]).format('YYYY-MM-DD 00:00:01');
  const dateToVal = moment(dates[1]).format('YYYY-MM-DD 23:59:59');
  const dateFromStrCdr = moment(dateFromVal).format('YYYYMMDDHHmmss');
  const dateToStrCdr = moment(dateToVal).format('YYYYMMDDHHmmss');

  return { dateFromStrCdr, dateToStrCdr };
};
