
import InitDb from './InitDb';

const defaultState = {
  sn: '',
  checks: [],
  code: '',
  idService: 0,
  isPaymentSuccess: false,
};

export const getReceiptFromIDB = async () => {
  const db = await InitDb();
  const tx = db.transaction('receipts', 'readwrite');
  const store = tx.objectStore('receipts');

  return store.getAll();
};

export const clearReceiptsTable = async () => {
  const db = await InitDb();
  const tx = db.transaction('receipts', 'readwrite');
  const store = tx.objectStore('receipts');

  store.clear();
};

export const saveReceiptInIDB = async (data) => {
  if (data.sn) {
    const db = await InitDb();
    const tx = db.transaction('receipts', 'readwrite');
    const store = tx.objectStore('receipts');

    return store.put({ ...defaultState, ...data });
  }

  return null;
};

export const removeReceiptFromIDB = async (sn) => {
  if (sn) {
    const db = await InitDb();
    const tx = db.transaction('receipts', 'readwrite');
    const store = tx.objectStore('receipts');

    store.delete(sn);
  }
};

export const getReceiptOnSnFromIDB = async (sn) => {
  if (sn) {
    const db = await InitDb();
    const tx = db.transaction('receipts', 'readwrite');
    const store = tx.objectStore('receipts');
    const receipt = await store.get(sn);

    return receipt || null;
  }
};

export const updateReceiptInIDB = async (sn, updatedCheckData) => {
  if (sn) {
    const db = await InitDb();
    const tx = db.transaction('receipts', 'readwrite');
    const store = tx.objectStore('receipts');

    return store.put({ sn, ...updatedCheckData });
  }

  return null;
};

