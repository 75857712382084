import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/UI/Input/input';
import './dropdownInput.scss';

const DropdownInput = ({
  placeholder,
  id,
  label,
  value,
  isError,
  errorMessage,
  callback,
  dropdownList,
  dropdownCallback
}) => {
  const wrapperRef = useRef(null);
  const [openDropdown, setOpenDropdown] = useState(false);

  const handleClickOutside = (e) => {
    if (wrapperRef && wrapperRef.current !== null && !wrapperRef.current.contains(e.target)) {
      setOpenDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="input-dropdown">
      <Input
        placeholder={placeholder}
        id={id}
        onchange={callback}
        onFocus={() => setOpenDropdown(true)}
        value={value.toString()}
        label={label}
        errorMessage={errorMessage}
        isError={isError}
        autoComplete="off"
      />
      {openDropdown && dropdownList.length > 0 && (
        <div className={`input-dropdown-list ${dropdownList.length > 5 ? 'dropdown-list-overflow' : ''}`} ref={wrapperRef} >
          {dropdownList.map((item) => (
            <button
              onClick={() => {
                dropdownCallback(item);
                setOpenDropdown(false);
              }}
              type="button"
              tabIndex={-1}
              key={item.Id}
              className="buttonWithoutStyles"
            >
              {item.Name}
            </button>
          )
          )}
        </div>
      )}
    </div>
  );
};

DropdownInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  dropdownCallback: PropTypes.func.isRequired,
  dropdownList: PropTypes.array,
  value: PropTypes.string,
  errorMessage: PropTypes.string,
};

DropdownInput.defaultProps = {
  value: '',
  errorMessage: 'Поле обязательно для заполнения',
  dropdownList: []
};

export default DropdownInput;
