export const formatApi = 'YYYYMMDDHHmmss';
export const MAX_FAVOURITES_COUNT = 12;
export const VERSION_PO = process.env.REACT_APP_VERSION_PO || '1.0.52';
export const PLUS24_LOGIN = process.env.REACT_APP_PLUS24_LOGIN || '';
export const PLUS24_PASSWORD = process.env.REACT_APP_PLUS24_PASSWORD || '';
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || '';

export const CASH_PAYMENT_METHOD = 0;
export const CARD_PAYMENT_METHOD = 1;

export const ENCASHMENT_BLOCK_MINUTES = 10;
export const ENCASHMENT_OPERATION_BLOCK_TIME = 2; // в минутах

export const MAIN_PAGE_URL = '/main';
export const LANG_LIST = [
  { value: 'Русский', key: 'ru' },
  { value: 'Казахский', key: 'kz' }
];

export const KT_REGIONS = [
  {
    description: 'Семей',
    townId: '18'
  }, {
    description: 'Нур-Султан',
    townId: '1'
  }, {
    description: 'Караганда',
    townId: '2'
  }, {
    description: 'Актобе',
    townId: '3'
  }, {
    description: 'Атырау',
    townId: '4'
  }, {
    description: 'Павлодар',
    townId: '5'
  }, {
    description: 'Шымкент',
    townId: '6'
  }, {
    description: 'Костанай',
    townId: '8'
  }, {
    description: 'Петропавловск',
    townId: '9'
  }, {
    description: 'Кокчетав',
    townId: '10'
  }, {
    description: 'Актау',
    townId: '11'
  }, {
    description: 'Усть-каменогорск',
    townId: '12'
  }, {
    description: 'Уральск',
    townId: '13'
  }, {
    description: 'Тараз',
    townId: '14'
  }, {
    description: 'Кызыл-орда',
    townId: '15'
  }, {
    description: 'Талдыкурган',
    townId: '16'
  }, {
    description: 'Алматы',
    townId: '17'
  }
];

export const DEFAULT_AMOUNT_SETTINGS = {
  min_amount: 10,
  max_amount: 100000,
  min_na: 3,
  max_na: 20,
};

export const MONTHS = {
  1: 'Январь',
  2: 'Февраль',
  3: 'Март',
  4: 'Апрель',
  5: 'Май',
  6: 'Июнь',
  7: 'Июль',
  8: 'Август',
  9: 'Сентябрь',
  10: 'Октябрь',
  11: 'Ноябрь',
  12: 'Декабрь'
};

export const MONTHS_KZ = {
  1: 'Қаңтар',
  2: 'Ақпан',
  3: 'Наурыз',
  4: 'Сәуiр',
  5: 'Мамыр',
  6: 'Маусым',
  7: 'Шiлде',
  8: 'Тамыз',
  9: 'Қыркүйек',
  10: 'Қазан',
  11: 'Қараша',
  12: 'Желтоқсан'
};

// для Костанай Су
export const paramsKostanaySu = {
  cold: {
    tariff: 'voda',
    com: '0',
    title: 'Холодная вода',
  },
  cold1: {
    tariff: 'voda',
    com: '0',
    title: 'Холодный прибор учёта 1',
  },
  cold2: {
    tariff: 'voda',
    com: '0',
    title: 'Холодный прибор учёта 2',
  },
  cold3: {
    tariff: 'voda',
    com: '0',
    title: 'Холодный прибор учёта 3',
  },
  hot: {
    tariff: 'stok',
    com: '',
    title: 'Сброс горячей воды',
  },
  hot1: {
    tariff: 'stok',
    com: '',
    title: 'Горячий прибор учёта 1',
  },
  hot2: {
    tariff: 'stok',
    com: '',
    title: 'Горячий прибор учёта 2',
  },
  hot3: {
    tariff: 'stok',
    com: '',
    title: 'Горячий прибор учёта 3',
  },
  com: {
    tariff: 'voda',
    com: '1',
    title: 'Холодная вода',
  },
  plv: {
    tariff: 'voda',
    com: '0',
    title: 'Поливной прибор учёта',
  },
};

export const COUNTERS_WITHOUT_SEVERAGE = ['hot', 'hot1', 'hot2', 'hot3', 'plv'];

export const XML_TO_JSON_OPTIONS = {
  numberParseOptions: {
    leadingZeros: false,
  },
  attributeNamePrefix: '',
  attributesGroupName: '@attributes', // default is 'false'
  textNodeName: 'content',
  ignoreAttributes: false,
  ignoreNameSpace: false,
  allowBooleanAttributes: true,
  parseNodeValue: true,
  parseAttributeValue: true,
  trimValues: true,
  cdataTagName: '__cdata', // default is 'false'
  cdataPositionChar: '\\c',
  localeRange: '',
  parseTrueNumberOnly: true
};

export const ERROR_CODE = {
  0: 'OK',
  1: 'Провайдер временно недоступен',
  2: 'Превышено число попыток',
  3: 'Техническая ошибка, нельзя отправить запрос провайдеру',
  4: 'Неверный формат счета/телефона',
  5: 'Номер не принадлежит оператору',
  6: 'Прием платежа запрещен, обратитесь в банк',
  7: 'Прием платежа запрещен, обратитесь к оператору',
  8: 'Прием платежа запрещен по техническим причинам',
  9: 'Timeout от провайдера',
  10: 'Дублирование платежа',
  11: 'Успешно фискализирован',
  12: 'Платеж не подтвержден клиентом',
  13: 'Сервер занят, повторите запрос через минуту',
  14: 'Принято неправильно зашифрованное сообщение.',
  15: 'Истекло время ожидания платежа в очереди',
  16: 'Пользователь с таким логином уже существует',
  17: 'Терминал не лицензирован, либо срок действия лицензии истек',
  18: 'Данный тип терминала не лицензирован',
  19: 'Сервис временно недоступен',
  22: 'Сервис не подключен',
  23: 'Сервис не подан на терминал',
  71: 'Домашний оператор не может принять платеж',
  72: 'ЛС платежной системы не доступен',
  73: 'Домашний оператор не доступен',
  74: 'ПЦ не может принимать платежи.',
  75: 'Провайдер не идентифицировал ПЦ',
  76: 'Платеж запрещен провайдером',
  77: 'Прием платежа запрещен провайдером',
  78: 'Провайдер не может провести платеж',
  79: 'Счет абонента не активен',
  80: 'VIP счет. Прием платежа запрешен.',
  81: 'Не найдено описание клиента',
  82: 'Провайдер запретип прием платежа ПЦ',
  83: 'В настоящее время прием платежа невозможен',
  84: 'Номер не принадлежит оператору.',
  85: 'Отмена платежа невозможна',
  86: 'Не определено состояние платежа',
  90: 'Проведение платежа не окончено',
  101: 'Нет такого файла для загрузки',
  130: 'Работа с данным провайдером не реализована',
  133: 'Нет прав на прием платежей',
  134: 'Нет прав кассира',
  135: 'Нельзя списывать с чужого счета',
  140: 'Прием платежа невозможен из-за технического сбоя. Повторите позже',
  150: 'Неверный пароль или нет прав на этот терминал',
  155: 'Прием платежа для данного провайдера запрещен',
  201: 'Слишком много запросов с терминала, обождите!!',
  202: 'Ошибка данных запроса',
  209: 'Ошибка авторизации',
  210: 'Нет такой транзакции в базе данных',
  211: 'Неверный статус транзакции',
  212: 'Не задана сумма платежа (или лимит терминала)',
  213: 'Не задана сумма списания',
  215: 'Транзакция с таким номером уже есть в базе',
  216: 'Ошибка при сохранении транзакции на сервере',
  220: 'Недостаток средств у агента',
  221: 'Недостаточно средств на счете в банке',
  233: 'Повторный платеж в течении часа',
  238: 'Номер телефона находится в неактивном состоянии',
  239: 'Некоммерческий клиент',
  240: 'Не проведена',
  241: 'Сумма слишком мала',
  242: 'Сумма слишком велика',
  243: 'Невозможно проверить состояние счета',
  244: 'Терминал не зарегистрирован у оператора',
  245: 'Неверный тип терминала',
  246: 'Терминал привязан к другому компьютеру',
  252: 'Превышена максимальная сумма оплаты для терминала',
  255: 'Недопустимая дополнительная комиссия',
  270: 'Ошибка от провайдера',
  271: 'Ошибка в ответе провайдера',
  272: 'Временно нет связи с провайдером',
  274: 'Сумма платежа должна быть целым числом',
  275: 'Некорректная сумма платежа',
  276: 'Коррекция! НЕ ПЕРЕПРОВОДИТЬ!',
  280: 'Недостаток средств у агента выше',
  295: 'Запрещенный запрос',
  300: 'Другая (неизвестная) ошибка провайдера',
  302: 'Проведение для пользовательского провайдера',
  501: 'Платеж обработан банком',
  502: 'Ошибка обработки платежа банком',
  503: 'Отсутствуют необходимые данные для обработки платежа',
  504: 'Невозможно отправить платеж в банк',
  505: 'Платеж обрабатывается банком без проверки статуса',
  506: 'Обрабатывается банком',
  550: 'Неверное значение номера транзакции',
  551: 'Неверное значение даты',
  552: 'Функция не поддерживается',
  553: 'Операция в данной валюте не поддерживается',
  554: 'Недопустимое значение суммы',
  555: 'Указанная в запросе дата платежа слишком старая',
  556: 'Некорректный формат даты или времени платежа',
  557: 'Нет доступа к данной функциональности',
  558: 'Неверный идентификатор сессии',
  559: 'Не удалось получить номер лицевого счета',
  600: 'Операция находится в очереди оплаты на сервере',
  601: 'Операция находится в очереди оплаты у провайдера',
  602: 'Операция оплачивается',
  603: 'Информация об операции временно недоступна',
  604: 'Операция не готова к оплате',
  605: 'Операция неправильная',
  606: 'Операция уже завершена (отменена или оплачена)',
  607: 'Время вышло',
  608: 'Операция не была проверена',
  609: 'Для оффлайн-операции: на счету агента недостаточно средств',
  610: 'Заблокировано',
  611: 'Отменено',
  700: 'Не удалось обработать запрос. Попробуйте повторить действие через минуту',
  701: 'Неправильный метод запроса. Предполагается передача данных методом POST',
  702: 'Получены POST-данные нулевой длины',
  703: 'Неверный формат XML-запроса',
  704: 'Версия протокола не поддерживается сервером',
  705: 'Вы не авторизованы',
  706: 'Сервер занят. Повторите запрос через минуту',
  707: 'Данная команда не поддерживается сервером',
  708: 'У пользователя нет прав для работы с этим терминалом',
  709: 'Неверный номер телефона/лиц.счета',
  710: 'Недопустимая сумма платежа',
  711: 'Недопустимая сумма комиссии',
  715: 'Сервер недоступен. Повторите попытку позднее.',
  717: 'Онлайн проверка для данного провайдера не поддерживается',
  800: 'Обнаружена подозрительная активность',
  801: 'Обнаружена подозрительная активность',
  900: 'Не удалось обработать запрос. Повторите действие позднее',
  901: 'Сервис временно недоступен',
  902: 'Неверный метод запроса. Предполагается передача методом POST',
  903: 'Получены данные нулевой длины',
  904: 'Ошибка при разборе XML-строки',
  905: 'Неверный формат XML-запроса',
  906: 'Версия протокола не поддерживается сервером',
  907: 'IP-адрес отправителя неизвестен системе',
  908: 'Пользователь не авторизован. Имя пользователя не верно либо пользователь не авторизован отправлять запросы с данного IP-адреса',
  909: 'Пользователь не авторизован. Пароль не верен',
  910: 'Тип запроса не поддерживается сервером',
  911: 'Сервис не найден',
  912: 'Неверный формат кошелька',
  913: 'Ошибка при разборе ответа WebMoney',
  914: 'Ошибка при преобразовании даты в требуемый формат',
  915: 'Кошелёк не найден',
  916: 'Ошибки с таким номером не существует',
  917: 'Серверу не удалось сформировать ответ',
  918: 'Транзакции с запрашиваемым номером не найдено',
  919: 'Платёж обрабатывается',
  920: 'Транзакция с таким номером уже существует',
  921: 'Неверный формат суммы для перевода',
  922: 'Ошибка разбора XML-строки при проведении транзакции в WebMoney',
  923: 'Ошибка при проведении транзакции'
};

export const ERROR_CODE_TYPES = {
  32: 'Не удалось загрузить Журнал операций.'
};
