export const X_REPORT_TYPE = 0;
export const Z_REPORT_TYPE = 1;

export const SECTION_TYPES = {
  FOR_PAYMENT: 1, // платеж
  FOR_COMMISSION: 2, // комиссия
};

export const FISCAL_SCREENS = {
  SELL: 1,
  INCOME: 2,
  OUTCOME: 3,
  BUY: 4,
  REFUND: 5,
  OPEN_SHIFT: 6,
  CLOSE_SHIFT: 7,
  X_REPORT: 9,
  Z_REPORTS_HISTORY: 10,
  UPDATE_SECTIONS: 11,
  SERVICE_PAYMENT: 12,
};

export const GET_UID_TRY_COUNT = 5;
export const GET_FISCAL_AUTH_DATA_REQ_NUMBER = 15;
export const FISCAL_KKM_STATUSES = {
  REGISTRATION: 1,
  ACTIVE: 2,
  BLOCKED: 3,
};

export const TIME_FOR_CALL_FISCAL_REQUEST = 58000;
export const TIME_FOR_CHECK_PAYS_IN_FISCAL = 60000;
export const GET_DOCUMENT_BY_SN_TRY_COUNT = 10;

export const TERMINALS_WITH_HIDDEN_SECTIONS = [154867, 148757, 146898, 111784, 127935];

export const FISCAL_TARIFFS = {
  FREE: 1,
  PAYING: 0,
};

export const DEFAULT_ID_UNIT_FOR_PAYMENTS = 1; // шт (ед. измерения по умолчанию для платежей)
