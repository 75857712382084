export const setToFavourites = (id) => {
  const favouritesList = getFavourites();

  favouritesList.push(id);
  window.localStorage.setItem('favourites', JSON.stringify(favouritesList));

  return favouritesList;
};

export const getFavourites = () => {
  if (window.localStorage.getItem('favourites') !== null) {
    return JSON.parse(window.localStorage.getItem('favourites'));
  }

  return [];
};

export const removeFromFavouritesOnId = (idService) => {
  const favouritesList = getFavourites();

  if (favouritesList.length) {
    const newFavourites = favouritesList.filter(item => (item !== idService));

    window.localStorage.setItem('favourites', JSON.stringify(newFavourites));

    return newFavourites;
  }

  return [];
};

const favourites = {
  setToFavourites,
  getFavourites,
  removeFromFavouritesOnId
};

export default favourites;
