/* eslint-disable import/extensions */
import React from 'react';
import PayTypes from 'paytypes';
import Autosuggest from 'react-autosuggest';
import moment from 'moment';
import 'moment/locale/ru';
import { Russian } from 'flatpickr/dist/l10n/ru';
import 'flatpickr/dist/flatpickr.css';
import Flatpickr from 'react-flatpickr';

import { TIME_INTERVAL } from 'constants/ktj';
import citiesList from 'public/json/ktj/cities.json';
import Checkbox from 'components/UI/Checkbox/checkbox';

const dateFormat = 'DD.MM.YYYY';
const {
  containers: { KtjContainer },
  templates: { KtjFirstScreenTemplate }
} = PayTypes;

/*  2@@2700152@2040500@30.01.2018@1 */

class FirstScreen extends KtjFirstScreenTemplate {
  state = {
    temporaryList: []
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDownFowWindow);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDownFowWindow);
  }

  onKeyDownFowWindow = (e) => {
    if (e.key === 'Enter' && !this.props.showModal) {
      this.validateAndCheck();
    }
  };

  // componentDidMount() {
  //   this.props.setCityFrom('КАНДЫАГАШ');
  //   this.props.setCityFromValue(2704870);
  //   this.props.setCityTo('КУЛЬСАРЫ');
  //   this.props.setCityToValue(2704822);
  //   this.props.setDate('29.04.2020');
  //   this.props.setTime('2103');
  // }

  setTemporaryCitiesList(temporaryList) {
    this.setState({ temporaryList });
  }

  onSuggestionsClearRequested() {
    return this.setTemporaryCitiesList([]);
  }

  setCityFromValue(city) {
    this.props.setCityFromValue(city.SC);

    return city.SS;
  }

  setCityToValue(city) {
    this.props.setCityToValue(city.SC);

    return city.SS;
  }

  getCitiesTo(value) {
    this.props.setCityTo(value);
    this.setTemporaryCitiesList(this.getCityList(value));
  }

  getCitiesFrom(value) {
    this.props.setCityFrom(value);
    this.setTemporaryCitiesList(this.getCityList(value));
  }

  getCityList = (value) =>
    citiesList.filter(city => (city.SF.toUpperCase().includes(value.toUpperCase()))).slice(0, 8)

  setDate = (date) => { // Формат: 30.01.2018
    if (date.length) {
      this.props.setDate(moment(date[0]).format(dateFormat));
    }
  }

  validateAndCheck() {
    const {
      kassa,
      showError,
      onlineCheck,
      payTypeKTJ: { cityToName, cityFromName, checkedTime, date, cityFromSc, cityToSc },
    } = this.props;

    if (cityToName && cityFromName && checkedTime.length && date) {
      if (cityFromSc && cityToSc) {
        const account = `2@@${cityFromSc}@${cityToSc}@${date}@${checkedTime}`;

        this.props.setShowModal(true);

        return onlineCheck(kassa.currentService, account);
      }

      return showError(
        'Выберите города из выпадающего списка и повторите попытку',
        'Города не выбраны'
      );
    }

    return showError(
      'Проверьте, пожалуйста и повторите попытку',
      'Все поля обязательны для заполнения'
    );
  }

  renderComponent() {
    const { temporaryList } = this.state;
    const {
      setTime,
      payTypeKTJ: { cityFromName, cityToName, checkedTime, date },
    } = this.props;

    const renderSuggestion = (city) => (
      <div className="search-item">
        {city.SS}
      </div>
    );

    return (
      <div className="pay-form ktj-first-screen">
        <div className="ktj-field">
          <label htmlFor="cityFrom">Город отправления</label>
          {/* <button onClick={this.cancelBooking}>Отменить бронь</button> */}
          <Autosuggest
            id="cityFrom"
            suggestions={temporaryList}
            onSuggestionsFetchRequested={(e) => this.getCitiesFrom(e.value)}
            onSuggestionsClearRequested={() => this.onSuggestionsClearRequested()}
            getSuggestionValue={(e) => this.setCityFromValue(e)}
            renderSuggestion={renderSuggestion}
            inputProps={{
              value: cityFromName,
              onChange: (_, value) => this.setCityFrom(value.newValue),
              placeholder: 'Город отправления',
            }}
          />
        </div>
        <div className="ktj-field">
          <label htmlFor="cityTo">Город прибытия</label>
          <Autosuggest
            id="cityTo"
            suggestions={temporaryList}
            onSuggestionsFetchRequested={(e) => this.getCitiesTo(e.value)}
            onSuggestionsClearRequested={() => this.onSuggestionsClearRequested()}
            getSuggestionValue={(value) => this.setCityToValue(value)}
            renderSuggestion={renderSuggestion}
            inputProps={{
              value: cityToName,
              onChange: (_, value) => this.setCityTo(value.newValue),
              placeholder: 'Город прибытия',
            }}
          />
        </div>
        <div className="ktj-field">
          <label htmlFor="date">Дата отправления</label>
          <Flatpickr
            id="date"
            placeholder="Выберите дату отправления"
            options={{
              dateFormat: 'd-m-Y',
              locale: Russian
            }}
            className="datepicker-wrapper"
            style={{ margin: '5px 0', textAlign: 'left' }}
            onChange={value => this.setDate(value)}
            value={date}
          />
        </div>
        {TIME_INTERVAL.map(({ label, value }) => (
          <Checkbox
            key={value}
            checked={checkedTime.includes(value)}
            onchange={() => setTime(value)}
            label={label}
            id={Number(value)}
          />
        ))}
        <div>
          <button
            type="button"
            className="btn-primary"
            onClick={() => this.validateAndCheck()}
          >
            Далее
          </button>
        </div>
      </div>
    );
  }
}

export default KtjContainer(FirstScreen);
