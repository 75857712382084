import PayTypes from 'paytypes';
import React from 'react';
import { connect } from 'react-redux';
import Checkbox from 'components/UI/Checkbox/checkbox';
import Steps from 'components/UI/Steps/steps';
import CustomButton from 'components/UI/Button/button';
import { STEPS, PLACE_TYPES_KTJ } from 'constants/ktj';
import { b64EncodeUnicode } from 'helpers/main';
import { getWagonSales } from '../helper';

const { KtjSelectPlacesTemplate } = PayTypes.templates;

/* Пример: 5@@2708000@2700000@17.02.2018@10:15@MDQz0KY= */

class SelectPlaces extends KtjSelectPlacesTemplate {
  state = {
    saleOnTwo: false,
    saleOnFour: false,
  };

  componentDidMount() {
    const { cars, selectedWagon } = this.props;
    const { saleOnTwo, saleOnFour } = getWagonSales(cars, selectedWagon);

    this.setWagonSales({ saleOnTwo, saleOnFour });
    this.props.setOnOkCallback(() => this.validateAndBooking());
  }

  setWagonSales = ({ saleOnTwo, saleOnFour }) =>
    this.setState({ saleOnTwo, saleOnFour });

  validateAndBooking = () => {
    const { from, to } = this.props.placesParam;

    if (from && to) {
      this.booking();
    } else {
      this.props.showError(
        'Заполните места и повторите попытку',
        'Места обязательны к заполнению'
      );
    }
  };

  booking = () => {
    const {
      currentService,
      onlineCheck,
      payTypeKTJ: {
        cityFromSc,
        cityToSc,
        date,
        departureTime,
        selectedTrain,
        payForBedLinen,
        selectedWagonData: { wagonType, wagonClassService, '@attributes': { Number: wagonNumber } },
        placesParam: { upperSeatsCount, lowerSeatsCount, },
      },
    } = this.props;

    const b64TrainNum = b64EncodeUnicode(selectedTrain);
    const b64WagonType = b64EncodeUnicode(wagonType);
    const b64WagonNum = b64EncodeUnicode(wagonNumber);
    const b64WagonClassService = b64EncodeUnicode(wagonClassService);
    const suggestionsForPLaces = b64EncodeUnicode(this.getplacesType());
    const b64CompType = b64EncodeUnicode(this.getCompType());
    const b64Passengers = b64EncodeUnicode(this.getXmlPassengers());
    const account = `5@@${cityFromSc}@${cityToSc}@${date} ${departureTime}@${b64TrainNum}@${b64WagonType}@${b64WagonNum}@${b64WagonClassService}@${upperSeatsCount || 0}@${lowerSeatsCount || 0}@${suggestionsForPLaces}@${Number(payForBedLinen)}@${b64CompType}@${this.getSeatsRange()}@@${b64Passengers}`;

    onlineCheck(currentService, account);
  };

  renderComponent() {
    const {
      date,
      timeInWay,
      passengers,
      cityToName,
      cityFromName,
      selectedTrain,
      selectedWagon,
      departureTime,
      arrivalTime,
      payForBedLinen,
      selectedWagonData,
      changePassengerInfo,
      enterPlaceParams,
      selectedWagonData: { wagonType },
      placesParam: {
        to: placesTo,
        from: placesFrom,
        upperSeatsCount,
        lowerSeatsCount,
        together,
        placesInOneCoupe,
        placesInOneCompartment
      },
    } = this.props;
    const { saleOnTwo, saleOnFour } = this.state;
    const placesCount = passengers.filter(item => (item.tariff !== '3')).length; // исключ. детей до 2х лет

    return (
      <>
        <Steps current={3} steps={STEPS} />
        <div className="selected-train-info">
          <p><b>{cityFromName} - {cityToName} ({date})</b></p>
          <p><b>Номер поезда:</b> {selectedTrain}</p>
          <p><b>Вагон:</b> № {selectedWagon}</p>
          <p><b>Отправление:</b> {departureTime}</p>
          <p><b>Прибытие:</b> {arrivalTime}</p>
          <p><b>В пути:</b> {timeInWay}</p>
          <CustomButton onClick={() => changePassengerInfo()} >
            Изменить информацию о пассажирах
          </CustomButton>
        </div>

        <h2 className="text-center margin-top">Памятка пассажиру</h2>
        <div className="ktj-passenger-memo">
          {PLACE_TYPES_KTJ.map(type => {
            const notes = type.note.map(item => <div key={item}>{item}</div>);

            return (
              <div className="text-center" key={type.title}>
                <div><b>{type.title}</b></div>
                {notes}
              </div>
            );
          })}
        </div>

        <div className="ktj-places-info">
          <p>
            Вагон № {selectedWagon}({wagonType}) поезда №{selectedTrain},
            отправляющегося в {departureTime}, содержит следующие места:
          </p>
          <h3 style={{ wordWrap: 'break-word' }}>
            {selectedWagonData.Places}
          </h3>
          <p>Вы можете указать дополнительные требования к местам:</p>
          <div className="ktj-enter-places">
            <div>
              <label htmlFor="placesFrom">Места с</label>
              <input
                id="placesFrom"
                type="number"
                placeholder=""
                onChange={(e) => enterPlaceParams('from', e.target.value.trim())}
                value={placesFrom}
              />
            </div>
            <div>
              <label htmlFor="placesTo">по</label>
              <input
                id="placesTo"
                type="number"
                placeholder=""
                onChange={(e) => enterPlaceParams('to', e.target.value.trim())}
                value={placesTo}
              />
            </div>
            {((wagonType === 'Плацкартный' || wagonType === 'Купе') && !saleOnTwo && !saleOnFour) && (
              <>
                <div>
                  <label htmlFor="countUpperSeats">Количество верхних мест:</label>
                  <input
                    id="countUpperSeats"
                    type="number"
                    placeholder=""
                    onChange={(e) => enterPlaceParams('upperSeatsCount', e.target.value.trim())}
                    value={upperSeatsCount}
                  />
                </div>
                <div>
                  <label htmlFor="countUpperSeats">Количество нижних мест:</label>
                  <input
                    id="countLowerSeats"
                    type="number"
                    placeholder=""
                    onChange={(e) => enterPlaceParams('lowerSeatsCount', e.target.value.trim())}
                    value={lowerSeatsCount}
                  />
                </div>
              </>
            )}
          </div>
          {(!saleOnTwo && !saleOnFour) && (
            <div className="button-group">
              <CustomButton onClick={() => this.setPlacesInOneCoupe()} yellow={placesInOneCoupe}>
                Места в одном купе
              </CustomButton>
              <CustomButton
                onClick={() => this.setPlacesInOneCompartment()}
                yellow={placesInOneCompartment}
              >
                Места в одном отсеке
              </CustomButton>
            </div>
          )}

          {(saleOnTwo && placesCount > 1) && (
            <div className="button-group">
              <CustomButton onClick={() => this.setTogetherPlace(0)} yellow={together}>
                Места в одном купе
              </CustomButton>
              <CustomButton onClick={() => this.setTogetherPlace(1)} yellow={!together}>
                Места в разных купе
              </CustomButton>
            </div>
          )}
          <h3>Номера мест могут быть изменены системой!</h3>
        </div>
        <div className="ktj-bedline-block">
          {(wagonType === 'Плацкартный' && Number(timeInWay.split(':')[0]) < 6) && (
            // если плацкарт и время поездки составляет менее 6ти часов, то предлагаем выбор
            // (иначе оплата за белье полюбому)
            <Checkbox
              checked={payForBedLinen}
              onchange={this.togglePayForBedLinen}
              label="Оплатить постельное белье"
              id={1}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    date,
    cityToName,
    cityFromName,
    selectedTrain,
    selectedWagon,
    placesParam,
    passengers,
    payForBedLinen,
    selectedWagonData,
    Wagons: { Cars },
    selectedTrainData: {
      Departure: { '@attributes': { Time: departureTime } },
      Arrival: { '@attributes': { Time: arrivalTime } },
      TimeInWay: timeInWay,
    },
  } = state.payTypeKTJ;

  return ({
    departureTime,
    arrivalTime,
    timeInWay,
    selectedTrain,
    selectedWagon,
    date,
    cityFromName,
    cityToName,
    placesParam,
    passengers,
    payForBedLinen,
    selectedWagonData,
    cars: [].concat(Cars),
    currentService: state.kassa.currentService,
  });
};

export default connect(mapStateToProps, null)(SelectPlaces);
