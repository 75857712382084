import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import MaskedInput from 'react-maskedinput';
import ImageUploader from 'react-images-upload';

import Icon from 'components/UI/Icon';
import Modal from 'components/UI/Modal/modal';
import Input from 'components/UI/Input/input';
import {
  sendIdentificationReq,
  setShowIdentificationModal,
} from 'actions/coid';
import { getClearPhone, imgToBase64, setObjectProperty, getObjectElement } from 'helpers/main';

import './style.scss';

const FIELDS = [
  {
    name: 'surname',
    label: 'Фамилия',
    type: 'text',
    required: true,
  },
  {
    name: 'name',
    label: 'Имя',
    type: 'text',
    required: true,
  },
  {
    name: 'patronymic',
    label: 'Отчество',
    type: 'text',
    required: true,
  },
  {
    name: 'iin',
    label: 'ИИН',
    type: 'number',
    required: true,
  },
];

const FIELDS_AD = [
  { name: 'phone', required: true },
  { name: 'selfie', required: true },
];

const Identification = (props) => {
  const [fieldsError, setFieldsError] = useState({});
  const [fieldsValue, setFieldsValue] = useState({});

  useEffect(() => {
    const init = () => {
      setFieldsValue([]);
    };

    if (!props.showIdentificationModal) {
      init();
    }
    if (props.showIdentModalFromCashout) {
      const data = [];

      data.phone = props.showIdentModalFromCashoutAccount;
      setFieldsValue(data);
    }
  },
  [
    props.showIdentificationModal,
    props.showIdentModalFromCashout,
    props.showIdentModalFromCashoutAccount
  ]);

  const validateFieldValue = (value, name) => {
    const errors = fieldsError;

    if (value === '' || typeof value === 'undefined') {
      setObjectProperty(errors, name, 'Поле обязательно для заполнения');
    } else if (name === 'iin' && value.length !== 12) {
      setObjectProperty(errors, name, 'ИИН должен состоять из 12 цифр');
    } else if (name === 'phone' && getClearPhone(value).length < 10) {
      setObjectProperty(errors, name, 'Телефон должен состоять из 11 цифр');
    }

    setFieldsError({ ...errors });
  };

  const handlerChangeFields = (name, required, value) => {
    const fields = fieldsError;

    setObjectProperty(fields, name, '');
    setFieldsError(fields);

    checkFieldValid(name, required, value);
    handlerSetFieldValue(name, value);
  };

  const checkFieldValid = (name, required, value) => {
    if (required === true) {
      validateFieldValue(value, name);
    }
  };

  const handlerSetFieldValue = (field, value) => {
    const values = fieldsValue;

    setObjectProperty(values, field, value);
    setFieldsValue(values);
  };

  const getFieldValue = (field) => {
    if (fieldsValue[field.toString()]) return getObjectElement(fieldsValue, field);
  };

  const getErrorsCount = (obj) => {
    let size = 0;

    const vals = Object.values(obj);

    for (let i = 0; i < vals.length; i++) {
      if (vals[Number(i)] !== '') size++;
    }

    return size;
  };

  const validateAndSendReq = () => {
    const fieldsList = FIELDS.concat(FIELDS_AD);

    fieldsList.forEach(({ name, required }) =>
      checkFieldValid(name, required, getFieldValue(name))
    );

    const errCount = getErrorsCount(fieldsError);

    if (errCount > 0) return;

    imgToBase64(fieldsValue.selfie).then((base) => {
      const splitted = base.split(';base64,');
      const data = {
        base: splitted[1],
        cellPhone: getClearPhone(fieldsValue.phone),
      };

      FIELDS.forEach((field) => { data[field.name] = fieldsValue[field.name]; });

      props.sendIdentificationReq(data);
    });
  };

  return (
    <>
      <Modal
        size="small-and-high"
        title="Идентификация клиента в ЛК"
        isOpen={props.showIdentificationModal}
        onBack={() => props.setShowIdentificationModal(false)}
        onClose={() => props.setShowIdentificationModal(false)}
        onOk={validateAndSendReq}
        cancelText="Отмена"
        okText="Идентифицировать"
      >
        <div className="form-data">
          {FIELDS.map((field) => (
            <Input
              key={field.name}
              id={field.name}
              type={field.type}
              onchange={(val) =>
                handlerChangeFields(
                  field.name,
                  field.required,
                  field.type === 'text' ? val.trim() : val
                )
                }
              value={fieldsValue[field.name]}
              label={field.label}
              errorMessage={
                  fieldsError[field.name] !== '' ? fieldsError[field.name] : ''
                }
              isError={Boolean(fieldsError[field.name])}
            />
          ))}

          <div
            className={fieldsError.phone ? 'input-error-block' : ''}
            style={{ marginBottom: 10 }}
          >
            <label htmlFor="phone">Введите номер телефона</label>
            <br />
            <MaskedInput
              mask="+7 (111) 111-11-11"
              type="tel"
              id="phone"
              autoComplete="off"
              maxLength={20}
              placeholder="+7 (777) 123 45 67"
              placeholderChar=" "
              className={fieldsError.phone ? 'input-warning' : ''}
              value={fieldsValue.phone}
              onChange={(e) =>
                handlerChangeFields('phone', true, e.target.value)
              }
              formatCharacters={{
                W: {
                  validate(char) {
                    return /[\wа-яА-Я-]/.test(char);
                  },
                },
              }}
            />
            <span className="inputError">{fieldsError.phone}</span>
          </div>
        </div>
        <div>
          <div
            className={`image-uploader ${fieldsError.selfie && 'image-uploader-error'
            }`}
          >
            <div className="image-uploader-item">
              <div className="image-preview">
                {fieldsValue.selfie && (
                  <img
                    style={{ maxWidth: '100px', maxHeight: '100px' }}
                    src={URL.createObjectURL(fieldsValue.selfie)}
                    alt={fieldsValue.selfie.name}
                  />
                )}
              </div>
              <ImageUploader
                singleImage
                withIcon={fieldsValue.selfie === null}
                label={
                  fieldsValue.selfie
                    ? fieldsValue.selfie.name
                    : 'Загрузите селфи'
                }
                buttonText={`Выбрать ${fieldsValue.selfie ? 'другое' : ''
                } фото`}
                onChange={(val) => handlerChangeFields('selfie', true, val[0])}
                imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.gif']}
                maxFileSize={5242880}
              />
            </div>
          </div>
        </div>
      </Modal>
      <div className="identification-block">
        <button
          className="identification-block-title"
          type="button"
          tabIndex={0}
          onClick={() => props.setShowIdentificationModal(true)}
        >
          <div>
            <Icon type="person" />
          </div>
          <div>
            <span>Идентификация клиента</span>
          </div>
        </button>
      </div>
    </>
  );
};

Identification.propTypes = {
  sendIdentificationReq: PropTypes.func.isRequired,
  setShowIdentificationModal: PropTypes.func.isRequired,
  showIdentificationModal: PropTypes.bool.isRequired,
  showIdentModalFromCashoutAccount: PropTypes.string.isRequired,
  showIdentModalFromCashout: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  showIdentificationModal: state.kassa.showIdentificationModal,
  showIdentModalFromCashoutAccount: state.kassa.showIdentModalFromCashoutAccount,
  showIdentModalFromCashout: state.kassa.showIdentModalFromCashout,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendIdentificationReq,
      setShowIdentificationModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Identification);
