/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import ReactInterval from 'react-interval';
import moment from 'moment';
import 'moment/locale/ru';
import PropTypes from 'prop-types';
import 'flatpickr/dist/flatpickr.css';
import { Russian } from 'flatpickr/dist/l10n/ru';
import Flatpickr from 'react-flatpickr';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { TableSortLabel } from '@material-ui/core';

import CustomButton from 'components/UI/Button/button';
import Icon from 'components/UI/Icon/index';
import Checkbox from 'components/UI/Checkbox/checkbox';
import { getKtjSn } from 'components/PayTypes/KTJ/helper';
import SendCheckToMail from 'components/SendCheckToMail';
import { checkPaymentStatus } from 'actions/api/makePay';
import { getCode } from 'actions/api/onlineCheck';
import {
  saveCheck,
  getCheckOnSn,
  sendCheckToEmail,
  setChecksWithCodeInProps,
  updateCheckWithCode,
  removeCheckWithCode
} from 'actions/check';
import { useCheckWithCode, useKtjCheck } from 'hooks';
import { searchPayments, saveSearchingDates } from 'actions/api/paymentHistory';
import { getTheStartDateOfTheCurrentDay, getTheEndOfTheCurrentDay, checkUnicodeSymbols } from 'helpers/main';
import { KTJ_SERVICE_ID } from 'constants/services';

const getColumns = (fromServicePaymentModal) => [
  {
    title: 'Транзакция',
    key: 'sn',
    dataIndex: 'sn',
    isSort: true
  },
  {
    title: 'Дата и время',
    key: 'datetime',
    dataIndex: 'datetime',
  },
  {
    title: '№ телефона / счета',
    key: 'account',
    dataIndex: 'account'
  },
  {
    title: 'К оплате, тг',
    key: 'totalSum',
    dataIndex: 'amount',
    isSort: true
  },
  {
    title: 'Провайдер',
    key: 'provider',
    dataIndex: 'provider'
  },
  {
    title: fromServicePaymentModal ? 'Продажа в фискал' : 'Дубликат чека',
    key: 'check',
    dataIndex: 'check',
  },
];

const PaymentsHistory = (props) => {
  const [dateFrom, setDateFrom] = useState(getTheStartDateOfTheCurrentDay());
  const [dateTo, setDateTo] = useState(getTheEndOfTheCurrentDay());
  const [localPaymentHistory, setLocalPaymentHistory] = useState([]);
  const [searchedSn, setSearchedSn] = useState('');
  const [paymentsStatusIntervalEnabled, setPaymentsStatusIntervalEnabled] = useState(false);
  const [selectedRowsArray, setSelectedRowsArray] = useState([]);
  const [totalPaymentSum, setTotalPaymentSum] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('sn');
  const [showSendCheckToMailModal, setShowSendCheckToMailModal] = useState(false);
  const [snForSendingCheckToEmail, setSnForSendingCheckToEmail] = useState('');
  const {
    paymentInProccess,
    ktjReceipt,
    checksWithCode,
    paymentsHistory: paymentsList
  } = props;

  useKtjCheck(ktjReceipt, props.saveCheck);
  const { hasSuccessPayment, getCodeHandler } = useCheckWithCode({
    checksWithCode,
    setChecksWithCodeInProps: props.setChecksWithCodeInProps,
    updateCheckWithCode: props.updateCheckWithCode,
    removeCheckWithCode: props.removeCheckWithCode,
    saveCheck: props.saveCheck,
    getCode: props.getCode,
    paymentsList
  });

  useEffect(() => {
    props.searchPayments(dateFrom, dateTo);
  }, [dateFrom, dateTo]);

  useEffect(() => {
    setPaymentsHistory();
  }, [paymentsList.length, searchedSn, paymentInProccess.length]);

  // если платеж в проведении, перезапрашиваем статус
  useEffect(() => {
    setPaymentsStatusIntervalEnabled(paymentInProccess.length);

    return () => setPaymentsStatusIntervalEnabled(false);
  },
  [paymentInProccess.length]);

  const setPaymentsHistory = () => {
    let paymentsHistoryList = [].concat(paymentsList).concat(paymentInProccess);
    const tableData = [];

    if (searchedSn) {
      paymentsHistoryList = paymentsHistoryList.filter(({ sn, account }) =>
        sn.toString().includes(searchedSn) || account.toString().includes(searchedSn)
      );
    }

    paymentsHistoryList.forEach((
      {
        sn,
        dateout,
        account,
        amount,
        com,
        nameService,
        idService,
        status,
        statusNote
      },
      idx
    ) => {
      const isExist = tableData.filter(row => (row.sn === sn));
      let datetime = (
        <div className="tooltip-wrapper">
          <span>Не проведен</span>
          <ReactTooltip id="tooltip-ref" backgroundColor="#232329">
            <span>{statusNote}</span>
          </ReactTooltip>
          <Icon
            type="question"
            className="payment-history-icon"
            data-tip
            data-for="tooltip-ref"
          />
        </div>
      );

      if (status === 919 || !('status' in paymentsHistoryList[Number(idx)])) {
        datetime = 'В обработке';
      }

      if (status === 0) {
        datetime = moment(dateout.toString(), 'YYYYMMDDHHmmss').format('DD.MM.YYYY HH:mm:ss');
      }

      if (!isExist.length) {
        tableData.push({
          key: sn,
          sn,
          datetime,
          account,
          amount: (
            <>
              {Number(amount) - Number(com)} тг <br />
              <span className="table-text-com">Внесено: {Number(amount)} тг</span> <br />
              <span className="table-text-com">Комиссия {com} тг</span>
            </>
          ),
          commission: com,
          totalSum: amount,
          provider: checkUnicodeSymbols(nameService),
          check: getPrintButton(sn, KTJ_SERVICE_ID === idService),
          idService,
        });
      }
    });

    setLocalPaymentHistory(tableData);
  };

  const displayTotalAmount = useCallback(
    () => {
      const sum = totalPaymentSum.reduce((a, b) => a + b, 0);

      return (
        <div className="total-sum">
          <span>Выбрано: {totalPaymentSum.length}</span>
          <span>Cумма: {sum.toFixed(2)}</span>
          <button type="button" className="reset-counter" onClick={resetCounter}>
            Снять выделение
          </button>
        </div>
      );
    },
    [totalPaymentSum],
  );

  const resetCounter = useCallback(() => {
    setSelectedRowsArray([]);
    setTotalPaymentSum([]);
  }, []);

  const getPrintButton = useCallback((sn, isKtj) => (
    <>
      <ReactTooltip id="tooltip-print-ref" backgroundColor="#232329">Печатать</ReactTooltip>
      <CustomButton
        data-tip
        data-for="tooltip-print-ref"
        onClick={() => props.getCheckOnSn({ sn, isKtj })}
      >
        <Icon type="print" />
      </CustomButton>

      <ReactTooltip id="tooltip-mail-ref" backgroundColor="#232329">Отправить на email</ReactTooltip>
      <CustomButton
        data-tip
        data-for="tooltip-mail-ref"
        onClick={() => {
          setSnForSendingCheckToEmail(sn.toString());
          setShowSendCheckToMailModal(true);
        }}
      >
        <Icon type="mail" />
      </CustomButton>
    </>
  ), [checksWithCode]);

  const highlightRow = (record) => {
    if (typeof record.datetime === 'object') {
      return 'failed-row';
    }

    if (record.datetime === 'В обработке') {
      return 'pending-row';
    }

    return '';
  };

  const changeDateRange = (dates) => {
    if (dates.length === 2) {
      const newDateFrom = moment(dates[0]).format('YYYY-MM-DD 00:00:01');
      const newDateTo = moment(dates[1]).format('YYYY-MM-DD 23:59:59');
      const dateFromStr = moment(newDateFrom).format('YYYYMMDDHHmmss');
      const dateToStr = moment(newDateTo).format('YYYYMMDDHHmmss');

      setDateFrom(dateFromStr);
      setDateTo(dateToStr);
      props.saveSearchingDates(dateFromStr, dateToStr);
    }
  };

  const handleSelectAllClick = (e) => {
    if (e.target.checked) {
      const newSelecteds = localPaymentHistory.map(n => n.key);
      const tempSumArray = localPaymentHistory.map(s => s.totalSum);

      setSelectedRowsArray(newSelecteds);
      setTotalPaymentSum(tempSumArray);

      return;
    }

    resetCounter();
    setSelectedRowsArray([]);
  };

  const isSelected = key => selectedRowsArray.indexOf(key) !== -1;

  const handleClickCheckbox = (e, idx) => {
    const selectedRowsDuplicate = [].concat(selectedRowsArray);
    const totalSumDuplicate = [].concat(totalPaymentSum);
    const indexOfRow = selectedRowsDuplicate.indexOf(+e.target.id);

    if (e.target.checked) {
      selectedRowsDuplicate.push(+e.target.id);
      totalSumDuplicate.push(localPaymentHistory[Number(idx)].totalSum);
    } else {
      selectedRowsDuplicate.splice(indexOfRow, 1);
      totalSumDuplicate.splice(indexOfRow, 1);
    }

    setSelectedRowsArray(selectedRowsDuplicate);
    setTotalPaymentSum(totalSumDuplicate);
  };

  const getSorting = (sortDirection, fieldForSortBy) =>
    sortDirection === 'desc'
      ? (a, b) => (a[fieldForSortBy.toString()] < b[fieldForSortBy.toString()] ? -1 : 1)
      : (a, b) => (b[fieldForSortBy.toString()] < a[fieldForSortBy.toString()] ? -1 : 1);

  const createSortHandler = (key) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(key);
  };

  const getSortDirection = (item) => {
    if (orderBy === item.key) return order;

    return 'asc';
  };

  const getLocalPaymentHistoryForSort = () => {
    const sorting = getSorting(order, orderBy);

    return localPaymentHistory.sort(sorting);
  };

  return (
    <div className="payment-history">
      {showSendCheckToMailModal && (
        <SendCheckToMail
          sn={snForSendingCheckToEmail}
          sendCheckToEmail={props.sendCheckToEmail}
          setShowSendCheckToMailModal={setShowSendCheckToMailModal}
        />
      )}
      <ReactInterval
        timeout={(getKtjSn() || Object.keys(checksWithCode).length) ? 10000 : 30000}
        enabled={paymentsStatusIntervalEnabled}
        callback={() => props.checkPaymentStatus(paymentInProccess)}
      />
      <ReactInterval
        timeout={5000}
        enabled={hasSuccessPayment}
        callback={getCodeHandler}
      />
      <div className="payment-history-head">
        <h3 className="payment-history-title">Журнал принятых платежей</h3>
        <div className="input-wrapper">
          <input
            placeholder="Найти транзакцию в журнале"
            className="search"
            onChange={e => setSearchedSn(e.target.value)}
          />
        </div>
        <div className="period-block-title">
          <Flatpickr
            options={{
              dateFormat: 'd-m-Y',
              mode: 'range',
              locale: Russian,
              defaultDate: [
                moment(dateFrom, 'YYYYMMDDHHmmss').format('DD-MM-YYYY'),
                moment(dateTo, 'YYYYMMDDHHmmss').format('DD-MM-YYYY')
              ]
            }}
            className="datepicker-wrapper"
            onChange={dates => changeDateRange(dates)}
          />
        </div>
      </div>
      <div className="payments-table">
        <TableContainer component={Paper} elevation={0}>
          <Table aria-label="simple table">
            {!localPaymentHistory.length &&
              <caption className="table-caption">Отсутствуют платежи за выбранный период</caption>
            }
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" align="center">
                  <Checkbox
                    onchange={(e) => handleSelectAllClick(e)}
                    checked={
                      selectedRowsArray.length > 0 &&
                      selectedRowsArray.length === localPaymentHistory.length
                    }
                  />
                </TableCell>
                {getColumns(props.fromServicePaymentModal).map((item) => (
                  <TableCell key={item.key} sortDirection="asc">
                    {item.isSort ? (
                      <TableSortLabel
                        active={item.isSort}
                        direction={getSortDirection(item)}
                        onClick={() => createSortHandler(item.key)}
                      >
                        {item.title}
                      </TableSortLabel>
                    ) :
                      `${item.title}`
                    }
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {getLocalPaymentHistoryForSort()
                .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                .map((rows, idx) => {
                  const isItemSelected = isSelected(rows.key);

                  return (
                    <TableRow key={rows.key} hover classes={{ root: highlightRow(rows) }} >
                      <TableCell padding="checkbox" component="th" scope="row" align="center">
                        <Checkbox
                          id={rows.key}
                          checked={isItemSelected}
                          onchange={(e) => handleClickCheckbox(e, idx)}
                        />
                      </TableCell>
                      <TableCell>{rows.key}</TableCell>
                      <TableCell>{rows.datetime}</TableCell>
                      <TableCell>{rows.account}</TableCell>
                      <TableCell>{rows.amount}</TableCell>
                      <TableCell>{rows.provider}</TableCell>
                      <TableCell>
                        {
                          props.fromServicePaymentModal ? (
                            <CustomButton onClick={() => props.showOperationDetails(rows)}>
                              Провести
                            </CustomButton>
                          )
                            : rows.check
                        }
                      </TableCell>
                    </TableRow >
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={localPaymentHistory.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
          labelRowsPerPage="Количество платежей на странице"
        />
        {totalPaymentSum.length > 0 && displayTotalAmount()}
      </div>
    </div >
  );
};

PaymentsHistory.defaultProps = {
  fromServicePaymentModal: false,
  showOperationDetails: null
};

PaymentsHistory.propTypes = {
  paymentsHistory: PropTypes.array.isRequired,
  paymentInProccess: PropTypes.array.isRequired,
  checksWithCode: PropTypes.object.isRequired,
  ktjReceipt: PropTypes.object.isRequired,
  searchPayments: PropTypes.func.isRequired,
  checkPaymentStatus: PropTypes.func.isRequired,
  getCheckOnSn: PropTypes.func.isRequired,
  saveCheck: PropTypes.func.isRequired,
  saveSearchingDates: PropTypes.func.isRequired,
  getCode: PropTypes.func.isRequired,
  sendCheckToEmail: PropTypes.func.isRequired,
  setChecksWithCodeInProps: PropTypes.func.isRequired,
  updateCheckWithCode: PropTypes.func.isRequired,
  removeCheckWithCode: PropTypes.func.isRequired,
  fromServicePaymentModal: PropTypes.bool,
  showOperationDetails: PropTypes.func
};

function mapStateToProps(state) {
  return {
    paymentsHistory: state.payment.paymentsHistory,
    paymentInProccess: state.payment.inProccess,
    checksWithCode: state.checksWithCode.checksWithCode,
    ktjReceipt: state.ktjReceipt.ktjReceipt,
    currentService: state.kassa.currentService,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    searchPayments,
    checkPaymentStatus,
    getCheckOnSn,
    saveCheck,
    sendCheckToEmail,
    setChecksWithCodeInProps,
    updateCheckWithCode,
    removeCheckWithCode,
    saveSearchingDates,
    getCode
  },
  dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsHistory);
