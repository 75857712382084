import PayTypes from 'paytypes';
import React from 'react';
import { connect } from 'react-redux';
import Steps from 'components/UI/Steps/steps';
import CustomButton from 'components/UI/Button/button';
import Alert from 'components/UI/Alert/alert';
import { STEPS } from 'constants/ktj';
import { t } from 'helpers/main';
import WagonScheme from '../WagonScheme';
import { getWagonTypeInfo, getWagonInfo } from '../helper';

const { KtjWagonsTemplate } = PayTypes.templates;

class Wagons extends KtjWagonsTemplate {
  state = {
    showWagonScheme: false,
    wagonForScheme: {},
    selectedWagon: '',
    wagonType: '',
    wagonData: {},
    wagonClassService: ''
  };

  componentDidMount() {
    this.props.setOnOkCallback(() => this.validateAndSelectWagon());
  }

  validateAndSelectWagon = () => {
    if (this.state.selectedWagon) {
      const { selectedWagon, wagonType, wagonData, wagonClassService } = this.state;

      this.props.selectWagon(selectedWagon, wagonType, wagonData, wagonClassService);
    } else {
      this.props.showError(
        'Выберите вагон и повторите попытку',
        'Вагон не выбран'
      );
    }
  };

  showWagonScheme(wagonNumber) {
    const { cars } = this.props;
    const wagonTypeInfo = getWagonTypeInfo(cars, wagonNumber);
    const wagonInfo = getWagonInfo(cars, wagonNumber);
    const wagonForScheme = {
      Type: wagonTypeInfo['@attributes'].Type,
      Places: [].concat(wagonInfo.Places)
    };

    this.setState({ wagonForScheme });
    this.setShowWagonScheme(true);
  }

  setShowWagonScheme = (showWagonScheme) => this.setState({ showWagonScheme });

  setSelectedWagon = (selectedWagon, wagonType, wagonData, wagonClassService) => {
    this.setState({ selectedWagon, wagonType, wagonData, wagonClassService });
    this.props.setOnOkCallback(() => this.validateAndSelectWagon());
  }

  getWagonsEmpty=() => {
    const res = [];

    res.push(<div />);

    return res;
  }

  renderComponent() {
    const { showWagonScheme, selectedWagon, wagonForScheme } = this.state;
    const {
      date,
      cars,
      cityFromName,
      cityToName,
      selectedTrain,
      departureTime,
      arrivalTime,
      timeInWay,
    } = this.props;

    if (showWagonScheme && wagonForScheme.Places) {
      return <WagonScheme car={wagonForScheme} setShowWagonScheme={this.setShowWagonScheme} />;
    }

    return (
      <>
        <Steps current={2} steps={STEPS} />
        <div className="selected-train-info">
          <p><b>{cityFromName} - {cityToName} ({date})</b></p>
          <p><b>Номер поезда:</b> {selectedTrain}</p>
          <p><b>Отправление:</b> {departureTime}</p>
          <p><b>Прибытие:</b> {arrivalTime}</p>
          <p><b>В пути:</b> {timeInWay}</p>
          <CustomButton onClick={() => this.props.clearTrain()} >
            Выбрать другой поезд
          </CustomButton>
        </div>
        <div>
          <h3>Выберите подходящий вагон:
            {selectedWagon && <Alert message={`Выбран вагон № ${selectedWagon}`} banner />}
          </h3>
          <hr />
          {cars.length &&
            cars.map((wagonTypes,) => {
              if (typeof wagonTypes !== 'undefined') {
                const { '@attributes': { Type: wagonClassService } } = wagonTypes.ClassService;

                return [].concat(wagonTypes.Car)
                  .map(wagon => {
                    const {
                      Places,
                      '@attributes': { Number: WagonNumber },
                      Seats: { '@attributes': { SeatsDn, SeatsUp, SeatsLateralDn, SeatsLateralUp } }
                    } = wagon;

                    return (
                      <button
                        key={WagonNumber}
                        className={`ktj-selectable-block buttonWithoutStyles ${WagonNumber === selectedWagon ? 'ktj-selected-block' : ''}`}
                        onClick={() => this.setSelectedWagon(WagonNumber, wagonTypes['@attributes'].Type, wagon, wagonClassService)}
                        type="button"
                        tabIndex={0}
                      >
                        <div className="ktj-wagon-header">
                          <div>
                            <b>№ {WagonNumber}</b>
                          </div>
                          <div>
                            <b>{wagonTypes['@attributes'].Type} ({wagonTypes['@attributes'].TrainLetter})</b>
                          </div>
                          <div>
                            {t('price')}: <b>{wagonTypes.Tariff} тг</b>
                          </div>
                          <div>
                            Количество свободных мест:
                            {(Places.match(/,/g) !== null) ? Places.match(/,/g).length + 1 : 1}
                          </div>
                          <div>
                            <CustomButton onClick={() => this.showWagonScheme(WagonNumber)}>
                              Схема вагона
                            </CustomButton>
                          </div>
                        </div>
                        <div className="ktj-places">
                          <div>
                            {t('ktj.lower')}: {(+SeatsDn) + (+SeatsLateralDn)} <br />
                            {t('ktj.upper')}: {(+SeatsUp) + (+SeatsLateralUp)}
                          </div>
                        </div>
                      </button>
                    );
                  });
              }

              return this.getWagonsEmpty();
            })}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    date,
    cityToName,
    cityFromName,
    selectedTrain,
    Wagons: { Cars },
    selectedTrainData: {
      Departure: { '@attributes': { Time: departureTime } },
      Arrival: { '@attributes': { Time: arrivalTime } },
      TimeInWay: timeInWay,
    },
  } = state.payTypeKTJ;

  return ({
    departureTime,
    arrivalTime,
    timeInWay,
    selectedTrain,
    date,
    cityFromName,
    cityToName,
    cars: [].concat(Cars),
  });
};

export default connect(mapStateToProps, null)(Wagons);
