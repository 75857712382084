import React from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { goToLoginPage } from 'actions/navigate';
import CustomButton from 'components/UI/Button/button';

const NotFound = (props) => (
  <div>
    <h1>404 - Страница не найдена!</h1>
    <CustomButton yellow className="login-form-button pay-input" onClick={props.goToLoginPage}>Перейти на главную</CustomButton>
  </div>
);

NotFound.propTypes = {
  goToLoginPage: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ goToLoginPage }, dispatch);
}

export default connect(null, mapDispatchToProps)(NotFound);

