/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomButton from 'components/UI/Button/button';
import Icon from '../Icon/index';
import '../Modal/modal.scss';

const ConfirmModal = ({
  isOpen,
  onClose,
  hideModal,
  onOk,
  okText,
  okButtonIsDisabled,
  title,
  message
}) => {
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    if (confirmed) {
      onOk();
      setConfirmed(false);
    }
  }, [confirmed]);

  if (isOpen) {
    return (
      <div className="modal-wrapper confirm-modal" >
        <div className="modal-block">
          <div className="modal-confirm">
            <div className="modal-confirm-header">
              <Icon type="alertIcon" />
              <span className="modal-header-text">{title}</span>
            </div>
            <p>{message}</p>
            <div className="confirm-button-group">
              <CustomButton
                className="modal-cancel"
                onClick={() => {
                  onClose();
                  setConfirmed(false);
                }}
              >
                Отмена
              </CustomButton>
              <CustomButton
                disabled={okButtonIsDisabled}
                yellow
                className="modal-ok"
                onClick={() => {
                  hideModal();
                  setConfirmed(true);
                }}
              >
                {okText}
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

ConfirmModal.defaultProps = {
  okButtonIsDisabled: false,
  okText: 'Ок',
  title: 'Все данные будут потеряны',
  message: 'Вы уверены, что хотите закрыть окно?'
};

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  okText: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  okButtonIsDisabled: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
};

export default ConfirmModal;
