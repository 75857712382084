import * as types from 'actions/loader/types';

const defaultState = {
  loaderCounter: 0,
  showLoaderPayment: false
};

const loader = (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.LOAD_START:
      return { ...state, loaderCounter: state.loaderCounter + 1 };
    case types.LOAD_STOP: {
      if (action.force || !state.loaderCounter) {
        return { ...state, loaderCounter: 0 };
      }

      return { ...state, loaderCounter: state.loaderCounter - 1 };
    }
    case types.LOAD_PAYMENT_START:
      return { ...state, showLoaderPayment: true };
    case types.LOAD_PAYMENT_STOP:
      return { ...state, showLoaderPayment: false };
    default:
      return state;
  }
};

export default loader;
