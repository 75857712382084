/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AdBlockDetect from 'react-ad-block-detect';

import { useLocation } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import NoWSConnect from 'components/NoWSConnect';
import Alert from 'components/UI/AlertModal/alertmodal';
import Loader from 'components/UI/Loader/Loader';
import ErrorBoundary from 'components/ErrorBoundary';
import LoaderPayment from 'components/UI/LoaderPayment';
import ConnectionStatus from 'components/ConnectionStatus';
import CertExpiredWarning from 'components/CertExpiredWarning';
import FiscalPaymentsStatus from 'components/Fiscal/FiscalPaymentsStatus';
import Identification from 'containers/Identification/index';
import { getAuthData } from 'model/UserDb';
import { showCashOutInfo } from 'actions/alert';
import { goToLoginPage } from 'actions/navigate';
import { getBalance } from 'actions/api/getBalance';
import { getServicesList } from 'actions/services';
import { sendInfoLogToGateway } from 'actions/api/logs';
import { getCommissionProfiles } from 'actions/commission';
import Fiscal from 'containers/Fiscal/index';
import { useWebSocket } from 'hooks';
import { setSentryBreadсrumbs } from 'helpers/main';
import { runTheLoader, stopTheLoader } from 'actions/loader';
import {
  setWsServerStatus,
  getFiscalAuthDataCommonRequest,
  checkFiscalAuthCommon
} from 'actions/fiscal';

import HeaderBlock from './HeaderBlock/index';
import FooterBlock from './FooterBlock';
import './layout.css';

const Main = (props) => {
  const { pathname } = useLocation();
  const { children, authDataIsRequested, isAuthInFiscal, isFiscal: isFiscalV2 } = props;
  const { isClosedWsByServer } = useWebSocket(
    props.sendInfoLogToGateway,
    props.getServicesList,
    props.getCommissionProfiles,
    props.showCashOutInfo
  );

  useEffect(() => {
    props.runTheLoader();

    setTimeout(() => props.stopTheLoader(), 4000);
  }, []);

  useEffect(() => {
    // костыль для шрифта для чека (иначе при первой генерации чека - шрифта нет)
    const canvas = document.createElement('canvas');

    canvas.setAttribute('width', 500);
    canvas.setAttribute('height', 500);
    canvas.setAttribute('id', 'myCanvas');
    canvas.style.display = 'none';
    document.querySelector('.content').appendChild(canvas);
    const c = document.getElementById('myCanvas');
    const ctx = c.getContext('2d');

    ctx.font = '30px Roboto';
    ctx.fillText('Hello World', 10, 50);
    setSentryBreadсrumbs('page', 'Update page');
  }, []);

  useEffect(() => {
    props.checkFiscalAuthCommon();
  }, [authDataIsRequested, isAuthInFiscal]);

  useEffect(() => {
    const { guid } = getAuthData();

    if (!guid) props.goToLoginPage();
  }, [props.goToLoginPage]);

  useEffect(() => {
    props.getBalance();
  }, [props.getBalance]);

  useEffect(() => {
    props.setWsServerStatus(isClosedWsByServer);
  }, [isClosedWsByServer]);

  return (
    <ErrorBoundary>
      <ReactNotifications />
      <HeaderBlock />
      <Alert />
      <Loader />
      <LoaderPayment/>
      <div className="content">
        <AdBlockDetect>
          <div className="warning-block">
            Мы обнаружили что у Вас включен AdBlock. Чтобы продолжить работу с
            веб-терминалом отключите его в настройках браузера.
          </div>
        </AdBlockDetect>
        {(isFiscalV2 && !window.location.href.includes('session-ended')) ? <FiscalPaymentsStatus/> : ''}
        <CertExpiredWarning />
        <ConnectionStatus />
        {(isFiscalV2 && !window.location.href.includes('session-ended')) ? <Fiscal /> : ''}
        {!isClosedWsByServer ? children : <NoWSConnect />}
      </div>
      {(pathname === '/main' && !isClosedWsByServer) && <Identification />}
      <FooterBlock />

    </ErrorBoundary>
  );
};

Main.propTypes = {
  isFiscal: PropTypes.bool.isRequired,
  authDataIsRequested: PropTypes.bool.isRequired,
  children: PropTypes.object.isRequired,
  goToLoginPage: PropTypes.func.isRequired,
  getBalance: PropTypes.func.isRequired,
  runTheLoader: PropTypes.func.isRequired,
  stopTheLoader: PropTypes.func.isRequired,
  sendInfoLogToGateway: PropTypes.func.isRequired,
  getServicesList: PropTypes.func.isRequired,
  showCashOutInfo: PropTypes.func.isRequired,
  getCommissionProfiles: PropTypes.func.isRequired,
  setWsServerStatus: PropTypes.func.isRequired,
  isAuthInFiscal: PropTypes.bool.isRequired,
  checkFiscalAuthCommon: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return ({
    isFiscal: state.fiscal.isFiscal,
    authDataIsRequested: state.fiscal.authDataIsRequested,
    isAuthInFiscal: state.fiscal.isAuthInFiscal
  });
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getBalance,
    runTheLoader,
    stopTheLoader,
    goToLoginPage,
    showCashOutInfo,
    getServicesList,
    getCommissionProfiles,
    sendInfoLogToGateway,
    getFiscalAuthDataCommonRequest,
    setWsServerStatus,
    checkFiscalAuthCommon
  }, dispatch);
}

export default (connect(mapStateToProps, mapDispatchToProps)(Main));
