import { useDispatch } from 'react-redux';
import { runTheLoader, stopTheLoader } from 'actions/loader';

const useLoader = () => {
  const dispatch = useDispatch();

  return {
    runTheLoader: () => dispatch(runTheLoader()),
    stopTheLoader: () => dispatch(stopTheLoader()),
  };
};

export default useLoader;

