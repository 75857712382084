import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/UI/Modal/modal';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';
import CustomButton from 'components/UI/Button/button';
import Icon from 'components/UI/Icon';

const EditNotification = ({ itemData, onCloseModal, onSend, open }) => {
  const [time, setTime] = useState('');
  const [error, setError] = useState(false);

  const onBeforeSend = useCallback((status) => () => {
    setError(false);
    let success = true;
    const id = itemData.id;

    if (status === 1) {
      if (time === '') {
        setError(true);
        success = false;
      }
    }
    if (success) {
      onCloseModal();
      onSend(id, status, time);
    }
  }, [itemData, onCloseModal, onSend]);

  const preSetTime = useCallback((val) => {
    const dateCurr = moment(val[0]).format('YYYY-MM-DD HH:mm:ss');

    setTime(dateCurr);
  }, []);

  const getFormattedTitle = useCallback(() => {
    const title = itemData.title;

    if (title) {
      const amountBefore = title.indexOf('на сумму');

      return title.substr(0, amountBefore);
    }

    return title;
  }, [itemData]);

  const getAmount = useCallback(() => {
    const title = itemData.title;

    if (title) {
      const amountBefore = title.indexOf('сумму') + 6;
      const amountAfter = title.indexOf('тенге') - 1;
      const amountString = title.substr(amountBefore, amountAfter - amountBefore);
      const amount = Number(amountString).toLocaleString();

      return `Сумма: ${amount} ₸`;
    }

    return title;
  }, [itemData]);

  return (
    <div className="notifModal">
      <Modal
        isOpen={open}
        onClose={onCloseModal}
        title=""
        isFooterHidden
        size="small"
      >
        <div className="notifModalCont">
          <Icon type="getMoney" className="alert-error" />
          <div className="notifTitle">{getFormattedTitle()}</div>
          <div className="notifSumm">{getAmount()}</div>
          <div className="notifTime" id="notifTime">
            <div>(время выдачи)</div>
            <Flatpickr
              data-enable-time
              options={{
                dateFormat: 'H:i',
                noCalendar: true,
                time_24hr: true,
                allowInput: true,
                position: 'auto center'
              }}
              className="datepicker-wrapper timepicker"
              onChange={(date) => preSetTime(date)}
              placeholder="00:00"

            />
          </div>
          {error && <div style={{ color: 'red' }}>Время обязательно для заполнения</div>}
          <div className="notifButtons">
            <CustomButton onClick={onBeforeSend(1)} className="notifButton">Подтвердить</CustomButton>
            <CustomButton onClick={onBeforeSend(0)} className="notifButton cancelButton">Отклонить</CustomButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

EditNotification.propTypes = {
  itemData: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,

};

export default EditNotification;
