import React, { Component } from 'react';
import AuthHeaderBlock from 'layout/auth/AuthHeaderBlock';

class NoWSConnect extends Component {
  render() {
    return (
      <>
        <AuthHeaderBlock />
        <div style={{ padding: 60, backgroundColor: 'white', textAlign: 'center' }}>
          <h2>Данная вкладка больше неактивна</h2>
          <p>Т.к. у Вас открыт веб-терминал в еще одной вкладке или окне</p>
        </div>
      </>
    );
  }
}

export default NoWSConnect;
