import InitDb from './InitDb';

// Заполнение/Добавление записей в таблицу с сервисами
export function addAllServices(services) {
  return getDbObject()
    .then((db) => {
      clearServiceTable(db)
        .then(() => {
          const tx = db.transaction('allServices', 'readwrite');
          const store = tx.objectStore('allServices');

          services.map(async service => {
            const obj = {
              idService: 0,
              type: 0,
              name: '',
              rank: 0,
              minLength: 0,
              maxLength: 0,
              logo: '',
              checkOnline: 0,
              typeInterface: 0,
              regExp: '',
              maskEdit: '',
              grbut: '',
              grname: '',
              idsg: 0,
              description: '',
              tag: '',
              isEMoney: 0,
              minAmount: 0,
              maxAmount: 0,
              rounding: '', // приходит только если у сервиса указаны одна из границ коммиссий
              minPr: '', // приходит только если у сервиса указаны обе границы коммиссий
              maxPr: '', // приходит только если у сервиса указаны обе границы коммиссий
              isCommissionDenied: false,
              min: '',
              max: '',
              ...service
            };

            if (typeof service.isCommissionDenied !== 'undefined') {
              obj.isCommissionDenied = true;
            }

            obj.maskEdit = obj.maskEdit.toString();

            store.put(obj);
            await tx.done;
          });
        });
    });
}

const getDbObject = async () => InitDb();

const clearServiceTable = async (db) => {
  const tx = db.transaction('allServices', 'readwrite');
  const store = tx.objectStore('allServices');
  const clearRes = store.clear();

  await tx.done;

  return clearRes;
};

// Получение списка сервисов
export async function getAllServices() {
  try {
    const db = await InitDb();

    return db.getAll('allServices');
  } catch (e) {
    return [];
  }
}

// Получение одного сервис из всего списка сервисов
// export async function getServiceOnID(key) {
//   try {
//     const db = await InitDb();
//     const value = await db.get('allServices', key);

//     return value;
//   } catch (e) {
//     return null;
//   }
// }

export async function getMinAndMaxAmount(key) {
  try {
    const db = await InitDb();
    const service = await db.get('allServices', key);
    const { minAmount, maxAmount } = service;

    return { minAmount: minAmount || 0, maxAmount: maxAmount || 0 };
  } catch (e) {
    return { minAmount: 0, maxAmount: 0 };
  }
}
