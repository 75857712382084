/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import Modal from 'components/UI/Modal/modal';
import Icon from 'components/UI/Icon/index';
import * as encashmentActions from 'actions/encashment';
import * as checkActions from 'actions/check';
import { setTransactionNumber as setSn, initCurrentService as initService } from 'actions/pay';
import { showError as showErrorAlert } from 'actions/alert';
import { generateCheckDataForEncashment } from 'helpers/check';
import { getCurrentDate, generateTransactionNumber, getFromLocalStorage, setToLocalStorage } from 'helpers/main';
import { ENCASHMENT_BLOCK_MINUTES } from 'constants/all';
import Alert from 'components/UI/Alert/alert';

const Encachment = ({
  SN,
  amount,
  isFiscal,
  cashFunds,
  isRequested,
  getCheckOnSn,
  doEncashment,
  initEncashmentData,
  setTransactionNumber,
  showError,
  isEncashmentSendedToAP,
  isEncashmentSendedToFiscal,
  initEncashmentStatuses,
  initCurrentService
}) => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [amountForInfo, setAmountForInfo] = useState(0);
  const [encashmentAmount, setEncashmentAmount] = useState(0);
  const [wasButtonPressed, setWasButtonPressed] = useState(false);
  const [isEncashmentBlocked, setIsEncashmentBlocked] = useState(true);
  const currentDate = getCurrentDate();
  const encashmentDateForCheck = moment(currentDate, 'YYYYMMDDHHmmss').format('YYYY-MM-DDTHH:MM:SS.SSSSSSSZ');

  const checkEnchasmentBlocked = useCallback(() => {
    const blockTime = getFromLocalStorage('encashmentBlockTime');

    if (blockTime && isFiscal) {
      const dateDiff = moment().diff(blockTime);
      const diffDuration = moment.duration(dateDiff);

      if (diffDuration.minutes() >= ENCASHMENT_BLOCK_MINUTES) {
        setToLocalStorage('encashmentBlockTime', '');
        setIsEncashmentBlocked(false);
      } else {
        setIsEncashmentBlocked(true);
      }
    } else {
      setIsEncashmentBlocked(false);
    }
  }, [isFiscal, showInfoModal]);

  useEffect(() => {
    // check encashment block time
    checkEnchasmentBlocked();
  }, [isFiscal, showInfoModal]);

  useEffect(() => {
    if (isRequested) setShowInfoModal(true);
    initCurrentService();
  }, [isRequested]);

  useEffect(() => {
    if (isFiscal) {
      if (cashFunds) {
        setEncashmentAmount(Math.floor(cashFunds));
      }
    } else if (amount) {
      setEncashmentAmount(Math.floor(amount));
    }
  }, [amount, isFiscal, cashFunds]);

  useEffect(() => {
    if (encashmentAmount) {
      setAmountForInfo(encashmentAmount);
    }
  }, [encashmentAmount, amountForInfo]);

  useEffect(() => {
    if ((!isFiscal && isEncashmentSendedToAP) ||
        (isFiscal && isEncashmentSendedToAP && isEncashmentSendedToFiscal)
    ) {
      setWasButtonPressed(false);
      setShowInfoModal(false);
      setShowSuccessModal(true);
    }
  }, [isEncashmentSendedToAP, isEncashmentSendedToFiscal]);

  const handleSubmit = useCallback(() => {
    if (!encashmentAmount) {
      showError('Нет суммы в кассе для проведения инкассации');
    } else if (isFiscal && encashmentAmount > cashFunds) {
      showError(
        'Обнаружена нехватка средств в кассе для совершения данной операции. Пожалуйста попробуйте немного позднее',
        'В кассе недостаточно средств'
      );
    } else {
      setWasButtonPressed(true);
      setShowInfoModal(false);
      goToEncachment();
    }
  }, [encashmentAmount]);

  const goToEncachment = useCallback(() => {
    if (encashmentAmount) {
      const sn = generateTransactionNumber();
      const totalAmount = encashmentAmount;
      const checkDataObj = generateCheckDataForEncashment(
        sn,
        encashmentAmount,
        encashmentDateForCheck
      );

      setTransactionNumber(sn);
      doEncashment({ totalAmount, currentDate, SN: sn, checkData: checkDataObj, isFiscal });
      initEncashmentData();
    } else {
      showError('Нет суммы в кассе для проведения инкассации');
    }
  }, [encashmentAmount]);

  const hideInfoModal = useCallback(() => {
    setShowInfoModal(false);
    initEncashmentData();
  }, []);

  const printCheck = useCallback((sn) => {
    getCheckOnSn({ sn, amount: encashmentAmount, dateIn: encashmentDateForCheck });
    setShowSuccessModal(false);
    setEncashmentAmount(0);
    initEncashmentStatuses();
    setTransactionNumber('');
  }, [encashmentAmount]);

  const hideSuccessModal = useCallback(() => {
    setShowSuccessModal(false);
    setEncashmentAmount(0);
    setAmountForInfo(0);
    initEncashmentStatuses();
    setTransactionNumber('');
  }, []);

  if (showInfoModal) {
    return (
      <Modal
        title="Инкассация"
        isOpen={showInfoModal}
        onOk={handleSubmit}
        okText="Инкассировать"
        onClose={hideInfoModal}
        okButtonIsDisabled={wasButtonPressed || isEncashmentBlocked}
        size="small"
      >

        <div className="encashment-modal">
          Cумма в кассе: {encashmentAmount} тг
        </div>
        <br />
        {isEncashmentBlocked ? (
          <Alert
            message="Операция временно заблокирована. Кнопка станет активна через несколько минут."
            banner
          />
        ) : null}
      </Modal>
    );
  }

  if (showSuccessModal) {
    return (
      <Modal
        title="Инкассация"
        isOpen={showSuccessModal}
        onOk={() => printCheck(SN)}
        okText="Распечатать чек"
        onClose={hideSuccessModal}
        size="small"
      >
        <div className="encashment-success" >
          <Icon type="success" />
          Инкассация на сумму {amountForInfo} прошла успешно
        </div>
      </Modal>
    );
  }

  return <div />;
};

Encachment.propTypes = {
  amount: PropTypes.number.isRequired,
  cashFunds: PropTypes.number.isRequired,
  doEncashment: PropTypes.func.isRequired,
  initEncashmentData: PropTypes.func.isRequired,
  getCheckOnSn: PropTypes.func.isRequired,
  setTransactionNumber: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  initEncashmentStatuses: PropTypes.func.isRequired,
  isFiscal: PropTypes.bool.isRequired,
  isRequested: PropTypes.bool.isRequired,
  isEncashmentSendedToAP: PropTypes.bool.isRequired,
  isEncashmentSendedToFiscal: PropTypes.bool.isRequired,
  SN: PropTypes.string.isRequired,
  initCurrentService: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    amount: state.encashment.amount,
    isRequested: state.encashment.isRequested,
    isEncashmentSendedToAP: state.encashment.isEncashmentSendedToAP,
    isEncashmentSendedToFiscal: state.encashment.isEncashmentSendedToFiscal,
    SN: state.payment.SN,
    isFiscal: state.fiscal.isFiscal,
    cashFunds: state.fiscal.balances.cash
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...encashmentActions,
    ...checkActions,
    setTransactionNumber: setSn,
    showError: showErrorAlert,
    initCurrentService: initService
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Encachment);
