import { PAYMENT_INIT } from 'actions/pay/types';
import * as types from 'actions/commission/types';

const defaultState = {
  commissionSum: 0, // значение комисии
  commissionValue: '', // процент или сумма комисии,
  currentComProfile: {},
  commissionProfiles: [],
  isCommissionProfilesRequested: false,
  isCommissionProfilesRequestedWithError: false
};

const commission = (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_COMMISSION_DATA:
      return {
        ...state,
        commissionSum: action.commissionSum,
        commissionValue: action.commissionValue
      };
    case types.SET_COMISSION_PROFILES_XML_REQUEST: {
      return { ...state, isCommissionProfilesRequested: true };
    }
    case types.COMMISION_PROFILES_REQUESTED_WITH_ERROR:
      return { ...state, isCommissionProfilesRequestedWithError: action.status };
    case types.SET_CURRENT_COMMISSION_PROFILE: {
      if (typeof action.currentComProfile === 'undefined') {
        return { ...state, currentComProfile: {} };
      }

      return { ...state, currentComProfile: action.currentComProfile };
    }
    case types.SET_COMMISSION_PROFILES:
      return { ...state, commissionProfiles: action.commissionProfiles };
    case types.CLEAR_COMMISSION_PROFILES_FOR_TEST:
      return { ...state, commissionProfiles: [] };
    case types.INIT_CURRENT_COM_PROFILE:
      return {
        ...state,
        currentComProfile: {},
      };
    case PAYMENT_INIT:
      return {
        ...state,
        commissionSum: 0, // значение комисии
        commissionValue: '', // процент или сумма комисии,
      };
    default:
      return state;
  }
};

export default commission;
