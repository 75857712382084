export const SENTRY_IGNORE_ERRORS = [
  /К сожалению, операция не может быть выполнена в данный момент по техническим причинам/,
  /Договоров не найдено или вы ввели неверные данные/,
  /Договоров данного типа не найдено/,
  /ИИН не найден/,
  /Попробуйте оплатить, выбрав Tez-Finance/,
  /К сожалению, у Вас не найдено ни одного Договора/,
  /Периоды доступные для оплаты взносов ОСМС отсутствуют/,
  /Недостаток средств у агента/,
  /ТОО Алматы ЭнергоСбыт не является поставщиком/,
  /Достигнут лимит максимальной дневной суммы пополнения/,
  /Неверный номер договора/,
  /OK/,
  /Квитанция не найдена!/,
  /По данному сервису невозможно совершить оплату услуг Алматинской области/,
  /Нет ответа от магазина/,
  /По статусу переданной заявки/,
  /По указанному ИИН не обнаружено/,
  /Заявка отменена/,
  /Время отправления поезда некорректно/,
  /В указанную дату поезд не ходит/,
  /Пролонгация невозможна/,
  /У абонента не найдены начисления/,
  /Другая (неизвестная) ошибка провайдера./,
  /Ошибка при вводе даты/,
  /Клиент либо счет не найдены. Убедитесь в/,
  /Штрафы не найдены/,
  /Сервисов к оплате не найдено./,
  /Заявка отменена/,
  /Использовать эту операцию разрешается не чаще /,
  /ФИО или дата рождения не соответсвует ИИН /,
  /Единый совокупный платеж может уплачиваться /,
  /Период оплаты должен быть в рамках текущего года/,
  /НЕТ В ЗАДАННЫХ ГРАНИЦАХ МЕСТ/,
  /Уважаемый клиент! По Вашему ИИН/,
  /Сумма оплаты не соответствует размеру ЕСП/,
  /Не найден счет по ИИН/,
  /Прием платежа запрещен провайдером./,
  /Абонент не найден./,
  /Касса заблокирована./,
  /Уважаемый клиент! Вы превысили лимит на пополнение банковского/,
];

export default SENTRY_IGNORE_ERRORS;
