export const getFromLS = (key, defaultValue) => {
  if (window.localStorage.getItem(key) !== null) {
    return window.localStorage.getItem(key);
  }

  return defaultValue;
};

export const getCertExpiredDate = () => getFromLS('expiredCertDate', '');
export const canDoCashOut = () => Number(getFromLS('hasCashout', 0));

