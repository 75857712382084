export const STEPS = ['Выбор поезда', 'Выбор вагона', 'Заполнение информации', 'Оплата'];

export const TIME_INTERVAL = [
  { label: 'Ночь (00:00 - 06:00)', value: '3' },
  { label: 'Утро (06:00 - 12:00)', value: '0' },
  { label: 'День (12:00 - 18:00)', value: '1' },
  { label: 'Вечер (18:00 - 24:00)', value: '2' }
];

export const TARIFF_KTJ = [
  { key: '1', value: 'Взрослый' },
  { key: '2', value: 'Дет. от 7 до 15' },
  { key: '3', value: 'Дет. до 2х лет без места' }
];

export const GENDER_KTJ = [
  { key: '1', value: 'Мужской' },
  { key: '2', value: 'Женский' }
];

export const CITIZENSHIP_KTJ = [
  { key: 'KAZ', value: 'Казахстан' },
  { key: 'RUS', value: 'Россия' }
];

export const DOC_TYPES_KTJ = [
  { key: 'УЛ', value: 'Удостоверение личности' },
  { key: 'ПК', value: 'Паспорт Казахстана' },
  { key: 'ПН', value: 'Паспорт РФ' },
  { key: 'ЗП', value: 'Заграничный паспорт, выданный гр. РФ' },
  { key: 'ПУ', value: 'Паспорт Узбекистана' },
  { key: 'СР', value: 'Свидетельство о рождении' },
  { key: 'ВБ', value: 'Военный билет' },
  { key: 'ЗЗ', value: 'Другие иностранные документы' },
  { key: 'БГ', value: 'УЛ без гражданства' },
  { key: 'СУ', value: 'Справка об утере УЛ/паспорта, временное УЛ' }
];

export const PLACE_TYPES_KTJ = [
  { title: 'Сидячий (общий) вагон', note: ['места подряд'] },
  { title: 'Плацкартный вагон', note: ['нечетные - нижние', 'четные - верхние', ' с 37 по 54 - боковые'] },
  { title: 'Вагон купе', note: ['нечетные - нижние', 'четные - верхние'] },
  { title: 'Вагон СВ (люкс)', note: ['Все - нижние'] },
];

export const carTypesKTJ = {
  common: {
    class: 'common',
    text: 'ОБЩИЙ',
    places: 81
  },
  platscard: {
    class: 'platscard',
    text: 'ПЛАЦКАРТНЫЙ',
    places: 54
  },
  coupe: {
    class: 'coupe',
    text: 'КУПЕ',
    places: 36
  },
  soft: {
    class: 'soft',
    text: 'МЯГКИЙ',
    places: 56
  },
  luxury: {
    class: 'luxury',
    text: 'ЛЮКС',
    places: 18
  }
};

/* типы вагонов "IndexType":
vagon_types: {
  ОБЩИЙ:1,
  СИДЯЧИЙ:2,
  ПЛАЦКАРТ:3,
  КУПЕ:4,
  МЯГКИЙ:5,
  ЛЮКС:6
},
*/
