/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Help from 'components/Help';
import PrintCheckSettings from 'containers/PrintCheckSettings/index';
import Dropdown from 'components/UI/Dropdown/dropdown';
import FiscalOferta from 'public/files/pult24-offer.pdf';
import { isTheShiftActive, setFiscalData } from 'helpers/fiscal';
import { logErrorToSentry } from 'helpers/main';
import { pingTerminal } from 'actions/encashment';
import { getCommissionProfiles } from 'actions/commission';
import { getServicesList } from 'actions/services';
import { showError } from 'actions/alert';
import { resetAuthData } from 'model/UserDb';
import {
  goToReport,
  goToEncashmentListPage,
  goToLoginPage,
  goToCashoutReport,
} from 'actions/navigate';

const MENU_ITEMS = {
  ENCASHMENT_OPERATION: 1,
  ENCASHMENT_LIST: 2,
  SUMMARY_REPORT: 3,
  CASHOUT_REPORT: 4,
  PRINT_CHECK_SETTINGS: 5,
  FISCAL_OFERTA: 6,
  HELP: 7,
  UPDATE_COMMISSION: 8,
  UPDATE_SERVICES: 9,
  LOGOUT: 10,
};

const MenuAdditionally = (props) => {
  const {
    isFiscal,
    isAuthInFiscal,
    Lock,
    setOpeningShiftCallback,
    setNeedToOpenShift,
    isKkmBlocked
  } = props;
  const [showHelpWindow, setShowHelpWindow] = useState(false);
  const [showReceiptSettings, setShowReceiptSettings] = useState(false);

  const menuItems = [
    {
      name: 'Инкассация',
      key: MENU_ITEMS.ENCASHMENT_OPERATION,
    },
    {
      name: 'Журнал инкассаций',
      key: MENU_ITEMS.ENCASHMENT_LIST,
    },
    {
      name: 'Итоговый отчет за период',
      key: MENU_ITEMS.SUMMARY_REPORT,
    },
    {
      name: 'Отчет по Выводу средств',
      key: MENU_ITEMS.CASHOUT_REPORT,
    },
    {
      name: 'Настроить печать чека',
      key: MENU_ITEMS.PRINT_CHECK_SETTINGS,
    },
    {
      name: 'Оферта Fiscal24',
      href: FiscalOferta,
      key: MENU_ITEMS.FISCAL_OFERTA,
    },
    {
      name: 'Обновить настройки комиссий',
      key: MENU_ITEMS.UPDATE_COMMISSION,
    },
    {
      name: 'Обновить список сервисов',
      key: MENU_ITEMS.UPDATE_SERVICES,
    },
  ];

  if (isFiscal) {
    menuItems.push({
      name: 'Помощь',
      key: MENU_ITEMS.HELP,
    });
  }

  menuItems.push({
    name: 'Выйти из терминала',
    key: MENU_ITEMS.LOGOUT,
    classname: 'dropdown-exit',
  });

  const clickOnMenuItem = useCallback(
    (key) => {
      switch (key) {
        case MENU_ITEMS.ENCASHMENT_OPERATION:
          return getEncashmentCallback();
        case MENU_ITEMS.ENCASHMENT_LIST:
          return props.goToEncashmentListPage();
        case MENU_ITEMS.SUMMARY_REPORT:
          return props.goToReport();
        case MENU_ITEMS.CASHOUT_REPORT:
          return props.goToCashoutReport();
        case MENU_ITEMS.PRINT_CHECK_SETTINGS:
          return setShowReceiptSettings(true);
        case MENU_ITEMS.HELP:
          return setShowHelpWindow(true);
        case MENU_ITEMS.UPDATE_COMMISSION:
          return props.getCommissionProfiles(true);
        case MENU_ITEMS.UPDATE_SERVICES:
          return props.getServicesList(true);
        case MENU_ITEMS.LOGOUT:
          return logout();
        default:
          break;
      }
    },
    [isFiscal, Lock, isAuthInFiscal]
  );

  const getEncashmentCallback = useCallback(() => {
    if (!isFiscal) {
      return props.pingTerminal(0, 0);
    }

    if (isFiscal) {
      if (isKkmBlocked) {
        props.showError('В данный момент невоможно выполнить инкассацию', 'Касса заблокирована');
      } else if (Lock) {
        if (isTheShiftActive()) {
          return props.pingTerminal(0, 0);
        }

        setOpeningShiftCallback(() => props.pingTerminal(0, 0));

        props.showError(
          'Смена просрочена. Закройте текущую смену и откройте новую'
        );
      } else if (isAuthInFiscal) {
        setNeedToOpenShift(true);
      } else {
        logErrorToSentry(
          'Инкассация. Терминал фискальный. Но не может авторизоваться в фискале.'
        );
        props.showError(
          'Ошибка инкассации. Не удалось авторизоваться в фискале. Обратитесь в ТП'
        );
      }
    }
  }, [isFiscal, Lock, isAuthInFiscal]);

  const logout = () => {
    resetAuthData();
    setFiscalData({ token: '' });
    props.goToLoginPage();
  };

  return (
    <>
      {showHelpWindow && <Help setShowHelpWindow={setShowHelpWindow} />}
      {showReceiptSettings &&
        <PrintCheckSettings setShowReceiptSettings={setShowReceiptSettings} />
      }
      <Dropdown
        title="Меню"
        menuItems={menuItems}
        clickOnMenuItem={(key) => clickOnMenuItem(key)}
      />
    </>
  );
};

MenuAdditionally.propTypes = {
  Lock: PropTypes.bool,
  isFiscal: PropTypes.bool.isRequired,
  isAuthInFiscal: PropTypes.bool.isRequired,
  isKkmBlocked: PropTypes.bool.isRequired,
  showError: PropTypes.func.isRequired,
  goToReport: PropTypes.func.isRequired,
  pingTerminal: PropTypes.func.isRequired,
  goToLoginPage: PropTypes.func.isRequired,
  setNeedToOpenShift: PropTypes.func.isRequired,
  goToEncashmentListPage: PropTypes.func.isRequired,
  setOpeningShiftCallback: PropTypes.func.isRequired,
  goToCashoutReport: PropTypes.func.isRequired,
  getCommissionProfiles: PropTypes.func.isRequired,
  getServicesList: PropTypes.func.isRequired
};

MenuAdditionally.defaultProps = {
  Lock: false,
};

const mapStateToProps = (state) => ({
  isFiscal: state.fiscal.isFiscal,
  isAuthInFiscal: state.fiscal.isAuthInFiscal,
  Lock: state.fiscal.kkmInfo.Lock,
  isKkmBlocked: state.fiscal.isKkmBlocked
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showError,
      goToReport,
      pingTerminal,
      goToLoginPage,
      goToEncashmentListPage,
      goToCashoutReport,
      getCommissionProfiles,
      getServicesList
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MenuAdditionally);
