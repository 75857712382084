import React from 'react';
import { useSelector } from 'react-redux';

import './loaderPayment.scss';

const LoaderPayment = () => {
  const { showLoaderPayment } = useSelector((state) => state.loader);

  if (showLoaderPayment) {
    return (
      <div className="loader-wrapper">
        <div className="loader-content">
          <div className="loader-header">Важно!</div>
          <div className="loader-black-content">
            Не обновляйте и не закрывайте браузер,
            это приведет к прерыванию операции!<br/>
            <div className="loader-spin"/>
            <div>Ваш запрос обрабатывается</div>
          </div>
        </div>

      </div>
    );
  }

  return null;
};

export default LoaderPayment;
