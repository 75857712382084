/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MaskedInput from 'react-maskedinput';

import IinField from 'components/IinField';
import { getMaskForInput, t, checkAccountOnReg } from 'helpers/main';
import { onlineCheck as onlineCheckReq } from 'actions/api/onlineCheck';
import { showError as showErrorHandler } from 'actions/alert';
import {
  updateFieldValue as setFieldValue,
  setConfirmInfo as setConfirmInfoHadler,
  showEnterAmountScreen as showEnterAmountHadler,
  updateWillBeCreditedAmount as updateWillBeCreditedAmountHandler,
  initPayScheme as initPaySchemeHandler
} from 'actions/pay';
import { getAmountForAdmission, getAddings } from './helper';

/* Билайн - Грузия. ЛС: 574149015 */
/* Beeline - Киргизия ЛС: 996778427690 */
/* Билайн - Узбекистан ЛС: 906690225 */

const ForeignOperators = ({
  onlineCheck,
  showError,
  updateFieldValue,
  currentService,
  lastRequestedAccount,
  measure,
  tariff,
  accountFromProps,
  setConfirmInfo,
  showEnterAmountScreen,
  updateWillBeCreditedAmount,
  willBeCreditedAmount,
  currentComProfile,
  initPayScheme,
  iinIsValidOrEmpty
}) => {
  const [account, setAccount] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const onKeyDownFowWindow = (e) => {
      if (e.key === 'Enter') {
        validateAndCheck();
      }
    };

    window.addEventListener('keydown', onKeyDownFowWindow);

    return () => {
      window.removeEventListener('keydown', onKeyDownFowWindow);
    };
  }, [currentService, account, lastRequestedAccount]);

  useEffect(
    () => {
      if (lastRequestedAccount) {
        confirm();
      }

      return () => initPayScheme(currentService.typeInterface);
    },
    [lastRequestedAccount, currentService.typeInterface]
  );

  const validateAndCheck = useCallback(
    () => {
      if (!iinIsValidOrEmpty()) return;
      setIsLoading(false);
      if (account) {
        const { regExp, maskEdit } = currentService;

        if (checkAccountOnReg(account, regExp, maskEdit)) {
          updateFieldValue('account', account);

          if (account === lastRequestedAccount) {
            return confirm();
          }
          setIsLoading(true);

          return onlineCheck(currentService, account).then(() => { setIsLoading(false); });
        }

        return showError(
          'Проверьте, пожалуйста, и повторите попытку',
          'Лицевой счет некорректен'
        );
      }

      return showError(
        'Проверьте, пожалуйста, и повторите попытку',
        'Не заполнен лицевой счет'
      );
    },
    [currentService, account, lastRequestedAccount],
  );

  const confirm = useCallback(
    () => {
      updateWillBeCreditedAmount(willBeCreditedAmount, currentComProfile);

      const curs = `1 ${measure} = ${tariff} тг`;
      const confirmFields = [
        { name: 'account', value: accountFromProps },
        { name: 'exchangeRate', value: `${curs}` },
        {
          name: 'Будет зачислено',
          value: '',
          callback: (updatedAmount) => getAmountForAdmission(updatedAmount, tariff, measure)
        },
      ];

      const checkInfo = {
        input: {
          account: accountFromProps
        },
        online: {
          currency: {
            tariff,
            commission: '',
            name: measure
          }
        }
      };

      setConfirmInfo(
        confirmFields,
        getAddings(),
        checkInfo,
        (updatedAmount) => getAddings(updatedAmount, tariff, measure)
      );
      showEnterAmountScreen();
    },
    [measure, tariff, accountFromProps, willBeCreditedAmount, currentComProfile]
  );

  const mask = getMaskForInput(currentService.maskEdit);

  return (
    <div className="pay-form">
      <IinField/>
      <div className="form-group">
        <MaskedInput
          mask={mask}
          type="tel"
          id="account"
          autoComplete="off"
          maxLength={40}
          placeholder="№ лицевого счета"
          placeholderChar=" "
          onChange={(e) => setAccount(e.target.value.toString().trim())}
          value={account}
          formatCharacters={{
            W: {
              validate(char) { return /[\wа-яА-Я-]/.test(char); },
            }
          }}
          disabled={isLoading}
        />
      </div>
      <div className="form-group">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => validateAndCheck()}
        >
          {t('next')}
        </button>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return ({
    lastRequestedAccount: state.payTypeForeignOperators.id.toString(),
    measure: state.payTypeForeignOperators.measure,
    tariff: state.payTypeForeignOperators.tariff,
    currentService: state.kassa.currentService,
    currentComProfile: state.commission.currentComProfile,
    accountFromProps: state.pay.account,
    willBeCreditedAmount: state.pay.willBeCreditedAmount,
  });
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onlineCheck: onlineCheckReq,
      showError: showErrorHandler,
      updateFieldValue: setFieldValue,
      setConfirmInfo: setConfirmInfoHadler,
      showEnterAmountScreen: showEnterAmountHadler,
      updateWillBeCreditedAmount: updateWillBeCreditedAmountHandler,
      initPayScheme: initPaySchemeHandler
    },
    dispatch
  );
}

ForeignOperators.propTypes = {
  onlineCheck: PropTypes.func.isRequired,
  initPayScheme: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  updateFieldValue: PropTypes.func.isRequired,
  showEnterAmountScreen: PropTypes.func.isRequired,
  updateWillBeCreditedAmount: PropTypes.func.isRequired,
  setConfirmInfo: PropTypes.func.isRequired,
  lastRequestedAccount: PropTypes.string.isRequired,
  measure: PropTypes.string.isRequired,
  accountFromProps: PropTypes.string.isRequired,
  willBeCreditedAmount: PropTypes.string.isRequired,
  currentComProfile: PropTypes.object.isRequired,
  currentService: PropTypes.object.isRequired,
  iinIsValidOrEmpty: PropTypes.func.isRequired,
  tariff: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForeignOperators);
