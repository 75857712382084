import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hideFiscalModal, makeFiscalPay } from 'actions/fiscal';
import PropTypes from 'prop-types';
import Modal from 'components/UI/Modal/modal';
import Input from 'components/UI/Input/input';
import PaymentMethod from 'containers/PaymentMethod';
import { formatMoney } from 'helpers/main';
import { getFiscalSectionsForOperations, getFiscalSectionsWithHidden, getFiscalUnitsForOperations } from 'helpers/fiscal';
import { getAuthData } from 'model/UserDb';
import { TERMINALS_WITH_HIDDEN_SECTIONS } from 'constants/fiscal';
import FiscalOperationReceipt from 'components/Fiscal/FiscalOperationReceipt';

const Sell = (props) => {
  const { paymentMethod, showModal, articles } = props;
  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);
  const [count, setCount] = useState(1);
  const [section, setSection] = useState(0);
  const [unit, setUnit] = useState(0);
  const [depositedAmount, setDepositedAmount] = useState(0);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isPriceValid, setIsPriceValid] = useState(true);
  const [isCountValid, setIsCountValid] = useState(true);
  const [isUnitValid, setIsUnitValid] = useState(true);
  const [isSectionValid, setIsSectionValid] = useState(true);
  const [isDepositedAmountValid, setIsDepositedAmountValid] = useState(true);
  const [isPaymentMethodValid, setIsPaymentMethodValid] = useState(true);
  const [isTotalSumValid, setIsTotalSumValid] = useState(true); // итого >= price * count ?
  const [change, setChange] = useState(0); // сдача
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const sections = getFiscalSectionsForOperations();
  const sectionsWithHidden = getFiscalSectionsWithHidden();
  const { idTerminal } = getAuthData();
  const totalSectionList = TERMINALS_WITH_HIDDEN_SECTIONS.includes(Number(idTerminal))
    ? sectionsWithHidden
    : sections;
  const units = getFiscalUnitsForOperations();

  useEffect(() => {
    if (depositedAmount > price * count) {
      setChange(depositedAmount - (price * count));

      return setIsTotalSumValid(true);
    }
    setChange(0);
  }, [depositedAmount, price, count]);

  useEffect(() => {
    if (name) setIsNameValid(true);
    if (price) setIsPriceValid(true);
    if (count) setIsCountValid(true);
    if (section) setIsSectionValid(true);
    if (depositedAmount) setIsDepositedAmountValid(true);
    if (paymentMethod !== null) setIsPaymentMethodValid(true);
    if (depositedAmount === price * count) return setIsTotalSumValid(true);
    if (unit) setIsUnitValid(true);
  }, [name, price, count, section, depositedAmount, paymentMethod, unit]);

  const clearFormData = () => {
    setName('');
    setPrice(0);
    setCount(0);
    setSection(0);
    setChange(0);
    setDepositedAmount(0);
    setSelectedSection(null);
    setSelectedUnit(null);
  };

  const validateAndBuy = () => {
    if (!name) return setIsNameValid(false);
    if (!price) return setIsPriceValid(false);
    if (!count) return setIsCountValid(false);
    if (!section) return setIsSectionValid(false);
    if (!unit) return setIsUnitValid(false);
    if (paymentMethod === null) return setIsPaymentMethodValid(false);
    if (!depositedAmount) return setIsDepositedAmountValid(false);
    if (depositedAmount < price * count) return setIsTotalSumValid(false);

    const reqData = {
      GenerateCheck: true,
      IdDomain: 1, // Вид деятельности (справочник)
      Cash: !paymentMethod ? depositedAmount : 0, // int Сумма покупки наличными
      NonCash: paymentMethod ? depositedAmount : 0, // int Сумма оплаты безналом
      Positions: [ // Список покупаемых товаров или услуг
        {
          Name: name, // string Наименование позиции
          IdSection: section, // int Идентификатор отдела (секции)
          Price: price, // int Цена позиции
          Qty: count, // int Количество в целых единицах
          Storno: false, // bool позиция была сторнирована (отменена)
          idUnit: unit // int ед.измерения
        }
      ],
      Total: price * count // int Итоговая сумма
    };

    props.makeFiscalPay(reqData);
    props.hideFiscalModal();
    clearFormData();
  };

  const selectArticle = (article) => {
    const { Name, Price, IdSection } = article;

    setName(Name);

    if (Price) {
      setPrice(Price);
    }

    if (IdSection) {
      const selValue = totalSectionList.filter((item) => item.key === Number(IdSection))[0];

      setSection(IdSection);
      if (selValue) {
        setSelectedSection({ value: selValue.key, label: selValue.value });
      }
    }
  };

  const handlerSetSection = (value) => {
    setSection(value);
    if (value) {
      const selValue = totalSectionList.filter((item) => item.key === Number(value))[0];

      setSelectedSection({ value: selValue.key, label: selValue.value });
    } else setSelectedSection(null);
  };

  const handlerSetUnit = (value) => {
    setUnit(value);
    if (value) {
      const selValue = units.filter((item) => item.key === Number(value))[0];

      setSelectedUnit({ value: selValue.key, label: selValue.value });
    } else setSelectedUnit(null);
  };

  return (
    <Modal
      title="Продажа"
      isOpen={showModal}
      onOk={() => validateAndBuy()}
      onClose={() => props.hideFiscalModal()}
      okText="Оформить чек"
    >
      <FiscalOperationReceipt
        item={{
          value: name,
          onChange: setName,
          isValid: isNameValid,
          dropdownList: articles,
          dropdownCallback: selectArticle }}
        price={{ value: price, isValid: isPriceValid, onChange: setPrice }}
        count={{ value: count, onChange: setCount, isValid: isCountValid }}
        section={{
          options: totalSectionList,
          value: selectedSection,
          onChange: handlerSetSection,
          isValid: isSectionValid
        }}
        units={{
          options: units,
          value: selectedUnit,
          onChange: handlerSetUnit,
          isValid: isUnitValid
        }}
      />

      <h2>Внесённая сумма</h2>
      <div className="fiscal-modal-section">
        <div>
          <PaymentMethod />
          <span className="inputError">{!isPaymentMethodValid ? 'Поле обязательно для заполнения' : ' '}</span>
        </div>
        {paymentMethod !== null && (
          <div className="fiscalItem">
            <Input
              placeholder="0"
              id="depositedAmount"
              type="number"
              onchange={(value) => setDepositedAmount(Number(value))}
              value={depositedAmount.toString()}
              label={!paymentMethod ? 'Наличными' : 'Безналичными'}
              errorMessage="Поле обязательно для заполнения"
              isError={!isDepositedAmountValid}
            />
          </div>
        )}
        {!isTotalSumValid ? (
          <span className="inputError">
            Для проведения операции не хватает {formatMoney((price * count) - depositedAmount)} тг
          </span>
        ) : ''}
      </div>
      <div>
        {change ? (
          <>
            <h2>Сдача</h2>
            {formatMoney(change)} тг
          </>
        ) : ''}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  paymentMethod: state.payment.paymentMethod,
  showModal: state.fiscal.modalData.showModal,
  articles: state.fiscal.articles,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    hideFiscalModal,
    makeFiscalPay
  },
  dispatch);

Sell.propTypes = {
  showModal: PropTypes.bool.isRequired,
  makeFiscalPay: PropTypes.func.isRequired,
  hideFiscalModal: PropTypes.func.isRequired,
  articles: PropTypes.array.isRequired,
  paymentMethod: PropTypes.number,
};

Sell.defaultProps = {
  paymentMethod: null
};

export default connect(mapStateToProps, mapDispatchToProps)(Sell);
