import * as types from 'actions/initialDownload/types';

const defaultState = {
  isPaymentHistoryRequested: false,
  isBalanceRequested: false,
  isFiscalAuthDataRequested: false,
  isFiscalKkmInfoRequested: false,
};

const initialDownload = (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.PAYMENT_HISTORY_LOADED:
      return { ...state, isPaymentHistoryRequested: action.loaded };
    case types.BALANCE_LOADED:
      return { ...state, isBalanceRequested: action.loaded };
    case types.FISCAL_AUTH_DATA_LOADED:
      return { ...state, isFiscalAuthDataRequested: action.loaded };
    case types.FISCAL_KKM_INFO_LOADED:
      return { ...state, isFiscalKkmInfoRequested: action.loaded };
    default:
      return state;
  }
};

export default initialDownload;
