import { showError } from 'actions/alert';
import { checkError, checkMsg } from 'helpers/onlineCheck';
import { getErrText } from 'helpers/main';

import * as types from './types';

export const jysanOnlineCheck = res => (dispatch) => {
  const errMsg = checkMsg(res);
  const errCode = checkError(res);

  if (errMsg) {
    dispatch(showError(errMsg));
  }

  if (!errCode) {
    const result = res.data.account;
    const { checkTime, MaxAmount, FIO, '@attributes': { id } } = result;

    // проверка на время
    if (typeof checkTime !== 'undefined') {
      dispatch({
        type: types.SET_JYSAN_CHECKTIME,
        showTimeMsg: Number(checkTime) !== 0,
      });
    }

    if (typeof FIO !== 'undefined') {
      dispatch({
        type: types.SET_JYSAN_DATA,
        MaxAmount,
        FIO,
        id
      });
    }
  } else {
    dispatch(showError(getErrText(errCode)));
  }
};

export default jysanOnlineCheck;
