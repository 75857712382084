import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import 'moment/locale/ru';
import 'flatpickr/dist/flatpickr.css';
import { Russian } from 'flatpickr/dist/l10n/ru';
import Flatpickr from 'react-flatpickr';
import { showError } from 'actions/alert';
import { FISCAL_SCREENS as FISCAL_OPERATIONS } from 'constants/fiscal';
import {
  hideFiscalModal,
  setFiscalPaymentsList,
  getFiscalPaymentsList,
  getOperationDetail,
  setFiscalReceipt,
  doFiscalRefund
} from 'actions/fiscal';
import { getTheStartDateOfTheCurrentDay, getTheEndOfTheCurrentDay, getHumanDateTime, formatMoney } from 'helpers/main';
import CustomButton from 'components/UI/Button/button';
import Modal from 'components/UI/Modal/modal';
import Icon from 'components/UI/Icon/index';
import Table from '@material-ui/core/Table';
import Alert from 'components/UI/Alert/alert';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { TableSortLabel } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

const columns = [
  {
    title: '№',
    key: 'id',
    dataIndex: 'id',
  },
  {
    title: 'Дата и время',
    key: 'datetime',
    dataIndex: 'datetime',
  },
  {
    title: 'Тип операции',
    key: 'TypeDocument',
    dataIndex: 'TypeDocument',
    isSort: true
  },
  {
    title: 'К оплате, тг',
    key: 'Cash',
    dataIndex: 'Cash',
    isSort: true
  },
  {
    title: 'Действия',
    key: 'operations',
    dataIndex: 'operations',
  },
];

const RefuntOfPayment = (props) => {
  const [dateFrom, setDateFrom] = useState(getTheStartDateOfTheCurrentDay());
  const [dateTo, setDateTo] = useState(getTheEndOfTheCurrentDay());
  const [searchedStr, setSearchedStr] = useState(0);
  const [filteredPaymentsList, setFilteredPaymentsList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('typeOperation');
  const [selectedOperationData, setSelectedOperationData] = useState({});
  const { showModal } = props;

  useEffect(() => {
    props.getFiscalPaymentsList(
      moment(dateFrom, 'YYYYMMDDhhmmss').format('YYYY-MM-DDT00:00:00'),
      moment(dateTo, 'YYYYMMDDhhmmss').format('YYYY-MM-DDT23:59:59')
    );
    // eslint-disable-next-line
  }, [dateFrom, dateTo]);

  useEffect(() => {
    if (searchedStr) {
      const filteredPayments = props.paymentsList.filter(({ TypeDocument, Value }) => {
        if (Number.isNaN(Number(searchedStr))) {
          return TypeDocument.Name.toLowerCase().includes(searchedStr.toLowerCase());
        }

        return Number(Value) === Number(searchedStr);
      });

      setFilteredPaymentsList(filteredPayments);
    } else {
      setFilteredPaymentsList([]);
    }
  }, [searchedStr, props.paymentsList]);

  const checkDataAndRefund = () => {
    const { Id, Cash, TypeDocument: { Id: typeOperation } } = selectedOperationData;

    if (typeOperation === FISCAL_OPERATIONS.SELL) {
      if (Cash && props.cash < Number(Cash)) {
        return props.showError(
          'Обнаружена нехватка средств в кассе для совершения данной операции. Пожалуйста попробуйте немного позднее',
          'В кассе недостаточно средств'
        );
      }
    }

    props.doFiscalRefund(Id);

    props.setFiscalReceipt('');
    props.hideFiscalModal();
    props.setFiscalPaymentsList([]);
  };

  const changeDateRange = (dates) => {
    if (dates.length === 2) {
      const newDateFrom = moment(dates[0]).format('YYYY-MM-DD 00:00:01');
      const newDateTo = moment(dates[1]).format('YYYY-MM-DD 23:59:59');
      const dateFromStr = moment(newDateFrom).format('YYYYMMDDHHmmss');
      const dateToStr = moment(newDateTo).format('YYYYMMDDHHmmss');

      setDateFrom(dateFromStr);
      setDateTo(dateToStr);
    }
  };

  const getSorting = (sortDirection, fieldForSortBy) =>
    (a, b) =>
      getSortingAscDesc(
        a,
        b,
        fieldForSortBy,
        sortDirection
      );

  const getSortingAscDesc = (a, b, fieldForSortBy, sortDirection,) => {
    if (sortDirection === 'desc') {
      if (fieldForSortBy === 'TypeDocument') {
        return (a.TypeDocument.Name < b.TypeDocument.Name ? -1 : 1);
      }

      return (a[fieldForSortBy.toString()] < b[fieldForSortBy.toString()] ? -1 : 1);
    }

    if (fieldForSortBy === 'TypeDocument') {
      return (b.TypeDocument.Name < a.TypeDocument.Name ? -1 : 1);
    }

    return (b[fieldForSortBy.toString()] < a[fieldForSortBy.toString()] ? -1 : 1);
  };

  const showConfirmModal = (data) => {
    setSelectedOperationData(data);
    props.getOperationDetail({ IdDocument: data.Id, printAfterGetting: false });
  };

  const printReceipt = (IdDocument) => {
    props.getOperationDetail({ IdDocument, printAfterGetting: true });
  };

  const modalSize = useMemo(() => {
    if (props.receiptForRefund) {
      if (props.receiptForRefund.includes('Check API Error')) {
        return 'small';
      }

      return 'small-and-all-high';
    }

    return 'high';
  }, [props.receiptForRefund]);

  const getOnClose = () => {
    if (props.receiptForRefund) { props.setFiscalReceipt(''); } else {
      props.hideFiscalModal();
      props.setFiscalPaymentsList([]);
    }
  };

  const checkOperationsList = () => {
    if (!props.paymentsList.length || (searchedStr && !filteredPaymentsList.length)) {
      return (
        <caption className="table-caption">
          Отсутствуют операции {
                        (searchedStr && !Number.isNaN(Number(searchedStr))) ?
                          `на сумму ${searchedStr} тг ` :
                          'по поисковому запросу '
                        }
          за выбранный период
        </caption>
      );
    }

    return <caption />;
  };

  const getSortDirection = (item) => {
    if (orderBy === item.key) return order;

    return 'asc';
  };

  const getSearchArray = () => {
    if (searchedStr) return filteredPaymentsList;

    return props.paymentsList;
  };

  const getPagingCount = () => {
    if (searchedStr) return filteredPaymentsList.length;

    return (props.paymentsList).length;
  };

  const checkReceiptError = () => {
    if (props.receiptForRefund.includes('Check API Error')) {
      return <Alert message="Внимание! Чек для данной транзакции не найден. Вы уверены что хотите сделать возврат?" banner />;
    }

    return (
      <>
        <Alert message="Внимание! Перед оформлением возврата проверьте чек, убедитесь что это та операция, которую вы искали" banner />
        <div className="fiscal-repeipt-preview">
          <img src={`data:image/png;base64, ${props.receiptForRefund}`} width="280px" alt="fiscal-receipt" />
        </div>
      </>
    );
  };

  return (
    <Modal
      title="Возврат операции"
      isOpen={showModal}
      onOk={checkDataAndRefund}
      onClose={getOnClose}
      okText="Оформить возврат"
      cancelText="Вернуться к списку операций"
      size={modalSize}
      isFooterHidden={!props.receiptForRefund}
    >
      <div>
        {!props.receiptForRefund ? (
          <div>
            <div className="payment-history-head fiscal-payment-history-head">
              <div className="input-wrapper">
                <input
                  placeholder="Найти операцию по сумме или типу операции"
                  className="search"
                  onChange={e => setSearchedStr(e.target.value)}
                />
              </div>
              <div className="period-block-title">
                <span>Выберите период</span>
                <Flatpickr
                  options={{
                    dateFormat: 'd-m-Y',
                    mode: 'range',
                    locale: Russian,
                    defaultDate: [
                      moment(dateFrom, 'YYYYMMDDHHmmss').format('DD-MM-YYYY'),
                      moment(dateTo, 'YYYYMMDDHHmmss').format('DD-MM-YYYY')
                    ]
                  }}
                  className="datepicker-wrapper"
                  onChange={dates => changeDateRange(dates)}
                />
              </div>
            </div>
            <div className="payments-table">
              <TableContainer component={Paper} elevation={0}>
                <Table aria-label="simple table">
                  {checkOperationsList()}
                  <TableHead>
                    <TableRow>
                      {columns.map((item) => (
                        <TableCell key={item.key} sortDirection="asc">
                          {item.isSort ? (
                            <TableSortLabel
                              active={item.isSort}
                              direction={getSortDirection(item)}
                              onClick={() => {
                                setOrder(order === 'asc' ? 'desc' : 'asc');
                                setOrderBy(item.key);
                              }}
                            >
                              {item.title}
                            </TableSortLabel>
                          ) :
                            `${item.title}`
                        }
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* если ищут по сумме, то отображаем отфильтрованный массив операций */}
                    {getSearchArray().sort(getSorting(order, orderBy))
                      .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                      .map((rows, idx) => (
                        <TableRow key={rows.ReceiptDate} hover>
                          <TableCell>{idx + 1}</TableCell>
                          <TableCell>{getHumanDateTime(rows.ReceiptDate)}</TableCell>
                          <TableCell>{rows.TypeDocument.Name}</TableCell>
                          <TableCell>{formatMoney(rows.Cash)} тг</TableCell>
                          <TableCell className="hideForPrint">
                            <CustomButton
                              data-tip
                              data-for="tooltip-print-ref"
                              onClick={() => printReceipt(rows.Id)}
                            >
                              Печать чека
                            </CustomButton>
                            {(
                              rows.TypeDocument.Id === FISCAL_OPERATIONS.BUY ||
                              rows.TypeDocument.Id === FISCAL_OPERATIONS.SELL
                            ) ? (
                              <CustomButton
                                data-tip
                                data-for="tooltip-print-ref"
                                onClick={() => showConfirmModal(rows)}
                              >
                                Возврат
                              </CustomButton>
                              ) : (
                                <div />
                              )}
                          </TableCell>
                        </TableRow >
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={getPagingCount()}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(_, newPage) => setPage(newPage)}
                onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
                labelRowsPerPage="Количество операций на странице"
              />
            </div>
          </div>
        ) : (
          <div className="fiscal-receipt-confirm-block">
            <div className="fiscal-receipt-warning">
              <div className="cashout-modal-head">
                <div>
                  <Icon type="refund" className="cashout-modal-icon" />
                </div>
                <div>Возврат</div>
              </div>
              {checkReceiptError()}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  showModal: state.fiscal.modalData.showModal,
  cash: state.fiscal.balances.cash,
  paymentsList: state.fiscal.paymentsList,
  receiptForRefund: state.fiscal.receiptForRefund,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    showError,
    doFiscalRefund,
    hideFiscalModal,
    setFiscalReceipt,
    getOperationDetail,
    getFiscalPaymentsList,
    setFiscalPaymentsList,
  },
  dispatch);

RefuntOfPayment.propTypes = {
  showModal: PropTypes.bool.isRequired,
  receiptForRefund: PropTypes.string.isRequired,
  showError: PropTypes.func.isRequired,
  hideFiscalModal: PropTypes.func.isRequired,
  doFiscalRefund: PropTypes.func.isRequired,
  setFiscalReceipt: PropTypes.func.isRequired,
  getOperationDetail: PropTypes.func.isRequired,
  getFiscalPaymentsList: PropTypes.func.isRequired,
  setFiscalPaymentsList: PropTypes.func.isRequired,
  cash: PropTypes.number.isRequired,
  paymentsList: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RefuntOfPayment);
