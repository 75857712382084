/* eslint-disable import/prefer-default-export */
export const TERMINALS_WITH_CERT_DATE_EXPIRE = '2023-04-20';
export const TERMINALS_WITH_CERT = [
  153478,
  153180,
  153179,
  153178,
  153106,
  153105,
  153108,
  153104,
  153107,
  153475,
  156970,
  155014,
  153477,
  155016,
  153115,
  153110,
  153121,
  153118,
  153113,
  153117,
  153114,
  153109,
  153111,
  153119,
  153112,
  153120,
  153122,
  153116,
  155017,
  155009,
  155010,
  155011,
  156975,
  156974,
  156969,
  156971,
  153150,
  153151,
  153149,
  153174,
  155008,
  155004,
  154995,
  154994,
  154996,
  154991,
  154990,
  154993,
  155003,
  154992,
  154999,
  155000,
  155001,
  154988,
  154998,
  154989,
  153482,
  153133,
  153129,
  153126,
  153128,
  153131,
  153132,
  153130,
  153127,
  153481,
  153148,
  156967,
  153175,
  153177,
  153176,
  153476,
  153171,
  153172,
  153173,
  155018,
  153143,
  153144,
  153147,
  153142,
  153145,
  153146,
  155021,
  155020,
  153135,
  156976,
  153137,
  153136,
  153152,
  156968,
  153153,
  153158,
  153156,
  153157,
  153479,
  153138,
  153140,
  153141,
  153139,
  153125,
  153124,
  153123,
  153155,
  153154,
  153134,
  155002,
  156972,
  156973,
  155007,
  153181,
  153182,
  155005,
  155006,
  153159,
  153162,
  153160,
  153161,
  153168,
  153169,
  153170,
  153480,
];
