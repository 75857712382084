import React from 'react';
import { getCertExpiredDate } from 'helpers/cert';
import { getCountDaysForDateExpired, declOfNum } from 'helpers/main';

const COUNT_DAYS_FOR_DISPLAYING_WARNING = 7;

const CertExpiredWarning = () => {
  const certExpiredDate = getCertExpiredDate();

  if (certExpiredDate) {
    const coutDaysForExpiring = Math.ceil(getCountDaysForDateExpired(certExpiredDate, 'dateTime'));

    if (coutDaysForExpiring && COUNT_DAYS_FOR_DISPLAYING_WARNING >= coutDaysForExpiring) {
      return (
        <div className="warning-block">
          Срок действия сертификата безопасности истекает через {coutDaysForExpiring} {' '}
          {declOfNum(coutDaysForExpiring, ['день', 'дня', 'дней'])}.
          Вы можете перевыпустить его в кабинете дилера.
        </div>
      );
    }

    if (!coutDaysForExpiring) {
      return (
        <div className="warning-block">
          Срок действия сертификата безопасности истек.
          Вы можете перевыпустить его в кабинете дилера.
        </div>
      );
    }
  }

  return <div />;
};

export default CertExpiredWarning;

