import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { useWindow } from 'hooks';
import { getAuthData } from 'model/UserDb';
import { fetchTerminalInfo } from 'actions/api/terminal';
import { makePay } from 'actions/api/makePay';
import { goToLoginPage } from 'actions/navigate';
import { onlineCheck } from 'actions/api/onlineCheck';

import LogoutTimer from 'components/LogoutTimer';
import ServiceListBlock from './ServiceListBlock';
import PaymentsHistory from './PaymentsHistory';
import Pay from './Pay';

const Main = (props) => {
  useWindow();
  const { guid } = getAuthData();

  useEffect(() => {
    if (guid !== '') {
      props.fetchTerminalInfo();
    } else {
      props.goToLoginPage();
    }
    // eslint-disable-next-line
  }, []);

  if (guid !== '') {
    return (
      <>
        <LogoutTimer />
        <ServiceListBlock />
        <div className="payment-wrapper">
          <PaymentsHistory />
          <Pay />
        </div>
      </>
    );
  }

  return null;
};

Main.propTypes = {
  goToLoginPage: PropTypes.func.isRequired,
  fetchTerminalInfo: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    goToLoginPage,
    onlineCheck,
    makePay,
    fetchTerminalInfo
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(Main);
