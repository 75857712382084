import PayTypes from 'paytypes';
import { showSuccess } from 'actions/alert';
import { hasItBeenTwoHours, logErrorToSentry } from 'helpers/main';
import { getLastProfilesUpdate, setLastProfilesUpdate } from 'model/Params';
import { saveCommissionProfilesInIDB, getCommissionProfilesFromIDB } from 'model/CommissionProfiles';

import * as types from './types';

const { stopLoader } = PayTypes.actions.handlers;

export function initCurrentComProfile() {
  return (dispatch) => {
    dispatch({ type: types.INIT_CURRENT_COM_PROFILE });
  };
}

export function updateCommissionData({ commissionSum, commissionValue }) {
  return (dispatch) => {
    dispatch({ type: types.SET_COMMISSION_DATA, commissionSum, commissionValue });
  };
}

export function setComProfilesToProps(commissionProfiles) {
  return ({ type: types.SET_COMMISSION_PROFILES, commissionProfiles });
}

export function setCurrentComProfile(currentComProfile) {
  return (dispatch) => {
    dispatch({ type: types.SET_CURRENT_COMMISSION_PROFILE, currentComProfile });
  };
}

export function clearProfilesInPropsForTest() {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_COMMISSION_PROFILES_FOR_TEST });
  };
}

// Список профилей комиссий
export const getCommissionProfiles = (forceUpdate = false) => async (dispatch) => {
  const lastProfilesUpdate = getLastProfilesUpdate();
  const commissionProfilesFromIDB = await getCommissionProfilesFromIDB();
  const canRequested = hasItBeenTwoHours(lastProfilesUpdate);

  const successCallback = (data) => {
    const { profCom } = data;

    if (typeof profCom.prof !== 'undefined') {
      if (forceUpdate) {
        dispatch(showSuccess('Настройки комиссий успешно обновлены'));
      }
      // Записываем дату последнего обновления
      setLastProfilesUpdate();

      dispatch(setComProfilesToProps(profCom.prof));

      saveCommissionProfilesInIDB(profCom.prof)
        .then(() => dispatch(stopLoader()))
        .catch((err) => logErrorToSentry(`Ошибка сохранения профилей комиссий ${err.message}`, '', 0, 'SAVE_COMMISSION_PROFILES'));

      dispatch({ type: types.COMMISION_PROFILES_REQUESTED_WITH_ERROR, status: false });
    }
  };

  const dispatchData = {
    type: types.SET_COMISSION_PROFILES_XML_REQUEST,
    payload: { reqType: 10, xml: '', successCallback }
  };

  // Диспачим чтобы сделать флаг isCommissionProfilesRequested = true
  dispatch({ type: types.SET_COMISSION_PROFILES_XML_REQUEST });

  if (commissionProfilesFromIDB.length) { // если есть сохраненные в indexedDb
    if (canRequested || forceUpdate) {
      dispatch(dispatchData);
    } else {
      dispatch(setComProfilesToProps(commissionProfilesFromIDB));
      dispatch(stopLoader());
    }
  } else {
    dispatch(dispatchData);
  }
};
